import _ from 'lodash';
import { replace } from 'connected-react-router';
import queryString from 'query-string';
import { ROUTE_CONSTANTS, ROUTES, RESULT_FEATURES, FUND_IDS_FIELD } from 'configs/AppFeatureConfig';
import { ALL_NESTED_STRATEGY_IDS, STRATEGY_CONFIGS_BY_ID, ALL_VALID_STRATEGY_IDS } from 'configs/FundStrategiesConfig';
import { FILTER_GROUP_IDS } from 'configs/CriteriaConfig';
import { INITIAL_STATE as DEFAULT_SORT } from 'reducers/SortResultsReducer';
import { ALL_SORT_FIELDS } from 'configs/ResultConfig';
import { getFilterGroupsFromQueryObj } from 'utilities/criteriaUtils';
import { getCurrentViewTabShortNames } from 'reducers/selectors/ViewTabSelectors';
import { getAssetClassCodesByCategoryCode } from 'reducers/FundTypeFiltersReducer';
import { getRouterPersistentQueries, getCurrentStartRoute } from 'reducers/selectors/RouterSelectors';
import mobileUtils from 'utilities/mobileUtils';
import { PERSISTENT_QUERIES } from '../configs/AppFeatureConfig';

/* #region  Constants */

const VALID_DISPLAY_TYPES = ['card', 'table', 'compare'];
const VALID_SORT_ORDERS = ['asc', 'desc'];
const DEFAULT_DISPLAY_TYPE = 'table';
const VALIDATION_SOURCE_BLACKLIST = ['urlValidationMiddleware', 'routerMiddleware'];

/* #endregion */

/* #region  Helper Functions - Redirection */

// helper function namespace
export const helperFunctions = {};

helperFunctions.redirectToStrategies = (state, store) => {
  const href = { pathname: ROUTE_CONSTANTS.STRATEGIES, search: queryString.stringify(getRouterPersistentQueries(state)) };
  const options = { source: 'urlValidationMiddleware', persistQuery: false };
  const action = replace(href, options);
  store.dispatch(action);
};

helperFunctions.redirectToStart = (state, store) => {
  const href = { pathname: getCurrentStartRoute(state), search: queryString.stringify(getRouterPersistentQueries(state)) };
  const options = { source: 'urlValidationMiddleware', persistQuery: false };
  const action = replace(href, options);
  store.dispatch(action);
};

/* #endregion */

/* #region  Helper Functions - Validation */

helperFunctions.invalidateValidationStatus = (validationStatus, fixWasAvailable = false, queryChanged = true) => {
  validationStatus.isValid = false;
  if (!fixWasAvailable) {
    // If no fix was available we will have to redirect to start
    validationStatus.shouldRedirectToStart = true;
  } else if (queryChanged) {
    validationStatus.persistQuery = false;
  }
};

// Validate displayType
helperFunctions.validateDisplayType = (params, validationStatus) => {
  // Must be one of "card, table, compare" (defined in VALID_DISPLAY_TYPES)
  if (_.isNil(params.displayType) || !_.includes(VALID_DISPLAY_TYPES, params.displayType)) {
    params.displayType = DEFAULT_DISPLAY_TYPE;
    helperFunctions.invalidateValidationStatus(validationStatus, true);
  }
  // Can only be 'card' on mobile
  if (!mobileUtils.isMobile() && params.displayType === 'card') {
    params.displayType = DEFAULT_DISPLAY_TYPE;
    helperFunctions.invalidateValidationStatus(validationStatus, true);
  }
};

// Validate viewTab
helperFunctions.validateViewTab = (params, state, validationStatus) => {
  const validViewTabs = getCurrentViewTabShortNames({ ...state, displayType: params.displayType });

  // Must be a valid view tab
  if (_.isNil(params.viewTab) || !_.includes(validViewTabs, params.viewTab)) {
    // If not => default to first valid tab
    params.viewTab = _.get(validViewTabs, 0);
    helperFunctions.invalidateValidationStatus(validationStatus, true);
  }
};

// Validate sortBy
helperFunctions.validateSortBy = (params, validationStatus) => {
  // Validate against list of all sort fields to make sure it exists
  if (_.isNil(params.sortBy) || !_.includes(ALL_SORT_FIELDS, params.sortBy)) {
    // If not => default to default sort field (averageAnnualReturnsYear3)
    params.sortBy = DEFAULT_SORT.sortCol;
    helperFunctions.invalidateValidationStatus(validationStatus, true);
  }
};

helperFunctions.validateSortOrder = (params, validationStatus) => {
  // Must be one of "asc"/"desc" (defined in DEFAULT_SORT.sortDir)
  if (_.isNil(params.sortOrder) || !_.includes(VALID_SORT_ORDERS, params.sortOrder)) {
    // If not => default to default sort direction (desc)
    params.sortOrder = DEFAULT_SORT.sortDir;
    helperFunctions.invalidateValidationStatus(validationStatus, true);
  }
};

// Validate pageNum
helperFunctions.validatePageNum = (params, validationStatus) => {
  const pageNumInt = _.parseInt(params.pageNum);
  const hasNoValue = _.isNil(params.pageNum) || _.isNil(pageNumInt);
  const isNotPositiveInt =
    _.isNaN(pageNumInt) || !_.isNumber(pageNumInt) || !_.isInteger(pageNumInt) || pageNumInt <= 0;
  // Must be numerical, positive, integer
  if (hasNoValue || isNotPositiveInt) {
    // If not => default to 1
    params.pageNum = 1;
    helperFunctions.invalidateValidationStatus(validationStatus, true);
  }
};

helperFunctions.validateResultsRouteParams = (router, state, validationStatus) => {
  // Keep a copy of original params
  const originalParams = _.get(router, ['params'], {});
  const newParams = _.cloneDeep(originalParams);

  // Check for invalid params and fix them if necessary
  helperFunctions.validateDisplayType(newParams, validationStatus);
  helperFunctions.validateViewTab(newParams, state, validationStatus);
  helperFunctions.validateSortBy(newParams, validationStatus);
  helperFunctions.validateSortOrder(newParams, validationStatus);
  helperFunctions.validatePageNum(newParams, validationStatus);

  const { displayType, viewTab, sortBy, sortOrder, pageNum } = newParams;
  const newPathname = `/results/${displayType}/${viewTab}/${sortBy}/${sortOrder}/${pageNum}`;
  return { newPathname };
};

// Validate filter group count
helperFunctions.validateFilterGroupCount = (newQuery, validationStatus) => {
  const filterGroups = getFilterGroupsFromQueryObj(newQuery, FILTER_GROUP_IDS, [FUND_IDS_FIELD]);
  const filterGroupIds = _.keys(filterGroups);

  // Validate existence of 1 or more criteria
  if (filterGroupIds.length < RESULT_FEATURES.MIN_CRITERIA_COUNT) {
    helperFunctions.invalidateValidationStatus(validationStatus);
  }
};

// Validate fund ids field
helperFunctions.validateFundsIdsField = (router, newQuery, validationStatus) => {
  const displayType = _.get(router, ['params', 'displayType']);
  // Validate presence of FUND_IDS_FIELD on compare view
  if (displayType === 'compare' && !_.has(newQuery, FUND_IDS_FIELD)) {
    helperFunctions.invalidateValidationStatus(validationStatus);
  }
};

// Validate assetClass and categories query
helperFunctions.validateAssetClassAndCategories = (state, newQuery, validationStatus) => {
  // If we have categories but no assetClass

  if (newQuery.category && !newQuery.assetClass) {
    const map = getAssetClassCodesByCategoryCode(state);
    const categories = newQuery.category.split(',');
    const assetClasses = _.map(categories, category => map[category]);
    const assetClassQuery = _.uniq(assetClasses).sort().join(',');
    if (assetClassQuery) {
      newQuery.assetClass = assetClassQuery;
    }
    helperFunctions.invalidateValidationStatus(validationStatus, true);
  }
};

// Validate criteria order
helperFunctions.validateCriteriaOrder = (newQuery, validationStatus) => {
  const filterGroups = getFilterGroupsFromQueryObj(newQuery, FILTER_GROUP_IDS, [FUND_IDS_FIELD]);
  const filterGroupIds = _.keys(filterGroups);
  const orderStr = _.get(newQuery, ['order']);
  let orderArr = !_.isNil(orderStr) && orderStr.length ? orderStr.split(',') : [];
  const orderedCriteriaCount = _.intersection(filterGroupIds, orderArr).length;

  // Validations for 'order' param
  if (orderedCriteriaCount !== filterGroupIds.length || orderedCriteriaCount !== orderArr.length) {
    // Ensure that there is exactly 1 entry in 'order' for each criteria present in query string
    const missingFromOrder = _.difference(filterGroupIds, orderArr);
    const missingFromCriteria = _.difference(orderArr, filterGroupIds);
    // Add criteria that are in qs but missing from "order"
    orderArr = [...orderArr, ...missingFromOrder];
    // Remove criteria from order that are not in qs
    orderArr = _.filter(orderArr, orderCrit => !_.includes(missingFromCriteria, orderCrit));
    newQuery.order = orderArr.join(',');
    helperFunctions.invalidateValidationStatus(validationStatus, true, true);
  }
};

// Validate presence of persistent queries
helperFunctions.validatePersistentQueries = (newQuery, validationStatus) => {
  if (!_.every(PERSISTENT_QUERIES, key => _.get(newQuery, [key]))) {
    helperFunctions.invalidateValidationStatus(validationStatus);
  }
};

helperFunctions.validateCriteriaQuery = (router, state, newQuery, validationStatus) => {
  helperFunctions.validateFilterGroupCount(newQuery, validationStatus);
  helperFunctions.validateFundsIdsField(router, newQuery, validationStatus);
  helperFunctions.validateAssetClassAndCategories(state, newQuery, validationStatus);
  helperFunctions.validateCriteriaOrder(newQuery, validationStatus);
  helperFunctions.validatePersistentQueries(newQuery, validationStatus);
};

helperFunctions.validateStrategiesQuery = (newQuery, validationStatus) => {
  let strategy = _.get(newQuery, ['strategy']);
  const filterGroups = getFilterGroupsFromQueryObj(newQuery, FILTER_GROUP_IDS, [FUND_IDS_FIELD]);

  // Validations for 'strategy' param
  if (!_.isNil(strategy)) {
    let hasModifiedIndicator = _.endsWith(strategy, '_modified');
    const strategyId = _.replace(strategy, '_modified', '');
    if (!_.includes(ALL_VALID_STRATEGY_IDS, strategyId)) {
      // verify strategy id is valid (or valid plus modified)
      delete newQuery.strategy;
      helperFunctions.invalidateValidationStatus(validationStatus, true, true);
    } else {
      // determine if criteria/filters in the query string match the strategy definition.  If not => add _modified to the strategyId in the qsVars
      const criteriaForStrategy = _.get(STRATEGY_CONFIGS_BY_ID, [strategyId, 'filterGroupSelections']);
      // convert the strategy criteria into same format as criteria in query string so they can be compared to determine if the strategy is modified or not.
      const criteriaForStrategyAsQs = _.chain(criteriaForStrategy)
        .keyBy(crit => crit.criteriaId)
        .mapValues(critVals => critVals.value.sort().join(','))
        .value();

      const sortedFilterGroups = _.mapValues(filterGroups, filterGroup =>
        !filterGroup ? [] : filterGroup.split(',').sort().join(','),
      );
      const filterGroupSelectionsMatchStrategy = _.isEqual(criteriaForStrategyAsQs, sortedFilterGroups);
      if (!filterGroupSelectionsMatchStrategy && !hasModifiedIndicator) {
        strategy = strategyId + '_modified';
        newQuery.strategy = strategy;
        helperFunctions.invalidateValidationStatus(validationStatus, true, true);
      } else if (filterGroupSelectionsMatchStrategy && hasModifiedIndicator) {
        newQuery.strategy = strategyId;
        helperFunctions.invalidateValidationStatus(validationStatus, true, true);
      }
    }
  }
};

helperFunctions.validateResultsQueryParams = (payload, state, validationStatus) => {
  const originalQuery = _.get(payload, ['query']);
  const newQuery = _.cloneDeep(originalQuery);

  helperFunctions.validateCriteriaQuery(payload, state, newQuery, validationStatus);
  helperFunctions.validateStrategiesQuery(newQuery, validationStatus);
  validationStatus.persistQuery = _.isEqual(newQuery, originalQuery);

  return { newQuery };
};

helperFunctions.validateNestedStrategiesRoute = (router, state, store, validationStatus) => {
  // Validate nested strategies route
  // strategy param must be valid nested strategy id
  const currentNestedStrategy = _.get(router, ['params', 'strategy']);
  //if not => redirect to /strategies route
  if (_.isNil(currentNestedStrategy) || !_.includes(ALL_NESTED_STRATEGY_IDS, currentNestedStrategy)) {
    helperFunctions.invalidateValidationStatus(validationStatus, true);
    helperFunctions.redirectToStrategies(state, store);
  }
};

helperFunctions.validateResultsRoute = (payload, state, store, validationStatus) => {
  // validate Route params
  const { newPathname } = helperFunctions.validateResultsRouteParams(payload, state, validationStatus);
  const { newQuery } = helperFunctions.validateResultsQueryParams(payload, state, validationStatus);
  if (validationStatus.shouldRedirectToStart) {
    helperFunctions.redirectToStart(state, store);
  } else if (!validationStatus.isValid) {
    _.assign(newQuery, getRouterPersistentQueries(state));
    const href = { pathname: newPathname, search: queryString.stringify(newQuery) };
    const options = { source: 'urlValidationMiddleware', persistQuery: validationStatus.persistQuery };
    const nextAction = replace(href, options);
    _.assign(payload, href);
    store.dispatch(nextAction);
  }
};

helperFunctions.validateUrl = (store, payload) => {
  
  const validationStatus = { isValid: true, shouldRedirectToStart: false, persistQuery: true };
  const state = store.getState();
  const currentRoute = _.get(payload, ['route']);
  //Check to see if current route matches any defined.  If not, then redirect to start page
  if (!_.has(ROUTES, currentRoute)) {
    helperFunctions.invalidateValidationStatus(validationStatus, true);
    helperFunctions.redirectToStart(state, store);
  } else if (currentRoute === ROUTE_CONSTANTS.NESTED_STRATEGIES) {
    helperFunctions.validateNestedStrategiesRoute(payload, state, store, validationStatus);
  } else if (currentRoute === ROUTE_CONSTANTS.RESULTS) {
    helperFunctions.validateResultsRoute(payload, state, store, validationStatus);
  }
  return validationStatus.isValid;
};

/* #endregion */

export const urlValidationMiddleware = store => next => action => {
  /********************** Url Validation Logic **********************/
  const actionSource = _.get(action, ['payload', 'location', 'state', 'source']);
  
  const urlNeedsValidation = !_.includes(VALIDATION_SOURCE_BLACKLIST, actionSource);
  
  let shouldContinue = true; 
  if (action.type === '@@router/LOCATION_CHANGE' && urlNeedsValidation) {
    shouldContinue = helperFunctions.validateUrl(store, action.payload);
  }
  if (shouldContinue) {
  
    next(action);
  }
};
export default urlValidationMiddleware;
