import { createReducer } from 'reduxsauce';
import Types from 'actions/Types';
import _ from 'lodash';

export const INITIAL_STATE = '--';

export const updateMatches = (state = INITIAL_STATE, action = {}) => {
  if (_.get(action, 'payload.ok', null)) {
    if (typeof action.payload.data.totalCount === 'number'){
      return action.payload.data.totalCount.toString();
    }
    return action.payload.data.totalCount;
  }
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.FUND_PICKS_FROM_FIDELITY_MATCHES_API_SUCCESS]: updateMatches,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const getFundPicksTotalMatches = (state) => {
  return state.fundPicksMatches
};
