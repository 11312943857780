import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QuickCriteriaClassPopup from 'components/StartPage/QuickCriteriaClassPopup';
import QuickCriteriaRiskSliderContainer from 'components/StartPage/QuickCriteriaRiskSliderContainer';
import { sorting } from 'utilities';
import Creators from 'actions/CriteriaActions';
import * as odaTracking from 'utilities/odaTracking';
import { connect } from 'react-redux';
import GlossaryDefinitionPopover from '../Shared/GlossaryDefinitionPopover';

class QuickCriteriaFundType extends Component {
  constructor() {
    super();
    this.popup = React.createRef();
    this.state = { isPopupOpen: false };
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
    document.removeEventListener('touchstart', this.handleOutsideClick, false);
  };

  handleOutsideClick = e => {
    const poperContainer = document.getElementById('glossary-definition-detail');
    if (poperContainer) {
      return;
    }

    if (e.target.className === 'class-options dropdown-arrows') {
      return;
    }

    e.stopPropagation();
    const popup = ReactDOM.findDOMNode(this.popup.current);
    if (!popup.contains(e.target)) {
      this.setState({ isPopupOpen: false });
      document.removeEventListener('mousedown', this.handleOutsideClick, false);
      document.removeEventListener('touchstart', this.handleOutsideClick, false);
    }
  };

  _openPopup = () => {
    const newState = !this.state.isPopupOpen;
    this.setState({ isPopupOpen: newState });
    if (newState) {
      document.addEventListener('mousedown', this.handleOutsideClick, false);
      document.addEventListener('touchstart', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('mousedown', this.handleOutsideClick, false);
      document.removeEventListener('touchstart', this.handleOutsideClick, false);
    }
  };

  selectionWording = () => {
    const { selectedAssetClasses, selectedCategories } = this.props;
    if (selectedAssetClasses.length > 0) {
      const assets =
        selectedAssetClasses.length === 1 ? '1 Asset Class' : `${selectedAssetClasses.length} Asset Classes`;
      const categories = selectedCategories.length === 1 ? '1 Category' : `${selectedCategories.length} Categories`;
      return `${assets}, ${categories}`;
    }
    return 'All Classes, All Categories';
  };

  render() {
    const {
      activeFundType,
      categoryRiskDisclosures,
      disclosures,
      handleMoveRiskSlider,
      riskValue,
      setActiveFundType,
      investmentTypeCriterion,
      handleSelectKeyCriteria,
      keyCriteriaSelected,
      criterion,
      selectedAssetClasses,
      selectedCategories,
    } = this.props;
    const categoryOptionsStyling = activeFundType === 'category' ? '' : 'disabled';
    const riskOptionsStyling = activeFundType === 'risk' ? '' : 'disabled';
    const options = Object.values(criterion.filterGroups.assetClass).sort(sorting.alphabetSortByProperty('label'));
    const categoryRadioSelectionStyling = activeFundType === 'category' ? '' : 'disabled-radio';
    const riskRadioSelectionStyling = activeFundType === 'risk' ? '' : 'disabled-radio';

    return (
      <div role="radiogroup" aria-label="Category Criteria" className="quick-criteria-fund-type">
        <div className={`category-radio-selection ${categoryRadioSelectionStyling}`}>
          <div className="input">
            <input
              tabIndex={0}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === 'Space') {
                  setActiveFundType('category');
                }
              }}
              name="category"
              type="radio"
              id="by-category-input"
              checked={activeFundType === 'category'}
              onClick={() => {
                setActiveFundType('category');
              }}
              readOnly
              data-tc={`${activeFundType === 'category' ? 'checked' : ''}`}
            />
            <label className="by-category-input" htmlFor="by-category-input">
              Asset Class and Category
              <span></span>
            </label>
            <GlossaryDefinitionPopover
              glossary="Asset Class and Category"
              container="quick-criteria-fund-type"
              ariaLabel="Asset Class and Category"
            />
          </div>
        </div>
        <div className={`category-criterion ${categoryOptionsStyling}`}>
          <div className="asset-class-dropdown">
            <div
              tabIndex={0}
              className="class-options dropdown-arrows"
              onClick={e => {
                this._openPopup();
                e.preventDefault();
                e.stopPropagation();
              }}
              onKeyDown={e => {
                if (e.key === 'Enter' && activeFundType === 'category') {
                  this._openPopup();
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            >
              {this.selectionWording()}
            </div>
            <QuickCriteriaClassPopup
              ref={this.popup}
              options={options}
              selectedAssetClasses={selectedAssetClasses}
              selectedCategories={selectedCategories}
              categoryRiskDisclosures={categoryRiskDisclosures}
              disclosures={disclosures}
              handleChangeAllActiveAssetClasses={this.props.handleChangeAllActiveAssetClasses}
              handleChangeAllActiveCategories={this.props.handleChangeAllActiveCategories}
              isOpen={this.state.isPopupOpen}
              showSidebar={true}
            />
          </div>
        </div>
        <div className={`risk-radio-selection ${riskRadioSelectionStyling}`}>
          <div className="input">
            <input
              tabIndex={0}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === 'Space') {
                  setActiveFundType('risk');
                }
              }}
              name="category"
              type="radio"
              id="by-risk-input"
              checked={activeFundType === 'risk'}
              onClick={() => {
                setActiveFundType('risk');
              }}
              readOnly
              data-tc={`${activeFundType === 'risk' ? 'checked' : ''}`}
            />
            <label className="by-risk-input" htmlFor="by-risk-input">
              Category by Morningstar Risk Rating
              <span></span>
            </label>
            <GlossaryDefinitionPopover
              glossary="Category by Morningstar Risk Rating"
              ariaLabel="Category by Morningstar Risk Rating"
              container="quick-criteria-fund-type"
            />
          </div>
        </div>
        <div className={`risk-criterion ${riskOptionsStyling}`}>
          <QuickCriteriaRiskSliderContainer
            investmentTypeCriterion={investmentTypeCriterion}
            handleMoveRiskSlider={handleMoveRiskSlider}
            riskValue={riskValue}
            handleSelectKeyCriteria={handleSelectKeyCriteria}
            keyCriteriaSelected={keyCriteriaSelected}
          />
        </div>
        <div className="description"></div>
        <br></br>
      </div>
    );
  }
}

QuickCriteriaFundType.propTypes = {
  /** The selected asset class section */
  activeClass: PropTypes.string.isRequired,

  /** The current active fund type. Can be category, risk, or null */
  activeFundType: PropTypes.string,

  /** A map of category risk disclosures with the asset class as a key */
  categoryRiskDisclosures: PropTypes.object.isRequired,

  /** The criterion definitions for fund type */
  criterion: PropTypes.object.isRequired,

  /** Disclosures for building out fund type descriptions from Fidelity API */
  disclosures: PropTypes.object,

  /** Handler for selecting a category within an asset class */
  handleSelectAssetClassCategory: PropTypes.func.isRequired,

  /** Handler for moving the Morningstar risk slider */
  handleMoveRiskSlider: PropTypes.func.isRequired,

  /** The value of the Morningstar risk slider */
  riskValue: PropTypes.string.isRequired,

  /** The currently selected categories */
  selectedCategories: PropTypes.array.isRequired,

  /** Callback to set the active fund type */
  setActiveFundType: PropTypes.func.isRequired,

  /** Dispatches action to track more links */
  trackMoreLink: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    trackMoreLink: moreLinkType => {
      const trackingData = odaTracking.getQuickCriteriaMoreLinkTrackingData(moreLinkType);
      dispatch(Creators.odaLogAction(trackingData));
    },
  };
};

const QuickCriteriaFundTypeContainer = connect(mapStateToProps, mapDispatchToProps)(QuickCriteriaFundType);

export default QuickCriteriaFundTypeContainer;
