import { connect } from 'react-redux';
import { getViewTab } from 'reducers/ViewTabReducer';
import { getViewTabDisplay } from 'reducers/selectors/ViewTabSelectors';
import Creators from 'actions/CriteriaActions';
import ViewTabs from 'components/ResultsView/ViewTabs';

const mapStateToProps = state => {
  return {
    activeViewTab: getViewTab(state),
    viewTabDisplay: getViewTabDisplay(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateViewTab: (label, shortName) => {
      dispatch(Creators.updateViewTab(label, shortName));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewTabs);
