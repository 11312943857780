import { connect } from 'react-redux';
import ViewAll from 'components/ViewAll';
import Creators from 'actions/CriteriaActions';
import { getAllCriteriaFilters } from 'reducers/CriteriaFiltersReducer';
import { getActiveCriteria } from 'reducers/ActiveCriteriaReducer';
import * as odaTracking from 'utilities/odaTracking';

const mapStateToProps = state => {
  return {
    activeCriteria: getActiveCriteria(state),
    currentCriteriaSelections: getAllCriteriaFilters(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateActiveCriteria: (critArray, filterGroupSelections, source = 'viewAll') => {
      dispatch(Creators.updateCriteria(critArray, filterGroupSelections, source));
    },
    updateExpandedCrit: crit => {
      dispatch(Creators.updateExpandedCrit(crit));
    },
    trackOpenViewAll: () => {
      const trackingData = odaTracking.getCriteriaMenuOpenViewAllTrackingData();
      dispatch(Creators.odaLogAction(trackingData));
    },
  };
};

const ViewAllContainer = connect(mapStateToProps, mapDispatchToProps)(ViewAll);

export default ViewAllContainer;
