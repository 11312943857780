import _ from 'lodash';
import Types from 'actions/Types';
import * as odaTracking from 'utilities/odaTracking';
import * as adobeAnalytics from 'utilities/adobeAnalytics';
import {
  CRITERIA_SOURCE,
  CLEAR_ALL_SOURCE,
  START_PAGE_VIEW_TAB_SOURCE,
  // mapFiltersToDescription,
  // getStringDescribingAllFilters,
} from 'utilities/odaUtils';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getTickersSelected } from 'reducers/FundsSelectedReducer';
import { getStartPageActiveViewTab } from 'reducers/StartPageViewTabReducer';
import { getViewTab } from 'reducers/ViewTabReducer';
// import { CRITERIA_IDS_BY_FILTER_GROUP } from 'configs/CriteriaConfig';
import { getActiveCriteria } from 'reducers/ActiveCriteriaReducer';

const logMethods = {};

// log ODA_INITIALIZE_TRACKING
logMethods[Types.ODA_INITIALIZE_TRACKING] = (state, action) => {
  odaTracking.logPageView();
};

// log ROUTER_LOCATION_CHANGED

logMethods["@@router/LOCATION_CHANGE"] = (state, action) => {
  const oldDisplayType = getDisplayType(state);
  const oldViewTab = getViewTab(state);
  const oldRoute = state.route;
  const displayType = action.payload.params.displayType;
  const viewTab = action.payload.params.viewTab;
  const route = action.payload.route;
  const product = _.isNil(action.payload.query.product) ? null : action.payload.query.product;
  if (oldRoute !== route || oldDisplayType !== displayType || oldViewTab !== viewTab) {
    // only track when something changes
    const adobeTrackingData = adobeAnalytics.getPageViewTrackingData(route, displayType, viewTab, product);
    adobeAnalytics.trackAnalytics(adobeTrackingData);
  }
};

// log UPDATE_VIEW_TAB
logMethods[Types.UPDATE_VIEW_TAB] = (state, action) => {
  const displayType = getDisplayType(state);
  const previousTab = getViewTab(state);
  if (action.viewTabLabel !== previousTab) {
    // don't track redundant tab clicks
    const trackingData = odaTracking.getViewTabTrackingData(displayType, action.viewTabLabel);
    odaTracking.logAction(trackingData);
  }
};

// log UPDATE_RESULTS_PAGE_NUM
logMethods[Types.UPDATE_RESULTS_PAGE_NUM] = (state, action) => {
  const displayType = getDisplayType(state);
  const trackingData = odaTracking.getPaginationTrackingData(displayType, action.pageNum);
  odaTracking.logAction(trackingData);
};

// log SORT_RESULTS
logMethods[Types.SORT_RESULTS] = (state, action) => {
  const displayType = getDisplayType(state);
  const trackingData = odaTracking.getSortResultsTrackingData(displayType, action.column, action.direction);
  odaTracking.logAction(trackingData);
};

// log DOWNLOAD_RESULTS_API
logMethods[Types.DOWNLOAD_RESULTS_API] = (state, action) => {
  const adobeTrackingData = adobeAnalytics.getIncludeETFTrackingData({
    "pname": state.viewTab,
    "intname":"download results"
  });
  adobeAnalytics.trackAnalytics(adobeTrackingData);
};

// log SHOW_COMPARE_VIEW
logMethods[Types.SHOW_COMPARE_VIEW] = (state, action) => {
  const displayType = getDisplayType(state);
  if (displayType === 'table') {
    // only tracking for results category (displayType === 'table')
    const tickers = _.map(action.fundsSelected, fundSelected => fundSelected.ticker);
    const trackingData = odaTracking.getResultsCompareFundsTrackingData(tickers);
    odaTracking.logAction(trackingData);
  }
};

// log UPDATE_FUNDS_SELECTED
logMethods[Types.UPDATE_FUNDS_SELECTED] = (state, action) => {
  const displayType = getDisplayType(state);
  if (displayType === 'compare') {
    // only tracking add/remove funds when on the compare page

    const oldTickers = getTickersSelected(state);
    const newTickers = _.map(action.fundsSelected, fundSelected => fundSelected.ticker);
    const addedTickers = _.difference(newTickers, oldTickers);
    const removedTickers = _.difference(oldTickers, newTickers);
    // Track added funds
    for (let i = 0; i < addedTickers.length; i++) {
      // should only add one fund at a time but incase we add multiple funds, log each ticker one at a time
      const trackingData = odaTracking.getCompareAddFundTrackingData(addedTickers[i]);
      odaTracking.logAction(trackingData);
    }
    // Track removed funds
    for (let i = 0; i < removedTickers.length; i++) {
      // should only remove one fund at a time but incase we remove multiple funds, log each ticker one at a time
      const trackingData = odaTracking.getCompareRemoveFundTrackingData(removedTickers[i]);
      odaTracking.logAction(trackingData);
    }
  }
};

// log ADD_SYMBOL_SUCCESS
logMethods[Types.ADD_SYMBOL_SUCCESS] = (state, action) => {
  const displayType = getDisplayType(state);
  // Track added funds
  if (displayType === 'compare') {
    // only tracking add/remove funds when on the compare page

    const trackingData = odaTracking.getCompareAddFundTrackingData(action.symbol);
    odaTracking.logAction(trackingData);
  }
};

// log QUICK_CRITERIA
// log STRATEGIES
logMethods[Types.QUICK_CRITERIA] = logMethods[Types.STRATEGIES] = (state, action) => {
  const previousTab = getStartPageActiveViewTab(state);
  if (action.viewTab !== previousTab) {
    // don't track redundant tab clicks
    const trackingData = odaTracking.getStartPageViewTabTrackingData(action.viewTab);
    odaTracking.logAction(trackingData);
  }
};

// log CHANGE_START_PAGE_VIEW_TAB
logMethods[Types.CHANGE_START_PAGE_VIEW_TAB] = (state, action) => {
  switch (action.source) {
    case START_PAGE_VIEW_TAB_SOURCE.QUICK_CRITERIA:
    case START_PAGE_VIEW_TAB_SOURCE.STRATEGIES: {
      const previousTab = getStartPageActiveViewTab(state);
      if (action.viewTab !== previousTab) {
        // don't track redundant tab clicks
        const trackingData = odaTracking.getStartPageViewTabTrackingData(action.viewTab);
        odaTracking.logAction(trackingData);
      }
      break;
    }
    case START_PAGE_VIEW_TAB_SOURCE.NESTED_STRATEGIES_BACK_BUTTON:
    case START_PAGE_VIEW_TAB_SOURCE.NESTED_STRATEGIES_CLOSE_BUTTON: {
      // The nested strategy back/close buttons trigger a change start page view tab action but we want to track these separately
      const trackingData = odaTracking.getStartPageStrategyGroupNavigationTrackingData(action.source);
      odaTracking.logAction(trackingData);
      break;
    }
    default:
      break;
  }
};

// log ADD_CRITERION
logMethods[Types.ADD_CRITERION] = (state, action) => {
  const critId = action.critId;
  if (action.source === CRITERIA_SOURCE.CRITERIA_MENU) {
    const trackingData = odaTracking.getCriteriaMenuAddCriterionTrackingData(critId);
    odaTracking.logAction(trackingData);
  } else if (action.source === CRITERIA_SOURCE.SEARCH_CRITERIA) {
    const trackingData = odaTracking.getCriteriaMenuSearchCriterionTrackingData(critId);
    odaTracking.logAction(trackingData);
  }
  const trackingData = odaTracking.getCriteriaAddCriterionTrackingData(critId, action.source);
  odaTracking.logAction(trackingData);

  if (critId === "investmentTypeCode") {
    const adobeTrackingData = adobeAnalytics.getIncludeETFTrackingData({pname: state.viewTab, intname: "Include ETFs"});
    adobeAnalytics.trackAnalytics(adobeTrackingData);
  }
  //track update criteria
  // add the criterion and its filters to criteriaFilters
  // const criteriaFilters = { ...state.criteriaFilters, ...action.filterGroupSelections };
  // const allSelections = getStringDescribingAllFilters(state, criteriaFilters);
  // const updateCriteriaTrackingData = odaTracking.getCriteriaUpdateCriteriaTrackingData(allSelections);
  // odaTracking.logAction(updateCriteriaTrackingData);
};

// log REMOVE_CRITERION
logMethods[Types.REMOVE_CRITERION] = (state, action) => {
  const removeCriterionTrackingData = odaTracking.getCriteriaMenuRemoveCriterionTrackingData(action.critId);
  odaTracking.logAction(removeCriterionTrackingData);

  //track update criteria
  const criteriaFilters = { ...state.criteriaFilters };
  delete criteriaFilters[action.critId]; // remove the criterion
  // const allSelections = getStringDescribingAllFilters(state, criteriaFilters);
  // const updateCriteriaTrackingData = odaTracking.getCriteriaUpdateCriteriaTrackingData(allSelections);
  // odaTracking.logAction(updateCriteriaTrackingData);
};

// log CLEAR_ALL
logMethods[Types.CLEAR_ALL] = (state, action) => {
  if (action.source === CLEAR_ALL_SOURCE.CRITERIA_MENU) {
    // Only tracking clear all for criteria menu for now
    const trackingData = odaTracking.getCriteriaMenuClearAllTrackingData();
    odaTracking.logAction(trackingData);
  }
};

// log LOAD_STRATEGY
logMethods[Types.LOAD_STRATEGY] = (state, action) => {
  const trackingData = odaTracking.getStrategiesStrategyClickedTrackingData(action.strategyId);
  odaTracking.logAction(trackingData);
};

// log VIEW_NESTED_STRATEGY
logMethods[Types.VIEW_NESTED_STRATEGY] = (state, action) => {
  const trackingData = odaTracking.getStrategiesStrategyGroupClickedTrackingData(action.strategyId);
  odaTracking.logAction(trackingData);
};

// shared helper function for:
// QUICK_CRITERIA_UPDATE_SELECTIONS
// QUICK_CRITERIA_UPDATE_ACTIVE_FUND_TYPE
// QUICK_CRITERIA_UPDATE_ASSET_CLASS

const logQuickCriteriaUpdates = (state, critId, selections) => {
  if (critId === 'category') {
    // for categories convert filter values to a string describing the filters
    // selections = mapFiltersToDescription(state, critId, selections);
  }
  if (!_.isNil(critId) && !_.isNil(selections)) {
    // don't track if nil
    const trackingData = odaTracking.getQuickCriteriaCriterionSelectedTrackingData(critId, selections);
    odaTracking.logAction(trackingData);
  }
};

// log QUICK_CRITERIA_UPDATE_SELECTIONS
logMethods[Types.QUICK_CRITERIA_UPDATE_SELECTIONS] = (state, action) => {
  const critId = action.critId;
  const selections = action.selections;
  logQuickCriteriaUpdates(state, critId, selections);
};

// log QUICK_CRITERIA_UPDATE_ACTIVE_FUND_TYPE
logMethods[Types.QUICK_CRITERIA_UPDATE_ACTIVE_FUND_TYPE] = (state, action) => {
  const critId = action.fundType;
  const selections = action.selections;
  logQuickCriteriaUpdates(state, critId, selections);
};

// log QUICK_CRITERIA_UPDATE_ASSET_CLASS
logMethods[Types.QUICK_CRITERIA_UPDATE_ASSET_CLASS] = (state, action) => {
  const critId = 'category';
  const selections = action.categories;
  logQuickCriteriaUpdates(state, critId, selections);
};

// log UPDATE_RANGE_INPUTS
logMethods[Types.UPDATE_RANGE_INPUTS] = (state, action) => {
  const filterGroupSelections = action.filterGroupSelections;
  // const critId = CRITERIA_IDS_BY_FILTER_GROUP[action.filterGroupId];

  // const filterDescription = mapFiltersToDescription(state, critId, filterGroupSelections);
  // const updateFiltersTrackingData = odaTracking.getCriteriaUpdateFiltersTrackingData(
  //   action.filterGroupId,
  //   filterDescription,
  // );
  // odaTracking.logAction(updateFiltersTrackingData);

  //track update criteria
  const criteriaFilters = { ...state.criteriaFilters };
  criteriaFilters[action.filterGroupId] = [...filterGroupSelections[action.filterGroupId]];
  // const allSelections = getStringDescribingAllFilters(state, criteriaFilters);
  // const updateCriteriaTrackingData = odaTracking.getCriteriaUpdateCriteriaTrackingData(allSelections);
  // odaTracking.logAction(updateCriteriaTrackingData);
};

// log UPDATE_FILTERS
logMethods[Types.UPDATE_FILTERS] = (state, action) => {
  // const filterGroupSelections = state.criteriaFilters[action.filterGroupId];
  // const filterUpdates = action.filterUpdates;
  // const critId = CRITERIA_IDS_BY_FILTER_GROUP[action.filterGroupId];
  // const filterDescription = mapFiltersToDescription(state, critId, filterGroupSelections, filterUpdates);
  // const updateFiltersTrackingData = odaTracking.getCriteriaUpdateFiltersTrackingData(
  //   action.filterGroupId,
  //   filterDescription,
  // );
  // odaTracking.logAction(updateFiltersTrackingData);
  //track update criteria
  // const criteriaFilters = { ...state.criteriaFilters };
  // const criteriaFilterUpdates = { [action.filterGroupId]: filterUpdates };
  // const allSelections = getStringDescribingAllFilters(state, criteriaFilters, criteriaFilterUpdates);
  // const updateCriteriaTrackingData = odaTracking.getCriteriaUpdateCriteriaTrackingData(allSelections);
  // odaTracking.logAction(updateCriteriaTrackingData);
};

// log UPDATE_CRITERIA
logMethods[Types.UPDATE_CRITERIA] = (state, action) => {
  if (state.quickCriteriaMenu.isMadLibsActive) {
    const madLibsSelections = state.quickCriteriaMenu.madLibsSelections
                              .filter(selection => selection.selected)
                              .map(selection => selection.selected);

    const delimitedSelections = `mfe|${madLibsSelections.join('^')}`
    const adobeTrackingData = adobeAnalytics.getViewMadLibsTrackingData(delimitedSelections)
    adobeAnalytics.trackAnalytics(adobeTrackingData);
  }
  // const allSelections = getStringDescribingAllFilters(state, action.filterGroupSelections);
  // const trackingData = odaTracking.getCriteriaUpdateCriteriaTrackingData(allSelections);
  // odaTracking.logAction(trackingData);

  // Track added criteria
  const oldCriteria = getActiveCriteria(state);
  const newCriteria = action.critIdArray;
  const addedCriteria = _.difference(newCriteria, oldCriteria);
  for (let i = 0; i < addedCriteria.length; i++) {
    const trackingData = odaTracking.getCriteriaAddCriterionTrackingData(addedCriteria[i], action.source);
    odaTracking.logAction(trackingData);

    if (addedCriteria[i] === "investmentTypeCode") {
      const adobeTrackingData = adobeAnalytics.getIncludeETFTrackingData({pname: state.viewTab, intname: "Include ETFs"});
      adobeAnalytics.trackAnalytics(adobeTrackingData);
    }
  }
};

// log QUICK_CRITERIA_CANCEL_MODAL
logMethods[Types.QUICK_CRITERIA_CANCEL_MODAL] = (state, action) => {
  const trackingData = odaTracking.getQuickCriteriaModalCloseModalTrackingData();
  odaTracking.logAction(trackingData);
};

// log QUICK_CRITERIA_DISPLAY_MODAL
logMethods[Types.QUICK_CRITERIA_DISPLAY_MODAL] = (state, action) => {
  const trackingData = odaTracking.getQuickCriteriaModalOpenModalTrackingData();
  odaTracking.logAction(trackingData);
};

// log ODA_LOG_ACTION
logMethods[Types.ODA_LOG_ACTION] = (state, action) => {
  odaTracking.logAction(action.data);
};

// log TRACK_ADOBE_ANALYTICS
logMethods[Types.TRACK_ADOBE_ANALYTICS] = (state, action) => {
  adobeAnalytics.trackAnalytics(action.data);
};

const analyticsMiddleware = store => next => action => {
  const logMethod = _.get(logMethods, action.type, () => {});
  const state = store.getState();
  logMethod(state, action);
  next(action);
};

export default analyticsMiddleware;
