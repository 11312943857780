import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { icons } from 'utilities';
import MadLibsSelection from './MadLibsSelection'

class MadLibsSelectionsContainer extends Component {
  constructor() {
    super();
    this.state = {
      isExpanded: true,
    };
  }

  _handleToggleExpansion = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  renderSelections = () => {
    const { madLibsSelections, madLibsAdditionalCriteria } = this.props;
    const selections = [];
    const lastIndex = madLibsSelections.length - 2;
    const madlibsSelected = madLibsSelections.filter(item => item.selected !== null).length;
    const additionalSelected = madLibsAdditionalCriteria.filter(item => item.selected === true);
    const firstIndexAdd = madLibsAdditionalCriteria.findIndex(item => item.selected === true);
    const indexAdd = madLibsAdditionalCriteria.slice().reverse().findIndex(item => item.selected === true);
    const lastIndexAdd = indexAdd >= 0 ? (madLibsAdditionalCriteria.length - 1) - indexAdd : indexAdd;
    madLibsSelections.forEach((option, i) => {
      if (option.selected) {
        const selection = option.options.filter(item => item.label === option.selected);
        if (i === 0) {
          selections.push(
            <MadLibsSelection selection={selection[0]} container='strategy-description' />
          );
        }
        else if (i === 1) {
          selections.push(
            <React.Fragment>
              <span> that </span>
              <MadLibsSelection selection={selection[0]} container='strategy-description' />
            </React.Fragment>
          );
        }
        else if (i === lastIndex && additionalSelected.length === 0) {
          selections.push(
            <React.Fragment>
              <span>, and </span>
              <MadLibsSelection selection={selection[0]} container='strategy-description' />
            </React.Fragment>
          );
        }
        else {
          selections.push(
            <React.Fragment>
              <span>, </span>
              <MadLibsSelection selection={selection[0]} container='strategy-description' />
            </React.Fragment>
          );
        }
      }
    });
    madLibsAdditionalCriteria.forEach((option, i) => {
      if (option.selected) {
        const selection = { label: option.label.toLowerCase(), options: null, criteria: null };
        if (i === firstIndexAdd && madlibsSelected === 0) {
          selections.push(
            <MadLibsSelection selection={selection} container='strategy-description' />
          );
        }
        else if (i === lastIndexAdd) {
          selections.push(
            <React.Fragment>
              <span>, and </span>
              <MadLibsSelection selection={selection} container='strategy-description' />
            </React.Fragment>
          );
        }
        else if (madlibsSelected >= 0) {
          selections.push(
            <React.Fragment>
              <span>, </span>
              <MadLibsSelection selection={selection} container='strategy-description' />
            </React.Fragment>
          );
        }
      }
    });

    return selections;
  }



  render() {
    const { madLibsMatches } = this.props;

    const { isExpanded } = this.state;
    const expanderText = isExpanded ? 'Hide Details' : 'Show Details';
    const expanderIconSrc = isExpanded ? icons.arrowDown : icons.arrowRight;
    const strategyIconSrc = icons['checkeredFlag'];
    const strategyIcon = !_.isNil(strategyIconSrc) ? (
      <img src={strategyIconSrc} alt="Icon representing this fund strategy" />
    ) : null;

    const strategyInfoStyle = isExpanded ? 'expanded' : 'collapsed';
    const strategyDescriptionStyle = isExpanded ? '' : 'off';
    const madLibsMatchesDisplay= madLibsMatches>1 ? madLibsMatches+' funds': madLibsMatches===1 ? madLibsMatches+' fund': ' funds';
    //const description = etfIsActive ? this.state.etfDescription : strategy.description;
    return (
      <div data-tc='madlibs-selections-container' className={`strategy-info ${strategyInfoStyle}`}>
        <div className="expander-toggle-button" onClick={() => this._handleToggleExpansion()}>
          <img src={expanderIconSrc} alt="Icon to expand or collapse strategies description" />
          <button className="as-link">{expanderText}</button>
        </div>
        <div className={`strategy-description ${strategyDescriptionStyle}`}>
          {strategyIcon}
          <div className="madlibs-results">
            <span>Displaying {madLibsMatchesDisplay} from </span>
            {this.renderSelections()} <br />
            <br />
            <div>Use the menu to the left to view and modify the selected criteria</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    madLibsSelections: state.quickCriteriaMenu.madLibsSelections,
    madLibsAdditionalCriteria: state.quickCriteriaMenu.madLibsAdditionalCriteria,
    totalMatches: state.totalMatches
  };
};

export default connect(mapStateToProps)(MadLibsSelectionsContainer);
