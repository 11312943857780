const allocationHighestRisk = {
  label: "highest risk/potential return",
  criteria: {
    category: ["XM"],
    activeFundType: 'category',
  }
}

const allocationHigherRisk = {
  label: "higher risk/potential return",
  criteria: {
    category: ["AL"],
    activeFundType: 'category',
  }
}

const allocationBalancedRisk = {
  label: "balanced risk/potential return",
  criteria: {
    category: ["MA"],
    activeFundType: 'category',
  }
}

const allocationLowerRisk = {
  label: "lower risk/potential return",
  criteria: {
    category: ["CA"],
    activeFundType: 'category',
  }
}

const allocationLowestRisk = {
  label: "lowest risk/potential return",
  criteria: {
    category: ["XY"],
    activeFundType: 'category',
  }
}

const moneyMarket = {
  label: "is a smart place to park my cash",
  criteria: {
    category: ["TF", "TM", "XT"],
    activeFundType: "category",
    assetClass: ["MM"]
  }
}

const moneyMarketEnd = {
  label: "is a smart place to park my cash",
  criteria: {
    category: ["TF", "TM", "XT"],
    activeFundType: "category",
    expenseRatio: ["0.0", "0.5"],
    assetClass: ["MM"]
  }
}

const lowFee = {
  label: "has low expenses",
  key: "lowFee",
  criteria: {
    expenses: ["1"],
  }
}

const highlyRated = {
  label: "is highly rated by Morningstar",
  criteria: {
    mStarRating: ["4", "5"]
  }
}

const activeManagement = {
  label: 'has a manager that attempts to beat the benchmark',
  criteria: {
    indexFundOnly: 'Active'
  }
}

const passiveManagement = {
  label: 'has a manager that attempts to match the benchmark (Index funds)',
  criteria: {
    indexFundOnly: 'Index'
  }
}

const targetDate5 = {
  label: 'my target withdrawal date is 5 years away',
  criteria: {
    category: ["TG"],
    activeFundType: 'category'
  }
}

const targetDate10 = {
  label: 'my target withdrawal date is 10 years away',
  criteria: {
    category: ["TH"],
    activeFundType: 'category'
  }
}

const targetDate15 = {
  label: 'my target withdrawal date is 15 years away',
  criteria: {
    category: ["TI"],
    activeFundType: 'category'
  }
}

const targetDate20 = {
  label: 'my target withdrawal date is 20 years away',
  criteria: {
    category: ["TJ"],
    activeFundType: 'category'
  }
}

const targetDate25 = {
  label: 'my target withdrawal date is 25 years away',
  criteria: {
    category: ["TK"],
    activeFundType: 'category'
  }
}

const targetDate30 = {
  label: 'my target withdrawal date is 30 years away',
  criteria: {
    category: ["TN"],
    activeFundType: 'category'
  }
}

const targetDate35 = {
  label: 'my target withdrawal date is 35 years away',
  criteria: {
    category: ["TL"],
    activeFundType: 'category',
  }
}

const targetDate40 = {
  label: 'my target withdrawal date is 40 years away',
  criteria: {
    category: ["XQ"],
    activeFundType: 'category',
  }
}

const targetDate = {
  label: 'automatically changes from potentially higher returns to lower risk as my target withdrawal date approaches',
  options: [targetDate5, targetDate10, targetDate15, targetDate20, targetDate25, targetDate30, targetDate35, targetDate40],
  criteria: {
    category: ["TG", "TH", "TI", "TJ", "TK", "TN", "TL", "XQ"],
    activeFundType: 'category',
  }
}

const predeterminedRisk = {
  label: 'is invested according to the predetermined risk level I choose',
  options: [allocationHighestRisk, allocationHigherRisk, allocationBalancedRisk, allocationLowerRisk, allocationLowestRisk],
  criteria: {
    category: ["XM", "AL", "MA", "CA", "XY"],
    activeFundType: 'category',
  }
}

const thirtyDayYield = {
  label: 'has paid a good 30-day yield',
  options: [lowFee, highlyRated],
  criteria: {
    assetClass: ["TBND"],
    category: ["GL", "PI", "RR", "MU", "GS", "IB", "WH", "CS", "CL", "CI", "XP", "NT", "TW", "IP", "GI", "UB", "EB", "BL", "HY"],
    activeFundType: "category",
    secYield30Day: ["2", "GT"]
  }
}

const thirtyDayYieldEnd = {
  label: 'has paid a good 30-day yield',
  criteria: {
    category: ["GL", "PI", "RR", "MU", "GS", "IB", "WH", "CS", "CL", "CI", "XP", "NT", "TW", "IP", "GI", "UB", "EB", "BL", "HY"],
    activeFundType: "category",
    assetClass: ["TBND"],
    secYield30Day: ["2", "GT"],
    expenses: ["1"]
  }
}

const inflationBond = {
  label: 'could help fight inflation',
  criteria: {
    category: ["IP"],
    activeFundType: "category",
    assetClass: ["TBND"]
  }
}

const inflationBondEnd = {
  label: 'could help fight inflation',
  criteria: {
    category: ["IP"],
    activeFundType: "category",
    assetClass: ["TBND"],
    expenses: ["1"]
  }
}

const potentialHighRisk = {
  label: "offers potentially higher returns (potentially riskier)",
  options: [activeManagement, passiveManagement, highlyRated],
  criteria: {
    category: ["LB", "LG", "LV", "MB", "MG", "MV", "SB", "SG", "SV"],
    activeFundType: "category",
    assetClass: ["DSTK"],
    returns: ["4", "5"],
    sharpRatio3Year: ["0.75", "GT"]
  }
};

const potentialHighRiskEnd = {
  label: "offers potentially higher returns (potentially riskier)",
  criteria: {
    category: ["LB", "LG", "LV", "MB", "MG", "MV", "SB", "SG", "SV"],
    activeFundType: "category",
    assetClass: ["DSTK"],
    returns: ["4", "5"],
    sharpRatio3Year: ["0.75", "GT"],
    expenses: ["1"]
  }
};

const diversifiedRisk = {
  label: "is one fund that contains a fully diversified portfolio",
  options: [targetDate, predeterminedRisk],
  criteria: {
    assetClass: ["BAL"],
    category: ["TG", "TH", "TI", "TJ", "TK", "TN", "TL", "XQ", "XM", "AL", "MA", "CA", "XY"],
    activeFundType: 'category'
  }
};

const lowRisk = {
  label: "focuses on relatively safer investments",
  options: [moneyMarket, thirtyDayYield, inflationBond],
  criteria: {
    category: ["TF", "TM", "XT", "GL", "PI", "RR", "MU", "GS", "IB", "WH", "CS", "CL", "CI", "XP", "NT", "TW", "IP", "GI", "UB", "EB", "BL", "HY"],
    activeFundType: "category",
    assetClass: ["TBND", "MM"]
  }
};

const lowRiskLowExpenses = {
  label: "focuses on relatively safer investments",
  options: [moneyMarketEnd, thirtyDayYieldEnd, inflationBondEnd],
  criteria: {
    category: ["TF", "TM", "XT", "GL", "PI", "RR", "MU", "GS", "IB", "WH", "CS", "CL", "CI", "XP", "NT", "TW", "IP", "GI", "UB", "EB", "BL", "HY"],
    activeFundType: "category",
    assetClass: ["TBND", "MM"]
  }
};

const hasLowFees = {
  //used to be "has low fees"
  label: 'has low expenses',
  options: [potentialHighRiskEnd, lowRiskLowExpenses],
  criteria: {
    expenses: ["1"]
  }
}

const data = [
  {
    label: "Fidelity (no fees)",
    options: [potentialHighRisk, diversifiedRisk, lowRisk, hasLowFees],
    criteria: {
      keyCriteria: [
        "fidelityFundOnly",
        "minimumInvestment",
        "ntf"
      ]
    },
  },
  {
    label: "any provider that doesn't charge a fee",
    options: [potentialHighRisk, diversifiedRisk, lowRisk, hasLowFees],
    criteria: {
      keyCriteria: [
        "minimumInvestment",
        "ntf"
      ]
    }
  },
  {
    label: "any provider",
    options: [potentialHighRisk, diversifiedRisk, lowRisk, hasLowFees],
    criteria: {
      keyCriteria: [
        "minimumInvestment"
      ]
    }
  }
]

export default data;