import Types from 'actions/Types';
import { getRouterPersistentQueries } from 'reducers/selectors/RouterSelectors';
import { HAS_FIDELITY_FUNDS_TABLE } from 'configs/AppFeatureConfig';
import { getBusinessChannel } from 'reducers/BusinessChannelReducer';
import _ from 'lodash';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { loginUtils, sagaUtils } from 'utilities';
import { ENDPOINTS } from 'services/endpoints';
import {mockDataInjection} from "../tests/lib/mockDataSetInjection";

export default api => {
  // helper functions
  function* addPolicyCode(action, params) {
    if (!action.policyCode) {
      const persistentQueries = yield select(getRouterPersistentQueries);
      params.policyCode = persistentQueries.product;
    } else {
      params.policyCode = action.policyCode
    }
    return params
  }

  function includeLeveragedAndInverseFundsWorkaround(params) {
    if (params.includeLeveragedAndInverseFunds === "Y") {
      // in the match count api includeLeveragedAndInverseFunds: 'Y' counts only leveragedAndInverseFunds
      // this is quirky but it is a deliberate decision that fidelity made so that a count diff is
      // displayed for this criteria. Setting to null means leveragedAndInverseFunds and non leveragedAndInverseFunds
      // get counted which is our intention here
      params.includeLeveragedAndInverseFunds = null;
    }
    return params
  }

  function* createCountApiParams(action, filterCriteriaName, searchParams = {}) {
    const businessChannel = yield select(getBusinessChannel);
    const standardParams = { ...searchParams, filterCriteriaName, businessChannel };
    yield addPolicyCode(action, standardParams)
    includeLeveragedAndInverseFundsWorkaround(standardParams)
    const additiveParams = {
      'investmentTypeCode': { investmentTypeCode: 'ETF' },
      'includeLeveragedFunds/includeInverseFunds': { includeLeveragedAndInverseFunds: 'Y' },
      'openToNewInvestors': { openToNewInvestors: 'NEW,CLOSED' }
    };
    const parameters = {...standardParams, ...(additiveParams[filterCriteriaName] || {})};
    // filter out falsey params
    return _.pickBy(parameters, _.identity);
  }

  function* createTotalCountApiParams(action, searchParams = {}) {
    const businessChannel = yield select(getBusinessChannel);
    const parameters = { ...searchParams, businessChannel };
    yield addPolicyCode(action, parameters)
    // filter out falsey params
    return _.pickBy(parameters, _.identity);
  }

  function* createPricingModelTotalCountApiParams(policyCode) {
    const businessChannel = yield select(getBusinessChannel);
    const parameters = { businessChannel, policyCode };
    // filter out falsey params
    return _.pickBy(parameters, _.identity);
  }

  // api methods

  function* fetchCriteriaMatches(action) {
    const { searchParams, currentCrit: filterCriteriaName } = action;
    const parameters = yield* createCountApiParams(action, filterCriteriaName, searchParams);
	const passingParams = sagaUtils.makeNTFParamsFromNtfTransFee(parameters);
    const response = yield call(api.postData, ENDPOINTS.MATCH_FILTER_CRITERIA_COUNT, passingParams);
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.MATCH_FILTER_CRITERIA_COUNT, passingParams);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}
    if (response.ok) {
      yield put({ type: Types.CRITERIA_MATCHES_API_SUCCESS, payload: response, critId: filterCriteriaName });
    } else {
      yield put({ type: Types.CRITERIA_MATCHES_API_ERROR, payload: response, critId: filterCriteriaName });
    }
  }

  function* fetchTotalMatches(action) {
    const parameters = yield* createTotalCountApiParams(action, action.searchParams);
	const passingParams = sagaUtils.makeNTFParamsFromNtfTransFee(parameters);
    const response = yield call(api.postData, ENDPOINTS.MATCH_FILTER_CRITERIA_TOTAL_COUNT, passingParams);
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.MATCH_FILTER_CRITERIA_TOTAL_COUNT, passingParams);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}
    if (response.ok) {
      yield put({ type: Types.TOTAL_MATCHES_API_SUCCESS, payload: response, critId: action.currentCrit });
    } else {
      yield put({ type: Types.TOTAL_MATCHES_API_ERROR, payload: response, critId: action.currentCrit });
    }
  }

  function* fetchFidelityTotalMatches(action) {
    // These params should always include fidelityFundOnly: "F" because we want the count of funds that meet these criteria and are fidelity funds
    const parameters = yield* createTotalCountApiParams(action, action.searchParams);
    const fidelityOnlyParams = {...parameters, 'fidelityFundOnly': "F"}
	const passingParams = sagaUtils.makeNTFParamsFromNtfTransFee(fidelityOnlyParams);
    const response = yield call(api.postData, ENDPOINTS.MATCH_FILTER_CRITERIA_TOTAL_COUNT, passingParams );
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.MATCH_FILTER_CRITERIA_TOTAL_COUNT, passingParams);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}
    if (response.ok) {
      yield put({ type: Types.FIDELITY_MATCHES_API_SUCCESS, payload: response });
    } else {
      yield put({ type: Types.FIDELITY_MATCHES_API_ERROR, payload: response });
    }
  }

  function* fetchQuickCriteriaMatches(action) {
    const parameters = yield* createTotalCountApiParams(action, action.searchParams);
	const passingParams = sagaUtils.makeNTFParamsFromNtfTransFee(parameters);
    const response = yield call(api.postData, ENDPOINTS.MATCH_FILTER_CRITERIA_TOTAL_COUNT, passingParams);
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.MATCH_FILTER_CRITERIA_TOTAL_COUNT, passingParams);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}
    if (response.ok) {
      yield put({ type: Types.QUICK_CRITERIA_MATCHES_API_SUCCESS, payload: response });
    } else {
      yield put({ type: Types.QUICK_CRITERIA_MATCHES_API_ERROR, payload: response });
    }
  }

  function* fetchFundPicksMatches(action) {
    const parameters = yield* createTotalCountApiParams(action, action.searchParams);
	const passingParams = sagaUtils.makeNTFParamsFromNtfTransFee(parameters);
    const response = yield call(api.postData, ENDPOINTS.MATCH_FILTER_CRITERIA_TOTAL_COUNT, passingParams);
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.MATCH_FILTER_CRITERIA_TOTAL_COUNT, passingParams);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}
    if (response.ok) {
      yield put({ type: Types.FUND_PICKS_FROM_FIDELITY_MATCHES_API_SUCCESS, payload: response });
    } else {
      yield put({ type: Types.FUND_PICKS_FROM_FIDELITY_MATCHES_API_ERROR, payload: response });
    }
  }
  function* fetchFundPicksLoading(action) {
    const parameters = yield* createCountApiParams(action, 'category', action.searchParams);
	const passingParams = sagaUtils.makeNTFParamsFromNtfTransFee(parameters);
    const response = yield call(api.postData, ENDPOINTS.MATCH_FILTER_CRITERIA_COUNT, passingParams);
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.MATCH_FILTER_CRITERIA_COUNT, passingParams);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}
    if (response.ok) {
      yield put({ type: Types.FUND_PICKS_FROM_FIDELITY_LOADING_API_SUCCESS, payload: response, params: action.searchParams });
    } else {
      yield put({ type: Types.FUND_PICKS_FROM_FIDELITY_LOADING_API_ERROR, payload: response, params: action.searchParams });
    }
  }
  
  function* fetchStrategyMatches(action) {
    const parameters = yield* createTotalCountApiParams(action, action.searchParams);
	const passingParams = sagaUtils.makeNTFParamsFromNtfTransFee(parameters);
    const response = yield call(api.postData, ENDPOINTS.MATCH_FILTER_CRITERIA_TOTAL_COUNT, passingParams);
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.MATCH_FILTER_CRITERIA_TOTAL_COUNT, passingParams);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}	
    if (response.ok) {
      yield put({ type: Types.STRATEGY_MATCHES_API_SUCCESS, payload: response, strategyId: action.strategyId });
    } else {
      yield put({ type: Types.STRATEGY_MATCHES_API_ERROR, payload: response, strategyId: action.strategyId });
    }
  }

  function* fetchPricingModelMatches(action) {
    const parameters = yield* createPricingModelTotalCountApiParams(action.policyCode);
	const passingParams = sagaUtils.makeNTFParamsFromNtfTransFee(parameters);
    const response = yield call(api.postData, ENDPOINTS.MATCH_FILTER_CRITERIA_TOTAL_COUNT, passingParams);
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.MATCH_FILTER_CRITERIA_TOTAL_COUNT, passingParams);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}	
    if (response.ok) {
      yield put({ type: Types.PRICING_MODEL_MATCHES_API_SUCCESS, payload: response, policyCode: action.policyCode });
    } else {
      yield put({ type: Types.PRICING_MODEL_MATCHES_API_ERROR, payload: response, policyCode: action.policyCode });
    }
  }

  function* watcher() {
    // handle login expiration
    loginUtils.redirectToLoginIfSessionExpired();
    loginUtils.resetLoginExpiration();

    yield takeEvery(Types.CRITERIA_MATCHES_API, fetchCriteriaMatches);
    yield takeEvery(Types.TOTAL_MATCHES_API, fetchTotalMatches);
    if (HAS_FIDELITY_FUNDS_TABLE) {
      // only fetch fidelity total matches if we have fidelity funds table
      yield takeEvery(Types.FUND_SEARCH_API, fetchFidelityTotalMatches);
    }
    yield takeEvery(Types.QUICK_CRITERIA_MATCHES_API, fetchQuickCriteriaMatches);
    yield takeEvery(Types.STRATEGY_MATCHES_API, fetchStrategyMatches);
    yield takeEvery(Types.PRICING_MODEL_MATCHES_API, fetchPricingModelMatches);
    yield takeEvery(Types.FUND_PICKS_FROM_FIDELITY_MATCHES_API, fetchFundPicksMatches);
    yield takeEvery(Types.FUND_PICKS_FROM_FIDELITY_LOADING_API, fetchFundPicksLoading);
  }

  return {
    watcher,
    fetchCriteriaMatches,
    fetchTotalMatches,
  };
};
