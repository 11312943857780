import _ from 'lodash';
import { RESULT_TEXT, RESULT_TEXT_SINGULAR } from 'configs/AppFeatureConfig';
const format = {};

format.formatNumResults = (totalMatches, currentResultsPageNum) => {
  if (totalMatches === 0 || _.isNil(totalMatches)) {
    return `Showing 0 ${RESULT_TEXT}`;
  } else if (totalMatches === 1) {
    return `Showing 1 ${RESULT_TEXT_SINGULAR}`;
  } else if (totalMatches <= 100) {
    return `Showing ${totalMatches} ${RESULT_TEXT}`;
  }
  const minResultsRange = (currentResultsPageNum - 1) * 100 + 1;
  const maxResultsRange = Math.min(currentResultsPageNum * 100, totalMatches);
  return `Showing ${minResultsRange} - ${maxResultsRange} of ${totalMatches} ${RESULT_TEXT}`;
};

format.camelCaseToWords = text => {
  const words = text.split(/(?=[A-Z])/);
  const capitalizedWords = words.map(word =>
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  const capitalizedStr = capitalizedWords.join(' ');

  return capitalizedStr;
}

format.transformToSnakeCase = text => {
  if (text === 'undefined' || undefined) return;
  text = text.toLowerCase();
  text = text.replace(/[^0-9a-zA-Z ]+/g, '');
  text = text.replace(/ /g, '-');
  text = text.replace(/-{2,}/g, '-');

  return text;
};

format.formatNumberWithDecimalPlaceRange = (value, minDecimalPlaces, maxDecimalPlaces) => {
  const valAsNum = _.toNumber(value);

  if (_.isNaN(valAsNum)) return '--';
  // keep decimal places within minDecimalPlaces to maxDecimalPlaces
  let numDecimalPlaces = (valAsNum.toString().split('.')[1] || []).length;
  numDecimalPlaces = Math.min(Math.max(numDecimalPlaces, minDecimalPlaces), maxDecimalPlaces);
  return valAsNum.toFixed(numDecimalPlaces);
};

format.formatInputValue = (value, type) => {
  value = value.trim().replace(/[^0-9.-]/gi, '');
  const valAsNum = _.toNumber(value);
  if (_.isNaN(valAsNum) || value === '') {
    return '';
  }
  const isInput = true;
  const magnitude = format.formatMagnitude(value, type, isInput);
  switch (type) {
    case 'M':
      return magnitude + 'M';
    case 'K':
      return magnitude + 'K';
    case 'D':
    case 'I':
    case 'integer':
      return magnitude;
    case 'millionsUSD':
      return '$' + magnitude + 'M';
    case 'thousandsUSD':
      return '$' + magnitude + 'K';
    case 'decimal3USD':
    case 'usd':
      return '$' + magnitude;
    case 'years':
    case 'decimalYears':
      const suffix = valAsNum === 1 ? ' year' : ' years';
      return magnitude + suffix;
    case 'percentage':
      return magnitude + '%';
    default:
      return magnitude;
  }
};

format.formatMagnitude = (value, type, isInput = false) => {
  const valAsNum = _.toNumber(value);
  if (_.isNaN(valAsNum)) {
    return '--';
  }

  switch (type) {
    case 'M':
    case 'millionsUSD':
      return (valAsNum / 1000000).toString();
    case 'K':
    case 'thousandsUSD':
      return (valAsNum / 1000).toString();
    case 'D':
      return valAsNum.toFixed(2);
    case 'decimal3USD':
      return format.formatNumberWithDecimalPlaceRange(valAsNum, 2, 3);
    case 'usd':
      // we want to display the usd with format $10K when >= $10,000
      // but when formatting our input, we want to show the whole thing e.g $10,000.00
      if (valAsNum >= 10000 && !isInput) {
        return (valAsNum / 1000).toString();
      }
      return valAsNum.toFixed(2);
    case 'I':
    case 'integer':
    case 'years':
      return valAsNum.toFixed(0);
    case 'percentage':
    case 'decimalYears':
      return format.formatNumberWithDecimalPlaceRange(valAsNum, 0, 2);
    default:
      return value;
  }
};

format.unformatMagnitude = (value, type) => {
  value = value.trim().replace(/[^0-9.-]/gi, '');

  const valAsNum = _.toNumber(value);

  if (_.isNaN(valAsNum)) {
    return null;
  }

  switch (type) {
    case 'M':
    case 'millionsUSD':
      return (valAsNum * 1000000).toString();
    case 'K':
    case 'thousandsUSD':
      return (valAsNum * 1000).toString();
    case 'D':
    case 'usd':
      return valAsNum.toFixed(2);
    case 'decimal3USD':
      return format.formatNumberWithDecimalPlaceRange(valAsNum, 2, 3);
    case 'I':
    case 'integer':
    case 'years':
      return valAsNum.toFixed(0);
    case 'percentage':
    case 'decimalYears':
      return format.formatNumberWithDecimalPlaceRange(valAsNum, 0, 2);
    default:
      return value;
  }
};

export default format;
