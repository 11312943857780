import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import { format, icons } from 'utilities';
import { getSustainableInvestmentDisclosureContent } from 'reducers/ApplicationDisclosuresReducer';
import { getEmploysExclusionsDisclosureContent } from 'reducers/ApplicationDisclosuresReducer';
import Modal from 'components/Shared/Modal';
import SelectAllCriteria from '../Shared/SelectAllCriteria';

const getDefaultSelectionText = filtersSelected => {
  const firstTwo = filtersSelected.slice(0, 2);
  let selectionText = firstTwo.join(', ');
  if (filtersSelected.length > 2) {
    selectionText += ` & ${filtersSelected.length - 2} more...`;
  }
  return selectionText;
};

const formatMinToMaxText = (minVal = '--', maxVal = '--', formatType) => {
  switch (formatType) {
    case 'percentage':
      return `${minVal}% - ${maxVal}%`;
    case 'years':
    case 'decimalYears':
      const minValFormatted = format.formatMagnitude(minVal, formatType);
      const maxValFormatted = format.formatMagnitude(maxVal, formatType);
      const minYearText = _.toNumber(minValFormatted) === 1 ? 'year' : 'years';
      const maxYearText = _.toNumber(maxValFormatted) === 1 ? 'year' : 'years';
      return `${minValFormatted} ${minYearText} - ${maxValFormatted} ${maxYearText}`;
    case 'millionsUSD':
      return `$${format.formatMagnitude(minVal, 'M')}M - $${format.formatMagnitude(maxVal, 'M')}M`;
    case 'thousandsUSD':
      return `$${format.formatMagnitude(minVal, 'K')}K - $${format.formatMagnitude(maxVal, 'K')}K`;
    case 'decimal3USD':
      return `$${format.formatMagnitude(minVal, 'decimal3USD')} - $${format.formatMagnitude(maxVal, 'decimal3USD')}`;
    case 'usd':
      const minValSuffix = minVal >= 10000 ? 'K' : '';
      const maxValSuffix = maxVal >= 10000 ? 'K' : '';
      const minValString = format.formatMagnitude(minVal, formatType) + minValSuffix;
      const maxValString = format.formatMagnitude(maxVal, formatType) + maxValSuffix;
      return `$${minValString} - $${maxValString}`;
    case 'integer':
      return `${format.formatMagnitude(minVal, 'I')} - ${format.formatMagnitude(maxVal, 'I')}`;
    default:
      return `${minVal} - ${maxVal}`;
  }
};

const formatMaxOnlyText = (maxVal = '--', formatType) => {
  switch (formatType) {
    case 'percentage':
      return `Below ${maxVal}%`;
    case 'years':
    case 'decimalYears':
      const maxValFormatted = format.formatMagnitude(maxVal, formatType);
      const yearText = _.toNumber(maxValFormatted) === 1 ? 'year' : 'years';
      return `Below ${maxValFormatted} ${yearText}`;
    case 'millionsUSD':
      return `Below $${format.formatMagnitude(maxVal, 'M')}M`;
    case 'thousandsUSD':
      return `Below $${format.formatMagnitude(maxVal, 'K')}K`;
    case 'decimal3USD':
      return `Below $${format.formatMagnitude(maxVal, 'decimal3USD')}`;
    case 'usd':
      const maxValSuffix = maxVal >= 10000 ? 'K' : '';
      return `Below $${format.formatMagnitude(maxVal, formatType)}${maxValSuffix}`;
    case 'integer':
      return `Below ${format.formatMagnitude(maxVal, 'I')}`;
    default:
      return `Below ${maxVal}`;
  }
};

const formatMinOnlyText = (minVal = '--', formatType) => {
  switch (formatType) {
    case 'percentage':
      return `Above ${minVal}%`;
    case 'years':
    case 'decimalYears':
      const minValFormatted = format.formatMagnitude(minVal, formatType);
      const yearText = _.toNumber(minValFormatted) === 1 ? 'year' : 'years';
      return `Above ${minValFormatted} ${yearText}`;
    case 'millionsUSD':
      return `Above $${format.formatMagnitude(minVal, 'M')}M`;
    case 'thousandsUSD':
      return `Above $${format.formatMagnitude(minVal, 'K')}K`;
    case 'decimal3USD':
      return `Above $${format.formatMagnitude(minVal, 'decimal3USD')}`;
    case 'usd':
      const minValSuffix = minVal >= 10000 ? 'K' : '';
      return `Above $${format.formatMagnitude(minVal, formatType)}${minValSuffix}`;
    case 'integer':
      return `Above ${format.formatMagnitude(minVal, 'I')}`;
    default:
      return `Above ${minVal}`;
  }
};

const formatRangeTextFromSelectedFilters = (filtersSelected, formatType) => {
  if (filtersSelected.includes('GT') && filtersSelected.includes('LS')) {
    return 'None selected';
  } else if (filtersSelected.includes('GT')) {
    return formatMinOnlyText(_.first(filtersSelected), formatType);
  } else if (filtersSelected.includes('LS')) {
    return formatMaxOnlyText(_.last(filtersSelected), formatType);
  } else {
    return formatMinToMaxText(_.first(filtersSelected), _.last(filtersSelected), formatType);
  }
};

const formatRangeText = (minFilter, maxFilter, filtersSelected, formatType) => {
  if (_.isNil(minFilter) || _.isNil(maxFilter)) {
    return formatRangeTextFromSelectedFilters(filtersSelected, formatType);
  } else if (!_.isNil(minFilter.min) && !_.isNil(maxFilter.max)) {
    return formatMinToMaxText(minFilter.min, maxFilter.max, formatType);
  } else if (_.isNil(minFilter.min) && !_.isNil(maxFilter.max)) {
    return formatMaxOnlyText(maxFilter.max, formatType);
  } else if (_.isNil(maxFilter.max) && !_.isNil(minFilter.min)) {
    return formatMinOnlyText(minFilter.min, formatType);
  } else {
    return 'None selected';
  }
};

const getMultiSelectSelectionText = (filtersSelected, flattenedFilterList, formatType) => {
  const sortedSelections = _.chain(flattenedFilterList)
    .filter(filter => {
      return filtersSelected.includes(filter.label);
    })
    .orderBy(filter => {
      if (!filter.min && filter.min !== 0) {
        return Number.NEGATIVE_INFINITY;
      }
      return parseFloat(filter.min);
    })
    .value();

  const minFilter = _.first(sortedSelections);
  const maxFilter = _.last(sortedSelections);
  return formatRangeText(minFilter, maxFilter, filtersSelected, formatType);
};

const generateSelectionText = (criteriaType, filtersSelected, filterGroups, formatType, id) => {
  const flattenedFilterList = _.flatMap(filterGroups, filterGroup => {
    return _.flatMap(filterGroup, filter => _.get(filter, ['categories'], filter));
  });
  const flattenedFiltersSelected = _.flatMap(filtersSelected, (filterGroup, filterGroupId) => {
    if (filterGroupId === 'assetClass') return []; // don't display selected asset classes
    return filterGroup;
  });
  const numFilters = flattenedFilterList.length;
  // if (criteriaType==="fundType" && filtersSelected && filtersSelected['assetClass'] && filtersSelected['category'] && filterGroups && filterGroups['assetClass'] && filterGroups['category']){
  //   if(filtersSelected['assetClass'].length===filterGroups['assetClass'].length && filtersSelected['category'].length===filterGroups['category'].length){
  //     return 'All selected';
  //   }
  // }
  if (numFilters === 1) {
    return flattenedFiltersSelected.length === 1 ? flattenedFilterList[0].label : 'None selected';
  }

  if (!flattenedFiltersSelected || (flattenedFiltersSelected && !flattenedFiltersSelected.length)) {
    return 'None selected';
  }

  if (flattenedFiltersSelected.length === numFilters) {
    return 'All selected';
  }

  switch (criteriaType) {
    case 'multiSelect':
      return getMultiSelectSelectionText(flattenedFiltersSelected, flattenedFilterList, formatType);
    default:
      return getDefaultSelectionText(flattenedFiltersSelected);
  }
};

class ActiveCriterionHeaderContainer extends Component {
  constructor() {
    super();
    this.state = { isModalOpen: false };
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleOnKeyDown = e => {
    if (e.key === 'Enter') {
      this.props.toggleExpansion();
    }
  };

  renderESGDiscolosure = label => {
    if (label === 'Sustainable Investment') {
      return this.props.sustainableInvestmentDisclosure;
    } else if (label === 'Employs Exclusions') {
      return this.props.employsExclusionsDisclosureContent;
    }
  };

  _renderHelpPopup = helpText => {
    if (!_.isNil(helpText)) {
      return (
        <span>
          <a
            tabIndex={0}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                this.handleOpenModal();
              }
            }}
            onClick={this.handleOpenModal}
            className="active-criterion-help-button"
          >
            <img src={icons.helpIcon} alt="Help Button" />
          </a>
          <Modal isOpen={this.state.isModalOpen} handleCloseModal={this.handleCloseModal}>
            <span dangerouslySetInnerHTML={{ __html: helpText }} />
          </Modal>
        </span>
      );
    }
    return null;
  };

  moveFocusNext = e => {
    if (e.key === 'Enter') {
      this.props.removeCriterion();
      const activeCriterionId = e.target.id.split('-')[0];
      //find next active-criterion-label-text
      const currentActiveCriterion = document.getElementById(activeCriterionId);
      const nextActiveCriterion = currentActiveCriterion.parentElement.nextSibling;
      if (nextActiveCriterion !== null) {
        const nextTabOrder = nextActiveCriterion.firstChild.firstChild.firstChild;
        nextTabOrder.focus();
      } else {
        const search = document.getElementById('search');
        search.focus();
      }
    }
  };

  render() {
    const {
      isExpanded,
      label,
      sublabel,
      id,
      matches,
      criteriaType,
      filterGroups,
      filtersSelected,
      formatType,
      removeCriterion,
      subGroupLabel,
      toggleExpansion,
      icon,
      helpText,
    } = this.props;

    let matchCountDisplay = null;
    let filtersMessage = null;
    if (!isExpanded) {
      matchCountDisplay = (
        <div className="active-criterion-match-count">
          <span>{matches}</span>
        </div>
      );
      filtersMessage = (
        <span className="active-criterion-selection-text">
          {generateSelectionText(criteriaType, filtersSelected, filterGroups, formatType, id)}
        </span>
      );
    }

    const labelText = _.isEmpty(subGroupLabel) ? label : `${subGroupLabel} (${label})`;

    const displayStyle = format.transformToSnakeCase(id);

    const expandedStyling = isExpanded ? 'expanded' : 'collapsed';

    const iconClass = !_.isNil(icon) ? format.transformToSnakeCase(icon) : '';

    const characterImage = icon === 'dollar' ? <span class="dollarSignIcon">$</span> : null;
    const image =
      icon && icons[icon] ? <img src={icons[icon]} alt={`Icon: ${icon}`} className={iconClass} /> : characterImage;

    const subheader =
      !_.isNil(sublabel) && isExpanded ? (
        <div className={`active-criterion-header ${displayStyle}`} key="active-criterion-subheader">
          <div className={`active-criterion-sublabel`} dangerouslySetInnerHTML={{ __html: sublabel }} />
        </div>
      ) : null;

    const esgSubheader = () => {
      const labelKey = label.replace(/^\w/, c => c.toLowerCase()).replace(/\s+/g, '');

      return isExpanded && (label === 'Sustainable Investment' || label === 'Employs Exclusions') ? (
        <SelectAllCriteria
          onFiltersUpdated={this.props.onFiltersUpdated}
          filtersSelected={filtersSelected[labelKey]}
          filters={filterGroups[labelKey]}
        />
      ) : null;
    };

    const closeId = displayStyle.concat('-remove');

    return [
      <div className={`active-criterion-header ${displayStyle}`} id={`${displayStyle}`} key="active-criterion-header">
        <div className={`active-criterion-label ${expandedStyling}`}>
          <span
            tabIndex={0}
            className="active-criterion-label-text"
            onKeyDown={e => this.handleOnKeyDown(e)}
            onClick={toggleExpansion}
          >
            {image} {<span className="label-text" dangerouslySetInnerHTML={{ __html: labelText }} />}
          </span>
          {label === 'Sustainable Investment' || label === 'Employs Exclusions'
            ? this._renderHelpPopup(this.renderESGDiscolosure(label))
            : this._renderHelpPopup(helpText)}
          {filtersMessage}
        </div>
        {matchCountDisplay}
        <div
          tabIndex={0}
          onKeyDown={e => this.moveFocusNext(e)}
          id={`${closeId}`}
          className={`remove-active-criterion ${expandedStyling}`}
        >
          <img src={icons.greyX} alt="Close Icon" onClick={removeCriterion} />
        </div>
      </div>,
      subheader,
      esgSubheader(),
    ];
  }
}

ActiveCriterionHeaderContainer.propTypes = {
  /** Name of Criterion */
  label: PropTypes.string.isRequired,

  /** Text that goes in the help pop up */
  helpText: PropTypes.string,

  /** ID of Criterion */
  id: PropTypes.string.isRequired,

  /** Denotes whether the criterion is expanded */
  isExpanded: PropTypes.bool.isRequired,

  /** Number of matches */
  matches: PropTypes.number,

  /** The criteria type (e.g. checkbox, multiselect, etc.) */
  criteriaType: PropTypes.string.isRequired,

  /** Labels of all selected filters for this criterion */
  filtersSelected: PropTypes.object.isRequired,

  /** Array of all filter definition objects for this criterion   */
  filterGroups: PropTypes.object.isRequired,

  /** A formatting type used to help display selection text for multi-select criteria */
  formatType: PropTypes.string,

  /** Handles removing a criterion from the active criteria list (by clicking x-icon) */
  removeCriterion: PropTypes.func.isRequired,

  /** Optional subGroupLabel of a criterion */
  subGroupLabel: PropTypes.string,

  /** Handles expanding and collapsing criterion display */
  toggleExpansion: PropTypes.func.isRequired,

  /** Image to displayed next to the criteria label (for certain criteria only) */
  icon: PropTypes.string,
};

const makeMapStateToProps = () => {
  const mapStateToProps = state => {
    return {
      sustainableInvestmentDisclosure: getSustainableInvestmentDisclosureContent(state),
      employsExclusionsDisclosureContent: getEmploysExclusionsDisclosureContent(state),
    };
  };
  return mapStateToProps;
};

const ActiveCriterionHeader = connect(makeMapStateToProps)(ActiveCriterionHeaderContainer);

export default ActiveCriterionHeader;
