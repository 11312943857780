import _ from 'lodash';
import { criteriaUtils } from 'utilities';
import Types from 'actions/Types';
import { FUND_IDS_FIELD } from 'configs/AppFeatureConfig';
import { FILTER_GROUP_IDS, CRITERIA_IDS_BY_FILTER_GROUP } from 'configs/CriteriaConfig';
import { getFilterGroupsFromQueryObj, getCriterionDefinition } from 'utilities/criteriaUtils';
import { TOGGLE_GROUP_CONFIG } from 'configs/ResultConfig';
import { getCriteriaDefinitions } from 'reducers/selectors/CriteriaSelectors';
import { getIsMobile } from 'reducers/MobileStatusReducer';

const scrollToCriteriaMenu = isMobile => {
  const critMenu = document.getElementsByClassName('criteria-menu')[0];
  let offsetTop = 0;
  if (isMobile) {
    offsetTop = critMenu.getBoundingClientRect().top;
  } else {
    offsetTop = critMenu.getBoundingClientRect().top + document.documentElement.scrollTop;
  }

  window.scroll({
    top: offsetTop,
    left: 0,
    behavior: 'smooth',
  });
};

const scrollToSelectedCriterion = () => {
  const critMenuHeader = document.getElementsByClassName('criteria-menu-header')[0];
  const critMenu = document.getElementsByClassName('open-criteria-menu-content')[0];
  const criteria = document.getElementsByClassName('active-criterion');
  var offsetCrit = 0;

  if (criteria[0] !== undefined && critMenuHeader !== undefined) {
    if (criteria.length === 1) {
      offsetCrit = 0;
    } else {
      offsetCrit = criteria[0].offsetHeight * criteria.length + critMenuHeader.offsetHeight;
    }
  }

  critMenu.scroll({
    top: offsetCrit,
    left: 0,
    behavior: 'smooth',
  });
};

const critFilterMethods = {};

critFilterMethods[Types.ADD_CRITERION] = store => {
  let isMobile = true;
  if (store !== undefined) {
    isMobile = getIsMobile(store.getState());
  }
  if (isMobile) {
    scrollToSelectedCriterion();
  } else {
    scrollToCriteriaMenu(isMobile);
  }
};

critFilterMethods[Types.REMOVE_CRITERION] = (store, action) => {
  const state = store.getState();
  const criteriaDefinitions = getCriteriaDefinitions(state);
  const critDef = getCriterionDefinition(criteriaDefinitions, action.critId);
  action.filterGroups = critDef.filterGroups;
};

critFilterMethods[Types.UPDATE_FILTERS] = (store, action) => {
  const state = store.getState();
  const criteriaDefinitions = getCriteriaDefinitions(state);
  const critId = CRITERIA_IDS_BY_FILTER_GROUP[action.filterGroupId];
  const critDef = getCriterionDefinition(criteriaDefinitions, critId);
  const currentSelections = _.pick(state.criteriaFilters, _.keys(critDef.filterGroups));
  const serviceVals = criteriaUtils.mapFiltersToServiceVals(
    criteriaDefinitions,
    critId,
    currentSelections,
    action.filterUpdates,
    action.filterGroupId,
  );
  action.filterGroupSelections = serviceVals;
};

critFilterMethods[Types.UPDATE_CRITERIA] = critFilterMethods[Types.LOAD_STRATEGY] = (store, action) => {
  const state = store.getState();
  const { critIdArray, filterGroupSelections } = action;

  if (filterGroupSelections && _.size(filterGroupSelections) > 0) {
    action.filterGroupSelections = filterGroupSelections;
  } else {
    action.filterGroupSelections = _.reduce(
      critIdArray,
      (result, critId) => {
        const isAlreadyAdded = _.has(state.criteriaFilters, critId);
        if (!isAlreadyAdded) {
          result[critId] = [];
        } else {
          result[critId] = state.criteriaFilters[critId];
        }
        return result;
      },
      {},
    );
  }
  const isMobile = getIsMobile(store.getState());
  scrollToCriteriaMenu(isMobile);
};

critFilterMethods["@@router/LOCATION_CHANGE"] = (store, action) => {
  const state = store.getState();
  const resultToggleGroupKeys = _.keys(state.resultToggleGroup);
  const rawQs = action.payload.query;
  const rawQsKeys = _.keys(rawQs);

  const filterGroups = getFilterGroupsFromQueryObj(rawQs, FILTER_GROUP_IDS, [FUND_IDS_FIELD]);
  if (!_.isNil(rawQs.strategy)) {
    action.strategyInfo = rawQs.strategy;
  }
  if (_.intersection(rawQsKeys, resultToggleGroupKeys).length) {
    const modifiedToggleGroups = _.pickBy(TOGGLE_GROUP_CONFIG, (toggleGroup, groupKey) => {
      if (!_.includes(rawQsKeys, groupKey)) {
        return false;
      }
      const groupResultIds = _.map(toggleGroup.resultList, result => result.id);
      return _.includes(groupResultIds, rawQs[groupKey]);
    });
    action.toggleGroupSelections = _.mapValues(modifiedToggleGroups, (toggleGroup, groupKey) => {
      const resultId = rawQs[groupKey];
      return _.find(toggleGroup.resultList, result => result.id === resultId);
    });
  }
  if (!_.isNil(rawQs[FUND_IDS_FIELD])) {
    action.fundsSelected = rawQs[FUND_IDS_FIELD];
  }
  if (!_.isNil(rawQs.order)) {
    action.critOrder = rawQs.order.split(',');
    
  } 
  action.filterGroupSelections = { ...filterGroups };
};

const critFilterMiddleware = store => next => action => {
  const critFilterMethod = _.get(critFilterMethods, action.type);
  if (_.isFunction(critFilterMethod)) {
    critFilterMethod(store, action);
  }
  next(action);
};

export default critFilterMiddleware;
