import React from 'react';
import PropTypes from 'prop-types';
import { icons } from 'utilities';

const CriteriaGroupHeader = ({ label, count, toggleExpansion, isExpanded }) => {
  const icon = isExpanded ? icons.collapseImg : icons.expandImg;
  return (
    <div
      tabIndex={0}
      onKeyUp={e => {
        if (e.key === 'Enter') {
          toggleExpansion();
        }
      }}
      className="criteria-group-header"
      aria-label='Accordion'
      onClick={toggleExpansion}
    >
      <div className="expander" onClick={toggleExpansion}>
        <img src={icon} alt="Expand/Collapse Icon" />
      </div>
      <div className="criteria-group-label">
        <span className="criteria-group-label-text" onClick={toggleExpansion}>
          {label}
        </span>
        <span className="criteria-group-count" onClick={toggleExpansion}>
          {' '}
          ({count} Criteria)
        </span>
      </div>
    </div>
  );
};

CriteriaGroupHeader.propTypes = {
  /** Name of criteria group */
  label: PropTypes.string.isRequired,

  /** Number of criteria in group */
  count: PropTypes.number,

  /** Function from parent container to toggle expansion */
  toggleExpansion: PropTypes.func.isRequired,

  isExpanded: PropTypes.bool.isRequired,
};

export default CriteriaGroupHeader;
