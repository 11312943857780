import React, { useState } from 'react';
import QuickCriteriaResultsFooter from './QuickCriteriaResultsFooterContainer';
import AdditionalCriteriaContainer from './MadLibsAdditionalCriteriaContainer';
import Creators from 'actions/CriteriaActions';
import Dropdown from './MadLibsDropdown';
import { connect } from 'react-redux';
import {
  getIsMadLibsActive,
  getMadLibsSelections,
  getMadLibsAdditionalCriteria,
  getMadLibsParsedSelections,
} from '../../reducers/QuickCriteriaMenuReducer';
import { getLearnMoreDisclosureContent } from 'reducers/ApplicationDisclosuresReducer';
import * as odaTracking from 'utilities/odaTracking';
import { CRITERIA_SOURCE } from 'utilities/odaUtils';
import _, { cloneDeep } from 'lodash';
import apiUtils from '../../utilities/apiUtils';

const MadLibs = ({
  selections,
  additionalCriteria,
  isMadLibsActive,
  updateMadLibsSelections,
  updateMadLibsAdditionalCriteria,
  parsedSelections,
  viewResults,
  learnMoreDisclosure
}) => {
  const [showLearnModel, setShowLearnModel] = useState(false);
  const buttonActive = isMadLibsActive && selections.filter(selection => selection.selected);

  const handleChange = (index, pick) => {
    const { label } = pick;
    if (selections[index].selected !== label) {
      if (index + 1 < selections.length) {
        const prevState = selections.splice(0, index + 1);
        const copiedState = _.cloneDeep(prevState);

        copiedState[index].selected = label;

        const newlySelected = copiedState[index].options.find(x => x.label === label);
        const newState = {
          options: newlySelected.options,
          selected: null,
        };

        updateMadLibsSelections([...copiedState, newState]);
      } else {
        const newlySelected = selections[index].options.find(x => x.label === label);
        const newState = {
          options: newlySelected.options,
          selected: null,
        };
        const copiedState = _.cloneDeep(selections);

        copiedState[index].selected = label;

        updateMadLibsSelections([...copiedState, newState]);
      }
    }
  };

  const labels = [
    ['I am looking for a fund from', null],
    ['that', 'and'],
    ['and', null],
    ['and', null],
  ];

  const renderDropdowns = () => {
    return labels.map((label, idx) => {
      const opts = selections[idx] ? selections[idx].options : [];
      const s = selections[idx] ? selections[idx].selected : null;
      return (
        <Dropdown
          key={idx}
          idx={idx}
          label={label}
          selection={s}
          options={opts}
          handleChange={x => handleChange(idx, x)}
        />
      );
    });
  };


  const handleSelectViewResults = () => {
    let { critIdArray, filterGroupSelections } = cloneDeep(parsedSelections);
    const source = CRITERIA_SOURCE.QUICK_CRITERIA_MENU;
    if (critIdArray.find(e=>e==='sociallyResponsible')){
      critIdArray=critIdArray.filter(e=> e!=='sociallyResponsible');
      delete filterGroupSelections['sociallyResponsible'];
      critIdArray.push('sustainableInvestment');
      critIdArray.push('employsExclusions');
      const sustainableInvestment = apiUtils.getSociallyResponsibleAttributesValues('sustainableInvestment').split(",");
      const employsExclusions = apiUtils.getSociallyResponsibleAttributesValues('employsExclusions').split(",");
      filterGroupSelections['sustainableInvestment']=sustainableInvestment;
      filterGroupSelections['employsExclusions']=employsExclusions;
    }
    viewResults(critIdArray, filterGroupSelections, source);
  };

  const onLearnModel = (open) => {
    setShowLearnModel(open);
  }

  return (
    <div className="madlibs-container" id="madlibs">
      <h2 className="center">Don't Know Where to Begin?</h2>
      <p className="center">
        Fill in the prompts below to quickly search through the 10,000+ funds available to find the right investment for
        you.<button className='as-link' onClick={() => {
          setShowLearnModel(true);
        }}>See the criteria underlying each choice.</button>
      </p>
      <form>
        <div className="row">{renderDropdowns()}</div>
      </form>
      <AdditionalCriteriaContainer
        updateMadLibsAdditionalCriteria={updateMadLibsAdditionalCriteria}
        additionalCriteria={additionalCriteria}
      />
      <div className="quick-criteria-footer">
        <QuickCriteriaResultsFooter
          handleSelectViewResults={handleSelectViewResults}
          section="madlibs"
          learnMoreDisclosure={learnMoreDisclosure}
          hasLearnMore={true}
          hasSelections={buttonActive}
          showLearnModel={showLearnModel}
          onLearnModel={onLearnModel} />
      </div>
    </div>
  );
};


const mapStateToProps = state => {
  return {
    isMadLibsActive: getIsMadLibsActive(state),
    selections: getMadLibsSelections(state),
    additionalCriteria: getMadLibsAdditionalCriteria(state),
    parsedSelections: getMadLibsParsedSelections(state),
    learnMoreDisclosure: getLearnMoreDisclosureContent(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMadLibsSelections: selections => {
      dispatch(Creators.quickCriteriaUpdateMadLibsSelections(selections));
    },
    updateMadLibsAdditionalCriteria: additionalCriteria => {
      dispatch(Creators.quickCriteriaUpdateMadLibsAdditionalCriteria(additionalCriteria));
    },
    viewResults: (critIdArray = [], criteriaSelections, source) => {
      dispatch(Creators.updateCriteria(critIdArray, criteriaSelections, source));
      if (source === CRITERIA_SOURCE.QUICK_CRITERIA) {
        const trackingData = odaTracking.getQuickCriteriaViewResultsTrackingData(critIdArray);
        dispatch(Creators.odaLogAction(trackingData));
      } else if (source === CRITERIA_SOURCE.QUICK_CRITERIA_MODAL) {
        const trackingData = odaTracking.getQuickCriteriaModalViewResultsTrackingData(critIdArray);
        dispatch(Creators.odaLogAction(trackingData));
      }
    }
  };
};

const MadLibsContainer = connect(mapStateToProps, mapDispatchToProps)(MadLibs);

export default MadLibsContainer;
export { MadLibs };
