import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AlphabetSelector from 'components/ActiveCriterion/AlphabetSelector';
import CheckboxFilter from 'components/ActiveCriterion/CheckboxFilter';
import FundFamilySubheader from 'components/ActiveCriterion/FundFamilySubheader';
import _ from 'lodash';

class FundFamilyInterface extends Component {
  constructor(props) {
    super(props);

    this.state = { activeSection: 'A' };
  }

  onSelectSection = section => {
    this.setState({ activeSection: section });
  };

  handleClickCheckbox = (label, isChecked) => {
    this.props.onFiltersUpdated({ [label]: isChecked });
  };

  _renderSubheader = () => {
    return (
      <FundFamilySubheader
        allLabels={this.allLabels}
        filtersSelected={this.props.filtersSelected}
        onFiltersUpdated={this.props.onFiltersUpdated}
      />
    );
  };

  _parseFilterData = filters => {
    this.validSections = new Set();
    this.allLabels = [];
    this.filtersBySection = {};

    filters.forEach(filter => {
      const fundFamilyLabel = filter.label;

      this.allLabels.push(fundFamilyLabel);
      this._buildValidSections(filter);
      this._buildFiltersBySection(filter);
    });
  };

  _buildValidSections = filter => {
    const section = this._getSectionFromChar(filter.label[0]);
    this.validSections.add(section);
  };

  _buildFiltersBySection = filter => {
    let key = this._getSectionFromChar(filter.label[0]);

    if (this.filtersBySection[key]) {
      this.filtersBySection[key].push(filter);
    } else {
      this.filtersBySection[key] = [];
      this.filtersBySection[key].push(filter);
    }
  };

  _buildSelectedSections = () => {
    const selectedSections = new Set();

    this.props.filtersSelected.forEach(filterLabel => {
      if (filterLabel && filterLabel[0]){
        const section = this._getSectionFromChar(filterLabel[0]);
        selectedSections.add(section);
      }
    });

    return selectedSections;
  };

  _getSectionFromChar = char => {
    const radix = 10;
    const charAsInt = Number.parseInt(char, radix);

    if (Number.isInteger(charAsInt)) {
      return '#';
    } else {
      return char.toUpperCase();
    }
  };

  _renderActiveSectionFilters = () => {
    const activeSectionFilters = (() => {
      if (_.get(this.props, ['criterion', 'isSinglePage'])) {
        return this.props.filters;
      }

      if (_.has(this.filtersBySection, this.state.activeSection)) {
        return this.filtersBySection[this.state.activeSection];
      }
      const firstKey = _.first(_.keys(this.filtersBySection).sort());
      this.setState({ activeSection: firstKey });
      return this.filtersBySection[firstKey];
    })();

    const filters = activeSectionFilters.map(filter => {
      const isChecked = this.props.filtersSelected.includes(filter.label);

      return (
        <CheckboxFilter
          key={filter.value}
          index={filter.value}
          isChecked={isChecked}
          filter={filter}
          handleClick={this.handleClickCheckbox}
        />
      );
    });

    return <ul>{filters}</ul>;
  };

  render() {
    const { filters } = this.props;
    const isSinglePage = _.get(this.props, ['criterion', 'isSinglePage'], false);
    this._parseFilterData(filters); //TODO: Moved this here so that matches vals would show up.  Need to make sure this isn't a performance issue
    const activeSectionFilters = this._renderActiveSectionFilters();
    const selectedSections = this._buildSelectedSections();
    const subHeader = isSinglePage ? null : this._renderSubheader();
    const alphabetSelector = isSinglePage ? null : (
      <AlphabetSelector
        activeSection={this.state.activeSection}
        validSections={Array.from(this.validSections)}
        selectedSections={Array.from(selectedSections)}
        onSelectSection={this.onSelectSection}
      />
    );

    return (
      <div className="fund-family-interface">
        {subHeader}
        {alphabetSelector}
        <div className="filter-list">{activeSectionFilters}</div>
      </div>
    );
  }
}

FundFamilyInterface.propTypes = {
  /** Array of all fund family filter options */
  filters: PropTypes.array.isRequired,

  /** Redux action that updates store with diff of changed filter */
  onFiltersUpdated: PropTypes.func.isRequired,

  /** Array of strings representing the different fund families selected by the user */
  filtersSelected: PropTypes.array.isRequired,

  criterion: PropTypes.object.isRequired,
};

export default FundFamilyInterface;
