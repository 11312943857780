import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ViewAllHeader from 'components/ViewAll/ViewAllHeader';
import ViewAllCriteriaGroup from 'components/ViewAll/ViewAllCriteriaGroup';
import ViewAllFooter from 'components/ViewAll/ViewAllFooter';
import Modal from 'components/Shared/Modal';
import { CRITERIA_BY_GROUP } from 'configs/CriteriaConfig';

class ViewAllModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCriteria: [...props.activeCriteria],
    };
  }

  handleApplyCriteria = e => {
    const { updateActiveCriteria, toggleModal } = this.props;

    e.preventDefault();
    updateActiveCriteria(this.state.selectedCriteria);
    toggleModal();
  };

  handleCancel = () => {
    this.setState({ selectedCriteria: [...this.props.activeCriteria] });

    this.props.toggleModal();
  };

  toggleCriterionSelection = (criterionId, isSelected) => {
    if (isSelected) {
      this._addCriterion(criterionId);
    } else {
      this._removeCriterion(criterionId);
    }
  };

  _addCriterion = criterionId => {
    this.setState({ selectedCriteria: [...this.state.selectedCriteria, criterionId] });
  };

  _removeCriterion = criterionId => {
    this.setState({
      selectedCriteria: this.state.selectedCriteria.filter(crit => {
        return crit !== criterionId;
      }),
    });
  };

  _renderAllGroups = () => {
    const renderedGroups = [];

    _.forEach(CRITERIA_BY_GROUP, group => {
      const renderedGroup = this._renderIndividualGroup(group);
      renderedGroups.push(renderedGroup);
    });

    return (
      <div id="view-all-criteria-groups">
        <div className="column-container">{renderedGroups}</div>
      </div>
    );
  };

  _renderIndividualGroup = group => {
    return (
      <ViewAllCriteriaGroup
        key={group.id}
        groupLabel={group.label}
        criteriaData={group.criteriaMenuItemList}
        selectedCriteria={this.state.selectedCriteria}
        activeCriteria={this.props.activeCriteria}
        toggleCriterionSelection={this.toggleCriterionSelection}
      />
    );
  };

  componentDidUpdate = prevProps => {
    if (!_.isEqual(this.props, prevProps)) {
      this.setState({
        selectedCriteria: [...this.props.activeCriteria],
      });
    }
  };

  render() {
    const viewAllGroups = this._renderAllGroups();

    return (
      <Modal isOpen={this.props.isModalOpen} classNames="view-all-modal" handleCloseModal={this.handleCancel}>
        <ViewAllHeader />

        {viewAllGroups}
        <ViewAllFooter
          handleCancel={this.handleCancel}
          handleApplyCriteria={this.handleApplyCriteria}
          selectedCriteria={this.state.selectedCriteria}
        />
      </Modal>
    );
  }
}

ViewAllModal.propTypes = {
  /** Array of all currently selected criteria's Ids */
  activeCriteria: PropTypes.array.isRequired,

  /** Handles closing the view all modal when the user clicks outside of the modal area */
  handleOutsideClick: PropTypes.func.isRequired,

  /** Handles modal display */
  toggleModal: PropTypes.func.isRequired,

  /** This updates redux state with selections from view all menu */
  updateActiveCriteria: PropTypes.func.isRequired,
};

export default ViewAllModal;
