import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Creators from 'actions/CriteriaActions';
import { CRITERIA_DICTIONARY, ALL_CRITERIA, CRITERIA_IDS_BY_FILTER_GROUP } from 'configs/CriteriaConfig';
import { getActiveCriteriaDictionary } from 'reducers/selectors/CriteriaSelectors';
import ViewAllContainer from 'containers/ViewAllContainer';
import SearchBarContainer from 'containers/SearchBarContainer';
import { CRITERIA_SOURCE } from 'utilities/odaUtils';
import { getDefaultFilterValues } from 'utilities/criteriaUtils';

class CriteriaSelectionMenuHeader extends Component {
  handleSelectSuggestion = suggestion => {
    const filterGroupId = CRITERIA_DICTIONARY[suggestion];
    const critId = CRITERIA_IDS_BY_FILTER_GROUP[filterGroupId];
    const filterGroupSelections = (() => {
      if (suggestion === 'Fund Type (by Risk)') {
        // special case because fund type doesn't define defaultFilterValues for Fund Type (by Risk)
        return { risk: [] };
      }
      return getDefaultFilterValues(ALL_CRITERIA, CRITERIA_IDS_BY_FILTER_GROUP, critId);
    })();
    const source = CRITERIA_SOURCE.SEARCH_CRITERIA;
    this.props.onCritSelected(critId, filterGroupSelections, source);
  };

  render() {
    const suggestions = Object.keys(CRITERIA_DICTIONARY);
    const disabledSuggestions = Object.keys(this.props.activeCriteriaDict);

    return (
      <div className="criteria-selection-menu-header">
        <div className="title">Add Criteria:</div>
        <div className="selection-content">
          <label for="search" class="search-label">Search:</label>
          <SearchBarContainer
            suggestions={suggestions}
            disabledSuggestions={disabledSuggestions}
            onSelectSuggestion={this.handleSelectSuggestion}
            placeholder="Search Criteria..."
            name="search"
            id="search"
            ariaLabel="search"
          />
          <div className="separator-text">OR</div>
          <ViewAllContainer />
        </div>
      </div>
    );
  }
}

CriteriaSelectionMenuHeader.propTypes = {
  /** A dictionary of all active criteria labels with their corresponding ids as the value */
  activeCriteriaDict: PropTypes.object,

  /** Function that dispatches redux action to select the chosen criterion*/
  onCritSelected: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    activeCriteriaDict: getActiveCriteriaDictionary(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCritSelected: (crit, defaultFilterGroupSelections, source) => {
      dispatch(Creators.addCriterion(crit, defaultFilterGroupSelections, source));
    },
  };
};

const CriteriaSelectionMenuHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(CriteriaSelectionMenuHeader);

export default CriteriaSelectionMenuHeaderContainer;
