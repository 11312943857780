/*
localStorage is used for storing data in sessionStorage
when sessionStorage is unavailable the data is just stored in this._data
this is currently being used for storing user preference from the QuickCriteriaResultsModalContainer
*/

import _ from 'lodash';

class LocalStorage {
  constructor() {
    if (!this.storageAvailable()) {
      this._data = {};
      console.log('sessionStorage not available, using internal store');
    }
  }

  getItem(key) {
    if (_.isNil(this._data)) {
      const storedData = sessionStorage.getItem(key);
      if (storedData) {
        try {
          return JSON.parse(storedData);
        } catch (e) {}
      }
    }
    return _.get(this, ['_data', key]);
  }

  setItem(key, valueHash) {
    let dataToStore;

    if (_.isNil(this._data)) {
      try {
        dataToStore = JSON.stringify(valueHash);
        sessionStorage.setItem(key, dataToStore);
        return;
      } catch (e) {
        this._data = {};
      }
    }

    this._data[key] = dataToStore;
  }

  sessionStore(key, valueHash) {
    if (_.isNil(key)) return null;

    if (_.isNil(valueHash)) {
      return this.getItem(key);
    } else {
      this.setItem(key, valueHash);
    }
    return null;
  }

  sessionStoreDelete(keyToDelete) {
    if (_.isNil(this._data)) {
      sessionStorage.removeItem(keyToDelete);
    } else {
      delete this._data[keyToDelete];
    }
  }

  sessionStoreClearAll() {
    if (_.isNil(this._data)) {
      sessionStorage.clear();
    } else {
      this._data = {};
    }
  }

  sessionStoreAll() {
    if (_.isNil(this._data)) {
      const all = {};
      for (let i = 0; i < sessionStorage.length; i++) {
        const currentKey = sessionStorage.key(i);
        all[currentKey] = JSON.parse(sessionStorage.getItem(currentKey));
      }
      return all;
    } else {
      return _.extend({}, this._data);
    }
  }

  sessionStoreKeys() {
    const keys = [];
    for (let i = 0; i < sessionStorage.length; i++) {
      keys[i] = sessionStorage.key(i);
    }
    return keys;
  }

  storageAvailable() {
    try {
      const x = '__storage_test__';
      sessionStorage.setItem(x, x);
      sessionStorage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  }
}

const localStorage = new LocalStorage();
export default localStorage;
