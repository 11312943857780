import { createReducer } from 'reduxsauce';
import screenerConfig from 'screenerConfig';

export const INITIAL_STATE = screenerConfig.businessChannel;

const ACTION_HANDLERS = {};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

// ---- Selectors ----

export const getBusinessChannel = state => state.businessChannel;
