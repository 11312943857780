import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { icons } from 'utilities';
import { connect } from 'react-redux';
import {
  getExpandablePageDisclosuresText,
  getNonExpandablePageDisclosuresText,
  getAfterExpandablePageDisclosuresText,
} from 'reducers/ApplicationDisclosuresReducer';
import Creators from 'actions/CriteriaActions';
import { HAS_TERMS_OF_USE_DISCLOSURE } from 'configs/AppFeatureConfig';

export class Disclosures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisclosuresHidden: true,
    };
    props.fetchDisclosures();
  }

  toggleDisclosuresHidden() {
    this.setState({
      isDisclosuresHidden: !this.state.isDisclosuresHidden,
    });
  }

  _renderExpandCollapseIcon = isDisclosuresHidden => {
    let iconToRender;
    let altTag;

    if (isDisclosuresHidden) {
      iconToRender = icons.expandDisclosures;
      altTag = 'Expand Disclosures';
    } else {
      iconToRender = icons.collapseDisclosures;
      altTag = 'Collapse Disclosures';
    }

    return <img src={iconToRender} alt={altTag} />;
  };

  _renderExpandableDisclosures = () => {
    return (
      <div className="disclosures">
        <span dangerouslySetInnerHTML={{ __html: this.props.expandableDisclosures }} />
      </div>
    );
  };

  _renderNonExpandableDisclosures = () => {
    return <span dangerouslySetInnerHTML={{ __html: this.props.nonExpandableDisclosures }} />;
  };

  _renderAfterExpandableDisclosures = () => {
    return <span dangerouslySetInnerHTML={{ __html: this.props.afterExpendableDisclosures }} />;
  };

  _renderTermsOfUseDisclosure = () => {
    return HAS_TERMS_OF_USE_DISCLOSURE ? (
      <p>
        Content and data provided by various third parties and Fidelity &ndash;{' '}
        <a href="https://www.fidelity.com/terms-of-use" target="_blank" rel="noopener noreferrer">
          Terms of Use
        </a>
      </p>
    ) : null;
  };

  _renderExpandButton = () => {
    if (!this.props.expandableDisclosures) return null;
    return (
      <button className="expand-collapse-link as-link" onClick={this.toggleDisclosuresHidden.bind(this)}>
        {this._renderExpandCollapseIcon(this.state.isDisclosuresHidden)}
        <span>Additional Important Information: Screener</span>
      </button>
    );
  };

  render() {
    return (
      <div className="disclosures-container">
        {this._renderNonExpandableDisclosures()}
        {this._renderExpandButton()}
        {!this.state.isDisclosuresHidden && this._renderExpandableDisclosures()}
        {this._renderAfterExpandableDisclosures()}
        {this._renderTermsOfUseDisclosure()}
      </div>
    );
  }
}

Disclosures.propTypes = {
  /** Redux action to grab application disclosures from Fidelity API */
  fetchDisclosures: PropTypes.func.isRequired,
  /** A HTML string for our expandable disclosures */
  expandableDisclosures: PropTypes.string.isRequired,
  /** A HTML string for our non-expandable disclosures */
  nonExpandableDisclosures: PropTypes.string.isRequired,
  /** A HTML string for our after expandable disclosures */
  afterExpendableDisclosures: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    expandableDisclosures: getExpandablePageDisclosuresText(state),
    nonExpandableDisclosures: getNonExpandablePageDisclosuresText(state),
    afterExpendableDisclosures: getAfterExpandablePageDisclosuresText(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDisclosures: () => {
      dispatch(Creators.fundApplicationDisclosureApi());
      dispatch(Creators.disclosureDisclosuresApi());
      dispatch(Creators.disclosureLabelSuppressionsApi());
    },
  };
};

export const DisclosuresContainer = connect(mapStateToProps, mapDispatchToProps)(Disclosures);
export default DisclosuresContainer;
