import { createReducer } from 'reduxsauce';
import Types from 'actions/Types';
import { CRITERIA_IDS_BY_FILTER_GROUP } from 'configs/CriteriaConfig';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { getActiveCriteria } from 'reducers/ActiveCriteriaReducer';

// the initial state of this reducer
export const INITIAL_STATE = {};

export const removeCriterion = (state = INITIAL_STATE, action = {}) => {
  const critObjs = _.omitBy(state, (matchesData, critId) => {
    return critId === action.critId;
  });
  return critObjs;
};

export const clearAll = (state = INITIAL_STATE, action = {}) => {
  return INITIAL_STATE;
};

export const updateMatches = (state = INITIAL_STATE, action = {}) => {
  return { ...state, [action.critId]: action.payload.data.totalCount };
};

export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
  //if route update came from middleware, then no need to update state again
  if (action.payload.location.state && action.payload.location.state.source === 'routerMiddleware') {
    return state;
  }
  /**
   * When the ROUTER_LOCATION_CHANGED action is dispatched as a result of URL navigation
   * (browser back/forward, reloading the page or loading URL directly) then clear out all
   * current matches data becuase new values will be added when the API calls return
   */
  return clearAll(state, action);
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.REMOVE_CRITERION]: removeCriterion,
  [Types.TOTAL_MATCHES_API_SUCCESS]: updateMatches,
  [Types.CLEAR_ALL]: clearAll,
  "@@router/LOCATION_CHANGE": routerLocationChanged,
};

export default createReducer(INITIAL_STATE, HANDLERS);

const getCriteriaFilters = state => state.criteriaFilters;

const getFilterGroupId = (state, props) => {
  if (props.criterion.id === 'fundType') {
    return _.has(getCriteriaFilters(state), 'risk') ? 'risk' : 'category';
  }
  return props.criterion.id;
};

export const getTotalMatches = state => state.totalMatches;

export const getTotalMatchesForCriterion = createSelector(
  [getTotalMatches, getFilterGroupId],
  (totalMatches, filterGroupId) => {
    return totalMatches[filterGroupId];
  },
);

export const getOverallTotalMatches = createSelector(
  [getTotalMatches, getActiveCriteria, getCriteriaFilters],
  (totalMatches, activeCriteria, criteriaFilters) => {
    const lastCritId = _.last(activeCriteria);
    const lastFilterGroupId = _.findLast(
      _.keys(criteriaFilters),
      filterGroupId => CRITERIA_IDS_BY_FILTER_GROUP[filterGroupId] === lastCritId,
    );

    return _.get(totalMatches, lastFilterGroupId, null);
  },
);
