import _ from 'lodash';
import Creators from 'actions/CriteriaActions';
import Types from 'actions/Types';
import { ROUTE_CONSTANTS } from 'configs/AppFeatureConfig';
import { getQuickCriteriaParsedSelections } from 'reducers/QuickCriteriaMenuReducer';
import { getRouterRoute } from 'reducers/selectors/RouterSelectors';
import { criteriaUtils, localStorage } from 'utilities';
import { CRITERIA_SOURCE } from 'utilities/odaUtils';

const determineIfQuickCriteriaHasSelections = quickCriteriaState => {
  const { activeFundType, selections } = quickCriteriaState;
  let hasSelections = false;
  _.each(selections, (value, key) => {
    if (key === 'category' || key === 'risk') {
      if (!_.isEmpty(activeFundType)) {
        hasSelections = true;
      }
    } else {
      if (!_.isEmpty(value)) {
        hasSelections = true;
      }
    }
  });

  return hasSelections;
};

const quickCriteriaMiddleware = store => next => action => {
  const { critIdArray, source } = action;
  const actionType = action.type;
  const state = store.getState();
  const hasQuickCriteriaSelections = determineIfQuickCriteriaHasSelections(state.quickCriteriaMenu);
  const currentActiveCriteria = state.activeCriteria;
  const isModalAlreadyOpen = state.quickCriteriaMenu.isModalOpen;
  const quickCriteriaBehavior = localStorage.sessionStore('quickCriteriaBehavior');
  const route = getRouterRoute(state);
 

  if (
    !hasQuickCriteriaSelections ||
    currentActiveCriteria.length > 0 ||
    route !== ROUTE_CONSTANTS.INDEX // if we are not on the quick criteria page, skip the quick criteria middleware
  ) {
    return next(action);
  }

  // If there is a quick criteria behavior in session storage, we skip the modal display phase and just
  // manually assemble selections
  if (!_.isNil(quickCriteriaBehavior)) {
    // if exclude is selected, then action is fine as is, only include needs explicit instructions
    if (quickCriteriaBehavior === 'include') {
      const quickCriteriaSelections = getQuickCriteriaParsedSelections(state);

      if (actionType === Types.ADD_CRITERION) {
        const newCritIdArray = criteriaUtils.buildDistinctCritIdArray(
          quickCriteriaSelections.critIdArray,
          action.critId,
        );
        const newFilterSelections = _.isNil(action.filterGroupSelections) ? {} : action.filterGroupSelections;
        _.defaults(newFilterSelections, quickCriteriaSelections.filterGroupSelections, { [action.critId]: [] });
        action = Creators.updateCriteria(newCritIdArray, newFilterSelections, 'middleware');
      } else if (actionType === Types.UPDATE_CRITERIA) {
        const newCritIdArray = criteriaUtils.buildDistinctCritIdArray(quickCriteriaSelections.critIdArray, critIdArray);

        const newFilterSelections = {};

        const defaultSelections = _.get(action, 'filterGroupSelections', {});
        const emptySelections = {};
        critIdArray.forEach(critId => (emptySelections[critId] = []));

        _.defaults(
          newFilterSelections,
          defaultSelections,
          quickCriteriaSelections.filterGroupSelections,
          emptySelections,
        );

        action = Creators.updateCriteria(newCritIdArray, newFilterSelections, 'middleware');
      }
    }
  } else {
    if (actionType === Types.ADD_CRITERION) {
      if (!isModalAlreadyOpen) {
        action = Creators.quickCriteriaDisplayModal(action);
      }
    } else if (actionType === Types.UPDATE_CRITERIA) {
      const isViewModalRemoveCriteria = critIdArray.length < currentActiveCriteria.length;
      if (!isViewModalRemoveCriteria) {
        const isActionFromQuickCriteriaMenu = source === CRITERIA_SOURCE.QUICK_CRITERIA_MENU;

        if (!isModalAlreadyOpen && !isActionFromQuickCriteriaMenu) {
          action = Creators.quickCriteriaDisplayModal(action);
        }
      }
    }
  }

  next(action);
};

export default quickCriteriaMiddleware;
