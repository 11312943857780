const hash = {};

hash.stringToHash = string => {
  var hashVal = 0,
    i,
    chr;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hashVal = (hashVal << 5) - hashVal + chr;
    hashVal |= 0; // Convert to 32bit integer
  }
  return hashVal;
};

export default hash;