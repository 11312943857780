import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ComparisonChart from './Growth10kChart/ComparisonChart';

class CompareCapitalIQChartContainer extends Component {
  state = { chartHasError: false };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div ref={c => (this.container = c)} id="compare-chart-container" className="compare-chart-container">
        <div className="chart-container">
        {<ComparisonChart />}
        </div>
      </div>
    );
  }
}

CompareCapitalIQChartContainer.propTypes = {};
const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareCapitalIQChartContainer);
