import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import QuickCriteriaFundType from 'components/StartPage/QuickCriteriaFundType';
import QuickCriteriaKeyCriteria from 'components/StartPage/QuickCriteriaKeyCriteria';
import QuickCriteriaMorningstarRatings from 'components/StartPage/QuickCriteriaMorningstarRatings';
import QuickCriteriaResultsFooterContainer from 'components/StartPage/QuickCriteriaResultsFooterContainer';
import QuickCriteriaResultsModalContainer from 'components/StartPage/QuickCriteriaResultsModalContainer';
import MadLibsLink from 'components/StartPage/MadLibsLink';
import { CRITERIA_SOURCE } from 'utilities/odaUtils';
import apiUtils from '../../utilities/apiUtils';

class QuickCriteriaMenu extends Component {
  constructor(props) {
    super(props);
    props.fetchDatavalueDisclosures();
    props.fetchQuickCriteriaMatches();
  }

  handleChangeActiveAssetClass = newAssetClass => {
    if (newAssetClass !== this.props.selectedAssetClass) {
      let selectedCategories = [];

      if (newAssetClass === 'All Classes, All Categories') {
        selectedCategories = this._getAllAssetClassValues(newAssetClass);
        this.props.updateSelections('assetClass', []);
      } else {
        selectedCategories = this._getAllCategoryValuesForAssetClass(newAssetClass);
        const fundTypeFilters = _.get(this.props, 'allCriteria.fundType.filterGroups.assetClass', []);
        const assetClassFilter = fundTypeFilters.find(filter => filter.label === newAssetClass);
        this.props.updateSelections('assetClass', [assetClassFilter.value]);
      }

      this.props.updateSelectedAssetClass(newAssetClass, selectedCategories);
    }
  };

  handleChangeAllActiveAssetClasses = ({ key, label }) => {
    const { selections } = this.props;
    let selectedCategories = [];

    if (label === 'All Asset Classes') {
      this.props.updateSelections('assetClass', []);
      this.props.updateSelections('category', []);
    } else {
      if (this.props.selections.assetClass.includes(key)) {
        const filteredAssetClasses = selections.assetClass.filter(x => x !== key);
        const categoriesToRemove = this._getAllCategoryValuesForAssetClass(label);
        selectedCategories = selections.category.filter(x => !categoriesToRemove.includes(x));

        this.props.updateSelections('assetClass', [...filteredAssetClasses]);
        this.props.updateSelections('category', [...selectedCategories]);
      } else {
        selectedCategories = this._getAllCategoryValuesForAssetClass(label);

        this.props.updateSelections('assetClass', [...selections.assetClass, key]);
        this.props.updateSelections('category', [...selections.category, ...selectedCategories]);
      }
    }
  };

  uncheckSelection = (category, assetClass) => {
    const { selections, updateSelections } = this.props;
    const assetClassCategories = this._getAllCategoryValuesForAssetClass(assetClass.label);

    if (category === 'All Categories') {
      const remainingCategories = selections.category.filter(x => !assetClassCategories.includes(x));
      const remainingAssetClasses = selections.assetClass.filter(x => x !== assetClass.key);

      updateSelections('category', [...remainingCategories]);
      updateSelections('assetClass', [...remainingAssetClasses]);
    } else {
      const remainingCategories = selections.category.filter(x => x !== category);
      updateSelections('category', [...remainingCategories]);

      if (!remainingCategories.some(x => assetClassCategories.includes(x))) {
        const remainingAssetClasses = selections.assetClass.filter(x => x !== assetClass.key);
        updateSelections('assetClass', [...remainingAssetClasses]);
      }
    }
  };

  handleChangeAllActiveCategories = (category, assetClass, isChecked, allCategoriesChecked) => {
    const { selections, updateSelections } = this.props;

    if (!isChecked) {
      this.uncheckSelection(category, assetClass);
    } else {
      const assetClassCategories = this._getAllCategoryValuesForAssetClass(assetClass.label);

      if (!selections.category.some(x => assetClassCategories.includes(x))) {
        updateSelections('assetClass', [...selections.assetClass, assetClass.key]);
      }

      if (category === 'All Categories') {
        const mergedUniques = new Set([...assetClassCategories, ...selections.category]);
        updateSelections('category', [...mergedUniques]);
      } else {
        if (allCategoriesChecked) {
          const cats = selections.category.filter(x => !assetClassCategories.includes(x));
          updateSelections('category', [...cats, category]);
        } else {
          updateSelections('category', [...selections.category, category]);
        }
      }
    }
  };

  handleSelectKeyCriteria = (label, isChecked, criteriaName) => {
    if (isChecked) {
      this.props.updateSelections(criteriaName, [...this.props.selections[criteriaName], label]);
    } else {
      const newSelections = this.props.selections[criteriaName].filter(filterName => filterName !== label);
      this.props.updateSelections(criteriaName, newSelections);
    }
  };

  handleSelectMorningstarCriteria = (criteriaName, values) => {
    this.props.updateSelections(criteriaName, [...values]);
  };

  handleSelectAssetClassCategory = (label, isChecked, isAllCategoriesCheckboxChecked) => {
    if (label === 'All Categories') {
      return this._handleSelectAllCategoriesForAssetClass(isChecked);
    }

    const allCategories = this._getAllCategoryValuesForAssetClass(this.props.selectedAssetClass);
    const selectedCategories = this.props.selections.category;

    if (isChecked) {
      if (isAllCategoriesCheckboxChecked) {
        // if currently 'All Categories' is checked and we are checking an individual category, select only that category
        this.props.updateSelections('category', [label]);
      } else {
        // otherwise select this new category in addition to the currently selected categories
        this.props.updateSelections('category', [...this.props.selections.category, label]);
      }
    } else {
      const newSelections = selectedCategories.filter(filterName => filterName !== label);
      if (newSelections.length === 0) {
        // if this is the last checked category, select the 'All Categories' checkbox
        this.props.updateSelections('category', allCategories);
      } else {
        // otherwise just unselect the one category
        this.props.updateSelections('category', newSelections);
      }
    }
  };

  handleSelectMgmtApproach = managementApproach => {
    this.props.updateSelections('indexFundOnly', managementApproach);
  };

  handleSelectViewResults = () => {
    const { critIdArray, filterGroupSelections } = this.props.parsedSelections;
    const source = CRITERIA_SOURCE.QUICK_CRITERIA_MENU;
    let newCritIdArray=_.cloneDeep(critIdArray);
    let newFilterGroupSelections=_.cloneDeep(filterGroupSelections);
    if (this.props.isAssetClassCategoryChanged || newCritIdArray.length === 1){
      this.props.viewResults(newCritIdArray, newFilterGroupSelections, source);
    } else {
      // remove FundType if there is other selection and FundType is not selected
      delete newFilterGroupSelections['assetClass'];
      delete newFilterGroupSelections['category']; 
      newCritIdArray = newCritIdArray.filter((item, i)=>{return i>0;});
      if (newCritIdArray.find(e=>e==='sociallyResponsible')){
        //to match fund strategy
		if (!newCritIdArray.includes('ntf') && !newCritIdArray.includes('transactionFeeIndicator')){
			newCritIdArray.push('ntf');
			newFilterGroupSelections['ntf']=['Y'];
		}
        newCritIdArray.push('sustainableInvestment');
        newCritIdArray.push('employsExclusions');
        const sustainableInvestment = apiUtils.getSociallyResponsibleAttributesValues('sustainableInvestment').split(",");
        const employsExclusions = apiUtils.getSociallyResponsibleAttributesValues('employsExclusions').split(",");
        newFilterGroupSelections['sustainableInvestment']=sustainableInvestment;
        newFilterGroupSelections['employsExclusions']=employsExclusions;
        newFilterGroupSelections['sociallyResponsible']=['Y'];
        this.props.viewResults(newCritIdArray, newFilterGroupSelections, source);
        return;
      }
      this.props.viewResults(newCritIdArray, newFilterGroupSelections, source);
    }
  };

  getQuickCriteriaSelections = () => {
    const { critIdArray, filterGroupSelections } = this.props.parsedSelections;
    return { critIdArray, filterGroupSelections };
  };

  handleMoveRiskSlider = e => {
    const value = e.target.value;

    this.props.updateSelections('risk', value);
  };

  _getAllAssetClassValues = () => {
    const fundTypeFilters = _.get(this.props, 'allCriteria.fundType.filterGroups.category', []);

    let allValues = [];
    fundTypeFilters.forEach(category => allValues.push(category.value));

    return allValues;
  };

  _handleSelectAllCategoriesForAssetClass = isChecked => {
    if (isChecked) {
      const selectedCategories = this._getAllCategoryValuesForAssetClass(this.props.selectedAssetClass);

      this.props.updateSelections('category', selectedCategories);
    } else {
      this.props.updateSelections('category', []);
    }
  };

  _getAllCategoryValuesForAssetClass = assetClass => {
    const fundTypeFilters = _.get(this.props, 'allCriteria.fundType.filterGroups.assetClass', []);
    const assetClassFilter = fundTypeFilters.find(filter => filter.label === assetClass);
    return assetClassFilter.categories.map(category => category.value);
  };

  _renderQuickCriteriaMenu = () => {};

  _renderFundPicks = () => {
    const { activeFundType, fundTypeDisclosures, selections } = this.props;
    return (
      <QuickCriteriaFundType
        activeClass={this.props.selectedAssetClass}
        activeFundType={activeFundType}
        categoryRiskDisclosures={this.props.categoryRiskDisclosures}
        disclosures={fundTypeDisclosures}
        handleChangeActiveAssetClass={this.handleChangeActiveAssetClass}
        handleMoveRiskSlider={this.handleMoveRiskSlider}
        handleSelectAssetClassCategory={this.handleSelectAssetClassCategory}
        riskValue={selections.risk}
        selectedCategories={selections.category}
        setActiveFundType={this.props.updateActiveFundType}
      />
    );
  };

  render() {
    const {
      allCriteria,
      activeFundType,
      fundTypeDisclosures,
      learnMoreDisclosure,
      parsedSelections,
      selections,
      cancelModal,
      hideModal,
      isModalOpen,
      updateExpandedCrit,
      userSelectedCriteria,
      viewResults,
      isMadLibsActive,
    } = this.props;

    const investmentTypeCriterion = allCriteria.investmentTypeCode;
    const fundTypeCriterion = allCriteria.fundType;
    const hasSelections = parsedSelections.critIdArray.length > 0 && !isMadLibsActive;

    return (
      <div className="quick-criteria-menu container-fluid">
        <QuickCriteriaResultsModalContainer
          cancelModal={cancelModal}
          hideModal={hideModal}
          isModalOpen={isModalOpen}
          parsedSelections={parsedSelections}
          updateExpandedCrit={updateExpandedCrit}
          userSelectedCriteria={userSelectedCriteria}
          viewResults={viewResults}
        />
        <div className="madlibs-mobile-link">
          <MadLibsLink />
        </div>
        <div className="all-quick-criteria">
          <QuickCriteriaFundType
            activeClass={this.props.selectedAssetClass}
            activeFundType={activeFundType}
            categoryRiskDisclosures={this.props.categoryRiskDisclosures}
            criterion={fundTypeCriterion}
            investmentTypeCriterion={investmentTypeCriterion}
            disclosures={fundTypeDisclosures}
            handleChangeAllActiveAssetClasses={this.handleChangeAllActiveAssetClasses}
            handleMoveRiskSlider={this.handleMoveRiskSlider}
            handleChangeAllActiveCategories={this.handleChangeAllActiveCategories}
            riskValue={selections.risk}
            selectedAssetClasses={selections.assetClass}
            selectedCategories={selections.category}
            setActiveFundType={this.props.updateActiveFundType}
            handleSelectKeyCriteria={this.handleSelectKeyCriteria}
            keyCriteriaSelected={selections.keyCriteria}
          />
          <QuickCriteriaKeyCriteria
            allCriteria={allCriteria}
            handleSelectKeyCriteria={this.handleSelectKeyCriteria}
            handleSelectMgmtApproach={this.handleSelectMgmtApproach}
            keyCriteriaSelected={selections.keyCriteria}
            mgmtApproachSelected={selections.indexFundOnly}
          />
          <QuickCriteriaMorningstarRatings
            allCriteria={allCriteria}
            ratingsSelected={selections.mStarRating}
            returnsSelected={selections.returns}
            expensesSelected={selections.expenses}
            handleClick={this.handleSelectMorningstarCriteria}
          />
        </div>
        <div>
          <QuickCriteriaResultsFooterContainer
            allCriteria={allCriteria}
            handleSelectKeyCriteria={this.handleSelectKeyCriteria}
            keyCriteriaSelected={selections.keyCriteria}          
            handleSelectViewResults={this.handleSelectViewResults}
            learnMoreDisclosure={learnMoreDisclosure}
            hasSelections={hasSelections}
            section="quick-criteria"
            hasLearnMore={false}
          />
        </div>
      </div>
    );
  }
}

QuickCriteriaMenu.propTypes = {
  /** The currently selected fund type */
  activeFundType: PropTypes.string,

  /** Object representing all criteria definitions */
  allCriteria: PropTypes.object.isRequired,

  /** Redux action to cancel the modal. This does not apply any selections */
  cancelModal: PropTypes.func.isRequired,

  /** A map of category risk disclosures with the asset class as a key */
  categoryRiskDisclosures: PropTypes.object.isRequired,

  /** Redux action to grab data value disclosures from Fidelity API */
  fetchDatavalueDisclosures: PropTypes.func.isRequired,

  /** Initial call to the matches API when the component is mounted */
  fetchQuickCriteriaMatches: PropTypes.func.isRequired,

  /** Disclosures for building out fund type descriptions from Fidelity API */
  fundTypeDisclosures: PropTypes.object,

  /** Function to hide the quick results modal */
  hideModal: PropTypes.func.isRequired,

  /** Determines whether the quick results modal is open */
  isModalOpen: PropTypes.bool.isRequired,

  /** The disclosure content for the learn more modal */
  learnMoreDisclosure: PropTypes.string,

  /** A parsed object containing the necessary parameters that can be used to update Redux store using the viewResults prop */
  parsedSelections: PropTypes.object.isRequired,

  /** The currently selected fund type asset class */
  selectedAssetClass: PropTypes.string.isRequired,

  /** Selections that the user has made */
  selections: PropTypes.object.isRequired,

  /** Redux action to update the active fund type */
  updateActiveFundType: PropTypes.func.isRequired,

  /** Updates the currently expanded criterion in the criteria menu */
  updateExpandedCrit: PropTypes.func.isRequired,

  /** Redux action to update the active asset class along with the appropriate categories */
  updateSelectedAssetClass: PropTypes.func.isRequired,

  /** Update the quick criteria selections for a given criteria ID */
  updateSelections: PropTypes.func.isRequired,

  /** A selection made by the user from the full criteria menu rail when the quick criteria menu has selections */
  userSelectedCriteria: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),

  /** Redux action to update state with selections from quick criteria */
  viewResults: PropTypes.func.isRequired,

  /** Determines whether Asset Class and Category has been selected */
  isAssetClassCategoryChanged: PropTypes.bool.isRequired,

};

export default QuickCriteriaMenu;
