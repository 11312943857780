import { connect } from 'react-redux';
import * as odaTracking from 'utilities/odaTracking';
import * as adobeAnalytics from 'utilities/adobeAnalytics';
import Creators from 'actions/CriteriaActions';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getViewTab } from 'reducers/ViewTabReducer';
import { RESULT_FEATURES } from 'configs/AppFeatureConfig';
import { getSharedStaticColumns } from 'reducers/SharedStaticColumnsReducer';
import { getSortSettings } from 'reducers/SortResultsReducer';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ActionCheckboxContainer from 'components/ResultsView/ActionCheckboxContainer';

import { START_PAGE_TYPE } from 'configs/AppFeatureConfig';

class ResultsTableRow extends Component {
  _handleClickFundName = event => {
    const isClickFromFundLink = event.nativeEvent.currentTarget.activeElement.matches('a');
    if (isClickFromFundLink) {
      const ticker = this.props.resultsData.additionalFundData.ticker;
      const trackFundClick = this.props.trackFundClick;
      const displayType = this.props.displayType;
      trackFundClick(displayType, ticker);
    }
  };
  _renderActionCheckbox = () => {
    const { cusip, fundCode } = this.props.resultsData.additionalFundData;
    // Not all funds/investments have cusips
    const id = !_.isNil(cusip) ? `cusip-${cusip}` : `fund-code-${fundCode}`;
    return (
      <ActionCheckboxContainer
        key={id}
        handleClick={this.props.handleActionCheckboxClick}
        additionalFundData={this.props.resultsData.additionalFundData}
        setCurrentCheckboxRef={this.props.setCurrentCheckboxRef}
        className={`col-static`}
      />
    );
  };
  _renderTableCellsForResult = (resultObj, metaData = null, isStatic = false) => {
    const { sortCol } = this.props.sortSettings;
    const tableCells = _.map(resultObj, (result, tdIdx) => {
      const meta = metaData[tdIdx];
      let style = {};

      const classNames = _.values(_.pick(meta, ['id', 'align']));
      if (meta.sortField === sortCol) {
        classNames.push('sorted-column-cell');
      }
      if (isStatic) {
        classNames.push('col-static');
        if (this.props.staticColumnsLeft && this.props.staticColumnsLeft[tdIdx + 1]) {
          style['left'] = this.props.staticColumnsLeft[tdIdx + 1] + 'px';
        }
      }
      const onClickHandler = meta.className === 'fund-name' ? e => this._handleClickFundName(e) : null;
      return (
        <td className={classNames.join(' ')} key={tdIdx} onClick={onClickHandler} style={style}>
          {result}
        </td>
      );
    });
    return [tableCells];
  };
  _renderBuyButtonCell = () => {
    const additionalFundData = this.props.resultsData.additionalFundData;
    const { id } = additionalFundData;
    const trackBuyButtonAction = this.props.trackBuyButtonAction;
    const { displayType, viewTab } = this.props;
    //TODO: need to apply URL resolving
    const baseUrl = `https://digital.fidelity.com/ftgw/digital/`;
    let tradeUrl = '';
    if (START_PAGE_TYPE === 'mfe') {
      tradeUrl = baseUrl + `trade-mutualfund?${RESULT_FEATURES.BUY_BUTTON_LINK_PARAMS}&SECURITY_ID=${id}`;
    } else {
      tradeUrl = baseUrl + 'annuity-exchange';
    }
    return (
      <td key={'buy_button_' + id}>
        <a
          target="_blank"
          href={tradeUrl}
          onClick={() => trackBuyButtonAction(displayType, viewTab, additionalFundData)}
        >
          <span className="buy-button"> {RESULT_FEATURES.BUY_BUTTON_LABEL} </span>
        </a>
      </td>
    );
  };
  render() {
    const { height, className, sharedStaticColumns } = this.props;
    const { displayData, metaData } = this.props.resultsData;

    const staticDisplayData = [];
    const staticMetaData = [];
    // populate staticDisplayData and staticMetaData with shared static column data
    for (let i = 0; i < metaData.length; i++) {
      if (_.includes(sharedStaticColumns, metaData[i].id)) {
        staticDisplayData.push(displayData[i]);
        staticMetaData.push(metaData[i]);
      }
    }
    const staticActionCell = this._renderActionCheckbox();
    const staticTableCells = this._renderTableCellsForResult(staticDisplayData, staticMetaData, true);

    const viewTabDisplayData = [];
    const viewTabMetaData = [];
    // populate viewTabDisplayData and viewTabMetaData with non shared static column data
    for (let i = 0; i < metaData.length; i++) {
      if (!_.includes(sharedStaticColumns, metaData[i].id)) {
        viewTabDisplayData.push(displayData[i]);
        viewTabMetaData.push(metaData[i]);
      }
    }
    const dataCells = this._renderTableCellsForResult(viewTabDisplayData, viewTabMetaData, false);
    const buyButton = RESULT_FEATURES.HAS_BUY_BUTTON ? this._renderBuyButtonCell() : null;

    const tableCells = [staticActionCell, ...staticTableCells, ...dataCells, buyButton];
    if (height) {
      const style = { height: height };
      return (
        <tr className={className} style={style}>
          {tableCells}
        </tr>
      );
    }
    return <tr className={className}>{tableCells}</tr>;
  }
}

ResultsTableRow.propTypes = {
  //Data from fund search call to be rendered in this row
  resultsData: PropTypes.object.isRequired,
  //height of this row (used for table alignment)
  height: PropTypes.number,
  //function that is called when the action checkbox gets clicked
  handleActionCheckboxClick: PropTypes.func.isRequired,
  //class attribute to apply to the tr element in this component
  className: PropTypes.string.isRequired,
  //function that dispatches action to track when user presses the buy button
  trackBuyButtonAction: PropTypes.func.isRequired,
  //function that facilitates passing checkbox ref to popover for positioning
  setCurrentCheckboxRef: PropTypes.func.isRequired,
  //dispatches action to track when fund name is clicked
  trackFundClick: PropTypes.func.isRequired,
  //Current Display type of application: compare,table,card,etc.
  displayType: PropTypes.string.isRequired,
  //Shared columns that belong on the static table
  sharedStaticColumns: PropTypes.array.isRequired,

  sortSettings: PropTypes.object.isRequired,
  staticColumnsLeft: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  return {
    displayType: getDisplayType(state),
    viewTab: getViewTab(state),
    sharedStaticColumns: getSharedStaticColumns(state),
    sortSettings: getSortSettings(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    trackBuyButtonAction: (displayType, viewTab, additionalFundData) => {
      const { ticker, cusip, tradingSymbol } = additionalFundData;
      const symbol = START_PAGE_TYPE === 'fili' ? tradingSymbol : ticker;
      const trackingData = odaTracking.getBuyButtonTrackingData(displayType, symbol);
      dispatch(Creators.odaLogAction(trackingData));
      const adobeTrackingData = adobeAnalytics.getBuyButtonTrackingData(displayType, viewTab, symbol, cusip);
      dispatch(Creators.trackAdobeAnalytics(adobeTrackingData));
    },
    trackFundClick: (displayType, ticker) => {
      const trackingData = odaTracking.getFundClickTrackingData(displayType, ticker);
      dispatch(Creators.odaLogAction(trackingData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsTableRow);
