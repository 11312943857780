import { connect } from 'react-redux';
import CriteriaGroup from 'components/CriteriaGroup';
import { getCriteriaGroupInactive } from 'reducers/selectors/CriteriaSelectors';
import Creators from 'actions/CriteriaActions';
import * as odaTracking from 'utilities/odaTracking';

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => {
    return {
      isTopLevel: ownProps.id === 'basicCriteria',
      setFocusCriteria: ownProps.setFocusCriteria,
      ...getCriteriaGroupInactive(state, ownProps),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => {
  return {
    trackCriteriaGroup: (critGroup, expanded) => {
      const trackingData = odaTracking.getCriteriaMenuGroupCriteriaTrackingData(critGroup, expanded);
      dispatch(Creators.odaLogAction(trackingData));
    },
  };
};

const CriteriaGroupContainer = connect(makeMapStateToProps, mapDispatchToProps)(CriteriaGroup);

export default CriteriaGroupContainer;
