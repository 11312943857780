import _ from 'lodash';
import React from 'react';
import { format } from 'utilities';
import { CRITERIA_BY_GROUP } from 'configs/CriteriaConfig';

const scrollToElementWithId = elementId => {
  const critGroup = document.getElementById(elementId);
  critGroup.scrollIntoView({ behavior: 'smooth' });
};

const renderViewGroupHeaders = () => {
  const headers = [];

  _.forEach(CRITERIA_BY_GROUP, criteriaGroup => {
    let headerClassName = format.transformToSnakeCase(criteriaGroup.label);

    let header = (
      <li className={headerClassName} key={criteriaGroup.id}>
        <button className="as-link" onClick={() => scrollToElementWithId('view-all-' + headerClassName)}>
          {criteriaGroup.label}
        </button>
      </li>
    );
    headers.push(header);
  });

  return <ul className="view-all-group-headers">{headers}</ul>;
};

// TODO: Update with correct definitions link
const ViewAllHeader = () => {
  const groupHeaders = renderViewGroupHeaders();

  return (
    <div className="view-all-header">
      <h2>Select Criteria</h2>
      {groupHeaders}
      {/* <div className="view-all-glossary-link">
        <a href="https://www.google.com">Criteria Definitions</a>
      </div> */}
    </div>
  );
};

ViewAllHeader.propTypes = {};

export default ViewAllHeader;
