import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RESULT_TYPE_CAPITALIZED } from 'configs/AppFeatureConfig';
import { getTotalMatches } from 'reducers/MadLibsMatchesReducer';
import { getQuickCriteriaMatchesLoading } from 'reducers/LoadingReducer';
import { getIsMadLibsActive } from '../../reducers/QuickCriteriaMenuReducer';

const MadLibsMatches = ({ matchCount, quickCriteriaMatchesLoading, isMadLibsActive }) => {
  const matchCountText = (quickCriteriaMatchesLoading || !isMadLibsActive) ? '--' : matchCount;
  let madLibMatches;
  if (matchCount === 0 && isMadLibsActive) {
    madLibMatches = `No Matching Funds`;
  }
  else {
    madLibMatches = `View ${matchCountText} ${RESULT_TYPE_CAPITALIZED}s`;
  }
  return madLibMatches;
};

MadLibsMatches.propTypes = {
  /** Number of matches that align with a user's current selections in the quick criteria menu */
  matchCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** A boolean indicating if quick criteria matches are loading */
  quickCriteriaMatchesLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  return {
    matchCount: getTotalMatches(state),
    quickCriteriaMatchesLoading: getQuickCriteriaMatchesLoading(state),
    isMadLibsActive: getIsMadLibsActive(state),
  };
};

const MadLibsMatchesContainer = connect(
  mapStateToProps,
  null,
)(MadLibsMatches);

export default MadLibsMatchesContainer;
export { MadLibsMatches };
