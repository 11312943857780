import { connect } from 'react-redux';
import CriteriaMenu from 'components/CriteriaMenu';
import { getIsMobile } from 'reducers/MobileStatusReducer';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import Creators from 'actions/CriteriaActions';
import * as odaTracking from 'utilities/odaTracking';
import { CLEAR_ALL_SOURCE } from 'utilities/odaUtils';
import { getCriteriaMenuExpanded } from 'reducers/CriteriaMenuExpandedReducer';
import { getActiveCriteriaList } from 'reducers/selectors/CriteriaSelectors';

const mapStateToProps = (state, ownProps) => {
  return {
    route: state.route,
    isMobile: getIsMobile(state),
    displayType: getDisplayType(state),
    isCriteriaMenuExpanded: getCriteriaMenuExpanded(state),
    activeCriteria: getActiveCriteriaList(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    trackCriteriaMenuExpandCollapse: expanded => {
      const trackingData = odaTracking.getCriteriaMenuToggleMenuTrackingData(expanded);
      dispatch(Creators.odaLogAction(trackingData));
    },
    updateCriteriaMenuExpanded: isExpanded => {
      dispatch(Creators.updateCriteriaMenuExpanded(isExpanded));
    },
    onClearAll: () => {
      const source = CLEAR_ALL_SOURCE.CRITERIA_MENU;
      dispatch(Creators.clearAll(source));
    },
  };
};

const CriteriaMenuContainer = connect(mapStateToProps, mapDispatchToProps)(CriteriaMenu);

export default CriteriaMenuContainer;
