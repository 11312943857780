import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { RUNNING_ENVIRONMENT } from '../../configs/AppFeatureConfig';

class FidelitySearchBar extends Component {
  constructor() {
    super();
    this.state = {
      searchText: '',
    };
  }

  setSearchText = e => {
    this.setState({
      searchText: e.target.value,
    });
  };

  handleSearch = e => {
    e.preventDefault();

    const searchString = encodeURIComponent(this.state.searchText);
    let redirectionLink = '';
    if (RUNNING_ENVIRONMENT && RUNNING_ENVIRONMENT.IsProduction) {
      redirectionLink = `https://digital.fidelity.com/search/funds?q=${searchString}`;
    } else {
      redirectionLink = `https://digitalxq1.fidelity.com/search/funds?q=${searchString}`;
    }
    window.location = redirectionLink;
  };

  render() {
    return (
      <form className="fidelity-search-bar" onSubmit={this.handleSearch}>
        <div className="search-bar-label">Find a mutual fund</div>
        <div className="search-box">
          <input
            type="text"
            placeholder="Name, Symbol, Family, or Top 10 Holding"
            value={this.state.searchText}
            onChange={this.setSearchText}
            aria-label="Find a mutual fund"
          />
          {this.props.suppressSubmitButton ? null : <input type="submit" value="Go" />}
        </div>
      </form>
    );
  }
}

FidelitySearchBar.defaultProps = {
  suppressSubmitButton: false,
};

FidelitySearchBar.propTypes = {
  suppressSubmitButton: PropTypes.bool,
};

export default FidelitySearchBar;
