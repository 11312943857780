import React, { Component } from 'react';
import QuickCriteriaCheckboxFilter from 'components/StartPage/QuickCriteriaCheckboxFilter';

const NUM_OF_COLUMNS = 3;

class FundPicksGroupPopup extends Component {
  _shouldCheckGroup = label => {
    return this.props.selectedGroups.includes(label);
  }

  _handleSelectGroup = (label, isChecked) => {
    this.props.handleSelectCategoryGroup(label, isChecked, this.props.allGroupsChecked);
  }

  _renderFilters = (allGroupsChecked) => {
    const { options } = this.props;
    const filterLength = options.length;
    const columnSize = Math.ceil(filterLength / NUM_OF_COLUMNS);

    const firstColumn = [];
    const secondColumn = [];
    const thirdColumn = [];

    options.forEach((option, i) => {
      let displayStyle = '';
      if (i === filterLength - 1 && thirdColumn.length !== columnSize - 1) {
        displayStyle = 'force-bottom-border';
      }

      const el = (
        <QuickCriteriaCheckboxFilter
          key={option.value}
          classNames={displayStyle}
          criteriaName={'category'}
          handleClick={this._handleSelectGroup}
          identifier={option.label}
          isChecked={this._shouldCheckGroup(option.label)}
          label={option.label}
        />
      );
      if (firstColumn.length < columnSize) {
        firstColumn.push(el);
      } else if (secondColumn.length < columnSize) {
        secondColumn.push(el);
      } else {
        thirdColumn.push(el);
      }
    });
    if (firstColumn.length===0){
      return (
        <div className="row">
          <div className="col-sm-12 col-md-12">No Category</div>
        </div>
      );
    }    
    else if (secondColumn.length===0){
      return (
        <div className="row">
          <div className="col-sm-12 col-md-12">{firstColumn}</div>
        </div>
      );
    } else if (thirdColumn.length===0){
      return (
        <div className="row">
          <div className="col-sm-12 col-md-6">{firstColumn}</div>
          <div className="col-sm-12 col-md-6">{secondColumn}</div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-sm-12 col-md-4">{firstColumn}</div>
        <div className="col-sm-12 col-md-4">{secondColumn}</div>
        <div className="col-sm-12 col-md-4">{thirdColumn}</div>
      </div>
    );
  };

  render() {
    const { isOpen, selectedGroups, allGroupsChecked } = this.props;
    const displayStyle = isOpen ? '' : 'off';
    const filters = this._renderFilters(allGroupsChecked);
    const zeroGroupsMessage =
      selectedGroups.length < 1 && !allGroupsChecked ? (
        <span className="zero-categories-message">
          (You must select at least one category to see results.)
        </span>
      ) : null;

    return (
      <div className={`quick-criteria-category-popup ${displayStyle}`}>
        <div className="container">
          <div className="row all-categories">
            <div className="col-xs-12">
              <QuickCriteriaCheckboxFilter
                handleClick={this._handleSelectGroup}
                isChecked={allGroupsChecked}
                isPartialChecked={false}
                label={'All Categories'}
                key={'All Categories'}
                criteriaName={'All Groups'}
              >
                {zeroGroupsMessage}
              </QuickCriteriaCheckboxFilter>
            </div>
          </div>
          {filters}
        </div>
      </div>
    );
  }
}


export default FundPicksGroupPopup;
