import React, { useState, useEffect } from 'react';

const SelectAllCriteria = ({ onFiltersUpdated, filters, filtersSelected }) => {
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectAll(filtersSelected.length === filters.length);
  });

  const setRemainingLabelsToSelected = () => {
    const updatedState = {};

    filters.forEach(filter => {
      if (!filtersSelected.includes(filter.label)) {
        updatedState[filter.label] = true;
      }
    });

    return updatedState;
  };

  const setAllLabelsToUnselected = () => {
    const updatedState = {};

    filters.forEach(filter => {
      updatedState[filter.label] = false;
    });

    return updatedState;
  };

  const handleCheck = () => {
    setSelectAll(!selectAll);
    let updatedState = null;

    if (!selectAll) {
      updatedState = setRemainingLabelsToSelected();
    } else {
      updatedState = setAllLabelsToUnselected();
    }

    onFiltersUpdated(updatedState);
  };

  const checkedStateClassName = selectAll ? 'checked' : 'unchecked';

  return (
    <div className="select-all-subheader">
      <div className={`checkbox-input ${checkedStateClassName}`}>
        <label
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleCheck();
            }
          }}
          tabIndex={0}
          for="selectAll"
        >
          <input
            tabIndex={0}
            type="checkbox"
            id="selectAll"
            name="selectAll"
            value="selectAll"
            onClick={() => handleCheck()}
          />
          <svg
            tabIndex={0}
            class="checkbox"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <path class="checkmark" d="M 5,11 L 8,14 L 14, 6" />
          </svg>
          <span>Select all</span>
        </label>
      </div>
    </div>
  );
};

export default SelectAllCriteria;
