import * as apiUtils from 'utilities/apiUtils';
import * as criteriaUtils from 'utilities/criteriaUtils';
import * as odaTracking from 'utilities/odaTracking';
import * as adobeAnalytics from 'utilities/adobeAnalytics';
import * as loginUtils from 'utilities/loginUtils';
import format from 'utilities/format';
import localStorage from 'utilities/localStorage';
import sorting from 'utilities/sorting';
import icons from 'utilities/icons';
import popup from 'utilities/popup';
import strategiesUtils from 'utilities/strategiesUtils';
import sagaUtils from 'utilities/sagaUtils';

const util = {};
util.apiUtils = apiUtils;
util.criteriaUtils = criteriaUtils;
util.odaTracking = odaTracking;
util.adobeAnalytics = adobeAnalytics;
util.loginUtils = loginUtils;
util.format = format;
util.localStorage = localStorage;
util.sorting = sorting;
util.icons = icons;
util.popup = popup;
util.strategiesUtils = strategiesUtils;
util.sagaUtils = sagaUtils;

export { apiUtils };
export { criteriaUtils };
export { odaTracking };
export { adobeAnalytics };
export { loginUtils };
export { format };
export { localStorage };
export { sorting };
export { icons };
export { popup };
export { strategiesUtils };
export { sagaUtils };
export default util;
