import React, { useRef, useState, useEffect } from "react";

const Dropdown = ({idx, label, selection, options, handleChange}) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState(selection || 'select');
  
  const beforeLabel = label[0] ? label[0] : null;
  const afterLabel = label[1] ? label[1] : null;
  const disabled = !options || options.length < 1;
  const hide = !options && idx === 3;

  const onOpenClick = () => {
    if (!disabled) {
      setIsActive(!isActive);
    }
  }

  const onDropdownClick = selection => {
    const { label } = selection;
    setSelected(label);
    setIsActive(!isActive);
    handleChange(selection);
  }

  const setActiveLabel = () => {
    const dropdownText = disabled || !selection ? 'select' : selected;
    setSelected(dropdownText);
  }

  useEffect(() => {
    const pageClickEvent = e => {
      const isDisabled = e.target.getAttribute('data-disabled');
      
      if (isDisabled) {
        setIsActive(true);
        return;
      };

      if (!isDisabled && dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    }
    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    }
  }, [isActive]);

  useEffect(() => {
    setActiveLabel(options);
  }, [options]);
  
  return (
    <div className={`madlib-dropdown-wrap ${hide ? 'hidden' : ''}`}>
      <div className={`madlib-label-field ${disabled ? 'disabled' : 'enabled'}`}>
        <span className="madlib-label before">{beforeLabel}</span>
        <div className="madlib-select-wrap" onClick={onOpenClick}>
          <div className="madlib-select-field">{selected}</div>
          <div className="madlib-dropdown-arrow"></div>
          <div ref={dropdownRef} className={`madlib-dropdown ${isActive ? 'active' : 'inactive'}`}>
            {options ? options.map(x => {
              if (x.disabled) {
                return <div className="madlib-select disabled" data-disabled="true" key={x.label} value={x.label}>{x.label}</div>
              } else {
                return <div className="madlib-select" onClick={() => onDropdownClick(x)} key={x.label} value={x.label}>{x.label}</div>
              }
            }) : null}
          </div>
        </div>
        <span className="madlib-label after" style={{color: selection ? 'black' : 'grey'}}>{afterLabel}</span>
      </div>
    </div>
  );
}

export default Dropdown;