import React from 'react';
import PropTypes from 'prop-types';

const ResultsCardItemGroupRow = ({ label, value }) => {
  return (
    <div className="results-card-item-group-row">
      <div className="label" dangerouslySetInnerHTML={{ __html: label }} />
      <div className="value">{value}</div>
    </div>
  );
};

ResultsCardItemGroupRow.propTypes = {
  /** The display name of this data point */
  label: PropTypes.string.isRequired,

  /** The formatted data point */
  value: PropTypes.array.isRequired,
};

export default ResultsCardItemGroupRow;
