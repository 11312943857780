import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { HAS_FIDELITY_FUNDS_TABLE, RESULT_TYPE_CAPITALIZED } from 'configs/AppFeatureConfig';
import Creators from 'actions/CriteriaActions';
import { getCardResultsData } from 'reducers/FundSearchResultsReducer';
import { getAllCriteriaFilters } from 'reducers/CriteriaFiltersReducer';
import { getSomeResultsLoading } from 'reducers/LoadingReducer';
import { getCriteriaDefinitions } from 'reducers/selectors/CriteriaSelectors';
import { getActiveCriteria } from 'reducers/ActiveCriteriaReducer';
import { getFidelityMatches } from 'reducers/FidelityMatchesReducer';
import { getFundSearchParsedError, getFidelityFundSearchParsedError } from 'reducers/ErrorReducer';
import ResultsCardItemContainer from 'components/ResultsView/ResultsCardItemContainer';

class ResultsCardView extends Component {
  _getIsFidelityFundsOnlySelected = () => {
    const fidelityFundOnlyFilter = _.get(this.props.criteriaFilters, 'fidelityFundOnly', []);
    const isFidelityFundOnlySelected = fidelityFundOnlyFilter.includes('F');
    return isFidelityFundOnlySelected;
  };
  _renderError = (errorText, isFidelityError = false) => {
    const className = isFidelityError ? 'no-results-card fidelity-light-green' : 'no-results-card';
    return (
      <div className={className}>
        <p className="no-results-text">{errorText}</p>
      </div>
    );
  };
  _renderResultsCards = (rawResultData = [], isFidelityResultSet = false) => {
    if (!isFidelityResultSet && !_.isNil(this.props.resultsError)) {
      return this._renderError(this.props.resultsError);
    } else if (isFidelityResultSet && !_.isNil(this.props.fidelityResultsError)) {
      return this._renderError(this.props.fidelityResultsError, isFidelityResultSet);
    }
    const isFidelityLightGreen = isFidelityResultSet && !this._getIsFidelityFundsOnlySelected();
    const resultCards = _.map(rawResultData, result => {
      const id = !_.isNil(result.additionalData.cusip)
        ? 'cusip-' + result.additionalData.cusip
        : 'fund-code-' + result.additionalData.fundCode;
      return <ResultsCardItemContainer key={id} data={result} isFidelityLightGreen={isFidelityLightGreen} />;
    });

    return resultCards;
  };
  _classNameWithLoading = baseClassName => {
    const className = `${baseClassName} ${this.props.someResultsLoading ? 'loading' : ''}`;
    return className;
  };

  _addFidelityFundsOnlyCrit = () => {
    const critArray = this.props.activeCriteria;
    const fidelityFundsOnlyCrit = this.props.criteriaDefinitions['fidelityFundOnly'];
    if (!critArray.includes(fidelityFundsOnlyCrit.id)) critArray.push(fidelityFundsOnlyCrit.id);
    const { defaultFilterGroupSelections } = fidelityFundsOnlyCrit;
    const filterGroupSelections = _.map(defaultFilterGroupSelections, selection => selection.value);
    const allSelections = { ...this.props.criteriaFilters, [fidelityFundsOnlyCrit.id]: filterGroupSelections };

    this.props.updateActiveCriteria(critArray, allSelections);
  };

  _removeFidelityFundsOnlyCrit = () => {
    const fidelityFundsOnlyCrit = this.props.criteriaDefinitions['fidelityFundOnly'];
    const critArray = _.filter(this.props.activeCriteria, critId => critId !== fidelityFundsOnlyCrit.id);
    const allSelections = { ...this.props.criteriaFilters };
    delete allSelections[fidelityFundsOnlyCrit.id];

    this.props.updateActiveCriteria(critArray, allSelections);
  };
  _renderViewMatchingFundsButton = () => {
    const isFidelityFundOnlySelected = this._getIsFidelityFundsOnlySelected();
    if (isFidelityFundOnlySelected) {
      return (
        <button className="as-link" onClick={this._removeFidelityFundsOnlyCrit}>
          (View All Matching {RESULT_TYPE_CAPITALIZED}s)
        </button>
      );
    } else {
      return this.props.numFidelityResults > 0 ? (
        <button className="as-link" onClick={this._addFidelityFundsOnlyCrit}>
          (View {this.props.numFidelityResults} Matching Fidelity Funds)
        </button>
      ) : null;
    }
  };
  _renderMatchingFidelityFunds = () => {
    const isFidelityFundOnlySelected = this._getIsFidelityFundsOnlySelected();
    const { results, fidelityResults } = this.props;
    // the fidelity results data comes from different props depending on whether fidelity funds only is selected
    const matchingFidelityResults = isFidelityFundOnlySelected ? results : fidelityResults;
    const isFidelityResultSet = true;
    const fidelityResultCards = this._renderResultsCards(matchingFidelityResults, isFidelityResultSet);
    return [
      <h4 className="matching-fidelity-funds">Matching Fidelity Funds</h4>,
      this._renderViewMatchingFundsButton(),
      <div className="fidelity-results-cards">{fidelityResultCards}</div>,
    ];
  };
  _renderAllMatchingFunds = () => {
    //DODGE suppress
    const dodgeAndCoxFunds = ['DODGX', 'DODBX', 'DODFX', 'DODLX', 'DODIX', 'DODEX', 'DODWX'];
    const filteredResults = this.props.results.filter(function (item) {
      return !dodgeAndCoxFunds.includes(item.additionalData.id);
    });

    const resultCards = this._renderResultsCards(filteredResults);
    // if we don't have fidelity funds table, then all matching funds is the only table so just say 'Matching Funds'
    const allMatchingFundsTitle = HAS_FIDELITY_FUNDS_TABLE
      ? `All Matching ${RESULT_TYPE_CAPITALIZED}s`
      : `Matching ${RESULT_TYPE_CAPITALIZED}s`;
    return [
      <h4 className={`all-matching-funds`} key="all-matching-funds">
        {allMatchingFundsTitle}
      </h4>,
      <div className={`all-results-cards`} key="all-results-cards">
        {resultCards}
      </div>,
    ];
  };
  render() {
    const isFidelityFundOnlySelected = this._getIsFidelityFundsOnlySelected();
    return (
      <div className={this._classNameWithLoading('results-card-view')}>
        {HAS_FIDELITY_FUNDS_TABLE || isFidelityFundOnlySelected ? this._renderMatchingFidelityFunds() : null}
        {!isFidelityFundOnlySelected ? this._renderAllMatchingFunds() : null}
      </div>
    );
  }
}

ResultsCardView.propTypes = {
  /** List of all active criteria */
  activeCriteria: PropTypes.array,

  /** Definitions of all criteria */
  criteriaDefinitions: PropTypes.object,

  /** All the currently selected criteria filters */
  criteriaFilters: PropTypes.object,

  /** An array of the raw data needed to create the regular results cards */
  results: PropTypes.array.isRequired,

  /** An array of the raw data needed to create the fidelity results cards */
  fidelityResults: PropTypes.array.isRequired,

  /** True if result table is still loading */
  someResultsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const allResults = getCardResultsData(state);
  const resultsError = getFundSearchParsedError(state);
  const fidelityResultsError = getFidelityFundSearchParsedError(state);
  return {
    results: allResults.results,
    fidelityResults: allResults.fidelityResults,
    resultsError,
    fidelityResultsError,
    someResultsLoading: getSomeResultsLoading(state),
    criteriaFilters: getAllCriteriaFilters(state),
    criteriaDefinitions: getCriteriaDefinitions(state),
    activeCriteria: getActiveCriteria(state),
    numFidelityResults: getFidelityMatches(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateActiveCriteria: (critArray, filterGroupSelections, source = 'resultsTable') => {
      dispatch(Creators.updateCriteria(critArray, filterGroupSelections, source));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsCardView);
