import React from 'react';
import { connect } from 'react-redux';
import { getMadLibsSelections } from '../../reducers/QuickCriteriaMenuReducer';
import { getOverallTotalMatches } from '../../reducers/TotalMatchesReducer';
import MadLibsSelectionsContainer from './MadLibsSelectionsContainer'

const MadLibsResultsTitle = ({
  selections,
  totalMatches
}) => {

  return (
    <div>
        <MadLibsSelectionsContainer madLibsMatches={totalMatches} madLibsSelections={selections}/>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    selections: getMadLibsSelections(state),
    totalMatches: getOverallTotalMatches(state),
  };
};

const MadLibsResultsTitleHeader = connect(mapStateToProps)(MadLibsResultsTitle)
export default MadLibsResultsTitleHeader;