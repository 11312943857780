import { connect } from 'react-redux';
import ActiveCriteria from 'components/ActiveCriteria';
import { getActiveCriteriaList } from 'reducers/selectors/CriteriaSelectors';
import { getExpandedCriterion } from 'reducers/ExpandedCriterionReducer';
import { getOverallTotalMatches } from 'reducers/TotalMatchesReducer';
import Creators from 'actions/CriteriaActions';
import { CLEAR_ALL_SOURCE } from 'utilities/odaUtils';

const mapStateToProps = (state, ownProps) => {
  return {
    activeCriteria: getActiveCriteriaList(state),
    expandedCriterion: getExpandedCriterion(state),
    totalMatches: getOverallTotalMatches(state),
    setFocusCriteria: ownProps.setFocusCriteria,
    focusCriteria: ownProps.focusCriteria,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCritRemoved: (crit, source) => {
      dispatch(Creators.removeCriterion(crit, source));
    },
    onClearAll: () => {
      const source = CLEAR_ALL_SOURCE.CRITERIA_MENU;
      dispatch(Creators.clearAll(source));
    },
    updateExpandedCrit: crit => {
      dispatch(Creators.updateExpandedCrit(crit));
    },
  };
};

const ActiveCriteriaContainer = connect(mapStateToProps, mapDispatchToProps)(ActiveCriteria);

export default ActiveCriteriaContainer;
