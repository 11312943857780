import React, { Component } from 'react';
import QuickCriteriaClassPopupHeader from 'components/StartPage/QuickCriteriaClassPopupHeader';
import { sorting } from 'utilities';

class FundPicksPopup extends Component {
  constructor() {
    super();

    this.state = {
      displayedSection: 'All Categories',
    };
  }

  handleHoverOverHeader = newDisplayedSection => {
    this.setState({ displayedSection: newDisplayedSection });
  };

  _renderPopupHeaders = (categories = {}) => {
    const headers = Object.keys(categories).sort(sorting.alphabetSort);

    return headers.map(header => (
      <QuickCriteriaClassPopupHeader
        key={header}
        displayedSection={this.state.displayedSection}
        handleChangeActiveClass={this.props.handleChangeActiveClass}
        handleHoverOverHeader={this.handleHoverOverHeader}
        label={header}
      />
    ));
  };

  render() {
    const { isOpen, categories } = this.props;
    const popupHeaders = this._renderPopupHeaders(categories);
    const displayStyle = isOpen ? '' : 'off';
    
    return (
      <div className={`quick-criteria-class-popup ${displayStyle}`}>
        <div className="container">
          <div className="row">
            <div className="headers-container">{popupHeaders}</div>
          </div>
        </div>
      </div>
    );
  }
}


export default FundPicksPopup;
