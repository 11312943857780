const MOBILE_PIXEL_WIDTH = 768;
const MOBILE_LANDSCAPE_PIXEL_WIDTH = 813;

export const isMobile = () => {
  const isMobileLandscapeView =
    window.innerWidth > window.innerHeight && window.innerWidth < MOBILE_LANDSCAPE_PIXEL_WIDTH;

  const isMobile = window.innerWidth < MOBILE_PIXEL_WIDTH;
  return isMobile || isMobileLandscapeView;
};

export const isScreenMobile = () => {
  const isMobileLandscapeView =
    window.screen.width > window.screen.height && window.screen.width < MOBILE_LANDSCAPE_PIXEL_WIDTH;

  const isMobile = window.screen.width < MOBILE_PIXEL_WIDTH;
  return isMobile || isMobileLandscapeView;
};

const mobileUtils = {
  MOBILE_PIXEL_WIDTH,
  MOBILE_LANDSCAPE_PIXEL_WIDTH,
  isMobile,
  isScreenMobile
};

export default mobileUtils;
