import getDefaultConfig from 'chartiq/js/defaultConfiguration.js';

const getConfig = (resources = {}) => {
  // Enabled deprecated components after updating from 9.0 to 9.1
  resources.deprecatedSettings = true;
  const config = getDefaultConfig(resources);

  // This needs to be false in order to enable initial data to be used
  config.restore = false;
  config.enabledAddOns = {
    fullScreen: false,
    rangeSlider: true,
    tableView: true,
    tooltip: false,
  };
  config.initialSymbol = '';
  config.plugins.tfc.allowUniqueAccountConstruction = true;
  config.chartEngineParams.preferences.currentPriceLine = false;
  config.chartEngineParams.layout = {
    chartType: 'line',
    crosshair: true,
  };
  config.rangeMenu = [
    { type: 'range', label: 'YTD', cmd: "set(1,'YTD', 1, 1, 'day')"},
    { type: 'range', label: '1Y', cmd: "set(1,'year', 1, 1, 'day')" },
    { type: 'range', label: '3Y', cmd: "set(3,'year', 1, 1, 'month')" },
    { type: 'range', label: '5Y', cmd: "set(5,'year', 1, 1, 'month')"},
    { type: 'range', label: '10Y', cmd: "set(10,'year', 1, 1, 'month')"},
  ];

  // This is the slider below the main chart. The rangeslider add on needs to be set to true to endable this
  config.addOns.rangeSlider = {
    height: '80px',
  };

  return config;
};

export default getConfig;
