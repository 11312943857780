import { createReducer } from 'reduxsauce';
import Types from '../actions/Types';

// the initial state of this reducer
export const INITIAL_STATE = false;
export const updateCriteriaMenuExpanded = (state = INITIAL_STATE, action = {}) => {
  return action.isCriteriaMenuExpanded;
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.UPDATE_CRITERIA_MENU_EXPANDED]: updateCriteriaMenuExpanded,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const getCriteriaMenuExpanded = state => state.criteriaMenuExpanded;
