import _ from 'lodash';
import Types from 'actions/Types';
import { PLAN_NUMBER, FUND_IDS_FIELD } from 'configs/AppFeatureConfig';
import { DEFAULT_SEARCH_PARAMETERS_CONFIG } from 'configs/DefaultSearchParametersConfig';
import { CRITERIA_IDS_BY_FILTER_GROUP, CRITERIA_CONFIG } from 'configs/CriteriaConfig';
import { criteriaUtils } from 'utilities';
import { getSelectedFundIds } from 'reducers/FundsSelectedReducer';
import { getCriteriaDefinitions } from 'reducers/selectors/CriteriaSelectors';
import { getViewTab } from 'reducers/ViewTabReducer';
import { getCurrentViewTabsByShortName } from 'reducers/selectors/ViewTabSelectors';
import { getBusinessChannel } from 'reducers/BusinessChannelReducer';
import { getPolicyCode } from 'reducers/PolicyInfoReducer';

export const constructDefaultParams = () => {
  const params = {};

  _.each(DEFAULT_SEARCH_PARAMETERS_CONFIG, param => {
    params[param.serviceField] = param.value;
  });
  params.planNumber = PLAN_NUMBER;

  return params;
};

export const constructSearchParams = (state, filters = {}) => {
  let { critFilters, criteriaDefinitions }=!_.isNil(filters)?filters:{};
  critFilters = !_.isNil(critFilters) ? critFilters : state.criteriaFilters;
  criteriaDefinitions = !_.isNil(criteriaDefinitions) ? criteriaDefinitions : getCriteriaDefinitions(state);

  let searchParams = constructDefaultParams();
  _.each(critFilters, (filters, filterGroupId) => {
    const critId = CRITERIA_IDS_BY_FILTER_GROUP[filterGroupId];
    const critDefinition = criteriaUtils.getCriterionDefinition(criteriaDefinitions, critId);
    if (!_.isNil(critDefinition)) {
      const critType = critDefinition.criteriaType;
      const filterHasSelections = !!filters.length;
      //TODO: once all criteria types are working, clean up this if bloc to remove all of the || conditions
      if (filterHasSelections) {
        if (critType === 'multiSelect') {
          const multiSelectDefault = { lowerRange: '', upperRange: '' };
          let lowerRangeVal = _.first(filters);
          let upperRangeVal = _.last(filters);

          if (lowerRangeVal === 'LS') lowerRangeVal = null;
          if (upperRangeVal === 'GT') upperRangeVal = null;
          searchParams[filterGroupId] = {
            ...multiSelectDefault,
            lowerRange: (lowerRangeVal && lowerRangeVal.trim()) || null,
            upperRange: (upperRangeVal && upperRangeVal.trim()) || null,
          };
        } else if (critType === 'multiField') {
          const { filterMaps } = critDefinition;
          const urlValue = _.first(filters) || null;
          _.each(filterMaps, filterMap => {
            if (filterMap.urlValue === urlValue) {
              searchParams[filterMap.searchField] = filterMap.serviceValue;
            }
          });
        } else if (critType === 'checkbox' || critType === 'radioButton' || critType === 'fundFamily') {
          if (!_.isNil(critDefinition.filterMaps)) {
            const urlValue = _.first(filters) || null;
            const { filterMaps } = critDefinition;
            _.each(filterMaps, filterMap => {
              if (filterMap.urlValue === urlValue) {
                searchParams[filterGroupId] = filterMap.serviceValue;
              }
            });
          } else {
            searchParams[filterGroupId] = filters.join(',');
          }
        } else if (critType === 'fundType') {
          searchParams[filterGroupId] = filters.join(',');
        }
      }
    }
  });
  return searchParams;
};

export const allMadLibsDataStructureFormatting = (quickCriteriaMenu, action) => {
  //additional criteria nextSelections
  const additionalCriteriaNextSelectionsFunction = (selections) => selections.filter(crit => crit.selected).reduce((acc, sel) => ({ ...acc, ...sel.criteria }), {})
  const additionalCriteriaNextSelections = action && action.additionalCriteria ? additionalCriteriaNextSelectionsFunction(action.additionalCriteria)
    : additionalCriteriaNextSelectionsFunction(quickCriteriaMenu.madLibsAdditionalCriteria)

  //dropdown criteria nextSelections
  const dropDownCriteriaNextSelectionsFunction = (selections) => _.initial(selections).map(sel => sel.options.filter(selection => {
    return (sel.selected === selection.label) ? selection.criteria : null
  })).reduce((acc, val) => acc.concat(val), []).reduce((acc, obj) => ({ ...acc, ...obj.criteria }), {})

  const dropDownCriteriaNextSelections = action && action.selections ? dropDownCriteriaNextSelectionsFunction(action.selections)
    : dropDownCriteriaNextSelectionsFunction(quickCriteriaMenu.madLibsSelections)

  const combinedNextSelections = _.mergeWith(dropDownCriteriaNextSelections, additionalCriteriaNextSelections, customizer)
  function customizer(objValue, srcValue) {
    if (_.isArray(objValue)) {
      return _.uniq(objValue.concat(srcValue));
    }
  }
  const totalSelections = {};
  _.defaults(totalSelections, combinedNextSelections);
  return totalSelections
}

export const getSubjectAreas = (state, { viewTabShortName = null, isMobile = null, displayType = null } = {}) => {
  viewTabShortName = !_.isNil(viewTabShortName) ? viewTabShortName : getViewTab(state);
  isMobile = !_.isNil(isMobile) ? isMobile : state.mobileStatus.isMobile;
  displayType = !_.isNil(displayType) ? displayType : state.displayType;
  if (displayType === 'start') {
    // get subject areas from table/card display type when on start page
    displayType = isMobile ? 'card' : 'table';
  }
  const currentViewTabsByShortName = getCurrentViewTabsByShortName({
    ...state,
    viewTab: viewTabShortName,
    displayType,
  });

  if (isMobile) {
    const cardSubjectAreas = _.get(currentViewTabsByShortName, ['card', 'subjectAreas'], []);
    const currentViewTabSubjectAreas = _.get(currentViewTabsByShortName, [viewTabShortName, 'subjectAreas'], []);
    return _.uniq([...cardSubjectAreas, ...currentViewTabSubjectAreas]);
  }

  const defaultSubjectAreas = _.get(currentViewTabsByShortName, ['overview', 'subjectAreas'], []);
  return _.get(currentViewTabsByShortName, [viewTabShortName, 'subjectAreas'], defaultSubjectAreas);
};

export const dispatchCompareViewSearch = (
  store,
  {
    subjectAreas = null,
    selectedFundIds = null,
    sortBy = null,
    sortOrder = null,
    symbolToAdd = null,
    isMobile = null,
    viewTabShortName = null,
    policyCode = null,
  } = {}, action
) => {

  const state = store.getState();
  isMobile = !_.isNil(isMobile) ? isMobile : state.mobileStatus.isMobile;
  viewTabShortName = !_.isNil(viewTabShortName) ? viewTabShortName : state.viewTab;

  subjectAreas = !_.isNil(subjectAreas)
    ? subjectAreas
    : getSubjectAreas(state, { viewTabShortName, isMobile, displayType: 'compare' });
  policyCode = !_.isNil(policyCode) ? policyCode : getPolicyCode(state);
  selectedFundIds = !_.isNil(selectedFundIds) && selectedFundIds.length > 0 ? selectedFundIds : action && action.fundsSelected ? action.fundsSelected.split(',') : getSelectedFundIds(state);
  if (selectedFundIds.length === 0) {
    selectedFundIds = _.get(state, ['router', 'query', FUND_IDS_FIELD], '').split(',');
  }
  if (!_.isNil(symbolToAdd)) {
    selectedFundIds = [...selectedFundIds, symbolToAdd];
  }
  sortBy = !_.isNil(sortBy) ? sortBy : state.sortResults.sortCol;
  sortOrder = !_.isNil(sortOrder) ? sortOrder : state.sortResults.sortDir;
  const searchParams = {
    [FUND_IDS_FIELD]: selectedFundIds.join(','),
    planNumber: PLAN_NUMBER,
  };
  const isFidelityFundsOnlySearch = searchParams.fidelityFundOnly || null;
  const currentPageNumber = 1;

  store.dispatch({
    type: Types.FUND_SEARCH_API,
    subjectAreas,
    searchParams,
    sortBy,
    sortOrder,
    currentPageNumber,
    isFidelityFundsOnlySearch: !!isFidelityFundsOnlySearch,
    symbolToAdd,
    displayType: 'compare',
    policyCode,
  });
};

// NOTE: must not be arrow function to allow arguments[1] to be used
export function dispatchFundSearch(
  store,
  {
    subjectAreas = null,
    searchParams = null,
    sortBy = null,
    sortOrder = null,
    currentPageNumber = null,
    displayType = null,
    isMobile = null,
    viewTabShortName = null,
    policyCode = null,
  } = {},
) {
  const state = store.getState();
  displayType = !_.isNil(displayType) ? displayType : state.displayType;
  if (displayType === 'compare') {
    //If in compare view, grab the arguments passed in to this function and call 'dispatchCompareViewSearch' instead
    const argsPassedIn = { ...arguments[1] };
    dispatchCompareViewSearch(store, argsPassedIn);
    return;
  }
  //use values that were passed in and retrieve the others from redux store
  isMobile = !_.isNil(isMobile) ? isMobile : state.mobileStatus.isMobile;
  viewTabShortName = !_.isNil(viewTabShortName) ? viewTabShortName : state.viewTab;
  searchParams = !_.isNil(searchParams) ? searchParams : constructSearchParams(state);
  sortBy = !_.isNil(sortBy) ? sortBy : state.sortResults.sortCol;
  sortOrder = !_.isNil(sortOrder) ? sortOrder : state.sortResults.sortDir;
  currentPageNumber = !_.isNil(currentPageNumber) ? currentPageNumber : state.resultsPageNum;
  subjectAreas = !_.isNil(subjectAreas)
    ? subjectAreas
    : getSubjectAreas(state, { ...state, viewTabShortName, isMobile, displayType });
  policyCode = !_.isNil(policyCode) ? policyCode : getPolicyCode(state);
  const isFidelityFundsOnlySearch = searchParams.fidelityFundOnly || null;

  store.dispatch({
    type: Types.FUND_SEARCH_API,
    subjectAreas,
    searchParams,
    sortBy,
    sortOrder,
    currentPageNumber,
    isFidelityFundsOnlySearch: !!isFidelityFundsOnlySearch,
    displayType,
    policyCode,
  });
}

export const constructDownloadResults = (
  state,
  { filters },
  {
    subjectAreas = null,
    searchParams = null,
    sortBy = null,
    sortOrder = null,
    currentPageNumber = null,
    displayType = null,
    isMobile = null,
  } = {},
) => {
  const filterMap = {
    overview: 'Overview',
    risk: 'Risk',
    'management-fees': 'ManagementAndFees',
    'morningstar-rankings': 'MorningstarRankings',
    'income-characteristics': 'IncomeCharacteristics',
    'short-term-perf': 'ShortTermPerformance',
    'daily-pricing-yields': 'DailyPricingAndYields',
  };

  let tabNames = [];
  let allSubjects = [];

  filters.forEach(filter => {
    if (filterMap[filter]) {
      tabNames.push(filterMap[filter]);
    }
  });

  filters.forEach(filter => {
    const subjects = !_.isNil(subjectAreas)
      ? subjectAreas
      : getSubjectAreas(state, { ...state, viewTabShortName: filter, isMobile, displayType });
    allSubjects = [...allSubjects, ...subjects];
  });

  const uniqueSubjects = [...new Set(allSubjects.map(subject => subject))];
  const businessChannel = getBusinessChannel(state);
  searchParams = !_.isNil(searchParams) ? searchParams : constructSearchParams(state);
  sortBy = !_.isNil(sortBy) ? sortBy : state.sortResults.sortCol;
  sortOrder = !_.isNil(sortOrder) ? sortOrder : state.sortResults.sortDir;
  currentPageNumber = !_.isNil(currentPageNumber) ? currentPageNumber : state.resultsPageNum;

  return {
    businessChannel,
    currentPageNumber,
    noOfRowsPerPage: 100,
    searchFilter: searchParams,
    sortBy,
    sortOrder,
    subjectAreaCode: uniqueSubjects.toString(),
    tabNames: tabNames.toString(),
  };
};

export const getSociallyResponsibleAttributesValues = (selection) =>{
  let criteriaConfig = CRITERIA_CONFIG.find(e=>e.id === 'sociallyResponsibleAttributes');
  if (criteriaConfig) {
    let esgCodes = [];
    _.each(criteriaConfig.criteriaMenuItemList, item => {
      if (item.filterGroups && item.filterGroups.sustainableInvestment && (!selection || selection === 'sustainableInvestment')){
        _.each(item.filterGroups.sustainableInvestment, i => {
          esgCodes.push(i.value);
        });
      } else if (item.filterGroups && item.filterGroups.employsExclusions && (!selection || selection === 'employsExclusions')){
        _.each(item.filterGroups.employsExclusions, i => {
          esgCodes.push(i.value);
        });
      }
    });
    return esgCodes.join(",");
  }
  return '';
}

const apiUtils = {
  constructDefaultParams,
  constructSearchParams,
  getSubjectAreas,
  dispatchCompareViewSearch,
  dispatchFundSearch,
  constructDownloadResults,
  allMadLibsDataStructureFormatting,
  getSociallyResponsibleAttributesValues,
};

export default apiUtils;
