import { connect } from 'react-redux';
import ResultsPagination from 'components/ResultsView/ResultsPagination';
import { getCurrentResultsPageNum, getTotalNumPages } from 'reducers/ResultsPageNumReducer';
import { getOverallTotalMatches } from 'reducers/TotalMatchesReducer';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import Creators from 'actions/CriteriaActions';

const mapStateToProps = state => {
  return {
    currentResultsPageNum: getCurrentResultsPageNum(state),
    totalNumPages: getTotalNumPages(state),
    totalMatches: getOverallTotalMatches(state),
    displayType: getDisplayType(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateResultsPageNum: pageNum => {
      dispatch(Creators.updateResultsPageNum(pageNum));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResultsPagination);
