import React from 'react';
import PropTypes from 'prop-types';
import { icons } from 'utilities';
import _ from 'lodash';

const MAX_ICON_COUNT = 5;

const _renderMorningstarIcons = (activeIcons, icon) => {
  if (_.isNaN(activeIcons)) activeIcons = 5;

  const icons = [];

  for (let i = 0; i < MAX_ICON_COUNT; i++) {
    if (i >= activeIcons) {
      icons.push(<span key={i} className={`${icon} greyed-out`} />);
    } else {
      icons.push(<span key={i} className={`${icon}`} />);
    }
  }

  return <div className="icons">{icons}</div>;
};

const _renderFidelityIcons = icon => {
  return (
    <div className="icons">
      <img src={icons[icon]} className="icon" alt={`${icon} icon`} />
    </div>
  );
};

const _renderNoIcon = () => {
  return <span>&nbsp;</span>;
};

const QuickCriteriaClassCheckbox = ({
  classNames,
  criteriaName,
  handleCheck,
  handleLableClick,
  icon,
  identifier,
  isChecked,
  isPartialChecked,
  label,
  children,
  glossaryDefinition,
}) => {
  const checkedClassName = isChecked ? 'checked' : 'unchecked';
  let displayIcon = null;

  if (label === 'Include ETFs') {
    displayIcon = _renderNoIcon();
  }
  if (icon === 'star' || icon === 'box') {
    displayIcon = _renderMorningstarIcons(Number(label), icon);
  } else if (icon) {
    displayIcon = _renderFidelityIcons(icon);
  }

  const callbackArg = identifier || label;

  return (
    <div className={`quick-criteria-class-checkbox checkbox-input ${classNames} ${checkedClassName}`}  
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div>
        <label for={label}>
          <svg
            class={`checkbox ${isPartialChecked ? 'partial-check' : ''}`}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              handleCheck(callbackArg, !isChecked, criteriaName);
            }}
          >
            <path class="checkmark" d="M 5,11 L 8,14 L 14, 6" />
            <path class="dash" d="M 5,10 L 16,10" />
          </svg>
          <span style={{'margin-left':'5px'}}>
            <span class="checkbox-label2"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleCheck(callbackArg, !isChecked, criteriaName);
              }}
            >{label}</span>
            {displayIcon}
            {glossaryDefinition}
          </span>
          {children}
        </label>
      </div>
    </div>
  );
};
QuickCriteriaClassCheckbox.defaultProps = {
  classNames: '',
  isPartialChecked: false,
  handleLableClick: null,
};

QuickCriteriaClassCheckbox.propTypes = {
  /** Additional classes for the top level component */
  classNames: PropTypes.string,

  /** If specified, will fire callback with this value instead of label */
  identifier: PropTypes.string,

  /** Name of the criteria associated with this filter */
  criteriaName: PropTypes.string.isRequired,

  /** Handles selecting a filter */
  handleCheck: PropTypes.func.isRequired,
  handleLableClick: PropTypes.func.isRequired,

  /** Can be either one of the Fidelity icons, or Morningstar stars/boxes */
  icon: PropTypes.string,

  isChecked: PropTypes.bool.isRequired,

  /** Name of the filter that is displayed along with the checkbox*/
  label: PropTypes.string.isRequired,
};

export default QuickCriteriaClassCheckbox;
