import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { icons, strategiesUtils } from 'utilities';
import Creators from 'actions/CriteriaActions';
import { FUND_STRATEGIES_CONFIG } from 'configs/FundStrategiesConfig';
import { ROUTE_CONSTANTS } from 'configs/AppFeatureConfig';
import NestedFundStrategiesContainer from 'components/StartPage/NestedFundStrategiesContainer';
import FundStrategy from 'components/StartPage/FundStrategyContainer';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getRouterRoute } from 'reducers/selectors/RouterSelectors';
import * as odaTracking from 'utilities/odaTracking';

const MAX_COLLAPSED_STRATEGIES = 8;

class FundStrategies extends Component {
  constructor() {
    super();

    this.state = {
      isExpanded: false,
    };
  }

  _renderStrategies = () => {
    let visibleStrategyCount = 0;
    const strategies = _.map(FUND_STRATEGIES_CONFIG, (strategy, i) => {
      const isHiddenByCollapse = !this.state.isExpanded && visibleStrategyCount >= MAX_COLLAPSED_STRATEGIES;
      if (isHiddenByCollapse || strategy.isHidden) return null;
      visibleStrategyCount++;
      if (strategy.isNestedStrategy) return this._renderNestedStrategy(strategy);

      return <FundStrategy key={strategy.id} strategy={strategy} />;
    });

    return strategies;
  };

  _renderNestedStrategy(strategy) {
    const handleClick = () => {
      this.props.viewNestedStrategy(strategy.id);
    };
    return <FundStrategy key={strategy.id} strategy={strategy} handleClick={handleClick} />;
  }

  _renderIndividualStrategy(strategy, icon) {
    return (
      <div
        className="fund-strategy"
        key={strategy.label}
        onClick={() => {
          this._handleClickStrategy(strategy);
        }}
      >
        <img src={icon} alt="Icon representing this fund strategy" />
        <button className="as-link" dangerouslySetInnerHTML={{ __html: strategy.label }} />
        <div className="strategy-match-count">-- funds</div>
      </div>
    );
  }

  _handleClickStrategy = strategy => {
    const {
      critIdArray,
      parsedSelections,
      strategyId,
      viewTabShortName,
      sortParams,
    } = strategiesUtils.parseLoadStrategyParams(strategy);
    this.props.loadStrategy(critIdArray, parsedSelections, strategyId, viewTabShortName, sortParams);
  };

  _renderToggleElement = () => {
    let text = '';
    let icon = '';

    if (!this.state.isExpanded) {
      text = 'Show More Strategies';
      icon = icons.arrowDown;
    } else {
      text = 'Show Fewer Strategies';
      icon = icons.arrowUp;
    }

    return (
      <div className="show-strategies-toggle">
        <button
          className="as-link"
          onClick={() => {
            this._handleExpandCollapse();
          }}
        >
          <img src={icon} alt="Arrow Icon" />
          {text}
        </button>
      </div>
    );
  };

  _handleExpandCollapse = () => {
    const newIsExpanded = !this.state.isExpanded;

    this.setState({
      isExpanded: newIsExpanded,
    });

    this.props.trackShowMoreStrategies(newIsExpanded);

    if (!newIsExpanded) {
      this.scrollToTopWhenShowingFewer();
    }
  };

  scrollToTopWhenShowingFewer = () => {
    const critMenu = document.getElementsByClassName('view-tabs')[0];
    const offsetTop = critMenu.getBoundingClientRect().top + document.documentElement.scrollTop;
    window.scroll({
      top: offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  };

  render() {
    const strategies = this._renderStrategies();
    const displayStyle = this.state.isExpanded ? 'expanded' : 'collapsed';
    const toggleElement = this._renderToggleElement();
    return (
      <div className="fund-strategies">
        {this.props.match.path === ROUTE_CONSTANTS.NESTED_STRATEGIES ? <NestedFundStrategiesContainer /> : null}
        {this.props.match.path === ROUTE_CONSTANTS.STRATEGIES ? (
          <React.Fragment>
            <p className="fund-strategies-teaser">
              Focus on an investment strategy, particular goals, or group of highly rated funds. Customize your criteria
              and make changes as your interest or needs evolve. Feel confident knowing that all funds have been
              selected by Fidelityâ€™s investment professionals, upon meeting our selection criteria.
            </p>
            <div className={`all-strategies ${displayStyle}`}>{strategies}</div>
            {toggleElement}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

FundStrategies.propTypes = {
  /** Redux action to load the strategy */
  loadStrategy: PropTypes.func.isRequired,

  /** Opens a nested strategy to view the sub-strategies contained within */
  viewNestedStrategy: PropTypes.func.isRequired,

  /**Current Display type of application: compare,table,card,etc. */
  displayType: PropTypes.string.isRequired,

  /**Current route */
  match: PropTypes.object.isRequired,

  /** Dispatch action for tracking show more strategies */
  trackShowMoreStrategies: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    displayType: getDisplayType(state),
    route: getRouterRoute(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadStrategy: (critArray, filterGroupSelections, strategyId, viewTabShortName, sortParams) => {
      dispatch(Creators.loadStrategy(critArray, filterGroupSelections, strategyId, viewTabShortName, sortParams));
    },
    viewNestedStrategy: strategyId => {
      dispatch(Creators.viewNestedStrategy(strategyId));
    },
    trackShowMoreStrategies: expanded => {
      const trackingData = odaTracking.getStrategiesToggleMoreStrategiesTrackingData(expanded);
      dispatch(Creators.odaLogAction(trackingData));
    },
  };
};

const FundStrategiesContainer = connect(mapStateToProps, mapDispatchToProps)(FundStrategies);

export default FundStrategiesContainer;
export { FundStrategies };
