import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';
import _ from 'lodash';
import { ROUTE_CONSTANTS, START_PAGE_TYPE } from 'configs/AppFeatureConfig';
import Types from 'actions/Types';

export const INITIAL_STATE = {
  activeTab: START_PAGE_TYPE === 'fili' ? 'annuityOptions' : 'quickCriteria',
  subSection: '',
};

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};

const changeViewTab = (state = INITIAL_STATE, action = {}) => {
  return { subSection: '', activeTab: action.viewTab };
};

const viewNestedStrategy = (state = INITIAL_STATE, action = {}) => {
  return { ...state, subSection: action.strategyId };
};

export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
  if (action.payload.route === ROUTE_CONSTANTS.INDEX) {
    const activeTab = START_PAGE_TYPE === 'fili' ? 'annuityOptions' : 'quickCriteria';
    return { activeTab, subSection: '' };
  } else if (action.payload.route === ROUTE_CONSTANTS.STRATEGIES) {
    return { activeTab: 'fundStrategies', subSection: '' };
  } else if (action.payload.route === ROUTE_CONSTANTS.NESTED_STRATEGIES) {
    const strategyId = _.get(action, 'payload.params.strategy', '');
    return { activeTab: 'fundStrategies', subSection: strategyId };
  } else if (action.payload.route === ROUTE_CONSTANTS.PICKS) {
    return { activeTab: 'fundPicks', subSection: '' };
  } else if (action.payload.route === ROUTE_CONSTANTS.WAYS_TO_INVEST) {
    return { activeTab: 'waysToInvest', subSection: '' };
  }
  return state;
};

const clearAll = (state = INITIAL_STATE, action = {}) => {
  return INITIAL_STATE;
};

const ACTION_HANDLERS = {
  [Types.CHANGE_START_PAGE_VIEW_TAB]: changeViewTab,
  [Types.VIEW_NESTED_STRATEGY]: viewNestedStrategy,
  "@@router/LOCATION_CHANGE": routerLocationChanged,
  [Types.CLEAR_ALL]: clearAll,
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

// ---- Selectors ----

export const getStartPageActiveViewTab = state => state.startPageViewTab.activeTab;
export const getStartPageActiveSubSection = state => state.startPageViewTab.subSection;
