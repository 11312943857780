import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Creators from 'actions/CriteriaActions';
import { makeGetToggleGroupSelections } from 'reducers/ResultToggleGroupReducer';
import _ from 'lodash';
import { START_PAGE_TYPE } from 'configs/AppFeatureConfig';

class ResultToggleGroup extends Component {
  constructor(props) {
    super(props);
    this.dropdownGroupref = React.createRef();
    this.state = {
      isDropdownExpanded: false,
      selectedId: this.props.currentSelection.id,
    };
  }
  componentDidUpdate = prevProps => {
    if (this.props.scrollPos !== prevProps.scrollPos) {
      this.setState({ isDropdownExpanded: false });
    }
  };
  _getToggleGroupLinkPosition = () => {
    if (this.refs.toggleGroupLink) {
      const toggleGroupRect = this.refs.toggleGroupLink.getBoundingClientRect();
      const top = Math.floor(toggleGroupRect.bottom); // using bottom because we want the top of the dropdown to be even with the bottom of the link
      const left = Math.floor(toggleGroupRect.left);
      return { top, left };
    }
    return null;
  };
  _getToggleGroupDropdownStyle = () => {
    if (!this.props.isFixed) {
      return null;
    }
    return { ...this._getToggleGroupLinkPosition() };
  };
  _focusCurrentId = id => {
    const classNameForAlink = `option-item-${id}`;
    const found = this.dropdownGroupref.current.querySelector('.' + classNameForAlink);
    if (found) {
		found.focus();
    }
  };
  _toggleDropdown = () => {
    this.setState({ isDropdownExpanded: !this.state.isDropdownExpanded, selectedId: this.props.currentSelection.id });
    if (!this.state.isDropdownExpanded) {
      setTimeout(() => {
        this._focusCurrentId(this.props.currentSelection.id);
      }, 300);
    }
  };
  _updateCurrentSelection = selection => {
    this.props.updateToggleGroupSelection(this.props.groupShortName, selection);
    this.setState({ isDropdownExpanded: false, selectedId: selection.id });
	const found2 = this.dropdownGroupref.current.querySelector('.dropdown-arrows-small');
	if (found2) {
		found2.focus();
	}
  };
  _renderColOptions = () => {
   const columnOptions = _.map(this.props.resultList, result => {
      const classNameForGA = this.props.isSorted
        ? `${START_PAGE_TYPE}-morningstar-${result.label.replace(/[' ']/g, '')}`
        : '';
      const classNameForAlink = `option-item-${result.id}`;
      const className = `toggle-group-option ${
        result.id === this.state.selectedId ? 'active' : ''
      } ${classNameForGA} ${classNameForAlink}`;
      return (
        <li
          key={result.id}
          className={className}
          role="option"
          aria-selected={result.id === this.state.selectedId}
          onClick={() => {
            this._updateCurrentSelection(result);
          }}
          tabIndex="0"
        >
          {result.label}
        </li>
      );
    });
    const dropdownClass = this.props.isFixed ? 'toggle-group-dropdown fixed' : 'toggle-group-dropdown';
    const dropdownStyle = this._getToggleGroupDropdownStyle();
	const dropdownHidden = this.state.isDropdownExpanded?"":"dropdown-hidden"
	const ulClassName = dropdownClass + ' ' + dropdownHidden
    return (
      <ul
        className={ulClassName}
        style={dropdownStyle}
        role="listbox"
        tabIndex="-1"
        onKeyDown={e => this._keyNavigation(e, 'down')}
      >
        {columnOptions}
      </ul>
    );
  };
  _renderCurrentSelection = () => {
    return (
      <button
        ref="toggleGroupLink"
        className="text-as-link dropdown-arrows-small transparent-btn"
        onClick={this._toggleDropdown}
        aria-haspopup="listbox"
        aria-expanded={this.state.isDropdownExpanded === true}
      >
        {this.props.currentSelection.label}
      </button>
    );
  };

  setPreviousItemId = () => {
    const found = this.props.resultList.findIndex(t => t.id === this.state.selectedId);
    let id = '';
    if (found <= 0) {
      id = this.props.resultList[this.props.resultList.length - 1].id;
    } else {
      id = this.props.resultList[found - 1].id;
    }
    this.setState({ isDropdownExpanded: this.state.isDropdownExpanded, selectedId: id });
    this._focusCurrentId(id);
  };
  setNextItemId = () => {
    const found = this.props.resultList.findIndex(t => t.id === this.state.selectedId);
    let id = '';
    if (found < this.props.resultList.length - 1) {
      id = this.props.resultList[found + 1].id;
    } else {
      id = this.props.resultList[0].id;
    }
    this.setState({ isDropdownExpanded: this.state.isDropdownExpanded, selectedId: id });
    this._focusCurrentId(id);
  };
  _keyNavigation = (e, from) => {
    e.stopPropagation();
    e.preventDefault();
    if (e.shiftKey && e.keyCode === 9) {
      // shift + tab
      this.setPreviousItemId();
    } else if (e.keyCode === 9 || e.keyCode === 40) {
      // tab, down
      this.setNextItemId();
    } else if (e.keyCode === 38) {
      // up
      this.setPreviousItemId();
    } else if (e.keyCode === 13 || e.keyCode === 32) {
      //enter, space
      const found = this.props.resultList.find(t => t.id === this.state.selectedId);
      if (found) {
        this._updateCurrentSelection(found);		
      }
    } else if (e.keyCode === 27) {
      //esc
	  this.setState({ isDropdownExpanded: false, selectedId: this.props.currentSelection.id });
	  const found = this.dropdownGroupref.current.querySelector('.dropdown-arrows-small');
	  if (found) {
		  found.focus();
	  }
    }
  };

  render() {
    return (
      <div className="result-toggle-group"  ref={this.dropdownGroupref}>
        {this._renderCurrentSelection()}
        {this._renderColOptions()}
      </div>
    );
  }
}

ResultToggleGroup.propTypes = {
  resultList: PropTypes.array.isRequired,
  groupLabel: PropTypes.string.isRequired,
  groupShortName: PropTypes.string.isRequired,
  updateToggleGroupSelection: PropTypes.func.isRequired,
  currentSelection: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const getToggleGroupSelections = makeGetToggleGroupSelections();
  return { currentSelection: getToggleGroupSelections(state, ownProps) };
};

const mapDispatchToProps = dispatch => {
  return {
    updateToggleGroupSelection: (resultGroup, selection) => {
      dispatch(Creators.updateResultToggleGroup(resultGroup, selection));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultToggleGroup);
