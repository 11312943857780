import apisauce from 'apisauce';

const create = baseURL => {
  const api = apisauce.create({
    baseURL,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    timeout: 60000,
  });

  const postData = (endpoint, parameters) => {
    return api.post(endpoint, parameters);
  };

  return {
    postData,
  };
};

export default {
  create,
};
