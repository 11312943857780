import React from 'react';
import PropTypes from 'prop-types';
import { icons } from 'utilities';
import _ from 'lodash';
import { prototype } from 'events';

const MAX_ICON_COUNT = 5;

const _renderMorningstarIcons = (activeIcons, icon) => {
  if (_.isNaN(activeIcons)) activeIcons = 5;

  const icons = [];

  for (let i = 0; i < MAX_ICON_COUNT; i++) {
    if (i >= activeIcons) {
      icons.push(<span key={i} className={`${icon} greyed-out`} />);
    } else {
      icons.push(<span key={i} className={`${icon}`} />);
    }
  }

  return <div className="icons">{icons}</div>;
};

const _renderFidelityIcons = icon => {
  return (
    <div className="icons">
      <img src={icons[icon]} className="icon" alt={`${icon} icon`} />
    </div>
  );
};

const _renderNoIcon = () => {
  return <span>&nbsp;</span>;
};

const QuickCriteriaCheckboxFilter = ({
  classNames,
  criteriaName,
  handleClick,
  icon,
  identifier,
  isChecked,
  noneChecked,
  isPartialChecked,
  label,
  labelStyle,
  children,
  glossaryDefinition,
}) => {
  const checkedClassName = isChecked ? 'checked' : 'unchecked';

  let displayIcon = null;
  let ariaDescribedBy = null;

  if (label === "$0" && isChecked) {
    ariaDescribedBy = 'supplemental-text';
  }
  if (label === 'Transaction Fee or Load' && isChecked) {
    ariaDescribedBy = 'supplemental-text';
  }
  if (['Transaction Fee or Load', '$0'].includes(label) && noneChecked) {
    ariaDescribedBy = 'supplemental-text';
  }
  if (label === 'Include ETFs') {
    displayIcon = _renderNoIcon();
  }
  if (icon === 'star' || icon === 'box') {
    displayIcon = _renderMorningstarIcons(Number(label), icon);
  } else if (icon === 'dollar') {
    displayIcon = <span className="dollarSignIcon">$</span>
  } else if (icon) {
    displayIcon = _renderFidelityIcons(icon);
  }

  const callbackArg = identifier || label;

  return (
    <li className={`checkbox-input QuickCriteriaCheckboxFilter-class ${classNames} ${checkedClassName}`}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <input
        type="checkbox"
        name={label}
        id={label}
        checked={isChecked}
        onChange={(e) => {
          handleClick(callbackArg, e.target.checked, criteriaName);
        }}
        aria-checked={isChecked}
        style={{ display: 'none' }}
      />

      <label htmlFor={label} style={labelStyle}>
        <svg
          tabIndex={0}
          className={`checkbox ${isPartialChecked ? 'partial-check' : ''}`}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              e.stopPropagation();
              handleClick(callbackArg, !isChecked, criteriaName)
            }
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClick(callbackArg, !isChecked, criteriaName);
          }}
          role="checkbox"
          aria-checked={isChecked}
          aria-label={label}
          aria-describedby={ariaDescribedBy}
        >
          <path className="checkmark" d="M 5,11 L 8,14 L 14, 6" />
          <path className="dash" d="M 5,10 L 16,10" />
        </svg>
        {displayIcon}
        <span
          className="checkbox-label"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClick(callbackArg, !isChecked, criteriaName);
          }}
        >
          {label}
        </span>
        {glossaryDefinition}
        {children}
      </label>
    </li>
  );
};

QuickCriteriaCheckboxFilter.defaultProps = {
  classNames: '',
  isPartialChecked: false,
};

QuickCriteriaCheckboxFilter.propTypes = {
  /** Additional classes for the top level component */
  classNames: PropTypes.string,

  /** If specified, will fire callback with this value instead of label */
  identifier: PropTypes.string,

  /** Name of the criteria associated with this filter */
  criteriaName: PropTypes.string.isRequired,

  /** Handles selecting a filter */
  handleClick: PropTypes.func.isRequired,

  /** Can be either one of the Fidelity icons, or Morningstar stars/boxes */
  icon: PropTypes.string,

  isChecked: PropTypes.bool.isRequired,

  noneChecked: PropTypes.bool,

  /** Name of the filter that is displayed along with the checkbox*/
  label: PropTypes.string.isRequired,

  /** Label Style  */
  labelStyle: PropTypes.object,
};

export default QuickCriteriaCheckboxFilter;
