import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { icons, strategiesUtils } from 'utilities';
import { connect } from 'react-redux';
import Creators from 'actions/CriteriaActions';

class NestedFundStrategy extends Component {
  _handleLoadingStrategy = () => {
    const {
      critIdArray,
      parsedSelections,
      strategyId,
      viewTabShortName,
      sortParams,
    } = strategiesUtils.parseLoadStrategyParams(this.props.strategy);
    this.props.loadStrategy(critIdArray, parsedSelections, strategyId, viewTabShortName, sortParams);
  };

  render() {
    const { strategy, parentStrategy, handleClick } = this.props;
    const icon = icons[strategy.icon];
    const clickHandler = handleClick || this._handleLoadingStrategy;

    return (
      <div className="nested-fund-strategy" key={strategy.label} onClick={clickHandler}>
        <div className="parent-strategy" dangerouslySetInnerHTML={{ __html: parentStrategy }} />
        <div className="main-content">
          <div className="strategy-name" dangerouslySetInnerHTML={{ __html: strategy.label }} />
          <img src={icon} alt="Icon representing this fund strategy" />
        </div>
        <div className="short-description">{strategy.shortDescription}</div>
        {/* <div className="strategy-match-count">{countText}</div> */}
      </div>
    );
  }
}

NestedFundStrategy.defaultProps = {
  strategy: {
    filterGroupSelections: [],
  },
};

NestedFundStrategy.propTypes = {
  /** Optional function to override loading a strategy */
  handleClick: PropTypes.func,

  /** Redux action to load the strategy */
  loadStrategy: PropTypes.func.isRequired,

  /** The name of the parent strategy that this nested strategy belongs to */
  parentStrategy: PropTypes.string.isRequired,

  /** The strategy configuration object */
  strategy: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    loadStrategy: (critArray, filterGroupSelections, strategyId, viewTabShortName, sortParams) => {
      dispatch(Creators.loadStrategy(critArray, filterGroupSelections, strategyId, viewTabShortName, sortParams));
    },
  };
};

const NestedFundStrategyContainer = connect(null, mapDispatchToProps)(NestedFundStrategy);

export default NestedFundStrategyContainer;
export { NestedFundStrategy };
