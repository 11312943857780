import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { icons } from 'utilities';
import { connect } from 'react-redux';
import { getNestedStrategy } from 'reducers/selectors/FundStrategiesSelectors';
import Creators from 'actions/CriteriaActions';
import NestedFundStrategy from 'components/StartPage/NestedFundStrategyContainer';
import { START_PAGE_VIEW_TAB_SOURCE } from 'utilities/odaUtils';

class NestedFundStrategies extends Component {
  _renderNestedStrategies = () => {
    const strategies = _.map(this.props.strategy.strategiesList, strategy => {
      return <NestedFundStrategy key={strategy.id} strategy={strategy} parentStrategy={this.props.strategy.label} />;
    });

    return strategies;
  };

  render() {
    const { strategy } = this.props;
    const nestedStrategies = this._renderNestedStrategies();
    return (
      <div className="nested-fund-strategies">
        <img
          src={icons.arrowLeft}
          alt="Arrow icon that takes you back to top level strategy view"
          className="arrow-left-icon"
          onClick={() => {
            this.props.exitNestedStrategyView(START_PAGE_VIEW_TAB_SOURCE.NESTED_STRATEGIES_BACK_BUTTON);
          }}
        />
        <img
          src={icons.greyX}
          alt="Close icon that takes you back to top level strategy view"
          className="grey-x-icon"
          onClick={() => {
            this.props.exitNestedStrategyView(START_PAGE_VIEW_TAB_SOURCE.NESTED_STRATEGIES_CLOSE_BUTTON);
          }}
        />
        <h3 dangerouslySetInnerHTML={{ __html: strategy.label }} />
        <p dangerouslySetInnerHTML={{ __html: strategy.description }} />
        <div className={`nested-strategies ${strategy.id}`}>{nestedStrategies}</div>
      </div>
    );
  }
}

NestedFundStrategies.defaultProps = {
  strategy: {
    strategiesList: [],
  },
};

NestedFundStrategies.propTypes = {
  /** Function to return to the top-level strategy view */
  exitNestedStrategyView: PropTypes.func.isRequired,

  /** The current nested strategy */
  strategy: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    strategy: getNestedStrategy(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    exitNestedStrategyView: source => {
      dispatch(Creators.changeStartPageViewTab('fundStrategies', source));
    },
  };
};

const NestedFundStrategiesContainer = connect(mapStateToProps, mapDispatchToProps)(NestedFundStrategies);

export default NestedFundStrategiesContainer;
export { NestedFundStrategies };
