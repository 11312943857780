import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';
import Types from 'actions/Types';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { RESULT_TYPE, RESULT_TYPE_CAPITALIZED } from 'configs/AppFeatureConfig';

export const INITIAL_STATE = {
  fundSearch: null,
  fidelityFundSearch: null,
  quickCriteriaMatches: null,
  fundPicksMatches: null,
  fidelityMatches: null,
  updateFundsSelected: null,
  critFilterMatches: {},
  critTotalMatches: {},
  strategyMatches: {},
  fundFamily: null,
  fundType: null,
  policyInfo: null,
};

const getErrorData = payload => {
  return { httpStatus: payload.status };
};

const fundSearchSuccess = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fundSearch: null };
};

const fundSearchError = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fundSearch: getErrorData(action.payload) };
};

const fidelityFundSearchSuccess = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fidelityFundSearch: null };
};

const fidelityFundSearchError = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fidelityFundSearch: getErrorData(action.payload) };
};

const criteriaFilterMatchesSuccess = (state = INITIAL_STATE, action = {}) => {
  const critFilterMatches = { ...state.critFilterMatches, [action.critId]: null };
  return { ...state, critFilterMatches };
};

const criteriaFilterMatchesError = (state = INITIAL_STATE, action = {}) => {
  const critFilterMatches = { ...state.critFilterMatches, [action.critId]: getErrorData(action.payload) };
  return { ...state, critFilterMatches };
};

const criteriaTotalMatchesSuccess = (state = INITIAL_STATE, action = {}) => {
  const critTotalMatches = { ...state.critTotalMatches, [action.critId]: null };
  return { ...state, critTotalMatches };
};

const criteriaTotalMatchesError = (state = INITIAL_STATE, action = {}) => {
  const critTotalMatches = { ...state.critTotalMatches, [action.critId]: getErrorData(action.payload) };
  return { ...state, critTotalMatches };
};

const strategyMatchesSuccess = (state = INITIAL_STATE, action = {}) => {
  const strategyMatches = { ...state.strategyMatches, [action.strategyId]: null };
  return { ...state, strategyMatches };
};

const strategyMatchesError = (state = INITIAL_STATE, action = {}) => {
  const strategyMatches = { ...state.strategyMatches, [action.strategyId]: getErrorData(action.payload) };
  return { ...state, strategyMatches };
};

const pricingModelMatchesSuccess = (state = INITIAL_STATE, action = {}) => {
  const pricingModelMatches = { ...state.pricingModelMatches, [action.policyCode]: null };
  return { ...state, pricingModelMatches };
};

const pricingModelMatchesError = (state = INITIAL_STATE, action = {}) => {
  const pricingModelMatches = { ...state.pricingModelMatches, [action.policyCode]: getErrorData(action.payload) };
  return { ...state, pricingModelMatches };
};

const quickCriteriaMatchesSuccess = (state = INITIAL_STATE, action = {}) => {
  return { ...state, quickCriteriaMatches: null };
};

const quickCriteriaMatchesError = (state = INITIAL_STATE, action = {}) => {
  return { ...state, quickCriteriaMatches: getErrorData(action.payload) };
};

const fundPicksMatchesSuccess = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fundPicksMatches: null };
};

const fundPicksMatchesError = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fundPicksMatches: getErrorData(action.payload) };
};

const fidelityMatchesSuccess = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fidelityMatches: null };
};

const fidelityMatchesError = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fidelityMatches: getErrorData(action.payload) };
};

const updateFundsSelectedSuccess = (state = INITIAL_STATE, action = {}) => {
  return { ...state, updateFundsSelected: null };
};

const updateFundsSelectedError = (state = INITIAL_STATE, action = {}) => {
  const errorType = _.defaultTo(action.errorType, null);
  const errorData = _.defaultTo(action.errorData, {});
  return { ...state, updateFundsSelected: { type: errorType, data: errorData } };
};

const removeCriterion = (state = INITIAL_STATE, action = {}) => {
  const critFilterMatches = { ...state.critFilterMatches, [action.critId]: null };
  const critTotalMatches = { ...state.critTotalMatches, [action.critId]: null };
  return { ...state, critFilterMatches, critTotalMatches };
};

const fundFamilySuccess = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fundFamily: null };
};

const fundFamilyError = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fundFamily: getErrorData(action.payload) };
};

const fundTypeSuccess = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fundType: null };
};

const fundTypeError = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fundType: getErrorData(action.payload) };
};

const policyInfoSuccess = (state = INITIAL_STATE, action = {}) => {
  return { ...state, policyInfo: null };
};

const policyInfoError = (state = INITIAL_STATE, action = {}) => {
  return { ...state, policyInfo: getErrorData(action.payload) };
};

const esgGroupsSuccess = (state = INITIAL_STATE, action = {}) => {
	return { ...state, esgGroups: null };
  };
  
  const esgGroupsError = (state = INITIAL_STATE, action = {}) => {
	return { ...state, esgGroups: getErrorData(action.payload) };
  };

const clearAll = (state = INITIAL_STATE) => INITIAL_STATE;
const defaultHandler = (state = INITIAL_STATE) => state;

const ACTION_HANDLERS = {
  [Types.FUND_SEARCH_API_SUCCESS]: fundSearchSuccess,
  [Types.FUND_SEARCH_API_ERROR]: fundSearchError,
  [Types.FIDELITY_FUND_SEARCH_API_SUCCESS]: fidelityFundSearchSuccess,
  [Types.FIDELITY_FUND_SEARCH_API_ERROR]: fidelityFundSearchError,
  [Types.CRITERIA_MATCHES_API_SUCCESS]: criteriaFilterMatchesSuccess,
  [Types.CRITERIA_MATCHES_API_ERROR]: criteriaFilterMatchesError,
  [Types.TOTAL_MATCHES_API_SUCCESS]: criteriaTotalMatchesSuccess,
  [Types.TOTAL_MATCHES_API_ERROR]: criteriaTotalMatchesError,
  [Types.QUICK_CRITERIA_MATCHES_API_SUCCESS]: quickCriteriaMatchesSuccess,
  [Types.QUICK_CRITERIA_MATCHES_API_ERROR]: quickCriteriaMatchesError,
  [Types.FUND_PICKS_FROM_FIDELITY_MATCHES_API_SUCCESS]: fundPicksMatchesSuccess,
  [Types.FUND_PICKS_FROM_FIDELITY_MATCHES_API_ERROR]: fundPicksMatchesError,
  [Types.FIDELITY_MATCHES_API_SUCCESS]: fidelityMatchesSuccess,
  [Types.FIDELITY_MATCHES_API_ERROR]: fidelityMatchesError,
  [Types.STRATEGY_MATCHES_API_SUCCESS]: strategyMatchesSuccess,
  [Types.STRATEGY_MATCHES_API_ERROR]: strategyMatchesError,
  [Types.PRICING_MODEL_MATCHES_API_SUCCESS]: pricingModelMatchesSuccess,
  [Types.PRICING_MODEL_MATCHES_API_ERROR]: pricingModelMatchesError,
  [Types.UPDATE_FUNDS_SELECTED_SUCCESS]: updateFundsSelectedSuccess,
  [Types.UPDATE_FUNDS_SELECTED_ERROR]: updateFundsSelectedError,
  [Types.REMOVE_CRITERION]: removeCriterion,
  [Types.FUNDFAMILY_API_SUCCESS]: fundFamilySuccess,
  [Types.FUNDFAMILY_API_ERROR]: fundFamilyError,
  [Types.FUND_TYPE_API_SUCCESS]: fundTypeSuccess,
  [Types.FUND_TYPE_API_ERROR]: fundTypeError,
  [Types.POLICY_INFO_API_SUCCESS]: policyInfoSuccess,
  [Types.POLICY_INFO_API_ERROR]: policyInfoError,
  [Types.ESGGROUPS_CLASSIFICATION_API_SUCCESS]: esgGroupsSuccess,
  [Types.ESGGROUPS_CLASSIFICATION_API_ERROR]: esgGroupsError,
  [Types.CLEAR_ALL]: clearAll,
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

export const getError = state => state.error;
export const getCritFilterMatchesError = state => state.error.critFilterMatches;
export const getCritTotalMatchesError = state => state.error.critTotalMatches;
export const getStrategyMatchesError = state => state.error.strategyMatches;
export const getPricingModelMatchesError = state => state.error.pricingModelMatches;
export const getFidelityMatchesError = state => state.error.fidelityMatches;
export const getQuickCriteriaMatchesError = state => state.error.quickCriteriaMatches;
export const getFundPicksMatchesError = state => state.error.fundPicksMatches;
export const getUpdateFundsSelectedError = state => state.error.updateFundsSelected;
export const getFundSearchError = state => state.error.fundSearch;
export const getFidelityFundSearchError = state => state.error.fidelityFundSearch;

const getPolicyCode = (state, props) => props.pricingModel.policyCode;

export const getFundSearchParsedError = createSelector([getFundSearchError], error => {
  if (_.isNil(error)) return null;
  if (error.httpStatus === 401) return "We're sorry, access to this service is not available.";
  return `There are no matching ${RESULT_TYPE}s`;
});

export const getFidelityFundSearchParsedError = createSelector([getFidelityFundSearchError], error => {
  if (_.isNil(error)) return null;
  if (error.httpStatus === 401) return "We're sorry, access to this service is not available.";
  return `There are no matching Fidelity ${RESULT_TYPE_CAPITALIZED}s`;
});

export const getPricingModelMatchesErrorForPolicyCode = createSelector(
  [getPricingModelMatchesError, getPolicyCode],
  (error, policyCode) => _.get(error, [policyCode]),
);
