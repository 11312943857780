import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { icons } from 'utilities';
import { APP_DISPLAY_TITLE, RESULT_FEATURES } from 'configs/AppFeatureConfig';
import FidelitySearchBar from 'components/Shared/FidelitySearchBar';
import { START_PAGE_VIEW_TAB_SOURCE } from 'utilities/odaUtils';
import Creators from 'actions/CriteriaActions';
import { getStartPageActiveViewTab } from 'reducers/StartPageViewTabReducer';
import { connect } from 'react-redux';

class MobileHeader extends Component {
  constructor() {
    super();
    this.state = {
      isExpanded: false,
    };
  }

  _toggleExpansion = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  render() {
    const { activeTab } = this.props;
    const zIndexHeader = this.state.isExpanded ? 'mobile-header-z-index-higher':'';
    const displayStyle = this.props.isMobile ? zIndexHeader : 'off';

    const dropdownDisplay = this.state.isExpanded ? '' : 'off';

    const dropdownArrowDirection = this.state.isExpanded ? 'up' : 'down';

    const fundSearchBar = !RESULT_FEATURES.HAS_SEARCH ? null : <FidelitySearchBar />;

    let activeTabSource = null;
    switch (activeTab) {
      case 'quickCriteria':
        activeTabSource = START_PAGE_VIEW_TAB_SOURCE.QUICK_CRITERIA;
        break;
      case 'fundStrategies':
        activeTabSource = START_PAGE_VIEW_TAB_SOURCE.STRATEGIES;
        break;
      case 'fundPicks':
        activeTabSource = START_PAGE_VIEW_TAB_SOURCE.PICKS;
        break;
      case 'annuityOptions':
        activeTabSource = START_PAGE_VIEW_TAB_SOURCE.ANNUITY_OPTIONS;
        break;
      case 'waysToInvest':
        activeTabSource = START_PAGE_VIEW_TAB_SOURCE.WAYS_TO_INVEST;
        break;
      default:
        activeTabSource = null;
        break;
    }

    const navMenuItems = RESULT_FEATURES.NAV_MENU_ITEMS.map(item => {
      if (item.viewTab === 'newScreen') {
        return (
          <li
            className="new-screen"
            onClick={() => {
              this._toggleExpansion();
              this.props.onNewSearch();
            }}
          >
            <img src={icons.newScreenIcon} alt="New screen icon" />
            <button className="as-link">{RESULT_FEATURES.NEW_SCREEN_LABEL}</button>
          </li>
        );
      } else {
        return (
          <li
            className="nav-menu-item"
            onClick={() => {
              this._toggleExpansion();
              this.props.changeStartPageViewTab(item.viewTab, activeTabSource);
            }}
          >
            <button className="as-link">{item.navMenuItemLabel}</button>
          </li>
        );
      }
    });

    return (
      <div className={`mobile-header ${displayStyle}`}>
        <div className="top-level" onClick={() => this._toggleExpansion()}>
          <h1>{APP_DISPLAY_TITLE}</h1>
          <span className={`dropdown-arrow ${dropdownArrowDirection}`} />
        </div>
        <ul className={`dropdown-items ${dropdownDisplay}`}>
          {navMenuItems}
          <li className="fidelity-search">{fundSearchBar}</li>
        </ul>
      </div>
    );
  }
}

MobileHeader.propTypes = {
  isMobile: PropTypes.bool.isRequired,

  /** Runs the Clear All action to start a new search */
  onNewSearch: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
  return {
    changeStartPageViewTab: (viewTab, source) => {
      dispatch(Creators.changeStartPageViewTab(viewTab, source));
    },
  };
};

const mapStateToProps = state => {
  return {
    activeTab: getStartPageActiveViewTab(state),
  };
};

const MobileHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(MobileHeader);

export default MobileHeaderContainer;
export { MobileHeader };
