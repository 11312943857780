import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';
import Types from '../actions/Types';
import screenerConfig from 'screenerConfig';
import { createSelector } from 'reselect';

export const INITIAL_STATE = {
  ...screenerConfig.resultToggleDefaults,
};

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};

export const updateToggleGroupSelection = (state = INITIAL_STATE, action = {}) => {
  return { ...state, [action.resultGroup]: action.selection };
};

export const clearAll = (state = INITIAL_STATE, action = {}) => {
  return INITIAL_STATE;
};

export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
  if (action.payload.location.state && action.payload.location.state.source === 'routerMiddleware') {
    return state;
  }
  if (!action.toggleGroupSelections) {
    return state;
  }

  return { ...state, ...action.toggleGroupSelections };
};

const ACTION_HANDLERS = {
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
  [Types.UPDATE_RESULT_TOGGLE_GROUP]: updateToggleGroupSelection,
  "@@router/LOCATION_CHANGE": routerLocationChanged,
  [Types.CLEAR_ALL]: clearAll,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

// ---- Selectors ----

export const getToggleGroups = state => state.resultToggleGroup;
export const getSelectionForGroup = (state, props) => state.resultToggleGroup[props.groupShortName];
export const getToggleGroupLabel = (state, props) => props.groupLabel;

export const makeGetToggleGroupSelections = () => {
  return createSelector([getSelectionForGroup], selectionForGroup => {
    return selectionForGroup;
  });
};
