import React from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';

const RadioButtonFilter = ({ isSelected, filter, handleClick }) => {
  const { label, count } = filter;
  const matches = _.defaultTo(count, '--');
  const matchesDisplay = isSelected ? 'active' : '';

  return (
    <li
      tabIndex={0}
      className="radio-button-filter"
      onClick={e => {
        e.preventDefault();
        if (!isSelected) {
          handleClick(label);
        }
      }}
      onKeyDown={e => {
        if (!isSelected && e.key === 'Enter') {
          handleClick(label);
        }
      }}
    >
      <label className="radio-button-input">
        <input tabOrder={0} type="radio" checked={!!isSelected} readOnly />
      </label>
      <div className="radio-button-filter-label">{label}</div>
      <div className="radio-button-match-details">
        <div className={`radio-button-cumulative-matches ${matchesDisplay}`}>{matches}</div>
      </div>
    </li>
  );
};

RadioButtonFilter.propTypes = {
  /** The position of the filter within the interface array */
  index: PropTypes.number.isRequired,

  isSelected: PropTypes.bool,

  /** If filter is for multi-select interface, determines the CSS display class of the connector lines */
  connectorDisplay: PropTypes.string,

  /** All the filter info in an object */
  filter: PropTypes.object,

  /** Handles updating radio button selection */
  handleClick: PropTypes.func.isRequired,
};

export default RadioButtonFilter;
