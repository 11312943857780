import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';
import Types from '../actions/Types';
import { createSelector } from 'reselect';
import _ from 'lodash';

export const INITIAL_STATE = {};

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};

const updateMatches = (state = INITIAL_STATE, action = {}) => {
  return { ...state, [action.policyCode]: action.payload.data.totalCount };
};

const ACTION_HANDLERS = {
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
  [Types.PRICING_MODEL_MATCHES_API_SUCCESS]: updateMatches,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

// ---- Selectors ----
export const getAllPricingModelMatches = state => state.pricingModelMatches;
const getPolicyCode = (state, props) => props.pricingModel.policyCode;

export const getMatchesForPolicyCode = createSelector(
  [getAllPricingModelMatches, getPolicyCode],
  (pricingModelMatches, policyCode) => _.get(pricingModelMatches, [policyCode]),
);
