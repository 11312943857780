import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  ROUTE_CONSTANTS,
  APP_DISPLAY_TITLE,
  HAS_BREADCRUMBS,
  START_PAGE_TYPE,
  RESULT_FEATURES,
} from 'configs/AppFeatureConfig';
import CriteriaMenuContainer from 'containers/CriteriaMenuContainer';
import ResultsViewContainer from 'containers/ResultsViewContainer';
import DisclosuresContainer from 'components/Evaluator/DisclosuresContainer';
import StartPage from 'components/StartPage';
import MobileHeader from 'components/Evaluator/MobileHeader';
import BreadcrumbsContainer from 'components/Evaluator/BreadcrumbsContainer';
import FidelitySearchBar from 'components/Shared/FidelitySearchBar';
import Creators from 'actions/CriteriaActions';
import { getIsMobile } from 'reducers/MobileStatusReducer';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getRouterRoute } from 'reducers/selectors/RouterSelectors';
import { CLEAR_ALL_SOURCE } from 'utilities/odaUtils';
import mobileUtils from 'utilities/mobileUtils';
import AnnuitiesStartPage from 'components/AnnuitiesStartPage';
import Banner from 'components/Shared/Banner';
import MadLibsLink from '../StartPage/MadLibsLink';

class Evaluator extends Component {
  constructor(props) {
    super(props);

    props.initializeOda();

    if (mobileUtils.isMobile()) {
      props.updateMobileStatus(true);
    }
  }

  componentWillMount = () => {
    window.addEventListener('resize', _.debounce(this.handleWindowSizeChange, 25));
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  };

  handleWindowSizeChange = () => {
    if (this.props.isMobile !== mobileUtils.isMobile()) {
      this.props.updateMobileStatus(mobileUtils.isMobile());
    }
  };
  _renderFidelitySearchBar = () => {
    if (this.props.match.path !== ROUTE_CONSTANTS.RESULTS && RESULT_FEATURES.HAS_SEARCH) {
      return <FidelitySearchBar match={this.props.match}/>;
    }
    return null;
  };

  _renderMainContentArea = () => {
    switch (this.props.match.path) {
      case ROUTE_CONSTANTS.INDEX:
      case ROUTE_CONSTANTS.STRATEGIES:
      case ROUTE_CONSTANTS.NESTED_STRATEGIES:
      case ROUTE_CONSTANTS.PICKS:
      case ROUTE_CONSTANTS.WAYS_TO_INVEST: {
        if (START_PAGE_TYPE === 'fili') {
          return <AnnuitiesStartPage match={this.props.match} />;
        } else {
          return <StartPage match={this.props.match} />;
        }
      }
      case ROUTE_CONSTANTS.RESULTS: {
        return <ResultsViewContainer match={this.props.match} />;
      }
      default: {
        // place holder div to keep height fixed
        return <div style={{ height: '596px' }}></div>;
      }
    }
  };
  render() {
    const { isMobile } = this.props;
    const titleStyle = isMobile ? 'off' : '';
    const route = this.props.route;
    return (
      <div className="evaluator">
        <main id="main">
          {HAS_BREADCRUMBS ? <BreadcrumbsContainer match={this.props.match}/> : null}
          <div className={`title-container ${titleStyle}`}>
            <div className="header">
              <h1>{APP_DISPLAY_TITLE}</h1>
              {route==="/" && START_PAGE_TYPE==="mfe" && (<MadLibsLink/>)}
            </div>
            {this._renderFidelitySearchBar()}
          </div>
          <MobileHeader match={this.props.match} isMobile={isMobile} onNewSearch={this.props.onNewSearch} appDisplayTitle={APP_DISPLAY_TITLE} />
          <div className="content">
            <CriteriaMenuContainer match={this.props.match} />
            {this._renderMainContentArea()}
          </div>
        </main>
        <DisclosuresContainer match={this.props.match} />
      </div>
    );
  }
}

Evaluator.propTypes = {
  isMobile: PropTypes.bool.isRequired,

  /** Action to set up ODA constants */
  initializeOda: PropTypes.func.isRequired,

  /** Runs the Clear All action to start a new search */
  onNewSearch: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isMobile: getIsMobile(state),
    displayType: getDisplayType(state),
    route: getRouterRoute(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMobileStatus: isMobile => {
      dispatch(Creators.updateMobileStatus(isMobile));
    },
    onNewSearch: () => {
      const source = CLEAR_ALL_SOURCE.MOBILE_HEADER;
      dispatch(Creators.clearAll(source, true));
    },
    initializeOda: () => {
      dispatch(Creators.odaInitializeTracking());
    },
  };
};

const EvaluatorContainer = connect(mapStateToProps, mapDispatchToProps)(Evaluator);

export default EvaluatorContainer;