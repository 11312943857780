import _ from 'lodash';
import { CRITERIA_IDS_BY_FILTER_GROUP } from 'configs/CriteriaConfig';
const strategiesUtils = {};

strategiesUtils.parseLoadStrategyParams = strategy => {
  var critIdArray = [];
  const parsedSelections = {};

  _.each(strategy.filterGroupSelections, filterSelection => {
    critIdArray.push(CRITERIA_IDS_BY_FILTER_GROUP[filterSelection.criteriaId]);
    parsedSelections[filterSelection.criteriaId] = filterSelection.value;
  });

  critIdArray = [...new Set(critIdArray)];
  const strategyId = strategy.id;
  const viewTabShortName = strategy.viewTab;
  const sortParams = { column: strategy.sortField, direction: strategy.sortDirection };
  return { critIdArray, parsedSelections, strategyId, viewTabShortName, sortParams };
};

export default strategiesUtils;
