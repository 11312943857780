import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Creators from 'actions/CriteriaActions';
import { getPlansByType } from 'reducers/PolicyInfoReducer';
import AnnuitiesProductLink from 'components/AnnuitiesStartPage/AnnuitiesProductLink';

class AnnuityOptions extends Component {
  _renderPlanStatus = plan => {
    const indicator = plan.isOpen ? 'OPEN' : 'CLOSED';
    return <span className={`${indicator.toLowerCase()} indicator`}>{indicator}</span>;
  };

  _renderTableRows = plans => {
    if (_.isEmpty(plans)) {
      return (
        <tr>
          <td>Loading...</td>
          <td></td>
        </tr>
      );
    }
    return _.map(plans, plan => (
      <tr key={plan.id}>
        <td>
          <AnnuitiesProductLink plan={plan} onPricingModelSelection={this.props.updatePricingModel} />
        </td>
        <td>{this._renderPlanStatus(plan)}</td>
      </tr>
    ));
  };

  _renderTable = (title, plans) => {
    return (
      <React.Fragment>
        <h3>{title}</h3>
        <table>
          <tbody>
            <tr>
              <th></th>
              <th>Open to new customers</th>
            </tr>
            {this._renderTableRows(plans)}
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  render() {
    const { plans } = this.props;
    const deferredFixedTable = this._renderTable('Deferred Fixed Annuities', plans.deferred);
    const deferredTable = this._renderTable('Deferred Variable Annuities', plans.taxdef);
    const incomeTable = this._renderTable('Immediate Variable Income Annuities', plans.income);
    return (
      <div className="annuity-options">
        <p>
          Learn more about deferred fixed annuities and the investment options within the variable annuities available
          through Fidelity.
        </p>
        {deferredFixedTable}
        {deferredTable}
        {incomeTable}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    plans: getPlansByType(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePricingModel: productCode => {
      dispatch(Creators.updatePricingModel(productCode, true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnuityOptions);
