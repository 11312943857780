import { connect } from 'react-redux';
import { COLUMN_LABELS_BY_SORT_FIELD } from 'configs/ResultConfig';
import { getSortFieldsWithLabels } from 'reducers/FundSearchResultsReducer';
import { getSortSettings } from 'reducers/SortResultsReducer';
import Creators from 'actions/CriteriaActions';
import * as odaTracking from 'utilities/odaTracking';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class ResultsSubTitle extends Component {
  constructor() {
    super();
    this.state = {
      isSorterExpanded: false,
    };
  }

  _toggleSorterExpansion = () => {
    const toggledIsSortedExpanded = !this.state.isSorterExpanded;
    this.setState({
      isSorterExpanded: toggledIsSortedExpanded,
    });
    if (this.props.displayType === 'card') {
      this.props.trackSortMenu(toggledIsSortedExpanded);
    }
  };

  _renderCurrentSortFieldDisplayName = () => {
    const { sortSettings } = this.props;
    const currentSortField = _.get(this.props, ['sortSettings', 'sortCol']);
    const currentSortFieldLabel = _.get(COLUMN_LABELS_BY_SORT_FIELD, currentSortField, '--');

    return (
      <button
        className={`current-sort-field sort-arrow active ${sortSettings.sortDir} as-link`}
        onClick={() => this._toggleSorterExpansion()}
        dangerouslySetInnerHTML={{ __html: currentSortFieldLabel }}
      />
    );
  };

  _renderCardButton = () => {
    return this.props.displayType === 'card' ? (
      <button className="as-link active">Card</button>
    ) : (
      <button className="as-link" onClick={() => this.props.updateDisplayType('card')}>
        Card
      </button>
    );
  };

  _renderTableButton = () => {
    return this.props.displayType === 'table' ? (
      <button className="as-link active">Table</button>
    ) : (
      <button className="as-link" onClick={() => this.props.updateDisplayType('table')}>
        Table
      </button>
    );
  };

  _renderAllSortFields = () => {
    return _.map(this.props.sortFieldsWithLabels, this._renderIndividualSortField);
  };

  _renderIndividualSortField = ({ sortField, label }) => {
    const { sortSettings } = this.props;
    const activeStatus =
      sortSettings.sortCol === sortField ? `sort-arrow inactive ${sortSettings.sortDir} selected` : 'unselected';

    let sortDirOnClick = 'asc';

    if (sortSettings.sortCol === sortField && sortSettings.sortDir === 'asc') {
      sortDirOnClick = 'desc';
    }

    return (
      <div className="sort-field-row" key={sortField} onClick={() => this.props.sortResults(sortField, sortDirOnClick)}>
        <button className={`label as-link ${activeStatus}`} dangerouslySetInnerHTML={{ __html: label }} />
      </div>
    );
  };

  render() {
    if (!this.props.isMobile) return null;

    const currentSortFieldLabel = this._renderCurrentSortFieldDisplayName();
    const cardButton = this._renderCardButton();
    const tableButton = this._renderTableButton();
    const allSortFields = this._renderAllSortFields();
    const allSortFieldsDisplay = this.state.isSorterExpanded ? '' : 'off';

    return (
      <div className="results-sub-title">
        <div className="top-level-section">
          <div className="sort-selector">Sort By: {currentSortFieldLabel}</div>
          <div className="view-selector">
            <div>View:</div>
            <div>
              {cardButton} | {tableButton}
            </div>
          </div>
        </div>
        <div className={`all-sort-fields ${allSortFieldsDisplay}`}>{allSortFields}</div>
      </div>
    );
  }
}

ResultsSubTitle.propTypes = {
  /** String that represents either table or card view */
  displayType: PropTypes.string.isRequired,

  isMobile: PropTypes.bool.isRequired,

  /** An array with two keys (labels and sortFields) that map a label with its corresponding sort field ID */
  sortFieldsWithLabels: PropTypes.array.isRequired,

  /** Redux action to sort results */
  sortResults: PropTypes.func.isRequired,

  trackSortMenu: PropTypes.func.isRequired,

  /** Contains the current sort field and direction */
  sortSettings: PropTypes.object.isRequired,

  /** Function to switch between table and card views */
  updateDisplayType: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    sortSettings: getSortSettings(state),
    sortFieldsWithLabels: getSortFieldsWithLabels(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sortResults: (sortField, direction) => {
      dispatch(Creators.sortResults(sortField, direction));
    },
    trackSortMenu: expanded => {
      const trackingData = odaTracking.getResultCardsSortMenuTrackingData(expanded);
      dispatch(Creators.odaLogAction(trackingData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsSubTitle);
