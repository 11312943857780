import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FUND_ID_FIELD } from 'configs/AppFeatureConfig';
import ResultsCardItemHeaderContainer from 'components/ResultsView/ResultsCardItemHeaderContainer';
import ResultsCardItemGroup from 'components/ResultsView/ResultsCardItemGroup';
import ResultsCardItemFooterContainer from 'components/ResultsView/ResultsCardItemFooterContainer';
import { connect } from 'react-redux';
import * as odaTracking from 'utilities/odaTracking';
import Creators from 'actions/CriteriaActions';

const renderResultsGroups = data => {
  /*********9/28/2023: Life of all funds override: Cleaned newProducts array out to allow regular data to flow through********/
  const newProducts = [ ];

  const symbol = data.additionalData['symbol'];
  if (symbol !== undefined && newProducts.includes(symbol)) {
    if (data['Performance'] !== undefined) {
      data['Performance'].forEach(item => {
        if (item['label'] === 'Life of Fund') {
          item['value'][0].props.children.props.children = '--';
        }
        if (item['value'][0].props.children === '--' || item['value'][0].props.children.props.children === '--') {
          return;
        }
        if (item['value'][1] === undefined) {
          return;
        }
        if (item['value'][0].props.children !== undefined && item['value'][0].props.children.props !== undefined) {
          item['value'][0].props.children.props.children = '--';
        }
        if (item['value'][1].props.children !== undefined && item['value'][1].props.children.props !== undefined) {
          item['value'][1].props.children.props.children = '--';
        }
      });
    }
  }
  /*********9/28/2023: Life of all funds override: Cleaned newProducts array out to allow regular data to flow through********/
        
  const parsedData = _.pickBy(data, (value, key) => {
    return key !== 'Overview' && key !== 'additionalData';
  });

  return _.map(parsedData, (value, key) => <ResultsCardItemGroup key={key} label={key} data={value} />);
};

class ResultsCardItem extends Component {
  constructor() {
    super();

    this.state = {
      isExpanded: false,
    };
  }

  handleToggleExpansion = () => {
    const toggledIsExpanded = !this.state.isExpanded;
    const fundId = this.props.data.additionalData[FUND_ID_FIELD];
    this.setState({
      isExpanded: toggledIsExpanded,
    });
    this.props.trackDetailToggleExpansion(toggledIsExpanded, fundId);
  };

  _renderNoTransactionFee() {
    const { data } = this.props;
    const ntfData = _.get(data, ['Overview', 'NTF or Fee']);
    return !_.isNil(ntfData) ? (
      <div className="results-card-item-group">
        <div className="ntf">{ntfData}</div>
      </div>
    ) : null;
  }

  render() {
    const { data, isFidelityLightGreen } = this.props;
    const { isExpanded } = this.state;

    const overallDisplayStyle = isFidelityLightGreen ? 'fidelity-light-green' : '';
    const resultsCardsGroupsDisplay = isExpanded ? '' : 'off';
    const expandedFooterDisplay = isExpanded ? '' : 'off';

    const resultsGroups = renderResultsGroups(data);
    return (
      <div className={`results-card-item ${overallDisplayStyle}`}>
        <ResultsCardItemHeaderContainer
          data={data['Overview']}
          additionalData={data.additionalData}
          isExpanded={isExpanded}
        />
        <ResultsCardItemFooterContainer
          isExpanded={isExpanded}
          handleToggleExpansion={this.handleToggleExpansion}
          additionalData={data.additionalData}
        />
        <div className={`results-card-item-all-groups ${resultsCardsGroupsDisplay}`}>
          {this._renderNoTransactionFee()}
          {resultsGroups}
        </div>
        <div className={`results-card-item-expanded-footer ${expandedFooterDisplay}`}>
          <ResultsCardItemFooterContainer
            isExpanded={isExpanded}
            handleToggleExpansion={this.handleToggleExpansion}
            additionalData={data.additionalData}
            showResearchButton={true}
          />
        </div>
      </div>
    );
  }
}

ResultsCardItem.propTypes = {
  /** An object that represents all the data to display in the result card */
  data: PropTypes.object.isRequired,

  isFidelityLightGreen: PropTypes.bool.isRequired,

  trackDetailToggleExpansion: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {
    trackDetailToggleExpansion: (expanded, fundId) => {
      const trackingData = odaTracking.getResultCardsDetailsTrackingData(expanded, fundId);
      dispatch(Creators.odaLogAction(trackingData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsCardItem);
