import React from 'react';
import MadLibsCriterion from './MadLibsCriterion'

const SelectedCriteriaBox = ({selectedCriteria, toggleCriterionSelection}) => {

    const renderSelectedCriteria = (criteria) =>{
        return criteria.map(x => <MadLibsCriterion criterion={x} key={x.label} className={"selected"} toggleCriterionSelection={toggleCriterionSelection}/>)
    }

    return (
        <div data-tc='criteria-tiles'>
            {renderSelectedCriteria(selectedCriteria)}
        </div>
    )
}

export default SelectedCriteriaBox