import _ from 'lodash';
import { LOGIN_EXPIRATION_MINUTES } from 'configs/AppFeatureConfig';

let loginExpirationTime;
const NO_LOGIN_EXPIRATION = _.isNil(LOGIN_EXPIRATION_MINUTES);

export const resetLoginExpiration = () => {
  if (NO_LOGIN_EXPIRATION) return;

  const milliseconds = Date.now() + LOGIN_EXPIRATION_MINUTES * 60000;
  loginExpirationTime = new Date(milliseconds);
  console.log('loginExpirationTime:', loginExpirationTime);
};

export const redirectToLogin = () => {
  // refreshing page will redirect user to login when session is expired
  window.location.reload();
};

export const redirectToLoginIfSessionExpired = () => {
  if (NO_LOGIN_EXPIRATION) return;

  if (Date.now() >= loginExpirationTime) {
    redirectToLogin();
  }
};
