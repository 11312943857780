import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { RESULT_FEATURES } from 'configs/AppFeatureConfig';
import { icons } from 'utilities';

class PricingModelRelatedInfo extends Component {
  _renderWaysToInvest = () => {
    const policyCode = this.props.policyCode.toUpperCase();
    const whitelist = _.get(RESULT_FEATURES, 'PRODUCT_CODES_WITH_WAYS_TO_INVEST', []);
    return whitelist.includes(policyCode) ? (
      <li>
		<img src={icons.listBulletFilledCircle} alt="list bullet" />
        <a href="http://personal.fidelity.com/research/annuities/content/build.shtml">Ways to Invest</a>
      </li>
    ) : null;
  };
  render() {
    const { policyCode } = this.props;
    if (!policyCode) {
      return null;
    }
    return (
      <div className="pricing-model-related-info">
        <div className="pricing-model-related-info-label">Related Information</div>
        <ul className="pricing-model-related-info-link-list">
          <li>
			<img src={icons.listBulletFilledCircle} alt="list bullet" />
            <a href={`http://fundresearch.fidelity.com/prospectus/annuities?product=${policyCode}`}>
              Prospectuses and Shareholder Reports
            </a>
          </li>
          {this._renderWaysToInvest()}
        </ul>
      </div>
    );
  }
}

PricingModelRelatedInfo.propTypes = {
  policyCode: PropTypes.string.isRequired,
};

export default PricingModelRelatedInfo;
