import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import _ from 'lodash';
import PricingModelPopupHeader from 'components/ResultsView/PricingModelPopupHeader';

class PricingModelPopup extends Component {
  constructor(props) {
    super(props);
    const displayedSection = _.get(props, ['items', 0, 'policyCode'], '');
    this.state = { isOpen: false, displayedSection };
    this.dropdownRef = React.createRef();
  }

  componentWillUnmount = () => {
    this._removeOutsideClickEventHandlers();
  };

  _removeOutsideClickEventHandlers = () => {
    document.removeEventListener('mousedown', this._handleOutsideClick, false);
    document.removeEventListener('touchstart', this._handleOutsideClick, false);
  };

  _handleOutsideClick = e => {
    e.stopPropagation();
    const dropdownNode = ReactDOM.findDOMNode(this.dropdownRef.current);
    const wasDropdownClicked = dropdownNode && dropdownNode.contains(e.target);
    if (!wasDropdownClicked) {
      this.setState({ isOpen: false });
      this._removeOutsideClickEventHandlers();
    }
  };

  _expandDropdown = e => {
    e.stopPropagation();
    this.setState({ isOpen: true });
    document.addEventListener('mousedown', this._handleOutsideClick, false);
    document.addEventListener('touchstart', this._handleOutsideClick, false);
  };

  _handleChangeSelection = newSelection => {
    this.props.handleChangeSelection(newSelection);
    this.setState({ isOpen: false });
    this._removeOutsideClickEventHandlers();
  };

  _handleHoverOverHeader = newDisplayedSection => {
    this.setState({ displayedSection: newDisplayedSection });
  };

  _renderPopupHeaders = items => {
    return items.map(item => (
      <PricingModelPopupHeader
        key={item.policySubHeader}
        displayedSection={this.state.displayedSection}
        handleChangeActiveClass={this._handleChangeSelection}
        handleHoverOverHeader={this._handleHoverOverHeader}
        handleTouchHeader={this._handleChangeSelection}
        item={item}
      />
    ));
  };

  _renderSelectionIndicator = () => {
    const { items } = this.props;
    const isClosed = _.get(items, [0, 'policyStatus']) === 'Closed';
    const selectionIndicatorText = isClosed ? 'CLOSED' : 'OPEN';
    const selectionIndicatorClass = isClosed ? 'closed' : 'open';
    return <span className={`${selectionIndicatorClass} indicator`}>{selectionIndicatorText}</span>;
  };

  render() {
    const { items } = this.props;
    if (!items || items.length < 2) return null; // don't show dropdown if we have less than 2 selections
    // first item will be current selection
    const selectionLabel = _.get(items, [0, 'policySubHeader'], '--');
    const { isOpen } = this.state;
    const popupHeaders = this._renderPopupHeaders(items);
    const displayStyle = isOpen ? '' : 'off';

    return (
      <div className="pricing-model-popup-container">
        <div className="pricing-model-popup-label">Contract: </div>
        <div className="pricing-model-popup">
          <div className="options dropdown-arrows" onClick={this._expandDropdown}>
            <div className="selection-label">
              {selectionLabel}
              {this._renderSelectionIndicator()}
            </div>
          </div>
          <div className={`pricing-model-popup-options ${displayStyle}`} ref={this.dropdownRef}>
            <div className="container">
              <div className="row">
                <div className="headers-container">{popupHeaders}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PricingModelPopup;
