import { createReducer } from 'reduxsauce';
import _ from 'lodash';
import Types from '../actions/Types';

// the initial state of this reducer
export const INITIAL_STATE = [];
const buildFundFamilyState = (state = INITIAL_STATE, action = {}) => {
  const fundFamilyData = _.map(action.payload.data, item => {
    const label = _.get(item, 'description', item.subAccountFamilyCodeName);
    const value = _.get(item, 'code', item.subAccountFamilyCode);
    return { label, value, key: value };
  });
  return [...fundFamilyData];
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.FUNDFAMILY_API_SUCCESS]: buildFundFamilyState,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const getFundFamilyFilters = state => state.fundFamilyFilters;
