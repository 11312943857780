import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { format, icons } from 'utilities';
import MultiSelectTextInput from 'components/ActiveCriterion/MultiSelectTextInput';

class MultiSelectRangeInputs extends Component {
  constructor(props) {
    super(props);
    this.valueSuffixesRegex = /[^0-9.-]/gi;
    this.state = {
      ...this.getMinAndMax(),
      shouldErrorDisplay: false,
    };
  }

  handleChangeMinimum = e => {
    this.setState({ min: e.target.value });
  };

  handleChangeMaximum = e => {
    this.setState({ max: e.target.value });
  };

  getMinAndMax = () => {
    const { minFilter, maxFilter } = this.props;

    let minValue = minFilter.min !== 'LS' && !_.isNil(minFilter.min) ? minFilter.min : '';
    let maxValue = maxFilter.max !== 'GT' && !_.isNil(maxFilter.max) ? maxFilter.max : '';
    minValue = format.formatInputValue(minValue, this.props.formatType);
    maxValue = format.formatInputValue(maxValue, this.props.formatType);

    return {
      min: minValue,
      max: maxValue,
    };
  };

  componentDidUpdate = prevProps => {
    if (_.isEqual(prevProps, this.props)) return;
    this.setState(this.getMinAndMax());
  };

  _handleSubmitRanges = e => {
    e.preventDefault();
    let { min = null, max = null } = this.state;

    if (!_.isNil(min)) min = min.replace(this.valueSuffixesRegex, '').trim();
    if (!_.isNil(max)) max = max.replace(this.valueSuffixesRegex, '').trim();

    if (!_.isNil(min) && !_.isEmpty(min)) {
      min = format.unformatMagnitude(min, this.props.formatType);
    }

    if (!_.isNil(max) && !_.isEmpty(max)) {
      max = format.unformatMagnitude(max, this.props.formatType);
    }

    let minAsNum = _.isEmpty(min) ? null : _.toNumber(min);
    let maxAsNum = _.isEmpty(max) ? null : _.toNumber(max);

    if (minAsNum > maxAsNum && !_.isNil(minAsNum) && !_.isNil(maxAsNum)) {
      this.setState({ shouldErrorDisplay: true });
      return;
    }

    this.setState({ shouldErrorDisplay: false });

    if (_.isEmpty(min)) min = 'LS';
    if (_.isEmpty(max)) max = 'GT';

    this.props.handleSubmitCustomRange(min, max);
  };

  render() {
    const { shouldErrorDisplay } = this.state;
    const formStyle = shouldErrorDisplay ? 'error' : '';
    return (
      <div className="multi-select-range-inputs">
        <form onSubmit={this._handleSubmitRanges} className={formStyle}>
          <MultiSelectTextInput
            formatType={this.props.formatType}
            onChange={this.handleChangeMinimum}
            placeholder="Min"
            value={this.state.min}
          />
          <span> &mdash; </span>
          <MultiSelectTextInput
            formatType={this.props.formatType}
            onChange={this.handleChangeMaximum}
            placeholder="Max"
            value={this.state.max}
          />
          <input type="submit" value="Go" />
        </form>
        {shouldErrorDisplay ? (
          <div className="error-message">
            {' '}
            <img src={icons.error} className="error-icon" alt="Error icon" />
            Please be sure left value is lower than right value.
          </div>
        ) : null}
      </div>
    );
  }
}

MultiSelectRangeInputs.defaultProps = {
  minFilter: {},
  maxFilter: {},
};

MultiSelectRangeInputs.propTypes = {
  /** A formatting type used to help display selection text for multi-select criteria */
  formatType: PropTypes.string,

  /** Dispatches redux action to update criteria with custom range values*/
  handleSubmitCustomRange: PropTypes.func.isRequired,

  /** Filter definition of the minimum filter applied */
  minFilter: PropTypes.object,

  /** Filter definition of the maximum filter applied */
  maxFilter: PropTypes.object,
};

export default MultiSelectRangeInputs;
