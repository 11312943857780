import closeicon from 'media/images/closeicon.png';
import balancingFixedIncomeRisks from 'media/images/balancingFixedIncomeRisks.png';
import categoryLeaders from 'media/images/categoryLeaders.png';
import fidelityZeroExpenseRatioIndexFunds from 'media/images/fidelityZeroExpenseRatioIndexFunds.png';
import internationalOpportunities from 'media/images/internationalOpportunities.png';
import fightingInflation from 'media/images/fightingInflation.png';
import seekingIncomeGeneration from 'media/images/seekingIncomeGeneration.png';
import managingTaxes from 'media/images/managingTaxes.png';
import morningstarTopRatedFunds from 'media/images/morningstarTopRatedFunds.png';
import indexFunds from 'media/images/indexFunds.png';
import preservingPrincipal from 'media/images/preservingPrincipal.png';
import sectors from 'media/images/sectors.png';
import sociallyResponsibleStrategy from 'media/images/sociallyResponsible.png';
import historicallyStablePerformers from 'media/images/historicallyStablePerformers.png';
import highRatedFundsAtCheapPrices from 'media/images/highRatedFundsAtCheapPrices.png';
import zeroMinimumInvestmentFunds from 'media/images/zeroMinimumInvestmentFunds.png';
import popupIcon from 'media/images/popup_icon.png';
import greyX from 'media/images/grey_x.png';
import helpIcon from 'media/images/help_icon.png';
import fidelityIcon from 'media/images/fidelity.png';
import fundPicksIcon from 'media/images/fundPick.png';
import fundPicksLargeIcon from 'media/images/fundPickLarge.png';
import leafIcon from 'media/images/leafIcon.png';
import esgLeafIcon from 'media/images/esgLeaf.png';
import ntfIcon from 'media/images/ntf.png';
import ntfLargeIcon from 'media/images/ntfLarge.png';
import feeLargeIcon from 'media/images/feeLarge.png';
import expandImg from 'media/images/expand.png';
import collapseImg from 'media/images/collapse.png';
import arrowUp from 'media/images/arrow_up.png';
import arrowDown from 'media/images/arrow_down.png';
import arrowLeft from 'media/images/arrow_left.png';
import arrowRight from 'media/images/arrow_right.png';
import funnelIcon from 'media/images/funnel.png';
import newScreenIcon from 'media/images/new_screen.svg';
import expandDisclosures from 'media/images/expand_disclosures.png';
import collapseDisclosures from 'media/images/collapse_disclosures.png';
import error from 'media/images/error.gif';
import excelIcon from 'media/images/excel_icon.png';
import checkmark from 'media/images/checkmark.png';
import checkeredFlag from 'media/images/checkeredFlag.png';
import handsOn from 'media/images/Hands-on.png';
import handsOff from 'media/images/Hands-off.png';
import sectorInvesting from 'media/images/sector-investing.png';
import dropdownChevrons from 'media/images/dropdown-chevrons.png';
import searchIcon from 'media/images/search-icon.png';
import clearSearch from 'media/images/clear-search.png';
import classDropdownArrow from 'media/images/class-dropdown-arrow.png';
import etfIcon from 'media/images/etf.png';
import etfIconSmall from 'media/images/etfSmall.png';
import etfIconLarge from 'media/images/etfIconLarge.svg';
import noteIcon from 'media/images/note_icon.png';
import partialSelection from 'media/images/PartialSelection.png';

import assetAllocation from 'media/images/assetRiskImages/Allocation.png';
import assetAlternative from 'media/images/assetRiskImages/Alternative.png';
import assetCommodities from 'media/images/assetRiskImages/Commodities.png';
import assetInternationalEquity from 'media/images/assetRiskImages/International Equity.png';
import assetMisc from 'media/images/assetRiskImages/Misc.png';
import assetMoneyMarket from 'media/images/assetRiskImages/Money Market.png';
import assetMunicipalBond from 'media/images/assetRiskImages/Municipal Bond.png';
import assetNonTraditionalEquity from 'media/images/assetRiskImages/NonTraditional Equity.png';
import assetSectorEquity from 'media/images/assetRiskImages/Sector Equity.png';
import assetTaxableBond from 'media/images/assetRiskImages/Taxable Bond.png';
import assetUSEquity from 'media/images/assetRiskImages/U.S Equity.png';

import listBulletFilledCircle from 'media/images/circle-filled-round-bullet.svg';

const iconMap = {
  closeicon,
  balancingFixedIncomeRisks,
  categoryLeaders,
  error,
  fidelityZeroExpenseRatioIndexFunds,
  internationalOpportunities,
  fightingInflation,
  seekingIncomeGeneration,
  managingTaxes,
  morningstarTopRatedFunds,
  indexFunds,
  preservingPrincipal,
  sectors,
  sociallyResponsibleStrategy,
  historicallyStablePerformers,
  highRatedFundsAtCheapPrices,
  zeroMinimumInvestmentFunds,
  popupIcon,
  greyX,
  helpIcon,
  fidelityIcon,
  fundPicksIcon,
  fundPicksLargeIcon,
  leafIcon,
  esgLeafIcon,
  ntfIcon,
  ntfLargeIcon,
  feeLargeIcon,
  expandImg,
  collapseImg,
  arrowUp,
  arrowDown,
  arrowLeft,
  arrowRight,
  funnelIcon,
  newScreenIcon,
  expandDisclosures,
  collapseDisclosures,
  checkmark,
  checkeredFlag,
  excelIcon,
  handsOn,
  handsOff,
  sectorInvesting,
  dropdownChevrons,
  searchIcon,
  clearSearch,
  classDropdownArrow,
  etfIcon,
  etfIconSmall,
  etfIconLarge,
  noteIcon,
  partialSelection,
  assetAllocation,
  assetAlternative,
  assetCommodities,
  assetInternationalEquity,
  assetMisc,
  assetMoneyMarket,
  assetMunicipalBond,
  assetNonTraditionalEquity,
  assetSectorEquity,
  assetTaxableBond,
  assetUSEquity,
  listBulletFilledCircle

};

export default iconMap;
