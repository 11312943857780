import apisauce from 'apisauce';
import {mockDataInjection} from "../tests/lib/mockDataSetInjection";

const create = baseURL => {
  const api = apisauce.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    timeout: mockDataInjection.enableDataInjection()?3000: 10000,
  });

  const postData = (endpoint, parameters) => {

    if (parameters.searchFilter.hasOwnProperty('sustainableInvestment') || parameters.searchFilter.hasOwnProperty('employsExclusions')){
      const esgCodesString = [parameters.searchFilter.sustainableInvestment, parameters.searchFilter.employsExclusions]
                            .filter( x => x)
                            .join(',')

      parameters.searchFilter["esgCodes"] = esgCodesString
      
      delete parameters.searchFilter["sustainableInvestment"];
      delete parameters.searchFilter["employsExclusions"];
    }
    return api.post(endpoint, parameters);
  };
  // const postData = (endPoint, parameters) => api.post(endPoint, parameters);
  // const putData = (endPoint, parameters) => api.put(endPoint, parameters);
  // const deleteData = (endPoint, parameters) => api.delete(endPoint, parameters);
  return {
    postData,
    // postData,
    // putData,
    // deleteData,
  };
};

export default {
  create,
};
