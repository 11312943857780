import React, { Component } from 'react';
import { sorting } from 'utilities';
import { ALL_CRITERIA } from 'configs/CriteriaConfig';
import CheckboxFilter from 'components/ActiveCriterion/CheckboxFilter';
import ExpandableCheckboxFilter from 'components/ActiveCriterion/ExpandableCheckboxFilter';
import FundTypeSubheader from 'components/ActiveCriterion/FundTypeSubheader';
import _ from 'lodash';

export default class FundTypeInterface extends Component {
  constructor() {
    super();
    this.state = {
      activeSection: 'category',
      expandedCategory: '',
    };
  }

  toggleExpansion = category => {
    if (this.state.expandedCategory === category) {
      this.setState({ expandedCategory: '' });
    } else {
      this.setState({ expandedCategory: category });
    }
  };

  handleClickRiskCheckbox = (label, isChecked) => {
    this.props.onFiltersUpdated({ risk: { [label]: isChecked } }, 'risk');
  };

  onCategoryFiltersUpdated = filterUpdates => {
    this.props.onFiltersUpdated(filterUpdates, 'category');
  };

  handleFundTypeToggle = event => {
    // make updates for new active section
    const newActiveSection = event.target.value;
    const filterUpdates = {
      risk: newActiveSection === 'risk' ? {} : null,
      category: newActiveSection === 'category' ? {} : null,
      assetClass: newActiveSection === 'category' ? {} : null,
    };

    this.props.onFiltersUpdated(filterUpdates, newActiveSection);
  };

  _renderRiskFilters = () => {
    const filters = this.props.filters.risk.map((filter, index) => {
      const riskInformation = this._renderRiskInformation(filter);
      const isChecked = this.props.filtersSelected.risk.includes(filter.label);
      return (
        <CheckboxFilter
          key={filter.label}
          index={index}
          isChecked={isChecked}
          filter={filter}
          handleClick={this.handleClickRiskCheckbox}
        >
          {riskInformation}
        </CheckboxFilter>
      );
    });

    return <ul className="risk-filters">{filters}</ul>;
  };

  _renderRiskInformation = filter => {
    let text = null;

    if (filter.label.toString() === '10') text = '(Higher Risk)';
    if (filter.label.toString() === '1') text = '(Lower Risk)';

    return (
      <div className="risk-information">
        <div className="risk-icon">
          <span className={`risk-icon-gradient risk-level-${filter.label}`} />
          <span className="risk-icon-legend">Lower</span>
          <span className="risk-icon-legend">Higher</span>
        </div>
        {text ? <span className="risk-icon-text">{text}</span> : null}
      </div>
    );
  };
  _renderCategoryFilters = () => {
    const { filters } = this.props;
    const { category: selectedCategories, assetClass: selectedAssetClasses } = this.props.filtersSelected;
    // allow for quick lookup to see if an asset class is selected
    const selectedAssetClassLookup = _.transform(selectedAssetClasses, (result, value) => (result[value] = true), {});
    // categoriesByCode contains match counts as well so we want an easy way to fetch these for later
    const categoriesByCode = _.keyBy(filters.category, 'value');

    
    const expandableCheckBoxFilters = !filters.assetClass?null: filters.assetClass
      .sort((a, b) => sorting.alphabetSort(a.label, b.label))
      .map(assetClass => {
        const isAssetClassSelected = selectedAssetClassLookup[assetClass.label];
        // these are the current category filters along with their match counts
        const assetClassCategories = _.map(assetClass.categories, category => categoriesByCode[category.value]);
        const selectedAssetClassCategories = (() => {
          if (isAssetClassSelected) {
            // only consider categories as selected if the current asset class is also selected
            const categoryLookup = _.transform(
              assetClass.categories,
              (result, item) => (result[item.label] = true),
              {},
            );
            return _.filter(selectedCategories, label => categoryLookup[label]);
          }
          return [];
        })();
        return (
          <ExpandableCheckboxFilter
            key={assetClass.label}
            filters={assetClassCategories}
            filter={assetClass}
            filtersSelected={selectedAssetClassCategories}
            isExpanded={this.state.expandedCategory === assetClass.label}
            toggleExpansion={this.toggleExpansion}
            onFiltersUpdated={this.onCategoryFiltersUpdated}
            parentFilterGroupId={'assetClass'}
            filterGroupId={'category'}
            isSelected={isAssetClassSelected}
          />
        );
      });

    return <div className="category-filters">{expandableCheckBoxFilters}</div>;
  };

  render() {
    const { filtersSelected, criterion } = this.props;
    const { id } = criterion;
    const activeType = _.has(filtersSelected, 'risk') ? 'risk' : 'category';
    const filters = activeType === 'risk' ? this._renderRiskFilters() : this._renderCategoryFilters();
    const subheader = (() => {
      const filterGroups = ALL_CRITERIA[id].filterGroups;
      if (_.has(filterGroups, 'category') && _.has(filterGroups, 'risk')) {
        // only add sub-header with radio buttons if fundType includes both 'category' and 'risk'
        return <FundTypeSubheader handleToggle={this.handleFundTypeToggle} activeType={activeType} />;
      }
      return null;
    })();

    return (
      <div tabIndex={0} className="fund-type-interface">
        {subheader}
        {filters}
      </div>
    );
  }
}
