import React, { Component } from 'react';
import { connect } from 'react-redux';
import Creators from 'actions/CriteriaActions';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactDOMServer from 'react-dom/server';
import { getColumnsForViewTab } from 'reducers/ViewTabReducer';
import { getSortSettings } from 'reducers/SortResultsReducer';
import { getIsMobile } from 'reducers/MobileStatusReducer';
import ResultToggleGroupContainer from 'components/ResultsView/ResultToggleGroupContainer';
import ResultsTablePopover from '../Shared/ResultsTablePopover';
import { makeGetToggleGroupSelections, getToggleGroups } from 'reducers/ResultToggleGroupReducer';
import { getAsOfDateSelector } from 'reducers/FundSearchResultsReducer';
import { getSomeResultsLoading } from 'reducers/LoadingReducer';
import { START_PAGE_TYPE, RESULT_FEATURES } from 'configs/AppFeatureConfig';
import { getSharedStaticColumns } from 'reducers/SharedStaticColumnsReducer';
import icons from 'utilities/icons';

class ResultsTableHeader extends Component {
  componentDidUpdate = () => {
    if (this.refs.thead && !_.isNil(this.props.scrollPos)) {
      this.refs.thead.scrollLeft = this.props.scrollPos;
    }
  };
  sortTable = column => {
    const sortDir = this.props.sortCol !== column ? 'desc' : this.props.sortDir === 'asc' ? 'desc' : 'asc';
    this.props.sortResults(column, sortDir);
  };

  _applySortForToggleGroup = toggleGroupConfig => {
    const toggleGroupSelection = this.props.toggleGroupSelections[toggleGroupConfig.shortName];
    if (!_.isNil(toggleGroupSelection)) {
      const resultConfigForSelection = _.find(toggleGroupConfig.resultList, result => {
        return result.id === toggleGroupSelection.id;
      });
      if (!_.isNil(resultConfigForSelection)) {
        this.sortTable(resultConfigForSelection.sortField);
      }
    }
  };

  _getColumns = () => {
    const { columns, sharedStaticColumns } = this.props;
    const rightColumns = _.filter(columns, col => !_.includes(sharedStaticColumns, col.id));
    const staticColumns = _.filter(columns, col => _.includes(sharedStaticColumns, col.id));
    return [{ label: 'Action' }, ...staticColumns, ...rightColumns];
  };

  _renderColGroup = colGroup => {
    switch (colGroup.type) {
      case 'text':
        return colGroup.label;
      case 'dailyNAVAsOfDate': {
        const dailyNAVAsOfDate = this.props.dailyNAVAsOfDate;
        const asOfDateText = !_.isNil(dailyNAVAsOfDate) && !this.props.someResultsLoading ? dailyNAVAsOfDate : '--';
        return colGroup.label + asOfDateText;
      }
      case 'dailyPerformanceAsOfDate': {
        const dailyPerformanceAsOfDate = this.props.dailyPerformanceAsOfDate;
        const asOfDateText =
          !_.isNil(dailyPerformanceAsOfDate) && !this.props.someResultsLoading ? dailyPerformanceAsOfDate : '--';
        return colGroup.label + asOfDateText;
      }
      default:
        return colGroup.label;
    }
  };

  _isColumnSortable = col => {
    if (col.resultType === 'resultToggleGroup') {
      return _.every(col.resultList, resultConfig => {
        return _.has(resultConfig, 'sortField') && !_.isNil(resultConfig.sortField);
      });
    }
    return false;
  };

  _renderGroupedColHeader = (numColsInGroup, col, isSortable = false) => {
    const colGroup = col.columnGroup;
    if (isSortable) {
      const { sortDir, sortCol } = this.props;
      const isSortCol = _.some(col.resultList, resultConfig => resultConfig.sortField === sortCol);
      const sortClass = isSortCol ? ` sort-${sortDir}` : '';
      const mStarClassName =
        this._renderColGroup(colGroup) === 'Morningstar'
          ? `${START_PAGE_TYPE}-morningstar-${this.props.currentSelection.label.replace(/[' ']/g, '')}`
          : '';
      return (
        <th
          className={`column-group sort ${sortClass} ${mStarClassName}`}
          onClick={() => this._applySortForToggleGroup(col)}
          key={colGroup.id}
          colSpan={numColsInGroup}
        >
          <span className={`column-group-label ${mStarClassName}`}>{this._renderColGroup(colGroup)}</span>
        </th>
      );
    } else {
      return (
        <th className="column-group" key={colGroup.id} colSpan={numColsInGroup}>
          <span className="column-group-label">{this._renderColGroup(colGroup)}</span>
        </th>
      );
    }
  };

  _renderGroupedColHeaderSpacer = (key, sortField, col, index) => {
    const { sortCol, sharedStaticColumns } = this.props;
    const classNames = ['column-group-spacer'];
    let style = {};
    if (sortField === sortCol) {
      classNames.push('sorted-column-cell');
    }
    if (col) {
      const staticColId = col ? sharedStaticColumns.find(e => e === col.id) : null;
      if (col.label === 'Action' || staticColId) {
        classNames.push('col-static');
        if (this.props.staticColumnsLeft && this.props.staticColumnsLeft[index]) {
          style['left'] = this.props.staticColumnsLeft[index] + 'px';
        }
      }
      classNames.push('col-' + key);
    }
    return <th className={classNames.join(' ')} key={key} style={style} />;
  };

  _getGroupedColHeaders = () => {
    const columns = this._getColumns();
    const numInEachGroup = _.chain(columns)
      .filter(col => _.has(col, 'columnGroup.id'))
      .countBy(col => col.columnGroup.id)
      .value();
    const groupsAlreadyRendered = _.mapValues(numInEachGroup, () => false);
    const groupColHeaders = [];
    _.each(columns, (col, index) => {
      const hasColGroup = _.has(col, 'columnGroup.id') && !_.isNil(col.columnGroup.id);
      if (!hasColGroup) {
        groupColHeaders.push(this._renderGroupedColHeaderSpacer(col.label, col.sortField, col, index));
      } else {
        const colGroup = col.columnGroup;
        const isColGroupRendered = groupsAlreadyRendered[colGroup.id];

        if (!isColGroupRendered) {
          const isSortable = this._isColumnSortable(col);
          const numColsInGroup = numInEachGroup[colGroup.id];
          const header = this._renderGroupedColHeader(numColsInGroup, col, isSortable);
          groupColHeaders.push(header);
          groupsAlreadyRendered[colGroup.id] = true;
        }
      }
    });

    if (RESULT_FEATURES.HAS_BUY_BUTTON) {
      // add spacer for buy button column
      groupColHeaders.push(this._renderGroupedColHeaderSpacer('buy-button-column-group'));
    }

    return groupColHeaders;
  };

  _renderGroupedColHeaders = () => {
    if (!this.props.isScrollableTable) return null;

    const groupColHeaders = this._getGroupedColHeaders();

    const headerRowStyle = !_.isNil(this.props.headerRowHeights)
      ? { height: _.first(this.props.headerRowHeights) }
      : null;
    return (
      <tr className="grouped-column-headers" style={headerRowStyle}>
        {groupColHeaders}
      </tr>
    );
  };
  _getHeaderCellWidth = index => {
    const { isFixedHeader, headerDimensions } = this.props;
    if (!isFixedHeader) return null;
    const width = _.get(headerDimensions, [index, 'width'], null);
    return { width };
  };

  _applyDisclosureIndicator = (text, disclosureIndicatorObj) => {
    const { disclosureSymbol, applyIndicatorAfter } = disclosureIndicatorObj;
    //if no disclosure symbol then return original text
    if (_.isNil(disclosureSymbol)) {
      return text;
    }
    // create the superscript element with the indicator symbol
    let disclosureSymbolHtml = (
      <sup className="disclosure-indicator" dangerouslySetInnerHTML={{ __html: disclosureSymbol }} />
    );
    //render the element as a string so that it can be inserted where we need it in the header
    disclosureSymbolHtml = ReactDOMServer.renderToStaticMarkup(disclosureSymbolHtml);

    //If 'applyIndicatorAfter' is null/undefined or doesn't match anything in 'text' just add the disclosure indicator to the end
    const applyIndicatorToEnd = _.isNil(applyIndicatorAfter) || text.indexOf(applyIndicatorAfter) === -1;
    if (applyIndicatorToEnd) {
      return `${text} ${disclosureSymbolHtml}`;
    }
    //Find index of last characer in 'text' of substring matching 'applyIndicatorAfter'.
    const applyIndicatorAtIndex = text.indexOf(applyIndicatorAfter) + applyIndicatorAfter.length;
    return `${text.slice(0, applyIndicatorAtIndex)} ${disclosureSymbolHtml} ${text.slice(applyIndicatorAtIndex)}`;
  };

  _renderHeaderLabel = (column, name) => {
    const { headerIcon, disclosureIndicator } = column;
    const hasDisclosureIndicator = !_.isNil(disclosureIndicator);
    let label = column.label;
    if (hasDisclosureIndicator) {
      label = this._applyDisclosureIndicator(label, disclosureIndicator);
    }
    if (label === 'NTF') {
      label = 'Non Transaction Fee';
    }
    let popover = null;

    switch (column.id) {
      case 'lifeOfFund':
        if (START_PAGE_TYPE !== 'mfe') {
          popover = <ResultsTablePopover definition="Life of Fund" container="table-container" />;
        }
        break;
      case 'lifeOfSubaccount':
        popover = <ResultsTablePopover definition="Life of Subaccount" container="table-container" />;
        break;
      default:
        break;
    }
    if (_.isNil(headerIcon)) {
      return (
        <span className="header-label">
          <span
            className={`${hasDisclosureIndicator ? `no-wrap ${name}` : name}`}
            dangerouslySetInnerHTML={{ __html: label }}
          />
          {!_.isNil(popover) ? popover : ''}
        </span>
      );
    } else {
      return (
        <span className="header-label">
          <img title={label} alt={label} src={icons[headerIcon]} />
        </span>
      );
    }
  };

  _renderHeaderButtonAltText = (headerCellStyle, col, colLabel) => {
    return (
      <button style={headerCellStyle} className="header-label-container">
        {this._renderHeaderLabel(col, colLabel)}
      </button>
    );
  };

  _renderTableHeaders = () => {
    const { sortDir, sortCol, sharedStaticColumns } = this.props;
    const columns = _.map(this._getColumns(), (col, idx) => {
      let staticColId = sharedStaticColumns.find(e => e === col.id);
	  let staticColumn="";
	  let style={};
      if (staticColId || col.label === 'Action') {
        staticColumn = 'col-static';
        if (col.id === 'fidelityFund' || col.id === 'fundPicks' || col.id === 'sociallyResponsible') {
          staticColumn = staticColumn + ' col-static-icon';
        } else if (col.label === 'Action') {
          staticColumn = staticColumn + ' col-' + col.label;
        }
        if (staticColId) {
          staticColumn = staticColumn + ' col-' + staticColId;
        }
		if (this.props.staticColumnsLeft && this.props.staticColumnsLeft[idx]){
			style['left']=this.props.staticColumnsLeft[idx]+'px';
		}
      }
      const isSortable = !_.isNil(col.sortField);
      const headerCellStyle = this._getHeaderCellWidth(idx);
      const strippedColLabel = col.label.replace(/[' '\(\)]/g, '');
      const thName = 'col-' + strippedColLabel;
      if (isSortable) {
        const colLabel = START_PAGE_TYPE + '-ResultTable-' + strippedColLabel;
        const sortClass = col.sortField === sortCol ? `sort sort-${sortDir}` : 'sort';
        if (col.sortField === sortCol && sortDir) {
          return (
            <th
              name={thName}
              className={`${sortClass} ${col.align} ${colLabel} ${staticColumn}`}
              onClick={() => this.sortTable(col.sortField)}
              key={col.label}
              aria-sort={sortDir === 'desc' ? 'descending' : 'ascending'}
			  style={style}
            >
              {this._renderHeaderButtonAltText(headerCellStyle, col, colLabel)}
            </th>
          );
        }
        return (
          <th
            name={thName}
            className={`${sortClass} ${col.align} ${colLabel} ${staticColumn}`}
            onClick={() => this.sortTable(col.sortField)}
            key={col.label}
			style={style}
          >
            {this._renderHeaderButtonAltText(headerCellStyle, col, colLabel)}
          </th>
        );
      } else if (col.resultType === 'resultToggleGroup') {
        const isSortCol = _.some(col.resultList, resultConfig => {
          return resultConfig.sortField === sortCol;
        });
        const sortClass = isSortCol ? ` sort-${sortDir}` : '';
        if (isSortCol && sortDir) {
          return (
            <th
              name={thName}
              key={col.label}
              className={`result-toggle-group${sortClass} ${col.align}`}
              aria-sort={sortDir === 'desc' ? 'descending' : 'ascending'}
			  style={style}
            >
              <div style={headerCellStyle} className="header-label-container">
                <ResultToggleGroupContainer
                  groupShortName={col.shortName}
                  groupLabel={col.label}
                  resultList={col.resultList}
                  isFixed={this.props.isFixedHeader}
                  scrollPos={this.props.scrollPos}
                  isSorted={isSortCol}
                />
              </div>
            </th>
          );
        }
        return (
          <th name={thName} key={col.label} className={`result-toggle-group${sortClass} ${col.align}`} style={style}>
            <div style={headerCellStyle} className="header-label-container">
              <ResultToggleGroupContainer
                groupShortName={col.shortName}
                groupLabel={col.label}
                resultList={col.resultList}
                isFixed={this.props.isFixedHeader}
                scrollPos={this.props.scrollPos}
                isSorted={isSortCol}
              />
            </div>
          </th>
        );
      }
      return (
        <th name={thName} key={col.label} className={`${col.align} ${staticColumn}`}  style={style}>
          <div style={headerCellStyle} className="header-label-container">
            {this._renderHeaderLabel(col)}
          </div>
        </th>
      );
    });
    if (this.props.isScrollableTable && RESULT_FEATURES.HAS_BUY_BUTTON) {
      // add header for button so that our separator line reaches the end of the table
      const buyButtonHeader = this._renderBuyButtonSpacer();
      columns.push(buyButtonHeader);
    }
    return columns;
  };
  _renderBuyButtonSpacer = () => {
    const buyButtonHeaderStyle = this._getHeaderCellWidth(this._getColumns().length);
    const buyButtonHeader = (
      <th name="col-buyButton" key="buy-button-header" colSpan="1">
        <div style={buyButtonHeaderStyle} className="header-label-container" />
      </th>
    );
    return buyButtonHeader;
  };
  _constructHeaderClassName = () => {
    const { className, showFixedHeader, isFixedHeader, isMobile } = this.props;

    let headerClass = className || '';
    if (isFixedHeader) {
      // Disgustingly necessary bandaid fix to force popover to close instead of bugging out
      // when switching from static to fixed header & vice versa.
      if (this.showFixedHeaderCache !== showFixedHeader) {
        let popovers = document.querySelectorAll('.popover-header > .close-icon');
        if (!_.isNil(popovers)) {
          popovers.forEach(p => {
            p.click();
          });
        }
      }
      const showHideClass = showFixedHeader ? (isMobile ? 'fixed-mobile' : 'fixed') : 'hide';
      headerClass = `${headerClass} ${showHideClass}`;
      this.showFixedHeaderCache = showFixedHeader;
    }
    return headerClass;
  };
  _constructHeaderStyle = () => {
    const { headerHeight, headerWidth, isFixedHeader, headerLeft } = this.props;
    if (isFixedHeader) {
      return { height: headerHeight, width: headerWidth, left:headerLeft };
    }
    return !_.isNil(headerHeight) ? { height: headerHeight } : null;
  };
  _renderColumnHeaderRow = () => {
    const tableHeaders = this._renderTableHeaders();
    const columnHeaderRowStyle =
      this.props.isScrollableTable && !_.isNil(this.props.headerRowHeights)
        ? { height: _.last(this.props.headerRowHeights) }
        : null;

    return <tr style={columnHeaderRowStyle}>{tableHeaders}</tr>;
  };
  render() {
    const { id } = this.props;
    const headerClass = this._constructHeaderClassName();
    const headerStyle = this._constructHeaderStyle();

    return (
      <thead ref="thead" style={headerStyle} id={id} className={headerClass}>
        {this._renderGroupedColHeaders()}
        {this._renderColumnHeaderRow()}
      </thead>
    );
  }
}
ResultsTableHeader.defaultProps = {
  isFixedHeader: false,
};
ResultsTableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  sortResults: PropTypes.func.isRequired,
  sortCol: PropTypes.string,
  sortDir: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  isScrollableTable: PropTypes.bool.isRequired,
  toggleGroupSelections: PropTypes.object.isRequired,
  headerLeft: PropTypes.number,
  headerHeight: PropTypes.number,
  headerRowHeights: PropTypes.array,
  headerDimensions: PropTypes.array,
  headerWidth: PropTypes.number,
  showFixedHeader: PropTypes.bool,
  dailyNAVAsOfDate: PropTypes.string,

  isFixedHeader: PropTypes.bool.isRequired,

  /** True if result table is still loading */
  someResultsLoading: PropTypes.bool.isRequired,

  sharedStaticColumns: PropTypes.array.isRequired,
  staticColumnsLeft: PropTypes.array
};

const mapStateToProps = state => {
  const columns = getColumnsForViewTab(state);
  const { sortDir, sortCol } = getSortSettings(state);
  const toggleGroupSelections = getToggleGroups(state);
  const dailyNAVAsOfDate = getAsOfDateSelector('dailyNAV')(state);
  const dailyPerformanceAsOfDate = getAsOfDateSelector('dailyPerformance')(state);
  const sharedStaticColumns = getSharedStaticColumns(state);
  const someResultsLoading = getSomeResultsLoading(state);
  const isMobile = getIsMobile(state);
  const getToggleGroupSelections = makeGetToggleGroupSelections();
  const currentSelection = getToggleGroupSelections(state, { groupShortName: 'mstar' });

  return {
    columns,
    sortCol,
    sortDir,
    toggleGroupSelections,
    dailyNAVAsOfDate,
    dailyPerformanceAsOfDate,
    someResultsLoading,
    sharedStaticColumns,
    isMobile,
    currentSelection,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    sortResults: (column, direction) => {
      dispatch(Creators.sortResults(column, direction));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsTableHeader);
