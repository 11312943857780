import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';
import { createSelector } from 'reselect';
import _ from 'lodash';
import Types from 'actions/Types';
import { APP_DISCLOSURE_CODE_PREFIX } from 'configs/AppFeatureConfig';
import { getFundTypeFilters } from 'reducers/FundTypeFiltersReducer';

export const INITIAL_STATE = [];

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};

const updateDatavalueDisclosures = (state = INITIAL_STATE, action = {}) => {
  return [...action.payload.data];
};

const ACTION_HANDLERS = {
  [Types.FUND_DATAVALUE_DISCLOSURE_API_SUCCESS]: updateDatavalueDisclosures,
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

// ---- Selectors ----

const getAllDatavalueDisclosures = state => state.datavalueDisclosures;
const getDatavalueEntityName = (state, id) => id;
// const getDatavalueCode = (state)

const _getMatchingAssetClassDisclosure = (assetClassLabel, disclosures) => {
  if (_.isNil(assetClassLabel)) return {};
  assetClassLabel = assetClassLabel.toLowerCase();

  if (assetClassLabel === 'allocation') assetClassLabel = 'balanced';

  if (assetClassLabel.indexOf('equity') >= 0) {
    assetClassLabel = assetClassLabel.replace('equity', 'stock');
  }

  for (let i = 0; i < disclosures.length; i++) {
    const label = _.get(disclosures[i], 'code', '');
    if (label.toLowerCase().indexOf(assetClassLabel) >= 0) {
      return disclosures[i];
    }
  }

  return {};
};

export const getDatavalueDisclosureList = createSelector(
  [getAllDatavalueDisclosures, getDatavalueEntityName],
  (disclosures, entityName) => {
    const dvalDisclosures = _.find(disclosures, disclosure => {
      return disclosure.entityName === entityName;
    });

    return _.get(dvalDisclosures, 'sectionCodes[0].disclosureList');
  },
);

export const getAssetClassDisclosureMap = createSelector(
  [getAllDatavalueDisclosures, getFundTypeFilters],
  (disclosures, fundTypeFilters) => {
    const dvalDisclosures = _.find(disclosures, disclosure => {
      return disclosure.entityName === 'ASET_CLASS_X';
    });

    const dvalDisclosureList = _.get(dvalDisclosures, 'sectionCodes[0].disclosureList', []);

    const disclosureMap = {
      'All Asset Classes': _getMatchingAssetClassDisclosure('All Asset Classes', dvalDisclosureList),
    };
    fundTypeFilters.forEach(assetClass => {
      const disclosure = _getMatchingAssetClassDisclosure(assetClass.label, dvalDisclosureList);
      disclosureMap[assetClass.label] = disclosure;
    });

    return disclosureMap;
  },
);

// TODO: Fidelity needs to specify how the generic modal content related with the asset class disclosures will be mapped
// Currently there are no reliable mappings and the modal content does not change based on asset class
export const getAssetClassDisclosureModalContent = createSelector(
  [getAllDatavalueDisclosures],
  disclosures => {
    const dvalDisclosures = _.find(disclosures, disclosure => {
      return disclosure.entityName === 'ASET_CLASS_X';
    });

    const dvalDisclosureList = _.get(dvalDisclosures, 'sectionCodes[0].disclosureList', []);

    const modalDisclosures = _.filter(dvalDisclosureList, disclosure =>
      disclosure.code.includes(`${APP_DISCLOSURE_CODE_PREFIX} Ctgy Risk Dstrb Desc`),
    );

    const modalDisclosureMap = {};
    _.each(modalDisclosures, disclosure => {
      const assetClass = _.last(disclosure.code.split(`${APP_DISCLOSURE_CODE_PREFIX} Ctgy Risk Dstrb Desc`)).trim();

      modalDisclosureMap[assetClass] = disclosure.text;
    });

    return modalDisclosureMap;
  },
);
