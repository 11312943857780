import { connect } from 'react-redux';
import Creators from 'actions/CriteriaActions';
import _ from 'lodash';
import { getViewTab } from 'reducers/ViewTabReducer';
import { getFundsSelected } from 'reducers/FundsSelectedReducer';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import * as odaTracking from 'utilities/odaTracking';
import * as adobeAnalytics from 'utilities/adobeAnalytics';
import { popup } from 'utilities';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FundsSelectedPopoverContainer from 'components/ResultsView/FundsSelectedPopoverContainer';
import { getHelpMeReadThisLinkFullUrl } from 'reducers/selectors/LinkSelectors';
import {
  RESULT_FEATURES,
  COMPARE_FEATURES,
  HAS_HELP_ME_READ_THIS_TABLE_LINK,
  HELP_ME_READ_THIS_TABLE_LINK_IS_POPUP,
} from 'configs/AppFeatureConfig';

class TakeActionMenu extends Component {
  constructor(props) {
    super(props);
    this.popoverRef = React.createRef();
    this.state = {
      showFunds: false,
	  isKeyNaviMode: false
    };
  }
  dispatchCompareFunds = e => {
    this.props.trackShowCompareView();
    this.props.showCompareView(this.props.fundsSelected);
  };
  dispatchShowFullResults = () => {
    this.props.trackBackToResearchResultsButton();
    this.props.showFullResultsView(this.props.viewTab);
  };

  _showHelpMeReadThisTable = () => {
    window.open(this.props.helpMeReadThisLinkFullUrl, 'helpMeReadThisTableWindow');
  };

  _showHelpMeReadThisTablePopup = () => {
    popup.openPopup(this.props.helpMeReadThisLinkFullUrl, 'helpMeReadThisTableWindow');
  };

  _renderCompareButton = () => {
    const buttonText = RESULT_FEATURES.COMPARE_BUTTON_LABEL;
    let button = <button onClick={this.dispatchCompareFunds}>{buttonText}</button>;
    if (!this.props.fundsSelected.length) {
      button = <button className="disabled">{buttonText}</button>;
    }

    return <div className="results-view-action-menu">{button} </div>;
  };
  _renderBackToFullResults = () => {
    return (
      <div className="compare-view-action-menu">
        <button onClick={this.dispatchShowFullResults} className="back-to-research-results">
          {COMPARE_FEATURES.BACK_BUTTON_LABEL}
        </button>
        {HAS_HELP_ME_READ_THIS_TABLE_LINK ? (
          HELP_ME_READ_THIS_TABLE_LINK_IS_POPUP ? (
            <button onClick={this._showHelpMeReadThisTablePopup} className="help-me-read-this-table as-link">
              <span>Help me read this table</span>
              <div role="img" alt="Popup Icon" className="popup-icon">
                <span>?</span>
              </div>
            </button>
          ) : (
            <button onClick={this._showHelpMeReadThisTable} className="help-me-read-this-table as-link">
              <span>Help me read this table</span>
            </button>
          )
        ) : null}
      </div>
    );
  };
  _renderActionMenuContent = () => {
    if (this.props.displayType === 'compare') {
      return this._renderBackToFullResults();
    } else {
      return this._renderCompareButton();
    }
  };
  _toggleFundsPopover = () => {
    this.setState({ showFunds: !this.state.showFunds, isKeyNaviMode: this.state.isKeyNaviMode });
  };
  _keydownHandler = (e, a)=>{
    this.setState({ showFunds: this.state.showFunds, isKeyNaviMode: true });
  }
  _closePopover = () => {
    this.setState({ showFunds: false, isKeyNaviMode: this.state.isKeyNaviMode });
	this.popoverRef.current.focus();
  };

  _renderShowFundsLink = () => {
    if (this.props.fundsSelected.length) {
      return (
        <button ref={this.popoverRef} className="show-funds-selected as-link" 
			onKeyDown={e=> this._keydownHandler(e, 'down')}
			onKeyUp={e=> this._keydownHandler(e, 'up')}
			onClick={this._toggleFundsPopover} 
			aria-expanded={this.state.showFunds}
		>
          (show {RESULT_FEATURES.RESULT_TYPE}s)
        </button>
      );
    }
    return <button className="show-funds-selected as-link disabled">(show {RESULT_FEATURES.RESULT_TYPE}s)</button>;
  };
  _renderNumFundsSelected = () => {
    const numFundsSelected = this.props.fundsSelected.length;
    const plural = numFundsSelected !== 1 ? 's' : '';
    const fundsSelectedText = `${numFundsSelected} ${RESULT_FEATURES.RESULT_TYPE_CAPITALIZED}${plural} Selected`;
    return (
      <div id="num-funds-selected-container">
        <p className="num-funds-selected">{fundsSelectedText}</p>
        {this._renderShowFundsLink()}
      </div>
    );
  };

  _renderFundsSelected = () => {
    if (this.state.showFunds && this.props.fundsSelected.length) {
      return (
        <FundsSelectedPopoverContainer
          isOpen={this.state.showFunds}
		  isKeyNaviMode = {this.state.isKeyNaviMode}
          container="table-container"
          closePopover={this._closePopover}
          referenceElement={this.popoverRef.current}
        />
      );
    }
    return null;
  };
  render() {
    let fundSelectionContainer = null;
    let helpMeReadThisButton = null;
    if (this.props.displayType !== 'compare') {
      fundSelectionContainer = (
        <div className="fund-selections-container"  aria-live="polite">
          {this._renderNumFundsSelected()} {this._renderFundsSelected()}
        </div>
      );
      // When HAS_MATCHING_FUNDS_LABEL is false we are moving the help button here
      const shouldRenderHelpButtonInActionMenu =
        !RESULT_FEATURES.HAS_MATCHING_FUNDS_LABEL && HAS_HELP_ME_READ_THIS_TABLE_LINK;
      helpMeReadThisButton = shouldRenderHelpButtonInActionMenu ? (
        HELP_ME_READ_THIS_TABLE_LINK_IS_POPUP ? (
          <button onClick={this._showHelpMeReadThisTablePopup} className="help-me-read-this-table as-link">
            <span>Help me read this table</span>
            <div role="img" alt="Popup Icon" className="popup-icon">
              <span>?</span>
            </div>
          </button>
        ) : (
          <button onClick={this._showHelpMeReadThisTable} className="help-me-read-this-table as-link">
            <span>Help me read this table</span>
          </button>
        )
      ) : null;
    }

    return (
      <div id="take-action-menu">
        {this._renderActionMenuContent()}
        {fundSelectionContainer}
        {helpMeReadThisButton}
      </div>
    );
  }
}

TakeActionMenu.propTypes = {
  showCompareView: PropTypes.func.isRequired,
  showFullResultsView: PropTypes.func.isRequired,
  trackBackToResearchResultsButton: PropTypes.func.isRequired,
  trackShowCompareView: PropTypes.func.isRequired,
  fundsSelected: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  const fundsSelected = getFundsSelected(state);
  const displayType = getDisplayType(state);
  const viewTab = getViewTab(state);
  const helpMeReadThisLinkFullUrl = getHelpMeReadThisLinkFullUrl(state);

  return {
    fundsSelected,
    displayType,
    viewTab,
    helpMeReadThisLinkFullUrl,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    showCompareView: fundsSelected => {
      const fundsWithoutCompareInfo = _.map(fundsSelected, fund => {
        return _.pick(fund, ['ticker', 'cusip', 'name', 'fundCode']);
      });
      dispatch(Creators.showCompareView(fundsWithoutCompareInfo));
    },
    showFullResultsView: viewTab => {
      dispatch(Creators.showFullResultsView(viewTab));
    },
    trackBackToResearchResultsButton: () => {
      const trackingData = odaTracking.getCompareBackToResearchResultsTrackingData();
      dispatch(Creators.odaLogAction(trackingData));
      const adobeTrackingData = adobeAnalytics.getCompareBackToResearchResultsTrackingData();
      dispatch(Creators.trackAdobeAnalytics(adobeTrackingData));
    },
    trackShowCompareView: () => {
      const adobeTrackingData = adobeAnalytics.getResultsCompareFundsTrackingData();
      dispatch(Creators.trackAdobeAnalytics(adobeTrackingData));
    },
    updateFundsSelected: fundsSelected => {
      dispatch(Creators.updateFundsSelected(fundsSelected));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TakeActionMenu);
