////  important
////  this json files are used only for test, so we can reduce bundles size about 3MB if we don't import those json mockup files.

/// build time
export const mockDataInjection = {
	getData: (keyword, parameters) =>{
		return null;
	},
	isInLocal: ()=>{
		return RUNNING_ENVIRONMENT && RUNNING_ENVIRONMENT.Env==='Development'? true: false;
	},
	enableMockDataInjection: false,
	enableDataInjection: (response) => { return false; }
}

/// You just use this code for mockup local tests.
/// You just commented out the mockDataInjection above and use below codes.
/// please don't remove below codes and don't turn on when pushing to repo.

// const countData = require("./mockDataSet1-count.json");
// const disclosures=require("./mockDataSet1-disclosures.json");
// const classification=require("./mockDataSet1-classification.json");
// const annuities=require("./mockDataSet1-annuities.json");
// const netBenefits = require("./mockDataSet1-netBenefits.json");
// const sustainableAttributes = require("./mockDataSet1-SustainableAttributes.json");
// const compareWFirst3funds = require("./mockDataSet1-CompareWFirst3funds.json");

// import {RUNNING_ENVIRONMENT} from '../../configs/AppFeatureConfig';

// const isMatchWithParameters = (parameters, paramString) => {
// 	if (!parameters) { return true; }
// 	if (parameters.length>0) {
// 		let exists = false;
// 		parameters.forEach(params => {
// 			if(JSON.stringify(params) === paramString) {
// 				exists = true;
// 			}
// 		});
// 		return exists;
// 	} else {
// 		return JSON.stringify(parameters) === paramString;
// 	}
// }

// export const mockDataInjection = {
// 	getData: (keyword, parameters) =>{
// 		const paramString = parameters? JSON.stringify(parameters): null;
// 		const data = [...countData, ...disclosures, ...classification, ...annuities, ...netBenefits, ...sustainableAttributes,...compareWFirst3funds];
// 		const item = data.find(e=> e.link.indexOf(keyword)>=0 && (!paramString || isMatchWithParameters(e.parameters, paramString)));
// 		if (item){
// 			return item.response;
// 		} else {
// 			console.log("NOT MATCH: keyword, parameters, item link, item parameters: ", keyword, JSON.stringify(parameters));
// 		}
// 		return null;
// 	},
// 	isInLocal: ()=>{
// 		return RUNNING_ENVIRONMENT && RUNNING_ENVIRONMENT.Env==='Development'? true: false;
// 	},
// 	enableMockDataInjection: false,
// 	enableDataInjection: (response) => {
// 		if (response){
// 			return !response.ok && mockDataInjection.enableMockDataInjection && mockDataInjection.isInLocal();
// 		} else {
// 			return mockDataInjection.enableMockDataInjection && mockDataInjection.isInLocal();
// 		}
// 	}
// }


