import React, { useState, useEffect } from 'react';
import CriteriaSelectionBox from './MadLibsCriteriaSelectionBox';
import SelectedCriteriaBox from './MadLibsSelectedCriteriaBox';
import _ from 'lodash';

const MadLibsAdditionalCriteriaContainer = ({ updateMadLibsAdditionalCriteria, additionalCriteria }) => {
  const [isActive, setIsActive] = useState(false);

  const onOpenClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    const pageClickEvent = e => {
      if (e.target.id !== 'additional-critieria') {
        setIsActive(!isActive);
      }
    };
    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }
    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive]);

  const toggleCriterionSelection = criterion => {
    const revisedCriteria = additionalCriteria.map(item => {
      const itemClone = _.cloneDeep(item);

      if (itemClone.label === criterion.label) {
        itemClone.selected = !itemClone.selected;
      }
      return itemClone;
    });
    updateMadLibsAdditionalCriteria(revisedCriteria);
  };

  const filteredForSelect = additionalCriteria.filter(n => n.selected);

  return (
    <div className="additionalCriteria-container">
      <div className="plus">
        +<a onClick={onOpenClick}>Add Additional Criteria</a>
      </div>
      <div className={`criteria-box ${isActive ? 'active' : 'inactive'}`}>
        <CriteriaSelectionBox criteria={additionalCriteria} toggleCriterionSelection={toggleCriterionSelection} />
      </div>
      <SelectedCriteriaBox selectedCriteria={filteredForSelect} toggleCriterionSelection={toggleCriterionSelection} />
    </div>
  );
};
export default MadLibsAdditionalCriteriaContainer;
