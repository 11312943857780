import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';
import Types from '../actions/Types';
import { createSelector } from 'reselect';
import _ from 'lodash';

export const INITIAL_STATE = {};

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};

const updateMatches = (state = INITIAL_STATE, action = {}) => {
  return { ...state, [action.strategyId]: action.payload.data.totalCount };
};

const ACTION_HANDLERS = {
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
  [Types.STRATEGY_MATCHES_API_SUCCESS]: updateMatches,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

// ---- Selectors ----
export const getAllStrategiesMatches = state => state.strategiesMatches;
const getStrategyId = (state, props) => props.strategy.id;

export const getMatchesForStrategy = createSelector(
  [getAllStrategiesMatches, getStrategyId],
  (strategiesMatches, strategyId) => {
    if (_.isNil(strategyId)) return '--';

    return strategiesMatches[strategyId] || '--';
  },
);
