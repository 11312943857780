import _ from 'lodash';
// import { CRITERIA_IDS_BY_FILTER_GROUP } from 'configs/CriteriaConfig';
// import { getCriteriaDefinitions } from 'reducers/selectors/CriteriaSelectors';
// import {
//   // getCriterionDefinition,
//   mapServiceValsToFilterLabels,
//   getUpdatedFilterSelections,
//   getMultiSelectCheckedFilters,
//   makeFilterLabelsToFilterMap,
// } from 'utilities/criteriaUtils';
// import sorting from 'utilities/sorting';

export const ODA_CATEGORIES = {
  RESULTS: 'Results',
  COMPARE: 'Compare',
  RESULTS_CARDS: 'Results Cards',
  START_PAGE: 'Start Page',
  CRITERIA_MENU: 'Criteria Menu',
  CRITERIA: 'Criteria',
  STRATEGIES: 'Strategies',
  PICKS: 'Picks',
  QUICK_CRITERIA: 'Quick Criteria',
  QUICK_CRITERIA_MODAL: 'Quick Criteria Modal',
};

export const CRITERIA_SOURCE = {
  VIEW_ALL: 'viewAll',
  CRITERIA_MENU: 'criteriaMenu',
  QUICK_CRITERIA_MENU: 'quickCriteriaMenu',
  QUICK_CRITERIA_MODAL: 'quickCriteriaModal',
  RESULTS_TABLE: 'resultsTable',
  ROUTER_MIDDLEWARE: 'routerMiddleware',
  SEARCH_CRITERIA: 'searchCriteria',
  PICKS: 'Picks',
};

export const CLEAR_ALL_SOURCE = {
  CRITERIA_MENU: 'Criteria Menu',
  MIDDLEWARE: 'Middleware',
  BREADCRUMBS: 'Breadcrumbs',
  MOBILE_HEADER: 'Mobile Header',
};

export const START_PAGE_VIEW_TAB_SOURCE = {
  PICKS: 'Picks',
  STRATEGIES: 'Strategies',
  QUICK_CRITERIA: 'Quick Criteria',
  NESTED_STRATEGIES_BACK_BUTTON: 'Nested Strategies Back Button',
  NESTED_STRATEGIES_CLOSE_BUTTON: 'Nested Strategies Close Button',
  ANNUITY_OPTIONS: 'Annuity Options',
  WAYS_TO_INVEST: 'Ways to Invest'
};

export const getODACategory = displayType => {
  if (displayType === 'card') {
    return ODA_CATEGORIES.RESULTS_CARDS;
  } else if (displayType === 'compare') {
    return ODA_CATEGORIES.COMPARE;
  } else if (displayType === 'table') {
    return ODA_CATEGORIES.RESULTS;
  }
  console.error('Could not determine correct ODA category because of unhandled display type');
  return null;
};

export const isValidActionTrackingData = ({ category = null, name = null, label = null, value = null }) => {
  // category and name are required fields
  const hasRequiredData = !_.isNil(category) && !_.isNil(name);
  // if we have a value field but not a label field, we're skipping one hierarchical level (category > name > label > value)
  // other possible missing intermediate fields would be category and name but hasRequiredData will check this for us
  const isMissingIntermediateData = !_.isNil(value) && _.isNil(label);
  return hasRequiredData && !isMissingIntermediateData;
};

// const getUpdatedMultiSelectFilterValues = (previousFilterVals, critDef, filterUpdates) => {
//   const minVal = _.first(previousFilterVals);
//   const maxVal = _.last(previousFilterVals);
//   const critId = critDef.id;
//   const filterList = critDef.filterGroups[critId];
//   const filterLabelsToFilterMap = makeFilterLabelsToFilterMap(filterList);
//   const previousCheckedFilters = getMultiSelectCheckedFilters(minVal, maxVal, filterList) || [];
//   const previousCheckedFilterLabels = { [critId]: _.map(previousCheckedFilters, filterObj => filterObj.label) };
//   const currentSelections = getUpdatedFilterSelections(
//     previousCheckedFilterLabels,
//     { [critId]: filterUpdates },
//     critId,
//   );

//   if (_.isEmpty(currentSelections)) return null;

//   let min = _.reduce(
//     currentSelections,
//     (result, label) => {
//       if (_.isNil(result) || _.isNil(filterLabelsToFilterMap[label].min)) return null;
//       const minVal = _.toNumber(filterLabelsToFilterMap[label].min);
//       return Math.min(minVal, result);
//     },
//     filterLabelsToFilterMap[_.first(currentSelections)].min,
//   );
//   let max = _.reduce(
//     currentSelections,
//     (result, label) => {
//       if (_.isNil(result) || _.isNil(filterLabelsToFilterMap[label].max)) return null;
//       const maxVal = _.toNumber(filterLabelsToFilterMap[label].max);
//       return Math.max(maxVal, result);
//     },
//     filterLabelsToFilterMap[_.last(currentSelections)].max,
//   );

//   if (_.isNil(min) || _.isNaN(min)) min = 'LS';
//   if (_.isNil(max) || _.isNaN(max)) max = 'GT';

//   return [min, max];
// };

// const getUpdatedFilterValues = (previousFilterVals, critDef, filterUpdates) => {
//   const filterList = critDef.filterGroups[critDef.id];
//   const filterLabelsToFilterMap = makeFilterLabelsToFilterMap(filterList);
//   const filterUpdatesByServiceVals = _.mapKeys(filterUpdates, (isChecked, label) => {
//     if (!_.isNil(filterLabelsToFilterMap[label].value)) {
//       return filterLabelsToFilterMap[label].value;
//     }
//     return label;
//   });
//   return getUpdatedFilterSelections(
//     { [critDef.id]: previousFilterVals },
//     { [critDef.id]: filterUpdatesByServiceVals },
//     critDef.id,
//   );
// };

// const mapCategoryFiltersToDescription = (critDef, previousFilterVals, filterUpdates) => {
//   const previousFilterLabels = mapServiceValsToFilterLabels(previousFilterVals, critDef, critDef.id);
//   const isFilterSelectedMap = {};
//   // populate isFilterSelectedMap with previous filters
//   _.each(previousFilterLabels, label => {
//     isFilterSelectedMap[label] = true;
//   });
//   // update isFilterSelectedMap with filterUpdates
//   _.each(filterUpdates, (isChecked, label) => {
//     isFilterSelectedMap[label] = isChecked;
//   });

//   const filterList = critDef.filterGroups['category'];
//   const criteriaFilterList = filterList.sort(sorting.alphabetSortByProperty('label'));
//   // make string describing asset class and categories
//   return _.reduce(
//     criteriaFilterList,
//     (results, assetClass) => {
//       // filter out unselected categories and
//       const selectedCategories = _.chain(assetClass.categories)
//         .filter(category => isFilterSelectedMap[category.label])
//         .map(x => x.label)
//         .value();
//       if (selectedCategories.length > 0) {
//         const categoriesDescription = selectedCategories.sort().toString();
//         const delimiter = results === '' ? '' : ' - ';
//         return results + delimiter + 'Asset Class: ' + assetClass.label + ' - Categories: ' + categoriesDescription;
//       }
//       return results;
//     },
//     '',
//   );
// };

// const mapMultiSelectFiltersToDescription = (critDef, previousFilterVals, filterUpdates) => {
//   let filterValues = [...previousFilterVals];
//   if (!_.isEmpty(filterUpdates)) {
//     filterValues = getUpdatedMultiSelectFilterValues(previousFilterVals, critDef, filterUpdates);
//   }
//   if (_.isNil(filterValues) || _.isEmpty(filterValues)) return '';
//   // remove trailing zeroes so that we log equal values in a consistent way
//   filterValues = _.map(filterValues, filterValue => {
//     const floatValue = parseFloat(filterValue);
//     return !_.isNaN(floatValue) ? floatValue : filterValue;
//   });
//   return filterValues.toString();
// };

//Returns a string describing the all the filters of a criterion (currently broken)
// export const mapFiltersToDescription = (state, critId, previousFilterVals, filterUpdates = {}) => {
//   const criteriaDefinitions = getCriteriaDefinitions(state);
//   const critDef = getCriterionDefinition(criteriaDefinitions, critId);
//   // TODO: fix this
//   const dummy = '';
//   if ('' === dummy) return dummy;

//   switch (_.get(critDef, 'criteriaType')) {
//     case 'fundType': {
//       return mapCategoryFiltersToDescription(critDef, previousFilterVals, filterUpdates);
//     }
//     case 'multiSelect': {
//       return mapMultiSelectFiltersToDescription(critDef, previousFilterVals, filterUpdates);
//     }
//     default: {
//       let filterValues = [...previousFilterVals];
//       if (!_.isEmpty(filterUpdates)) {
//         filterValues = getUpdatedFilterValues(previousFilterVals, critDef, filterUpdates);
//       }
//       if (_.isNil(filterValues) || _.isEmpty(filterValues)) return '';
//       return filterValues.sort().toString();
//     }
//   }
// };
//
// export const getStringDescribingAllFilters = (state, criteriaFilters, criteriaFilterUpdates = {}) => {
//   const sortedFilterGroupIds = Object.keys(criteriaFilters).sort();
//   return _.reduce(
//     sortedFilterGroupIds,
//     (result, filterGroupId) => {
//       const filters = criteriaFilters[filterGroupId];
//       const delimiter = result === '' ? '' : ' - ';
//       const filterUpdates = criteriaFilterUpdates[filterGroupId] || {};
//       const critId = CRITERIA_IDS_BY_FILTER_GROUP[filterGroupId];
//       const filterDescription = mapFiltersToDescription(state, critId, filters, filterUpdates);
//       return result + delimiter + filterGroupId + ': ' + filterDescription;
//     },
//     '',
//   );
// };
