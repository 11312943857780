import apisauce from 'apisauce';
import {mockDataInjection} from "../tests/lib/mockDataSetInjection";

const create = baseURL => {
  const api = apisauce.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    timeout: mockDataInjection.enableDataInjection()?3000: 10000,
  });

  const getData = (endpoint, params = {}) => api.get(endpoint, params);
  return {
    getData,
  };
};

export default {
  create,
};
