import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as odaTracking from 'utilities/odaTracking';
import Creators from 'actions/CriteriaActions';
import { getDisplayType } from 'reducers/DisplayTypeReducer';

const ResultsCardItemHeader = ({ data, isExpanded, additionalData, trackFundClick, displayType }) => {
  const performanceOverviewStyling = isExpanded ? 'off' : '';
  const isFidelityFund = !!_.get(data, ['fidelityFund', 0]);
  const isFundPick = !!_.get(data, ['fundPicks', 0]);
  const isSociallyResponsible = !!_.get(data, ['sociallyResponsible', 0]);

  const handleClickFundName = event => {
    const isClickFromFundLink = event.nativeEvent.currentTarget.activeElement.matches('a');
    if (isClickFromFundLink) {
      trackFundClick(displayType, additionalData.id);
    }
  };

  /*********9/28/2023: Life of all funds override: Cleaned newProducts array out to allow regular data to flow through********/
        
  const newProducts = [ ];
  /*********9/28/2023: Life of all funds override: Cleaned newProducts array out to allow regular data to flow through********/
        
  return (
    <div className="results-card-item-header">
      <div className="top-level-data">
        {isFidelityFund ? <div className="fidelity-fund-icon">{data['fidelityFund']}</div> : null}
        {isFundPick ? <div className="fund-picks">{data['fundPicks']}</div> : null}
        {isSociallyResponsible ? <div className="socially-responsible">{data['sociallyResponsible']}</div> : null}
        <div className="name" onClick={handleClickFundName}>
          {data['name']}
        </div>
      </div>
      <table className="overview-table-info">
        <tbody>
          <tr className="overview-table-info-row">
            <td className="label">Morningstar Category</td>
            <td className="label">{data['morningstarCategory']}</td>
          </tr>
          <tr className="overview-table-info-row">
            <td className="label">Morningstar Overall Rating</td>
            <td className="value">
              <div className="morningstar-overall">{data['morningstarRatingOverall']}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className={`performance-overview ${performanceOverviewStyling}`}>
        <div className="ytd">
          <div>YTD (Daily)</div>
          {newProducts.includes(additionalData.id) ? '--' : data['ytdDaily']}
          <div className="soft">
            as of
            {newProducts.includes(additionalData.id) ? '--' : data['dailyPerfAsOfDate']}
          </div>
        </div>
        <div className="one-year">
          <div>1 Yr</div>
          {newProducts.includes(additionalData.id) ? '--' : data['yr1']}
        </div>
        <div className="three-year">
          <div>3 Yr</div>
          {newProducts.includes(additionalData.id) ? '--' : data['yr3']}
        </div>
      </div>
    </div>
  );
};

ResultsCardItemHeader.propTypes = {
  /** An object that represents all the data to display in the result card */
  data: PropTypes.object.isRequired,

  isExpanded: PropTypes.bool.isRequired,

  displayType: PropTypes.string.isRequired,

  /** The stock fund data for this card */
  additionalData: PropTypes.object.isRequired,

  /** Dispatches action to track when fund name is clicked */
  trackFundClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    displayType: getDisplayType(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    trackFundClick: (displayType, ticker) => {
      const trackingData = odaTracking.getFundClickTrackingData(displayType, ticker);
      dispatch(Creators.odaLogAction(trackingData));
    },
  };
};

const ResultsCardItemHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(ResultsCardItemHeader);

export default ResultsCardItemHeaderContainer;
