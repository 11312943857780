import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { sorting } from 'utilities';
import CheckboxFilter from 'components/ActiveCriterion/CheckboxFilter';
import MultiSelectRangeInputs from 'components/ActiveCriterion/MultiSelectRangeInputs';

class MultiSelectInterface extends Component {
  constructor(props) {
    super(props);
    this.filterCount = props.filters.length;
  }

  handleClickCheckbox = (label, isChecked) => {
    // TODO: Need _manageCheckedFilters to return label and isChecked for each checkbox whose checked state is updated.
    this.filterState = this._getFilterState();
    const updatedFilters = this._manageCheckedFilters(label, isChecked);
    this.props.onFiltersUpdated(updatedFilters);
  };

  handleSubmitCustomRange = (min, max) => {
    this.props.onRangeFiltersUpdated([min, max]);
  };

  _manageCheckedFilters = (label, isChecked) => {
    const checkedFilters = this.props.filtersSelected;
    const isFirstCheck = !checkedFilters.length;
    const isCheckAfterCustomRange = this._determineIfCheckAfterCustomRange();

    if (isFirstCheck || isCheckAfterCustomRange) {
      return {
        [label]: true,
      };
    }

    const checkedIndex = this._labelToIndex(label);

    if (isChecked) {
      return this._handleCheckedFilter(checkedIndex);
    } else {
      return this._handleUncheckedFilter(checkedIndex);
    }
  };

  _determineIfCheckAfterCustomRange = () => {
    const { filtersSelected, filters } = this.props;
    const firstFilter = _.first(filtersSelected);
    const lastFilter = _.last(filtersSelected);

    const firstFilterDef = _.find(filters, filter => filter.label === firstFilter);
    const lastFilterDef = _.find(filters, filter => filter.label === lastFilter);

    return _.isNil(firstFilterDef) || _.isNil(lastFilterDef);
  };

  _labelToIndex = label => {
    return this.props.filters.findIndex(filter => filter.label === label);
  };

  _indexToLabel = index => {
    return this.props.filters[index].label;
  };

  _getFilterState = () => {
    return _.map(this.props.filters, filter => {
      return this.props.filtersSelected.includes(filter.label);
    });
  };

  _handleCheckedFilter = checkedIndex => {
    let start = this._findCheckedStartPoint();
    let end = this._findCheckedEndPoint();

    if (checkedIndex < start) {
      end = start - 1;
      start = checkedIndex;
    } else if (checkedIndex > end) {
      start = end + 1;
      end = checkedIndex;
    }
    const updatedFilters = {};
    for (let i = start; i <= end; i++) {
      const label = this._indexToLabel(i);
      updatedFilters[label] = true;
    }

    return updatedFilters;
  };

  _findCheckedStartPoint = () => {
    const checkedFilters = this.filterState;
    for (let i = 0; i < this.filterCount; i++) {
      if (checkedFilters[i] === true) return i;
    }
  };

  _findCheckedEndPoint = () => {
    const checkedFilters = this.filterState;
    for (let i = this.filterCount - 1; i >= 0; i--) {
      if (checkedFilters[i] === true) return i;
    }
  };

  _handleUncheckedFilter = checkedIndex => {
    const activeStart = this._findCheckedStartPoint();
    if (activeStart === checkedIndex) {
      return this._removeStartCheckOnly(checkedIndex);
    } else {
      return this._removeValuesAboveAndIncludingIndex(checkedIndex);
    }
  };

  _removeStartCheckOnly = checkedIndex => {
    return {
      [this._indexToLabel(checkedIndex)]: false,
    };
  };

  _removeValuesAboveAndIncludingIndex = checkedIndex => {
    const checkedFilters = this.filterState;
    const updatedFilters = {};

    for (let i = checkedIndex; i < this.filterCount; i++) {
      if (checkedFilters[i]) {
        updatedFilters[this._indexToLabel(i)] = false;
      }
    }

    return updatedFilters;
  };

  _renderAllFilters = filters => {
    return filters.map((filter, index) => {
      const connectorDisplay = this._getConnectorDisplayClass(filters.length - 1, index);
      const isChecked = this.props.filtersSelected.includes(filter.label);
      return (
        <CheckboxFilter
          key={filter.label}
          index={index}
          isChecked={isChecked}
          connectorDisplay={connectorDisplay}
          filter={filter}
          handleClick={this.handleClickCheckbox}
        />
      );
    });
  };

  _getConnectorDisplayClass = (max, index) => {
    if (index === 0) return 'first';
    if (index === max) return 'last';

    return 'middle';
  };

  _renderRangeInputs = () => {
    const { filtersSelected, filters } = this.props;
    // Sort filters from lowest to highest
    const orderedFilters = [...filters].sort(sorting.multiSelectFilterListSort);

    const firstLabel = _.first(filtersSelected);
    const lastLabel = _.last(filtersSelected);

    let minFilter = _.find(orderedFilters, filter => filter.label === firstLabel || filter.label === lastLabel);
    let maxFilter = _.findLast(orderedFilters, filter => filter.label === firstLabel || filter.label === lastLabel);

    if (_.isNil(minFilter) || _.isNil(maxFilter)) {
      // this case happens when the user adds a custom range input
      minFilter = { min: firstLabel };
      maxFilter = { max: lastLabel };
    }
    return (
      <MultiSelectRangeInputs
        formatType={this.props.formatType}
        minFilter={minFilter}
        maxFilter={maxFilter}
        handleSubmitCustomRange={this.handleSubmitCustomRange}
      />
    );
  };

  render() {
    const { filters } = this.props;

    const allFilters = this._renderAllFilters(filters);
    const rangeInputs = this._renderRangeInputs();
    return (
      <React.Fragment>
        {rangeInputs}
        <ul className="multi-select-interface">{allFilters}</ul>
      </React.Fragment>
    );
  }
}

MultiSelectInterface.propTypes = {
  filters: PropTypes.array,

  filtersSelected: PropTypes.array,

  /** A formatting type used to help display selection text for multi-select criteria */
  formatType: PropTypes.string,

  /** Action specifically for updating range inputs */
  onRangeFiltersUpdated: PropTypes.func,
};

export default MultiSelectInterface;
