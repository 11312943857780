import { createReducer } from 'reduxsauce';
import Types from 'actions/Types';
import _ from 'lodash';
import { CRITERIA_IDS_BY_FILTER_GROUP } from 'configs/CriteriaConfig';

// the initial state of this reducer
export const INITIAL_STATE = [];

export const addCriterion = (state = INITIAL_STATE, action = {}) => {
  return _.uniq([...state, action.critId]).filter(e=>e);
};

export const updateCriteria = (state = INITIAL_STATE, action = {}) => {
  return _.uniq(action.critIdArray).filter(e=>e);
};

export const removeCriterion = (state = INITIAL_STATE, action = {}) => {
  return _.filter(state, critId => critId !== action.critId);
};

export const clearAll = (state = INITIAL_STATE, action = {}) => {
  return INITIAL_STATE;
};

export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
  if (action.payload.location.state && action.payload.location.state.source === 'routerMiddleware') {
    if (action.payload.action !== 'POP'){
      return state
    }    
  }

  if (action.payload.location.pathname === '/') {
    return clearAll(state, action);
  }

  // only "POP" url passes this line. (Browser back and forward , or refresh F5)
  if (action.payload.location.pathname.match("^/results")) {
      const critIds = _.map(action.critOrder, critId => critId==="fundType"? critId : CRITERIA_IDS_BY_FILTER_GROUP[critId]);
      return _.uniq(critIds).filter(e=>e);
  }
  return state;
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.ADD_CRITERION]: addCriterion,
  [Types.UPDATE_CRITERIA]: updateCriteria,
  [Types.LOAD_STRATEGY]: updateCriteria,
  [Types.REMOVE_CRITERION]: removeCriterion,
  [Types.CLEAR_ALL]: clearAll,
  "@@router/LOCATION_CHANGE": routerLocationChanged,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const getActiveCriteria = state => state.activeCriteria;
