import React from 'react';
import PropTypes from 'prop-types';

const QuickCriteriaClassPopupHeader = ({
  displayedSection,
  handleChangeActiveClass,
  handleHoverOverHeader,
  label,
}) => {
  const displayStyling = displayedSection === label ? 'active' : '';
  return (
    <div
      className={`quick-criteria-class-popup-header ${displayStyling}`}
      onMouseEnter={e => {
        e.preventDefault();
        handleHoverOverHeader(label);
      }}
      onClick={e => {
        e.preventDefault();
        handleChangeActiveClass(label);
      }}
    >
      {label}
    </div>
  );
};

QuickCriteriaClassPopupHeader.propTypes = {
  /** The asset class section to display */
  displayedSection: PropTypes.string.isRequired,

  /** Function that changes the active asset class section */
  handleChangeActiveClass: PropTypes.func.isRequired,

  /** Function that determines what section is currently being hovered over */
  handleHoverOverHeader: PropTypes.func.isRequired,

  /** The name of the asset class */
  label: PropTypes.string.isRequired,
};

export default QuickCriteriaClassPopupHeader;
