import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Creators from 'actions/CriteriaActions';
import { APP_DISPLAY_TITLE, ROUTE_FEATURES } from 'configs/AppFeatureConfig';
import { getViewTab } from 'reducers/ViewTabReducer';
import { getIsMobile } from 'reducers/MobileStatusReducer';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getRouterRoute, getRouterResult } from 'reducers/selectors/RouterSelectors';
import { CLEAR_ALL_SOURCE } from 'utilities/odaUtils';

const _renderBreadCrumbs = ({ onClearAll, showFullResultsView, viewTab, displayType, match }) => {
  if (!match.path.match("^/results")) {
    return <React.Fragment> &gt; {APP_DISPLAY_TITLE}</React.Fragment>;
  }
  if (match.path.match("^/results") && displayType !== 'compare') {
    return (
      <React.Fragment>
        &gt; <a onClick={() => onClearAll()}>{APP_DISPLAY_TITLE}</a> &gt; {ROUTE_FEATURES.ROUTES[match.path].pageTitle}
      </React.Fragment>
    );
  }
  if (match.path.match("^/results") && displayType === 'compare') {
    return (
      <React.Fragment>
        &gt; <a onClick={() => onClearAll()}>{APP_DISPLAY_TITLE}</a> &gt;{' '}
        <a onClick={() => showFullResultsView(viewTab)}>{ROUTE_FEATURES.ROUTES[match.path].pageTitle}</a> &gt; Compare Funds
      </React.Fragment>
    );
  }
  return null;
};

const Breadcrumbs = props => {
  const { isMobile } = props;
  const displayStyle = isMobile ? 'off' : '';
  return (
    <div className={`breadcrumbs ${displayStyle}`}>
      <b>
        <a href="https://www.fidelity.com/">Home</a>{' '}
      </b>
      {_renderBreadCrumbs(props)}
    </div>
  );
};

Breadcrumbs.propTypes = {
  isMobile: PropTypes.bool.isRequired,

  /** Redux action to clear all selections and return to the landing page */
  onClearAll: PropTypes.func.isRequired,

  /** Returns you back to the results view from the compare page */
  showFullResultsView: PropTypes.func.isRequired,

  /** The previously active view tab */
  viewTab: PropTypes.string.isRequired,

  /**Current Display type of application: compare,table,card,etc. */
  displayType: PropTypes.string.isRequired,

  /**Current route */
  match: PropTypes.object.isRequired,

  /** The current feature configs for the given router */
  routerResult: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    isMobile: getIsMobile(state),
    viewTab: getViewTab(state),
    displayType: getDisplayType(state),
    route: getRouterRoute(state),
    routerResult: getRouterResult(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClearAll: () => {
      const source = CLEAR_ALL_SOURCE.BREADCRUMBS;
      dispatch(Creators.clearAll(source, true));
    },
    showFullResultsView: viewTab => {
      dispatch(Creators.showFullResultsView(viewTab));
    },
  };
};

const BreadcrumbsContainer = connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);

export default BreadcrumbsContainer;
export { Breadcrumbs };
