import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';
import _ from 'lodash';
import Types from 'actions/Types';

export const INITIAL_STATE = 'start';

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};

const updateDisplayType = (state = INITIAL_STATE, action = {}) => {
  return action.displayType;
};

const showCompareView = (state = INITIAL_STATE, action = {}) => {
  return 'compare';
};

const updateMobileStatus = (state = INITIAL_STATE, action = {}) => {
  return action.displayType;
};

export const showFullResultsView = (state = INITIAL_STATE, action = {}) => {
  return 'table';
};

export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
  if (action.payload.location.pathname === '/') {
    return INITIAL_STATE;
  } else if (
    !_.get(action, ['payload', 'params', 'displayType'])
  ) { 
    return state;
  }
  return action.payload.params.displayType;
};

const ACTION_HANDLERS = {
  [Types.UPDATE_DISPLAY_TYPE]: updateDisplayType,
  [Types.SHOW_COMPARE_VIEW]: showCompareView,
  [Types.SHOW_FULL_RESULTS_VIEW]: showFullResultsView,
  "@@router/LOCATION_CHANGE": routerLocationChanged,
  [Types.SET_DISPLAY_TYPE]: updateDisplayType,
  [Types.UPDATE_MOBILE_STATUS]: updateMobileStatus,
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

// ---- Selectors ----

export const getDisplayType = state => state.displayType;
