import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { criteriaUtils, localStorage } from 'utilities';
import Modal from 'components/Shared/Modal';
import Creators from 'actions/CriteriaActions';
import { connect } from 'react-redux';
import * as odaTracking from 'utilities/odaTracking';
import { CRITERIA_SOURCE } from 'utilities/odaUtils';

class QuickCriteriaResultsModal extends Component {
  constructor() {
    super();
    this.state = {
      quickCriteriaBehavior: 'include',
      hideAlertInFuture: false,
    };
  }
  _handleOutsideClick = e => {
    if (e.target.className.trim() === 'container') {
      e.preventDefault();
      this.props.cancelModal();
    }
  };

  _handleCancel = e => {
    e.preventDefault();
    this.props.cancelModal();
  };

  _handleClickQuickCriteriaBehavior = behavior => {
    this.setState({
      quickCriteriaBehavior: behavior,
    });
  };

  _handleClickHideAlertInFuture = e => {
    this.setState({ hideAlertInFuture: !this.state.hideAlertInFuture });
    e.stopPropagation();
    e.preventDefault();
  };

  _handleClickViewResults = () => {
    const behavior = this.state.quickCriteriaBehavior;

    if (this.state.hideAlertInFuture) {
      localStorage.sessionStore('quickCriteriaBehavior', behavior);
    }

    if (this.state.quickCriteriaBehavior === 'include') {
      const results = this._buildSelectionsIncludingQuickCriteria();
      this._handleSendingSelections(results);
    } else if (this.state.quickCriteriaBehavior === 'exclude') {
      const results = this._buildSelectionsExcludingQuickCriteria();
      this._handleSendingSelections(results);
    }

    this.props.hideModal();
  };

  _handleSendingSelections = ({ critIdArray, filterGroupSelections }) => {
    const source = CRITERIA_SOURCE.QUICK_CRITERIA_MODAL;
    this.props.viewResults(critIdArray, filterGroupSelections, source);

    // Track include selections options
    const include = this.state.quickCriteriaBehavior === 'include';
    const newHideAlertInFuture = this.state.hideAlertInFuture;
    this.props.trackIncludeSelectionsOptions(include, newHideAlertInFuture);

    const critToExpand = _.first(critIdArray);
    this.props.updateExpandedCrit(critToExpand);
  };

  _buildSelectionsIncludingQuickCriteria = () => {
    const quickCriteriaSelections = this.props.parsedSelections;
    const { userSelectedCriteria } = this.props;
    const { source } = userSelectedCriteria;

    let critIdArray = [];
    let { filterGroupSelections } = quickCriteriaSelections;

    const isSelectionFromViewAllModal = source === 'viewAll';
    const isCriterionWithDefaultFilters = _.isObject(userSelectedCriteria) && source !== 'viewAll';

    if (isSelectionFromViewAllModal) {
      critIdArray = criteriaUtils.buildDistinctCritIdArray(
        quickCriteriaSelections.critIdArray,
        userSelectedCriteria.critIdArray,
      );

      const defaultFilterGroupSelections = _.get(userSelectedCriteria, 'filterGroupSelections', {});

      const emptySelections = {};
      userSelectedCriteria.critIdArray.forEach(criterion => (emptySelections[criterion] = []));

      _.defaults(filterGroupSelections, defaultFilterGroupSelections, emptySelections);
    } else if (isCriterionWithDefaultFilters) {
      const defaultFilterGroupSelections = _.get(userSelectedCriteria, 'filterGroupSelections', {});
      const userSelectedCritId = _.get(userSelectedCriteria, 'critId', '');
      critIdArray = criteriaUtils.buildDistinctCritIdArray(quickCriteriaSelections.critIdArray, userSelectedCritId);
      _.defaults(filterGroupSelections, defaultFilterGroupSelections);
    } else {
      critIdArray = criteriaUtils.buildDistinctCritIdArray(quickCriteriaSelections.critIdArray, userSelectedCriteria);
      _.defaults(filterGroupSelections, { [userSelectedCriteria]: [] });
    }
    return { filterGroupSelections, critIdArray };
  };

  _buildSelectionsExcludingQuickCriteria = () => {
    const { userSelectedCriteria } = this.props;
    const { source } = userSelectedCriteria;
    let critIdArray = [];
    let filterGroupSelections = {};

    const isSelectionFromViewAllModal = source === 'viewAll';
    const isCriterionWithDefaultFilters = _.isObject(userSelectedCriteria) && source !== 'viewAll';

    if (isSelectionFromViewAllModal) {
      critIdArray = userSelectedCriteria.critIdArray;
      const defaultFilterGroupSelections = _.get(userSelectedCriteria, 'filterGroupSelections', {});

      const emptySelections = {};
      userSelectedCriteria.critIdArray.forEach(criterion => (emptySelections[criterion] = []));

      _.defaults(filterGroupSelections, defaultFilterGroupSelections, emptySelections);
    } else if (isCriterionWithDefaultFilters) {
      const defaultFilterGroupSelections = _.get(userSelectedCriteria, 'filterGroupSelections', {});
      const userSelectedCritId = _.get(userSelectedCriteria, 'critId', '');
      critIdArray = [userSelectedCritId];
      filterGroupSelections = defaultFilterGroupSelections;
    } else {
      critIdArray = [userSelectedCriteria];
      filterGroupSelections = { [userSelectedCriteria]: [] };
    }

    return { critIdArray, filterGroupSelections };
  };

  render() {
    const { quickCriteriaBehavior, hideAlertInFuture } = this.state;

    return (
      <Modal
        isOpen={this.props.isModalOpen}
        handleCloseModal={this.props.cancelModal}
        classNames="quick-criteria-results-modal"
      >
        <div className="header">You're about to view results</div>
        <div className="main">
          <p>
            By making a selection in the criteria menu, you will be taken to the results table. Since you have already
            made Quick Criteria selections, you can either:
          </p>
          <div className="include-selections">
            <label htmlFor="include-selections" onClick={() => this._handleClickQuickCriteriaBehavior('include')}>
              <input type="radio" id="include-selections" checked={quickCriteriaBehavior === 'include'} readOnly />
              Include my Quick Criteria selections in the results
            </label>
          </div>
          <div className="exclude-selections">
            <label htmlFor="exclude-selections" onClick={() => this._handleClickQuickCriteriaBehavior('exclude')}>
              <input type="radio" id="exclude-selections" checked={quickCriteriaBehavior === 'exclude'} readOnly />
              Do not include my Quick Criteria selections in the results
            </label>
          </div>
        </div>
        <div className="footer">
          <div className="row">
            <div className="col-xs-12 col-sm-6 hide-alert">
              <div onClick={this._handleClickHideAlertInFuture}>
                <label htmlFor="do-not-show-alert">
                  <input type="checkbox" id="do-not-show-alert" checked={hideAlertInFuture} />
                  Do not show this alert for the rest of my session
                </label>
              </div>
            </div>
            <div className="col-xs-12 col-sm-2 cancel">
              <button className="as-link" onClick={this._handleCancel}>
                Cancel
              </button>
            </div>
            <div className="col-xs-12 col-sm-4 view-results">
              <button onClick={() => this._handleClickViewResults()}>View Results</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

QuickCriteriaResultsModal.propTypes = {
  /** Redux action to cancel the modal. This does not apply any selections */
  cancelModal: PropTypes.func.isRequired,

  /** Function to hide the quick results modal */
  hideModal: PropTypes.func.isRequired,

  /** Determines whether the quick results modal is open */
  isModalOpen: PropTypes.bool.isRequired,

  /** A parsed object containing the necessary parameters that can be used to update Redux store using the viewResults prop */
  parsedSelections: PropTypes.object.isRequired,

  /** A selection made by the user from the full criteria menu rail when the quick criteria menu has selections */
  userSelectedCriteria: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),

  /** Redux action to update state with selections from quick criteria */
  viewResults: PropTypes.func.isRequired,

  /** Updates the currently expanded criterion in the criteria menu */
  updateExpandedCrit: PropTypes.func.isRequired,

  /** Dispatches action to track include selection options */
  trackIncludeSelectionsOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    trackIncludeSelectionsOptions: (include, dontShow) => {
      const criteriaOptionsTrackingData = odaTracking.getQuickCriteriaModalCriteriaOptionsTrackingData(include);
      const modalOptionsTrackingData = odaTracking.getQuickCriteriaModalModalOptionsTrackingData(dontShow);
      dispatch(Creators.odaLogAction(criteriaOptionsTrackingData));
      dispatch(Creators.odaLogAction(modalOptionsTrackingData));
    },
  };
};

const QuickCriteriaResultsModalContainer = connect(mapStateToProps, mapDispatchToProps)(QuickCriteriaResultsModal);

export default QuickCriteriaResultsModalContainer;
