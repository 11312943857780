import { createSelector } from 'reselect';
import _ from 'lodash';
import { CRITERIA_DICTIONARY, CRITERIA_BY_ID, ALL_CRITERIA } from 'configs/CriteriaConfig';
import { traverseNestedCriteria } from 'utilities/criteriaUtils';
import { getFundFamilyFilters } from 'reducers/FundFamilyFiltersReducer';
import { getFundTypeFilters } from 'reducers/FundTypeFiltersReducer';
import { getActiveCriteria } from 'reducers/ActiveCriteriaReducer';

const getCriteriaGroupId = (state, props) => props.id;

export const getCriteriaDefinitions = createSelector(
  [getFundTypeFilters, getFundFamilyFilters],
  (fundTypeFilters, fundFamilyFilters) => {
    const criteriaDeepClone = _.cloneDeepWith(ALL_CRITERIA);
    const categoryCriteria = criteriaDeepClone.fundType || criteriaDeepClone.category;
    const fundFamilyCriteria = _.get(criteriaDeepClone, 'fundFamily');
    if (_.get(fundTypeFilters, ['length']) && !_.isNil(categoryCriteria)) {
      const categoryFilters = _.flatMap(fundTypeFilters, 'categories');
      categoryCriteria.filterGroups.assetClass = fundTypeFilters;
      categoryCriteria.filterGroups.category = categoryFilters;
    }
    if (_.get(fundFamilyFilters, ['length']) && !_.isNil(fundFamilyCriteria)) {
      const id = fundFamilyCriteria.id;
      fundFamilyCriteria.filterGroups[id] = fundFamilyFilters;
    }
    return criteriaDeepClone;
  },
);

export const getActiveCriteriaList = createSelector(
  [getCriteriaDefinitions, getActiveCriteria],
  (criteria, activeCriteria) => {
    const activeCrit = _.chain(activeCriteria)
      .map(critId => criteria[critId])
      .filter(crit => !_.isNil(crit))
      .value();
    return activeCrit;
  },
);

export const getActiveCriteriaMap = createSelector([getActiveCriteriaList], activeCriteriaList => {
  return _.transform(activeCriteriaList, (result, activeCriteria) => (result[activeCriteria.id] = true), {});
});

export const getActiveCriteriaDictionary = createSelector([getActiveCriteria], activeCriteria => {
  return _.pickBy(CRITERIA_DICTIONARY, id => activeCriteria.includes(id));
});

export const getCriteriaGroupInactive = createSelector(
  [getCriteriaGroupId, getActiveCriteria],
  (groupId, activeCriteriaIds) => {
    const groupDeepClone = _.cloneDeepWith(_.get(CRITERIA_BY_ID, groupId, {}));
    let inactiveCriteriaCount = 0;

    // traverse nested criteria with current group as root and remove active criteria from our deep copy
    traverseNestedCriteria(
      groupDeepClone.criteriaMenuItemList,
      (criterion, parents) => {
        const directParent = parents[parents.length - 1];

        if (activeCriteriaIds.includes(criterion.id)) {
          // remove active criterion
          // note we are assigning a new array instead of mutating it,
          // that way traverseNestedCriteria will keep traversing the old array
          directParent.criteriaMenuItemList = _.filter(
            directParent.criteriaMenuItemList,
            crit => crit.id !== criterion.id,
          );
        } else {
          inactiveCriteriaCount++;
        }
      },
      [groupDeepClone],
    );

    return {
      id: groupId,
      inactiveCriteriaCount: inactiveCriteriaCount,
      inactiveCriteria: groupDeepClone.criteriaMenuItemList,
    };
  },
);
