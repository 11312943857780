import _ from 'lodash';
import { createSelector } from 'reselect';
import { HELP_ME_READ_THIS_LINK } from 'configs/AppFeatureConfig';
import { getViewTab } from 'reducers/ViewTabReducer';
import { getCurrentViewTabsByShortName } from 'reducers/selectors/ViewTabSelectors';

export const getHelpMeReadThisLinkFullUrl = createSelector(
  [getViewTab, getCurrentViewTabsByShortName],
  (viewTab, currentViewTabsByShortName) => {
    const viewTabId = _.get(currentViewTabsByShortName, [viewTab, 'helpMeReadThisId']);
    return HELP_ME_READ_THIS_LINK + '#' + viewTabId;
  },
);
