import _ from 'lodash';
const sorting = {};

sorting.alphabetSort = (a, b) => {
  var nameA = a.toUpperCase();
  var nameB = b.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

sorting.alphabetSortByProperty = property => {
  return (a, b) => {
    const aProp = _.get(a, property, '');
    const bProp = _.get(b, property, '');
    return sorting.alphabetSort(aProp, bProp);
  };
};

sorting.multiSelectFilterListSort = (a, b) => {
  // nil min values indicate no lower bound so it goes first
  if (_.isNil(a.min)) return -1;
  if (_.isNil(b.min)) return 1;

  var minA = _.toNumber(a.min);
  var minB = _.toNumber(b.min);

  // non-number min values (E.g min='LS') indicate no lower bound so it goes first
  if (_.isNaN(minA)) return -1;
  if (_.isNaN(minB)) return 1;

  if (minA < minB) return -1;
  if (minA > minB) return 1;
  return 0;
};

export default sorting;
