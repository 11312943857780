import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import ViewAllModal from 'components/ViewAll/ViewAllModal';
import _ from 'lodash';
import { ALL_CRITERIA, CRITERIA_IDS_BY_FILTER_GROUP } from 'configs/CriteriaConfig';
import { getDefaultFilterValues } from 'utilities/criteriaUtils';

class ViewAll extends Component {
  constructor() {
    super();
    this.state = {
      isModalOpen: false,
    };
  }

  toggleModal = () => {
    const newIsModalOpen = !this.state.isModalOpen;
    this.setState({
      isModalOpen: newIsModalOpen,
    });

    if (newIsModalOpen) {
      this.props.trackOpenViewAll();
    }
  };

  handleOutsideClick = e => {
    if (e.target.className === 'view-all-modal-container') {
      this.toggleModal();
    }
  };

  _assembleFilterSelections = selectedCriteria => {
    const filterGroupSelections = { ...this.props.currentCriteriaSelections };

    const selectedCriteriaFilterSelections = {};

    _.each(selectedCriteria, criterion => {
      const criterionConfig = _.get(ALL_CRITERIA, criterion, {});
      const { defaultFilterGroupSelections } = criterionConfig;
      if (!_.isNil(defaultFilterGroupSelections)) {
        const defaultSelection = getDefaultFilterValues(ALL_CRITERIA, CRITERIA_IDS_BY_FILTER_GROUP, criterion);
        _.assign(selectedCriteriaFilterSelections, defaultSelection);
      } else if (filterGroupSelections[criterion]) {
        selectedCriteriaFilterSelections[criterion] = filterGroupSelections[criterion];
      } else {
        selectedCriteriaFilterSelections[criterion] = [];
      }
    });

    return selectedCriteriaFilterSelections;
  };

  updateActiveCriteria = selectedCriteria => {
    const filterGroupSelections = this._assembleFilterSelections(selectedCriteria);
    this.props.updateActiveCriteria(selectedCriteria, filterGroupSelections);
    const viewAllCritAdded = _.difference(selectedCriteria, this.props.activeCriteria);

    // Expand the first criteria added via the view all modal
    if (viewAllCritAdded.length) {
      const critToExpand = _.first(viewAllCritAdded);
      this.props.updateExpandedCrit(critToExpand);
    }
  };

  render() {
    return (
      <div className="view-all">
        <ViewAllModal
          activeCriteria={this.props.activeCriteria}
          toggleModal={this.toggleModal}
          updateActiveCriteria={this.updateActiveCriteria}
          handleOutsideClick={this.handleOutsideClick}
          isModalOpen={this.state.isModalOpen}
        />
        <div className="view-all-open-button">
          <span
            onKeyDown={e => {
              if (e.key === 'Enter') {
                this.toggleModal();
              }
            }}
            tabIndex={0}
            onClick={this.toggleModal}
          >
            View All
          </span>
        </div>
      </div>
    );
  }
}

ViewAll.propTypes = {
  /** Array of all currently selected criteria's ids */
  activeCriteria: PropTypes.array,

  /** All the currently selected criteria filters */
  currentCriteriaSelections: PropTypes.object,

  /** This updates redux state with selections from view all menu */
  updateActiveCriteria: PropTypes.func,

  /** Updates the expanded criteria */
  updateExpandedCrit: PropTypes.func,

  /** Dispatches action to track opening the view all modal */
  trackOpenViewAll: PropTypes.func,
};

export default ViewAll;
