import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions(
  {
    addCriterion: ['critId', 'filterGroupSelections', 'source'],
    removeCriterion: ['critId', 'source'],
    updateCriteria: ['critIdArray', 'filterGroupSelections', 'source'],
    updateExpandedCrit: ['expandedCritId'],
    updateCriteriaMenuExpanded: ['isCriteriaMenuExpanded'],
    updateFilters: ['filterGroupId', 'filterUpdates'],
    updatePricingModel: ['productCode', 'redirectToResults'],
    updateRangeInputs: ['filterGroupId', 'filterGroupSelections'],
    updateViewTab: ['viewTabLabel', 'viewTabShortName'],
    sortResults: ['column', 'direction'],
    fundTypeApi: null,
    fundDatavalueDisclosureApi: null,
    fundApplicationDisclosureApi: null,
    disclosureDisclosuresApi: null,
    disclosureLabelSuppressionsApi: null,
    clearAll: ['source', 'goToStart'],
    updateResultsPageNum: ['pageNum'],
    quickCriteriaCancelModal: null,
    quickCriteriaDisplayModal: ['userSelections'],
    quickCriteriaHideModal: null,
    quickCriteriaUpdateActiveFundType: ['fundType', 'selections'],
    quickCriteriaUpdateAssetClass: ['assetClass', 'categories'],
    quickCriteriaUpdateSelections: ['critId', 'selections'],
    quickCriteriaUpdateMadLibsSelections: ['selections'],
    quickCriteriaUpdateMadLibsAdditionalCriteria: ['additionalCriteria'],
    quickCriteriaMatchesApi: ['searchParams'],
    quickCriteriaInitialMatchesApi: null,
    updateMobileStatus: ['isMobile'],
    updateDisplayType: ['displayType'], // should be used when components need to dispatch an action to change the display type
    setDisplayType: ['displayType'], // only ever invoked in middleware to set the appropriate display type based on mobile vs desktop experience
    showCompareView: ['fundsSelected'],
    showFullResultsView: ['viewTabShortName'],
    addSymbol: ['symbol'],
    addSymbolSuccess: ['symbol'],
    updateFundsSelected: ['fundsSelected'],
    updateFundsSelectedError: ['errorType', 'errorData'],
    viewNestedStrategy: ['strategyId'],
    loadStrategy: ['critIdArray', 'filterGroupSelections', 'strategyId', 'viewTabShortName', 'sortParams'],
    changeStartPageViewTab: ['viewTab', 'source'],
    strategyMatchesApi: ['strategyId', 'searchParams'],
    pricingModelMatchesApi: ['policyCode'],
    updateResultToggleGroup: ['resultGroup', 'selection'],
    odaInitializeTracking: null,
    odaLogAction: ['data'],
    trackAdobeAnalytics: ['data'],
    fundPicksUpdateSelectedCategory: ['category', 'groups'],
    fundPicksUpdateSelectedGroups: ['group', 'checked', 'allGroupsChecked'],
    fundPicksFromFidelityInitialMatchesApi: null,
    fundPicksFromFidelityMatchesApi: ['searchParams'],
    fundPicksFromFidelityLoadingApi: ['searchParams'],
    downloadResultsApi: ['filters'],
  },
  {},
); // options - the 2nd parameter is optional

export const CriteriaActionTypes = Types;
export default Creators;
