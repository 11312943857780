import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Creators from 'actions/CriteriaActions';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getMatchesForPolicyCode } from 'reducers/PricingModelMatchesReducer';
import { getPricingModelMatchesLoadingForPolicyCode } from 'reducers/LoadingReducer';
import { getPricingModelMatchesErrorForPolicyCode } from 'reducers/ErrorReducer';

class AnnuitiesPricingModelSelection extends Component {
  constructor(props) {
    const { isMatchCountLoading, matchCount, matchCountError, pricingModel } = props;
    if (!isMatchCountLoading && _.isNil(matchCount) && _.isNil(matchCountError)) {
      props.pricingModelMatchesApi(pricingModel.policyCode);
    }
    super(props);
  }
  _renderStatus = pricingModel => {
    const status = pricingModel.policyStatus || '--';
    const indicator = status.toUpperCase();
    const isOpen = status === 'Open';
    const statusDescription = isOpen ? 'Open to new customers' : 'Closed to new customers';
    return (
      <span className="status-count">
        <span className={`${indicator.toLowerCase()} indicator`}>{indicator}</span>
        {statusDescription}
      </span>
    );
  };
  render() {
    const { pricingModel, matchCount, onClick } = this.props;
    const isNYLP = pricingModel.policyCode === "PV190" || pricingModel.policyCode === "PV120";
    const isNPIA = pricingModel.policyCode === "PI220"
    const link = pricingModel.policyCode === "PV190" 
        ? "https://fundresearch.fidelity.com/annuities/summary/FPVAC" 
        : pricingModel.policyCode === "PV120" 
          ? "https://fundresearch.fidelity.com/annuities/summary/FPVBC"
          : "https://fundresearch.fidelity.com/annuities/summary/FONWC"

    return (
      <a className="pricing-model-selection" onClick={!isNYLP && !isNPIA ? onClick : null} href = {isNYLP || isNPIA ? link : null}>
        <div className="pricing-model-wrap">
          <span className="header" dangerouslySetInnerHTML={{ __html: pricingModel.policyShortName }} />
          <h2 className="subheader" dangerouslySetInnerHTML={{ __html: pricingModel.policySubHeader }} />
          <div className="status-and-fund-count">
            {this._renderStatus(pricingModel)}
            <span className="fund-count">
              <span className="fund-count-number">{_.defaultTo(matchCount, '--')}</span> funds
            </span>
          </div>
        </div>
      </a>
    );
  }
}

AnnuitiesPricingModelSelection.propTypes = {
  matchCount: PropTypes.number,
  isMatchCountLoading: PropTypes.bool.isRequired,
  pricingModel: PropTypes.object.isRequired,
  matchCountError: PropTypes.object,
  pricingModelMatchesApi: PropTypes.func.isRequired,
};
const mapStateToProps = (state, props) => {
  return {
    matchCount: getMatchesForPolicyCode(state, props),
    isMatchCountLoading: getPricingModelMatchesLoadingForPolicyCode(state, props),
    matchCountError: getPricingModelMatchesErrorForPolicyCode(state, props),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    pricingModelMatchesApi: productCode => {
      dispatch(Creators.pricingModelMatchesApi(productCode));
    },
  };
};
const AnnuitiesPricingModelSelectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnnuitiesPricingModelSelection);

export default AnnuitiesPricingModelSelectionContainer;
