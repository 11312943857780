import { createReducer } from 'reduxsauce';
import Types from '../actions/Types';
import { createSelector } from 'reselect';
import { getOverallTotalMatches } from 'reducers/TotalMatchesReducer';

// the initial state of this reducer
export const INITIAL_STATE = 1;

export const updateCurrentPage = (state = INITIAL_STATE, action = {}) => {
  const currentPageNum = action.pageNum > 0 ? action.pageNum : INITIAL_STATE;
  return currentPageNum;
};

export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
  if (action.payload.location.state && action.payload.location.state.source === 'routerMiddleware') {
    return state;
  }
  if (!action.payload || !action.payload.params || !action.payload.params.pageNum) {
    return state;
  }
  const pageNumAsNum = parseInt(action.payload.params.pageNum, 10); //TODO: Make sure pareseInt is successful
  return pageNumAsNum;
};

export const clearAll = (state = INITIAL_STATE, action = {}) => {
  return INITIAL_STATE;
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.UPDATE_RESULTS_PAGE_NUM]: updateCurrentPage,
  [Types.ADD_CRITERION]: clearAll,
  [Types.UPDATE_FILTERS]: clearAll,
  [Types.UPDATE_RANGE_INPUTS]: clearAll,
  [Types.UPDATE_CRITERIA]: clearAll,
  [Types.LOAD_STRATEGY]: clearAll,
  [Types.SORT_RESULTS]: clearAll,
  [Types.REMOVE_CRITERION]: clearAll,
  [Types.CLEAR_ALL]: clearAll,
  "@@router/LOCATION_CHANGE": routerLocationChanged,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const getCurrentResultsPageNum = state => state.resultsPageNum;

export const getTotalNumPages = createSelector([getOverallTotalMatches], overallTotalMatches => {
  return Math.ceil(overallTotalMatches / 100);
});
