import _ from 'lodash';
const popup = {};

popup.openPopup = (url, windowName, width = 470, height = 490) => {
  const windowFeatures = {
    left: (window.innerWidth - width) / 2,
    top: (window.innerHeight - height) / 2,
    width: width,
    height: height,
    toolbar: 'no',
    scrollbars: 'yes',
    resizable: 'yes',
  };
  const windowFeaturesString = _.map(windowFeatures, (val, key) => {
    return `${key}=${val}`;
  }).join(',');
  window.open(url, windowName, windowFeaturesString);
};

export default popup;
