import _ from 'lodash';

export const getParamNumber = (parameters, id) => {
  if (!parameters.hasOwnProperty(id) ) return 0;
  if (parameters[id]==='N') return 1;
  if (parameters[id]==='Y') return 2;
  return 0;
};

export const makeNTFParamsFromNtfTransFee = (original_parameters) => {
  let parameters = _.cloneDeep(original_parameters);
  if (parameters){
    // TotalCount API
    if (parameters.hasOwnProperty('ntf') || parameters.hasOwnProperty('transactionFeeIndicator')){
      const ntf = getParamNumber(parameters, 'ntf');
      const transactionFeeIndicator = getParamNumber(parameters, 'transactionFeeIndicator');
      if (ntf === 2 && transactionFeeIndicator === 2){
        delete parameters['ntf'];
        delete parameters['transactionFeeIndicator'];
      } 
    }
    //Count API: filterCriteriaName: "transactionFeeIndicator"
    if (parameters.hasOwnProperty('filterCriteriaName') && parameters['filterCriteriaName']==='transactionFeeIndicator' ){
      delete parameters['ntf'];
    } else if (parameters.hasOwnProperty('filterCriteriaName') && parameters['filterCriteriaName']==='ntf' ){
      delete parameters['transactionFeeIndicator'];
    } else if (parameters.hasOwnProperty('filterCriteriaName') && parameters['filterCriteriaName']==='sustainableInvestment' ){
      parameters['filterCriteriaName'] = "esgCodes";
    } else if (parameters.hasOwnProperty('filterCriteriaName') && parameters['filterCriteriaName']==='employsExclusions' ){
      parameters['filterCriteriaName'] = "esgCodes";
    }
  }
  return parameters;
}


const sagaUtils = {
  getParamNumber,
  makeNTFParamsFromNtfTransFee
};

export default sagaUtils;
