import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';
import { createSelector } from 'reselect';
import _ from 'lodash';
import Types from 'actions/Types';
import { RESULT_FEATURES } from 'configs/AppFeatureConfig';

export const INITIAL_STATE = {
  fundSearch: false,
  fidelityFundSearch: false,
  critFilterMatches: [],
  critTotalMatches: [],
  strategyMatches: [],
  pricingModelMatches: [],
  quickCriteriaMatches: false,
  fundPicksMatches:false,
  downloadResults: false,
  fundFamily: false,
  fundType: false,
  policyInfo: false,
};

const makeLoadingStateChanger = (type, isLoading) => {
  return (state = INITIAL_STATE, action = {}) => {
    return { ...state, [type]: isLoading };
  };
};

const finishDownloadResults = makeLoadingStateChanger('downloadResults', false);
const loadingDownloadResults = makeLoadingStateChanger('downloadResults', true);

const finishFundSearch = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fundSearch: false };
};

const finishFidelityFundSearch = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fidelityFundSearch: false };
};

const loadingFundSearch = (state = INITIAL_STATE, action = {}) => {
  if (RESULT_FEATURES.HAS_FIDELITY_FUNDS_TABLE) {
    return { ...state, fundSearch: true, fidelityFundSearch: true };
  } else {
    return { ...state, fundSearch: true };
  }
};

const finishQuickCriteriaMatches = makeLoadingStateChanger('quickCriteriaMatches', false);
const loadingQuickCriteriaMatches = makeLoadingStateChanger('quickCriteriaMatches', true);

const finishFundPicksMatches = makeLoadingStateChanger('fundPicksMatches', false);
const loadingFundPicksMatches = makeLoadingStateChanger('fundPicksMatches', true);

const criteriaFilterMatches = (state = INITIAL_STATE, action = {}) => {
  const critFilterMatches = [...state.critFilterMatches, action.currentCrit];
  return { ...state, critFilterMatches };
};

const finishCriteriaFilterMatches = (state = INITIAL_STATE, action = {}) => {
  const critFilterMatches = _.filter(state.critFilterMatches, critId => {
    return critId !== action.critId;
  });

  return { ...state, critFilterMatches };
};

const criteriaTotalMatches = (state = INITIAL_STATE, action = {}) => {
  const critTotalMatches = [...state.critTotalMatches, action.currentCrit];
  return { ...state, critTotalMatches };
};
const finishCriteriaTotalMatches = (state = INITIAL_STATE, action = {}) => {
  const critTotalMatches = _.filter(state.critTotalMatches, critId => {
    return critId !== action.critId;
  });
  return { ...state, critTotalMatches };
};

const strategyMatches = (state = INITIAL_STATE, action = {}) => {
  const strategyMatches = [...state.strategyMatches, action.strategyId];

  return { ...state, strategyMatches };
};

const finishStrategyMatches = (state = INITIAL_STATE, action = {}) => {
  const strategyMatches = _.filter(state.strategyMatches, strategyId => {
    return strategyId !== action.strategyId;
  });

  return { ...state, strategyMatches };
};

const pricingModelMatches = (state = INITIAL_STATE, action = {}) => {
  const pricingModelMatches = [...state.pricingModelMatches, action.policyCode];

  return { ...state, pricingModelMatches };
};

const finishPricingModelMatches = (state = INITIAL_STATE, action = {}) => {
  const pricingModelMatches = _.filter(state.pricingModelMatches, policyCode => {
    return policyCode !== action.policyCode;
  });

  return { ...state, pricingModelMatches };
};

const loadingFundClassification = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fundFamily: true, fundType: true, policyInfo: true };
};

const finishFundFamily = makeLoadingStateChanger('fundFamily', false);
const loadingFundFamily = makeLoadingStateChanger('fundFamily', true);

const finishFundType = makeLoadingStateChanger('fundType', false);
const loadingFundType = makeLoadingStateChanger('fundType', true);

const finishPolicyInfo = makeLoadingStateChanger('policyInfo', false);
const loadingPolicyInfo = makeLoadingStateChanger('policyInfo', true);

const finishEsgGroups = makeLoadingStateChanger('esgGroups', false);
const loadingEsgGroups = makeLoadingStateChanger('esgGroups', true);

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};

const ACTION_HANDLERS = {
  [Types.FUND_SEARCH_API]: loadingFundSearch,
  [Types.FUND_SEARCH_API_SUCCESS]: finishFundSearch,
  [Types.FUND_SEARCH_API_ERROR]: finishFundSearch,
  [Types.FIDELITY_FUND_SEARCH_API_SUCCESS]: finishFidelityFundSearch,
  [Types.FIDELITY_FUND_SEARCH_API_ERROR]: finishFidelityFundSearch,

  [Types.QUICK_CRITERIA_MATCHES_API]: loadingQuickCriteriaMatches,
  [Types.QUICK_CRITERIA_MATCHES_API_SUCCESS]: finishQuickCriteriaMatches,
  [Types.QUICK_CRITERIA_MATCHES_API_ERROR]: finishQuickCriteriaMatches,

  [Types.FUND_PICKS_FROM_FIDELITY_MATCHES_API]: loadingFundPicksMatches,
  [Types.FUND_PICKS_FROM_FIDELITY_MATCHES_API_SUCCESS]: finishFundPicksMatches,
  [Types.FUND_PICKS_FROM_FIDELITY_MATCHES_API_ERROR]: finishFundPicksMatches,

  [Types.CRITERIA_MATCHES_API]: criteriaFilterMatches,
  [Types.CRITERIA_MATCHES_API_SUCCESS]: finishCriteriaFilterMatches,
  [Types.CRITERIA_MATCHES_API_ERROR]: finishCriteriaFilterMatches,

  [Types.TOTAL_MATCHES_API]: criteriaTotalMatches,
  [Types.TOTAL_MATCHES_API_SUCCESS]: finishCriteriaTotalMatches,
  [Types.TOTAL_MATCHES_API_ERROR]: finishCriteriaTotalMatches,

  [Types.STRATEGY_MATCHES_API]: strategyMatches,
  [Types.STRATEGY_MATCHES_API_SUCCESS]: finishStrategyMatches,
  [Types.STRATEGY_MATCHES_API_ERROR]: finishStrategyMatches,

  [Types.PRICING_MODEL_MATCHES_API]: pricingModelMatches,
  [Types.PRICING_MODEL_MATCHES_API_SUCCESS]: finishPricingModelMatches,
  [Types.PRICING_MODEL_MATCHES_API_ERROR]: finishPricingModelMatches,

  [Types.DOWNLOAD_RESULTS_API]: loadingDownloadResults,
  [Types.DOWNLOAD_RESULTS_API_SUCCESS]: finishDownloadResults,
  [Types.DOWNLOAD_RESULTS_API_ERROR]: finishDownloadResults,

  [Types.FUND_CLASSIFICATION_API]: loadingFundClassification,

  [Types.FUNDFAMILY_API]: loadingFundFamily,
  [Types.FUNDFAMILY_API_SUCCESS]: finishFundFamily,
  [Types.FUNDFAMILY_API_ERROR]: finishFundFamily,

  [Types.FUND_TYPE_API]: loadingFundType,
  [Types.FUND_TYPE_API_SUCCESS]: finishFundType,
  [Types.FUND_TYPE_API_ERROR]: finishFundType,

  [Types.POLICY_INFO_API]: loadingPolicyInfo,
  [Types.POLICY_INFO_API_SUCCESS]: finishPolicyInfo,
  [Types.POLICY_INFO_API_ERROR]: finishPolicyInfo,

  [Types.ESGGROUPS_CLASSIFICATION_API]: loadingEsgGroups,
  [Types.ESGGROUPS_CLASSIFICATION_API_SUCCESS]: finishEsgGroups,
  [Types.ESGGROUPS_CLASSIFICATION_API_ERROR]: finishEsgGroups,

  [ReduxSauceTypes.DEFAULT]: defaultHandler,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

export const getLoading = state => state.loading;
export const getCritFiltersLoading = state => state.loading.critFilterMatches;
export const getCritTotalLoading = state => state.loading.critTotalMatches;
export const getStrategyMatchesLoading = state => state.loading.strategyMatches;
export const getPricingModelMatchesLoading = state => state.loading.pricingModelMatches;
const getCritId = (state, props) => props.criterion.id;
const getStrategyId = (state, props) => props.strategy.id;
const getPolicyCode = (state, props) => props.pricingModel.policyCode;

export const getResultsLoading = createSelector([getLoading], loading => {
  return loading.fundSearch;
});

export const getFidelityResultsLoading = createSelector([getLoading], loading => {
  return loading.fidelityFundSearch;
});

export const getDownloadResultsLoading = createSelector([getLoading], loading => {
  return loading.downloadResults;
});

export const getSomeResultsLoading = createSelector(
  [getResultsLoading, getFidelityResultsLoading, getDownloadResultsLoading],
  (resultsLoading, fidelityResultsLoading, downloadResultsLoading) => {
    if (RESULT_FEATURES.HAS_FIDELITY_FUNDS_TABLE) {
      return downloadResultsLoading || resultsLoading || fidelityResultsLoading;
    } else {
      return downloadResultsLoading || resultsLoading;
    }
  },
);

export const getQuickCriteriaMatchesLoading = createSelector([getLoading], loading => {
  return loading.quickCriteriaMatches;
});

export const getFundPicksMatchesLoading = createSelector([getLoading], loading => {
  return loading.fundPicksMatches;
});

export const getMatchesLoading = createSelector(
  [getCritFiltersLoading, getCritTotalLoading, getCritId],
  (critFiltersLoading, critTotalLoading, critId) => {
    const isLoadingTotalMatches = _.includes(critTotalLoading, critId);
    const isLoadingFilterMatches = _.includes(critFiltersLoading, critId);
    return isLoadingTotalMatches && isLoadingFilterMatches;
  },
);

export const getStrategyMatchesLoadingForStrategy = createSelector(
  [getStrategyMatchesLoading, getStrategyId],
  (strategyMatchesLoading, strategyId) => {
    return _.includes(strategyMatchesLoading, strategyId);
  },
);

export const getPricingModelMatchesLoadingForPolicyCode = createSelector(
  [getPricingModelMatchesLoading, getPolicyCode],
  (pricingModelMatchesLoading, policyCode) => {
    return _.includes(pricingModelMatchesLoading, policyCode);
  },
);
