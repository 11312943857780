import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Popover from './Popover';
import _ from 'lodash';

class GlossaryDefinitionPopover extends Component {
  constructor(props) {
    super(props);
    this.bodyStyle = {};
    this.elmPopover = React.createRef();
    this.state = { popoverShow: false, resized: 0 };

    this.showPopover = () => {
      if (this.state.popoverShow) {
        this.setState({
          ...this.state,
          popoverShow: false,
        });
        this.clearScrollInterval();
      } else {
        this.setState({
          ...this.state,
          popoverShow: true,
        });
      }
    };

    GlossaryDefinitionPopover.defaultProps = {
      ariaLabel: "Popup"
    }

    this.renderBody = () => {
      const { glossary } = this.props;
      if (GlossaryDefinitionPopover.glossaryDefinition[glossary]) {
        const text = GlossaryDefinitionPopover.glossaryDefinition[glossary];
        return <div style={this.bodyStyle} dangerouslySetInnerHTML={{ __html: text }} />;
      } else {
        const text = 'Definition not available';
        return <div style={this.bodyStyle} dangerouslySetInnerHTML={{ __html: text }} />;
      }
    };

    this.renderPopover = () => {
      if (this.state.popoverShow) {
        this.setupScrollInterval();
        const parent = document.getElementById('root');
        const textBody = this.renderBody();
        const maxwidth = window.screen.width - 4;
        const style = { maxWidth: (maxwidth > 600 ? 600 : maxwidth) + 'px' };

        return ReactDOM.createPortal(
          <Popover
            id="glossary-definition-detail"
            title={this.props.glossary}
            body={textBody}
            referenceElement={this.elmPopover.current}
            placement={this.props.placement || 'bottom'}
            closePopover={this.showPopover}
            style={style}
          />,
          parent,
        );
      } else {
        this.clearScrollInterval();
        return null;
      }
    };

    // check if the popupover falls in the scroll window, if not, it will be closed.
    this.interval1 = null;
    this.clearScrollInterval = () => {
      if (this.interval1 != null) {
        clearInterval(this.interval1);
        this.interval1 = null;
      }
    };
    this.setupScrollInterval = () => {
      this.clearScrollInterval();
      let containerClassName =
        this.props.scrolled && this.props.scrolled.container ? this.props.scrolled.container : null;
      let targetClassName = this.props.scrolled && this.props.scrolled.targetDiv ? this.props.scrolled.targetDiv : null;
      let targetDivHeight =
        this.props.scrolled && this.props.scrolled.targetDivHeight ? this.props.scrolled.targetDivHeight : null;
      if (containerClassName && targetClassName) {
        this.interval1 = setInterval(() => {
          let ref = this.elmPopover.current;
          let scrollContainer = ref ? ref.closest('.' + containerClassName) : null;
          let targetDiv = ref ? ref.closest('.' + targetClassName) : null;
          if (scrollContainer && targetDiv) {
            // range : container scrollTop  <= assetClassWrap.offsetTop <=  (container scrollTop + container Height)
            let low = scrollContainer.scrollTop;
            let high = scrollContainer.scrollTop + scrollContainer.clientHeight - 6;
            let containerOffsetTop = scrollContainer.offsetTop;
            let parentOffsetTop =
              targetDiv.offsetParent && targetDiv.offsetParent.offsetTop > 0 ? targetDiv.offsetParent.offsetTop : 0;
            let popoverPositionTop = parentOffsetTop - containerOffsetTop + targetDiv.offsetTop;
            let popoverPositionBottom = parentOffsetTop - containerOffsetTop + targetDiv.offsetTop + targetDivHeight; // 40 : height of the "?" div.
            if (low > popoverPositionBottom || high < popoverPositionTop) {
              if (this.state.popoverShow) {
                this.showPopover();
              }
            }
          } else {
            this.clearScrollInterval();
          }
        }, 500);
      }
    };
  } //constructor

  onClick = e => {
    this.showPopover();
    e.preventDefault();
    e.stopPropagation();
  };

  updateDimensions() {
    this.setState({
      ...this.state,
      resized: this.state.resized + 1,
    });
  }

  componentDidMount() {
    this.bodyStyle.width = document.getElementsByClassName(this.props.container)[0].clientWidth;
    window.addEventListener('resize', this.updateDimensions.bind(this));
    window.addEventListener('orientationchange', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    this.clearScrollInterval();
    window.removeEventListener('resize', this.updateDimensions.bind(this));
    window.removeEventListener('orientationchange', this.updateDimensions.bind(this));
  }

  render() {
    return (
      <React.Fragment>
        <button
          style={{
            padding: '2',
            border: 'none',
            background: 'none',
            verticalAlign: 'middle',
            'min-width': '25px'
          }}
          ref={this.elmPopover}
          onClick={this.onClick}
        >
          <div role="img" aria-label={this.props.ariaLabel} alt="Popup Icon" className="popup-icon">
            <span>?</span>
          </div>
        </button>
        {this.renderPopover()}
      </React.Fragment>
    );
  }

  static glossaryDefinition = {};
  static loadGlossaryDefinitions = disclosureDisclosures => {
    if (Object.keys(GlossaryDefinitionPopover.glossaryDefinition).length > 0) {
      return true;
    }

    if (Object.keys(disclosureDisclosures).length > 0) {
      const FidelityFund = `<u>Fidelity Funds</u>: ${_.get(
        disclosureDisclosures,
        'Funds managed by Fidelity Investments[0].text',
        'Undefined',
      )}`;
      const FundPick = `<u>Fund Picks from Fidelity</u>: ${_.get(
        disclosureDisclosures,
        'MFE_Fundpicks[0].text',
        'Undefined',
      )}`;
      const NTF = disclosureDisclosures['MFE_NoTransactionFee'][0].text;
      const MI = disclosureDisclosures['Minimum investment < $2.5k'][0].text;
      const SustainableAttributes = `<u>Sustainable Attributes</u>: ${_.get(
        disclosureDisclosures,
        'MFE_Sustainableattributes[0].text',
        'Undefined',
      )}`;
      GlossaryDefinitionPopover.glossaryDefinition[
        'Key Criteria'
      ] = `${FidelityFund}<p>${FundPick}</p><p>${NTF}</p><p>${MI}</p><p>${SustainableAttributes}</p>`;
      GlossaryDefinitionPopover.glossaryDefinition['Asset Class and Category'] =
        disclosureDisclosures['MFE_AllAstCls'][0].text;
      GlossaryDefinitionPopover.glossaryDefinition['Category by Morningstar Risk Rating'] =
        disclosureDisclosures['MFE_Moreonrisk'][0].text;

      const Ratings = `<u>Ratings</u>: ${disclosureDisclosures['MFE_Ovrallrtng'][0].text}`;
      const Returns = `<u>Returns</u>: ${disclosureDisclosures['MFE_Returns'][0].text}`;
      const Expenses = `<u>Expenses</u>: ${disclosureDisclosures['MFE_Xpns'][0].text}`;
      GlossaryDefinitionPopover.glossaryDefinition[
        'Morningstar Ratings'
      ] = `${Ratings}<p>${Returns}</p><p>${Expenses}</p>`;

      GlossaryDefinitionPopover.glossaryDefinition['All Asset Classes'] =
        disclosureDisclosures['MFE_AllAstCls'][0].text;
      GlossaryDefinitionPopover.glossaryDefinition['Allocation'] = disclosureDisclosures['MFE_Alloc'][0].text;
      GlossaryDefinitionPopover.glossaryDefinition['Alternative'] = disclosureDisclosures['MFE_Alternative'][0].text;
      GlossaryDefinitionPopover.glossaryDefinition['Commodities'] = disclosureDisclosures['MFE_Commodities'][0].text;
      GlossaryDefinitionPopover.glossaryDefinition['International Equity'] =
        disclosureDisclosures['MFE_IntEqty'][0].text;
      GlossaryDefinitionPopover.glossaryDefinition['Money Market'] = disclosureDisclosures['MFE_MoneyMarket'][0].text;
      GlossaryDefinitionPopover.glossaryDefinition['Municipal Bond'] = disclosureDisclosures['MFE_MunBond'][0].text;
      GlossaryDefinitionPopover.glossaryDefinition['Sector Equity'] = disclosureDisclosures['MFE_SectorEqty'][0].text;
      GlossaryDefinitionPopover.glossaryDefinition['Taxable Bond'] = disclosureDisclosures['MFE_TaxBond'][0].text;
      GlossaryDefinitionPopover.glossaryDefinition['U.S. Equity'] = disclosureDisclosures['MFE_USEqty'][0].text;
      GlossaryDefinitionPopover.glossaryDefinition['Nontraditional Equity'] =
        disclosureDisclosures['MFE_NonTraditionEqty'][0].text;
      GlossaryDefinitionPopover.glossaryDefinition['Miscellaneous'] = disclosureDisclosures['Miscellaneous'][0].text;
      GlossaryDefinitionPopover.glossaryDefinition['Category Risk Distribution Within Asset Class'] =
        disclosureDisclosures['MFE_Moreonrisk'][0].text;
      return true;
    }
    return false;
  };
}

const mapStateToProps = state => {
  try {
    GlossaryDefinitionPopover.loadGlossaryDefinitions(state.disclosureDisclosures);
    return {};
  } catch (ex) {
    return {};
  }
};

export default connect(mapStateToProps)(GlossaryDefinitionPopover);
