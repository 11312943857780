import { createSelector } from 'reselect';
import _ from 'lodash';
import { VIEW_TAB_CONFIG, VIEW_TABS_BY_DISPLAY_TYPE } from 'configs/ViewTabConfig';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getPolicyCode } from 'reducers/PolicyInfoReducer';

export const getCurrentViewTabsByShortName = createSelector([getDisplayType], displayType => {
  const viewTabIds = VIEW_TABS_BY_DISPLAY_TYPE[displayType];
  const vewTabs = _.map(viewTabIds, viewTabId => _.get(VIEW_TAB_CONFIG, viewTabId));
  return _.keyBy(vewTabs, 'shortName');
});

export const getViewTabDisplay = createSelector([getDisplayType, getPolicyCode], (displayType, policyCode) => {
  const viewTabIds = VIEW_TABS_BY_DISPLAY_TYPE[displayType];
  // policyCodes 'FIA', 'FFLI' are not to include daily-pricing-yields tab
  const isSpecialCasePolicy = ['FIA', 'FFLI'].includes(policyCode);
  const viewTabFilter = viewTab => {
    if (isSpecialCasePolicy && viewTab.id === 'daily-pricing-yields') {
      return false;
    }
    return !_.isNil(viewTab) && viewTab.showAsViewTab;
  };
  const parsedConfig = _.chain(viewTabIds)
    .map(viewTabId => _.get(VIEW_TAB_CONFIG, viewTabId))
    .filter(viewTabFilter)
    .value();

  const viewTabDisplay = _.mapValues(parsedConfig, tabConfig => {
    return {
      id: tabConfig.id,
      label: tabConfig.label,
      shortName: tabConfig.shortName,
    };
  });

  return _.keyBy(viewTabDisplay, 'id');
});

export const getCurrentViewTabShortNames = createSelector([getCurrentViewTabsByShortName], currentViewTabsByShortName =>
  _.keys(currentViewTabsByShortName),
);
