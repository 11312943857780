import _ from 'lodash';
import { createReducer } from 'reduxsauce';
import { createSelector } from 'reselect';
import Types from 'actions/Types';
import { getFundTypeFilters } from 'reducers/FundTypeFiltersReducer';
import { sorting } from 'utilities';

export const INITIAL_STATE = {
  isModalOpen: false,
  userSelectedCriteria: null,
  selectedCategory: 'All Categories',
  selectedGroups: [],
  groups: [],
  allGroupsChecked: true,
  assetTotalCounts:{}
};

const filterGroups = (state, { category, groups }) => {
  if (category !== 'All Categories') {
    const totalCountGroups = state.assetTotalCounts[category];
    const totalCountGroupsArray = Object.keys(totalCountGroups).filter(e=>e !== '_total_' && totalCountGroups[e]>0 );
    const filteredGroups = _.filter(groups, group => _.includes(totalCountGroupsArray, group.key));
    return filteredGroups.sort((a, b) => {
      return sorting.alphabetSort(a.label, b.label);
    });
  } else {
    return [];
  }
};

// select AssetClasses on UI
const updateSelectedCategory = (state = INITIAL_STATE, action = {}) => {
  if (action.category === state.selectedCategory) {
    return { ...state };
  } else {
    const filteredGroups = filterGroups(state, action);
    return {
      ...state,
      selectedCategory: action.category,
      selectedGroups: [],
      groups: filteredGroups,
      allGroupsChecked: true
    };
  }
};

// select Categroy on UI
const updateSelectedGroups = (state = INITIAL_STATE, action = {}) => {
  let groups = state.selectedGroups;
  if (action.group === 'All Groups') {
    return {
      ...state,
      selectedGroups: [],
      allGroupsChecked: !state.allGroupsChecked,
    };
  } else {
    if (state.allGroupsChecked) {
      let groups2 = state.groups.map(e => e.label);
      return {
        ...state,
        selectedGroups: groups2.filter(x => x === action.group),
        allGroupsChecked: false,
      };
    } else {
      if (groups.includes(action.group)) {
        return {
          ...state,
          selectedGroups: groups.filter(x => x !== action.group),
          allGroupsChecked: false,
        };
      } else {
        let selectedGroups = [...groups, action.group];
        return {
          ...state,
          selectedGroups: selectedGroups,
          allGroupsChecked: false,
        };
      }
    }
  }
};

const resetFundPicks = (state = INITIAL_STATE, action = {}) => {
  const assetTotalCounts = state.assetTotalCounts;
  return { ...INITIAL_STATE, assetTotalCounts: assetTotalCounts };
};

const loadingApiSuccess = (state = INITIAL_STATE, action = {}) => {
  let assetTotalCounts = state.assetTotalCounts;
  if (action.params && action.payload && action.payload.data && action.payload.data.length>=0){
    const fundApiData = action.params['fundApiData'];
    const categories = action.payload.data;
    categories.forEach(cat =>{
      let catCode = cat.criteria;
      let asset = fundApiData.find(e=> e.categories.find(t=>t.code===catCode)!=null);
      if (asset){
        const oldvalue = assetTotalCounts[asset.description] && assetTotalCounts[asset.description]['_total_']>0?assetTotalCounts[asset.description]['_total_']:0;
        if (!assetTotalCounts[asset.description]){
          assetTotalCounts[asset.description]={};
        }
        assetTotalCounts[asset.description]['_total_'] = oldvalue + cat.count;
        assetTotalCounts[asset.description][catCode]=cat.count;
      }
    });
    return {...state, assetTotalCounts: assetTotalCounts };
  }
  return state;
};

export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
  if (action.payload.location.pathname === '/picks') {
    const assetTotalCounts = state.assetTotalCounts;
    return { ...INITIAL_STATE, assetTotalCounts: assetTotalCounts};
  }
  return state;
};



const ACTION_HANDLERS = {
  [Types.FUND_PICKS_UPDATE_SELECTED_CATEGORY]: updateSelectedCategory,
  [Types.FUND_PICKS_UPDATE_SELECTED_GROUPS]: updateSelectedGroups,
  [Types.ADD_CRITERION]: resetFundPicks,
  [Types.UPDATE_CRITERIA]: resetFundPicks,
  [Types.LOAD_STRATEGY]: resetFundPicks,
  [Types.FUND_PICKS_FROM_FIDELITY_LOADING_API_SUCCESS]: loadingApiSuccess,
  "@@router/LOCATION_CHANGE": routerLocationChanged,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

// ---- Selectors ----

export const getFundPicksAssetTotalCounts = state => state.fundPicks.assetTotalCounts;
export const getFundPicksGroups = state => state.fundPicks.groups;
export const getFundPicksSelectedCategory = state => state.fundPicks.selectedCategory;
export const getFundPicksSelectedGroups = state => state.fundPicks.selectedGroups;
export const getFundPicksAllGroupsChecked = state => state.fundPicks.allGroupsChecked;
export const getQuickCriteriaModalState = state => state.quickCriteriaMenu.isModalOpen;

export const getFundPicksParsedSelections = createSelector(
  [getFundPicksSelectedCategory, getFundPicksSelectedGroups, getFundTypeFilters, getFundPicksAssetTotalCounts],
  (selectedCategory, selectedGroups, fundTypeFilters, fundPicksAssetTotalCounts) => {
    let categoryIds = [];
    let groupIds = [];
    const assetTotalCounts = Object.keys(fundPicksAssetTotalCounts).filter(e=> fundPicksAssetTotalCounts[e] && fundPicksAssetTotalCounts[e]['_total_'] > 0);
    const assetFilters = fundTypeFilters.filter(e=> assetTotalCounts.includes(e.label));
    if (selectedCategory === 'All Categories') {
      categoryIds = assetFilters.map(e=>e.key);
      groupIds = [];
    } else {
      const filter = assetFilters.find(e=>e.label===selectedCategory);
      if (filter){
        categoryIds.push(filter.key);
        let catKeys=[];
        if (fundPicksAssetTotalCounts[selectedCategory]) {
          catKeys =  Object.keys(fundPicksAssetTotalCounts[selectedCategory]).filter(e=> e !== '_total_' && fundPicksAssetTotalCounts[selectedCategory][e]>0 );
        }
        if (selectedGroups.length < 1) {
          groupIds=catKeys;
        } else {
          selectedGroups.forEach(e=>{
            const f = filter.categories.find(t=>t.label === e);
            if (f && catKeys.includes(f.key)){
              groupIds.push(f.key);
            }
          });
        }
      }
    }
    const critIdArray = ['fundType', 'fundPicks', 'ntf'];
    const filterGroupSelections = {
      assetClass: categoryIds,
      category: groupIds,
      fundPicks: ['Y'],
      ntf: ['Y'],
    };
    return { critIdArray, filterGroupSelections };
  },
);
