import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Creators from 'actions/CriteriaActions';
import _ from 'lodash';
import FidelitySearchBar from 'components/Shared/FidelitySearchBar';
import PricingModelPopup from 'components/ResultsView/PricingModelPopup';
import PricingModelRelatedInfo from 'components/ResultsView/PricingModelRelatedInfo';
import { getOverallTotalMatches } from 'reducers/TotalMatchesReducer';
import { getIsMobile } from 'reducers/MobileStatusReducer';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getStrategyData } from 'reducers/selectors/FundStrategiesSelectors';
import { getIfStrategyIsModified } from 'reducers/FundStrategiesReducer';
import { getCurrentResultsPageNum } from 'reducers/ResultsPageNumReducer';
import { getTopOfTableAsOfDate } from 'reducers/FundSearchResultsReducer';
import { getSomeResultsLoading } from 'reducers/LoadingReducer';
import { getIsMadLibsActive } from '../../reducers/QuickCriteriaMenuReducer';
import { getPricingModels, getPolicyCode } from 'reducers/PolicyInfoReducer';
import { getRouterResult } from 'reducers/selectors/RouterSelectors';
import { RESULT_FEATURES } from 'configs/AppFeatureConfig';
import format from 'utilities/format';

const _renderAsOfDate = asOfDate => {
  const fidelityAsOfDate = asOfDate.fidelity || '--';
  const otherAsOfDate = asOfDate.other || '--';
  return `Fidelity fund performance data as of ${fidelityAsOfDate}, all other fund data as of ${otherAsOfDate} unless otherwise noted`;
};

const _renderResultCountSubTitle = ({
  totalMatches,
  currentResultsPageNum,
  asOfDate,
  isMobile,
  displayType,
  someResultsLoading,
}) => {
  asOfDate = !_.isNil(asOfDate) && !someResultsLoading ? asOfDate : '--';
  if (displayType === 'compare') {
    return (
      <div className="result-count-subtitle">
        <div>{_renderAsOfDate(asOfDate)}</div>
      </div>
    );
  } else if (isMobile) {
    return (
      <div className="result-count-subtitle">
        <div className="strong">{format.formatNumResults(totalMatches, currentResultsPageNum)}</div>
        <div>{_renderAsOfDate(asOfDate)}</div>
      </div>
    );
  } else {
    return (
      <div className="result-count-subtitle">
        <span className="strong">{format.formatNumResults(totalMatches, currentResultsPageNum)}</span>
        <br />
        <span>{_renderAsOfDate(asOfDate)}</span>
      </div>
    );
  }
};

const _renderPricingModelsPopup = (displayType, pricingModels, updatePricingModel) => {
  if (!RESULT_FEATURES.HAS_PRICING_MODEL_DROPDOWN || displayType === 'compare') return null;
  const isClosed = _.get(pricingModels, [0, 'policyStatus']) === 'Closed';
  const selectionPolicyDescription = isClosed ? (
    <div className="close-indicator-description">
      <span className={`closed indicator`}>CLOSED</span> This annuity is no longer accepting new customers
    </div>
  ) : null;
  return (
    <React.Fragment>
      <PricingModelPopup items={pricingModels} handleChangeSelection={updatePricingModel} />
      {selectionPolicyDescription}
    </React.Fragment>
  );
};

const _getTitleText = (displayType, strategy, isStrategyModified, pricingModels, isMadLibsActive) => {
  if (displayType === 'compare') {
    return 'Compare Funds';
  } else if (!_.isNil(strategy)) {
    return isStrategyModified ? `${strategy.label} (Modified)` : strategy.label;
  } else if (!_.isNil(pricingModels) && pricingModels.length) {
    return pricingModels[0].policyLongName;
  } else if(isMadLibsActive) {
    return 'Getting Started with Mutual Funds'
  } else {
    return
  }
};

const ResultsTitle = props => {
  const {
    isMobile,
    displayType,
    strategy,
    isStrategyModified,
    policyCode,
    pricingModels,
    updatePricingModel, 
    isMadLibsActive
  } = props;
  const mutualFundSearch = isMobile || !RESULT_FEATURES.HAS_SEARCH ? null : <FidelitySearchBar />;
  const pricingModelRelatedInfo = !RESULT_FEATURES.HAS_PRICING_MODEL_RELATED_INFO ? null : (
    <PricingModelRelatedInfo policyCode={policyCode} />
  );
  const resultCountSubTitle = _renderResultCountSubTitle(props);
  const titleText = _getTitleText(displayType, strategy, isStrategyModified, pricingModels, isMadLibsActive);
  const subTitle = RESULT_FEATURES.HAS_SUB_TITLE ? <h3 dangerouslySetInnerHTML={{ __html: titleText }} /> : null;
  const pricingModelsPopup = _renderPricingModelsPopup(displayType, pricingModels, updatePricingModel);

  return (
    <div className="results-title">
      <div className="title-and-result-count">
        {subTitle}
        {pricingModelsPopup}
        {resultCountSubTitle}
      </div>
      {mutualFundSearch}
      {pricingModelRelatedInfo}
    </div>
  );
};

ResultsTitle.propTypes = {
  totalMatches: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentResultsPageNum: PropTypes.number.isRequired,
  /** Object representing the currently loaded strategy, if any */
  strategy: PropTypes.object,
  isStrategyModified: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  displayType: PropTypes.string.isRequired,
  asOfDate: PropTypes.object,
  /** True if result table is still loading */
  someResultsLoading: PropTypes.bool.isRequired,
  /** The current feature configs for the given router */
  routerResult: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    totalMatches: getOverallTotalMatches(state),
    isMobile: getIsMobile(state),
    asOfDate: getTopOfTableAsOfDate(state),
    displayType: getDisplayType(state),
    strategy: getStrategyData(state),
    isStrategyModified: getIfStrategyIsModified(state),
    currentResultsPageNum: getCurrentResultsPageNum(state),
    someResultsLoading: getSomeResultsLoading(state),
    routerResult: getRouterResult(state),
    pricingModels: getPricingModels(state),
    policyCode: getPolicyCode(state),
    isMadLibsActive: getIsMadLibsActive(state)
  };
}

const mapDispatchToProps = dispatch => {
  return {
    updatePricingModel: productCode => {
      dispatch(Creators.updatePricingModel(productCode));
    },
  };
};

const ResultsTitleContainer = connect(mapStateToProps, mapDispatchToProps)(ResultsTitle);
export default ResultsTitleContainer;
