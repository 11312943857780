import screenerConfig from 'screenerConfig';

export const APP_FEATURE_CONFIG = screenerConfig.appFeatureConfig;

export const APP_DISPLAY_TITLE = APP_FEATURE_CONFIG.APP_DISPLAY_TITLE;
export const APP_CHANNEL = APP_FEATURE_CONFIG.APP_CHANNEL;
export const APP_DISCLOSURE_CHANNEL = APP_FEATURE_CONFIG.APP_DISCLOSURE_CHANNEL;
export const APP_DISCLOSURE_CODE_PREFIX = APP_FEATURE_CONFIG.APP_DISCLOSURE_CODE_PREFIX;
export const ADOBE_ANALYTICS_CHANNEL = APP_FEATURE_CONFIG.ADOBE_ANALYTICS_CHANNEL;
export const ADOBE_ANALYTICS_SEC1_PREFIX = APP_FEATURE_CONFIG.ADOBE_ANALYTICS_SEC1_PREFIX;
export const DEFAULT_MOBILE_DISPLAY_TYPE = APP_FEATURE_CONFIG.DEFAULT_MOBILE_DISPLAY_TYPE;
// TODO: Remove HAS_TERMS_OF_USE_DISCLOSURE when disclosure API handles this edge case
export const HAS_TERMS_OF_USE_DISCLOSURE = APP_FEATURE_CONFIG.HAS_TERMS_OF_USE_DISCLOSURE;
export const LOGIN_EXPIRATION_MINUTES = APP_FEATURE_CONFIG.LOGIN_EXPIRATION_MINUTES;
export const PLAN_NUMBER = APP_FEATURE_CONFIG.PLAN_NUMBER;
export const START_PAGE_TYPE = APP_FEATURE_CONFIG.START_PAGE_TYPE;

// Result feature constants
export const RESULT_FEATURES = APP_FEATURE_CONFIG.RESULT_FEATURES;
export const BUY_BUTTON_LABEL = RESULT_FEATURES.BUY_BUTTON_LABEL;
export const BUY_BUTTON_LINK_PARAMS = RESULT_FEATURES.BUY_BUTTON_LINK_PARAMS;
export const CLEAR_ALL_REDIRECTS_TO_START = RESULT_FEATURES.CLEAR_ALL_REDIRECTS_TO_START;
export const COMPARE_BUTTON_LABEL = RESULT_FEATURES.COMPARE_BUTTON_LABEL;
export const COMPARE_LABEL = RESULT_FEATURES.COMPARE_LABEL;
export const ETF_LINK_URL = RESULT_FEATURES.ETF_LINK_URL;
export const FIDELITY_AS_OF_DATE_SUBJECT_AREA = RESULT_FEATURES.FIDELITY_AS_OF_DATE_SUBJECT_AREA;
export const FUND_DATA_SUBJECT_AREA = RESULT_FEATURES.FUND_DATA_SUBJECT_AREA;
export const FUND_LINK_URL = RESULT_FEATURES.FUND_LINK_URL;
export const FUND_LINK_OPENS_NEW_WINDOW = RESULT_FEATURES.FUND_LINK_OPENS_NEW_WINDOW;
export const FUND_LINK_ID_FIELD = RESULT_FEATURES.FUND_LINK_ID_FIELD;
export const FUND_NAME_FIELD = RESULT_FEATURES.FUND_NAME_FIELD;
export const FUND_SYMBOL_FIELD = RESULT_FEATURES.FUND_SYMBOL_FIELD;
export const HAS_BREADCRUMBS = RESULT_FEATURES.HAS_BREADCRUMBS;
export const HAS_FIDELITY_FUNDS_TABLE = RESULT_FEATURES.HAS_FIDELITY_FUNDS_TABLE;
export const HAS_MATCHING_FUNDS_LABEL = RESULT_FEATURES.HAS_MATCHING_FUNDS_LABEL;
export const HELP_ME_READ_THIS_LINK = RESULT_FEATURES.HELP_ME_READ_THIS_LINK;
export const RESULT_TEXT = RESULT_FEATURES.RESULT_TEXT;
export const RESULT_TYPE = RESULT_FEATURES.RESULT_TYPE;
export const HAS_HELP_ME_READ_THIS_TABLE_LINK = RESULT_FEATURES.HAS_HELP_ME_READ_THIS_TABLE_LINK;
export const HELP_ME_READ_THIS_TABLE_LINK_IS_POPUP = RESULT_FEATURES.HELP_ME_READ_THIS_TABLE_LINK_IS_POPUP;
export const HAS_DOWNLOAD_RESULTS = RESULT_FEATURES.HAS_DOWNLOAD_RESULTS;
export const HAS_PRICING_MODEL_DROPDOWN = RESULT_FEATURES.HAS_PRICING_MODEL_DROPDOWN;
export const NEW_SCREEN_LABEL = RESULT_FEATURES.NEW_SCREEN_LABEL;
export const HAS_BANNER = RESULT_FEATURES.HAS_BANNER;

//Feature flags
// export const FEATURE_FLAGS = APP_FEATURE_CONFIG.FEATURE_FLAGS;
// export const SUPPRESS_DODGE_AND_COX_FUNDS = FEATURE_FLAGS.SUPPRESS_DODGE_AND_COX_FUNDS;

// computed constants
export const RESULT_TEXT_SINGULAR = RESULT_TEXT.substring(0, RESULT_TEXT.length - 1);
export const RESULT_TYPE_CAPITALIZED = RESULT_TYPE.charAt(0).toUpperCase() + RESULT_TYPE.slice(1);
RESULT_FEATURES.RESULT_TEXT_SINGULAR = RESULT_TEXT_SINGULAR;
RESULT_FEATURES.RESULT_TYPE_CAPITALIZED = RESULT_TYPE_CAPITALIZED;

// Compare feature constants
export const COMPARE_FEATURES = APP_FEATURE_CONFIG.COMPARE_FEATURES;
export const BACK_BUTTON_LABEL = COMPARE_FEATURES.BACK_BUTTON_LABEL;
export const CHART_FUND_ID_FIELD = COMPARE_FEATURES.CHART_FUND_ID_FIELD;
export const CHART_FUND_LABEL_FIELD = COMPARE_FEATURES.CHART_FUND_LABEL_FIELD;
export const FUND_ID_FIELD = COMPARE_FEATURES.FUND_ID_FIELD;
export const FUND_IDS_FIELD = COMPARE_FEATURES.FUND_IDS_FIELD;
export const HAS_ADD_SYMBOL_FIELD = COMPARE_FEATURES.HAS_ADD_SYMBOL_FIELD;

// Route feature constants
export const ROUTE_FEATURES = APP_FEATURE_CONFIG.ROUTE_FEATURES;
export const ROUTE_CONSTANTS = ROUTE_FEATURES.ROUTE_CONSTANTS;
export const START_ROUTE = ROUTE_FEATURES.START_ROUTE;
export const MOBILE_START_ROUTE = ROUTE_FEATURES.MOBILE_START_ROUTE;
export const PERSISTENT_QUERIES = ROUTE_FEATURES.PERSISTENT_QUERIES;
export const ROUTES = ROUTE_FEATURES.ROUTES;

export const RUNNING_ENVIRONMENT = APP_FEATURE_CONFIG.RUNNING_ENVIRONMENT;