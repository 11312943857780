import { createSelector } from 'reselect';
import _ from 'lodash';
import { FUND_STRATEGIES_CONFIG } from 'configs/FundStrategiesConfig';
import { getStartPageActiveSubSection } from 'reducers/StartPageViewTabReducer';
import { getFundStrategiesState } from 'reducers/FundStrategiesReducer';

export const getNestedStrategy = createSelector(
  [getStartPageActiveSubSection],
  nestedStrategy => {
    return _.find(FUND_STRATEGIES_CONFIG, strategy => strategy.id === nestedStrategy);
  },
);

export const getStrategyData = createSelector(
  [getFundStrategiesState],
  strategiesState => {
    const currentStrategyId = strategiesState.strategyId;
    if (_.isNil(currentStrategyId)) return null;

    const strategiesParts = currentStrategyId.split(':');
    const isNestedStrategy = strategiesParts.length > 1;

    if (isNestedStrategy) {
      const parentStrategyId = _.first(strategiesParts);
      const parentStrategy = _.find(FUND_STRATEGIES_CONFIG, strategy => strategy.id === parentStrategyId);
      return _.find(parentStrategy.strategiesList, strategy => strategy.id === currentStrategyId);
    } else {
      return _.find(FUND_STRATEGIES_CONFIG, strategy => strategy.id === currentStrategyId);
    }
  },
);
