import _ from 'lodash';
import screenerConfig from 'screenerConfig';

export const RESULT_CONFIG = screenerConfig.resultConfig;

// ---- Computed constants ----

export const TOGGLE_GROUP_CONFIG = _.chain(RESULT_CONFIG)
  .pickBy(result => {
    return result.resultType === 'resultToggleGroup';
  })
  .mapKeys(configObj => {
    return configObj.shortName;
  })
  .value();

export const ALL_SORT_FIELDS = _.chain(RESULT_CONFIG)
  .map(config => config.sortField)
  .filter(sortField => {
    return !_.isNil(sortField);
  })
  .value();

export const COLUMN_LABELS_BY_SORT_FIELD = _.chain(RESULT_CONFIG)
  .keyBy(config => config.sortField)
  .pickBy(config => !_.isNil(config.sortField) && !_.isNil(config.label))
  .mapValues(config => config.label)
  .value();
