import React from 'react';
import PropTypes from 'prop-types';

const QuickCriteriaRiskSlider = ({ handleMoveRiskSlider, riskValue, trackMoreLink }) => {
  let textValue = '';
  if (riskValue == 1) {
    textValue = 'Risk level ' + riskValue + '(lowest risk)';
  } else if (riskValue > 1 && riskValue < 5) {
    textValue = 'Risk level ' + riskValue + '(lower risk)';
  } else if (riskValue == 5) {
    textValue = 'Risk level ' + riskValue + '(median risk)';
  } else if (riskValue > 5 && riskValue < 10) {
    textValue = 'Risk level ' + riskValue + '(higher risk)';
  } else if (riskValue == 10) {
    textValue = 'Risk level ' + riskValue + '(highest risk)';
  }
  return (
    <div className="quick-criteria-risk-slider">
      <input
        aria-label="Category by Morning Star Risk Rating"
        type="range"
        min="1"
        max="10"
        aria-valuemin="1"
        aria-valuemax="2"
        value={riskValue}
        aria-valuetext={textValue}
        className="slider-input"
        onChange={handleMoveRiskSlider}
      />
      <div className="labels">
        <div className="left">Lower Risk</div>
        <div className="right">Higher Risk</div>
      </div>
    </div>
  );
};

QuickCriteriaRiskSlider.propTypes = {
  /** Handler for moving the Morningstar risk slider */
  handleMoveRiskSlider: PropTypes.func.isRequired,

  /** The value of the Morningstar risk slider */
  riskValue: PropTypes.string.isRequired,
};

export default QuickCriteriaRiskSlider;
