import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Creators from 'actions/CriteriaActions';
import _ from 'lodash';
import { icons } from 'utilities';
import { getFundsSelected } from 'reducers/FundsSelectedReducer';
import Popover from 'components/Shared/Popover';
import { RESULT_TYPE_CAPITALIZED, COMPARE_LABEL } from 'configs/AppFeatureConfig';

class FundsSelectedPopover extends Component {
  constructor(props){
    super(props);
    this.bodyStyle={};
  }

  _removeFundFromSelections = cusip => {
    const fundsSelected = _.filter(this.props.fundsSelected, fund => fund.cusip !== cusip);
    if (this.props.fundToCheckAfterRemoval) {
		fundsSelected.push(this.props.fundToCheckAfterRemoval);
      this.props.closePopover();
    }
    return this.props.updateFundsSelected(fundsSelected);
  };
  _renderFundSelectionForMouse = fundInfo => {
    const { cusip, fundCode, id } = this.props.additionalFundData || {};
    const key = `cusip-${cusip || ''}-fund-code-${fundCode || ''}-id-${id || ''}`;
	const fundName = fundInfo.name? fundInfo.name.replace("<sup>&reg;</sup>",""): '';
    return (
      <div key={key} className="fund-selection" role="listitem">
        <div className="remove-fund-container" aria-label={`${fundName} symbol ${fundInfo.symbol} `}>
          <img
            className="close-icon close-icon-small"
            src={icons.greyX}
            alt={`${fundName} ${fundInfo.symbol} `}
            onClick={() => {
              this._removeFundFromSelections(fundInfo.cusip);
            }}
          />
        </div>
        <div aria-hidden="true">
          <span className="selected-fund-name" dangerouslySetInnerHTML={{ __html: fundInfo.name }} />
          <span className="selected-fund-ticker"> ({fundInfo.symbol})</span>
        </div>
      </div>
    );
  };
  _renderFundSelectionForKeyboard = fundInfo => {
    const { cusip, fundCode, id } = this.props.additionalFundData || {};
    const key = `cusip-${cusip || ''}-fund-code-${fundCode || ''}-id-${id || ''}`;
	const fundName = fundInfo.name? fundInfo.name.replace("<sup>&reg;</sup>",""): '';
    return (
      <div key={key} className="fund-selection" role="listitem">
        <button className="remove-fund-container transparent-btn popover-btn" 
			aria-label={`${fundName} symbol ${fundInfo.symbol} `}
			onClick={(e) => {
				this._removeFundFromSelections(fundInfo.cusip);
			}}		
		>
          <img
            className="close-icon close-icon-small"
            src={icons.greyX}
            alt="Grey X Icon"
			aria-hidden="true"
          />
        </button>
        <div aria-hidden="true">
          <span className="selected-fund-name" dangerouslySetInnerHTML={{ __html: fundInfo.name }} />
          <span className="selected-fund-ticker"> ({fundInfo.symbol})</span>
        </div>
      </div>
    );
  };
  _closePopover = () => {
    this.props.closePopover();
  };
  render() {
    this.bodyStyle.width = 300;
    if( this.props.container &&
        document.getElementsByClassName(this.props.container)[0] ){
          this.bodyStyle.width = document.getElementsByClassName(this.props.container)[0].clientWidth-6;
          if( this.bodyStyle.width > 500){
            this.bodyStyle.width = 500;
          }
    }
    const fundSelections = _.map(this.props.fundsSelected, fund => {
		if (this.props.isKeyNaviMode) {
      		return this._renderFundSelectionForKeyboard(fund);
		}
		return this._renderFundSelectionForMouse(fund);
    });
	const fundSelectionList= () =>{
		return (
			<div class="fund-selection-list" role="list">
				{fundSelections}
			</div>
		);
	}
    return (
      <Popover
        id="fund-selections-detail"
        style={this.bodyStyle}
        title={COMPARE_LABEL}
        titleSubtext={`${this.props.fundsSelected.length} of 5 ${RESULT_TYPE_CAPITALIZED}s`}
        closePopover={this._closePopover}
        body={fundSelectionList()}
        referenceElement={this.props.referenceElement}
        placement={this.props.placement || 'bottom'}
        error={this.props.error}
		isKeyNaviMode = { this.props.isKeyNaviMode}
      />
    );
  }
}

FundsSelectedPopover.propTypes = {
  //List of funds from state that the user has selected
  fundsSelected: PropTypes.array.isRequired,
  //function that dispatches action to update the funds selected in state
  updateFundsSelected: PropTypes.func.isRequired,
  //denotes whether the popover is open (visible) or closed (not visible)
  isOpen: PropTypes.bool.isRequired,
  // true: keyboard navigation using tab, spacebar, enter key.
  isKeyNaviMode: PropTypes.bool,
  //function to be called when the popover is closed
  closePopover: PropTypes.func.isRequired,
  //element that the popover should be pointing towards and positioned relative to
  referenceElement: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  //String used to denote how popover should be positioned relative to the reference element (see react-popper docs for list of accepted values)
  placement: PropTypes.string,
  //if defined, this object specifies which fund should be checked upon removing a fund from inside the popover
  fundToCheckAfterRemoval: PropTypes.shape({
    cusip: PropTypes.string,
    ticker: PropTypes.string,
    fundCode: PropTypes.string,
    name: PropTypes.string,
  }),
};

const mapStateToProps = state => {
  const fundsSelected = getFundsSelected(state);
  return {
    fundsSelected,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateFundsSelected: fundsSelected => {
      dispatch(Creators.updateFundsSelected(fundsSelected));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundsSelectedPopover);
