import { createReducer } from 'reduxsauce';
import Types from '../actions/Types';

// the initial state of this reducer
export const INITIAL_STATE = '';

export const addCriterion = (state = INITIAL_STATE, action = {}) => {
  return action.critId;
};

export const removeCriterion = (state = INITIAL_STATE, action = {}) => {
  const expandedCrit = action.critId === state ? INITIAL_STATE : state;
  return expandedCrit;
};

export const clearAll = (state = INITIAL_STATE, action = {}) => {
  return INITIAL_STATE;
};

export const updateExpandedCrit = (state = INITIAL_STATE, action = {}) => {
  return action.expandedCritId;
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.ADD_CRITERION]: addCriterion,
  [Types.REMOVE_CRITERION]: removeCriterion,
  [Types.CLEAR_ALL]: clearAll,
  [Types.UPDATE_EXPANDED_CRIT]: updateExpandedCrit,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const getExpandedCriterion = state => state.expandedCriterion;
