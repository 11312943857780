import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Modal from 'components/Shared/Modal';

class MoreOnRiskLink extends Component {
  constructor() {
    super();
    this.state = { isModalOpen: false };
  }
  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };
  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };
  handleLinkClicked = event => {
    this.handleOpenModal();
    if (!_.isNil(this.props.onLinkClick)) {
      this.props.onLinkClick(event);
    }
  };
  render() {
    return (
      <span>
        <a onClick={this.handleLinkClicked}>More on Risk</a>
        <Modal isOpen={this.state.isModalOpen} handleCloseModal={this.handleCloseModal} classNames="more-on-risk-modal">
          <p>
            <b>More on Risk</b>
          </p>
          <p>
            Morningstar assigns a Risk Level to each of its mutual fund Categories. The Risk Slider groups funds in
            Morningstar Categories by Risk Level. These Risk Levels range from 1 for those funds with the least amount
            of risk to 10 for those with the greatest amount of risk. Morningstar calculates these Risk Levels by
            looking at the Morningstar Risk of the funds in the Category over the previous 5-year period. Morningstar
            Risk is the difference between the Morningstar Return, based on fund total returns, and the Morningstar Risk
            Adjusted Return, based on fund total returns adjusted for performance volatility. The Category Risk Level is
            based on the equal weighted average Morningstar Risk of the funds in the Category. Morningstar's Research
            Committee evaluates the Category Morningstar Risk and assigns the Category Risk Level after further
            qualitative judgment.
          </p>
          <p>
            <b>
              The Category Risk Level does not reflect the risk associated with any individual fund or the relative risk
              of a fund within a particular Morningstar Category.
            </b>
          </p>
          <p>
            Because the score is based on past performance, it may not reflect the future risk of the Category. In
            addition to risk, you should evaluate a fund's investment objective, strategy, fees, and other
            characteristics before making your investment decision.
          </p>
        </Modal>
      </span>
    );
  }
}

MoreOnRiskLink.propTypes = {
  /** Event handler for when the More on risk link is clicked */
  onLinkClick: PropTypes.func,
};

export default MoreOnRiskLink;
