import { ROUTE_CONSTANTS } from 'configs/AppFeatureConfig';
import { matchPath } from 'react-router';
import queryString from 'query-string';
import { RUNNING_ENVIRONMENT } from '../configs/AppFeatureConfig';
import mobileUtils from '../utilities/mobileUtils';

const routerCompatMiddleware = store => next => action => {
  if (RUNNING_ENVIRONMENT.Env === 'Development') {
    // localhost.
    // console.log("routerCompatMiddleware:", action.type, action);
  }
  if (action.type === '@@router/LOCATION_CHANGE') {
    let pathName = action.payload.location.pathname;
    action.payload.query = queryString.parse(action.payload.location.search);

    if (pathName.match('^/results')) {
      if (mobileUtils.isMobile() === false && pathName.indexOf('/results/card/card/') >= 0) {
        pathName = pathName.replace('/results/card/card/', '/results/table/overview/');
      }
      const match = matchPath(pathName, ROUTE_CONSTANTS.RESULTS);
      action.payload.params = match.params;
      action.payload.route = ROUTE_CONSTANTS.RESULTS;
    } else if (pathName.includes('/strategies/')) {
      const match = matchPath(pathName, ROUTE_CONSTANTS.NESTED_STRATEGIES);
      action.payload.params = match.params;
      action.payload.route = ROUTE_CONSTANTS.NESTED_STRATEGIES;
    } else if (pathName === '/') {
      action.payload.route = ROUTE_CONSTANTS.INDEX;
      action.payload.params = {};
    } else if (pathName === '/strategies') {
      action.payload.route = ROUTE_CONSTANTS.STRATEGIES;
      action.payload.params = {};
    } else if (pathName === '/picks') {
      action.payload.route = ROUTE_CONSTANTS.PICKS;
      action.payload.params = {};
    } else if (pathName === '/ways-to-invest') {
      action.payload.route = ROUTE_CONSTANTS.WAYS_TO_INVEST;
      action.payload.params = {};
    }
  }
  next(action);
};
export default routerCompatMiddleware;
