import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import Popover from './Popover';
import _ from 'lodash';

class ResultsTablePopover extends Component {
  constructor(props) {
    super(props);
    this.bodyStyle = {};
    this.elmPopover = React.createRef();
    this.state = { popoverShow: false, resized: 0 };

    this.showPopover = () => {
      if (this.state.popoverShow) {
        this.setState({
          ...this.state,
          popoverShow: false,
        });
        this.clearScrollInterval();
      } else {
        this.setState({
          ...this.state,
          popoverShow: true,
        });
      }
    };

    this.renderBody = () => {
      const { definition } = this.props;
      console.log(definition);
      if (ResultsTablePopover.definition[definition]) {
        const text = ResultsTablePopover.definition[definition];
        return <div style={this.bodyStyle} dangerouslySetInnerHTML={{ __html: text }} />;
      } else {
        const text = 'Definition not available';
        return <div style={this.bodyStyle} dangerouslySetInnerHTML={{ __html: text }} />;
      }
    };

    this.renderPopover = () => {
      if (this.state.popoverShow) {
        this.setupScrollInterval();
        const parent = document.getElementById('root');
        const textBody = this.renderBody();
        const maxwidth = window.screen.width - 4;
        const style = { maxWidth: (maxwidth > 600 ? 600 : maxwidth) + 'px' };

        return ReactDOM.createPortal(
          <Popover
            id="result-table-definition-detail"
            title={this.props.definition}
            body={textBody}
            referenceElement={this.elmPopover.current}
            placement={this.props.placement || 'bottom'}
            closePopover={this.showPopover}
            style={style}
          />,
          parent,
        );
      } else {
        this.clearScrollInterval();
        return null;
      }
    };

    // check if the popupover falls in the scroll window, if not, it will be closed.
    this.interval1 = null;
    this.clearScrollInterval = () => {
      if (this.interval1 != null) {
        clearInterval(this.interval1);
        this.interval1 = null;
      }
    };
    this.setupScrollInterval = () => {
      this.clearScrollInterval();
      let containerClassName =
        this.props.scrolled && this.props.scrolled.container ? this.props.scrolled.container : null;
      let targetClassName = this.props.scrolled && this.props.scrolled.targetDiv ? this.props.scrolled.targetDiv : null;
      let targetDivHeight =
        this.props.scrolled && this.props.scrolled.targetDivHeight ? this.props.scrolled.targetDivHeight : null;
      if (containerClassName && targetClassName) {
        this.interval1 = setInterval(() => {
          let ref = this.elmPopover.current;
          let scrollContainer = ref ? ref.closest('.' + containerClassName) : null;
          let targetDiv = ref ? ref.closest('.' + targetClassName) : null;
          if (scrollContainer && targetDiv) {
            // range : container scrollTop  <= assetClassWrap.offsetTop <=  (container scrollTop + container Height)
            let low = scrollContainer.scrollTop;
            let high = scrollContainer.scrollTop + scrollContainer.clientHeight - 6;
            let containerOffsetTop = scrollContainer.offsetTop;
            let parentOffsetTop =
              targetDiv.offsetParent && targetDiv.offsetParent.offsetTop > 0 ? targetDiv.offsetParent.offsetTop : 0;
            let popoverPositionTop = parentOffsetTop - containerOffsetTop + targetDiv.offsetTop;
            let popoverPositionBottom = parentOffsetTop - containerOffsetTop + targetDiv.offsetTop + targetDivHeight; // 40 : height of the "?" div.
            if (low > popoverPositionBottom || high < popoverPositionTop) {
              if (this.state.popoverShow) {
                this.showPopover();
              }
            }
          } else {
            this.clearScrollInterval();
          }
        }, 500);
      }
    };
  } //constructor

  onClick = e => {
    this.showPopover();
    e.preventDefault();
    e.stopPropagation();
  };

  updateDimensions() {
    this.setState({
      ...this.state,
      resized: this.state.resized + 1,
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
    window.addEventListener('orientationchange', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    this.clearScrollInterval();
    window.removeEventListener('resize', this.updateDimensions.bind(this));
    window.removeEventListener('orientationchange', this.updateDimensions.bind(this));
  }

  render() {
    return (
      <React.Fragment>
        <button
          style={{ padding: '2', border: 'none', background: 'none', verticalAlign: 'middle', 'min-width': '25px' }}
          ref={this.elmPopover}
          onClick={this.onClick}
        >
          <div role="img" alt="Popup Icon" className="popup-icon">
            <span>?</span>
          </div>
        </button>
        {this.renderPopover()}
      </React.Fragment>
    );
  }

  static definition = {};
  static loadDefinitions = resultTableDefinitions => {
    ResultsTablePopover.definition[
      'Life of Fund'
    ] = `Life of Fund returns are from the inception date of the underlying fund, which predates the sub-account inception date.  Fund returns prior to each fund's sub-account inception date have been adjusted so that they reflect the annuity's annual annuity charge. <b>Predated performance is hypothetical.</b>`;
    ResultsTablePopover.definition['Life of Subaccount'] =
      'Unless otherwise stated under "Additional Important Information" below, Life of Subaccount returns are from the date the fund was added as a subaccount to the variable annuity product (the "sub-account inception date").';
    return true;
  };
}

const mapStateToProps = state => {
  try {
    ResultsTablePopover.loadDefinitions(state.resultTableDefinitions);
    return {};
  } catch (ex) {
    return {};
  }
};

export default connect(mapStateToProps)(ResultsTablePopover);
