import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';

export const INITIAL_STATE = 'start';

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};

export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
    if (!action.payload.params) {
        return state;
      } else{
        return action.payload.params
    } 
};

const ACTION_HANDLERS = {
  "@@router/LOCATION_CHANGE": routerLocationChanged,
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
