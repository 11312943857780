import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as reducers from 'reducers';
import rootSaga from 'sagas/index';
import { connectRouter, routerMiddleware } from 'connected-react-router'

import quickCriteriaMiddleware from 'middleware/quickCriteriaMiddleware';
import routerCompatMiddleware from 'middleware/routerCompatMiddleware';
import criteriaSelectionsMiddleware from 'middleware/criteriaSelectionsMiddleware';
import apiServiceMiddleware from 'middleware/apiServiceMiddleware';
import routerMiddlewarex from 'middleware/routerMiddleware';
import analyticsMiddleware from 'middleware/analyticsMiddleware';
import urlValidationMiddleware from 'middleware/urlValidationMiddleware';
import displayTypeMiddleware from 'middleware/displayTypeMiddleware';



export default function configureStore(history) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const sagaMiddleware = createSagaMiddleware();
  const middlewares = applyMiddleware(
    store => next => action => { next(action)},
    routerMiddleware(history),
    routerCompatMiddleware,
    displayTypeMiddleware,
    urlValidationMiddleware,
    quickCriteriaMiddleware,
    criteriaSelectionsMiddleware,
    apiServiceMiddleware,
    sagaMiddleware,
    routerMiddlewarex,
    analyticsMiddleware,
  );
  
  const enhancers = composeEnhancers(middlewares);
  const rootReducer = (history) => combineReducers({ router:connectRouter(history), ...reducers });
  const store = createStore(rootReducer(history), {}, enhancers);
  sagaMiddleware.run(rootSaga());
  return store;
}
