import React, { Component } from 'react';
import { icons } from 'utilities';

class WaysToInvest extends Component {
  render() {
    return (
      <div className="ways-to-invest">
        <p>
          Customize your investment approach based on your overall target asset mix of stocks, bonds, and cash to help meet your investing style, objectives, and tax management needs. Below are three ways to consider investing within the Fidelity Personal Retirement Annuity's 55+ funds, covering a broad range of asset classes and sectors.
        </p>
        <div className="row">
          <a href="https://www.fidelity.com/annuities/FPRA-variable-annuity/investment-options-hands-off" target="_blank" rel="noopener noreferrer">
            <img src={icons.handsOff} alt="hands-off investing"/>
          </a>
          <div className="ways-to-invest-teaser">
            <a href="https://www.fidelity.com/annuities/FPRA-variable-annuity/investment-options-hands-off" target="_blank" rel="noopener noreferrer">
              Hands-off approach with single-fund investing
            </a>
            <p>
              If you prefer a strategy that doesn't require you to actively build or manage your portfolio, a single-fund solution from Fidelity offers automatic diversification<sup>1</sup> and professional money management.
            </p>
          </div>
        </div>
        <div className="row">
          <a href="https://www.fidelity.com/annuities/FPRA-variable-annuity/investment-options-hands-on" target="_blank" rel="noopener noreferrer">
            <img src={icons.handsOn} alt="hands-on investing"/>
          </a>
          <div className="ways-to-invest-teaser">
            <a href="https://www.fidelity.com/annuities/FPRA-variable-annuity/investment-options-hands-on" target="_blank" rel="noopener noreferrer">
              Hands-on approach with your own custom portfolio
            </a>
            <p>
              If you prefer to pick your own funds and periodically rebalance your portfolio as the market changes, you can choose from more than 55 Fidelity and non-Fidelity funds. Plus, our powerful tools and resources can help you research, select, and monitor your investments.
            </p>
          </div>
        </div>
        <div className="row">
          <a href="https://www.fidelity.com/annuities/FPRA-variable-annuity/investment-options-sector-investing" target="_blank" rel="noopener noreferrer">
            <img src={icons.sectorInvesting} alt="sector investing"/>
          </a>
          <div className="ways-to-invest-teaser">
            <a href="https://www.fidelity.com/annuities/FPRA-variable-annuity/investment-options-sector-investing" target="_blank" rel="noopener noreferrer">
              Sector investing approach with a tax-deferred account
            </a>
            <p>
              If you prefer to invest in industry sector funds to help diversify and potentially grow your portfolio, while managing risk, you can choose from 11 funds representing a broad set of industries. Plus, our resources and online tools can help you build a sector strategy that matches your investing style.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default WaysToInvest;