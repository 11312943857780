import _ from 'lodash';
import { createReducer } from 'reduxsauce';
import Types from '../actions/Types';
import { createSelector } from 'reselect';

// the initial state of this reducer
export const INITIAL_STATE = [];

const policyInfoApiSuccess = (state = INITIAL_STATE, action = {}) => {
  return action.payload.data;
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.POLICY_INFO_API_SUCCESS]: policyInfoApiSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
export const getPolicyInfo = state => state.policyInfo;
export const getPolicyCode = state => state.query.product;

export const getPricingModelsByPlan = createSelector([getPolicyInfo], policyInfo => {
  return _.groupBy(policyInfo, 'policyShortName');
});

export const getPolicyCodeToPlan = createSelector([getPolicyInfo], policyInfo => {
  const keyedByPolicy = _.keyBy(policyInfo, 'policyCode');
  return _.mapValues(keyedByPolicy, 'policyShortName');
});
export const getPlan = createSelector(
  [getPolicyCodeToPlan, getPolicyCode],
  (policyCodeToPlan, policyCode) => policyCodeToPlan[policyCode],
);

export const getPricingModels = createSelector(
  [getPricingModelsByPlan, getPlan, getPolicyCode],
  (pricingModelsByPlan, plan, policyCode) => {
    let pricingModels = pricingModelsByPlan[plan];
    // move current pricing model to top of list
    return _.sortBy(pricingModels, pricingModel => (pricingModel.policyCode === policyCode ? 0 : 1));
  },
);

const _groupAndSortPricingModels = plans =>
  _.chain(plans)
    .groupBy('policyShortName')
    .mapValues(pricingModels => _.orderBy(pricingModels, ['policyStatus', 'policySubHeader'], ['desc', 'asc']))
    .value();

const _getPlanStatus = pricingModels => pricingModels.some(pricingModel => pricingModel.policyStatus === 'Open');

const _getPlanList = policies => {
  const pricingModelsByPlan = _groupAndSortPricingModels(policies);
  const plans = _.map(pricingModelsByPlan, (pricingModels, id) => {
    return {
      id,
      name: _.get(pricingModels, [0, 'policyShortName']),
      pricingModels,
      isOpen: _getPlanStatus(pricingModels),
    };
  });
  return _.orderBy(plans, ['isOpen', 'planName'], ['desc', 'asc']);
};
export const getPlansByType = createSelector([getPolicyInfo], policyInfo => {
  const types = _.groupBy(policyInfo, 'policyType');
  const income = _getPlanList(types.INCOME);
  const taxdef = _getPlanList(types.TAXDEF);
  //the above are from a Fidelity API. The below is hardcoded as apart of the ask for FILI-137
  const deferred = [{id:"Find Rates for Your State of Residence", name: "Find Rates for Your State of Residence", isOpen: true, link:'https://fixedincome.fidelity.com/ftgw/fi/FILanding#tbannuities'}]
  return { taxdef, income, deferred };
});
