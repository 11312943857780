import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';

export const INITIAL_STATE = '/';

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};
//if the payload does not have the data that i'm returning, instead of returning the payload, just return state

export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
    if (!action.payload.route) {
        return state;
      } else{
        return action.payload.route
    }  
};

const ACTION_HANDLERS = {
  "@@router/LOCATION_CHANGE": routerLocationChanged,
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
