import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ResultsCardItemGroupRow from 'components/ResultsView/ResultsCardItemGroupRow';

const _renderResultsRows = data => {
  return _.map(data, ({ label, value }) => <ResultsCardItemGroupRow key={label} label={label} value={value} />);
};

const ResultsCardItemGroup = ({ label, data }) => {
  const resultsRows = _renderResultsRows(data);

  return (
    <div className="results-card-item-group">
      <h6>{label}</h6>
      {resultsRows}
    </div>
  );
};

ResultsCardItemGroup.propTypes = {
  /** The name of the card group */
  label: PropTypes.string.isRequired,

  /** The data associated with this card group */
  data: PropTypes.array.isRequired,
};

export default ResultsCardItemGroup;
