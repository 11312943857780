import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { icons } from 'utilities';
import ReactDOM from 'react-dom';
import _ from 'lodash';

class Modal extends Component {
  _handleOutsideClick = e => {
    if (e.target.className.trim() === 'modal-container') {
      e.preventDefault();
      e.stopPropagation();
      this.props.handleCloseModal();
    }
  };

  _handleCancel = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.handleCloseModal();
  };

  componentDidUpdate(prevProps) {
    if (this.props.modalRef && this.props.isOpen && !prevProps.isOpen) {
      this.props.modalRef.current.focus();
    }
  }

  render() {
    const modalRoot = document.getElementById('modal-root');

    const displayStyling = this.props.isOpen ? 'on' : 'off';

    const modal = (
      <div className={`modal ${displayStyling}`} onClick={this._handleOutsideClick}>
        <div className="modal-container" onClick={this._handleOutsideClick}>
          <div
            tabIndex="-1"
            ref={this.props.modalRef}
            className={`modal-content ${this.props.classNames}`}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                e.preventDefault();
                this.props.handleCloseModal();
              }
              if (e.key === ' ') e.preventDefault();
            }}
          >
            <div className="modal-header">
              {this.props.header ? <h3>{this.props.header}</h3> : null}
              <div className="close">
                <button
                  tabIndex="0"
                  style={{ backgroundImage: 'url(' + icons.greyX + ')' }}
                  aria-label="Close"
                  alt="Close Icon"
                  onClick={this._handleCancel}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      this.props.handleCloseModal();
                    }
                  }}
                />
              </div>
            </div>
            <div className="modal-body">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
    if (_.isNil(modalRoot)) {
      return modal;
    }
    return ReactDOM.createPortal(modal, modalRoot);
  }
}

Modal.defaultProps = {
  classNames: '',
};

Modal.propTypes = {
  /** Additional class names that are appended to the modal content div to help customize styling */
  classNames: PropTypes.string,

  /** Function that handles closing the modal */
  handleCloseModal: PropTypes.func.isRequired,

  /** Optional content to display at the top of the modal */
  header: PropTypes.string,

  isOpen: PropTypes.bool,

  modalRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

export default Modal;
