import React from 'react';
import PropTypes from 'prop-types';
import SearchBarContainer from 'containers/SearchBarContainer';

const setRemainingLabelsToSelected = (allLabels, filtersSelected) => {
  const updatedState = {};

  allLabels.forEach(label => {
    if (!filtersSelected.includes(label)) {
      updatedState[label] = true;
    }
  });

  return updatedState;
};

const setAllLabelsToUnselected = allLabels => {
  const updatedState = {};

  allLabels.forEach(label => {
    updatedState[label] = false;
  });

  return updatedState;
};

const handleCheckAll = (props, areAllSelected) => {
  const { allLabels, filtersSelected, onFiltersUpdated } = props;
  let updatedState = null;

  if (!areAllSelected) {
    updatedState = setRemainingLabelsToSelected(allLabels, filtersSelected);
  } else {
    updatedState = setAllLabelsToUnselected(allLabels);
  }

  onFiltersUpdated(updatedState);
};

const onSelectSuggestion = (suggestionValue, onFiltersUpdated) => {
  onFiltersUpdated({ [suggestionValue]: true });
};

const FundFamilySubheader = props => {
  const { allLabels, filtersSelected } = props;
  const areAllSelected = allLabels.length === filtersSelected.length;
  const checkedStateClassName = areAllSelected ? 'checked' : 'unchecked';

  return (
    <div className="fund-family-subheader">
      <SearchBarContainer
        suggestions={allLabels}
        disabledSuggestions={filtersSelected}
        placeholder="Search fund families..."
        onSelectSuggestion={suggestionValue => {
          onSelectSuggestion(suggestionValue, props.onFiltersUpdated);
        }}
      />
      <div className={`checkbox-input ${checkedStateClassName}`}>
        <label for="selectAll">
          <input
            type="checkbox"
            id="selectAll"
            name="selectAll"
            value="selectAll"
            onClick={() => {
              handleCheckAll(props, areAllSelected);
            }}
          />
          <svg
            class="checkbox"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <path class="checkmark" d="M 5,11 L 8,14 L 14, 6" />
          </svg>
        </label>
      </div>
      <span>Select All</span>
    </div>
  );
};

FundFamilySubheader.propTypes = {
  allLabels: PropTypes.array.isRequired,

  /** Array of strings representing the different fund families selected by the user */
  filtersSelected: PropTypes.array.isRequired,

  /** Redux action that updates store with diff of changed filter */
  onFiltersUpdated: PropTypes.func.isRequired,
};

export default FundFamilySubheader;
