import { createReducer } from 'reduxsauce';
import Types from '../actions/Types';
import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  FUND_ID_FIELD,
  FUND_IDS_FIELD,
  FUND_DATA_SUBJECT_AREA,
  FUND_NAME_FIELD,
  FUND_SYMBOL_FIELD,
} from 'configs/AppFeatureConfig';

const compareChartColors = ['#1962c8', '#79b3fd', '#b50000', '#d3cb4f', '#22767c'];

const _getFundData = rawInfo => {
  return {
    name: rawInfo[FUND_NAME_FIELD],
    symbol: rawInfo[FUND_SYMBOL_FIELD],
    ticker: rawInfo.ticker,
    fundCode: rawInfo.fundCode,
    cusip: rawInfo.cusip,
    [FUND_ID_FIELD]: rawInfo[FUND_ID_FIELD],
  };
};

// the initial state of this reducer
export const INITIAL_STATE = [];

export const updateFundsSelected = (state = INITIAL_STATE, action = {}) => {
  return [...action.fundsSelected];
};
export const getFundDataFromSearch = (state = INITIAL_STATE, action = {}) => {
  if (action.displayType !== 'compare') {
    return state;
  }
  let fundsSelected = [...state];
  const fundDataNeeded = _.some(fundsSelected, fund => {
    return _.isNil(fund.name);
  });
  const fundsReturned = action.payload.data.funds;
  const symbolsReturned = _.flatMap(fundsReturned, fund => {
    return _.get(fund, [FUND_DATA_SUBJECT_AREA, FUND_SYMBOL_FIELD], []);
  });
  if (fundDataNeeded) {
    fundsSelected = _.chain(fundsSelected)
      .map(selectedFund => {
        const currentFund = _.find(fundsReturned, fundData => {
          return _.get(fundData, [FUND_DATA_SUBJECT_AREA, FUND_ID_FIELD]) === selectedFund[FUND_ID_FIELD];
        });

        if (_.isNil(currentFund)) return null;
        const rawInfo = currentFund[FUND_DATA_SUBJECT_AREA];
        return _getFundData(rawInfo);
      })
      .filter(selectedFund => !_.isNil(selectedFund))
      .uniqBy(selectedFund => selectedFund[FUND_ID_FIELD])
      .value();
  }

  if (_.includes(symbolsReturned, action.symbolToAdd)) {
    const fundDataToAdd = _.find(fundsReturned, fund => {
      return fund[FUND_DATA_SUBJECT_AREA][FUND_SYMBOL_FIELD] === action.symbolToAdd;
    });
    const rawInfo = fundDataToAdd[FUND_DATA_SUBJECT_AREA];
    const addedFundInfo = _getFundData(rawInfo);
    return [...fundsSelected, addedFundInfo];
  }
  return fundsSelected;
};

export const clearAll = (state = INITIAL_STATE, action = {}) => {
  return INITIAL_STATE;
};
export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
  if (action.payload.location.state && action.payload.location.state.source === 'routerMiddleware') {
    return state;
  }
  if (action.payload.location.pathname === '/') {
    return clearAll(state, action);
  }
  if (action.payload.location.pathname.match("^/results")) {
    
    let fundsSelected = _.get(action, 'fundsSelected');
    const fundsRequested = _.get(action, ['payload', 'query', FUND_IDS_FIELD]);
    if (!_.isNil(fundsSelected) || !_.isNil(fundsRequested)) {
      let funds = !_.isNil(fundsSelected) ? fundsSelected.split(',') : fundsRequested.split(',');
      funds = _.filter(funds, fund => fund !== '');
      fundsSelected = _.map(funds, fund => {
        return {
          name: null,
          ticker: null,
          cusip: null,
          fundCode: null,
          [FUND_ID_FIELD]: fund,
        };
      });
      return fundsSelected;
    }
  }
  return state;
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.UPDATE_FUNDS_SELECTED]: updateFundsSelected,
  [Types.FUND_SEARCH_API_SUCCESS]: getFundDataFromSearch,
  [Types.CLEAR_ALL]: clearAll,
  [Types.UPDATE_PRICING_MODEL]: clearAll,
  "@@router/LOCATION_CHANGE": routerLocationChanged,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const getFundSelections = state => state.fundsSelected;
export const getFundTickers = state => state

export const getFundsSelected = createSelector([getFundSelections], fundSelections => {
  return _.map(fundSelections, (fund, idx) => {
    return { ...fund, color: compareChartColors[idx] };
  });
});

export const getCusipsSelected = createSelector([getFundSelections], fundSelections => {
  return _.map(fundSelections, fund => {
    return fund.cusip;
  });
});

export const getTickersSelected = createSelector([getFundSelections], fundSelections => {
  return _.map(fundSelections, fund => {
    return fund.ticker;
  });
});


export const getSelectedFundTickerIds = createSelector([getFundSelections], fundSelections => {
  return _.map(fundSelections, fund => {
    return fund[FUND_ID_FIELD];
  });
})
export const getSelectedFundIds = createSelector([getFundSelections], fundSelections => {
  return _.map(fundSelections, fund => {
    return fund[FUND_ID_FIELD];
  });
});
