import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import ActiveCriterionHeader from 'components/ActiveCriterion/ActiveCriterionHeader';
import ActiveCriterionDefinition from 'components/ActiveCriterion/ActiveCriterionDefinition';
import CheckboxInterface from 'components/ActiveCriterion/CheckboxInterface';
import FundFamilyInterface from 'components/ActiveCriterion/FundFamilyInterface';
import FundTypeInterface from 'components/ActiveCriterion/FundTypeInterface';
import MultiSelectInterface from 'components/ActiveCriterion/MultiSelectInterface';
import RadioButtonInterface from 'components/ActiveCriterion/RadioButtonInterface';
import { CRITERIA_SOURCE } from 'utilities/odaUtils';
import _ from 'lodash';

class ActiveCriterion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectionText: 'None selected',
    };
  }

  updateSelectionText = selectionText => {
    this.setState({
      selectionText,
    });
  };

  updateFilters = (filterUpdates, filterGroupId) => {
    if (_.isNil(filterGroupId)) {
      filterGroupId = this.props.criterion.id;
      filterUpdates = { [filterGroupId]: filterUpdates };
    }
    this.props.onFiltersUpdated(filterGroupId, filterUpdates);
  };

  updateRangeFilterInputs = filterUpdates => {
    this.props.onRangeFiltersUpdated(this.props.criterion.id, { [this.props.criterion.id]: filterUpdates });
  };

  removeCriterion = () => {
    const source = CRITERIA_SOURCE.CRITERIA_MENU;
    this.props.onCritRemoved(this.props.criterion.id, source);
  };

  toggleExpansion = () => {
    const expandedCrit = this.props.isExpanded ? 'none' : this.props.criterion.id;
    this.props.updateExpandedCrit(expandedCrit);
  };

  _getFilterListWithMatches = (filterGroups, filterMatches) => {
    return _.mapValues(filterGroups, (filterList, id) => {
      const matches = _.keyBy(filterMatches[id], 'criteria');

      //DODGE suppress
      // if (FEATURE_FLAGS.SUPPRESS_DODGE_AND_COX_FUNDS) {
      filterList = filterList.filter(function (item) {
        return item.label !== 'Dodge & Cox';
      });
      // }

      return _.map(filterList, (filter, index) => {
        const matchKey = filter.key || `${id}_${index + 1}`;
        const matchInfo = _.get(matches, matchKey, {});
        return { ...filter, ...matchInfo };
      });
    });
  };

  _renderActiveCriterionInterface = () => {
    const { filtersSelected, filterMatches } = this.props;
    const { criteriaType, id, formatType, filterGroups } = this.props.criterion;
    const filtersWithMatches = this._getFilterListWithMatches(filterGroups, filterMatches);

    switch (criteriaType) {
      case 'checkbox':
      case 'multiField': {
        return (
          <CheckboxInterface
            key={id}
            filters={filtersWithMatches[id] || []}
            filtersSelected={filtersSelected[id] || []}
            onFiltersUpdated={this.updateFilters}
          />
        );
      }
      case 'multiSelect': {
        return (
          <MultiSelectInterface
            key={id}
            filters={filtersWithMatches[id] || []}
            filtersSelected={filtersSelected[id] || []}
            formatType={formatType}
            onFiltersUpdated={this.updateFilters}
            onRangeFiltersUpdated={this.updateRangeFilterInputs}
          />
        );
      }
      case 'radioButton': {
        return (
          <RadioButtonInterface
            filters={filtersWithMatches[id] || []}
            filtersSelected={filtersSelected[id] || []}
            onFiltersUpdated={this.updateFilters}
          />
        );
      }
      case 'fundFamily': {
        return (
          <FundFamilyInterface
            filters={filtersWithMatches[id] || []}
            filtersSelected={filtersSelected[id] || []}
            criterion={this.props.criterion}
            onFiltersUpdated={this.updateFilters}
          />
        );
      }
      case 'fundType': {
        return (
          <FundTypeInterface
            filters={filtersWithMatches}
            filtersSelected={filtersSelected}
            criterion={this.props.criterion}
            onFiltersUpdated={this.updateFilters}
          />
        );
      }
      default: {
        return [];
      }
    }
  };
  _loadingClass = () => {
    const id = this.props.criterion.id;
    const loaderSizeClass =
      _.get(this, ['props', 'criterion', 'filterGroups', id, 'length'], 0) > 1 ? 'loading' : 'loading small-loading';
    const loadingClass = `${this.props.isLoading ? loaderSizeClass : ''}`;
    return loadingClass;
  };
  render() {
    const { totalMatches, isExpanded, filtersSelected } = this.props;
    const expandedStyling = isExpanded ? '' : 'off';

    const {
      label,
      sublabel,
      helpText,
      id,
      glossaryLink,
      criteriaType,
      image,
      criteriaSubGroupLabel,
      formatType,
      filterGroups,
    } = this.props.criterion;

    const activeCriterionInterface = this._renderActiveCriterionInterface();

    const activeCriterionDefinition = _.isEmpty(glossaryLink) ? null : (
      <ActiveCriterionDefinition className={expandedStyling} glossaryLink={glossaryLink} />
    );
    return (
      <div className={`active-criterion`}>
        <ActiveCriterionHeader
          isExpanded={isExpanded}
          label={label}
          sublabel={sublabel}
          helpText={helpText}
          id={id}
          subGroupLabel={criteriaSubGroupLabel}
          matches={totalMatches}
          criteriaType={criteriaType}
          filtersSelected={filtersSelected}
          filterGroups={filterGroups}
          formatType={formatType}
          removeCriterion={this.removeCriterion}
          toggleExpansion={this.toggleExpansion}
          icon={image}
          onFiltersUpdated={this.updateFilters}
        />
        <div className={`active-criterion-content ${expandedStyling} ${this._loadingClass()}`}>
          {activeCriterionDefinition}
          {activeCriterionInterface}
        </div>
      </div>
    );
  }
}

ActiveCriterion.propTypes = {
  /** Object defining the criterion */
  criterion: PropTypes.object.isRequired,
  onCritRemoved: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  updateExpandedCrit: PropTypes.func.isRequired,
  // filtersSelected: PropTypes.object.isRequired,
  onFiltersUpdated: PropTypes.func.isRequired,
  onRangeFiltersUpdated: PropTypes.func,
  /** Number of matches */
  totalMatches: PropTypes.number,
};

export default ActiveCriterion;
