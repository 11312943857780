import Types from 'actions/Types';
import { RESULT_FEATURES, FUND_ID_FIELD, FUND_IDS_FIELD, FUND_DATA_SUBJECT_AREA } from 'configs/AppFeatureConfig';
import { getRouterPersistentQueries } from 'reducers/selectors/RouterSelectors';
import { getBusinessChannel } from 'reducers/BusinessChannelReducer';
import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import _ from 'lodash';
import { loginUtils, sagaUtils } from 'utilities';
import { ENDPOINTS } from 'services/endpoints';
import {mockDataInjection} from "../tests/lib/mockDataSetInjection";

export default api => {
  function* validateCompareViewSearchResults(action, response) {
    const returnedFunds = _.get(response, 'data.funds', []);
    const returnedFundIds = _.flatMap(returnedFunds, fund => {
      return _.get(fund, [FUND_DATA_SUBJECT_AREA, FUND_ID_FIELD], []);
    });
    let requestedFundIds = _.get(action, ['searchParams', FUND_IDS_FIELD], null);
    requestedFundIds = !_.isNil(requestedFundIds) ? requestedFundIds.split(',') : [];
    const invalidSymbols = [];
    _.each(requestedFundIds, requestedFundId => {
      const currentFund = _.find(returnedFundIds, returnedFundId => {
        return returnedFundId === requestedFundId;
      });
      if (_.isNil(currentFund)) {
        invalidSymbols.push(action.symbolToAdd);
      }
    });
    if (_.get(action, ['searchParams', FUND_IDS_FIELD], '') === '') {
      yield put({ type: Types.UPDATE_FUNDS_SELECTED_ERROR, errorType: 'notEnoughFunds' });
    } else if (requestedFundIds.length > 5) {
      yield put({
        type: Types.UPDATE_FUNDS_SELECTED_ERROR,
        errorType: 'tooManyFunds',
      });
    } else if (invalidSymbols.length > 0) {
      yield put({
        type: Types.UPDATE_FUNDS_SELECTED_ERROR,
        errorType: 'invalidSymbols',
        errorData: { invalidSymbols },
      });
    } else {
      yield put({ type: Types.UPDATE_FUNDS_SELECTED_SUCCESS });
      if (!_.isNil(action.symbolToAdd)) {
        yield put({ type: Types.ADD_SYMBOL_SUCCESS, symbol: action.symbolToAdd });
      }
    }
  }

  function* fetchSearchResults(action, isFidelityFundsOnlySearch, isCompareView) {
    //TODO: add error handling for cases where subject areas are missing
    const persistentQueries = yield select(getRouterPersistentQueries);
    const subjectAreas = action.subjectAreas.join(',');
    const businessChannel = yield select(getBusinessChannel);
    const defaultSearchParams = {
      businessChannel,
      currentPageNumber: 1,
      noOfRowsPerPage: 100,
      sortBy: 'averageAnnualReturnsYear3',
      sortOrder: 'DESC',
      subjectAreaCode: subjectAreas,
    };

    let searchParams = action.searchParams || {};
    if (businessChannel === "RETAIL" && isCompareView) {
      searchParams.investmentTypeCode = "MFN,ETF"
    }
    if (action.policyCode || _.has(persistentQueries, 'product')) {
      searchParams.policyCode = action.policyCode || _.get(persistentQueries, 'product');
    }
    searchParams = sagaUtils.makeNTFParamsFromNtfTransFee(searchParams);
    const sortOrder = action.sortOrder ? action.sortOrder.toUpperCase() : null;
    const currentPageNumber = action.currentPageNumber && typeof action.currentPageNumber === "string" ? parseInt(action.currentPageNumber, 10) : action.currentPageNumber;
    const parameters = _.defaults(
      {},
      {
        searchFilter: searchParams,
        sortBy: action.sortBy,
        sortOrder: sortOrder,
        currentPageNumber: currentPageNumber,
      },
      defaultSearchParams,
    );
    const response = yield call(api.postData, ENDPOINTS.SEARCH_FUNDS, parameters);
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.SEARCH_FUNDS, parameters);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}
    if (response.ok) {
      let successPayload = { type: Types.FUND_SEARCH_API_SUCCESS, payload: response };
      if (action.symbolToAdd) {
        successPayload['symbolToAdd'] = action.symbolToAdd;
      }
      if (action.displayType) {
        successPayload['displayType'] = action.displayType;
      }
      yield put({ ...successPayload });

      if (isFidelityFundsOnlySearch || isCompareView) {
        yield put({
          type: Types.FIDELITY_FUND_SEARCH_API_SUCCESS,
          payload: { ...response, data: { funds: [] } },
        });
      }

      if (isCompareView) {
        yield call(validateCompareViewSearchResults, action, response);
      }
    } else {
      yield put({ type: Types.FUND_SEARCH_API_ERROR, payload: response });

      if (isFidelityFundsOnlySearch || isCompareView) {
        yield put({ type: Types.FIDELITY_FUND_SEARCH_API_ERROR, payload: response });
      }
    }
  }

  function* fetchFidelitySearchResults(action) {
    //TODO: add error handling for cases where subject areas are missing
    const persistentQueries = yield select(getRouterPersistentQueries);
    const subjectAreas = action.subjectAreas.join(',');
    const businessChannel = yield select(getBusinessChannel);
    const defaultSearchParams = {
      businessChannel,
      currentPageNumber: 1,
      noOfRowsPerPage: 3,
      searchFilter: { expenses: '5' },
      sortBy: 'legalName',
      sortOrder: 'ASC',
      subjectAreaCode: subjectAreas,
    };

    let searchParams = { ...action.searchParams, fidelityFundOnly: 'F' };
    const sortOrder = action.sortOrder ? action.sortOrder.toUpperCase() : null;
    if (action.policyCode || _.has(persistentQueries, 'product')) {
      searchParams.policyCode = action.policyCode || _.get(persistentQueries, 'product');
    }
    searchParams = sagaUtils.makeNTFParamsFromNtfTransFee(searchParams);

    const parameters = _.defaults(
      {},
      { searchFilter: { ...searchParams }, sortOrder: sortOrder, sortBy: action.sortBy },
      defaultSearchParams,
    );
    const response = yield call(api.postData, ENDPOINTS.SEARCH_FUNDS, parameters);
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.SEARCH_FUNDS, parameters);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}	
    if (response.ok) {
      yield put({ type: Types.FIDELITY_FUND_SEARCH_API_SUCCESS, payload: response });
    } else {
      yield put({ type: Types.FIDELITY_FUND_SEARCH_API_ERROR, payload: response });
    }
  }

  function* rootSaga(action) {
    // handle login expiration
    loginUtils.redirectToLoginIfSessionExpired();
    loginUtils.resetLoginExpiration();

    const isFidelityFundOnlySearch = _.get(action, 'searchParams.fidelityFundOnly');
    const isCompareView = _.get(action, 'displayType') === 'compare';

    if (isFidelityFundOnlySearch || isCompareView) {
      yield call(fetchSearchResults, action, isFidelityFundOnlySearch, isCompareView);
    } else if (RESULT_FEATURES.HAS_FIDELITY_FUNDS_TABLE) {
      yield all({
        results: call(fetchSearchResults, action),
        fidelityResults: call(fetchFidelitySearchResults, action),
      });
    } else {
      yield all({ results: call(fetchSearchResults, action) });
    }
  }
  function* watcher() {
    yield takeEvery(Types.FUND_SEARCH_API, rootSaga);
  }

  return {
    watcher,
    rootSaga,
    fetchFidelitySearchResults,
    fetchSearchResults,
  };
};
