import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MaskedInput from 'react-text-mask';
import Creators from 'actions/CriteriaActions';
import { getSelectedFundIds } from 'reducers/FundsSelectedReducer';
import { getUpdateFundsSelectedError } from 'reducers/ErrorReducer';
import { icons, popup } from 'utilities';

class AddSymbol extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputSymbol: '',
    };
  }
  errorTypes = {
    tooManyFunds: 'tooManyFunds',
    notEnoughFunds: 'notEnoughFunds',
    fundAlreadyAdded: 'fundAlreadyAdded',
    invalidSymbols: 'invalidSymbols',
  };
  _getErrorMessage = error => {
    const errorType = _.get(error, 'type', null);
    if (_.isNil(errorType)) return null;
    switch (errorType) {
      case 'tooManyFunds': {
        return (
          <span className="error-message">
            You may only compare up to five funds at once. To add this fund to your comparison, please delete one or
            more funds.
          </span>
        );
      }
      case 'notEnoughFunds': {
        return (
          <span className="error-message">
            You must have at least one fund selected. Please choose a symbol or use{' '}
            {this._renderLookupSymbolLink('symbol lookup tool')}.
          </span>
        );
      }
      case 'fundAlreadyAdded': {
        return (
          <span className="error-message">
            You have entered the symbol of a fund that you have already entered for comparison purposes. Please choose
            another symbol or use {this._renderLookupSymbolLink('symbol lookup tool')}.
          </span>
        );
      }
      case 'invalidSymbols': {
        const placeholder = 'selected funds';
        const invalidSymbols = _.get(error, 'data.invalidSymbols', [placeholder]).join(',');
        return (
          <span className="error-message">
            There are no matching results for {invalidSymbols}. Please try again or use our{' '}
            {this._renderLookupSymbolLink('symbol lookup tool')}.
          </span>
        );
      }
      default:
        return null;
    }
  };
  componentDidUpdate(prevProps) {
    const addedSymbols = _.difference(this.props.selectedFundIds, prevProps.selectedFundIds);
    if (_.includes(addedSymbols, this.state.inputSymbol)) {
      this.setState({ inputSymbol: '' });
    }
  }
  _setSymbolInput = e => {
    this.setState({
      inputSymbol: e.target.value,
    });
  };
  _handleKeyPress = e => {
    const keyPressed = e.keyCode || e.which;
    const enterKeyCode = 13;
    //if "enter" key is pressed call method to add symbol
    if (keyPressed === enterKeyCode) {
      this._handleAddSymbol();
    }
  };
  _renderInputSymbol = () => {
    return (
      <div className="input-symbol-container">
        <MaskedInput
          mask={[/[A-Z]/i, /[A-Z]/i, /[A-Z]/i, /[A-Z]/i, /[A-Z]/i]}
          className={this.props.hasError ? 'error' : null}
          guide={false}
          type="text"
          placeholder="Symbol"
          pipe={(conformedVal, config) => {
            return conformedVal.toUpperCase();
          }}
          value={this.state.inputSymbol}
          onChange={this._setSymbolInput}
          onKeyPress={this._handleKeyPress}
        />
      </div>
    );
  };
  _preRequestValidation = symbol => {
    const { selectedFundIds } = this.props;
    const numSelected = selectedFundIds.length;
    if (symbol === '') {
      return false;
    }

    if (_.includes(selectedFundIds, symbol)) {
      this.props.updateFundsSelectedError(this.errorTypes.fundAlreadyAdded);
      return false;
    }
    if (numSelected >= 5) {
      this.props.updateFundsSelectedError(this.errorTypes.tooManyFunds);
      return false;
    }

    return true;
  };
  _handleAddSymbol = () => {
    const symbolToAdd = this.state.inputSymbol;
    if (this._preRequestValidation(symbolToAdd)) {
      //dispatch action to look up symbol using search service
      this.props.addSymbol(symbolToAdd);
    }
  };
  _renderAddSymbolButton = () => {
    const className = this.props.hasError ? 'error add-symbol-button' : 'add-symbol-button';
    return (
      <div className="add-symbol-button-container">
        <button className={className} onClick={this._handleAddSymbol}>
          Add
        </button>
      </div>
    );
  };
  _openSymbolLookupWindow = () => {
    const symbolLookupUrl = 'https://quotes.fidelity.com/ftgw/fbc/ofquotes/webxpress/popup_lookup';
    popup.openPopup(symbolLookupUrl, 'symbolLookUpWindow');
  };
  _renderLookupSymbolLink = (text = 'Look Up Symbol') => {
    return (
      <button
        onClick={() => {
          this._openSymbolLookupWindow();
        }}
        className="look-up-symbol as-link"
      >
        {text}
      </button>
    );
  };
  _renderLookupSymbol = () => {
    return <div className="look-up-symbol-container">{this._renderLookupSymbolLink()}</div>;
  };
  _renderErrorMessage = () => {
    if (this.props.hasError) {
      return (
        <div className="error-container">
          <img className="error-icon" src={icons.error} alt="error" />
          <div className="error-text">{this._getErrorMessage(this.props.error)}</div>
        </div>
      );
    }
    return null;
  };
  // Removed
  // {this._renderLookupSymbol()}
  // from render list; may be readded in future when functionality is complete
  render() {
    return (
      <div className="add-symbol-container">
        {this._renderInputSymbol()}
        {this._renderAddSymbolButton()}
        {this._renderErrorMessage()}
      </div>
    );
  }
}

AddSymbol.propTypes = {
  //Array of fund primary keys that the user has selected
  selectedFundIds: PropTypes.array.isRequired,
  //Function that dispatches an ADD_SYMBOL action
  addSymbol: PropTypes.func.isRequired,
  //An error object with error type and data properties
  error: PropTypes.object,
  //A boolean indicating whether or not we have an error
  hasError: PropTypes.bool.isRequired,
};
const mapStateToProps = state => {
  const selectedFundIds = getSelectedFundIds(state);
  const error = getUpdateFundsSelectedError(state);
  const hasError = !_.isNil(error);
  return {
    selectedFundIds,
    error,
    hasError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addSymbol: symbol => {
      dispatch(Creators.addSymbol(symbol));
    },
    updateFundsSelectedError: (errorType, errorData = {}) => {
      dispatch(Creators.updateFundsSelectedError(errorType, errorData));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddSymbol);
