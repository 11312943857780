import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { icons } from 'utilities';
import { ROUTE_CONSTANTS, START_PAGE_TYPE } from 'configs/AppFeatureConfig';
import CriteriaSelectionMenuContainer from 'components/CriteriaSelectionMenu';
import ActiveCriteriaContainer from 'containers/ActiveCriteriaContainer';
import CriteriaMenuHeader from 'components/CriteriaMenu/CriteriaMenuHeader';

class CriteriaMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusCriteria: '',
    };
  }

  _isDisabled = () => {
    return this.props.displayType === 'compare' || (this.props.displayType === 'start' && START_PAGE_TYPE === 'fili');
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillMount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  };

  componentDidUpdate = prevProps => {
    if (
      (prevProps.match && prevProps.match.path !== this.props.match.path) ||
      prevProps.displayType !== this.props.displayType
    ) {
      let isCriteriaMenuExpanded = false;
      if (
        this.props.match.path !== ROUTE_CONSTANTS.INDEX &&
        this.props.match.path !== ROUTE_CONSTANTS.STRATEGIES &&
        this.props.match.path !== ROUTE_CONSTANTS.NESTED_STRATEGIES &&
        this.props.match.path !== ROUTE_CONSTANTS.PICKS &&
        this.props.match.path !== ROUTE_CONSTANTS.ANNUITIES &&
        this.props.match.path !== ROUTE_CONSTANTS.WAYS_TO_INVEST &&
        !this.props.isMobile &&
        !this._isDisabled()
      ) {
        isCriteriaMenuExpanded = true;
      }
      this.props.updateCriteriaMenuExpanded(isCriteriaMenuExpanded);
    }
  };

  handleScroll = () => {
    const criteriaMenu = document.getElementsByClassName('criteria-menu')[0];
    const mobileHeader = document.getElementsByClassName('mobile-header')[0];
    if (this.props.isMobile && mobileHeader !== undefined) {
      if (window.scrollY <= window.scrollY + mobileHeader.getBoundingClientRect().bottom) {
        criteriaMenu.setAttribute('style', 'position: sticky;');
      } else {
        criteriaMenu.setAttribute('style', 'position: fixed;');
      }
    } else {
      criteriaMenu.setAttribute('style', 'position: relative;');
    }
  };

  handleToggleCriteriaMenuDisplay = () => {
    if (!this._isDisabled()) {
      this.props.updateCriteriaMenuExpanded(!this.props.isCriteriaMenuExpanded);
      this.props.trackCriteriaMenuExpandCollapse(!this.props.isCriteriaMenuExpanded);
    }
  };

  handleOutsideMobileClick = e => {
    const targetClass = e.target.className.trim();

    if (targetClass.includes('criteria-menu') && this.props.isMobile && this.props.isCriteriaMenuExpanded) {
      this.props.updateCriteriaMenuExpanded(!this.props.isCriteriaMenuExpanded);
    }
  };

  setFocusCriteria = id => {
    this.setState({
      focusCriteria: id || '',
    });
  };

  render() {
    const { isCriteriaMenuExpanded, activeCriteria, onClearAll } = this.props;
    const openContentDisplay = isCriteriaMenuExpanded ? '' : 'off';
    const closedContentDisplay = isCriteriaMenuExpanded ? 'off' : '';
    const criteriaMenuDisplay = isCriteriaMenuExpanded ? 'expanded' : 'collapsed';
    const disabledClass = this._isDisabled() ? 'disabled-overlay' : '';
    const criteriaMenu = document.getElementsByClassName('criteria-menu')[0];
    if (criteriaMenu !== undefined) {
      this.handleScroll();
    }
    return (
      <div className={`criteria-menu ${disabledClass} ${criteriaMenuDisplay}`} onClick={this.handleOutsideMobileClick}>
        <CriteriaMenuHeader
          handleToggleCriteriaMenuDisplay={this.handleToggleCriteriaMenuDisplay}
          isCriteriaMenuOpen={isCriteriaMenuExpanded}
          activeCriteria={activeCriteria}
          onClearAll={onClearAll}
          isMobile={this.props.isMobile}
        />
        <div className={`open-criteria-menu-content ${openContentDisplay}`}>
          <ActiveCriteriaContainer setFocusCriteria={this.setFocusCriteria} focusCriteria={this.state.focusCriteria} />
          <CriteriaSelectionMenuContainer setFocusCriteria={this.setFocusCriteria} />
        </div>
        <div className={`closed-criteria-menu-content ${closedContentDisplay}`}>
          <img src={icons.arrowRight} alt="Arrow Right Icon" onClick={this.handleToggleCriteriaMenuDisplay} />
        </div>
      </div>
    );
  }
}

CriteriaMenu.propTypes = {
  /** The current route */
  match: PropTypes.object.isRequired,
  /** Dispatches action to track expanding/collapsing the criteria menu */
  trackCriteriaMenuExpandCollapse: PropTypes.func,
};
export default CriteriaMenu;
