import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import quoteFeed from './customQuoteFeed';
import Chart, { CIQ } from './Core';
import getConfig from './customConfig';
import Template from './Template';
export { CIQ };

import { getFundsSelected } from 'reducers/FundsSelectedReducer';

import benchmarks from './benchmarks.json';


class CompareChart extends Component {
  state = { chartHasError: false };

  constructor(props) {
    super(props);

		this.store = new CIQ.NameValueStore();
		this.config = CIQ.extend(getConfig(), props.config || {});
		
		this.state = {
			chart: new CIQ.UI.Chart(),
			stx: null,
			uiContext: null,
			chartInitializedCallback: props.chartInitialized,
			shortcutDialog: false,
			interval: 'day',
			asOfDate: null
		};
  }
  componentDidMount = () => {
  // ChartIQ Overrides
  // ------------------
  document.querySelector("cq-show-range").set = function(activator, multiplier, base, interval, period, timeUnit) 
  { 
    const {context} = this;
    if (context.loader) context.loader.show();
    const params = {
      multiplier,
      base,
      forceLoad: true,
    };
    if (interval) {
      params.periodicity = {
        interval,
        period: period || 1,
        timeUnit
      };
    }
    // Store span params in session
    sessionStorage.setItem("spanParams", JSON.stringify(params));

    context.stx.updateRangeMenu(params);

    context.stx.setSpan(params, () => {
      if (context.loader) context.loader.hide();
      delete params.padding;
      this.emitCustomEvent({
        effect: "select",
        detail: {
          params
        }
      });
      context.stx.setRange({
        dtLeft: context.stx.masterData[0]?.DT,
        dtRight: context.stx.masterData[context.stx.masterData.length - 1]?.DT,
        useExistingData: true
      });
    });
  };
  }

  componentDidUpdate = (prevProps) => {
    if(this.props.fundsSelected !== prevProps.fundsSelected) {
      var prev = prevProps?.fundsSelected?.map(fund => {return fund.ticker}).join(',');
      var next = this.props.fundsSelected?.map(fund => {return fund.ticker}).join(',');
      if(next !== prev) {
        this.updateFunds(prevProps.fundsSelected);
      }
    }
  }
  
	componentWillUnmount() {
		const { stx } = this.state;
		stx.destroy();
		stx.draw = () => {};
	}

  quoteFeedCallback = (symbol, urlObject, result) => {
	const data = JSON.parse(result);
	if( urlObject?.url?.includes('investments') && data?.[symbol]?.asOfDate) {
		const asOfDate = new Date(data[symbol].asOfDate).toLocaleDateString('en-US');
		this.setState({ asOfDate});
	}
  }
  buildChart = () => {
    const { stx } = this.state;

    stx.chart.yAxis.drawCurrentPriceLabel = false;
    stx.chart.allowScrollPast = false;
    stx.chart.allowScrollFuture = false;
    stx.controls.chartControls.style.display = 'none';
    stx.controls.chartControls = null;
    stx.dontRoll = true;
	  quoteFeed.callback = this.quoteFeedCallback;
    
    stx.attachQuoteFeed(quoteFeed,{
      refreshInterval: null,
      backgroundRefreshInterval: null
    });

    stx.addEventListener('periodicity', event => {
      if(event.differentData) {
		const interval = stx?.getPeriodicity()?.timeUnit;
		this.setState({ interval: interval });
        this.forceUpdate();
      }
    });

    stx.updateRangeMenu = (params) => {
      var selected = params.base === 'year' ? `${params.multiplier}Y` : 'YTD';
      var periods = document.querySelectorAll("cq-show-range div");
      periods.forEach(p => {
        if(selected === p.innerText){
          p.classList.add('active');
        } else {
          p.classList.remove('active');
        }
      })
    }
    // Initialize default chart span from session storage if possible,
    // else default 
    var spanParams;
    try { 
      spanParams = JSON.parse(sessionStorage.getItem("spanParams")); 
    } catch(e) { 
      spanParams = {
        multiplier: 1,
        base: "year",
        periodicity: {
          period: 1,
          interval: 1,
          timeUnit: "day"
        }
      };
    }

    this.updateFunds([], spanParams);

    stx.loadChart(null, {
      stretchToFillScreen: true,
      span: spanParams
    });
  }

  updateFunds = (prevFunds = [], spanParams = null) => {
    const { stx } = this.state;
    prevFunds.map(fund => {
      stx.removeSeries(fund.ticker);
    });
	benchmarks.map(benchmark => {
		stx.removeSeries(benchmark.id);
	});
    this.props.fundsSelected.map((fund, index) => {
      stx.addSeries(fund.ticker, {
      renderer: 'Lines', 
      color: fund.color,
      shareYAxis: true,
      permanent: true
      }, function() {
        if(index === 0) {
          if(spanParams) {
            stx.updateRangeMenu(spanParams);
          }
          stx.setRange({
            dtLeft: stx.chart.masterData[0]?.DT,
            dtRight: stx.chart.masterData[stx.chart.masterData.length - 1]?.DT
          });
        }
      });
    });
    benchmarks.map(benchmark => {
		stx.addSeries(benchmark.id, {
		display: benchmark.indexName,
		renderer: 'Lines', 
		color: benchmark.color,
		shareYAxis: true,
		permanent: true,
		disabled: true
		});
	});
  }

  postInit({ chartEngine, uiContext }) {
		if (this.props.chartIntialized) {
			this.props.chartIntialized({ chartEngine, uiContext });
		}
		this.setState({ stx: chartEngine, uiContext: uiContext });
		Object.assign(window, { stx: chartEngine, CIQ });
		this.buildChart();
	}

  getFrequencyLabel = () => {
	const { interval } = this.state;
    switch (interval) {
      case 'day':
        return 'Daily';
      case 'week':
        return 'Weekly';
      case 'month':
        return 'Monthly';
      case 'year':
        return 'Annually';
      default:
        return 'N/A';
    }
  };

	getAsOfDate = () => {
		const { asOfDate } = this.state;
		return asOfDate || '--';
	}

  render() {
	return (
    <Chart 
      config={this.config} 
      chartInitialized={this.postInit.bind(this)} 
      onChartReady={this.props.onChartReady}>
      <Template 
        getFrequency={this.getFrequencyLabel} 
        getAsOfDate={this.getAsOfDate} 
      />
    </Chart>
    );
  }
}
CompareChart.propTypes = {
  fundsSelected: PropTypes.array.isRequired,
};
const mapStateToProps = state => {
  const fundsSelected = getFundsSelected(state);
  return { fundsSelected };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareChart);