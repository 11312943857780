import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Autosuggest from 'react-autosuggest';
import ReactDOMServer from 'react-dom/server';

class SearchBar extends Component {
  constructor() {
    super();

    this.state = {
      value: '',
      suggestions: [],
    };
  }
  // '&' followed by 2 or more numbers/letters, and ending in a ';' -- ex: &sup2;
  specialCharPattern = new RegExp(/&(\d|\w){2,};/, 'g');

  onChange = (event, { newValue }) => {
    this.setState({ value: newValue });
  };

  getSuggestionValue = suggestion => suggestion;

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  onSuggestionSelected = (event, { suggestionValue }) => {
    if (!this.props.disabledSuggestions.includes(suggestionValue)) {
      this.props.onSelectSuggestion(suggestionValue);
    }

    this.setState({
      value: '',
    });
  };

  getSuggestions = value => {
    const { suggestions } = this.props;
    const parsedValue = this._parseInput(value);
    const inputLength = parsedValue.length;
    const regex = new RegExp(`\\b${parsedValue}`, 'i');

    return inputLength === 0 ? [] : suggestions.filter(suggestion => regex.test(suggestion));
  };

  _parseInput = value => {
    const trimmedValue = value.trim().toLowerCase();
    const escapedValue = trimmedValue.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

    return escapedValue;
  };

  _renderMatchAsBold = (fullStr, strToMatch) => {
    //does 'fullStr' contain substring that matches 'strToMatch'
    const containsQuery = fullStr.toLowerCase().includes(strToMatch.toLowerCase());
    //if it does, then add highlighting
    if (containsQuery) {
      const startHighlight = fullStr.toLowerCase().indexOf(strToMatch.toLowerCase());
      const endHighlight = startHighlight + strToMatch.length;
      const startText = fullStr.slice(0, startHighlight);
      const highlightedText = fullStr.slice(startHighlight, endHighlight);
      const endText = fullStr.slice(endHighlight, fullStr.length);
      return (
        <span>
          {startText}
          <strong>{highlightedText}</strong>
          {endText}
        </span>
      );
    }
    return <span>{fullStr}</span>;
  };

  renderSuggestion = (suggestion, { query }) => {
    const isDisabledSuggestion = this.props.disabledSuggestions.includes(suggestion);
    const suggestionStyling = isDisabledSuggestion ? 'disabled' : '';
    const hasSpecialChar = this.specialCharPattern.test(suggestion);

    if (!hasSpecialChar) {
      const suggestionText = this._renderMatchAsBold(suggestion, query);
      return <div className={`suggestion ${suggestionStyling}`}>{suggestionText}</div>;
    }

    const specialCharMatch = suggestion.match(this.specialCharPattern, 1);
    const splitOnSpecial = suggestion.split(_.first(specialCharMatch));

    const highlightedSubstrings = _.map(splitOnSpecial, subStr => {
      const highlightedSubstr = this._renderMatchAsBold(subStr, query);
      return ReactDOMServer.renderToStaticMarkup(highlightedSubstr);
    });

    //replace special character that was removed when we split the string
    const suggestionToRender = highlightedSubstrings.join(specialCharMatch[0]);
    return (
      <div className={`suggestion ${suggestionStyling}`}>
        <span dangerouslySetInnerHTML={{ __html: suggestionToRender }} />
      </div>
    );
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: this.props.placeholder || '',
      name: this.props.name || '',
      id: this.props.id || '',
      "aria-label": this.props.ariaLabel || '',
      value,
      onChange: this.onChange,
    };

    return (
      <div className="search-bar">
        <Autosuggest
          focusInputOnSuggestionClick={!this.props.isMobile}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    );
  }
}

SearchBar.propTypes = {
  /** Array of strings of all possible suggestions to populate the auto complete */
  suggestions: PropTypes.array.isRequired,

  /** Array of strings of disabled suggestions that should render but not be clickable */
  disabledSuggestions: PropTypes.array.isRequired,

  isMobile: PropTypes.bool.isRequired,

  /** Callback that is triggered when user makes a selection*/
  onSelectSuggestion: PropTypes.func.isRequired,

  /** Placeholder text for the search box input */
  placeholder: PropTypes.string,
};

export default SearchBar;
