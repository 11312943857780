import { createTypes } from 'reduxsauce';

export default createTypes(`
  API_REQUEST_RESOURCE
  API_RECEIVE_RESPONSE
  API_FAILURE

  FUND_CLASSIFICATION_API
  FUND_CLASSIFICATION_API_SUCCESS
  FUND_CLASSIFICATION_API_ERROR

  FUNDFAMILY_API
  FUNDFAMILY_API_SUCCESS
  FUNDFAMILY_API_ERROR
  
  POLICY_INFO_API
  POLICY_INFO_API_SUCCESS
  POLICY_INFO_API_ERROR

  ESGGROUPS_CLASSIFICATION_API
  ESGGROUPS_CLASSIFICATION_API_SUCCESS
  ESGGROUPS_CLASSIFICATION_API_ERROR

  CRITERIA_MATCHES_API
  CRITERIA_MATCHES_API_SUCCESS
  CRITERIA_MATCHES_API_ERROR

  TOTAL_MATCHES_API
  TOTAL_MATCHES_API_SUCCESS
  TOTAL_MATCHES_API_ERROR

  QUICK_CRITERIA_INITIAL_MATCHES_API
  QUICK_CRITERIA_MATCHES_API
  QUICK_CRITERIA_MATCHES_API_SUCCESS
  QUICK_CRITERIA_MATCHES_API_ERROR

  STRATEGY_MATCHES_API
  STRATEGY_MATCHES_API_SUCCESS
  STRATEGY_MATCHES_API_ERROR

  PRICING_MODEL_MATCHES_API
  PRICING_MODEL_MATCHES_API_SUCCESS
  PRICING_MODEL_MATCHES_API_ERROR

  FUND_SEARCH_API
  FUND_SEARCH_API_SUCCESS
  FUND_SEARCH_API_ERROR
  
  FIDELITY_FUND_SEARCH_API_SUCCESS
  FIDELITY_FUND_SEARCH_API_ERROR
  FIDELITY_MATCHES_API_SUCCESS
  FIDELITY_MATCHES_API_ERROR
  
  FUND_TYPE_API
  FUND_TYPE_API_SUCCESS
  FUND_TYPE_API_ERROR

  FUND_APPLICATION_DISCLOSURE_API
  FUND_APPLICATION_DISCLOSURE_API_SUCCESS
  FUND_APPLICATION_DISCLOSURE_API_ERROR

  DISCLOSURE_DISCLOSURES_API
  DISCLOSURE_DISCLOSURES_API_SUCCESS
  DISCLOSURE_DISCLOSURES_API_ERROR

  DISCLOSURE_LABEL_SUPPRESSIONS_API
  DISCLOSURE_LABEL_SUPPRESSIONS_API_SUCCESS
  DISCLOSURE_LABEL_SUPPRESSIONS_API_ERROR

  FUND_DATAVALUE_DISCLOSURE_API
  FUND_DATAVALUE_DISCLOSURE_API_SUCCESS
  FUND_DATAVALUE_DISCLOSURE_API_ERROR

  QUICK_CRITERIA_CANCEL_MODAL
  QUICK_CRITERIA_DISPLAY_MODAL
  QUICK_CRITERIA_HIDE_MODAL
  QUICK_CRITERIA_UPDATE_ACTIVE_FUND_TYPE
  QUICK_CRITERIA_UPDATE_ASSET_CLASS
  QUICK_CRITERIA_UPDATE_SELECTIONS
  QUICK_CRITERIA_UPDATE_MAD_LIBS_SELECTIONS
  QUICK_CRITERIA_UPDATE_MAD_LIBS_ADDITIONAL_CRITERIA

  UPDATE_RESULT_TOGGLE_GROUP

  SORT_RESULTS
  
  ADD_CRITERION
  UPDATE_CRITERIA
  REMOVE_CRITERION
  CLEAR_ALL
  UPDATE_EXPANDED_CRIT
  UPDATE_FILTERS
  UPDATE_RANGE_INPUTS

  UPDATE_VIEW_TAB
  UPDATE_RESULTS_PAGE_NUM

  SHOW_COMPARE_VIEW
  SHOW_FULL_RESULTS_VIEW
  ADD_SYMBOL
  ADD_SYMBOL_SUCCESS

  UPDATE_FUNDS_SELECTED
  UPDATE_FUNDS_SELECTED_SUCCESS
  UPDATE_FUNDS_SELECTED_ERROR

  UPDATE_MOBILE_STATUS
  UPDATE_CRITERIA_MENU_EXPANDED

  UPDATE_DISPLAY_TYPE
  SET_DISPLAY_TYPE
  
  CHANGE_START_PAGE_VIEW_TAB

  LOAD_STRATEGY
  VIEW_NESTED_STRATEGY
  
  ODA_INITIALIZE_TRACKING
  ODA_LOG_ACTION
  TRACK_ADOBE_ANALYTICS

  ROUTER_LOCATION_CHANGED

  FUND_PICKS_UPDATE_SELECTED_CATEGORY
  FUND_PICKS_UPDATE_SELECTED_GROUPS
  FUND_PICKS_FROM_FIDELITY_INITIAL_MATCHES_API
  FUND_PICKS_FROM_FIDELITY_MATCHES_API
  FUND_PICKS_FROM_FIDELITY_MATCHES_API_SUCCESS
  FUND_PICKS_FROM_FIDELITY_MATCHES_API_ERROR
  FUND_PICKS_FROM_FIDELITY_LOADING_API
  FUND_PICKS_FROM_FIDELITY_LOADING_API_SUCCESS
  FUND_PICKS_FROM_FIDELITY_LOADING_API_ERROR

  DOWNLOAD_RESULTS_API
  DOWNLOAD_RESULTS_API_SUCCESS
  DOWNLOAD_RESULTS_API_ERROR

  UPDATE_PRICING_MODEL
`);
