import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { icons } from 'utilities';
import { getStrategyData } from 'reducers/selectors/FundStrategiesSelectors';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getActiveCriteria } from 'reducers/ActiveCriteriaReducer';

class StrategyInfo extends Component {
  constructor() {
    super();
    this.state = {
      isExpanded: true,
      etfDescription:
        "<p>An exchange traded fund (ETF) is a type of security that involves a collection of securities that often tracks an underlying index, although they can invest in any number of industry sectors or use various strategies. ETFs are in many ways similar to mutual funds; however, they are listed on exchanges and ETF shares trade throughout the day just like ordinary stock.</p><p>For more detailed information, including intraday pricing, please visit the <a href='https://screener.fidelity.com/ftgw/etf/evaluator/gotoBL/research#/home'>ETF Screener</a>.</p>",
    };
  }

  _handleToggleExpansion = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  render() {
    const { isExpanded } = this.state;
    const { strategy, displayType, activeCriteria } = this.props;
    const etfIsActive = activeCriteria.includes('investmentTypeCode');

    if ((_.isNil(strategy) || displayType === 'compare') && !etfIsActive) return null;

    const expanderText = isExpanded ? 'Hide Details' : 'Show Details';
    const expanderIconSrc = isExpanded ? icons.arrowDown : icons.arrowRight;
    const strategyIconSrc = etfIsActive ? icons['etfIconLarge'] : icons[strategy.icon];
    const strategyIcon = !_.isNil(strategyIconSrc) ? (
      <img src={strategyIconSrc} alt="Icon representing this fund strategy" />
    ) : null;
    const strategyInfoStyle = isExpanded ? 'expanded' : 'collapsed';
    const strategyDescriptionStyle = isExpanded ? '' : 'off';
    const description = etfIsActive ? this.state.etfDescription : strategy.description;
    return (
      <div className={`strategy-info ${strategyInfoStyle}`}>
        <div className="expander-toggle-button" onClick={() => this._handleToggleExpansion()}>
          <img src={expanderIconSrc} alt="Icon to expand or collapse strategies description" />
          <button className="as-link" aria-expanded={isExpanded}>{expanderText}</button>
        </div>
        <div className={`strategy-description ${strategyDescriptionStyle}`}>
          {strategyIcon}
          <div className="strategy-description-text" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    );
  }
}

StrategyInfo.propTypes = {
  /** The current way of displaying results. Can be card, table, or compare */
  displayType: PropTypes.string.isRequired,

  /** Object representing the currently loaded strategy, if any */
  strategy: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    strategy: getStrategyData(state),
    displayType: getDisplayType(state),
    activeCriteria: getActiveCriteria(state),
  };
};

const StrategyInfoContainer = connect(mapStateToProps, null)(StrategyInfo);

export { StrategyInfo };
export default StrategyInfoContainer;
