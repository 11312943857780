import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';
import { createSelector } from 'reselect';
import _ from 'lodash';
import Types from 'actions/Types';
import { getViewTab } from 'reducers/ViewTabReducer';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getCurrentViewTabsByShortName } from 'reducers/selectors/ViewTabSelectors';
import { APP_DISCLOSURE_CODE_PREFIX, ROUTE_CONSTANTS } from 'configs/AppFeatureConfig';

export const INITIAL_STATE = [];

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};

const updateApplicationDisclosures = (state = INITIAL_STATE, action = {}) => {
  return [...action.payload.data];
};

const ACTION_HANDLERS = {
  [Types.FUND_APPLICATION_DISCLOSURE_API_SUCCESS]: updateApplicationDisclosures,
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

// ---- Selectors ----
const getAlldisclosureDisclosures = state => state.disclosureDisclosures;
const getAllApplicationDisclosures = state => state.applicationDisclosures;
const getAllESGGroups = state => state.esgGroupsReplacements;
const route = state => state.route;

const getDisclosureObjList = (disclosures, code) => {
  return _.find(disclosures, disclosureObj => {
    return disclosureObj.code === code;
  });
};

const findDisclosureByCode = (disclosures, disclosureCategoryCode, disclosureCode) => {
  const disclosureObjList = getDisclosureObjList(disclosures, disclosureCategoryCode);
  return _.find(_.get(disclosureObjList, 'disclosureList'), disclosure => {
    return disclosure.code === disclosureCode;
  });
};

const filterDisclosuresByPlacement = (disclosures, placement) => {
  return _.filter(disclosures, d => {
    return _.get(d, 'placement') === placement;
  });
};

export const getPageDisclosures = createSelector(
  [route, getAllApplicationDisclosures, getDisplayType, getCurrentViewTabsByShortName, getViewTab],
  (route, disclosures, displayType, currentViewTabsByShortName, viewTab) => {
    const { INDEX, STRATEGIES, NESTED_STRATEGIES, PICKS, WAYS_TO_INVEST } = ROUTE_CONSTANTS;
    const validLandingPages = [INDEX, STRATEGIES, PICKS, NESTED_STRATEGIES, WAYS_TO_INVEST];
    const isLanding = validLandingPages.includes(route);
    const resultsPrepend = displayType === 'compare' ? 'C' : 'R';
    const getPageDisclosureCode = () => {
      // Landing Page
      switch (route) {
        case INDEX:
          return `${APP_DISCLOSURE_CODE_PREFIX}LAND`;
        case STRATEGIES:
        case NESTED_STRATEGIES:
          return `${APP_DISCLOSURE_CODE_PREFIX}STRATEGIES`;
        case PICKS:
          return `${APP_DISCLOSURE_CODE_PREFIX}FUNDPK`;
        case WAYS_TO_INVEST:
          return `${APP_DISCLOSURE_CODE_PREFIX}WAYTOINV`;
        default:
          return `${APP_DISCLOSURE_CODE_PREFIX}${resultsPrepend}ALL`;
      }
    };

    let pageDisclObjList, tabDisclObjList;

    // Page Disclosures
    pageDisclObjList = getDisclosureObjList(disclosures, getPageDisclosureCode());
    // Tab Disclosures
    if (displayType === 'card' && !isLanding) {
      tabDisclObjList = getDisclosureObjList(disclosures, `${APP_DISCLOSURE_CODE_PREFIX}MOBILECARD`);
    } else if (!isLanding) {
      let disclosureCode = _.get(currentViewTabsByShortName, [viewTab, 'disclosureCode']);
      if (displayType === 'compare' && viewTab === 'overview' && APP_DISCLOSURE_CODE_PREFIX !== 'FILI') {
        disclosureCode = 'PERF';
      }
      tabDisclObjList = getDisclosureObjList(disclosures, APP_DISCLOSURE_CODE_PREFIX + resultsPrepend + disclosureCode);
    }

    // Combine and filter arrays
    const disclosuresObjList = _.concat(
      [],
      _.get(tabDisclObjList, 'disclosureList'),
      _.get(pageDisclObjList, 'disclosureList'),
    );
    return disclosuresObjList;
  },
);

export const makeGetPageDisclosuresTextByPlacement = placement => {
  return createSelector([getPageDisclosures], pageDisclosures => {
    const expandablePageDisclosures = filterDisclosuresByPlacement(pageDisclosures, placement);
    let disclosuresText = '';
    _.forEach(expandablePageDisclosures, disclosureObj => {
      disclosuresText += `<p>${_.get(disclosureObj, 'text')}</p>`;
    });
    return disclosuresText;
  });
};

export const getExpandablePageDisclosuresText = makeGetPageDisclosuresTextByPlacement('Section Footer');

export const getNonExpandablePageDisclosuresText = makeGetPageDisclosuresTextByPlacement('Section Footer - Non Ex');

export const getAfterExpandablePageDisclosuresText = makeGetPageDisclosuresTextByPlacement('Section Footer - After Ex');

export const getLearnMoreDisclosureContent = createSelector([getAlldisclosureDisclosures], disclosureDisclosures => {

  const Natural_lang_1 =  _.get(disclosureDisclosures, 'Natural_lang_1[0].text', 'Undefined');
  const Natural_lang_2 =  _.get(disclosureDisclosures, 'Natural_lang_2[0].text', 'Undefined');
  const Natural_lang_3 =  _.get(disclosureDisclosures, 'Natural_lang_3[0].text', 'Undefined');
  const Natural_lang_4 =  _.get(disclosureDisclosures, 'Natural_lang_4[0].text', 'Undefined');

  return Natural_lang_1+Natural_lang_2+Natural_lang_3+Natural_lang_4;
});

const addBrBr = (text)=>{
   const brbr = '<br><br>';
   if (text && text.length > brbr.length ) {
	  const substr = text.substr(text.length - brbr.length, brbr.length);
	  if (substr !== brbr) {
		return text + brbr;
	  }
   }
   return text;
}

export const getSustainableInvestmentDisclosureContent = createSelector([getAlldisclosureDisclosures, getAllESGGroups], (disclosureDisclosures, esgGroups) => {
    
	if (!esgGroups.items || !esgGroups.items[0]) { 
		return '';
	}
  const sustainableInvestmentList = esgGroups.items[0];
  const disclosures = {
	RESE: 'ESG_Resource Security[0].text',
	CLAC: 'ESG_Climate Action[0].text',
	BANE: 'ESG_Basic Needs[0].text',
	HEEC: 'ESG_Healthy Ecosystems[0].text',
	HUDE: 'ESG_Human Development[0].text',
	GEES: 'ESG_General ESG[0].text'
  }

  let disclousure = _.get(disclosureDisclosures, 'ESG_SustainableInvst[0].text', '');
  if(sustainableInvestmentList && sustainableInvestmentList.length > 0) {
		sustainableInvestmentList.forEach(item => {
			const keyText = disclosures[item.code];
			if (keyText) {
				const text = _.get(disclosureDisclosures, keyText, '');
				if (text) {
					disclousure += addBrBr(text);
				}
			}
		});
  }
  return disclousure;
});

export const getEmploysExclusionsDisclosureContent = createSelector([getAlldisclosureDisclosures, getAllESGGroups], (disclosureDisclosures, esgGroups) => {

	if (!esgGroups.items || !esgGroups.items[1]) { 
		return '';
	}

	const employExclusionsList = esgGroups.items[1];
	const disclosures = {
	  NBSC: 'ESG_NormsBasedScreening[0].text',
	  POIL: 'ESG_ExcludePalmOil[0].text',
	  COAL: 'ESG_ExcludeThermalCoal[0].text',
	  FSPL: 'ESG_ExcludeFur[0].text',
	  OTHR: 'ESG_ExcludeOther[0].text',
	  ATST: 'ESG_ExcludeAnimalTest[0].text',
	  GMOS: 'ESG_ExcludeGMO[0].text',
	  ADET: 'ESG_ExcludeAdultEntertain[0].text',
	  MICO: 'ESG_ExcludeMilitary[0].text',
	  CWPN: 'ESG_ContWeapons[0].text',
	  ABSC: 'ESG_ExcludeAbortions[0].text',
	  SARM: 'ESG_ExcludeSmallArm[0].text',
	  ALHL: 'ESG_ExcludeAlcohol[0].text',
	  GMBL: 'ESG_ExcludeGambling[0].text',
	  PEST: 'ESG_ExcludePesticide[0].text',
	  FOFU: 'ESG_Fossil Fuel[0].text',
	  TBCO: 'ESG_ExcludeTobacco[0].text',
	  NCLR: 'ESG_ExcludeNuclear[0].text'
	}
  
	let disclousure = _.get(disclosureDisclosures, 'ESG_EmploysExclusion[0].text', '');
	if(employExclusionsList && employExclusionsList.length > 0) {
		employExclusionsList.forEach(item => {
			  const keyText = disclosures[item.code];
			  if (keyText) {
				  const text = _.get(disclosureDisclosures, keyText, '');
				  if (text) {
					  disclousure += addBrBr(text);
				  }
			  }
		  });
	}
	return disclousure;
});

export const getTableDisclosureContent = createSelector(
  [getAllApplicationDisclosures, getDisplayType],
  (applicationDisclosures, displayType) => {
    const resultsPrepend = displayType === 'compare' ? 'C' : 'R';

    const fundStatusDisclosure = findDisclosureByCode(
      applicationDisclosures,
      `${APP_DISCLOSURE_CODE_PREFIX}${resultsPrepend}ALL`,
      `${APP_DISCLOSURE_CODE_PREFIX} Fund Status Disclosure`,
    );

    return _.get(fundStatusDisclosure, 'text');
  },
);
