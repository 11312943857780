import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Creators from 'actions/CriteriaActions';
import { ROUTE_CONSTANTS } from 'configs/AppFeatureConfig';
import { getStartPageActiveViewTab } from 'reducers/StartPageViewTabReducer';
import { getIsMobile } from 'reducers/MobileStatusReducer';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getRouterRoute } from 'reducers/selectors/RouterSelectors';
import { START_PAGE_VIEW_TAB_SOURCE } from 'utilities/odaUtils';
import WaysToInvest from 'components/AnnuitiesStartPage/WaysToInvest';
import AnnuityOptions from 'components/AnnuitiesStartPage/AnnuityOptions';

class AnnuitiesStartPage extends Component {
  _renderContainer = () => {
    switch (this.props.match.path) {
      case ROUTE_CONSTANTS.INDEX:
        return <AnnuityOptions />;
      case ROUTE_CONSTANTS.WAYS_TO_INVEST:
        return <WaysToInvest />;
      default:
        return null;
    }
  };

  render() {
    const { activeTab } = this.props;

    const annuityOptionsTabStyle = activeTab === 'annuityOptions' ? 'active' : 'inactive';
    const waysToInvestTabStyle = activeTab === 'waysToInvest' ? 'active' : 'inactive';

    let activeTabSource = null;

    switch (activeTab) {
      case 'annuityOptions':
        activeTabSource = START_PAGE_VIEW_TAB_SOURCE.ANNUITY_OPTIONS;
        break;
      case 'waysToInvest':
        activeTabSource = START_PAGE_VIEW_TAB_SOURCE.WAYS_TO_INVEST;
        break;
      default:
        activeTabSource = null;
        break;
    }

    return (
      <div className="landing-page-container">
        <div className="start-page">
          <div className="view-tabs row">
            <div
              className={`col-xs-6 annuity-options-tab ${annuityOptionsTabStyle}`}
              onClick={() => this.props.changeStartPageViewTab('annuityOptions', activeTabSource)}
            >
              <button className="as-link">Annuity Options</button>
            </div>
            <div
              className={`col-xs-6 ways-to-invest-tab ${waysToInvestTabStyle}`}
              onClick={() => this.props.changeStartPageViewTab('waysToInvest', activeTabSource)}
            >
              <button className="as-link">Ways to Invest</button>
            </div>
          </div>
          {this._renderContainer()}
        </div>
      </div>
    );
  }
}

AnnuitiesStartPage.propTypes = {
  /** The current active start page view tab */
  activeTab: PropTypes.string.isRequired,

  /** Redux action to change the view tab on the start page */
  changeStartPageViewTab: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    activeTab: getStartPageActiveViewTab(state),
    isMobile: getIsMobile(state),
    displayType: getDisplayType(state),
    route: getRouterRoute(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeStartPageViewTab: (viewTab, source) => {
      dispatch(Creators.changeStartPageViewTab(viewTab, source));
    },
  };
};

const AnnuitiesStartContainer = connect(mapStateToProps, mapDispatchToProps)(AnnuitiesStartPage);

export default AnnuitiesStartContainer;
export { AnnuitiesStartPage };
