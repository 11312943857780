import _ from 'lodash';
import sorting from 'utilities/sorting';

export const getCurrentActiveCrit = (store, critId) => {
  return _.find(store.getState().activeCriteria, critObj => {
    return _.first(_.keys(critObj)) === critId;
  });
};

export const getFilterGroupsFromQueryObj = (query, filterGroupIds, whiteList) => {
  const filteredCriteria = _.pickBy(query, (value, key) => {
    if (whiteList.includes(key.toLowerCase())) return true;
    return _.includes(filterGroupIds, key);
  });
  return filteredCriteria;
};

export const getCriterionDefinition = (criteriaDefinitions, critId) => {
  return criteriaDefinitions[critId];
};

export const getDefaultFilterGroupSelectionValues = defaultFilterGroupSelections => {
  return _.mapValues(defaultFilterGroupSelections, filterGroupSelections => {
    return _.map(filterGroupSelections, 'value');
  });
};

export const getDefaultFilterValues = (allCriteria, criteriaIdsByFilterGroup, critId) => {
  const defaultFilterGroupSelections = _.get(allCriteria, [critId, 'defaultFilterGroupSelections']);
  return getDefaultFilterGroupSelectionValues(defaultFilterGroupSelections);
};

// Translates filter updates ({label:isChecked} -> ex: {"high expenses":true}) to array of labels for checked filters (["high expenses"])
export const getUpdatedFilterSelections = (currentSelections, filterUpdates, filterGroupId) => {
  let filterGroupSelections = _.mapValues(currentSelections, selections => {
    return _.transform(selections, (result, selection) => (result[selection] = true), {});
  });
  _.each(filterUpdates, (groupUpdates, filterGroupId) => {
    if (_.isNil(groupUpdates)) {
      delete filterGroupSelections[filterGroupId];
    } else if (_.isEmpty(groupUpdates)) {
      filterGroupSelections[filterGroupId] = {};
    } else {
      filterGroupSelections[filterGroupId] = _.get(filterGroupSelections, [filterGroupId], {});
      _.each(groupUpdates, (isChecked, label) => {
        filterGroupSelections[filterGroupId][label] = isChecked;
      });
    }
  });
  return _.mapValues(filterGroupSelections, selections => {
    return _.transform(
      selections,
      (result, isChecked, label) => {
        if (isChecked) result.push(label);
      },
      [],
    );
  });
};
export const getNestedFilters = filterList => {
  return _.flatMap(filterList, assetClassItem => {
    const categoryFiltersWithAssetClass = _.map(assetClassItem.categories, category => {
      return { ...category, assetClassValue: assetClassItem.value };
    });
    return categoryFiltersWithAssetClass;
  });
};
export const getFiltersByVal = (filterList, val) => {
  return _.filter(filterList, filterObj => {
    return filterObj.value === val;
  });
};

export const getFilterByLabel = (filterList, label) => {
  return _.find(filterList, filterObj => {
    return filterObj.label === label;
  });
};

export const filterLabelToValue = (filterList, label, criteriaType) => {
  const filterObj = getFilterByLabel(filterList, label);
  switch (criteriaType) {
    case 'multiSelect':
      return {
        min: filterObj.min,
        max: filterObj.max,
      };
    default:
      return filterObj.value;
  }
};

export const makeServiceValueToFilterMap = filterList => {
  const serviceValueToFilterMap = {};
  _.each(filterList, filterObj => {
    serviceValueToFilterMap[filterObj.value] = filterObj;
  });
  return serviceValueToFilterMap;
};

export const makeFilterLabelsToFilterMap = filterList => {
  const filterLabelsToFilterMap = {};
  _.each(filterList, filterObj => {
    filterLabelsToFilterMap[filterObj.label] = filterObj;
  });
  return filterLabelsToFilterMap;
};

export const getMultiSelectCheckedFilters = (minVal, maxVal, filterList) => {
  //find endpoints
  const maxFilterIndex = _.findIndex(filterList, filterObj => {
    if (maxVal === 'GT') return filterObj.max === null;

    return !_.isNil(filterObj.max) && _.toNumber(filterObj.max) === _.toNumber(maxVal);
  });
  const minFilterIndex = _.findIndex(filterList, filterObj => {
    if (minVal === 'LS') return filterObj.min === null;

    return !_.isNil(filterObj.min) && _.toNumber(filterObj.min) === _.toNumber(minVal);
  });

  const foundNoMatches = maxFilterIndex < 0 || minFilterIndex < 0;
  const minFilterMaxVal = _.toNumber(_.get(filterList, [minFilterIndex, 'max'], Infinity));
  const maxFilterMinVal = _.toNumber(_.get(filterList, [maxFilterIndex, 'min'], -Infinity));

  // if minVal === maxVal there's a chance we select two filters because we matched the inner bounds
  // e.g minVal = 1, maxVal = 1, and we have two filters with ranges [0, 1] and [1, 3]
  // we wrongfully selected these filters because [0, 1] got matched with maxVal = 1 and [1, 3] with minVal = 1
  // in such cases no filters should be selected so we return null
  const didMatchWrongMinAndMax = minFilterMaxVal > maxFilterMinVal && minFilterIndex !== maxFilterIndex;
  if (foundNoMatches || didMatchWrongMinAndMax) {
    return null;
  }
  const startIdx = Math.min(minFilterIndex, maxFilterIndex);
  const endIdx = Math.max(minFilterIndex, maxFilterIndex);
  const filtersChecked = _.slice([...filterList], startIdx, endIdx + 1);
  return filtersChecked;
};

export const mapServiceValsToFilterLabels = (filterGroupSelections, critDef, filterGroupId) => {
  const criteriaType = critDef.criteriaType;

  switch (criteriaType) {
    case 'multiSelect': {
      const filterList = critDef.filterGroups[filterGroupId];
      const currentSelections = filterGroupSelections[filterGroupId];
      const minVal = _.first(currentSelections);
      const maxVal = _.last(currentSelections);
      const filtersChecked = getMultiSelectCheckedFilters(minVal, maxVal, filterList);

      if (_.isNil(filtersChecked) || _.isEmpty(filtersChecked)) {
        return { [filterGroupId]: currentSelections || [] };
      }

      //return labels of endpoints and filters in between
      return { [filterGroupId]: _.map(filtersChecked, 'label') || [] };
    }
    case 'fundType': {
      return _.mapValues(filterGroupSelections, (serviceValues, filterGroupId) => {
        const filterList =
          filterGroupId === 'category'
            ? getNestedFilters(critDef.filterGroups.assetClass)
            : critDef.filterGroups[filterGroupId];
        const serviceValueToFilterMap = makeServiceValueToFilterMap(filterList);

        return _.map(serviceValues, serviceValue => {
          return _.get(serviceValueToFilterMap, [serviceValue, 'label']);
        });
      });
    }
    default: {
      const filterList = critDef.filterGroups[filterGroupId];
      const serviceValueToFilterMap = makeServiceValueToFilterMap(filterList);
      return {
        [filterGroupId]: _.map(filterGroupSelections[filterGroupId], serviceValue => {
          return _.get(serviceValueToFilterMap, [serviceValue, 'label']);
        }),
      };
    }
  }
};

//Given a list of category codes, return an array of asset class codes that those categories belong to
export const mapCategoryValsToAssetClassVals = (categories, critDef) => {
  const filterList = getNestedFilters(critDef.filterGroups.category);
  const assetClassCodes = _.chain(categories)
    .flatMap(category => {
      const filters = getFiltersByVal(filterList, category);
      const assetClassValue = _.map(filters, 'assetClassValue');
      return assetClassValue;
    })
    .uniq()
    .value();
  return assetClassCodes;
};

export const mapFiltersToServiceVals = (
  criteriaDefinitions,
  critId,
  currentSelections,
  filterUpdates,
  filterGroupId,
) => {
  const critDef = getCriterionDefinition(criteriaDefinitions, critId);
  const criteriaType = critDef.criteriaType;
  const currentFilters = mapServiceValsToFilterLabels(currentSelections, critDef, filterGroupId);
  const filterGroupSelections = getUpdatedFilterSelections(currentFilters, filterUpdates, filterGroupId);

  switch (criteriaType) {
    case 'multiSelect':
      const filterList = critDef.filterGroups[critId];
      const checkedFilters = _.filter(filterList, filter =>
        filterGroupSelections[filterGroupId].includes(filter.label),
      );
      const singleFilter = _.first(checkedFilters);

      if (checkedFilters.length === 1 && !_.isNil(singleFilter.min) && !_.isNil(singleFilter.max)) {
        return { [critId]: [singleFilter.min, singleFilter.max] };
      }

      // Sort checked filters from lowest to highest
      const orderedFilters = checkedFilters.sort(sorting.multiSelectFilterListSort);

      const firstFilter = _.first(orderedFilters);
      const lastFilter = _.last(orderedFilters);
      let min = (firstFilter && firstFilter.min) || null;
      let max = (lastFilter && lastFilter.max) || null;

      if (_.isNil(min)) min = 'LS';
      if (_.isNil(max)) max = 'GT';

      return { [critId]: [min, max] };
    case 'fundType':
      return _.mapValues(filterGroupSelections, (serviceValues, filterGroupId) => {
        const filterList =
          filterGroupId === 'category'
            ? getNestedFilters(critDef.filterGroups.assetClass)
            : critDef.filterGroups[filterGroupId];
        return _.map(serviceValues, selection => {
          return filterLabelToValue(filterList, selection, criteriaType);
        });
      });
    default:
      return _.mapValues(filterGroupSelections, (serviceValues, filterGroupId) => {
        return _.map(serviceValues, selection => {
          return filterLabelToValue(critDef.filterGroups[filterGroupId], selection, criteriaType);
        });
      });
  }
};

export const buildDistinctCritIdArray = (...args) => {
  const critIdSet = new Set();

  args.forEach(arg => {
    if (_.isArray(arg)) {
      arg.forEach(id => critIdSet.add(id));
    } else {
      critIdSet.add(arg);
    }
  });

  return Array.from(critIdSet);
};

export const traverseNestedCriteria = (criteriaMenuItemList, callback, parents = []) => {
  return _.forEach(criteriaMenuItemList, menuItem => {
    if (menuItem.criteriaMenuItemType === 'group') {
      const criteriaMenuItemList = _.get(menuItem, 'criteriaMenuItemList');
      traverseNestedCriteria(criteriaMenuItemList, callback, [...parents, menuItem]);
    } else {
      callback(menuItem, parents);
    }
  });
};
