import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { sorting } from 'utilities';
import FundPicksPopup from './FundPicksPopup';
import Button from 'components/Shared/Button';
import FundPicksGroupPopup from './FundPicksGroupPopup';
import { CRITERIA_SOURCE } from 'utilities/odaUtils';

class FundPicksSelector extends Component {
  constructor() {
    super();
    this.categoryPopup = React.createRef();
    this.groupPopup = React.createRef();

    this.state = {
      isCategoryPopupOpen: false,
      isGroupPopupOpen: false,
    };
  }

  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
    document.removeEventListener('touchstart', this.handleOutsideClick, false);
  };

  handleOutsideClick = e => {
    e.stopPropagation();
    const categoryPopup = ReactDOM.findDOMNode(this.categoryPopup.current);
    const groupPopup = ReactDOM.findDOMNode(this.groupPopup.current);
    const isModalOpen = this._checkIfModalIsOpen();
    const wasCategoryPopupClicked = categoryPopup && categoryPopup.contains(e.target);
    const wasGroupPopupClicked = groupPopup && groupPopup.contains(e.target);
    if (!wasCategoryPopupClicked && !wasGroupPopupClicked && !isModalOpen) {
      this.setState({
        isCategoryPopupOpen: false,
        isGroupPopupOpen: false,
      });

      document.removeEventListener('mousedown', this.handleOutsideClick, false);
      document.removeEventListener('touchstart', this.handleOutsideClick, false);
    }
  };

  handleViewResults = e => {
    e.stopPropagation();
    const { critIdArray, filterGroupSelections } = this.props.parsedSelections;
    const source = CRITERIA_SOURCE.PICKS;
    this.props.viewResults(critIdArray, filterGroupSelections, source);
  };

  handleChangeActiveCategory = newActiveCategory => {
    if (newActiveCategory === 'All Asset Classes') {
      newActiveCategory = 'All Categories';
    }
    this.props.handleChangeActiveCategory(newActiveCategory);
    this.setState({ isCategoryPopupOpen: false });

    document.removeEventListener('mousedown', this.handleOutsideClick, false);
    document.removeEventListener('touchstart', this.handleOutsideClick, false);
  };
  handleSelectCategoryGroup = newActiveGroup => {
    if (newActiveGroup === 'All Categories') {
      newActiveGroup = 'All Groups';
    }
    this.props.handleSelectCategoryGroup(newActiveGroup);
  };

  _buildGroupSelectionText = () => {
    const { selectedGroups, availableGroups, allGroupsChecked } = this.props;
    if (selectedGroups.length === availableGroups.length || allGroupsChecked) return 'All Categories';
    else if (selectedGroups.length === 1) return '1 Category Selected';
    else if (selectedGroups.length === 0) return '0 Category Selected';
    else return `${selectedGroups.length} Categories Selected`;
  };

  _countGroupSelections = () => {
    const { selectedGroups, availableGroups, allGroupsChecked } = this.props;
    if (selectedGroups.length === availableGroups.length || allGroupsChecked) return 999;
    return selectedGroups.length;
  };

  _disableViewResults = () => {
    const { allGroupsChecked, selectedGroups } = this.props;
    return !allGroupsChecked && selectedGroups.length < 1;
  };

  _checkIfModalIsOpen = () => {
    const modalRoot = document.getElementById('modal-root');
    let isModalOpen = false;

    _.each(modalRoot.childNodes, node => {
      if (node.classList.contains('on')) isModalOpen = true;
    });

    return isModalOpen;
  };

  _openCategorySelection = () => {
    this.setState({
      isCategoryPopupOpen: true,
      isGroupPopupOpen: false,
    });

    document.addEventListener('mousedown', this.handleOutsideClick, false);
    document.addEventListener('touchstart', this.handleOutsideClick, false);
  };

  _openGroupSelection = () => {
    this.setState({
      isCategoryPopupOpen: false,
      isGroupPopupOpen: true,
    });

    document.addEventListener('mousedown', this.handleOutsideClick, false);
    document.addEventListener('touchstart', this.handleOutsideClick, false);
  };

  _getCategoryFilters = () => {
    let filterList = _.get(this.props, 'activeGroups', []);
    filterList['All Categories'] = { label: 'All Categories', value: '' };
    const sortedCategoryFilters = filterList.sort(sorting.alphabetSortByProperty('label'));
    return sortedCategoryFilters;
  };

  _renderGroupDropdown = categorySelectionText => {
    if (this.props.selectedCategory === 'All Categories') {
      return (
        <div className="category-dropdown disabled">
          <span>Select Category</span>
          <div className="category-options dropdown-arrows">All Categories</div>
        </div>
      );
    }

    return (
      <div className="category-dropdown">
        <span>Select Category</span>
        <div className="category-options dropdown-arrows" onClick={this._openGroupSelection}>
          {categorySelectionText}
        </div>
        <FundPicksGroupPopup
          ref={this.groupPopup}
          selectedGroups={this.props.selectedGroups}
          options={this.props.availableGroups}
          handleSelectCategoryGroup={this.handleSelectCategoryGroup}
          isOpen={this.state.isGroupPopupOpen}
          allGroupsChecked={this.props.allGroupsChecked}
        />
      </div>
    );
  };

  _renderCategoryDropdown = categories => {
    let categories2 = _.cloneDeep(categories);
    if (categories2['All Categories']) {
      let category = categories2['All Categories'];
      delete categories2['All Categories'];
      category.label = 'All Asset Classes';
      categories2['All Asset Classes'] = category;
    }
    return (
      <div className="asset-class-dropdown">
        <span>Select Asset Class</span>
        <div className="class-options dropdown-arrows" onClick={this._openCategorySelection}>
          {this.props.selectedCategory === 'All Categories' ? 'All Asset Classes' : this.props.selectedCategory}
        </div>

        <FundPicksPopup
          ref={this.categoryPopup}
          activeChoice={this.props.selectedCategory}
          categories={categories2}
          handleChangeActiveClass={this.handleChangeActiveCategory}
          isOpen={this.state.isCategoryPopupOpen}
        />
      </div>
    );
  };

  render() {
    const groupSelectionText = this._buildGroupSelectionText();
    const groupDropdown = this._renderGroupDropdown(groupSelectionText);
    const categoryDropdown = this._renderCategoryDropdown(this.props.categories);
    const isFundPicksLoading = this.props.isFundPicksLoading;
    const isFundPickError = this.props.isFundPickError;
    const fundPicksMatches = this.props.fundPicksMatches;
    const disabled =
      this._disableViewResults() || isFundPicksLoading || fundPicksMatches === '0' || isFundPickError !== null;
    const viewResultCount = fundPicksMatches === '0' ? 'No Matching Funds' : 'View ' + fundPicksMatches + ' Funds';
    const viewResults2 = isFundPicksLoading || this._countGroupSelections() === 0 ? 'View -- Funds' : viewResultCount;
    const viewResults = isFundPickError == null ? viewResults2 : 'API Service Error';
    return (
      <div className="quick-criteria-fund-type fund-picks-selection">
        <div className="large-header">Search Fund Picks</div>
        <div className={`category-criterion`}>
          {categoryDropdown}
          {groupDropdown}
          <Button text={viewResults} onClick={this.handleViewResults} disabled={disabled} />
        </div>
      </div>
    );
  }
}

FundPicksSelector.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  selectedGroups: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  availableGroups: PropTypes.array,
  categories: PropTypes.object,
  handleChangeActiveCategory: PropTypes.func.isRequired,
  handleSelectCategoryGroup: PropTypes.func.isRequired,
  isFundPicksLoading: PropTypes.bool,
  isFundPickError: PropTypes.object,
  fundPicksMatches: PropTypes.string,
};

export default FundPicksSelector;
