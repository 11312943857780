import React from 'react';
import PropTypes from 'prop-types';

const _renderSections = ({ activeSection, validSections, selectedSections, onSelectSection }) => {
  const sections = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    '#',
  ];

  return sections.map(section => {
    let cssClasses = 'as-link';
    let handleClick = onSelectSection;

    if (activeSection === section) {
      cssClasses += ' active';
    }

    if (!validSections.includes(section)) {
      cssClasses += ' inactive';
      handleClick = () => {};
    }

    if (selectedSections.includes(section)) {
      cssClasses += ' selected';
    }

    return (
      <button key={section} className={cssClasses.trim()} onClick={() => handleClick(section)}>
        {section}
      </button>
    );
  });
};

const AlphabetSelector = props => {
  const sections = _renderSections(props);

  return (
    <div className="alphabet-selector">
      <div className="sections">{sections}</div>
    </div>
  );
};

AlphabetSelector.propTypes = {
  activeSection: PropTypes.string.isRequired,

  validSections: PropTypes.array.isRequired,

  selectedSections: PropTypes.array.isRequired,

  onSelectSection: PropTypes.func.isRequired,
};

export default AlphabetSelector;
