import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckboxFilter from 'components/ActiveCriterion/CheckboxFilter';

class CheckboxInterface extends Component {
  constructor(props) {
    super(props);
    this.filterCount = props.filters.length;
    this.state = { checkedFilters: [] };
  }

  handleClickCheckbox = (label, isChecked) => {
    this.props.onFiltersUpdated({ [label]: isChecked });
  };

  _renderAllFilters = filters => {
    return filters.map((filter, index) => {
      const isChecked = this.props.filtersSelected.includes(filter.label);
      return (
        <CheckboxFilter
          key={filter.label}
          index={index}
          isChecked={isChecked}
          filter={filter}
          handleClick={this.handleClickCheckbox}
        />
      );
    });
  };

  render() {
    const { filters } = this.props;
    const allFilters = this._renderAllFilters(filters);

    return <ul tabIndex={0} className="checkbox-interface">{allFilters}</ul>;
  }
}

CheckboxInterface.propTypes = {
  filters: PropTypes.array.isRequired,
  filtersSelected: PropTypes.array,
  onFiltersUpdated: PropTypes.func.isRequired,
};

export default CheckboxInterface;
