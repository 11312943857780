import { createReducer } from 'reduxsauce';
import Types from '../actions/Types';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { getCurrentViewTabsByShortName } from 'reducers/selectors/ViewTabSelectors';
import { RESULT_CONFIG } from 'configs/ResultConfig';
import { VIEW_TABS_BY_DISPLAY_TYPE } from 'configs/ViewTabConfig';
import { getSearchResults, getFidelitySearchResults } from 'reducers/FundSearchResultsReducer';

// the initial state of this reducer
export const INITIAL_STATE = 'start';

export const updateViewTab = (state = INITIAL_STATE, action = {}) => {
  return !_.isNil(action.viewTabShortName) ? action.viewTabShortName : state;
};

export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
  if (action.payload.location.pathname === '/') {
    return INITIAL_STATE;
  } else if (
    !_.get(action, ['payload', 'params', 'viewTab'])
  ) {
    return state;
  }
  return action.payload.params.viewTab;
};

export const handleDisplayTypeUpdate = (state = INITIAL_STATE, action = {}) => {
  if (!_.isNil(action.viewTabShortName)) {
    return action.viewTabShortName;
  }
  return _.get(VIEW_TABS_BY_DISPLAY_TYPE, action.displayType, [state])[0];
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.UPDATE_VIEW_TAB]: updateViewTab,
  [Types.LOAD_STRATEGY]: updateViewTab,
  [Types.SHOW_COMPARE_VIEW]: handleDisplayTypeUpdate,
  [Types.SHOW_FULL_RESULTS_VIEW]: handleDisplayTypeUpdate,
  [Types.UPDATE_DISPLAY_TYPE]: handleDisplayTypeUpdate,
  [Types.SET_DISPLAY_TYPE]: handleDisplayTypeUpdate,
  "@@router/LOCATION_CHANGE": routerLocationChanged,
  [Types.UPDATE_MOBILE_STATUS]: updateViewTab,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const getViewTab = state => state.viewTab;

const checkSaas = (id, results) => {
  let columnId;

  switch (id) {
    case 'yr1':
      columnId = 'year1';
      break;
    case 'yr3':
      columnId = 'year3';
      break;
    case 'yr5':
      columnId = 'year5';
      break;
    case 'yr10':
      columnId = 'year10';
      break;
    case 'lifeOfFund':
      columnId = 'fiasCLifePct';
      break;
    case 'lifeOfSubaccount':
      columnId = 'life';
      break;
    default:
      columnId = null;
      break;
  }

  return results.some(result => {
    const path = _.get(result, ['monthlyPerformance', 'averageAnnualReturns']);
    
    if (path) {
      if (columnId === 'fiasCLifePct') {
        return !_.isEmpty(path[columnId]);
      }
      if (_.get(path, 'saas')) {
        return !_.isEmpty(path['saas'][columnId]);
      }
    }
    return false;
  });
}

export const getColumnsForViewTab = createSelector(
  [getViewTab, getCurrentViewTabsByShortName, getSearchResults, getFidelitySearchResults],
  (viewTab, currentViewTabsByShortName, searchResults, fidelitySearchResults) => {
    const columnLabels = _.get(currentViewTabsByShortName, [viewTab, 'viewTabCols']);
    const allResults = _.concat(searchResults, fidelitySearchResults);

    const columnData = _.map(columnLabels, colLabel => {
      const resultConfigForCol = RESULT_CONFIG[colLabel];
      const { id, resultType, label, sortField, columnGroup, align, beforeAfter } = resultConfigForCol;
      let currentColInfo = {
        id,
        resultType,
        label,
        sortField,
        columnGroup,
        align,
        beforeAfter
      };

      const hasAfter = checkSaas(id, allResults);
      let updatedLabel;
      
      if (beforeAfter && hasAfter) {
        updatedLabel = `${label} (Before/After)`;
      } else {
        updatedLabel = label;
      }
      
      if (resultType === 'resultToggleGroup') {
        currentColInfo = {
          ...currentColInfo,
          resultList: resultConfigForCol.resultList,
          shortName: resultConfigForCol.shortName,
        };
      }
      if (resultConfigForCol.headerIcon) {
        currentColInfo['headerIcon'] = resultConfigForCol.headerIcon;
      }
      if (resultConfigForCol.disclosureIndicator) {
        currentColInfo['disclosureIndicator'] = resultConfigForCol.disclosureIndicator;
      }
      currentColInfo['label'] = updatedLabel;
      return currentColInfo;
    });
    return columnData;
  },
);
