import { createReducer } from 'reduxsauce';
import _ from 'lodash';
import { createSelector } from 'reselect';
import Types from '../actions/Types';
import { RUNNING_ENVIRONMENT } from '../configs/AppFeatureConfig';
import iconMap from '../utilities/icons';

// the initial state of this reducer
export const INITIAL_STATE = [];


const riskImagesUrl=[
 { id: 'BAL',  local:iconMap.assetAllocation , prod:'/bin-public/060_www_fidelity_com/images/mutual-funds/risk-images/Allocation.png'},
 { id: 'ALT',  local:iconMap.assetAlternative, prod:'/bin-public/060_www_fidelity_com/images/mutual-funds/risk-images/Alternative.png'},
 { id: 'COMM', local:iconMap.assetCommodities, prod:'/bin-public/060_www_fidelity_com/images/mutual-funds/risk-images/Commodities.png'},
 { id: 'ISTK', local:iconMap.assetInternationalEquity, prod:'/bin-public/060_www_fidelity_com/images/mutual-funds/risk-images/International Equity.png'},
 { id: 'MM',   local:iconMap.assetMoneyMarket, prod:'/bin-public/060_www_fidelity_com/images/mutual-funds/risk-images/Money Market.png'},
 { id: 'MBND', local:iconMap.assetMunicipalBond, prod:'/bin-public/060_www_fidelity_com/images/mutual-funds/risk-images/Municipal Bond.png'},
 { id: 'NTEQ', local:iconMap.assetNonTraditionalEquity , prod:'/bin-public/060_www_fidelity_com/images/mutual-funds/risk-images/NonTraditional Equity.png'},
 { id: 'SSTK', local:iconMap.assetSectorEquity, prod:'/bin-public/060_www_fidelity_com/images/mutual-funds/risk-images/Sector Equity.png'},
 { id: 'TBND', local:iconMap.assetTaxableBond, prod:'/bin-public/060_www_fidelity_com/images/mutual-funds/risk-images/Taxable Bond.png'},
 { id: 'DSTK', local:iconMap.assetUSEquity, prod:'/bin-public/060_www_fidelity_com/images/mutual-funds/risk-images/U.S Equity.png'},
 { id: 'MISC', local:iconMap.assetMisc, prod:'/bin-public/060_www_fidelity_com/images/mutual-funds/risk-images/Misc.png'},
];

const riskImagesBaseUrl=[
  'https://www.fidelity.com', //production
  'https://wwwxq1.fidelity.com', // ac1
  'https://wwwxq2.fidelity.com', // ac2
  '', // else,  localhost.
];

const fundTypeApiSuccess = (state = INITIAL_STATE, action = {}) => {
  const fundTypeData = _.map(action.payload.data, assetClassItem => {
    const categoryData = _.map(assetClassItem.categories, categoryItem => {
      return {
        label: categoryItem.description,
        value: categoryItem.code,
        key: categoryItem.code,
        riskRatingOverall: categoryItem.riskRatingOverall,
        asOfDate: categoryItem.asOfDate,
      };
    });
    let riskItem = riskImagesUrl.find(e=>e.id === assetClassItem.code);
    let localImage = true;
    let url = riskItem? riskItem.local : '';
    let riskImageUrl="";
    if (RUNNING_ENVIRONMENT && RUNNING_ENVIRONMENT.IsProduction) {
      localImage = false;
      url = riskItem? riskItem.prod : '';
      riskImageUrl = riskImagesBaseUrl[0]+url;
    } else if (RUNNING_ENVIRONMENT && RUNNING_ENVIRONMENT.IsAcceptance) {
      localImage = false;
      url = riskItem? riskItem.prod : '';
      riskImageUrl = riskImagesBaseUrl[1]+url;
    } else if (RUNNING_ENVIRONMENT && RUNNING_ENVIRONMENT.IsAcceptance2) {
      localImage = false;
      url = riskItem? riskItem.prod : '';
      riskImageUrl = riskImagesBaseUrl[2]+url;
    }
    return {
      label: assetClassItem.description,
      value: assetClassItem.code,
      key: assetClassItem.code,
      categories: categoryData,
      riskImageUrl: localImage? url : riskImageUrl
    };
  });
  return [...fundTypeData];
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.FUND_TYPE_API_SUCCESS]: fundTypeApiSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const getFundTypeFilters = state => state.fundTypeFilters;

export const getAssetClassCodesByCategoryCode = createSelector([getFundTypeFilters], fundTypeFilters => {
  return _.transform(
    fundTypeFilters,
    (result, assetClass) => {
      _.forEach(assetClass.categories, category => {
        result[category.value] = assetClass.value;
      });
    },
    {},
  );
});


export const getFidelityFundPicks = createSelector([getFundTypeFilters], fundSelections => {
  let fundMap = {};
  fundSelections.forEach(fund => {
      fundMap[fund.label] = {
        label: fund.label,
        value: fund.value,
        groups: fund.categories,
      };
  });
  fundMap['All Categories'] = {
    label: 'All Categories',
    value: 'ALL_CATEGORIES',
    groups: [],
  };
  return fundMap;
});
