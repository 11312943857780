import { createReducer } from 'reduxsauce';
import Types from '../actions/Types';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { criteriaUtils } from 'utilities';
import { getCriteriaDefinitions } from 'reducers/selectors/CriteriaSelectors';

// the initial state of this reducer
export const INITIAL_STATE = {};

export const addCriterion = (state = INITIAL_STATE, action = {}) => {
  return { ...state, ...action.filterGroupSelections };
};

export const updateCriteria = (state = INITIAL_STATE, action = {}) => {
  return { ...action.filterGroupSelections };
};

export const removeCriterion = (state = INITIAL_STATE, action = {}) => {
  let nextState = {...state};
  Object.keys(action.filterGroups).forEach(f =>{
    if (nextState.hasOwnProperty(f)){
      delete nextState[f];
    }
  });
  return nextState;
};

export const clearAll = (state = INITIAL_STATE, action = {}) => {
  return INITIAL_STATE;
};

export const updateFilters = (state = INITIAL_STATE, action = {}) => {
  const added = _.assign({}, state, action.filterGroupSelections);
  return _.omitBy(added, (filters, filterGroupId) => _.isNull(_.get(action, ['filterUpdates', filterGroupId])));
};

export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
  //if route update came from middleware, then no need to update state again
  if (action.type!=="@@router/LOCATION_CHANGE" && action.payload.location.state && action.payload.location.state.source === 'routerMiddleware') {
    return state;
  }
  if (action.payload.location.pathname === '/') {
    return clearAll(state, action);
  }
  if (action.payload.location.pathname.match("^/results")) {
    const filterGroupSelections = _.chain(action.filterGroupSelections)
      .mapValues(qsVal => {
        const formatqsVal = qsVal === 'MFN,ETF'? qsVal.split(' ') : qsVal.split(',')
        return qsVal.length === 0 ? [] : formatqsVal; // array of current filters
      })
      .pickBy((filterVals, crit) => {
        return crit !== 'tickers' && crit !== 'fundCodes'; // remove "tickers" because this is not an actual crit
      })
      .value();
    let newAction = {};
    newAction.critIdArray = _.keys(action.payload.query);
    newAction.filterGroupSelections = filterGroupSelections;
    return updateCriteria(state, newAction);
  }
  return state;
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.ADD_CRITERION]: addCriterion,
  [Types.UPDATE_CRITERIA]: updateCriteria,
  [Types.LOAD_STRATEGY]: updateCriteria,
  [Types.REMOVE_CRITERION]: removeCriterion,
  [Types.UPDATE_FILTERS]: updateFilters,
  [Types.UPDATE_RANGE_INPUTS]: updateFilters,
  [Types.CLEAR_ALL]: clearAll,
  "@@router/LOCATION_CHANGE": routerLocationChanged,

};

export default createReducer(INITIAL_STATE, HANDLERS);

const getCritId = (state, props) => props.criterion.id;
export const getAllCriteriaFilters = state => state.criteriaFilters;
const getAllESGGroups = state => state.esgGroupsReplacements;

export const getFilterSelectionsForCriterion = createSelector(
  [getCritId, getAllCriteriaFilters, getCriteriaDefinitions, getAllESGGroups],
  (critId, allCritFilters, criteriaDefinitions, esgGroups) => {
    const critDef = criteriaUtils.getCriterionDefinition(criteriaDefinitions, critId);
    const critFilters = _.pickBy(allCritFilters, (vals, id) => _.has(critDef.filterGroups, id));
	const socAttrs = esgGroups.socAttrs;
	if (socAttrs && socAttrs[critId] && criteriaDefinitions[critId] && critDef && critDef.filterGroups[critId]) {
		critDef.filterGroups[critId] = socAttrs[critId];
	}
    return criteriaUtils.mapServiceValsToFilterLabels(critFilters, critDef, critId);
  }
);
