import { connect } from 'react-redux';
import SearchBar from 'components/Shared/SearchBar';
import { getIsMobile } from 'reducers/MobileStatusReducer';

const mapStateToProps = state => {
  return {
    isMobile: getIsMobile(state),
  };
};

const SearchBarContainer = connect(mapStateToProps, null)(SearchBar);

export default SearchBarContainer;
