import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router'; // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import 'styles/index.scss';
import configureStore from './configureStore';
import EvaluatorContainer from 'components/Evaluator/EvaluatorContainer';
import screenerConfig from 'screenerConfig';
import { ROUTE_FEATURES } from 'configs/AppFeatureConfig';
import { createBrowserHistory } from 'history';

const determineBasePath = () => {
  let basepath = screenerConfig.urlConfig.routerBasePath;
  if (process.env.NODE_ENV === 'development') {
    basepath = '';
  }
  return basepath;
};

const history = createBrowserHistory({
  basename: determineBasePath(),
});
const rootElement = document.getElementById('root');
const store = configureStore(history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>  
        <Switch>
          <Route exact path={ROUTE_FEATURES.ROUTE_CONSTANTS.INDEX} component={EvaluatorContainer} />
          <Route exact path={ROUTE_FEATURES.ROUTE_CONSTANTS.PICKS} component={EvaluatorContainer} />
          <Route path={ROUTE_FEATURES.ROUTE_CONSTANTS.RESULTS} component={EvaluatorContainer} />
          <Route exact path={ROUTE_FEATURES.ROUTE_CONSTANTS.STRATEGIES} component={EvaluatorContainer} />
          <Route exact path={ROUTE_FEATURES.ROUTE_CONSTANTS.NESTED_STRATEGIES} component={EvaluatorContainer} />
          <Route exact path={ROUTE_FEATURES.ROUTE_CONSTANTS.WAYS_TO_INVEST} component={EvaluatorContainer} />
        </Switch>     
    </ConnectedRouter>
  </Provider>,
  rootElement,
);
