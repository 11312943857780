import _ from 'lodash';
import _waq from 'odaTracking';
import { ODA_CATEGORIES, START_PAGE_VIEW_TAB_SOURCE, getODACategory } from 'utilities/odaUtils';
import { RESULT_FEATURES, COMPARE_FEATURES, START_PAGE_TYPE } from 'configs/AppFeatureConfig';

const isValidActionTrackingData = ({ category = null, name = null, label = null, value = null }) => {
  // category and name are required fields
  const hasRequiredData = !_.isNil(category) && !_.isNil(name);
  // if we have a value field but not a label field, we're skipping one hierarchical level (category > name > label > value)
  // other possible missing intermediate fields would be category and name but hasRequiredData will check this for us
  const isMissingIntermediateData = !_.isNil(value) && _.isNil(label);
  return hasRequiredData && !isMissingIntermediateData;
};

export const logAction = ({ category = null, name = null, label = null, value = null }) => {
  let trackingData = _.omitBy({ category, name, label, value }, _.isNil);
  if (!isValidActionTrackingData(trackingData)) {
    trackingData = { category: 'Tracking Failed' };
    console.error('Action tracking failed');
  }
  _waq.push(['_trackAction', trackingData]);
  console.log('_waq _trackAction', trackingData);
};

export const logPageView = () => {
  const title = document.title;
  _waq.push(['_trackPageView', { category: title }]);
  console.log('_waq _trackPageView', { category: title });
};

// Results ODA actions

export const getResultsCompareFundsTrackingData = tickers => {
  const category = ODA_CATEGORIES.RESULTS;
  const name = RESULT_FEATURES.COMPARE_BUTTON_LABEL;
  const label = tickers.sort().toString();
  return { category, name, label };
};

// Compare ODA actions

export const getCompareRemoveFundTrackingData = ticker => {
  const category = ODA_CATEGORIES.COMPARE;
  const name = 'Remove Fund';
  const label = ticker;
  return { category, name, label };
};

export const getCompareAddFundTrackingData = ticker => {
  const category = ODA_CATEGORIES.COMPARE;
  const name = 'Add Fund';
  const label = ticker;
  return { category, name, label };
};

export const getCompareBackToResearchResultsTrackingData = () => {
  const category = ODA_CATEGORIES.COMPARE;
  const name = COMPARE_FEATURES.BACK_BUTTON_LABEL;
  return { category, name };
};

// Result Cards ODA actions

export const getResultCardsDetailsTrackingData = (expanded, fundId) => {
  const category = ODA_CATEGORIES.RESULTS_CARDS;
  const name = 'Details';
  const label = expanded ? 'Expand' : 'Collapse';
  const value = fundId;
  return { category, name, label, value };
};

export const getResultCardsSortMenuTrackingData = expanded => {
  const category = ODA_CATEGORIES.RESULTS_CARDS;
  const name = 'Sort Menu';
  const label = expanded ? 'Expand' : 'Collapse';
  return { category, name, label };
};

// Start Page ODA Actions

export const getStartPageViewTabTrackingData = viewTab => {
  const category = ODA_CATEGORIES.START_PAGE;
  const name = 'View Tab';
  const label = viewTab;
  return { category, name, label };
};

// Criteria Menu ODA Actions

export const getCriteriaMenuAddCriterionTrackingData = critId => {
  const category = ODA_CATEGORIES.CRITERIA_MENU;
  const name = 'Add Criterion';
  const label = critId;
  return { category, name, label };
};

export const getCriteriaMenuSearchCriterionTrackingData = critId => {
  const category = ODA_CATEGORIES.CRITERIA_MENU;
  const name = 'Search Criterion';
  const label = critId;
  return { category, name, label };
};

export const getCriteriaMenuGroupCriteriaTrackingData = (critGroup, expanded) => {
  const category = ODA_CATEGORIES.CRITERIA_MENU;
  const name = 'Criteria Group';
  const label = critGroup;
  const value = expanded ? 'Expand' : 'Collapse';
  return { category, name, label, value };
};

export const getCriteriaMenuOpenViewAllTrackingData = () => {
  const category = ODA_CATEGORIES.CRITERIA_MENU;
  const name = 'Open View All';
  return { category, name };
};

export const getCriteriaMenuToggleMenuTrackingData = expanded => {
  const category = ODA_CATEGORIES.CRITERIA_MENU;
  const name = 'Toggle Menu';
  const label = expanded ? 'Expand' : 'Collapse';
  return { category, name, label };
};

export const getCriteriaMenuRemoveCriterionTrackingData = critId => {
  const category = ODA_CATEGORIES.CRITERIA_MENU;
  const name = 'Remove Criterion';
  const label = critId;
  return { category, name, label };
};

export const getCriteriaMenuClearAllTrackingData = () => {
  const category = ODA_CATEGORIES.CRITERIA_MENU;
  const name = 'Clear All';
  return { category, name };
};

// Strategies ODA Actions

export const getStrategiesToggleMoreStrategiesTrackingData = expanded => {
  const category = ODA_CATEGORIES.STRATEGIES;
  const name = 'Toggle More Strategies';
  const label = expanded ? 'Expand' : 'Collapse';
  return { category, name, label };
};

export const getStrategiesStrategyClickedTrackingData = strategyId => {
  const category = ODA_CATEGORIES.STRATEGIES;
  const name = 'Strategy Clicked';
  const label = strategyId;
  return { category, name, label };
};

export const getStrategiesStrategyGroupClickedTrackingData = strategyId => {
  const category = ODA_CATEGORIES.STRATEGIES;
  const name = 'Strategy Group Clicked';
  const label = strategyId;
  return { category, name, label };
};

export const getStartPageStrategyGroupNavigationTrackingData = source => {
  const category = ODA_CATEGORIES.STRATEGIES;
  const name = 'Strategy Group Navigation';
  const label = source === START_PAGE_VIEW_TAB_SOURCE.NESTED_STRATEGIES_BACK_BUTTON ? 'Back' : 'Close';
  return { category, name, label };
};

// Quick Criteria ODA Actions

export const getQuickCriteriaCriterionSelectedTrackingData = (critId, selections) => {
  const category = ODA_CATEGORIES.QUICK_CRITERIA;
  const name = 'Update Selections';
  const label = critId;
  const value = selections.constructor === Array ? selections.sort().toString() : selections;
  return { category, name, label, value };
};

export const getQuickCriteriaMoreLinkTrackingData = moreLinkType => {
  const category = ODA_CATEGORIES.QUICK_CRITERIA;
  const name = 'More Links';
  const label = moreLinkType;
  return { category, name, label };
};

export const getQuickCriteriaViewResultsTrackingData = critIdArray => {
  const category = ODA_CATEGORIES.QUICK_CRITERIA;
  const name = 'View Results';
  const label = critIdArray.sort().toString();
  return { category, name, label };
};

// Quick Criteria Modal ODA Actions

export const getQuickCriteriaModalCloseModalTrackingData = () => {
  const category = ODA_CATEGORIES.QUICK_CRITERIA_MODAL;
  const name = 'Close Modal';
  return { category, name };
};

export const getQuickCriteriaModalOpenModalTrackingData = () => {
  const category = ODA_CATEGORIES.QUICK_CRITERIA_MODAL;
  const name = 'Open Modal';
  return { category, name };
};

export const getQuickCriteriaModalViewResultsTrackingData = critIdArray => {
  const category = ODA_CATEGORIES.QUICK_CRITERIA_MODAL;
  const name = 'View Results';
  const label = critIdArray.sort().toString();
  return { category, name, label };
};

export const getQuickCriteriaModalCriteriaOptionsTrackingData = include => {
  const category = ODA_CATEGORIES.QUICK_CRITERIA_MODAL;
  const name = 'Criteria Options';
  const label = include ? 'Include Selections' : 'Do Not Include Selections';
  return { category, name, label };
};

export const getQuickCriteriaModalModalOptionsTrackingData = dontShow => {
  const category = ODA_CATEGORIES.QUICK_CRITERIA_MODAL;
  const name = 'Modal Options';
  const label = dontShow ? 'Do Not Show Alert' : 'Continue To Show Alert';
  return { category, name, label };
};

// Criteria ODA Actions
export const getCriteriaAddCriterionTrackingData = (critId, source) => {
  const category = ODA_CATEGORIES.CRITERIA;
  const name = 'Add Criterion';
  const label = critId;
  const value = source;
  return { category, name, label, value };
};

export const getCriteriaUpdateFiltersTrackingData = (critId, filters) => {
  const category = ODA_CATEGORIES.CRITERIA;
  const name = 'Update Filters';
  const label = critId;
  const value = filters.constructor === Array ? filters.sort().toString() : filters;
  return { category, name, label, value };
};

export const getCriteriaUpdateCriteriaTrackingData = allSelections => {
  const category = ODA_CATEGORIES.CRITERIA;
  const name = 'Update Criteria';
  const label = allSelections;
  return { category, name, label };
};

// Multiple category ODA actions

export const getViewTabTrackingData = (displayType, tabName) => {
  const category = getODACategory(displayType);
  const name = 'View Tab';
  const label = tabName;
  return { category, name, label };
};

export const getPaginationTrackingData = (displayType, pageNum) => {
  const category = getODACategory(displayType);
  const name = 'Pagination';
  const label = pageNum;
  return { category, name, label };
};

export const getSortResultsTrackingData = (displayType, column, direction) => {
  const category = getODACategory(displayType);
  const name = 'Sort Results';
  const label = column;
  const value = direction;
  return { category, name, label, value };
};

export const getBuyButtonTrackingData = (displayType, ticker) => {
  const category = getODACategory(displayType);
  const name = START_PAGE_TYPE === 'fili' ? 'Exchange Button' : 'Buy Button';
  const label = ticker;
  return { category, name, label };
};

export const getResearchTrackingData = (displayType, ticker) => {
  const category = getODACategory(displayType);
  const name = 'Research Button';
  const label = ticker;
  return { category, name, label };
};

export const getFundClickTrackingData = (displayType, ticker) => {
  const category = getODACategory(displayType);
  const name = 'Fund Click';
  const label = ticker;
  return { category, name, label };
};

export const getAnnuityOptionTrackingData = ({ name }) => {
  const category = 'Annuity Options';
  const label = name;
  return { category, name, label };
}