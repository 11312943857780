import Types from 'actions/Types';
import Creators from 'actions/CriteriaActions';
import _ from 'lodash';
import { DEFAULT_MOBILE_DISPLAY_TYPE } from 'configs/AppFeatureConfig';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getCurrentViewTabShortNames } from 'reducers/selectors/ViewTabSelectors';
import mobileUtils from 'utilities/mobileUtils';

const resultsRouteActions = [
  Types.ADD_CRITERION,
  Types.UPDATE_FILTERS,
  Types.UPDATE_RANGE_INPUTS,
  Types.UPDATE_CRITERIA,
  Types.LOAD_STRATEGY,
  Types.UPDATE_PRICING_MODEL,
];
const displayTypeUpdateActions = [
  Types.SHOW_COMPARE_VIEW,
  Types.SHOW_FULL_RESULTS_VIEW,
  Types.UPDATE_DISPLAY_TYPE,
  Types.UPDATE_MOBILE_STATUS,
  Types.UPDATE_CRITERIA,
  Types.LOAD_STRATEGY,
];

const displayTypeMiddleware = store => next => action => {
  /** Ensures that when results are loaded from the start page, the display (card vs. table)
   * is set properly depending on whether the app is in the mobile experience or desktop experience
   */
  if (_.includes(resultsRouteActions, action.type) && getDisplayType(store.getState()) === 'start') {
    const newDisplayType = mobileUtils.isMobile() ? 'card' : 'table';
    store.dispatch(Creators.setDisplayType(newDisplayType));
  }

  /** Handles view tab changes when display type changes */
  if (_.includes(displayTypeUpdateActions, action.type)) {
    let newDisplayType;
    // get newDisplayType for each action
    switch (action.type) {
      case Types.SHOW_COMPARE_VIEW: {
        newDisplayType = 'compare';
        break;
      }
      case Types.SHOW_FULL_RESULTS_VIEW: {
        newDisplayType = 'table';
        break;
      }
      case Types.UPDATE_DISPLAY_TYPE: {
        newDisplayType = action.displayType;
        break;
      }
      case Types.UPDATE_MOBILE_STATUS: {
        newDisplayType = action.isMobile ? DEFAULT_MOBILE_DISPLAY_TYPE : 'table';
        break;
      }
      default:
        break;
    }

    const state = store.getState();
    const validTabs = getCurrentViewTabShortNames({ ...state, displayType: newDisplayType });
    if (newDisplayType === 'table' && state.viewTab === 'snapshot' && validTabs.includes('overview')) {
      // when switching to newDisplayType === 'table', if current tab is snapshot, switch to overview (if it's a valid tab)
      action.viewTabShortName = 'overview';
    } else if (newDisplayType === 'compare' && validTabs.includes('snapshot')) {
      // when switching to newDisplayType === 'compare', switch to snapshot (if it's a valid tab)
      action.viewTabShortName = 'snapshot';
    } else if (!_.isNil(action.viewTabShortName)) {
      // if action.viewTabShortName is valid, use it otherwise use the first valid tab
      action.viewTabShortName = validTabs.includes(action.viewTabShortName)
        ? action.viewTabShortName
        : _.get(validTabs, 0);
    } else {
      // if current tab is valid, use it otherwise use the first valid tab
      action.viewTabShortName = validTabs.includes(state.viewTab) ? state.viewTab : _.get(validTabs, 0);
    }
    action.displayType = newDisplayType;
  }

  next(action);
};

export default displayTypeMiddleware;
