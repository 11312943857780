import Types from 'actions/Types';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';
import { APP_DISCLOSURE_CHANNEL } from 'configs/AppFeatureConfig';
import { getRouterPersistentQueries } from 'reducers/selectors/RouterSelectors';
import { loginUtils } from 'utilities';
import { ENDPOINTS } from 'services/endpoints';
import {mockDataInjection} from "../tests/lib/mockDataSetInjection";

export default api => {
  // helpers
  function* getApiParameters(action) {
    let policyCode = action.policyCode;
    if (!policyCode) {
      const persistentQueries = yield select(getRouterPersistentQueries);
      if (!persistentQueries) {
        policyCode = persistentQueries.product;
      }
      else {
        const state = yield select();
        policyCode = state.router.location.query.product;
      }
    }
    if (!policyCode && ENDPOINTS.CLASSIFICATION_POLICY_INFO) {
      // for landing page we need to pass this dummy policy code to get API to work
      policyCode = 'FFLI';
    }
    const parameters = { policyCode };
    // filter out falsey params
    return _.pickBy(parameters, _.identity);
  }

  function* fetchApplicationDisclosures(action) {
    const parameters = yield* getApiParameters(action);
    const response = yield call(
      api.getData,
      ENDPOINTS.DISCLOSURE_APPLICATION + '/' + APP_DISCLOSURE_CHANNEL,
      parameters,
    );
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.DISCLOSURE_APPLICATION + '/' + APP_DISCLOSURE_CHANNEL, parameters);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}	
    if (response.ok) {
      response.data = _.get(response, ['data', 0, 'disclosures', 0, 'sectionCodes']);
      yield put({ type: Types.FUND_APPLICATION_DISCLOSURE_API_SUCCESS, payload: response });
    } else {
      yield put({ type: Types.FUND_APPLICATION_DISCLOSURE_API_ERROR, payload: response });
    }
  }

  function* fetchDataValueDisclosures(action) {
    const parameters = yield* getApiParameters(action);
    const response = yield call(
      api.getData,
      ENDPOINTS.DISCLOSURE_DATA_VALUE + '/' + APP_DISCLOSURE_CHANNEL,
      parameters,
    );
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.DISCLOSURE_DATA_VALUE + '/' + APP_DISCLOSURE_CHANNEL, parameters);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}	
    if (response.ok) {
      response.data = _.get(response, ['data', 0, 'disclosures', 0, 'entities']);
      yield put({ type: Types.FUND_DATAVALUE_DISCLOSURE_API_SUCCESS, payload: response });
    } else {
      yield put({ type: Types.FUND_DATAVALUE_DISCLOSURE_API_ERROR, payload: response });
    }
  }

  function* fetchDisclosureDisclosures(action) {
    const parameters = yield* getApiParameters(action);
    const response = yield call(
      api.getData,
      ENDPOINTS.DISCLOSURE_DISCLOSURES + '?applicationCode=' + APP_DISCLOSURE_CHANNEL,
      parameters,
    );
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.DISCLOSURE_DISCLOSURES + '?applicationCode=' + APP_DISCLOSURE_CHANNEL, parameters);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}	
    if (response.ok) {
      yield put({ type: Types.DISCLOSURE_DISCLOSURES_API_SUCCESS, payload: response });
    } else {
      yield put({ type: Types.DISCLOSURE_DISCLOSURES_API_ERROR, payload: response });
    }
  }

  function* fetchDisclosureLabelSuppressions(action) {
    const parameters = yield* getApiParameters(action);
    const response = yield call(
      api.getData,
      ENDPOINTS.DISCLOSURE_LABEL_SUPPRESSIONS + '?applicationCode=' + APP_DISCLOSURE_CHANNEL,
      parameters,
    );
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.DISCLOSURE_LABEL_SUPPRESSIONS + '?applicationCode=' + APP_DISCLOSURE_CHANNEL, parameters);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}		
    if (response.ok) {
      yield put({ type: Types.DISCLOSURE_LABEL_SUPPRESSIONS_API_SUCCESS, payload: response });
    } else {
      yield put({ type: Types.DISCLOSURE_LABEL_SUPPRESSIONS_API_ERROR, payload: response });
    }
  }

  function* watcher() {
    // handle login expiration
    loginUtils.redirectToLoginIfSessionExpired();
    loginUtils.resetLoginExpiration();

    yield takeEvery(Types.FUND_APPLICATION_DISCLOSURE_API, fetchApplicationDisclosures);
    yield takeEvery(Types.FUND_DATAVALUE_DISCLOSURE_API, fetchDataValueDisclosures);
    yield takeEvery(Types.DISCLOSURE_DISCLOSURES_API, fetchDisclosureDisclosures);
    yield takeEvery(Types.DISCLOSURE_LABEL_SUPPRESSIONS_API, fetchDisclosureLabelSuppressions);
  }

  return {
    watcher,
    fetchApplicationDisclosures,
    fetchDataValueDisclosures,
    fetchDisclosureDisclosures,
    fetchDisclosureLabelSuppressions,
  };
};
