import React, { Component } from 'react';
import { connect } from 'react-redux'
import Popover from '../Shared/Popover';
import _ from 'lodash';

class MadLibsSelection extends Component {
  constructor(props){
    super(props);
    this.bodyStyle={};
    this.elmPopover = React.createRef();
    this.refElement=null;
    this.state = {popoverShow: false};
    this.showPopover = () => {
      if(this.state.popoverShow){
        this.setState({
          ...this.state,
          popoverShow: false
        });
      }else{
        this.setState({
          ...this.state,
          popoverShow: true
        });
      }
    };
    this.getCriteriaList = (criteria) => {
      let items = {};
      return (
        <ul>
          {items}
        </ul>
      );
    };
    this.renderBody = () => {
      const { label } = this.props.selection;
      if(MadLibsSelection.glossaryDefinition[label]){
        const text = MadLibsSelection.glossaryDefinition[label];
        return (
          <div dangerouslySetInnerHTML={{__html: text}} />
        );
      }else{
        const text = "Definition not available";
        return <div dangerouslySetInnerHTML={{__html: text}} />;
      }
    };
    this.renderPopover = () => {
      const { label } = this.props.selection;
      if(this.state.popoverShow){
        this.bodyStyle.width = 300;
        if( this.props.container &&
            document.getElementsByClassName(this.props.container)[0] ){
              this.bodyStyle.width = document.getElementsByClassName(this.props.container)[0].clientWidth;
              if( this.bodyStyle.width > 500){
                this.bodyStyle.width = 500;
              }
        }
        const textBody = this.renderBody();
        if (this.refElement==null) {
          this.refElement = this.elmPopover.current;
        }

        return (
          <Popover
          id="madlib-selection-criteria"
          style={this.bodyStyle}
          title={label}
          body={textBody}
          referenceElement={this.refElement}
          placement={this.props.placement || 'bottom'}
          closePopover={this.showPopover}
        />
        );
      }
      else{
        return null;
      }
    };
  }

  onClick = (e) => {
    this.showPopover();
    e.preventDefault();
    e.stopPropagation();
  }
  spanClick = (e)=>{
    this.refElement=e.target;
  }

  componentDidMount() {

  }

  getLabelSpans(label){
    let splits = label.split(" ");
    let splitSpans=[];
    splits.forEach((e,i) => {
      if (i+1===splits.length){
        splitSpans.push(<span  onClick={this.spanClick}>{e}</span>);
      } else {
        splitSpans.push(<span  onClick={this.spanClick}>{e} </span>);
      }
    });
    return splitSpans;
  }

  render() {
    const labelSpans = this.getLabelSpans(this.props.selection.label);
    return (
      <React.Fragment>
        <a 
          className="madlibs-selection"
          ref={this.elmPopover}
          onClick={this.onClick}>
          {labelSpans}
        </a>
        {this.renderPopover()}
      </React.Fragment>
    )
  }

  static glossaryDefinition = {}
  static loadGlossaryDefinitions = (disclosureDisclosures) => {
    if( Object.keys(MadLibsSelection.glossaryDefinition).length > 0 ) {
      return true;
    }

    if(Object.keys(disclosureDisclosures).length>0){
      const MINIMUM_INVESTMENT = disclosureDisclosures['Minimum investment < $2.5k'][0].text
      const FidelityFund = _.get(disclosureDisclosures, 'Funds managed by Fidelity Investments[0].text', 'Undefined');
      const MANAGED_BY_OTHERS = _.get(disclosureDisclosures, 'Funds managed by other managements companies[0].text', 'Undefined');
      const NTF = _.get(disclosureDisclosures, 'MFE_NoTransactionFee[0].text', 'Undefined');
      const ANY_PROVIDER = `${FidelityFund}<p>${MANAGED_BY_OTHERS}</p><p>${MINIMUM_INVESTMENT}</p>`;

      MadLibsSelection.glossaryDefinition['no transaction fee'] = NTF;
      MadLibsSelection.glossaryDefinition['Fidelity (no fees)'] = `${FidelityFund}<p>${MINIMUM_INVESTMENT}</p>`;
      MadLibsSelection.glossaryDefinition['any provider'] = ANY_PROVIDER;
      MadLibsSelection.glossaryDefinition['any provider that doesn\'t charge a fee'] = `${ANY_PROVIDER}<p>${NTF}</p>`;

      const MORNINGSTAR_US = _.get(disclosureDisclosures, 'Morningstar US Stock Asset Class[0].text', 'Undefined');
      const HIGH_RETURN = _.get(disclosureDisclosures, 'High Return Rating from Morningstar[0].text', 'Undefined');
      const SHARP_RATIO = disclosureDisclosures['Sharpe Ratio of >=1.5'][0].text
      MadLibsSelection.glossaryDefinition['offers potentially higher returns (potentially riskier)'] = `${MORNINGSTAR_US}<p>${HIGH_RETURN}</p><p>${SHARP_RATIO}</p>`;
      MadLibsSelection.glossaryDefinition['higher risk/potential return'] = `${MORNINGSTAR_US}<p>${HIGH_RETURN}</p><p>${SHARP_RATIO}</p>`;
      MadLibsSelection.glossaryDefinition['highest risk/potential return'] = `${MORNINGSTAR_US}<p>${HIGH_RETURN}</p><p>${SHARP_RATIO}</p>`;

      MadLibsSelection.glossaryDefinition['is one fund that contains a fully diversified portfolio'] = disclosureDisclosures['Asset Allocation and Target Date funds'][0].text;

      const TAXABLE_BOND = _.get(disclosureDisclosures, 'Taxable Bond Funds[0].text', 'Undefined');
      const MONEY_MARKET = _.get(disclosureDisclosures, 'Money Market Funds[0].text', 'Undefined');
      MadLibsSelection.glossaryDefinition['focuses on relatively safer investments'] = `${TAXABLE_BOND}<p>${MONEY_MARKET}</p>`;
      
      MadLibsSelection.glossaryDefinition['low expenses'] = MadLibsSelection.glossaryDefinition['has low expenses'] = disclosureDisclosures['Morningstar Fee Rating = Low'][0].text;

      MadLibsSelection.glossaryDefinition['has a manager that attempts to beat the benchmark'] = disclosureDisclosures['Actively Managed Funds'][0].text;
      MadLibsSelection.glossaryDefinition['has a manager that attempts to match the benchmark (Index funds)'] = disclosureDisclosures['Index Funds'][0].text;
      MadLibsSelection.glossaryDefinition['is highly rated by Morningstar'] = _.get(disclosureDisclosures, 'Morningstar Star rating of 4 and 5[0].text', 'Undefined');;
      MadLibsSelection.glossaryDefinition['automatically changes from potentially higher returns to lower risk as my target withdrawal date approaches'] = disclosureDisclosures['Target Date Funds'][0].text;
      MadLibsSelection.glossaryDefinition['is invested according to the predetermined risk level I choose'] = disclosureDisclosures['Asset Allocation Funds'][0].text;
      MadLibsSelection.glossaryDefinition['balanced risk/potential return'] = disclosureDisclosures['Asset Allocation Funds'][0].text;
      MadLibsSelection.glossaryDefinition['lower risk/potential return'] = disclosureDisclosures['Target Date Funds'][0].text;
      MadLibsSelection.glossaryDefinition['lowest risk/potential return'] = disclosureDisclosures['Target Date Funds'][0].text;

      MadLibsSelection.glossaryDefinition['is a smart place to park my cash'] = MONEY_MARKET;
      
      const DAY_YIELD = _.get(disclosureDisclosures, '30-day Yield > 2%[0].text', 'Undefined');
      MadLibsSelection.glossaryDefinition['has paid a good 30-day yield'] = `${TAXABLE_BOND}<p>${DAY_YIELD}</p>`;

      MadLibsSelection.glossaryDefinition['could help fight inflation'] = disclosureDisclosures['Inflation Protected Bond'][0].text;
      MadLibsSelection.glossaryDefinition['socially responsible'] = _.get(disclosureDisclosures, 'MFE_Social_resp[0].text', 'Undefined');
    
      MadLibsSelection.glossaryDefinition['my target withdrawal date is 5 years away'] = 'my target withdrawal date is 5 years away';
      MadLibsSelection.glossaryDefinition['my target withdrawal date is 10 years away'] = 'my target withdrawal date is 10 years away';
      MadLibsSelection.glossaryDefinition['my target withdrawal date is 15 years away'] = 'my target withdrawal date is 15 years away';
      MadLibsSelection.glossaryDefinition['my target withdrawal date is 20 years away'] = 'my target withdrawal date is 20 years away';
      MadLibsSelection.glossaryDefinition['my target withdrawal date is 25 years away'] = 'my target withdrawal date is 25 years away';
      MadLibsSelection.glossaryDefinition['my target withdrawal date is 30 years away'] = 'my target withdrawal date is 30 years away';
      MadLibsSelection.glossaryDefinition['my target withdrawal date is 35 years away'] = 'my target withdrawal date is 35 years away';
      MadLibsSelection.glossaryDefinition['my target withdrawal date is 40 years away'] = 'my target withdrawal date is 40 years away';
    }
  }
};

const mapStateToProps = state => {
  try{
    MadLibsSelection.loadGlossaryDefinitions(state.disclosureDisclosures);
    return {};
  } catch(ex) {
    console.log(ex);
    return {};
  }
};

export default connect(mapStateToProps)(MadLibsSelection);