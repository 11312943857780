import React, { Component, createRef } from 'react';

import PropTypes from 'prop-types';
import _ from 'lodash';
import { format, icons } from 'utilities';
import { connect } from 'react-redux';
import { getCriteriaGroupInactive } from 'reducers/selectors/CriteriaSelectors';
import { getSustainableInvestmentDisclosureContent } from 'reducers/ApplicationDisclosuresReducer';
import { getEmploysExclusionsDisclosureContent } from 'reducers/ApplicationDisclosuresReducer';
import Creators from 'actions/CriteriaActions';
import { CRITERIA_SOURCE } from 'utilities/odaUtils';
import Modal from 'components/Shared/Modal';
import { getDefaultFilterGroupSelectionValues } from 'utilities/criteriaUtils';
import apiUtils from '../../utilities/apiUtils';

class InactiveCriterion extends Component {
  constructor() {
    super();
    this.state = { isModalOpen: false };
    this.modalRef = createRef();
  }

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleOpenModal = () => {
    this.setState({ isModalOpen: true });
  };

  renderESGDiscolosure = label => {
    if (label === 'Sustainable Investment') {
      return this.props.sustainableInvestmentDisclosure;
    } else if (label === 'Employs Exclusions') {
      return this.props.employsExclusionsDisclosureContent;
    }
  };

  _renderHelpPopup = helpText => {
    if (!_.isNil(helpText)) {
      return (
        <span>
          <button
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                this.handleOpenModal();
              }
            }}
            onClick={this.handleOpenModal}
            tabIndex={0}
            aria-label="Help"
            className="inactive-criterion-help-button"
            src={icons.helpIcon}
            style={{ backgroundImage: 'url(' + icons.helpIcon + ')' }}
            alt="Help Button"
          />
          <Modal isOpen={this.state.isModalOpen} handleCloseModal={this.handleCloseModal} modalRef={this.modalRef}>
            <span dangerouslySetInnerHTML={{ __html: helpText }} />
          </Modal>
        </span>
      );
    }
    return null;
  };

  render() {
    const { addCriterion, isTopLevel, useSublabel, toggleExpansion } = this.props;
    const { id, label, sublabel, image: iconType, defaultFilterGroupSelections, helpText } = this.props.criterion;
    const displayLabel = useSublabel ? sublabel : label;
    const displayStyle = isTopLevel ? 'top-level-criterion' : '';
    const filterGroupSelections = getDefaultFilterGroupSelectionValues(defaultFilterGroupSelections);
    const characterImage = iconType === 'dollar' ? <span class="dollarSignIcon">$</span> : null;
    const icon = iconType ? icons[iconType] : null;
    const iconClass = !_.isNil(iconType) ? format.transformToSnakeCase(iconType) : '';
    const iconAlt = !_.isNil(iconType) ? format.camelCaseToWords(iconType).replace('Esg', 'ESG') : '';
    const image = icon ? <img src={icon} alt={iconAlt} className={iconClass} /> : characterImage;
    const clickHandler = () => {
      if (id === 'sociallyResponsible') {
        addCriterion(
          'ntf',
          getDefaultFilterGroupSelectionValues({
            ntf: [
              {
                label: 'Display No Transaction Fee Only',
                value: 'Y',
                icon: null,
                key: null,
              },
            ],
          }),
        );

        const sustainableInvestment = apiUtils
          .getSociallyResponsibleAttributesValues('sustainableInvestment')
          .split(',');
        const employsExclusions = apiUtils.getSociallyResponsibleAttributesValues('employsExclusions').split(',');
        addCriterion('sustainableInvestment', { sustainableInvestment: sustainableInvestment });
        addCriterion('employsExclusions', { employsExclusions: employsExclusions });
      }
      addCriterion(id, filterGroupSelections);

      this.props.setFocusCriteria(format.transformToSnakeCase(id));
    };

    return (
      <li className={`inactive-criterion ${displayStyle}`}>
        {isTopLevel ? null : <div className="inactive-criterion-spacer" />}
        <div className="inactive-criterion-content">
          {image}
          <button
            tabIndex={0}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                clickHandler();
              }
            }}
            onClick={clickHandler}
            dangerouslySetInnerHTML={{ __html: displayLabel }}
          />
          {label === 'Sustainable Investment' || label === 'Employs Exclusions'
            ? this._renderHelpPopup(this.renderESGDiscolosure(label))
            : this._renderHelpPopup(helpText)}
        </div>
      </li>
    );
  }
}

InactiveCriterion.defaultProps = {
  isTopLevel: false,
};

InactiveCriterion.propTypes = {
  /** Function from parent that adds criterion to active criteria list */
  addCriterion: PropTypes.func.isRequired,

  /** Unique identifier for a criterion */
  criterion: PropTypes.object.isRequired,

  /** Determines whether to render a spacer */
  isTopLevel: PropTypes.bool,

  /** Handles expanding and collapsing criterion display */
  toggleExpansion: PropTypes.func.isRequired,
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, ownProps) => {
    return {
      ...getCriteriaGroupInactive(state, ownProps),
      sustainableInvestmentDisclosure: getSustainableInvestmentDisclosureContent(state),
      employsExclusionsDisclosureContent: getEmploysExclusionsDisclosureContent(state),
    };
  };
  return mapStateToProps;
};

const mapDispatchToProps = dispatch => {
  return {
    addCriterion: (crit, filterGroupSelections, options) => {
      dispatch(Creators.addCriterion(crit, filterGroupSelections, CRITERIA_SOURCE.CRITERIA_MENU, options));
    },
  };
};

const InactiveCriterionContainer = connect(makeMapStateToProps, mapDispatchToProps)(InactiveCriterion);

export default InactiveCriterionContainer;
