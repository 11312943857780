import React from 'react';
import PropTypes from 'prop-types';
import QuickCriteriaCheckboxFilter from 'components/StartPage/QuickCriteriaCheckboxFilter';
import GlossaryDefinitionPopover from '../Shared/GlossaryDefinitionPopover';

const QuickCriteriaKeyCriteria = ({
  allCriteria,
  handleSelectKeyCriteria,
  handleSelectMgmtApproach,
  keyCriteriaSelected,
  mgmtApproachSelected,
}) => {
  const { fidelityFundOnly, fundPicks, sociallyResponsible, minimumInvestment } = allCriteria;
  return (
    <div className="quick-criteria-key-criteria">
      <div>
        <span className="large-header">Key Criteria</span>
        <GlossaryDefinitionPopover
          glossary="Key Criteria"
          container="quick-criteria-key-criteria"
          ariaLabel="Key Criteria"
        />
        <ul className="top-section">
          <QuickCriteriaCheckboxFilter
            classNames="item fidelity-funds-only"
            icon="fidelityIcon"
            identifier={fidelityFundOnly.id}
            isChecked={keyCriteriaSelected.includes(fidelityFundOnly.id)}
            label={fidelityFundOnly.label}
            handleClick={handleSelectKeyCriteria}
            criteriaName="keyCriteria"
          />
          <QuickCriteriaCheckboxFilter
            classNames="item fund-picks-fidelity"
            icon="fundPicksIcon"
            identifier={fundPicks.id}
            isChecked={keyCriteriaSelected.includes(fundPicks.id)}
            label={fundPicks.label}
            handleClick={handleSelectKeyCriteria}
            criteriaName="keyCriteria"
          />
          <QuickCriteriaCheckboxFilter
            classNames="item sustainable-attributes-fidelity"
            icon={sociallyResponsible.image}
            identifier={sociallyResponsible.id}
            isChecked={keyCriteriaSelected.includes(sociallyResponsible.id)}
            label={sociallyResponsible.label}
            handleClick={handleSelectKeyCriteria}
            criteriaName="keyCriteria"
          />
          <QuickCriteriaCheckboxFilter
            classNames="item minimum-investment-key-criteria"
            identifier={minimumInvestment.id}
            isChecked={keyCriteriaSelected.includes(minimumInvestment.id)}
            label="Minimum Investment < $2.5k"
            handleClick={handleSelectKeyCriteria}
            criteriaName="keyCriteria"
          />
        </ul>
      </div>
      <div role="radiogroup" aria-label="Management Approach" className="mgmt-approach">
        <div className="small-header">
          <b>Management Approach</b>
        </div>
        <input
          name="management-approach"
          type="radio"
          id="mgmt-approach-all"
          checked={mgmtApproachSelected === 'All'}
          onChange={() => {
            handleSelectMgmtApproach('All');
          }}
        />
        <label htmlFor="mgmt-approach-all" className="mgmt-approach-radio-input">
          All
        </label>
        <input
          name="management-approach"
          type="radio"
          id="mgmt-approach-active"
          checked={mgmtApproachSelected === 'Active'}
          onChange={() => {
            handleSelectMgmtApproach('Active');
          }}
        />
        <label htmlFor="mgmt-approach-active" className="mgmt-approach-radio-input">
          Active
        </label>
        <input
          name="management-approach"
          type="radio"
          id="mgmt-approach-index"
          checked={mgmtApproachSelected === 'Index'}
          onChange={() => {
            handleSelectMgmtApproach('Index');
          }}
        />
        <label htmlFor="mgmt-approach-index" className="mgmt-approach-radio-input">
          Index
        </label>
      </div>
    </div>
  );
};

QuickCriteriaKeyCriteria.propTypes = {
  /** Object representing all criteria definitions */
  allCriteria: PropTypes.object.isRequired,

  /** Handle selecting a key criteria */
  handleSelectKeyCriteria: PropTypes.func.isRequired,

  /** Handles selecting a management approach */
  handleSelectMgmtApproach: PropTypes.func.isRequired,

  /** Array of strings representing selected key criteria */
  keyCriteriaSelected: PropTypes.array.isRequired,

  /** String representing the selected management approach */
  mgmtApproachSelected: PropTypes.string.isRequired,
};

export default QuickCriteriaKeyCriteria;
