import _ from 'lodash';
import screenerConfig from 'screenerConfig';

export const FUND_STRATEGIES_CONFIG = screenerConfig.fundStrategiesConfig || [];

export const ALL_NESTED_STRATEGY_IDS = _.chain(FUND_STRATEGIES_CONFIG)
  .filter(strategy => {
    return strategy.isNestedStrategy;
  })
  .map(nestedStrategy => nestedStrategy.id)
  .value();

export const STRATEGY_CONFIGS_BY_ID = _.keyBy(
  _.flatMap(FUND_STRATEGIES_CONFIG, strategy => {
    return strategy.isNestedStrategy && strategy.strategiesList ? strategy.strategiesList : strategy;
  }),
  config => config.id,
);

export const ALL_VALID_STRATEGY_IDS = _.flatMap(FUND_STRATEGIES_CONFIG, strategy => {
  if (strategy.isNestedStrategy && strategy.strategiesList) {
    return _.map(strategy.strategiesList, nestedStrategy => nestedStrategy.id);
  }
  return strategy.id;
});
