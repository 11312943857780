import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { icons, strategiesUtils } from 'utilities';
import { connect } from 'react-redux';
import Creators from 'actions/CriteriaActions';
import { getMatchesForStrategy } from 'reducers/StrategiesMatchesReducer';
import { getStrategyMatchesLoadingForStrategy } from 'reducers/LoadingReducer';
import { cloneDeep } from 'lodash';
import apiUtils from '../../utilities/apiUtils';

class FundStrategy extends Component {
  _handleLoadingStrategy = () => {
    const {
      critIdArray,
      parsedSelections,
      strategyId,
      viewTabShortName,
      sortParams,
    } = strategiesUtils.parseLoadStrategyParams(this.props.strategy);
    if (strategyId === 'sociallyResponsibleStrategy'){
      let critIdArray1= cloneDeep(critIdArray);
      let parsedSelections1= cloneDeep(parsedSelections);
      if (critIdArray1.find(e=>e==='sociallyResponsible')){
        critIdArray1=critIdArray1.filter(e=> e!=='sociallyResponsible');
        delete parsedSelections1['sociallyResponsible'];
        critIdArray1.push('sustainableInvestment');
        critIdArray1.push('employsExclusions');
        const sustainableInvestment = apiUtils.getSociallyResponsibleAttributesValues('sustainableInvestment').split(",");
        const employsExclusions = apiUtils.getSociallyResponsibleAttributesValues('employsExclusions').split(",");
        parsedSelections1['sustainableInvestment']=sustainableInvestment;
        parsedSelections1['employsExclusions']=employsExclusions;
        this.props.loadStrategy(critIdArray1, parsedSelections1, strategyId, viewTabShortName, sortParams);
        return;
      }
    }
    this.props.loadStrategy(critIdArray, parsedSelections, strategyId, viewTabShortName, sortParams);
  };

  render() {
    const { strategy, handleClick, isLoading, matchCount } = this.props;

    const icon = icons[strategy.icon];
    const clickHandler = handleClick || this._handleLoadingStrategy;

    let countText;

    if (strategy.isNestedStrategy) {
      countText = `${strategy.strategiesList.length} strategies`;
    } else if (isLoading) {
      countText = 'Loading...';
    } else {
      countText = `${matchCount} funds`;
    }

    return (
      <div className="fund-strategy" key={strategy.label} onClick={clickHandler}>
        <img src={icon} alt="Icon representing this fund strategy" />
        <button className="as-link" dangerouslySetInnerHTML={{ __html: strategy.label }} />
        <div className="strategy-match-count">{countText}</div>
      </div>
    );
  }
}

FundStrategy.defaultProps = {
  strategy: {
    filterGroupSelections: [],
  },
};

FundStrategy.propTypes = {
  /** Optional function to override loading a strategy */
  handleClick: PropTypes.func,

  isLoading: PropTypes.bool.isRequired,

  /** Redux action to load the strategy */
  loadStrategy: PropTypes.func.isRequired,

  /** The current amount of matches that fit this fund type */
  matchCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  /** The strategy configuration object */
  strategy: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    matchCount: getMatchesForStrategy(state, ownProps),
    isLoading: getStrategyMatchesLoadingForStrategy(state, ownProps),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadStrategy: (critArray, filterGroupSelections, strategyId, viewTabShortName, sortParams) => {
      dispatch(Creators.loadStrategy(critArray, filterGroupSelections, strategyId, viewTabShortName, sortParams));
    },
  };
};

const FundStrategyContainer = connect(mapStateToProps, mapDispatchToProps)(FundStrategy);

export default FundStrategyContainer;
export { FundStrategy };
