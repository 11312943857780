import React from 'react';

const _scrollToMadLib = () => {
  document.getElementById('madlibs').scrollIntoView({ behavior: 'smooth' });
}

const MadLibsLink = () => {
  return <button className="as-link" onClick={_scrollToMadLib}>Don't Know Where to Begin?</button>
}

export default MadLibsLink;