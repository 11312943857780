import screenerConfig from 'screenerConfig';

export const FIDELITY_API = 'https://fundresearchxq1.fidelity.com/fund-screener/api';
export const LOCAL_API = 'http://localhost/fidelity/fund-screener/fund-screener/api';
//TODO: Temporarily setting API_BASE_URL to acceptance 1 proxy URL (pending Fidelity making CORS updates to allow requests from non-integrated environment)
export const API_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost/fidelity/fund-screener/fund-screener/api'
    : screenerConfig.urlConfig.apiServiceBaseUrl;
//const API_BASE_URL = 'http://localhost/fidelity/fund-screener/fund-screener/api';
export const ENDPOINTS = screenerConfig.urlConfig.endpoints;
export const PRODUCTION_API = process.env.REACT_APP_DEFAULT_API;

export default {
  FIDELITY_API,
  LOCAL_API,
  ENDPOINTS,
  PRODUCTION_API,
  API_BASE_URL,
};
