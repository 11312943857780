import React from 'react';
import PropTypes from 'prop-types';

const ViewAllCriterion = ({ criterionLabel, isSelected, criterionId, toggleCriterionSelection }) => {
  const viewAllCriterionClassName = isSelected ? 'view-all-criterion checked' : 'view-all-criterion unchecked';

  return (
    <div
      className={`checkbox-input ${viewAllCriterionClassName}`}
      onClick={() => toggleCriterionSelection(criterionId, !isSelected)}
    >
      <div>
        <label for={criterionLabel}>
          <input type="checkbox" id={criterionLabel} name={criterionLabel} value={criterionLabel} />
          <svg
            class="checkbox"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <path class="checkmark" d="M 5,11 L 8,14 L 14, 6" />
          </svg>
          {criterionLabel === 'R&sup2;' ? (
            <span class="view-all-criterion-label">R&sup2;</span>
          ) : (
            <span class="view-all-criterion-label">{criterionLabel}</span>
          )}
        </label>
      </div>
    </div>
  );
};

ViewAllCriterion.propTypes = {
  /** Name of criterion */
  criterionLabel: PropTypes.string,

  isSelected: PropTypes.bool,

  /** Function to update selected criterion */
  toggleCriterionSelection: PropTypes.func,

  /** Criterion Service field -- this gets passed to active criteria when a criterion is selected) */
  criterionId: PropTypes.string,
};

export default ViewAllCriterion;
