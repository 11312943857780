import React, { Component } from 'react';
import _ from 'lodash';
import { icons } from 'utilities';
import { connect } from 'react-redux';
import { getCriteriaDefinitions } from 'reducers/selectors/CriteriaSelectors';
import FundPicksSelector from 'components/StartPage/FundPicksSelector';
import { getFundPicksMatchesLoading } from 'reducers/LoadingReducer';
import { getFundPicksMatchesError } from 'reducers/ErrorReducer';
import Creators from 'actions/CriteriaActions';
import {
  getFundPicksAssetTotalCounts,
  getFundPicksGroups,
  getFundPicksSelectedCategory,
  getFundPicksSelectedGroups,
  getQuickCriteriaModalState,
  getFundPicksParsedSelections,
  getFundPicksAllGroupsChecked
} from 'reducers/FundPicksReducer';
import { getFidelityFundPicks } from 'reducers/FundTypeFiltersReducer';
import { getFundPicksTotalMatches } from 'reducers/FundPicksMatchesReducer';

class FundPicks extends Component {
  constructor(props) {
    super(props);
    props.fetchFundPicksMatches();
  }

  handleChangeActiveCategory = newCategory => {
    if (newCategory !== this.props.selectedCategory) {
      let selectedGroups = [];
      selectedGroups = this._getAllGroupValuesForCategory(newCategory);
      this.props.updateSelectedCategory(newCategory, selectedGroups);
    }
  };

  _getAllGroupValuesForCategory = category => {
    const fundPicksFilters = _.get(this.props, 'allFunds', []);
    return fundPicksFilters[category].groups;
  };

  render() {
    const fundPickLogo = icons['fundPicksLargeIcon'];
    return (
      <div className="fund-picks">
        <div className="fund-picks-header">
          Fund Picks from Fidelity<sup>&reg;</sup>
        </div>
        <div className="fund-picks-wrap">
          <img src={fundPickLogo} alt="fund picks logo" />
          <div className="fund-picks-info">
            <p>
              Every quarter, we screen funds available through Fidelity, including those managed by other fund companies, to provide you with a list of funds that meet <a href="https://www.fidelity.com/mutual-funds/all-mutual-funds/selection-criteria-for-fund-picks" target="_blank" rel="noopener noreferrer">our selection criteria</a>.
            </p>
            <p>
              Our selection criteria contains important information about the universe of funds that we consider and the
              screening criteria that is used in Fund Picks.
            </p>
            <ul>
              <li>Funds have no transaction fees*</li>
              <li>Investment profiles are taken into account, including key features and investment minimums</li>
            </ul>
            <ul>
              <li>Three-year performance history is tracked</li>
              <li>Funds offer highest three-year risk-adjusted returns within Morningstar category</li>
            </ul>
          </div>
        </div>
        <div className="fund-picks-selector">
          <FundPicksSelector
            selectedCategory={this.props.selectedCategory}
            selectedGroups={this.props.selectedGroups}
            availableGroups={this.props.groups}
            allGroupsChecked={this.props.allGroupsChecked}
            categories={this.props.allFunds}
            handleChangeActiveCategory={this.handleChangeActiveCategory}
            handleSelectCategoryGroup={this.props.updateSelectedGroups}
            parsedSelections={this.props.parsedSelections}
            viewResults={this.props.viewResults}
            isFundPicksLoading={this.props.isFundPicksLoading}
            isFundPickError={this.props.isFundPickError}
            fundPicksMatches = {this.props.fundPicksMatches}
          />
        </div>
      </div>
    );
  }
}

const getFidelityAllFunds=(state)=>{
  let allfunds = _.cloneDeep(getFidelityFundPicks(state));
  let keys = Object.keys(allfunds);
  let fundPicksTotalCounts = getFundPicksAssetTotalCounts(state);
  let fundPicksTotalCountsKeys = Object.keys(fundPicksTotalCounts).filter(e=>fundPicksTotalCounts[e] && fundPicksTotalCounts[e]['_total_'] >0);
  keys.forEach(key=>{
    if (key!=='All Categories' && !fundPicksTotalCountsKeys.includes(key)){
       delete allfunds[key];
    }
  });
  return allfunds;
}

const getFundPicksGroupsByCount = (state)=>{
  let category = getFundPicksSelectedCategory(state);
  let groups = getFundPicksGroups(state);
  let fundPicksTotalCounts = getFundPicksAssetTotalCounts(state);
  let categoryCount = fundPicksTotalCounts[category];
  let filteredGroups =[];
  groups.forEach(g =>{
    if (categoryCount && categoryCount[g.key]>0){
      filteredGroups.push(g);
    }
  });
  return filteredGroups;
}

const mapStateToProps = (state, ownProps) => {
  return {
    allCriteria: getCriteriaDefinitions(state),
    selectedCategory: getFundPicksSelectedCategory(state),
    selectedGroups: getFundPicksSelectedGroups(state),
    groups: getFundPicksGroupsByCount(state),
    isModalOpen: getQuickCriteriaModalState(state),
    allFunds: getFidelityAllFunds(state),
    parsedSelections: getFundPicksParsedSelections(state),
    allGroupsChecked: getFundPicksAllGroupsChecked(state),
    isFundPicksLoading: getFundPicksMatchesLoading(state),
    isFundPickError: getFundPicksMatchesError(state),
    fundPicksMatches : getFundPicksTotalMatches(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    cancelModal: () => {
      dispatch(Creators.quickCriteriaCancelModal());
    },
    updateSelectedCategory: (category, groups) => {
      dispatch(Creators.fundPicksUpdateSelectedCategory(category, groups));
      dispatch(Creators.fundPicksFromFidelityInitialMatchesApi());
    },
    updateSelectedGroups: (group, checked, allGroupsChecked) => {
      dispatch(Creators.fundPicksUpdateSelectedGroups(group, checked, allGroupsChecked));
      dispatch(Creators.fundPicksFromFidelityInitialMatchesApi());
    },
    fetchFundPicksMatches: () => {
      dispatch(Creators.fundPicksFromFidelityInitialMatchesApi());
    },
    hideModal: () => {
      dispatch(Creators.quickCriteriaHideModal());
    },
    viewResults: (critIdArray = [], criteriaSelections, source) => {
      dispatch(Creators.updateCriteria(critIdArray, criteriaSelections, source));
    },
  };
};

const FundPicksContainer = connect(mapStateToProps, mapDispatchToProps)(FundPicks);

export default FundPicksContainer;
export { FundPicks };
