import _ from 'lodash';
import Types from 'actions/Types';
import Creators from 'actions/CriteriaActions';
import { apiUtils } from 'utilities';
import {
  ROUTE_CONSTANTS,
  FUND_ID_FIELD,
  FUND_IDS_FIELD,
  PERSISTENT_QUERIES,
  RESULT_FEATURES,
} from 'configs/AppFeatureConfig';
import { FUND_STRATEGIES_CONFIG } from 'configs/FundStrategiesConfig';
import { CRITERIA_IDS_BY_FILTER_GROUP } from 'configs/CriteriaConfig';
import { ENDPOINTS } from 'services/endpoints';
import { getQuickCriteriaParsedSelections, buildCriteriaSelections } from 'reducers/QuickCriteriaMenuReducer';
import { getFundPicksParsedSelections } from 'reducers/FundPicksReducer';
import { getCriterionDefinition } from 'utilities/criteriaUtils';
import { TOGGLE_GROUP_CONFIG } from 'configs/ResultConfig';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getAllStrategiesMatches } from 'reducers/StrategiesMatchesReducer';
import { getCriteriaDefinitions } from 'reducers/selectors/CriteriaSelectors';
import { allMadLibsDataStructureFormatting } from 'utilities/apiUtils';

const apiMethods = {};

apiMethods[Types.ADD_CRITERION] = (store, next, action) => {
  let callNextAction = true;
  const state = store.getState();
  if (action.critId === 'fundFamily' && state.fundFamilyFilters.length <= 0) {
    store.dispatch({ type: Types.FUNDFAMILY_API, callbackAction: action });
    callNextAction = false;
  } else {
    const totalMatchesCritFilters = _.defaults({}, action.filterGroupSelections, state.criteriaFilters);
    const totalMatchesSearchParams = apiUtils.constructSearchParams(state, { critFilters: totalMatchesCritFilters });
    _.forEach(action.filterGroupSelections, (filterGroupSelection, filterGroupId) => {
      const totalMatchesAction = {
        type: 'TOTAL_MATCHES_API',
        currentCrit: filterGroupId,
        searchParams: totalMatchesSearchParams,
      };
      store.dispatch(totalMatchesAction);
      const criteriaMatchesCritFilters = _.pickBy(totalMatchesCritFilters, (filters, id) => id !== filterGroupId);
      const criteriaMatchesSearchParams = apiUtils.constructSearchParams(state, {
        critFilters: criteriaMatchesCritFilters,
      });
      const criteriaMatchesAction = {
        type: 'CRITERIA_MATCHES_API',
        currentCrit: filterGroupId,
        searchParams: criteriaMatchesSearchParams,
      };
      store.dispatch(criteriaMatchesAction);
    });
    apiUtils.dispatchFundSearch(store, { searchParams: totalMatchesSearchParams, currentPageNumber: 1 });
  }

  if (callNextAction) {
    next(action);
  }
};

apiMethods[Types.REMOVE_CRITERION] = (store, next, action) => {
  let filterVals = {};
  const state = store.getState();
  if (state.activeCriteria.length > RESULT_FEATURES.MIN_CRITERIA_COUNT) {
    const removedCritIndex = _.findIndex(state.activeCriteria, critId => critId === action.critId);
    const activeCriteria = _.filter(state.activeCriteria, critId => critId !== action.critId);
    const criteriaDefinitions = getCriteriaDefinitions(state);
    filterVals = _.transform(
      activeCriteria,
      (result, critId, index) => {
        const critDef = getCriterionDefinition(criteriaDefinitions, critId) || {};
        _.assign(result, _.pick(state.criteriaFilters, _.keys(critDef.filterGroups)));
        if (index >= removedCritIndex) {
          const filterGroups = critDef.filterGroups;
          _.forEach(filterGroups, (filterGroup, filterGroupId) => {
            const totalMatchesAction = {
              type: 'TOTAL_MATCHES_API',
              currentCrit: filterGroupId,
              searchParams: apiUtils.constructSearchParams(state, { critFilters: result }),
            };
            const criteriaMatchesAction = {
              type: 'CRITERIA_MATCHES_API',
              currentCrit: filterGroupId,
              searchParams: apiUtils.constructSearchParams(state, {
                critFilters: _.pickBy(result, (filters, id) => id !== filterGroupId),
              }),
            };
            store.dispatch(totalMatchesAction);
            store.dispatch(criteriaMatchesAction);
          });
        }
      },
      {},
    );
    apiUtils.dispatchFundSearch(store, {
      searchParams: apiUtils.constructSearchParams(state, { critFilters: filterVals }),
      currentPageNumber: 1,
    });
  }
  next(action);
};

apiMethods[Types.UPDATE_FILTERS] = apiMethods[Types.UPDATE_RANGE_INPUTS] = (store, next, action) => {
  const state = store.getState();
  // Determine which criteria need updated matches calls
  const activeCriteria = state.activeCriteria;
  const critFilters = state.criteriaFilters;
  const critDefinitions = getCriteriaDefinitions(state);
  const updatedCritId = CRITERIA_IDS_BY_FILTER_GROUP[action.filterGroupId];
  const updatedCritIndex = _.findIndex(activeCriteria, critId => critId === updatedCritId);
  const newSelections = action.filterGroupSelections;

  // Kick off calls for getting total matches for the ones that need to be updated
  const filterVals = _.transform(
    activeCriteria,
    (result, critId, index) => {
      const filterGroups = _.get(getCriterionDefinition(critDefinitions, critId), ['filterGroups']);
      _.forEach(filterGroups, (filterGroup, filterGroupId) => {
        const filterVals = (() => {
          const added = _.get(newSelections, [filterGroupId], critFilters[filterGroupId]);
          const updates = _.get(action, ['filterUpdates', filterGroupId]);
          return _.isNull(updates) ? null : added;
        })();
        if (!_.isNil(filterVals)) {
          result[filterGroupId] = filterVals;
        }
        // Get new total matches for updated crit and those "below"
        if (index >= updatedCritIndex) {
          const totalMatchesAction = {
            type: 'TOTAL_MATCHES_API',
            currentCrit: filterGroupId,
            searchParams: apiUtils.constructSearchParams(state, { critFilters: result }),
          };
          const criteriaMatchesAction = {
            type: 'CRITERIA_MATCHES_API',
            currentCrit: filterGroupId,
            searchParams: apiUtils.constructSearchParams(state, {
              critFilters: _.pickBy(result, (filters, id) => id !== filterGroupId),
            }),
          };
          store.dispatch(totalMatchesAction);
          // Get new filter-level matches for all criteria "below" the one that was just updated
          // TODO: find way to defer these calls until after the criterion gets expanded
          store.dispatch(criteriaMatchesAction);
        }
      });
    },
    {},
  );

  apiUtils.dispatchFundSearch(store, {
    searchParams: apiUtils.constructSearchParams(state, { critFilters: filterVals }),
    currentPageNumber: 1,
  });
  next(action);
};

apiMethods[Types.UPDATE_CRITERIA] = apiMethods[Types.LOAD_STRATEGY] = (store, next, action) => {
  let callNextAction = true;
  let filterVals = {};
  const state = store.getState();
  const { critIdArray, filterGroupSelections } = action;
  if (critIdArray.includes('fundFamily') && state.fundFamilyFilters.length <= 0) {
    store.dispatch({ type: Types.FUNDFAMILY_API, callbackAction: action });
    callNextAction = false;
  } else {
    //Kick off matches calls for all of the criteria
    //TODO: Only make matches calls for criteria that need matches
    const critDefinitions = getCriteriaDefinitions(state);
    filterVals = _.transform(
      critIdArray,
      (filterValAccumulator, critId) => {
        const filterGroups = getCriterionDefinition(critDefinitions, critId).filterGroups;
        _.forEach(filterGroups, (filterGroup, filterGroupId) => {
          if (!_.has(filterGroupSelections, filterGroupId)) return;

          const criteriaMatchesOptions = { critFilters: { ...filterValAccumulator } };
          const criteriaMatchesSearchParams = apiUtils.constructSearchParams(state, criteriaMatchesOptions);
          const criteriaMatchesAction = {
            type: 'CRITERIA_MATCHES_API',
            currentCrit: filterGroupId,
            searchParams: criteriaMatchesSearchParams,
          };
          store.dispatch(criteriaMatchesAction);

          filterValAccumulator[filterGroupId] = filterGroupSelections[filterGroupId];

          const totalMatchesOptions = { critFilters: { ...filterValAccumulator } };
          const totalMatchesSearchParams = apiUtils.constructSearchParams(state, totalMatchesOptions);
          const totalMatchesAction = {
            type: 'TOTAL_MATCHES_API',
            currentCrit: filterGroupId,
            searchParams: totalMatchesSearchParams,
          };
          store.dispatch(totalMatchesAction);
        });
      },
      {},
    );
  }
  apiUtils.dispatchFundSearch(store, {
    searchParams: apiUtils.constructSearchParams(state, { critFilters: filterVals }),
    currentPageNumber: 1,
    subjectAreas: apiUtils.getSubjectAreas(state, {
      viewTabShortName: action.viewTabShortName,
      displayType: action.displayType,
    }),
    sortBy: _.get(action, ['sortParams', 'column'], null),
    sortOrder: _.get(action, ['sortParams', 'direction'], null),
  });

  if (callNextAction) {
    next(action);
  }
};

// helper functions for ROUTER_LOCATION_CHANGED apiMethods

const loadStrategyMatchCounts = (store, state) => {
  const strategiesMatches = getAllStrategiesMatches(state);
  _.each(FUND_STRATEGIES_CONFIG, strategy => {
    if (strategy.isNestedStrategy || !_.isNil(strategiesMatches[strategy.id])) return;
    let critFilters = {};
    _.each(strategy.filterGroupSelections, selection => (critFilters[selection.criteriaId] = selection.value));
    const searchParams = apiUtils.constructSearchParams(state, { critFilters });
    if (strategy.id === 'sociallyResponsibleStrategy') {
      const esgCodesStr = apiUtils.getSociallyResponsibleAttributesValues();
      if (esgCodesStr) {
        delete searchParams['sociallyResponsible'];
        searchParams['esgCodes'] = esgCodesStr;
      }
    }
    const strategyMatchesAction = Creators.strategyMatchesApi(strategy.id, searchParams);
    store.dispatch(strategyMatchesAction);
  });
};

const getCriteriaFromQuery = criteria => {
  return _.chain(criteria)
    .pickBy((value, key) => _.has(CRITERIA_IDS_BY_FILTER_GROUP, key))
    .mapValues(filterVals => {
      const formatFilterVals = filterVals === 'MFN,ETF' ? filterVals.split(' ') : filterVals.split(',');
      return filterVals.length === 0 ? [] : formatFilterVals;
    })
    .value();
};

const loadFundSearch = (store, state, action, filterVals, criteria) => {
  const { displayType } = action.payload.params;
  //pull params from query string
  const { viewTab, sortBy, sortOrder, pageNum } = action.payload.params;
  let searchParamsFromRoute = apiUtils.constructSearchParams(state, { critFilters: filterVals });

  if (displayType === 'compare') {
    let selectedFundIds = criteria[FUND_ID_FIELD];
    if (!_.isNil(selectedFundIds) && selectedFundIds !== '') {
      selectedFundIds = selectedFundIds.split(',');
      selectedFundIds = _.chain(selectedFundIds)
        .filter(fund => fund !== '')
        .uniq()
        .value();
    } else {
      selectedFundIds = [];
    }
    apiUtils.dispatchCompareViewSearch(
      store,
      {
        selectedFundIds,
        subjectAreas: apiUtils.getSubjectAreas(state, { viewTabShortName: viewTab, displayType: displayType }),
      },
      action,
    );
  } else if (displayType === 'table' || displayType === 'card') {
    apiUtils.dispatchFundSearch(
      store,
      {
        subjectAreas: apiUtils.getSubjectAreas(state, { viewTabShortName: viewTab, displayType: displayType }),
        searchParams: searchParamsFromRoute,
        displayType,
        sortBy,
        sortOrder,
        currentPageNumber: pageNum,
      },
      action,
    );
  }
};

apiMethods['@@router/LOCATION_CHANGE'] = (store, next, action) => {
  let callNextAction = true;
  let filterVals = {};
  const state = store.getState();
  const { error, loading } = state;
  const criteria = action.filterGroupSelections;
  const fetchFundFamily = state.fundFamilyFilters.length <= 0 && _.isNil(error.fundFamily) && !loading.fundFamily;
  const fetchFundType = state.fundTypeFilters.length <= 0 && _.isNil(error.fundType) && !loading.fundType;
  const fetchPolicyInfo =
    state.policyInfo.length <= 0 &&
    _.isNil(error.policyInfo) &&
    !loading.policyInfo &&
    ENDPOINTS.CLASSIFICATION_POLICY_INFO;

  const fetchEsgGroups =
    !state.esgGroupsReplacements.done &&
    _.isNil(error.esgGroups) &&
    !loading.esgGroups &&
    ENDPOINTS.CLASSIFICATION_ESG_GROUPS;

  const hasPersistenQueries = _.every(PERSISTENT_QUERIES, key => action.payload.query[key]);
  if (fetchEsgGroups) {
    store.dispatch({ type: Types.ESGGROUPS_CLASSIFICATION_API, callbackAction: action });
    callNextAction = false;
  } else if ((fetchFundFamily || fetchFundType || fetchPolicyInfo) && hasPersistenQueries) {
    store.dispatch({ type: Types.FUND_CLASSIFICATION_API, callbackAction: action });
    callNextAction = false;
  } else if (fetchPolicyInfo) {
    store.dispatch({ type: Types.POLICY_INFO_API, callbackAction: action });
    callNextAction = false;
  } else {
    // load strategy match counts if location changes to strategies
    if (action.payload.location.pathname.match('^/strategies$')) {
      loadStrategyMatchCounts(store, state);
    }
    if (
      _.get(action, ['payload', 'location', 'state', 'source']) === 'routerMiddleware' &&
      !_.get(action, ['payload', 'location', 'state', 'shouldFetchData']) &&
      _.get(action, ['payload', 'action']) !== 'POP'
    ) {
      next(action);
      return;
    }
    const criteriaFromQuery = getCriteriaFromQuery(criteria);
    const { displayType } = action.payload.params;
    if (displayType === 'compare') {
      filterVals[FUND_IDS_FIELD] = criteriaFromQuery[FUND_IDS_FIELD];
    } else {
      const critDefinitions = getCriteriaDefinitions(state);
      const critIdArray = _.uniq(_.map(action.critOrder, crit => CRITERIA_IDS_BY_FILTER_GROUP[crit]));
      filterVals = _.transform(
        critIdArray,
        (filterValAccumulator, critId) => {
          const filterGroups = _.get(getCriterionDefinition(critDefinitions, critId), ['filterGroups']);
          _.forEach(filterGroups, (filterGroup, filterGroupId) => {
            if (!_.includes(action.critOrder, filterGroupId)) return;

            const criteriaMatchesOptions = { critFilters: { ...filterValAccumulator } };
            const criteriaMatchesSearchParams = apiUtils.constructSearchParams(state, criteriaMatchesOptions);
            const criteriaMatchesAction = {
              type: 'CRITERIA_MATCHES_API',
              currentCrit: filterGroupId,
              searchParams: criteriaMatchesSearchParams,
            };
            store.dispatch(criteriaMatchesAction);

            filterValAccumulator[filterGroupId] = criteriaFromQuery[filterGroupId];

            const totalMatchesOptions = { critFilters: { ...filterValAccumulator } };
            const totalMatchesSearchParams = apiUtils.constructSearchParams(state, totalMatchesOptions);
            const totalMatchesAction = {
              type: 'TOTAL_MATCHES_API',
              currentCrit: filterGroupId,
              searchParams: totalMatchesSearchParams,
            };
            store.dispatch(totalMatchesAction);
          });
        },
        {},
      );

      if (_.isEmpty(filterVals)) {
        // if we have no filters, we still want to display the total match count
        const searchParams = apiUtils.constructSearchParams(state, { critFilters: filterVals });
        store.dispatch({ type: 'TOTAL_MATCHES_API', searchParams });
      }
    }
    loadFundSearch(store, state, action, filterVals, criteria);
  }

  if (callNextAction) {
    next(action);
  }
};

apiMethods[Types.UPDATE_VIEW_TAB] = (store, next, action) => {
  const state = store.getState();
  apiUtils.dispatchFundSearch(store, {
    viewTabShortName: action.viewTabShortName,
    subjectAreas: apiUtils.getSubjectAreas(state, { viewTabShortName: action.viewTabShortName }),
  });
  next(action);
};

apiMethods[Types.SORT_RESULTS] = (store, next, action) => {
  apiUtils.dispatchFundSearch(store, {
    sortBy: action.column,
    sortOrder: action.direction,
    currentPageNumber: 1,
  });
  next(action);
};

apiMethods[Types.UPDATE_RESULTS_PAGE_NUM] = (store, next, action) => {
  apiUtils.dispatchFundSearch(store, {
    currentPageNumber: action.pageNum,
  });
  next(action);
};

apiMethods[Types.UPDATE_MOBILE_STATUS] = (store, next, action) => {
  if (action.isMobile && store.getState().route === ROUTE_CONSTANTS.RESULTS) {
    apiUtils.dispatchFundSearch(store, {
      viewTabShortName: action.viewTabShortName,
      displayType: action.displayType,
      isMobile: action.isMobile,
    });
  }
  next(action);
};

apiMethods[Types.SHOW_COMPARE_VIEW] = (store, next, action) => {
  const state = store.getState();
  apiUtils.dispatchCompareViewSearch(store, {
    subjectAreas: apiUtils.getSubjectAreas(state, {
      viewTabShortName: action.viewTabShortName,
      displayType: 'compare',
    }),
  });
  next(action);
};

apiMethods[Types.ADD_SYMBOL] = (store, next, action) => {
  apiUtils.dispatchCompareViewSearch(store, { symbolToAdd: action.symbol });
  next(action);
};

apiMethods[Types.UPDATE_FUNDS_SELECTED] = (store, next, action) => {
  const state = store.getState();
  if (getDisplayType(state) === 'compare') {
    const selectedFundIds = _.map(action.fundsSelected, FUND_ID_FIELD);
    apiUtils.dispatchCompareViewSearch(store, { selectedFundIds });
  }
  next(action);
};

apiMethods[Types.SHOW_FULL_RESULTS_VIEW] = (store, next, action) => {
  const state = store.getState();
  apiUtils.dispatchFundSearch(store, {
    displayType: 'table',
    subjectAreas: apiUtils.getSubjectAreas(state, {
      viewTabShortName: action.viewTabShortName,
      displayType: 'table',
    }),
  });
  next(action);
};

apiMethods[Types.UPDATE_DISPLAY_TYPE] = (store, next, action) => {
  const state = store.getState();
  apiUtils.dispatchFundSearch(store, {
    displayType: action.displayType,
    subjectAreas: apiUtils.getSubjectAreas(state, {
      viewTabShortName: action.viewTabShortName,
      displayType: action.displayType,
    }),
  });
  next(action);
};

apiMethods[Types.QUICK_CRITERIA_INITIAL_MATCHES_API] = (store, next, action) => {
  const state = store.getState();
  const qcmSelections = (() => {
    if (!state.quickCriteriaMenu.isMadLibsActive) {
      return getQuickCriteriaParsedSelections(state).filterGroupSelections;
    } else {
      const totalSelections = allMadLibsDataStructureFormatting(state.quickCriteriaMenu, action);
      return buildCriteriaSelections({
        selections: _.omit(totalSelections, 'activeFundType'),
        activeFundType: totalSelections.activeFundType,
      });
    }
  })();
  const searchParams = apiUtils.constructSearchParams(state, { critFilters: qcmSelections });
  if (searchParams['sociallyResponsible']) {
    const esgCodesStr = apiUtils.getSociallyResponsibleAttributesValues();
    if (esgCodesStr) {
      delete searchParams['sociallyResponsible'];
      searchParams['esgCodes'] = esgCodesStr;
    }
    if (!searchParams['ntf'] && !searchParams['transactionFeeIndicator']) {
      searchParams['ntf'] = 'Y';
    }
  }
  const qcmMatchesAction = Creators.quickCriteriaMatchesApi(searchParams);
  store.dispatch(qcmMatchesAction);
  next(action);
};

apiMethods[Types.FUND_PICKS_FROM_FIDELITY_INITIAL_MATCHES_API] = (store, next, action) => {
  const state = store.getState();
  const qcmSelections = (() => {
    return getFundPicksParsedSelections(state).filterGroupSelections;
  })();
  const searchParams = apiUtils.constructSearchParams(state, { critFilters: qcmSelections });
  const qcmMatchesAction = Creators.fundPicksFromFidelityMatchesApi(searchParams);
  store.dispatch(qcmMatchesAction);
  next(action);
};

apiMethods[Types.FUND_TYPE_API_SUCCESS] = (store, next, action) => {
  const state = store.getState();
  const qcmSelections = (() => {
    return getFundPicksParsedSelections(state).filterGroupSelections;
  })();
  if (action['payload'] && action['payload']['data'] && action['payload']['data'].length > 0) {
    let assetClasses = [];
    action['payload']['data'].forEach(assetClass => {
      assetClasses.push(assetClass.code);
    });
    const searchParams = apiUtils.constructSearchParams(state, { critFilters: qcmSelections });
    searchParams['assetClass'] = assetClasses.join(',');
    searchParams['retryCount'] = 1;
    searchParams['fundApiData'] = action['payload']['data'];
    let qcmMatchesAction = Creators.fundPicksFromFidelityLoadingApi(searchParams);
    store.dispatch(qcmMatchesAction);
  }
  next(action);
};

apiMethods[Types.FUND_PICKS_FROM_FIDELITY_LOADING_API_ERROR] = (store, next, action) => {
  if (action['params']) {
    let params = action['params'];
    if (params.retryCount < 5) {
      setTimeout(() => {
        const state = store.getState();
        const qcmSelections = (() => {
          return getFundPicksParsedSelections(state).filterGroupSelections;
        })();
        const searchParams = apiUtils.constructSearchParams(state, { critFilters: qcmSelections });
        searchParams['assetClass'] = params.assetClass;
        searchParams['fundApiData'] = params.fundApiData;
        searchParams['retryCount'] = params.retryCount + 1;
        let qcmMatchesAction = Creators.fundPicksFromFidelityLoadingApi(searchParams);
        store.dispatch(qcmMatchesAction);
      }, 1000);
    }
  }
  next(action);
};

apiMethods[Types.QUICK_CRITERIA_UPDATE_ACTIVE_FUND_TYPE] = (store, next, action) => {
  const state = store.getState();
  const { fundType } = action;
  let selectedAssetClass = '';
  let value = [];

  const prevSelections = state.quickCriteriaMenu.selections;

  if (fundType === 'category') {
    selectedAssetClass = 'All Asset Classes';
  }

  if (fundType === 'risk') {
    value = [prevSelections.risk];
  }

  const nextSelections = { [fundType]: value };
  const totalSelections = {};
  _.defaults(totalSelections, nextSelections, prevSelections);

  const qcmSelections = buildCriteriaSelections({
    selections: totalSelections,
    activeFundType: fundType,
    selectedAssetClass,
  });

  const searchParams = apiUtils.constructSearchParams(state, { critFilters: qcmSelections });

  action.selections = qcmSelections[fundType];

  const qcmMatchesAction = Creators.quickCriteriaMatchesApi(searchParams);
  store.dispatch(qcmMatchesAction);
  next(action);
};

apiMethods[Types.QUICK_CRITERIA_UPDATE_ASSET_CLASS] = (store, next, action) => {
  const state = store.getState();
  const { categories } = action;

  const nextSelections = { category: categories };
  const prevSelections = store.getState().quickCriteriaMenu.selections;
  const totalSelections = {};
  _.defaults(totalSelections, nextSelections, prevSelections);

  const qcmSelections = buildCriteriaSelections({
    selections: totalSelections,
    activeFundType: store.getState().quickCriteriaMenu.activeFundType,
  });

  const searchParams = apiUtils.constructSearchParams(state, { critFilters: qcmSelections });
  const qcmMatchesAction = Creators.quickCriteriaMatchesApi(searchParams);
  store.dispatch(qcmMatchesAction);
  next(action);
};

apiMethods[Types.QUICK_CRITERIA_UPDATE_SELECTIONS] = (store, next, action) => {
  const state = store.getState();
  const { critId, selections } = action;
  const nextSelections = { [critId]: selections };
  const prevSelections = store.getState().quickCriteriaMenu.selections;
  const totalSelections = {};

  _.defaults(totalSelections, nextSelections, prevSelections);
  const qcmSelections = buildCriteriaSelections({
    selections: totalSelections,
    activeFundType: store.getState().quickCriteriaMenu.activeFundType,
  });

  const searchParams = apiUtils.constructSearchParams(state, { critFilters: qcmSelections });
  if (searchParams['sociallyResponsible']) {
    const esgCodesStr = apiUtils.getSociallyResponsibleAttributesValues();
    if (esgCodesStr) {
      delete searchParams['sociallyResponsible'];
      searchParams['esgCodes'] = esgCodesStr;
    }
    if (!searchParams['ntf'] && !searchParams['transactionFeeIndicator']) {
      searchParams['ntf'] = 'Y';
    }
  }
  const qcmMatchesAction = Creators.quickCriteriaMatchesApi(searchParams);
  store.dispatch(qcmMatchesAction);
  next(action);
};

apiMethods[Types.QUICK_CRITERIA_UPDATE_MAD_LIBS_ADDITIONAL_CRITERIA] = apiMethods[
  Types.QUICK_CRITERIA_UPDATE_MAD_LIBS_SELECTIONS
] = (store, next, action) => {
  const state = store.getState();
  const totalSelections = allMadLibsDataStructureFormatting(state.quickCriteriaMenu, action);

  const qcmSelections = buildCriteriaSelections({
    selections: _.omit(totalSelections, 'activeFundType'),
    activeFundType: totalSelections.activeFundType,
  });

  const searchParams = apiUtils.constructSearchParams(state, { critFilters: qcmSelections });
  if (searchParams['sociallyResponsible']) {
    const esgCodesStr = apiUtils.getSociallyResponsibleAttributesValues();
    if (esgCodesStr) {
      delete searchParams['sociallyResponsible'];
      searchParams['esgCodes'] = esgCodesStr;
    }
  }
  const qcmMatchesAction = Creators.quickCriteriaMatchesApi(searchParams);
  store.dispatch(qcmMatchesAction);
  next(action);
};

apiMethods[Types.UPDATE_RESULT_TOGGLE_GROUP] = (store, next, action) => {
  //if current sort field is in the result toggle group => need to replace sort field with current selection:
  //get current sort field
  const currentSortField = store.getState().sortResults.sortCol;
  // get config for current toggle group that was updated
  const toggleGroupConfig = TOGGLE_GROUP_CONFIG[action.resultGroup];
  //get sort fields for all results in the toggle group
  const toggleGroupSortFields = _.map(toggleGroupConfig.resultList, result => {
    return result.sortField;
  });
  //determine if sort field is in the toggle group that was just updated
  const replaceSortField = _.includes(toggleGroupSortFields, currentSortField);

  if (replaceSortField) {
    //get sort field for new selection
    const newSortField = _.find(toggleGroupConfig.resultList, result => {
      return result.id === action.selection.id;
    }).sortField;
    apiUtils.dispatchFundSearch(store, {
      sortBy: newSortField,
      // sortOrder: action.direction,
      currentPageNumber: 1,
    });
    action.newSortField = newSortField;
  }
  next(action);
};

apiMethods[Types.UPDATE_PRICING_MODEL] = (store, next, action) => {
  const policyCode = action.productCode;
  store.dispatch({ type: Types.FUND_CLASSIFICATION_API, policyCode });
  store.dispatch({ type: Types.FUND_APPLICATION_DISCLOSURE_API, policyCode });
  apiUtils.dispatchFundSearch(store, { policyCode });
  const searchParams = apiUtils.constructSearchParams(store.getState(), null);

  store.dispatch({
    type: 'TOTAL_MATCHES_API',
    searchParams,
    policyCode,
  });

  next(action);
};

apiMethods[Types.CLEAR_ALL] = (store, next, action) => {
  if (action.goToStart) {
    // FFLI is the landing page dummy code
    store.dispatch({ type: Types.FUND_APPLICATION_DISCLOSURE_API, policyCode: 'FFLI' });
  }

  next(action);
};

const apiServiceMiddleware = store => next => action => {
  const apiMethod = _.get(apiMethods, action.type);
  if (_.isFunction(apiMethod)) {
    apiMethod(store, next, action);
  } else {
    next(action);
  }
};

export default apiServiceMiddleware;
