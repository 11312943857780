import { createReducer, Types as ReduxSauceTypes } from 'reduxsauce';
import _ from 'lodash';
import Types from 'actions/Types';

export const INITIAL_STATE = {
  strategyId: null,
  isModified: false,
};

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};

const loadStrategy = (state = INITIAL_STATE, action = {}) => {
  return { strategyId: action.strategyId, isModified: false };
};

const setModalStrategyToModified = (state = INITIAL_STATE, action = {}) => {
  if (!_.isNil(state.strategyId)) {
    return { ...state, isModified: true };
  } else {
    return state;
  }
};

const clearAll = (state = INITIAL_STATE) => {
  return INITIAL_STATE;
};

const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
  if (action.payload.location.state && action.payload.location.state.source === 'routerMiddleware') {
    return state;
  }
  if (!action.payload.location.pathname.match("^/results")) {
    return clearAll(state);
  }
  
  if (!_.isNil(action.strategyInfo)) {
    const strategyParts = action.strategyInfo.split('_');
    const isModified = strategyParts.length > 1 && strategyParts[1] === 'modified';
    if (isModified) {
      return { strategyId: strategyParts[0], isModified: true };
    }
    return { strategyId: action.strategyInfo, isModified: false };
  }

  return state;
};

const ACTION_HANDLERS = {
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
  [Types.LOAD_STRATEGY]: loadStrategy,
  [Types.ADD_CRITERION]: setModalStrategyToModified,
  [Types.REMOVE_CRITERION]: setModalStrategyToModified,
  [Types.UPDATE_CRITERIA]: setModalStrategyToModified,
  [Types.UPDATE_FILTERS]: setModalStrategyToModified,
  [Types.UPDATE_RANGE_INPUTS]: setModalStrategyToModified,
  "@@router/LOCATION_CHANGE": routerLocationChanged,
  [Types.CLEAR_ALL]: clearAll,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

// ---- Selectors ----

export const getFundStrategiesState = state => state.fundStrategies;
export const getIfStrategyIsModified = state => state.fundStrategies.isModified;
