import _ from 'lodash';
import screenerConfig from 'screenerConfig';
import { traverseNestedCriteria } from 'utilities/criteriaUtils';

const getCRITERIA_CONFIG = (criteriaConfig) =>{
  const ntf = criteriaConfig.find(e=>e.id === 'ntf');
  const ntfIndex = criteriaConfig.findIndex(e=>e.id === 'ntf');
  const transactionFeeIndicator1 = criteriaConfig.find(e=>e.id === 'transactionFeeIndicator');
  if (ntf && !transactionFeeIndicator1){
    let transactionFeeIndicator = _.cloneDeepWith(ntf);
    transactionFeeIndicator.id = 'transactionFeeIndicator';
    transactionFeeIndicator.image='dollar';
    transactionFeeIndicator.label='Transaction Fee or Load';
    transactionFeeIndicator.defaultFilterGroupSelections['transactionFeeIndicator'] = transactionFeeIndicator.defaultFilterGroupSelections['ntf'];
    delete transactionFeeIndicator.defaultFilterGroupSelections['ntf'];
    transactionFeeIndicator.defaultFilterGroupSelections['transactionFeeIndicator'][0].key='transactionFeeIndicator';
    transactionFeeIndicator.defaultFilterGroupSelections['transactionFeeIndicator'][0].label="Display Transaction Fee or Load";
    transactionFeeIndicator.filterGroups['transactionFeeIndicator'] = transactionFeeIndicator.filterGroups['ntf'];
    delete transactionFeeIndicator.filterGroups['ntf'];
    transactionFeeIndicator.filterGroups['transactionFeeIndicator'][0].key='transactionFeeIndicator';
    transactionFeeIndicator.filterGroups['transactionFeeIndicator'][0].label="Display Transaction Fee or Load";
    criteriaConfig.splice(ntfIndex+1,0,transactionFeeIndicator);
  }
  return criteriaConfig;
}

export const CRITERIA_CONFIG = getCRITERIA_CONFIG(screenerConfig.criteriaConfig);

// Add some computed values to CRITERIA_CONFIG
traverseNestedCriteria(CRITERIA_CONFIG, (criterion, parents) => {
  criterion.criteriaGroupLabel = _.get(parents, [0, 'label']);
  criterion.criteriaSubGroupLabel = _.get(parents, [1, 'label']);
  if (_.isEmpty(criterion.defaultFilterGroupSelections) || _.isNil(criterion.defaultFilterGroupSelections)) {
    // Add empty list with id as key for defaultFilterGroupSelections when none exist
    criterion.defaultFilterGroupSelections = { [criterion.id]: [] };
  }
});

export const FLATTENED_CRITERIA_LIST = (() => {
  const flatList = [];
  traverseNestedCriteria(CRITERIA_CONFIG, criterion => flatList.push(criterion));
  return flatList;
})();

export const ALL_CRITERIA = _.keyBy(FLATTENED_CRITERIA_LIST, 'id');

export const CRITERIA_IDS_BY_FILTER_GROUP = _.transform(
  ALL_CRITERIA,
  (result, criteria) => {
    _.forEach(criteria.filterGroups, (filters, filterGroupId) => {
      result[filterGroupId] = criteria.id;
    });
  },
  {},
);

export const FILTER_GROUP_IDS = _.keys(CRITERIA_IDS_BY_FILTER_GROUP);

export const CRITERIA_DICTIONARY = (() => {
  const dictionary = {};

  traverseNestedCriteria(CRITERIA_CONFIG, (criterion, parents) => {
    if (criterion.id === 'fundType') {
      if (!_.isNil(criterion.filterGroups.category)) {
        dictionary[criterion.label + ' (by Category)'] = 'category';
      }
      if (!_.isNil(criterion.filterGroups.risk)) {
        dictionary[criterion.label + ' (by Risk)'] = 'risk';
      }
    } else if (parents.length >= 2) {
      const subGroup = _.get(parents, parents.length - 1);
      const label = `${subGroup.label} (${criterion.label})`;
      dictionary[label] = criterion.id;
    } else {
      const label = criterion.label;
      dictionary[label] = criterion.id;
    }
  });

  return dictionary;
})();

export const CRITERIA_IDS = _.transform(
  ALL_CRITERIA,
  (result, criteria, id) => {
    if (id === 'fundType') {
      // include the category and risk sub-criteria
      const categoryId = _.get(criteria, ['category', 'id'], null);
      const riskId = _.get(criteria, ['risk', 'id'], null);
      const subCriteriaIds = _.filter([categoryId, riskId], id => !_.isNil(id));
      result.push(...subCriteriaIds);
    } else {
      result.push(id);
    }
  },
  [],
);

export const CRITERIA_BY_GROUP = (() => {
  const groups = _.filter(CRITERIA_CONFIG, item => item.criteriaMenuItemType === 'group');
  const basicCriteria = _.filter(CRITERIA_CONFIG, item => item.criteriaMenuItemType === 'criterion');
  const groupsById = {
    basicCriteria: {
      criteriaMenuItemList: basicCriteria,
      criteriaMenuItemType: 'group',
      id: 'basicCriteria',
      label: 'Basic Criteria',
    },
  };
  _.assign(groupsById, _.keyBy(groups, 'id'));

  groupsById.basicCriteria = {
    criteriaMenuItemList: basicCriteria,
    criteriaMenuItemType: 'group',
    id: 'basicCriteria',
    label: 'Basic Criteria',
  };
  return groupsById;
})();

export const CRITERIA_BY_ID = _.keyBy(CRITERIA_CONFIG, 'id');

