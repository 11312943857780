import React from 'react';
import PropTypes from 'prop-types';
import QuickCriteriaIconFilter from 'components/StartPage/QuickCriteriaIconFilter';
import GlossaryDefinitionPopover from '../Shared/GlossaryDefinitionPopover';

const _renderIconFilter = (criteriaName, valuesSelected, handleClick, icon = 'box') => {
  return (
    <QuickCriteriaIconFilter
      icon={icon}
      handleClick={handleClick}
      criteriaName={criteriaName}
      valuesSelected={valuesSelected}
    />
  );
};

const QuickCriteriaMorningstarRatings = ({ handleClick, ratingsSelected, returnsSelected, expensesSelected }) => {
  const ratingsFilters = _renderIconFilter('mStarRating', ratingsSelected, handleClick, 'star');
  const returnsFilters = _renderIconFilter('returns', returnsSelected, handleClick);
  const expensesFilters = _renderIconFilter('expenses', expensesSelected, handleClick);

  return (
    <div className="quick-criteria-morningstar-ratings">
      <div>
        <span className="large-header">Morningstar Ratings</span>
        <GlossaryDefinitionPopover
          glossary="Morningstar Ratings"
          ariaLabel="Morningstar Ratings"
          container="quick-criteria-morningstar-ratings"
        />
      </div>
      <div className="morningstar-disclaimer">Compared to Morningstar category average</div>
      <div className="container">
        <div className="row">
          <div className="rating">
            <div className="small-header col">Overall</div>
            {ratingsFilters}
          </div>
        </div>
        <div className="row">
          <div className="returns">
            <div className="small-header col">Returns</div>
            {returnsFilters}
          </div>
        </div>
        <div className="row">
          <div className="expenses">
            <div className="small-header col">Expenses</div>
            {expensesFilters}
          </div>
        </div>
      </div>
    </div>
  );
};

QuickCriteriaMorningstarRatings.propTypes = {
  /** Array representing all Morningstar ratings selected */
  ratingsSelected: PropTypes.array.isRequired,

  /** Array representing all Morningstar returns selected */
  returnsSelected: PropTypes.array.isRequired,

  /** Array representing all Morningstar expenses selected */
  expensesSelected: PropTypes.array.isRequired,

  /** Handle selecting a criteria filter */
  handleClick: PropTypes.func.isRequired,
};

export default QuickCriteriaMorningstarRatings;
