import _ from 'lodash';
import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import ActiveCriterionContainer from 'containers/ActiveCriterionContainer';

class ActiveCriteria extends Component {
  _renderActiveCriteria = () => {
    return this.props.activeCriteria.map(crit => {
      const isExpanded = crit.id === this.props.expandedCriterion;

      return (
        <ActiveCriterionContainer
          criterion={crit}
          key={crit.id}
          onCritRemoved={this.props.onCritRemoved}
          isExpanded={isExpanded}
          updateExpandedCrit={this.props.updateExpandedCrit}
        />
      );
    });
  };

  componentDidUpdate = () => {
    if (this.props.focusCriteria) {
      let focusCriteria = document.getElementById(this.props.focusCriteria);
      if (_.isNil(focusCriteria) === false) {
        focusCriteria.querySelector('.active-criterion-label-text').focus();
        this.props.setFocusCriteria();
      }
    }
  };

  handleClearAll = e => {
    e.preventDefault();
    this.props.onClearAll();
  };

  render() {
    const { activeCriteria } = this.props;
    const hideTotalMatchesClass = activeCriteria.length ? '' : 'off';
    const totalMatches = !_.isNil(this.props.totalMatches) ? this.props.totalMatches : '--';
    const clearAllDisplay = activeCriteria.length === 0 ? 'off' : '';
    const noCriteriaDisplay = activeCriteria.length === 0 ? '' : 'off';

    return (
      <div className="active-criteria-menu">
        <div className="title">
          <span>Selected Criteria:</span>
          <div className={`clear-all ${clearAllDisplay}`}>
            <button
              className="as-link"
              type="button"
              onClick={this.handleClearAll}
              aria-label="criteria filter clear all"
            >
              Clear All
            </button>
          </div>
        </div>
        <div className={`no-criteria ${noCriteriaDisplay}`}>
          <div>No criteria applied</div>
          <div>Start a new screen by selecting criteria below.</div>
        </div>
        <div className="active-criteria">{this._renderActiveCriteria()}</div>
        <div className={`matches-container ${hideTotalMatchesClass}`}>
          <span className="matches-label strong">MATCHES:</span>
          <span className="matches-value"> {totalMatches}</span>
        </div>
      </div>
    );
  }
}

ActiveCriteria.propTypes = {
  /** An array representing active fully formed criteria objects */
  activeCriteria: PropTypes.array.isRequired,

  /** The currently expanded criterion */
  expandedCriterion: PropTypes.string.isRequired,

  /** Function that clears all active criteria */
  onClearAll: PropTypes.func.isRequired,

  /** Redux function that is called when a criteria is removed */
  onCritRemoved: PropTypes.func.isRequired,

  /** The total matches of all the active criteria */
  totalMatches: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /** Updates the currently expanded criteria */
  updateExpandedCrit: PropTypes.func.isRequired,
};

export default ActiveCriteria;
