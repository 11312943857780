import { connect } from 'react-redux';
import QuickCriteriaMenu from 'components/StartPage/QuickCriteriaMenu';
import { getCriteriaDefinitions } from 'reducers/selectors/CriteriaSelectors';
import Creators from 'actions/CriteriaActions';
import { getLearnMoreDisclosureContent } from 'reducers/ApplicationDisclosuresReducer';
import { getAssetClassDisclosureMap, getAssetClassDisclosureModalContent } from 'reducers/DatavalueDisclosuresReducer';
import {
  getQuickCriteriaSelections,
  getQuickCriteriaActiveFundType,
  getQuickCriteriaSelectedAssetClass,
  getQuickCriteriaModalState,
  getQuickCriteriaUserSelectedCriteria,
  getQuickCriteriaParsedSelections,
  getIsAssetClassCategoryChanged
} from 'reducers/QuickCriteriaMenuReducer';
import * as odaTracking from 'utilities/odaTracking';
import { CRITERIA_SOURCE } from 'utilities/odaUtils';
import { getIsMadLibsActive } from '../reducers/QuickCriteriaMenuReducer';

const mapStateToProps = (state, ownProps) => {
  return {
    activeFundType: getQuickCriteriaActiveFundType(state),
    allCriteria: getCriteriaDefinitions(state),
    fundTypeDisclosures: getAssetClassDisclosureMap(state),
    categoryRiskDisclosures: getAssetClassDisclosureModalContent(state),
    learnMoreDisclosure: getLearnMoreDisclosureContent(state),
    selectedAssetClass: getQuickCriteriaSelectedAssetClass(state),
    selections: getQuickCriteriaSelections(state),
    isModalOpen: getQuickCriteriaModalState(state),
    userSelectedCriteria: getQuickCriteriaUserSelectedCriteria(state),
    parsedSelections: getQuickCriteriaParsedSelections(state),
    isMadLibsActive: getIsMadLibsActive(state),
    isAssetClassCategoryChanged: getIsAssetClassCategoryChanged(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    cancelModal: () => {
      dispatch(Creators.quickCriteriaCancelModal());
    },

    fetchDatavalueDisclosures: () => {
      dispatch(Creators.fundDatavalueDisclosureApi());
    },
    fetchQuickCriteriaMatches: () => {
      dispatch(Creators.quickCriteriaInitialMatchesApi());
    },
    updateActiveFundType: fundType => {
      dispatch(Creators.quickCriteriaUpdateActiveFundType(fundType));
    },
    updateSelectedAssetClass: (assetClass, categories) => {
      dispatch(Creators.quickCriteriaUpdateAssetClass(assetClass, categories));
    },
    updateSelections: (critId, selections) => {
      dispatch(Creators.quickCriteriaUpdateSelections(critId, selections));
    },
    updateExpandedCrit: crit => {
      dispatch(Creators.updateExpandedCrit(crit));
    },
    viewResults: (critIdArray = [], criteriaSelections, source) => {
      dispatch(Creators.updateCriteria(critIdArray, criteriaSelections, source));
      if (source === CRITERIA_SOURCE.QUICK_CRITERIA) {
        const trackingData = odaTracking.getQuickCriteriaViewResultsTrackingData(critIdArray);
        dispatch(Creators.odaLogAction(trackingData));
      } else if (source === CRITERIA_SOURCE.QUICK_CRITERIA_MODAL) {
        const trackingData = odaTracking.getQuickCriteriaModalViewResultsTrackingData(critIdArray);
        dispatch(Creators.odaLogAction(trackingData));
      }
    },
    hideModal: () => {
      dispatch(Creators.quickCriteriaHideModal());
    },
  };
};

const QuickCriteriaMenuContainer = connect(mapStateToProps, mapDispatchToProps)(QuickCriteriaMenu);

export default QuickCriteriaMenuContainer;
