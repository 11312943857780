import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import InactiveCriterionContainer from 'components/CriteriaGroup/InactiveCriterionContainer';
import CriteriaGroupHeader from 'components/CriteriaGroup/CriteriaGroupHeader';
import CriteriaSubGroup from 'components/CriteriaGroup/CriteriaSubGroup';

class CriteriaGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleExpansion = () => {
    const newIsExpanded = !this.props.isExpanded;
    this.props.expanderChange(this.props.id, newIsExpanded);
    this.props.trackCriteriaGroup(this.props.id, newIsExpanded);
  };

  _renderInactiveCriteria = () => {
    if (this.props.inactiveCriteriaCount === 0 && !this.props.isTopLevel) {
      return this._renderAllSelectedMessage();
    }

    return this._parseInactiveCriteria();
  };

  _renderAllSelectedMessage = () => {
    return (
      <div className="inactive-criterion">
        <div className="inactive-criterion-spacer" />
        <div className="inactive-criterion-content">All criteria in this category have been selected</div>
      </div>
    );
  };

  _parseInactiveCriteria = () => {
    return _.map(this.props.inactiveCriteria, item => {
      if (_.get(item, 'criteriaMenuItemType') === 'group') {
        return !_.isEmpty(item.criteriaMenuItemList) ? (
          <CriteriaSubGroup
            key={item.id}
            label={item.label}
            inactiveCriteria={item.criteriaMenuItemList}
            setFocusCriteria={this.props.setFocusCriteria}
          />
        ) : null;
      } else {
        return (
          <InactiveCriterionContainer
            key={item.id}
            criterion={item}
            useSublabel={item.label === this.props.label}
            setFocusCriteria={this.props.setFocusCriteria}
          />
        );
      }
    });
  };

  render() {
    const { label, isExpanded, inactiveCriteriaCount } = this.props;

    const inactiveCriteria = this._renderInactiveCriteria();
    const displayStyling = isExpanded ? '' : 'off';

    return (
      <div className="criteria-group">
        <CriteriaGroupHeader
          label={label}
          count={inactiveCriteriaCount}
          toggleExpansion={this.toggleExpansion}
          isExpanded={isExpanded}
        />
        <ul className={`inactive-criteria-all ${displayStyling}`}>{inactiveCriteria}</ul>
      </div>
    );
  }
}

CriteriaGroup.propTypes = {
  /** The ID of criteria group */
  id: PropTypes.string,

  /** Name of criteria group */
  label: PropTypes.string.isRequired,

  /** Number of inactive criteria in group */
  inactiveCriteriaCount: PropTypes.number,

  /** Array of inactive criteria in the group */
  inactiveCriteria: PropTypes.array,

  /** Denotes whether the criteria group is expanded */
  isExpanded: PropTypes.bool.isRequired,

  /** Notifies the parent component when group is expanded/collapsed */
  expanderChange: PropTypes.func.isRequired,

  /** Dispatches tracking action when criteria group is expanded/collapsed */
  trackCriteriaGroup: PropTypes.func,
};

export default CriteriaGroup;
