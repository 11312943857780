import { createReducer } from 'reduxsauce';
import _ from 'lodash';
import { RESULT_CONFIG } from 'configs/ResultConfig';
import {
  FIDELITY_AS_OF_DATE_SUBJECT_AREA,
  FUND_DATA_SUBJECT_AREA,
  FUND_ID_FIELD,
  FUND_NAME_FIELD,
  FUND_SYMBOL_FIELD,
} from 'configs/AppFeatureConfig';
import Types from '../actions/Types';
import { createSelector } from 'reselect';
import formatResultField from 'utilities/resultFormat';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getToggleGroups } from 'reducers/ResultToggleGroupReducer';
import { getCurrentViewTabsByShortName } from 'reducers/selectors/ViewTabSelectors';

// the initial state of this reducer
export const INITIAL_STATE = { results: [], fidelityResults: [] };

const searchResultsError = (state = INITIAL_STATE, action = {}) => {
  return { ...state, results: [] };
};

const fidelitySearchResultsError = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fidelityResults: [] };
};

const updateSearchResults = (state = INITIAL_STATE, action = {}) => {
  return { ...state, results: [...action.payload.data.funds] };
};

const updateFidelitySearchResults = (state = INITIAL_STATE, action = {}) => {
  return { ...state, fidelityResults: [...action.payload.data.funds] };
};

const clearAll = (state = INITIAL_STATE, action = {}) => {
  return INITIAL_STATE;
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.FUND_SEARCH_API_SUCCESS]: updateSearchResults,
  [Types.FUND_SEARCH_API_ERROR]: searchResultsError,
  [Types.FIDELITY_FUND_SEARCH_API_SUCCESS]: updateFidelitySearchResults,
  [Types.FIDELITY_FUND_SEARCH_API_ERROR]: fidelitySearchResultsError,
  [Types.CLEAR_ALL]: clearAll,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const getSearchResults = state => state.fundSearchResults.results;
export const getFidelitySearchResults = state => state.fundSearchResults.fidelityResults;
export const getViewTab = state => state.viewTab;

const _getMetaData = resultConfig => _.pick(resultConfig, ['id', 'label', 'className', 'align', 'sortField']);
const _getIsFidelityFund = rawInfo => {
  return (
    _.get(rawInfo, 'managedBy') === 'FIDELITY MANAGED' ||
    _.get(rawInfo, 'fundMgmCompanyName') === 'Fidelity Investments'
  );
};

const _getAdditionalFundData = result => {
  const rawInfo = _.get(result, FUND_DATA_SUBJECT_AREA);
  const info = _.pick(rawInfo, ['ticker', 'fundCode', 'tradingSymbol', 'cusip']);
  info.name = rawInfo[FUND_NAME_FIELD];
  info.longName = rawInfo['longName'];
  info.portfolioNum = rawInfo['portfolioNum'];
  info.id = rawInfo[FUND_ID_FIELD];
  info.symbol = rawInfo[FUND_SYMBOL_FIELD];
  info.isFidelityFund = _getIsFidelityFund(rawInfo);
  return info;
};

const _extractDataFromResult = (columnConfigs, result) => {
  const metaData = _.map(columnConfigs, _getMetaData);
  // For each item in columnConfigs, extract the corresponding data point from the current result
  const displayData = _.map(columnConfigs, config => formatResultField(result, config));
  const additionalFundData = _getAdditionalFundData(result);

  return {
    displayData,
    additionalFundData,
    metaData,
  };
};

export const getResultsData = createSelector(
  [getSearchResults, getFidelitySearchResults, getViewTab, getCurrentViewTabsByShortName, getToggleGroups],
  (searchResults, fidelitySearchResults, currentViewTab, currentViewTabsByShortName, toggleGroups) => {    
    const columns = _.get(currentViewTabsByShortName, [currentViewTab, 'viewTabCols']);
    const columnConfigs = _.map(columns, col => {
      let config = RESULT_CONFIG[col];
      // if the column is a resultToggleGroup item, then swap in the corresponding config for the current selection
      if (config.resultType === 'resultToggleGroup') {
        const currentSelection = toggleGroups[config.shortName].id;
        config = _.find(config.resultList, result => result.id === currentSelection);
      }
      return config;
    });
    const results = _.map(searchResults, result => _extractDataFromResult(columnConfigs, result));
    const fidelityResults = _.map(fidelitySearchResults, result => _extractDataFromResult(columnConfigs, result));
    return { results, fidelityResults };
  },
);

export const getAsOfDateSelector = subjectArea =>
  createSelector([getSearchResults, getFidelitySearchResults], (searchResults, fidelitySearchResults) => {
    // we just need one entry from either results so pick one that's not empty and take the first
    const firstEntry = _.first(searchResults || fidelitySearchResults);
    return _.get(firstEntry, [subjectArea, 'asOfDate']);
  });

const _isFidelityFundWithAsOfDate = result => {
  const rawInfo = _.get(result, FUND_DATA_SUBJECT_AREA);
  const isFidelityFund = _getIsFidelityFund(rawInfo);
  const asOfDate = _.get(result, [FIDELITY_AS_OF_DATE_SUBJECT_AREA, 'asOfDate']);
  return isFidelityFund && !_.isNil(asOfDate);
};

const _hasMorningstarDate = result => {
  const asOfDate = _.get(result, ['mstarRatings', 'asOfDate']);
  return !_.isNil(asOfDate);
};

export const getTopOfTableAsOfDate = createSelector(
  [getSearchResults, getFidelitySearchResults],
  (searchResults, fidelitySearchResults) => {
    const allResults = _.concat(searchResults, fidelitySearchResults);
    const fidelityEntry = _.find(allResults, _isFidelityFundWithAsOfDate);
    const otherEntry = _.find(allResults, _hasMorningstarDate);
    const fidelityAsOfDate = _.get(fidelityEntry, [FIDELITY_AS_OF_DATE_SUBJECT_AREA, 'asOfDate']);
    const otherDataAsOfDate = _.get(otherEntry, ['mstarRatings', 'asOfDate']);
    return { fidelity: fidelityAsOfDate, other: otherDataAsOfDate };
  },
);

const _extractCardDataFromResult = (cardGroups, result) => {
  const resultCard = {};

  _.each(cardGroups, cardGroup => {
    const { groupLabel, formatStyle } = cardGroup;
    if (formatStyle === 'object') {
      resultCard[groupLabel] = {};
    } else if (formatStyle === 'array') {
      resultCard[groupLabel] = [];
    }

    _.each(cardGroup.resultFields, resultField => {
      const resultFieldConfig = RESULT_CONFIG[resultField];
      const formatOptions = { isMobile: true };
      const values = formatResultField(result, resultFieldConfig, formatOptions);

      const { label } = resultFieldConfig;
      if (formatStyle === 'object') {
        resultCard[groupLabel][resultField] = values;
      } else if (formatStyle === 'array') {
        resultCard[groupLabel].push({ label, value: values });
      }
    });
  });

  resultCard.additionalData = _getAdditionalFundData(result);

  return resultCard;
};

export const getCardResultsData = createSelector(
  [getSearchResults, getFidelitySearchResults, getCurrentViewTabsByShortName],
  (searchResults, fidelitySearchResults, currentViewTabsByShortName) => {
    const cardGroups = _.get(currentViewTabsByShortName, 'card.cardGroups', []);
    const results = _.map(searchResults, result => _extractCardDataFromResult(cardGroups, result));
    const fidelityResults = _.map(fidelitySearchResults, result => _extractCardDataFromResult(cardGroups, result));
    return { results, fidelityResults };
  },
);

const _parseSortFieldsFromConfig = rawResultsFields => {
  const filteredFields = _.filter(rawResultsFields, resultField => {
    const config = RESULT_CONFIG[resultField];
    return !_.isNil(config) && !_.isNil(config.sortField);
  });

  const sortFieldsWithLabels = _.map(filteredFields, resultField => {
    return _.pick(RESULT_CONFIG[resultField], ['label', 'sortField']);
  });

  return _.sortBy(sortFieldsWithLabels, o => o.label);
};

export const getSortFieldsWithLabels = createSelector(
  [getDisplayType, getViewTab, getCurrentViewTabsByShortName],
  (displayType, currentViewTab, currentViewTabsByShortName) => {
    if (displayType === 'table' || displayType === 'compare') {
      const rawResultFields = _.get(currentViewTabsByShortName, [currentViewTab, 'viewTabCols'], []);
      return _parseSortFieldsFromConfig(rawResultFields);
    } else if (displayType === 'card') {
      const cardGroups = _.get(currentViewTabsByShortName, 'card.cardGroups', []);
      const allResultFields = _.map(cardGroups, groups => groups.resultFields);
      const uniqueResultFields = _.union(...allResultFields);
      return _parseSortFieldsFromConfig(uniqueResultFields);
    }
    return [];
  },
);
