import React, { Component } from 'react';
import CriteriaGroupContainer from 'containers/CriteriaGroupContainer';
import CriteriaSelectionMenuHeaderContainer from 'components/CriteriaSelectionMenu/CriteriaSelectionMenuHeaderContainer';
import InactiveCriterionContainer from 'components/CriteriaGroup/InactiveCriterionContainer';
import { connect } from 'react-redux';
import { getActiveCriteriaMap } from 'reducers/selectors/CriteriaSelectors';
import _ from 'lodash';
import { CRITERIA_CONFIG } from 'configs/CriteriaConfig';

export class CriteriaSelectionMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { expandedGroup: '' };
    this.handleExpanderChange = this.handleExpanderChange.bind(this);
  }

  handleExpanderChange(groupId, isExpanded) {
    if (isExpanded) {
      this.setState((prevState, props) => {
        return { expandedGroup: groupId };
      });
    } else {
      this.setState((prevState, props) => {
        return { expandedGroup: null };
      });
    }
  }

  _isGroupExpanded(groupId) {
    return this.state.expandedGroup === groupId;
  }

  _isNtfOrTransFee(id) {
    if (id === 'transactionFeeIndicator') return false;
    if (this.props.activeCriteriaMap['ntf'] && id === 'ntf') {
      return false;
    }
    return true;
  }

  _renderCriteriaGroups() {
    const CriteriaGroups = _.map(CRITERIA_CONFIG, item => {
      if (item.label === 'Sustainable Attributes' && item.criteriaType === 'checkbox') {
        return null;
      }
      if (item.criteriaMenuItemType === 'group') {
        return (
          <CriteriaGroupContainer
            id={item.id}
            label={item.label}
            isExpanded={this._isGroupExpanded(item.id)}
            expanderChange={this.handleExpanderChange}
            key={item.id}
            setFocusCriteria={this.props.setFocusCriteria}
          />
        );
      } else if (!this.props.activeCriteriaMap[item.id] && this._isNtfOrTransFee(item.id)) {
        return (
          <InactiveCriterionContainer
            key={item.id}
            criterion={item}
            isTopLevel={true}
            setFocusCriteria={this.props.setFocusCriteria}
          />
        );
      }
    });
    return CriteriaGroups;
  }

  render() {
    return (
      <div className="criteria-selection-menu">
        <CriteriaSelectionMenuHeaderContainer />
        {this._renderCriteriaGroups()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    activeCriteriaMap: getActiveCriteriaMap(state),
  };
};

const CriteriaSelectionMenuContainer = connect(mapStateToProps)(CriteriaSelectionMenu);

export default CriteriaSelectionMenuContainer;
