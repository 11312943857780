import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const createYearsMask = params => {
  return rawValue => {
    const value = rawValue.trim().replace(/[^0-9.-]/gi, '');
    const valAsNum = _.toNumber(value);
    const suffix = valAsNum === 1 ? ' year' : ' years';
    return createNumberMask({ ...params, suffix })(rawValue);
  };
};

const masks = {
  percentage: createNumberMask({
    prefix: '',
    suffix: '%',
    integerLimit: 3,
    allowDecimal: true,
    decimalLimit: 2,
  }),
  years: createYearsMask({
    prefix: '',
    integerLimit: 2,
  }),
  decimalYears: createYearsMask({
    prefix: '',
    integerLimit: 2,
    allowDecimal: true,
    decimalLimit: 2,
  }),
  millionsUSD: createNumberMask({
    suffix: 'M',
    integerLimit: 5,
  }),
  thousandsUSD: createNumberMask({
    suffix: 'K',
    integerLimit: 5,
    allowDecimal: true,
    decimalLimit: 5,
  }),
  decimal3USD: createNumberMask({
    suffix: '',
    integerLimit: 1,
    allowDecimal: true,
    decimalLimit: 3,
  }),
  usd: createNumberMask({
    suffix: '',
    integerLimit: 8,
    allowDecimal: true,
    decimalLimit: 2,
  }),
  integer: createNumberMask({
    prefix: '',
    suffix: '',
    integerLimit: 3,
    allowDecimal: false,
    decimalLimit: 0,
  }),
  default: createNumberMask({
    prefix: '',
    suffix: '',
    integerLimit: 3,
    allowDecimal: true,
    decimalLimit: 2,
  }),
};

const MultiSelectTextInput = ({ formatType = 'default', onChange, placeholder, value }) => {
  if (_.isNil(formatType)) formatType = 'default';

  value = !_.isNil(value) && value !== 'LS' && value !== 'GT' ? value : '';

  return (
    <div className="multi-select-text-input">
      <MaskedInput mask={masks[formatType]} onChange={onChange} placeholder={placeholder} value={value} />
    </div>
  );
};

MultiSelectTextInput.propTypes = {
  /** A formatting type used to help display selection text for multi-select criteria */
  formatType: PropTypes.string,

  /** A function that handles when the input values change */
  onChange: PropTypes.func.isRequired,

  placeholder: PropTypes.string.isRequired,
};

export default MultiSelectTextInput;
