import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'utilities';
import ViewAllCriterion from 'components/ViewAll/ViewAllCriterion';
import _ from 'lodash';

const _renderCriterion = (criterion, props) => {
  const { toggleCriterionSelection } = props;
  const criterionLabel = criterion.label;
  let critId = criterion.id;

  const isSelected = props.selectedCriteria.includes(critId);

  return (
    <ViewAllCriterion
      key={criterionLabel}
      criterionLabel={criterionLabel}
      criterionId={critId}
      isSelected={isSelected}
      toggleCriterionSelection={toggleCriterionSelection}
    />
  );
};

const _renderStandardCriteria = props => {
  const { criteriaData } = props;
  const leftColumn = [];
  const rightColumn = [];
  const halfwayPoint = Math.ceil(criteriaData.length / 2);
  const hasSubGroup = _.some(criteriaData, crit => {
    return crit.criteriaMenuItemType === 'group';
  });

  if (hasSubGroup) {
    const criteria = _.filter(criteriaData, crit => crit.criteriaMenuItemType === 'criterion');
    const subGroups = _.filter(criteriaData, crit => crit.criteriaMenuItemType === 'group');

    const subMidpoint = Math.ceil(criteria.length / 2);
    _.each(criteria, (crit, idx) => {
      if (idx < subMidpoint) {
        leftColumn.push(_renderCriterion(crit, props));
      } else {
        rightColumn.push(_renderCriterion(crit, props));
      }
    });
    let groupNum = 1;
    _.each(subGroups, subGroup => {
      const subGroupCriteria = _.get(subGroup, 'criteriaMenuItemList', []);
      if (groupNum % 2 === 0) {
        leftColumn.push(<h4 key={subGroup.id}>{subGroup.label}</h4>);
        _.each(subGroupCriteria, crit => {
          leftColumn.push(_renderCriterion(crit, props));
        });
      } else {
        rightColumn.push(<h4 key={subGroup.id}>{subGroup.label}</h4>);
        _.each(subGroupCriteria, crit => {
          rightColumn.push(_renderCriterion(crit, props));
        });
      }
      groupNum++;
    });
  } else {
    criteriaData.forEach((criterion, i) => {
      let viewAllCriterion = _renderCriterion(criterion, props);
      if (i < halfwayPoint) {
        leftColumn.push(viewAllCriterion);
      } else {
        rightColumn.push(viewAllCriterion);
      }
    });
  }
  return (
    <div className="view-all-criteria">
      <ul className="view-all-criteria-group-column">{leftColumn}</ul>
      <ul className="view-all-criteria-group-column">{rightColumn}</ul>
    </div>
  );
};

const ViewAllCriteriaGroup = props => {
  const { groupLabel } = props;
  const groupClassName = format.transformToSnakeCase(groupLabel);
  let criteria = null;

  criteria = _renderStandardCriteria(props);

  return (
    <div id={`view-all-${groupClassName}`} className="view-all-criteria-group">
      <h3>{groupLabel}</h3>
      {criteria}
    </div>
  );
};

ViewAllCriteriaGroup.propTypes = {
  /** Name of the criteria group */
  groupLabel: PropTypes.string.isRequired,

  /** Raw JSON representing criteria */
  criteriaData: PropTypes.array.isRequired,

  /** All selected criteria within current criteria group */
  selectedCriteria: PropTypes.array.isRequired,

  /** Function to update selected criterion */
  toggleCriterionSelection: PropTypes.func.isRequired,
};

export default ViewAllCriteriaGroup;
