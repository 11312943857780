import React, { useState } from 'react';
import { connect } from 'react-redux'
import { CSSTransitionGroup } from 'react-transition-group';
import hash from 'utilities/hash';
import localStorage from 'utilities/localStorage';
import '../../styles/components/shared/_banner.scss'
import icons from 'utilities/icons';
import {
  HAS_BANNER
} from '../../configs/AppFeatureConfig';

const Banner = (props) => {

  const {bannerMessage, route} = props;
  const isResultsView = typeof route === 'string' && route.slice(0,8) === '/results';
  const hashValue = hash.stringToHash(bannerMessage);
  const [dismissedBanner, setDismissedBanner] = useState(false);
  const dismissedBanners = localStorage.getItem('dismissedBanners') || [];

  const isDismissed = dismissedBanners.includes(hashValue) || !isResultsView || !HAS_BANNER;

  const _handleDismissBanner = (hashValue) =>{
    dismissedBanners.push(hashValue);
    localStorage.setItem('dismissedBanners', dismissedBanners);
    setDismissedBanner(!dismissedBanner);
  }
	const renderBody = () => {
		return isDismissed ? null : (
     <div className="banner-container" key={hashValue}>
      <div className="info-icon">
        <img src={icons.noteIcon} alt="banner icon" />
      </div>
      <div className="banner-main" dangerouslySetInnerHTML={{ __html: bannerMessage }} />
      <div className="close-icon" onClick={() => _handleDismissBanner(hashValue)}>
        <div className="icon close-x"></div>
      </div>
     </div>
    )
	}
  let body = renderBody();
	return !isResultsView ? null : (
    <div className="banners-section">
    <CSSTransitionGroup
      in = {dismissedBanner}
      transitionName="collapse"
      transitionLeaveTimeout={300}
      transitionEnterTimeout={0}>
      {body}
    </CSSTransitionGroup>
  </div>
	);
}

function mapStateToProps(state) {
	return { route: state.route };
}

export default connect(mapStateToProps)(Banner);