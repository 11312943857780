import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Shared/Modal';
import { getTotalMatches } from 'reducers/QuickCriteriaMatchesReducer';
import { connect } from 'react-redux';
import Creators from 'actions/CriteriaActions';
import * as odaTracking from 'utilities/odaTracking';
import QuickCriteriaMatchesContainer from './QuickCriteriaMatchesContainer';
import QuickCriteriaTransactionFee from './QuickCriteriaTransactionFee';
import MadLibsMatchesContainer from './MadLibsMatchesContainer';
import { getIsMadLibsActive } from '../../reducers/QuickCriteriaMenuReducer';
import { CLEAR_ALL_SOURCE } from 'utilities/odaUtils';

class QuickCriteriaResultsFooter extends Component {

  _openModal = e => {
    e.preventDefault();
    this.props.onLearnModel(true);
    this.props.trackMoreLink('Learn More');
  };

  _handleCloseModal = e => {
    this.props.onLearnModel(false);
  };

  _handleDontKnowWhereToBegin = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  handleClearAll = e => {
    e.preventDefault();
    this.props.onClearAll();
  };

  render() {
    const { allCriteria,  keyCriteriaSelected, handleSelectViewResults, hasSelections, matchCount, isMadLibsActive, learnMoreDisclosure, section } = this.props; 
    let buttonStyling = 'disabled';
    let buttonStylingClear = 'disabled';
    let clearDisabled;
    let viewDisabled = false;
    const clickHandler = hasSelections ? handleSelectViewResults : () => { };
    if (section === 'quick-criteria') {
      buttonStyling = hasSelections? '' : 'disabled';
      buttonStylingClear = hasSelections ? '' : 'disabled';
      clearDisabled = hasSelections ? false : true;
    }
    else if (section === 'madlibs') {
      buttonStyling = isMadLibsActive && matchCount !== 0 ? '' : 'disabled';
      buttonStylingClear = isMadLibsActive ? '' : 'disabled';
      clearDisabled = isMadLibsActive ? false : true;
      if (matchCount === 0) {
        viewDisabled = true;
      } 
    }
    return (
      <div className="quick-criteria-results-footer">
        <div className={`${section}-results`}>
          <div className="transaction-fee-footer">
            { section === 'quick-criteria' && (
              <QuickCriteriaTransactionFee
                allCriteria={allCriteria}
                keyCriteriaSelected={keyCriteriaSelected}
                handleSelectKeyCriteria={this.props.handleSelectKeyCriteria}
              />
            )}
          </div>
          <div className="view-funds-clear-all-container">
            <div>
              <button onClick={clickHandler} disabled={viewDisabled} className={`action-button ${buttonStyling}`} data-tc={`${section}-button`}>
                {(section === 'quick-criteria') ? <QuickCriteriaMatchesContainer />
                  : <MadLibsMatchesContainer />
                }
              </button>
            </div>
            <div className="clear-all">
              <button className={`as-link ${buttonStylingClear}`} type="button" disabled={clearDisabled} onClick={this.handleClearAll}>
                Clear All Criteria
              </button>
            </div>
          </div>
        </div>
        <Modal isOpen={this.props.showLearnModel} handleCloseModal={this._handleCloseModal} >
          <div className="fidelity-disclosure" dangerouslySetInnerHTML={{ __html: learnMoreDisclosure }} />
        </Modal>
      </div>
    );
  }
}

QuickCriteriaResultsFooter.propTypes = {
  /** Callback that is fired when view results button is selected */
  handleSelectViewResults: PropTypes.func.isRequired,

  /** Represents if the quick criteria menu currently has selections */
  hasSelections: PropTypes.bool.isRequired,

  /** The disclosure content for the learn more modal */
  learnMoreDisclosure: PropTypes.string,

  /** Dispatches action to track more links */
  trackMoreLink: PropTypes.func.isRequired,

  /** Number of matches that align with a user's current selections in the quick criteria menu */
  matchCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

  /** Function that clears all active criteria */
  onClearAll: PropTypes.func.isRequired,

  hasLearnMore: PropTypes.bool.isRequired,
  showLearnModel: PropTypes.bool.isRequired,
  onLearnModel: PropTypes.func.isRequired,
  isMadLibsActive: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    matchCount: getTotalMatches(state),
    isMadLibsActive: getIsMadLibsActive(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    trackMoreLink: moreLinkType => {
      const trackingData = odaTracking.getQuickCriteriaMoreLinkTrackingData(moreLinkType);
      dispatch(Creators.odaLogAction(trackingData));
    },
    onClearAll: () => {
      const source = CLEAR_ALL_SOURCE.CRITERIA_MENU;
      dispatch(Creators.clearAll(source));
      dispatch(Creators.quickCriteriaInitialMatchesApi());
    }
  };
};

const QuickCriteriaResultsFooterContainer = connect(mapStateToProps, mapDispatchToProps)(QuickCriteriaResultsFooter);

export default QuickCriteriaResultsFooterContainer;
