import React, { Component } from 'react';
import { sorting } from 'utilities';
import QuickCriteriaClassCheckbox from 'components/StartPage/QuickCriteriaClassCheckbox';
import GlossaryDefinitionPopover from '../Shared/GlossaryDefinitionPopover'

class QuickCriteriaClassPopupDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAllCategoriesCheckboxChecked: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedAssetClasses !== this.props.selectedAssetClasses) {
      const { key } = this.props.assetClass;
      const isActiveClass = nextProps.selectedAssetClasses.includes(key);
      if (!isActiveClass) {
        this.setState({
          isAllCategoriesCheckboxChecked: false,
        });
      }
    }
  }

  handleToggle = () => {
    const { handleDropDown, expandStatus, assetClass } = this.props;
    const newState = !expandStatus;
    handleDropDown({ assetClass: assetClass, expanded: newState });
  };

  handleSelectAssetClass = assetClass => {
    this.setState({ isAllCategoriesCheckboxChecked: true });
    this.props.handleChangeAllActiveAssetClasses(assetClass);
  };

  handleSelectCategory = (label, isChecked) => {
    const allChecked = this.state.isAllCategoriesCheckboxChecked;
    this.props.handleChangeAllActiveCategories(label, this.props.assetClass, isChecked, allChecked);
    this.setState({ isAllCategoriesCheckboxChecked: false });
  };

  shouldCheckCategory = filterCode => {
    return this.props.selectedCategories.includes(filterCode);
  };

  shouldPartialCheckCategory = filterCode => {
    let iCheckedCount = 0;
    const selectedCategories = this.props.selectedCategories;
    this.props.assetClass.categories.forEach((item) => {
      if (selectedCategories.includes(item.value)) {
        iCheckedCount++;
      }
    });
    return iCheckedCount > 0 && iCheckedCount < this.props.assetClass.categories.length;
  };

  shouldCheckAssetClass = filterCode => {
    return this.props.selectedAssetClasses.includes(filterCode);
  };

  renderSelectedCategoriesCount = (ac, sc) => {
    const individualSelectedClassCategoryCount = ac.categories.filter(category => sc.includes(category.key)).length
    return <div>({individualSelectedClassCategoryCount}/{ac.categories.length})</div>
  }

  renderCheckbox = filter => {
    return (
      <QuickCriteriaClassCheckbox
        key={filter.key}
        criteriaName={'category'}
        handleCheck={this.handleSelectCategory}
        identifier={filter.key}
        isChecked={this.shouldCheckCategory(filter.key)}
        label={filter.label}
      />
    );
  };

  isExpanded = () => {
    const { expandStatus } = this.props;
    return expandStatus;
  }

  render() {
    const { assetClass, selectedCategories } = this.props;
    const sortedCategories = Object.values(assetClass.categories).sort(sorting.alphabetSortByProperty('label'));
    return (
      <div className="asset-class-wrap" data-tc={`${assetClass.label}`}>
        <div className="quick-criteria-asset-class-label-wrap" onClick={() => this.handleToggle()}>
          <div className='asset-class-header' onClick={() => this.handleToggle()}>
            <div className='asset-checkbox'>
              <QuickCriteriaClassCheckbox
                key={assetClass.key}
                criteriaName={'category'}
                handleCheck={() => this.handleSelectAssetClass(assetClass)}
                handleLableClick={() => this.handleToggle()}
                identifier={assetClass.key}
                isChecked={this.shouldCheckAssetClass(assetClass.key)}
                isPartialChecked={this.shouldPartialCheckCategory(assetClass.key)}
                label={assetClass.label}
                glossaryDefinition={<GlossaryDefinitionPopover glossary={assetClass.label} container="quick-criteria-fund-type" scrolled={this.props.scrolled}/>}
              />
            </div>
            <div className='selected-categories-count'>{this.renderSelectedCategoriesCount(assetClass, selectedCategories)}</div>
          </div>
          <div className='risk-image-div'>
            <div className='risk-image'>
              <img src={assetClass.riskImageUrl} alt='asset risk level'></img>
            </div>
            <div className='dropDownButton' onClick={this.handleToggle}>
              <div className={`caret ${this.isExpanded() ? 'down' : ''}`} onClick={this.handleToggle}></div>
            </div>
          </div>
        </div>
        <div className={`quick-criteria-class-popup-categories ${this.isExpanded() ? 'show' : 'hide'}`}>
          {sortedCategories.map(category => this.renderCheckbox(category))}
        </div>
      </div>
    );
  }
}

export default QuickCriteriaClassPopupDropdown;
