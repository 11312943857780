import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { format } from 'utilities';
import Creators from 'actions/CriteriaActions';
import { CRITERIA_SOURCE } from 'utilities/odaUtils';
import { getDefaultFilterGroupSelectionValues } from 'utilities/criteriaUtils';

export const NestedInactiveCriterion = ({ criterion, addCriterion, setFocusCriteria }) => {
  const { label, id, defaultFilterGroupSelections } = criterion;
  const filterGroupSelections = getDefaultFilterGroupSelectionValues(defaultFilterGroupSelections);
  const clickHandler = () => {
    addCriterion(id, filterGroupSelections);
    setFocusCriteria(format.transformToSnakeCase(id));
  };
  return (
    <li className="nested-inactive-criterion">
      <div className="nested-inactive-criterion-spacer" />
      <div
        tabIndex={0}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            clickHandler();
          }
        }}
        className="nested-inactive-criterion-content"
      >
        <a onClick={clickHandler}>{label}</a>
      </div>
    </li>
  );
};

NestedInactiveCriterion.propTypes = {
  /** Function from parent that adds criterion to active criteria list */
  addCriterion: PropTypes.func.isRequired,

  /** Unique identifier for a criterion */
  criterion: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    addCriterion: (crit, filterGroupSelections) => {
      dispatch(Creators.addCriterion(crit, filterGroupSelections, CRITERIA_SOURCE.CRITERIA_MENU));
    },
  };
};

const NestedInactiveCriterionContainer = connect(mapStateToProps, mapDispatchToProps)(NestedInactiveCriterion);

export default NestedInactiveCriterionContainer;
