import { connect } from 'react-redux';
import ActiveCriterion from 'components/ActiveCriterion';
import { getFilterSelectionsForCriterion } from 'reducers/CriteriaFiltersReducer';
import { getMatchesForCriterion } from 'reducers/CriteriaMatchesReducer';
import { getTotalMatchesForCriterion } from 'reducers/TotalMatchesReducer';
import { getMatchesLoading } from 'reducers/LoadingReducer';
import Creators from 'actions/CriteriaActions';

const mapStateToProps = (state, ownProps) => {
  return {
    filtersSelected: getFilterSelectionsForCriterion(state, ownProps),
    filterMatches: getMatchesForCriterion(state, ownProps),
    totalMatches: getTotalMatchesForCriterion(state, ownProps),
    isLoading: getMatchesLoading(state, ownProps),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFiltersUpdated: (critId, filterUpdates) => {
      dispatch(Creators.updateFilters(critId, filterUpdates));
    },
    onRangeFiltersUpdated: (critId, filterUpdates) => {
      dispatch(Creators.updateRangeInputs(critId, filterUpdates));
    },
  };
};

const ActiveCriterionContainer = connect(mapStateToProps, mapDispatchToProps)(ActiveCriterion);

export default ActiveCriterionContainer;
