import React, { Component } from 'react';

class Button extends Component {
  render() {
    const { text, onClick, disabled } = this.props;
    return (
      <div className="view-results-button">
        <button onClick={onClick} disabled={disabled} className={disabled ? 'disabled-btn' : 'continue-btn'}>
          {text}
        </button>
      </div>
    );
  }
}

export default Button;
