import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';
// import { createSelector } from 'reselect';
// import _ from 'lodash';
import Types from 'actions/Types';

export const INITIAL_STATE = {};

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};

const updateDisclosureLabelSuppressions = (state = INITIAL_STATE, action = {}) => {
  return { ...action.payload.data.sections };
};

const ACTION_HANDLERS = {
  [Types.DISCLOSURE_LABEL_SUPPRESSIONS_API_SUCCESS]: updateDisclosureLabelSuppressions,
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);
