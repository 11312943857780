import { createReducer } from 'reduxsauce';
import Types from '../actions/Types';
import { createSelector } from 'reselect';
import _ from 'lodash';

// the initial state of this reducer
export const INITIAL_STATE = {};

export const removeCriterion = (state = INITIAL_STATE, action = {}) => {
  const critObjs = _.omitBy(state, (matchesData, critId) => {
    return critId === action.critId;
  });
  return critObjs;
};

export const clearAll = (state = INITIAL_STATE, action = {}) => {
  return INITIAL_STATE;
};

export const updateMatches = (state = INITIAL_STATE, action = {}) => {
  //const orderedMatches = _.orderBy(action.payload.data, ['criteria'], ['desc']);
  return { ...state, [action.critId]: action.payload.data };
};

export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
  //if route update came from middleware, then no need to update state again
  if (action.payload.location.state && action.payload.location.state.source === 'routerMiddleware') {
    return state;
  }
  /**
   * When the ROUTER_LOCATION_CHANGED action is dispatched as a result of URL navigation
   * (browser back/forward, reloading the page or loading URL directly) then clear out all
   * current matches data becuase new values will be added when the API calls return
   */
  return clearAll(state, action);
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.REMOVE_CRITERION]: removeCriterion,
  [Types.CRITERIA_MATCHES_API_SUCCESS]: updateMatches,
  [Types.CLEAR_ALL]: clearAll,
  "@@router/LOCATION_CHANGE": routerLocationChanged,
};

export default createReducer(INITIAL_STATE, HANDLERS);

const getFilterGroups = (state, props) => props.criterion.filterGroups;

const getCriteriaMatches = state => state.criteriaMatches;
export const getMatchesForCriterion = createSelector(
  [getCriteriaMatches, getFilterGroups],
  (criteriaMatches, filterGroups) => {
    return _.mapValues(filterGroups, (value, filterGroupId) => criteriaMatches[filterGroupId] || []) || {};
  },
);
