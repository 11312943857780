import React from 'react';
import PropTypes from 'prop-types';

const PricingModelPopupHeader = ({
  displayedSection,
  handleChangeActiveClass,
  handleHoverOverHeader,
  handleTouchHeader,
  item,
}) => {
  const { policyCode, policySubHeader: label, policyStatus } = item;
  const displayStyling = displayedSection === policyCode ? 'active' : '';
  const status = policyStatus || '--';
  const indicatorText = status.toUpperCase();
  const indicatorClass = status.toLowerCase();
  const indicator = <span className={`${indicatorClass} indicator`}>{indicatorText}</span>;
  return (
    <div
      className={`pricing-model-popup-option-header ${displayStyling}`}
      onMouseEnter={e => {
        e.preventDefault();
        handleHoverOverHeader(policyCode);
      }}
      onTouchStart={e => {
        e.preventDefault();
        handleTouchHeader(policyCode);
      }}
      onMouseDown={e => {
        e.preventDefault();
        handleChangeActiveClass(policyCode);
      }}
      onTouchEnd={e => {
        e.preventDefault();
      }}
    >
      {label} {indicator}
    </div>
  );
};

PricingModelPopupHeader.propTypes = {
  /** The asset class section to display */
  displayedSection: PropTypes.string.isRequired,

  /** Function that changes the active asset class section */
  handleChangeActiveClass: PropTypes.func.isRequired,

  /** Function that determines what section is currently being hovered over */
  handleHoverOverHeader: PropTypes.func.isRequired,

  /** The name of the asset class */
  item: PropTypes.object.isRequired,
};

export default PricingModelPopupHeader;
