import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';
import angular from 'angular';
import HypothesisChartViewModel from 'HypothesisChartViewModel';
import { getFundsSelected } from 'reducers/FundsSelectedReducer';
import { FUND_ID_FIELD, PLAN_NUMBER, APP_CHANNEL, COMPARE_FEATURES } from 'configs/AppFeatureConfig';

class CompareChart extends Component {
  state = { chartHasError: false };

  constructor(props) {
    super(props);
    this.chartModel = new HypothesisChartViewModel();
  }
  chartHtml = '<hypothesis-chart data-context="chart"></hypothesis-chart>';
  componentDidMount = () => {
    try {
      var chart = this.chartModel;
      const funds = this._getChartInputs(this.props.fundsSelected).join(',');
      chart.refresh(funds, 'COMPARE', {
        fundSymbolField: COMPARE_FEATURES.CHART_FUND_ID_FIELD,
        fundLabelField: COMPARE_FEATURES.CHART_FUND_LABEL_FIELD,
        pathVariables: {
          channel: APP_CHANNEL,
        },
        queryParameters: {
          planid: PLAN_NUMBER,
        },
      });

      angular.module('chartApp', ['fmr.hypothesis-mf-chart']).controller('ChartController', [
        '$scope',
        function($scope) {
          $scope.chart = chart;
        },
      ]);
      angular.bootstrap(this.container, ['chartApp']);
    } catch (error) {
      if (!this.state.chartHasError) {
        this.setState({ chartHasError: true });
        return true;
      }
    }
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    try {
      const funds = this._getChartInputs(nextProps.fundsSelected).join(',');
      this.chartModel.refresh(funds, 'COMPARE', {
        fundSymbolField: COMPARE_FEATURES.CHART_FUND_ID_FIELD,
        fundLabelField: COMPARE_FEATURES.CHART_FUND_LABEL_FIELD,
        pathVariables: {
          channel: APP_CHANNEL,
        },
        queryParameters: {
          planid: PLAN_NUMBER,
        },
      });
      if (this.state.chartHasError) {
        this.setState({ chartHasError: false });
        return true;
      }
    } catch (error) {
      if (!this.state.chartHasError) {
        this.setState({ chartHasError: true });
        return true;
      }
    }
    return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
  };
  _getChartInputs = fundList => {
    return _.map(fundList, fund => {
      const hideInCompare = fund.hideInCompare;
      return hideInCompare ? 'null' : fund[FUND_ID_FIELD];
    });
  };
  render() {
    return (
      <div ref={c => (this.container = c)} id="compare-chart-container" className="compare-chart-container">
        <div
          dangerouslySetInnerHTML={{
            __html: !this.state.chartHasError
              ? this.chartHtml
              : '<div align="center">This data is temporarily unavailable. Please try again later.</div>',
          }}
          className="chart-container"
          ng-controller="ChartController"
        />
      </div>
    );
  }
}

CompareChart.propTypes = {
  fundsSelected: PropTypes.array.isRequired,
};
const mapStateToProps = state => {
  const fundsSelected = getFundsSelected(state);
  return { fundsSelected };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CompareChart);
