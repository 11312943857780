import _ from 'lodash';
import Types from 'actions/Types';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { apiUtils } from 'utilities';
import { ENDPOINTS } from 'services/endpoints';
import { getRouterPersistentQueries } from 'reducers/selectors/RouterSelectors';

export default api => {
  function* rootSaga(action) {
    const state = yield select();
    const persistentQueries = yield select(getRouterPersistentQueries);
    const parameters = apiUtils.constructDownloadResults(state, action);
    if (parameters['searchFilter']['sustainableInvestment']) {
      const esgCodesStr = apiUtils.getSociallyResponsibleAttributesValues();
      if (esgCodesStr) {
        parameters['searchFilter']['esgCodes'] = esgCodesStr;
      }
    }
    if (action.policyCode || _.has(persistentQueries, 'product')) {
      parameters.searchFilter.policyCode = action.policyCode || _.get(persistentQueries, 'product');
    }
    const response = yield call(api.postData, ENDPOINTS.SEARCH_FUNDS_XSLX, parameters);
    if (response.ok) {
      yield put({ type: Types.DOWNLOAD_RESULTS_API_SUCCESS, payload: response });
      const contentType = _.get(response, ['headers', 'content-type']);
      const blob = new Blob([response.data], { type: contentType });
      if (navigator.appVersion.toString().indexOf('.NET') > 0) {
        window.navigator.msSaveBlob(blob, 'Download_Results.xlsx');
      } else {
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob, { type: contentType });
        a.download = 'Download_Results.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } else {
      yield put({ type: Types.DOWNLOAD_RESULTS_API_ERROR, payload: response });
    }
  }

  function* watcher() {
    yield takeEvery(Types.DOWNLOAD_RESULTS_API, rootSaga);
  }

  return {
    watcher,
    rootSaga,
  };
};
