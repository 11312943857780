import _ from 'lodash';
import { createReducer } from 'reduxsauce';
import Types from '../actions/Types';

// the initial state of this reducer
export const INITIAL_STATE = {
  sortCol: 'averageAnnualReturnsYear3',
  sortDir: 'desc',
};

export const updateSort = (state = INITIAL_STATE, action = {}) => {
  return {
    sortCol: action.column,
    sortDir: action.direction,
  };
};
export const updateStrategiesSort = (state = INITIAL_STATE, action = {}) => {
  return {
    sortCol: _.get(action, ['sortParams', 'column'], state.sortCol),
    sortDir: _.get(action, ['sortParams', 'direction'], state.sortDir),
  };
};
export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
  //cover the router action type when back/forward of history browser :  sortCol, sortDir are changed with in the current url of history.
  if (action.type!=="@@router/LOCATION_CHANGE" && _.get(action, ['payload', 'location', 'state','source']) === 'routerMiddleware') {
    return state;
  }
  return {
    sortCol: _.get(action, ['payload', 'params', 'sortBy'], state.sortCol),
    sortDir: _.get(action, ['payload', 'params', 'sortOrder'], state.sortDir),
  };
};
export const clearAll = (state = INITIAL_STATE, action = {}) => {
  return INITIAL_STATE;
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.SORT_RESULTS]: updateSort,
  [Types.LOAD_STRATEGY]: updateStrategiesSort,
  [Types.CLEAR_ALL]: clearAll,
  "@@router/LOCATION_CHANGE": routerLocationChanged,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const getSortSettings = state => state.sortResults;
