import { createReducer } from 'reduxsauce';
import Types from '../actions/Types';
import _ from 'lodash';

// the initial state of this reducer
export const INITIAL_STATE = '--';

export const updateFidelityMatches = (state = INITIAL_STATE, action = {}) => {
  if (_.get(action, 'payload.ok', null)) {
    return action.payload.data.totalCount;
  }
  return INITIAL_STATE;
};
//TODO - commented out by some one else - should this ever be brought back in, will need to be updated for new routing system
// export const routerLocationChanged = (state = INITIAL_STATE, action = {}) => {
//   if (action.payload.options && action.payload.options.source === 'routerMiddleware') {
//     return state;
//   }
//   if (!action.payload || !action.payload.params || !action.payload.params.viewTab) {
//     return state;
//   }
//   return action.payload.params.viewTab;
// };
export const clearAll = (state = INITIAL_STATE, action = {}) => {
  return INITIAL_STATE;
};

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.FIDELITY_MATCHES_API_SUCCESS]: updateFidelityMatches,
  [Types.CLEAR_ALL]: clearAll,
  //"@@router/LOCATION_CHANGE": routerLocationChanged,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const getFidelityMatches = state => state.fidelityMatches;
