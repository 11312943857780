import React from 'react';

// Import necessary ChartIQ library files
import { CIQ } from 'chartiq/js/standard.js';
import 'chartiq/js/components.js';
import 'chartiq/js/components-legacy.js';
import 'chartiq/js/addOns.js';

import ChartTemplate from './Template';

// Base styles required by the library to render color correctly.
import 'chartiq/css/normalize.css';
import 'chartiq/css/stx-chart.css'; // Chart API
import 'chartiq/css/chartiq.css'; // Chart UI
import './library-overrides.scss';

import { getCustomConfig } from './resources'; // ChartIQ library resources

import getLicenseKey from 'chartiq/key.js';
// import SymbolInfoDisplay from './SymbolInfoDisplay';
getLicenseKey(CIQ);

export { CIQ };

/**
 * This is a fully functional example showing how to load a chart with complete user interface.
 *
 * @export
 * @class Core
 * @extends {React.Component}
 */
export default class Core extends React.Component {
  /**
   * @constructor
   * @param {object} [props] React props
   * @param {object} [props.config] Configuration used for the chart.
   * @param {object} [props.resources] Object of resources passed into configuration to be applied
   * @param {Core~chartInitialized} [props.chartInitialized] Callback that fires when the chart is created
   */
  constructor(props) {
    super(props);
    const { config, resources } = props;
    this.container = React.createRef();
    const configObj = getCustomConfig({ resources });
    CIQ.extend(configObj, config);
    this.config = configObj;

    this.stx = null;
    this.uiContext = null;
  }

  componentDidMount() {
    if (this.init) return;
    const container = this.container.current;
    const { chartInitialized } = this.props;
    const { config } = this;

    portalizeContextDialogs(container);
    // Delay the call to createChartAndUI so any other chart components on the page
    // using multi chart setup have a chance to call portalizeContextDialogs
    window.setTimeout(() => {
      const uiContext = (this.uiContext = this.createChartAndUI({
        container,
        config,
      }));
      const chartEngine = (this.stx = uiContext.stx);

      // This is required to render the range slider
      // chartEngine.layout.rangeSlider = () => true;

      this.setState({ stx: chartEngine, UIContext: uiContext });

      if (chartInitialized) {
        chartInitialized({ chartEngine, uiContext });
      }
    }, 0);
    this.init = true;
    this.rendered = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const newAddSeries = this.props.addSeries;
    const oldAddSeries = prevProps.addSeries;

    // This is so we don't update the chart when no changes were made
    let isIdentical = true;
    newAddSeries &&
      newAddSeries.forEach((newSeries, i) => {
        if (newSeries !== oldAddSeries[i]) {
          isIdentical = false;
          return;
        }
      });
    // this.stx.dispatch("layout", {
    // 	stx: this.stx,
    // 	layout: this.stx.layout
    // });
  }

  componentWillUnmount() {
    // Destroy the ChartEngine instance when unloading the component.
    // This will stop internal processes such as quotefeed polling.
    if (!this.stx || !this.rendered) return;

    this.stx.destroy();
    this.stx.draw = () => {};
  }

  createChartAndUI({ container, config }) {
    // A Strategy to optionally use the Study Menu or the default Study Browser via URL query string.
    const useStudyMenu = /studymenu=y/i.test(document.location.href);
    if (useStudyMenu && CIQ.Studies.Favorites) {
      CIQ.Studies.Favorites_disabled = CIQ.Studies.Favorites;
      delete CIQ.Studies.Favorites;
    } else if (!useStudyMenu && CIQ.Studies.Favorites_disabled) {
      CIQ.Studies.Favorites = CIQ.Studies.Favorites_disabled;
    }
    return new CIQ.UI.Chart().createChartAndUI({ container, config });
  }

  render() {
    const hoverValues = 'test';
    this.rendered = true;
    const {
      props: { children },
      config,
    } = this;

    let template = <ChartTemplate config={config} />;
    const childrenCount = React.Children.count(children);
    if (childrenCount === 1) {
      template = React.cloneElement(children, { config });
    } else if (childrenCount > 1) {
      template = children;
    }
    return (
      <React.Fragment>
        <cq-context ref={this.container}>
          {/* <SymbolInfoDisplay stx={this.state ? this.state.stx : null} symbol={this.props.symbol} /> */}
          {template}
        </cq-context>
      </React.Fragment>
    );
  }
}

/**
 * For applications that have more then one chart, keep single dialog of the same type
 * and move it outside context node to be shared by all chart components
 */
function portalizeContextDialogs(container) {
  container.querySelectorAll('cq-dialog').forEach(dialog => {
    dialog.remove();
    if (!dialogPortalized(dialog)) {
      document.body.appendChild(dialog);
    }
  });
}

function dialogPortalized(el) {
  const tag = el.firstChild.nodeName.toLowerCase();
  let result = Array.from(document.querySelectorAll(tag)).some(el => !el.closest('cq-context'));
  return result;
}

/**
 * @callback Core~chartInitialized
 * @param {CIQ.ChartEngine} chartEngine
 * @param {CIQ.UI.Context} uiContext
 */

// Adjustments to compensate for when webpack config is not available
(function initDynamicShare() {
  // Decorate the library function to avoid copying html2canvas.min.js to distribution to js/thirdparty directory
  if (CIQ.Share.fullChart2PNG_init) return;
  const fullChart2PNG = CIQ.Share.fullChart2PNG;
  CIQ.Share.fullChart2PNG = function (stx, params, cb) {
    import('chartiq/js/thirdparty/html2canvas.min.js').then(() => {
      fullChart2PNG(stx, params, cb);
    });
  };
  CIQ.Share.fullChart2PNG_init = true;
})();
