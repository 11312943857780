import React from 'react';
import PropTypes from 'prop-types';

const ViewAllFooter = ({ handleCancel, handleApplyCriteria, selectedCriteria }) => {
  const hasSelections = selectedCriteria.length > 0;

  const buttonStyle = hasSelections ? '' : 'disabled';

  const onClickFn = hasSelections ? handleApplyCriteria : () => {};
  return (
    <div className="view-all-footer">
      <div className="view-all-cancel">
        <span onClick={handleCancel}>Cancel</span>
      </div>
      <div className="view-all-apply-criteria">
        <button onClick={onClickFn} className={buttonStyle}>
          Apply Criteria
        </button>
      </div>
    </div>
  );
};

ViewAllFooter.propTypes = {
  /** Handles cancelling and resetting selected criteria */
  handleCancel: PropTypes.func.isRequired,

  /** Updates container state representing all selected criteria */
  handleApplyCriteria: PropTypes.func.isRequired,

  /** The current selected criteria in the view all modal */
  selectedCriteria: PropTypes.array,
};

export default ViewAllFooter;
