import React from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import StarIconSvg from 'components/Shared/StarIconSvg';

const MAX_ICON_COUNT = 5;

const _renderMorningstarIcons = activeIcons => {
  if (_.isNaN(activeIcons)) activeIcons = 5;

  const icons = [];

  for (let i = 0; i < MAX_ICON_COUNT; i++) {
    icons.push(<StarIconSvg key={i} isGrayedOut={i >= activeIcons} height={15} width={18} />);
  }

  return <div className="icons">{icons}</div>;
};

const CheckboxFilter = ({ isChecked, connectorDisplay, filter, handleClick, isNested, children }) => {
  const { icon, label, value, count, newSign } = filter;
  const additiveFilterKeys = ['openToNewInvestors', 'investmentTypeCode', 'includeLeveragedFunds/includeInverseFunds'];
  const matches = _.defaultTo(count, '--');
  const formattedMatches = additiveFilterKeys.includes(filter.key) ? `+ ${matches}` : matches;
  const matchesDisplay = isChecked ? 'active' : '';
  const checkboxFilterClassName = isChecked ? 'checkbox-filter checked' : 'checkbox-filter unchecked';
  const displayIcon = icon === 'star' ? _renderMorningstarIcons(Number(value), icon) : null;
  const newSignDisplay = newSign ? <span style={{ color: 'orange' }}> &nbsp;&nbsp; NEW</span> : null;
  const displayLabel = label ? label.split('::')[0] : label;

  const clickFunction = e => {
    e.preventDefault();
    e.stopPropagation();
    handleClick(label, !isChecked);
  };
  return (
    <li
      className={`checkbox-input tabIndex={0} CheckboxFilter-class ${checkboxFilterClassName} ${connectorDisplay}`}
      onClick={e => {
        clickFunction(e);
      }}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          clickFunction(e);
        }
      }}
    >
      {isNested ? (
        <div
          className="checkbox-filter-spacer"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      ) : null}
      <label tabIndex={0} for={label}>
        <input type="checkbox" id={label} name={label} value={label} />
        <svg
          class="checkbox"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <path class="checkmark" d="M 5,11 L 8,14 L 14, 6" />
        </svg>
      </label>
      {displayIcon !== null ? (
        <div className="checkbox-filter-icon">{displayIcon}</div>
      ) : (
        <div className="checkbox-filter-label">
          {displayLabel}
          {newSignDisplay}
        </div>
      )}
      <div className="checkbox-filter-children">{children}</div>
      <div className="checkbox-match-details">
        <div className={`checkbox-cumulative-matches ${matchesDisplay}`}>{formattedMatches}</div>
      </div>
    </li>
  );
};

CheckboxFilter.defaultProps = {
  isNested: false,
  connectorDisplay: '',
};

CheckboxFilter.propTypes = {
  isChecked: PropTypes.bool,

  /** Will render a spacer to the left of the filter if true */
  isNested: PropTypes.bool,

  /** If filter is for multi-select interface, determines the CSS display class of the connector lines */
  connectorDisplay: PropTypes.string,

  filter: PropTypes.object.isRequired,

  /** Handles updating checkbox selection */
  handleClick: PropTypes.func.isRequired,
};

export default CheckboxFilter;
