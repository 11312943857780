import _ from 'lodash';
import { icons } from 'utilities';
import React from 'react';
import StarIconSvg from 'components/Shared/StarIconSvg';
import {
  ETF_LINK_URL,
  FUND_LINK_URL,
  FUND_LINK_ID_FIELD,
  FUND_LINK_OPENS_NEW_WINDOW,
  PLAN_NUMBER,
  FUND_DATA_SUBJECT_AREA,
  APP_CHANNEL,
} from 'configs/AppFeatureConfig';

// "text";
// "number";
// "pct";+
// "posNeg";+
// "price";
// "link";
// "date";
// "asOfDate";
// "icon";
// "multiLine";
// "mStarRating";
// "numRated";
// "riskScale";
// "yn";
// "years";
// "magnitude"

//TODO: need to introduce some sort of ordering so that format flags can be sorted in useful way
const format_statusDescriptionAsterisks = (val, key) => {
  // Map service status description text to asterisks
  const textMap = {
    'Closed to all investors': '*',
    'Closed to new investors': '**',
  };
  if (val in textMap) {
    return (
      <span key={key} className="status-description-asterisks">
        {textMap[val]}
      </span>
    );
  }
  return null;
};

/** formats number with commas */
const format_num = (val, key) => {
  const valString = val.toString().trim();

  let decimalPointPosition = _.findLastIndex(valString, char => char === '.');
  if (decimalPointPosition === -1) decimalPointPosition = valString.length;

  // initialized with substring from decimal point to last digit
  let resultString = valString.substr(decimalPointPosition);
  // add commas traversing valString backwards from index: decimalPointPosition-1 to 0
  for (var i = decimalPointPosition - 1, integerDigitPlace = 0; i >= 0; i--, integerDigitPlace++) {
    const char = valString.charAt(i);
    const shouldAddComma = integerDigitPlace % 3 === 0 && integerDigitPlace !== 0;
    resultString = shouldAddComma ? char + ',' + resultString : char + resultString;
  }
  return resultString;
};

const format_icon = (investmentType, val, additionalFormatData, label, key) => {
  const icon = additionalFormatData.icon;
  if (icon === 'etfIcon') {
    if (investmentType === 'MFN') {
      return null;
    } else {
      return <img key={key} src={icons[icon]} alt={label} />;
    }
  }
  if (val === 'FIDELITY MANAGED' || val === 'Y' || val === 'Fidelity Investments') {
    return <img key={key} src={icons[icon]} alt={label} />;
  }
  if (icon === 'ntfIcon') {
    return (
      <span key={key} className="results-transaction-fee">
        $
      </span>
    );
  }
  return null;
};

const format_dualStateIcon = (val, additionalFormatData, label, key) => {
  var icon = null;
  var displayLabel = null;
  if (val === 'Y') {
    icon = additionalFormatData.iconY;
    displayLabel = additionalFormatData.labelY != null ? additionalFormatData.labelY : '';
  } else if (val === 'N') {
    icon = additionalFormatData.iconN;
    displayLabel = additionalFormatData.labelN != null ? additionalFormatData.labelN : '';
  }
  if (icon != null && displayLabel != null) {
    return [
      <img key={`${key}_icon`} src={icons[icon]} alt={label} />,
      <span key={`${key}_label`} className="label">
        {displayLabel}
      </span>,
    ];
  }
  return '--';
};

const format_pct = (val, key) => {
  return `${format_num(val)}%`;
};

const format_beforeSurrenderCharge = (accumulatedVal, key) => {
  return <div key={key}>{accumulatedVal}</div>;
};

const format_afterSurrenderCharge = (accumulatedVal, key) => {
  return accumulatedVal === '--' ? null : <div key={key}>{accumulatedVal}</div>;
};

const format_surrenderChargeFee = (rawVal, key) => {
  // used to indicate that after surrender charge exists
  return rawVal ? <div key={key}>After surrender fee</div> : '';
};

const format_posNeg = (val, rawVal, key) => {
  if (rawVal > 0) {
    return <span key={key} className="positive">{`+${val}`}</span>;
  }
  if (rawVal < 0) {
    return (
      <span key={key} className="negative">
        {val}
      </span>
    );
  }
  return val;
};

const format_price = (rawVal, key) => {
  const valString = rawVal.toString().trim();
  // we can't use Math.abs otherwise it'll truncate our trailing zeros
  const absValString = rawVal < 0 ? valString.substr(1) : valString;
  const sign = rawVal < 0 ? '-' : '';
  return `${sign}$${format_num(absValString)}`;
};

const format_years = (rawVal, key) => {
  return `${rawVal} years`;
};

const format_fundLink = (val, fundData, key) => {
  const ticker = APP_CHANNEL === 'fili' ? fundData.tradingSymbol : fundData.ticker;
  const portfolioNumber = APP_CHANNEL === 'fili' ? `, ${fundData.portfolioNum}` : ''


  const isEtf = (fundData.fundInvestmentTypeName || fundData.fundInvestmentTypecd) === 'ETF';
  const etfLink = `${ETF_LINK_URL}?symbols=${ticker}`;

  const planNumberQuery = !_.isNil(PLAN_NUMBER) ? '&plan=' + PLAN_NUMBER : '';
  const fundLinkUrl = isEtf ? etfLink : FUND_LINK_URL + _.get(fundData, FUND_LINK_ID_FIELD, '') + planNumberQuery;
  const fundLinkClickHandler = event => {
    if (FUND_LINK_OPENS_NEW_WINDOW) {
      event.preventDefault();
      window.open(fundLinkUrl, '_blank', 'width=800,height=600,toolbar=no,scrollbars=yes,resizable=yes');
    }
  };

  val = !_.isNil(ticker) ? `${val} (${ticker}${portfolioNumber})` : val;

  return <a key={key} href={fundLinkUrl} onClick={fundLinkClickHandler} dangerouslySetInnerHTML={{ __html: val }} />;
};

const format_mStarRating = (val, isMobile, key) => {
  const MAX_STARS = 5;
  let height = 10,
    width = 12;
  if (isMobile) {
    height = 14;
    width = 17;
  }

  const stars = [];
  for (let i = 0; i < MAX_STARS; i++) {
    stars.push(<StarIconSvg key={i} isGrayedOut={i >= val} height={height} width={width} />);
  }
  return (
    <div key={key} className="star-group">
      {stars}
    </div>
  );
};

const format_numFunds = (val, key) => {
  const text = `(${format_num(val)} Funds)`;
  return (
    <span key={key} className="number-of-funds soft">
      {text}
    </span>
  );
};

const format_riskScale = (val, key) => {
  const className = `risk-icon-gradient result-risk risk-level-${val}`;
  return (
    <div key={key} className="risk-rating-container">
      <div className={className} />
      <span className="risk-rating-text left">LOWER</span>
      <span className="risk-rating-text right">HIGHER</span>
    </div>
  );
};

const format_boxScale = (val, key) => {
  let boxes = [];
  for (let i = 1; i <= 5; i++) {
    if (i <= val) {
      boxes.push(<span className="box-scale-blue" key={i} />);
    } else {
      boxes.push(<span className="box-scale-gray" key={i} />);
    }
  }
  const scaleValToLabel = {
    1: 'Low',
    2: 'Below Average',
    3: 'Average',
    4: 'Above Average',
    5: 'High',
  };
  return (
    <div key={key} className="box-scale-container">
      <div className="box-scale">{boxes}</div>
      <span className="box-scale-label">{scaleValToLabel[val]}</span>
    </div>
  );
};

const format_asOfDate = (val, key) => {
  return (
    <span key={key} className="results-as-of-date soft">
      {val}
    </span>
  );
};

const format_transactionFee = (val, key) => {
  if (val === 'N')
    return (
      <span key={key} className="results-transaction-fee">
        $
      </span>
    );
  return null;
};

const format_ETF = (val, key) => {
  if (val === 'ETF') {
    return (
      <span>
        <img key={key} src={icons['etfIconSmall']} alt={'etfIconSmals'} />
        &nbsp;
      </span>
    );
  }
  return null;
};

const formatForNilVal = (formatFlag, additionalInfo, key) => {
  if (formatFlag === 'icon') {
    return additionalInfo && additionalInfo.icon === 'ntfIcon' ? '$0' : null;
  }

  switch (formatFlag) {
    case 'ETF':
    case 'statusDescriptionAsterisks':
    case 'transactionFee':
    case 'surrenderChargeFee':
      return null;
    default:
      return '--';
  }
};

const formatResult = (val, formatConfig, label, fundData, options = { isMobile: false }, index) => {
  const { isMobile } = options;
  const { format, additionalFormatData } = formatConfig;
  const rawVal = _.clone(val);
  const formattedVal = _.reduce(
    format,
    (accumulatedVal, formatFlag, formatIndex) => {
      const key = `${formatFlag}_${formatIndex}`;
      if (_.isNil(accumulatedVal)) {
        return formatForNilVal(formatFlag, additionalFormatData);
      }
      switch (formatFlag) {
        case 'text':
          return accumulatedVal;
        case 'statusDescriptionAsterisks':
          return format_statusDescriptionAsterisks(rawVal, key);
        case 'icon':
          return format_icon(fundData.fundInvestmentTypecd, accumulatedVal, additionalFormatData, label, key);
        case 'dualStateIcon':
          return format_dualStateIcon(accumulatedVal, additionalFormatData, label, key);
        case 'pct':
          return format_pct(rawVal, key);
        case 'beforeSurrenderCharge':
          return format_beforeSurrenderCharge(accumulatedVal, key);
        case 'afterSurrenderCharge':
          return format_afterSurrenderCharge(accumulatedVal, key);
        case 'surrenderChargeFee':
          return format_surrenderChargeFee(accumulatedVal, rawVal, key);
        case 'price':
          return format_price(rawVal, key);
        case 'number':
          return format_num(accumulatedVal, key);
        case 'posNeg':
          return format_posNeg(accumulatedVal, rawVal, key);
        case 'years':
          return format_years(accumulatedVal, rawVal, key);
        case 'fundLink':
          return format_fundLink(accumulatedVal, fundData, key);
        case 'mStarRating':
          return format_mStarRating(accumulatedVal, isMobile, key);
        case 'boxScale':
          return format_boxScale(accumulatedVal, key);
        case 'numFunds':
          return format_numFunds(accumulatedVal, key);
        case 'riskScale':
          return format_riskScale(accumulatedVal, key);
        case 'asOfDate':
          return format_asOfDate(accumulatedVal, key);
        case 'transactionFee':
          return format_transactionFee(accumulatedVal, key);
        case 'ETF':
          return format_ETF(accumulatedVal, key);
        default:
          return accumulatedVal;
      }
    },
    val,
  );
  return React.isValidElement(formattedVal) || formattedVal === '--' || formattedVal === '' || formattedVal === null ? (
    formattedVal
  ) : (
    <span key={index}>{formattedVal}</span>
  );
};

const _getDataPointPath = (serviceField, subjectArea, subjectAreaSubGroup) => {
  let dataPointPath = subjectArea;
  if (subjectAreaSubGroup) {
    dataPointPath += '.' + subjectAreaSubGroup;
  }
  dataPointPath += '.' + serviceField;
  return dataPointPath;
};

const formatResultField = (result, resultFieldConfig, formatOptions = { isMobile: false }) => {
  const { dataPointFormatting, label } = resultFieldConfig;
  const values = _.chain(dataPointFormatting)
    .map((formatData, serviceField, index) => {
      const dataPointPath = _getDataPointPath(serviceField, formatData.subjectArea, formatData.subjectAreaSubGroup);
      const rawVal = _.get(result, dataPointPath);
      return formatResult(rawVal, formatData, label, result[FUND_DATA_SUBJECT_AREA], formatOptions, index);
    })
    .filter(value => !_.isNil(value) && value !== '')
    .value();
  const valsContainsDashes = _.some(values, value => {
    return value === '--';
  });
  if (valsContainsDashes) {
    return ['--'];
  }
  return values;
};

export default formatResultField;
