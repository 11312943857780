import React from 'react';
import MadLibsCriterion from './MadLibsCriterion'

const CriteriaSelectionBox = ({toggleCriterionSelection, criteria}) => {

    const createCriteria = () =>{
       return criteria.map(x => <MadLibsCriterion criterion={x} key={x.label} toggleCriterionSelection={toggleCriterionSelection} />)
    }
   
    return (
        <div id="additional-critieria">
            {createCriteria()}       
        </div>
    )
}
export default CriteriaSelectionBox