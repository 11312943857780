import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class ViewTabs extends Component {
  onKeyDown = (e, label, shortName) => {
    const { viewTabDisplay, activeViewTab } = this.props;
    if (activeViewTab !== shortName && (e.key === 'Enter' || e.key === ' ')) {
      e.stopPropagation();
      e.preventDefault();
      this.props.updateViewTab(label, shortName);
    } else if (
      viewTabDisplay &&
      activeViewTab &&
      (e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Home' || e.key === 'End')
    ) {
      const keys = Object.keys(viewTabDisplay);
      let index = keys.findIndex(t => t === activeViewTab);
      e.stopPropagation();
      e.preventDefault();
      if (index >= 0 && keys.length > 0) {
        if (e.key === 'ArrowLeft') {
          index = index === 0 ? keys.length - 1 : --index;
        } else if (e.key === 'ArrowRight') {
          index = index === keys.length - 1 ? 0 : ++index;
        } else if (e.key === 'Home') {
          index = 0;
        } else if (e.key === 'End') {
          index = keys.length - 1;
        }
        const tab = viewTabDisplay[keys[index]];
        if (tab && tab.label) {
          this.props.updateViewTab(tab.label, tab.shortName);
          const tabId = `view-tab-${tab.shortName}-id`;
          const current = document.querySelector('#'+tabId);
		  if (current){
			current.focus();
		  }
        }
      }
    } else if (e.key === 'Enter' || e.key === ' ') {
		e.stopPropagation();
		e.preventDefault();	
	}
  };
  render() {
    const { viewTabDisplay } = this.props;
    const viewTabs = _.map(viewTabDisplay, (tabDisplay, key) => {
      const { label, shortName } = tabDisplay;
      const activeClass = this.props.activeViewTab === shortName ? 'active' : '';
      const tabId = `view-tab-${shortName}-id`;
      const panelId = `view-tab-panel-${shortName}-id`;
      return (
        <li
          id={tabId}
          role="tab"
          aria-selected={activeClass === 'active'}
          aria-controls={panelId}
          className={`view-tab ${activeClass}`}
          tabIndex={activeClass? "0":"-1"}
          key={shortName}
          onKeyDown={e => {
            this.onKeyDown(e, label, shortName);
          }}
          onClick={e => {
            if (this.props.activeViewTab !== shortName) {
              this.props.updateViewTab(label, shortName);
            }
          }}
        >
          {label}
        </li>
      );
    });
    return (
      <ul role="tablist" className="view-tabs-container">
        {viewTabs}
      </ul>
    );
  }
}

ViewTabs.propTypes = {
  activeTab: PropTypes.string,
};

export default ViewTabs;
