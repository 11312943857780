import { all, fork } from 'redux-saga/effects';
import endpoints from 'services/endpoints';
import fundClassificationSagaFactory from 'sagas/FundClassificationSaga';
import fundDisclosureSagaFactory from 'sagas/FundDisclosureSaga';
import fundMatchesSagaFactory from 'sagas/FundMatchesSaga';
import fundSearchSagaFactory from 'sagas/FundSearchSaga';
import downloadResultSagaFactory from 'sagas/DownloadResultSaga';
import fundClassificationApiFactory from 'services/FundClassificationApi';
import fundDisclosureApiFactory from 'services/FundDisclosureApi';
import fundMatchesApiFactory from 'services/FundMatchesApi';
import fundSearchApiFactory from 'services/FundSearchApi';
import downloadResultApiFactory from 'services/DownloadResultApi';

const rootSaga = () => {
  const fundClassificationApi = fundClassificationApiFactory.create(endpoints.API_BASE_URL);
  const fundMatchesApi = fundMatchesApiFactory.create(endpoints.API_BASE_URL);
  const fundDisclosureApi = fundDisclosureApiFactory.create(endpoints.API_BASE_URL);
  const fundSearchApi = fundSearchApiFactory.create(endpoints.API_BASE_URL);
  const downloadResultApi = downloadResultApiFactory.create(endpoints.API_BASE_URL);

  const fundClassificationSaga = fundClassificationSagaFactory(fundClassificationApi);
  const fundDisclosureSaga = fundDisclosureSagaFactory(fundDisclosureApi);
  const fundMatchesSaga = fundMatchesSagaFactory(fundMatchesApi);
  const fundSearchSaga = fundSearchSagaFactory(fundSearchApi);
  const downloadResultSaga = downloadResultSagaFactory(downloadResultApi);

  function* root() {
    yield all([
      fork(fundClassificationSaga.watcher),
      fork(fundDisclosureSaga.watcher),
      fork(fundMatchesSaga.watcher),
      fork(fundSearchSaga.watcher),
      fork(downloadResultSaga.watcher),
    ]);
  }

  return root;
};

export default rootSaga;
