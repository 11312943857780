import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';
import Types from 'actions/Types';
import mobileUtils from 'utilities/mobileUtils';

export const INITIAL_STATE = {
  isMobile: mobileUtils.isMobile(),
};

const updateMobileStatus = (state = INITIAL_STATE, action = {}) => {
  return { isMobile: action.isMobile };
};

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};

const ACTION_HANDLERS = {
  [Types.UPDATE_MOBILE_STATUS]: updateMobileStatus,
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

export const getMobileStatus = state => state.mobileStatus;
export const getIsMobile = state => state.mobileStatus.isMobile;
