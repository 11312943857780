import React from 'react';
import { PropTypes } from 'prop-types';

const ActiveCriterionDefinition = ({ glossaryLink }) => {
  return (
    <div className="active-criterion-definition">
      <a href={glossaryLink} target="_blank">
        <span>Definition</span>
        <div role="img" alt="Popup Icon" className="popup-icon">
          <span>?</span>
        </div>
      </a>
    </div>
  );
};

ActiveCriterionDefinition.propTypes = {
  /** Link to glossary definition of criterion */
  glossaryLink: PropTypes.string,
};

export default ActiveCriterionDefinition;
