import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { icons } from 'utilities';
import NestedInactiveCriterionContainer from 'components/CriteriaGroup/NestedInactiveCriterionContainer';

class CriteriaSubGroup extends Component {
  constructor() {
    super();

    this.state = {
      isExpanded: false,
    };
  }

  toggleExpansion = () => {
    this.setState({ isExpanded: !this.state.isExpanded });
  };

  _renderNestedInactiveCriteria = () => {
    return this.props.inactiveCriteria.map(criterion => (
      <NestedInactiveCriterionContainer
        key={criterion.id}
        criterion={criterion}
        setFocusCriteria={this.props.setFocusCriteria}
      />
    ));
  };

  render() {
    const { isExpanded } = this.state;
    const nestedInactiveCriteria = this._renderNestedInactiveCriteria();
    const nestedStyling = isExpanded ? '' : 'off';
    const arrowIcon = isExpanded ? icons.arrowUp : icons.arrowDown;
    const topLevelStyling = isExpanded ? 'expanded' : 'collapsed';
    return (
      <div className={`criteria-sub-group ${topLevelStyling}`}>
        <div className="criteria-sub-group-header">
          <div className="criteria-sub-group-spacer" />
          <div
            tabIndex={0}
            className="criteria-sub-group-content"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                this.toggleExpansion();
              }
            }}
            about
            onClick={this.toggleExpansion}
          >
            <a>{this.props.label}</a>
            <img src={arrowIcon} alt="Arrow down icon" />
          </div>
        </div>
        <ul className={`${nestedStyling} inactive-criteria-by-subgroup`}>{nestedInactiveCriteria}</ul>
      </div>
    );
  }
}

CriteriaSubGroup.propTypes = {
  /** The name of the sub group */
  label: PropTypes.string.isRequired,

  /** An array representing each of the inactive criteria in this sub group */
  inactiveCriteria: PropTypes.array.isRequired,
};

export default CriteriaSubGroup;
