import _ from 'lodash';
import { createSelector } from 'reselect';
import { START_ROUTE, MOBILE_START_ROUTE, ROUTES } from 'configs/AppFeatureConfig';
import { getIsMobile } from 'reducers/MobileStatusReducer';
//this file looks up router state as presented by redux little router.



export const getRouterState = state => state;
//provides the complete state.router package
export const getRouterPathname = state => state.router.location.pathname;
//provides the complete pathname
export const getRouterQuery = state => state.query;
//provides the query paramaters

export const getRouterRoute = createSelector([getRouterState], routerState => { 
  return routerState.route;
});
//this provides the route match string which is basically the route id /results/:displaytype etc

export const getRouterResult = createSelector([getRouterState], routerState => {
  return ROUTES[routerState.route]
});
//just an object that contains the page title

export const getRouterPersistentQueries = createSelector([getRouterState], routerState => {
  return routerState.query;
});
//pick is taking the persitent queries out of the queries and returning them

export const getCurrentStartRoute = createSelector([getIsMobile], mobileStatus => {
  return mobileStatus && !_.isNil(MOBILE_START_ROUTE) ? MOBILE_START_ROUTE : START_ROUTE;
});
//checks mobilestatus and if mobile start route is not nil, returns the mobile start route