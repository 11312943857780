import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StarIconSvg extends Component {
  _getStarStyle = () => {
    const color = this.props.isGrayedOut ? this.props.disabledColor : this.props.color;
    const style = {
      fill: color,
      strokeWidth: 5,
      stroke: color,
    };
    return style;
  };
  render() {
    return (
      <svg
        className={this.props.className}
        height={this.props.height}
        width={this.props.width}
        viewBox="0 0 210 210"
        style={this._getStarStyle()}
      >
        <polygon points="100,10 40,198 190,78 10,78 160,198" />
        Sorry, your browser does not support SVG.
      </svg>
    );
  }
}
StarIconSvg.defaultProps = {
  height: 20,
  width: 25,
  color: '#eca400',
  disabledColor: '#cdcdcd',
  className: 'star-icon',
};
StarIconSvg.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
  disabledColor: PropTypes.string,
  isGrayedOut: PropTypes.bool,
  className: PropTypes.string,
};

export default StarIconSvg;
