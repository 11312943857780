import React from 'react';
import { icons } from 'utilities';

const MadLibsCriterion = ({ criterion, toggleCriterionSelection, className }) => {
  const checkedStateClassName = criterion.selected ? 'checked' : 'unchecked';

  return (
    <div
      id="additional-critieria"
      className={`madlib-criterion ${className}`}
      onClick={() => toggleCriterionSelection(criterion)}
    >
      {className ? (
        <React.Fragment>
          <span id="additional-critieria" dangerouslySetInnerHTML={{ __html: criterion.label }} />
          <img id="additional-critieria" alt="deselect icon" className={'selectedImg'} src={icons.closeicon} />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={`checkbox-input ${checkedStateClassName}`}>
            <label id="additional-critieria">
              <input type="checkbox" id="additional-critieria" name={criterion.label} value={criterion.label} />
              <svg
                className="checkbox"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <path class="checkmark" d="M 5,11 L 8,14 L 14, 6" />
              </svg>
            </label>
          </div>
          <span id="additional-critieria">{criterion.label}</span>
        </React.Fragment>
      )}
    </div>
  );
};

export default MadLibsCriterion;
