import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { icons } from 'utilities';
import { Popper } from 'react-popper';
import _ from 'lodash';

class Popover extends Component {
  constructor(props) {
    super(props);
    this.focusPopover = this.focusPopover.bind(this);
  }
  componentDidMount() {
    this.focusPopover();
  }
  componentDidUpdate() {
    this.focusPopover();
  }
  focusPopover() {
    if (this.popperRef) {
		if(this.props.isKeyNaviMode){
			this.popperRef.querySelector(".close-icon").focus();
		} else {
			this.popperRef.focus({ preventScroll: true });
		}
    }
  }
  blurPopover(e) {
    if (e.target.className && !e.target.className.includes('popover-btn')) {
      this.props.closePopover();
    }
  }
  keyDownPopover(e) {
	if (e.keyCode === 27){ //esc key
		this.props.closePopover();
	} else if (e.keyCode === 9) { // tab key.
		const tappableElements = this.popperRef.querySelectorAll("button");
		if (tappableElements.length>0){
			tappableElements.forEach((b, index)=> {
				if (document.activeElement.isSameNode(b)) {
					if (!e.shiftKey) {  // just tab
						if (index === tappableElements.length-1){
							e.preventDefault();
							e.stopPropagation();
							tappableElements[0].focus();
						}
					} else {  // shift + tab
						if (index === 0) {
							e.preventDefault();
							e.stopPropagation();
							tappableElements[tappableElements.length-1].focus();
						}
					}
				}
			});
		}
	}
  }
  _getArrowPlacementClass = () => {
    const elm = document.getElementById(this.props.id);
    if (elm) {
      return elm.getAttribute('data-popper-placement');
    }

    const placement = this.props.placement;
    if (placement.indexOf('top') > -1) {
      return 'top';
    }
    //bottom
    if (placement.indexOf('bottom') > -1) {
      return 'bottom';
    }
    if (placement.indexOf('left') > -1) {
      return 'left';
    }
    if (placement.indexOf('right') > -1) {
      return 'right';
    }
  };
  _renderError = () => {
    if (_.isNil(this.props.error)) return null;
    return (
      <div className="error-container">
        <div className="error-text">{this.props.error}</div>
      </div>
    );
  };
  _renderTitleSubtext = () => {
    if (_.isNil(this.props.titleSubtext)) return null;
    return <span className="popover-title-subtext">{this.props.titleSubtext}</span>;
  };
  render() {
    let popoverClassName = _.isNil(this.props.error) ? 'popover' : 'popover error';
    if (this.props.isKeyNaviMode) {
		// key navigation mode with tab, space, enter.
      return (
        <Popper
          referenceElement={this.props.referenceElement}
          placement={this.props.placement}
          modifiers={{
            offset: { offset: '10px, 10px' },
            preventOverflow: { boundariesElement: document.getElementById('main') },
          }}
          innerRef={node => (this.popperRef = node)}
        >
          {({ ref, style, placement, arrowProps }) => {
            const divStyle = {
              ...style,
              ...this.props.style,
            };
            const maxWidthStyle =
              this.props.style && this.props.style.maxWidth ? { width: this.props.style.maxWidth } : null;
            return (
              <div
                tabIndex="-1"
                className={popoverClassName}
				ref={ref}
                id={this.props.id}
                style={divStyle}
                data-popper-placement={placement}
                onKeyDown={e => this.keyDownPopover(e)}
              >
                {this._renderError()}
                <div className="popover-header disclosure-heading">
                  <span className="popover-title">{this.props.title}</span>
                  {this._renderTitleSubtext()}
                  <button
					className="close-icon transparent-btn"
                    aria-label={this.props.title + ' close'}
                    onClick={e => {
                      this.props.closePopover();
                      e.stopPropagation();
                    }}
                  >
                    <img className="close-icon" src={icons.greyX} alt="Close Popover" aria-hidden="true" />
                  </button>
                </div>
                <div className="popover-body disclosure-text" style={maxWidthStyle}>
                  {this.props.body}
                </div>
                <div
                  className={`popover-arrow ${this._getArrowPlacementClass()}`}
                  ref={arrowProps.ref}
                  style={arrowProps.style}
                />
              </div>
            );
          }}
        </Popper>
      );
    }

	// mouse clicking or touch 
	popoverClassName = popoverClassName+" popover-div-mouse-interface"
    return (
		<Popper
		  referenceElement={this.props.referenceElement}
		  placement={this.props.placement}
		  modifiers={{
			offset: { offset: '10px, 10px' },
			preventOverflow: { boundariesElement: document.getElementById('main') },
		  }}
		  innerRef={node => (this.popperRef = node)}
		>
		  {({ ref, style, placement, arrowProps }) => {
			const divStyle = {
			  ...style,
			  ...this.props.style,
			};
			const maxWidthStyle =
			  this.props.style && this.props.style.maxWidth ? { width: this.props.style.maxWidth } : null;
			return (
			  <div
				tabIndex="-1"
				className={popoverClassName}
				id={this.props.id}
				ref={ref}
				style={divStyle}
				data-popper-placement={placement}
				onBlur={this.props.closePopover}
			  >
				{this._renderError()}
				<div className="popover-header disclosure-heading">
				  <span className="popover-title">{this.props.title}</span>
				  {this._renderTitleSubtext()}
				  <img
					className="close-icon"
					src={icons.greyX}
					alt="Close popover button"
					onClick={e => {
					  this.props.closePopover();
					  e.stopPropagation();
					}}
				  />
				</div>
				<div className="popover-body disclosure-text" style={maxWidthStyle}>
				  {this.props.body}
				</div>
				<div
				  className={`popover-arrow ${this._getArrowPlacementClass()}`}
				  ref={arrowProps.ref}
				  style={arrowProps.style}
				/>
			  </div>
			);
		  }}
		</Popper>
	  );
  }
}

Popover.propTypes = {
  //id attribute that will be applied to the outermost div of the popoover
  id: PropTypes.string.isRequired,
  //String to be displayed as the title of the popover
  title: PropTypes.string.isRequired,
  //Optional string to be displayed as the title subtext (smaller font w/ gray color) of the popover
  titleSubtext: PropTypes.string,
  //function to be called when the popover is closed
  closePopover: PropTypes.func.isRequired,
  //content to render in the popover body
  body: PropTypes.node.isRequired,
  //Content to be displayed above the popover title to indicate error state
  error: PropTypes.node,
  //element that the popover should be pointing towards and positioned relative to
  referenceElement: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  //String used to denote how popover should be positioned relative to the reference element (see react-popper docs for list of accepted values)
  placement: PropTypes.string,
  style: PropTypes.object,
  // true: keyboard navigation using tab, spacebar, enter key.
  isKeyNaviMode: PropTypes.bool,
};

export default Popover;
