import apisauce from 'apisauce';
import {mockDataInjection} from "../tests/lib/mockDataSetInjection";

const create = baseURL => {
  const api = apisauce.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    timeout: mockDataInjection.enableDataInjection()?3000: 10000,
  });

  const postData = (endpoint, parameters) => {
    if (parameters.hasOwnProperty('sustainableInvestment') || parameters.hasOwnProperty('employsExclusions')){
      const esgCodesString = [parameters.sustainableInvestment, parameters.employsExclusions]
                            .filter( x => x)
                            .join(',')

      parameters["esgCodes"] = esgCodesString
      
      delete parameters["sustainableInvestment"];
      delete parameters["employsExclusions"];
    }
    return api.post(endpoint, parameters);
  };
  return {
    postData,
  };
};

export default {
  create,
};
