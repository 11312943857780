import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Creators from 'actions/CriteriaActions';
import _ from 'lodash';
import { FUND_ID_FIELD, APP_CHANNEL } from 'configs/AppFeatureConfig';
import { getFundsSelected, getSelectedFundIds } from 'reducers/FundsSelectedReducer';
import { getDisplayType } from 'reducers/DisplayTypeReducer';

class ActionCheckbox extends Component {
  constructor(props) {
    super(props);
    this.checkboxRef = null;
    this.setCheckboxRef = element => {
      this.checkboxRef = element;
    };
    this._checkboxClicked = this._checkboxClicked.bind(this);
  }
  _getChartColor = () => {
    const currentFundSelection = _.find(this.props.fundsSelected, fund => {
      return fund[FUND_ID_FIELD] === this.props.additionalFundData[FUND_ID_FIELD];
    });
    return _.get(currentFundSelection, 'color') || null;
  };

  _determineIsChecked = () => {
    return _.includes(this.props.selectedFundIds, this.props.additionalFundData[FUND_ID_FIELD]);
  };
  _checkboxClicked = () => {
    this.props.handleClick(!this._determineIsChecked(), this.props.additionalFundData, this.checkboxRef);
  };
  _renderColorBox = () => {
    let boxStyle = null;
    if (this.props.displayType === 'compare') {
      boxStyle = { borderRight: `10px solid ${this._getChartColor()}` };
    }
    return boxStyle;
  };
  _renderInput = key => {
	const ariaLabel = APP_CHANNEL !== 'fili'? 
		this.props.additionalFundData['name'].replace('<sup>&reg;</sup>','')+", "+this.props.additionalFundData['ticker'] :
		(this.props.additionalFundData['longName'].replace('<sup>&reg;</sup>','')+", "+this.props.additionalFundData['symbol']+", "+ this.props.additionalFundData['portfolioNum']);
    let label = null;
    if (this.props.displayType === 'compare') {
      label = <label ref={this.setCheckboxRef} className="x-icon" htmlFor={`action-checkbox-${key}`} aria-label={ariaLabel} />;
    } else {
      label = <label ref={this.setCheckboxRef} className="checkbox-icon" htmlFor={`action-checkbox-${key}`} aria-label={ariaLabel} />;
    }
    return (
      <React.Fragment>
        <input
          id={`action-checkbox-${key}`}
          className="action-checkbox"
          type="checkbox"
          checked={this._determineIsChecked()}
          onChange={element => {
            this.props.setCurrentCheckboxRef(element);
            this._checkboxClicked();
          }}
        />
        {label}
      </React.Fragment>
    );
  };

  render() {
    const { cusip, fundCode, id } = this.props.additionalFundData || {};
    // Not all funds/investments have cusips
    const key = `cusip-${cusip || ''}-fund-code-${fundCode || ''}-id-${id || ''}`;
    return (
      <td key={key} style={this._renderColorBox()} className={this.props.className}>
        <div className="action-checkbox-container">{this._renderInput(key)}</div>
      </td>
    );
  }
}

ActionCheckbox.propTypes = {
  fundsSelected: PropTypes.array.isRequired,
  selectedFundIds: PropTypes.array.isRequired,
  displayType: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  additionalFundData: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const displayType = getDisplayType(state);
  const fundsSelected = getFundsSelected(state);
  const selectedFundIds = getSelectedFundIds(state);
  return {
    displayType,
    fundsSelected,
    selectedFundIds,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateFundsSelected: fundsSelected => {
      dispatch(Creators.updateFundsSelected(fundsSelected));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActionCheckbox);
