import { createReducer } from 'reduxsauce';
import Types from 'actions/Types';
import _ from 'lodash';

export const INITIAL_STATE = '--';

export const updateMatches = (state = INITIAL_STATE, action = {}) => {
  if (_.get(action, 'payload.ok', null)) {
    return action.payload.data.totalCount;
  }
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.QUICK_CRITERIA_MATCHES_API_SUCCESS]: updateMatches,
};

export default createReducer(INITIAL_STATE, HANDLERS);

export const getTotalMatches = (state) => {
  return state.quickCriteriaMatches
};
