import { connect } from 'react-redux';
import * as odaTracking from 'utilities/odaTracking';
import * as adobeAnalytics from 'utilities/adobeAnalytics';
import Creators from 'actions/CriteriaActions';
import _ from 'lodash';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import {
  RESULT_FEATURES,
  FUND_LINK_URL,
  FUND_LINK_ID_FIELD,
  FUND_LINK_OPENS_NEW_WINDOW,
  PLAN_NUMBER,
  START_PAGE_TYPE,
} from 'configs/AppFeatureConfig';
import React from 'react';
import PropTypes from 'prop-types';
import { icons } from 'utilities';

const ResultsCardItemFooter = ({
  handleToggleExpansion,
  isExpanded,
  showResearchButton,
  additionalData,
  trackBuyButton,
  trackResearchButton,
  displayType,
}) => {
  const { id } = additionalData;
  const icon = isExpanded ? icons.collapseImg : icons.expandImg;
  let baseUrl = `https://digital.fidelity.com/ftgw/digital/`;
  let buyLink = '';
  if (START_PAGE_TYPE === 'mfe') {
    buyLink = baseUrl + `trade-mutualfund?${RESULT_FEATURES.BUY_BUTTON_LINK_PARAMS}&SECURITY_ID=${id}`;
  } else {
    buyLink = baseUrl + 'annuity-exchange';
  }
  const researchButtonStyle = showResearchButton ? '' : 'off';
  const buyButton = (
    <div className="buy-button">
      <a href={buyLink} onClick={() => trackBuyButton(displayType, additionalData)}>
        <button>{RESULT_FEATURES.BUY_BUTTON_LABEL}</button>
      </a>
    </div>
  );
  const planNumberQuery = !_.isNil(PLAN_NUMBER) ? '&plan=' + PLAN_NUMBER : '';
  const fundLinkUrl = FUND_LINK_URL + _.get(additionalData, FUND_LINK_ID_FIELD, '') + planNumberQuery;

  const fundLinkClickHandler = event => {
    trackResearchButton(displayType, additionalData.ticker);
    if (FUND_LINK_OPENS_NEW_WINDOW) {
      event.preventDefault();
      window.open(fundLinkUrl, '_blank', 'width=800,height=600,toolbar=no,scrollbars=yes,resizable=yes');
    }
  };
  return (
    <div className="results-card-item-footer">
      <div className="details-expander-button" onClick={() => handleToggleExpansion()}>
        <img src={icon} alt="Expand/Collapse Icon" />
        <button className="as-link">Details</button>
      </div>

      <div className="external-link-buttons">
        <div className={`research-button ${researchButtonStyle}`}>
          <a href={fundLinkUrl} onClick={fundLinkClickHandler}>
            <button>Research</button>
          </a>
        </div>
        {RESULT_FEATURES.HAS_BUY_BUTTON ? buyButton : null}
      </div>
    </div>
  );
};

ResultsCardItemFooter.defaultProps = {
  showResearchButton: false,
};

ResultsCardItemFooter.propTypes = {
  /** Function that handles collapsing and expanding the details view of the result card */
  handleToggleExpansion: PropTypes.func.isRequired,

  isExpanded: PropTypes.bool.isRequired,

  showResearchButton: PropTypes.bool.isRequired,

  additionalData: PropTypes.object.isRequired,
  /**  function that dispatches action to track when user presses the buy button */
  trackBuyButton: PropTypes.func.isRequired,
  /**  function that dispatches action to track when user presses the research button */
  trackResearchButton: PropTypes.func.isRequired,
  /** Current Display type of application: compare,table,card,etc. */
  displayType: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    displayType: getDisplayType(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    trackBuyButton: (displayType, additionalFundData) => {
      const { id, cusip } = additionalFundData;
      const trackingData = odaTracking.getBuyButtonTrackingData(displayType, id);
      dispatch(Creators.odaLogAction(trackingData));
      const adobeTrackingData = adobeAnalytics.getBuyButtonTrackingData(displayType, 'card', id, cusip);
      dispatch(Creators.trackAdobeAnalytics(adobeTrackingData));
    },
    trackResearchButton: (displayType, id) => {
      const trackingData = odaTracking.getResearchTrackingData(displayType, id);
      dispatch(Creators.odaLogAction(trackingData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsCardItemFooter);
