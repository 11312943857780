import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Modal from 'components/Shared/Modal';
import QuickCriteriaCheckboxFilter from 'components/StartPage/QuickCriteriaCheckboxFilter';
import { icons } from 'utilities';
import Creators from 'actions/CriteriaActions';
import { getViewTabDisplay } from 'reducers/selectors/ViewTabSelectors';
import { RESULT_FEATURES } from '../../configs/AppFeatureConfig';

class DownloadResults extends Component {
  constructor() {
    super();
    this.state = {
      isModalOpen: false,
      selectedFilters: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.viewTab !== this.props.viewTab) {
      this.setState({ selectedFilters: [...this.state.selectedFilters, nextProps.viewTab] });
    }
  }

  handleCloseModal = () => {
    this.setState({
      isModalOpen: false,
      selectedFilters: [],
    });
  };

  handleOpenModal = () => {
    this.setState({
      isModalOpen: true,
      selectedFilters: [this.props.viewTab],
    });
  };

  handleLinkClicked = event => {
    this.handleOpenModal();
    if (!_.isNil(this.props.onLinkClick)) {
      this.props.onLinkClick(event);
    }
  };

  handleClick = event => {
    if (this.state.selectedFilters.includes(event)) {
      this.setState({
        selectedFilters: this.state.selectedFilters.filter(filter => {
          return filter !== event;
        }),
      });
    } else {
      this.setState({ selectedFilters: [...this.state.selectedFilters, event] });
    }
  };

  handleDownloadClick = event => {
    event.preventDefault();
    this.handleCloseModal();
    this.props.getDownloadResults(this.state.selectedFilters);
  };

  _shouldCheckFilter = filter => {
    return this.state.selectedFilters.includes(filter);
  };

  _renderCheckboxes = () => {
    return _.map(this.props.viewTabDisplay, (tab, id) => {
      return <QuickCriteriaCheckboxFilter
          key={id}
          classNames="item"
          identifier={id}
          isChecked={this._shouldCheckFilter(id)}
          label={tab.label}
          handleClick={this.handleClick}
          criteriaName={id}
        />
    });
  }

  render() {
    return (
      <React.Fragment>
        <button className="download-results-button" onClick={this.handleLinkClicked}>
          <img src={icons.excelIcon} alt="Download Results" />
          &nbsp;Download Results
        </button>
        <Modal
          isOpen={this.state.isModalOpen}
          handleCloseModal={this.handleCloseModal}
          classNames="download-results-modal"
        >
          <h2>Download Results</h2>
          <p>You are about to download the current resulting {RESULT_FEATURES.RESULT_TYPE}s from the views selected below.</p>
          <ul>
            {this._renderCheckboxes()}
          </ul>
          <p>
            Up to 100 results will download, corresponding to the default sort order or any other sorting you have
            applied.
          </p>
          <footer>
            <button className="link-button" onClick={this.handleCloseModal}>
              Cancel
            </button>
            <button
              className={this.state.selectedFilters.length > 0 ? 'continue-btn' : 'disabled-btn'}
              onClick={this.handleDownloadClick}
              disabled={this.state.selectedFilters.length < 1}
            >
              Continue
            </button>
          </footer>
        </Modal>
      </React.Fragment>
    );
  }
}

DownloadResults.propTypes = {
  /** Event handler for when the More on risk link is clicked */
  onLinkClick: PropTypes.func,
};

const mapDispatchToProps = dispatch => {
  return {
    getDownloadResults: filters => {
      dispatch(Creators.downloadResultsApi(filters));
    },
  };
};

const mapStateToProps = state => {
  return {
    viewTabDisplay: getViewTabDisplay(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadResults);
