import { createReducer } from 'reduxsauce';
import Types from 'actions/Types';
import _ from 'lodash';

export const INITIAL_STATE = { done: false };

// update sociallyResponsibleAttributes filter groups.
const newSignKeyArray = {
	'GEES':true, 'CLAC': true, 'HEEC':true, 'RESE':true, 'HUDE':true, 'BANE':true, 'FOFU':true
};

export const getAttrs = (atts) => {
	let susAtts = [];
	if (atts[0] && atts[0].subGroups && atts[0].subGroups.length>0){
		atts[0].subGroups.forEach(e=>{
			susAtts.push({...e, label: e.description, value:e.code, icon:null, key:e.code, newSign: newSignKeyArray[e.code]? true: false});
		}); 
	}
	let susAtts2 = [];
	if (atts[1] && atts[1].subGroups && atts[1].subGroups.length>0){
		atts[1].subGroups.forEach(e=>{
			susAtts2.push({...e, label: e.description, value:e.code, icon:null, key:e.code, newSign: newSignKeyArray[e.code]? true: false});
		}); 
	}
	susAtts = _.sortBy(susAtts, ['label']);
	susAtts2 = _.sortBy(susAtts2, ['label']);
	return [susAtts, susAtts2];
}

export const updateMatches = (state = INITIAL_STATE, action = {}) => {
  if (_.get(action, 'payload.ok', null)) {
	const sinv = action.payload.data.find( e=> e.code === 'SINV');
	const emax = action.payload.data.find( e=> e.code === 'EMEX');
	const items = getAttrs([sinv, emax]);
    return { ...state, done: true, items: items, socAttrs: {sustainableInvestment: items[0], employsExclusions: items[1]} };
  }
  return INITIAL_STATE;
};

export const HANDLERS = {
  [Types.ESGGROUPS_CLASSIFICATION_API_SUCCESS]: updateMatches,
};

export default createReducer(INITIAL_STATE, HANDLERS);
