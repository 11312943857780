import _ from 'lodash';
import Types from 'actions/Types';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import Creators from 'actions/CriteriaActions';
import { INITIAL_STATE as resultToggleGroupInitial } from 'reducers/ResultToggleGroupReducer';
import { getSortSettings } from 'reducers/SortResultsReducer';
import { getSelectedFundIds } from 'reducers/FundsSelectedReducer';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { FILTER_GROUP_IDS, ALL_CRITERIA } from 'configs/CriteriaConfig';
import {
  FUND_ID_FIELD,
  FUND_IDS_FIELD,
  ROUTE_CONSTANTS,
  RESULT_FEATURES,
  CLEAR_ALL_REDIRECTS_TO_START,
} from 'configs/AppFeatureConfig';
import { getRouterPersistentQueries, getCurrentStartRoute } from 'reducers/selectors/RouterSelectors';
import { getFilterGroupsFromQueryObj } from 'utilities/criteriaUtils';

const getQueryFromFilterGroupSelections = filterGroupSelections => {
  return _.mapValues(filterGroupSelections, vals => (vals ? vals.join(',') : ''));
};
//creates url parts
const dispatchResultsPath = (
  store,
  state,
  {
    query = null,
    displayType = null,
    viewTab = null,
    sortBy = null,
    sortOrder = null,
    currentPageNumber = null,
    criteriaOrder = null,
    persistQuery = true,
  } = {},
  //takes in the store, state, query params
  action
 
) => {
  viewTab = !_.isNil(viewTab) ? viewTab : state.viewTab;
   //if view tab is not passed in as a query param - will use state.viewTab - same thing for all ofthem
  displayType = !_.isNil(displayType) ? displayType : state.displayType;
  sortBy = !_.isNil(sortBy) ? sortBy : state.sortResults.sortCol;
  sortOrder = !_.isNil(sortOrder) ? sortOrder : state.sortResults.sortDir;
  currentPageNumber = !_.isNil(currentPageNumber) ? currentPageNumber : state.resultsPageNum;
  const filterGroupsFromQuery = getFilterGroupsFromQueryObj(query, FILTER_GROUP_IDS, [FUND_IDS_FIELD]);
  const order = !_.isNil(criteriaOrder)
    ? criteriaOrder
    : _.uniq([..._.keys(filterGroupsFromQuery)]).join(',');
    //_.uniq([...state.activeCriteria, ..._.keys(filterGroupsFromQuery)]).join(',');
    //can sort it up or down

  const pathname = `/results/${displayType}/${viewTab}/${sortBy}/${sortOrder}/${currentPageNumber}`;
  //this rebuilds the results url based on people's selections, each a colon piece of the route id
  query = { ...getRouterPersistentQueries(state), ...query };
  //grabs the router persistent queries and adds tthem into the query object
  if (order.length > 0) {
    // add order parameter if it is not empty
    query.order = typeof order==="string"?order:"";
  }
  adjustQueryWithAction(query, action, state.activeCriteria);
  const href = { pathname, search: queryString.stringify(query) };
  //building a new action to dispatch to
  persistQuery = true
  const options = { persistQuery, source: 'routerMiddleware' };
  //builds the options
  const newAction = push(href, options);
  //the push from the routing system... in little router- takes an options object, not in connected router
  store.dispatch(newAction);
  //dispatches the action - triggers a state change
};


const setIndexOrder=(order1, val, index)=>{
  if (order1 && order1.length>0){
    order1.forEach(e=>{
      if (e.id===val){ e.index=index;}
    });
  }
}

// sort selected Criteria
const sortOrderWithActiveCriteria=(order,activeCriteria, query)=>{
  if (_.isNil(activeCriteria)){activeCriteria=[];}
  if (order && order.length>0){
    let order1=order.map(e=> {return {'id':e, 'index':100};});
    activeCriteria.forEach((e,i)=>{
      if (e==='fundType'){ 
        setIndexOrder(order1, 'category',i);      
        setIndexOrder(order1, 'assetClass',i);      
        setIndexOrder(order1, 'risk',i);
      } else {
        setIndexOrder(order1, e,i); 
      }
    });
    order1.sort((a,b)=>{ return a.index<b.index?-1:(a.index===b.index?0:1);});
    query.order = order1.map(e=>{ return e.id; }).join(",");
  } else {
    query.order="";
  }
}

// url parameter table for each criteria selection
const removeUrlParamsTable={
  "fundFamily": "fundFamily","fidelityFundOnly":"fidelityFundOnly","fundPicks":"fundPicks","sociallyResponsible":"sociallyResponsible",
  "investmentTypeCode":"investmentTypeCode","indexFundOnly":"indexFundOnly","mStarRating":"mStarRating","returns":"returns",
  "rank1Year":"rank1Year","rank3Year":"rank3Year","rank5Year":"rank5Year","rank10Year":"rank10Year",
  "returnsYTD":"returnsYTD","returns1Year":"returns1Year","returns3Year":"returns3Year","returns5Year":"returns5Year","returns10Year":"returns10Year",
  "expenses":"expenses","expenseRatio":"expenseRatio","managerTenure":"managerTenure","inceptionDate":"inceptionDate","totalAsset":"totalAsset",
  "turnoverRatio":"turnoverRatio","minimumInvestment":"minimumInvestment","openToNewInvestors":"openToNewInvestors",
  "includeLeveragedFunds/includeInverseFunds":"includeLeveragedFunds/includeInverseFunds","sharpRatio3Year":"sharpRatio3Year","beta":"beta",
  "r2":"r2","standardDeviation":"standardDeviation",
  "currentYield":"currentYield","averageMaturity":"averageMaturity","averageDuration":"averageDuration","secYield30Day":"secYield30Day",
  "ntf":"ntf","transactionFeeIndicator":"transactionFeeIndicator", "tickers":"tickers", "employsExclusions":"employsExclusions",
  "sustainableInvestment":"sustainableInvestment"
};

// url parameters : add or remove
const adjustQueryWithAction=(query, action, activeCriteria)=>{
  let order = query.order?query.order.split(","):[];
  if (action.type===Types.ADD_CRITERION && action.critId==="fundType"){
    query["assetClass"]="";
    query["category"]="";
    delete query["risk"];
    order = order.filter(e=> e!=="risk"  && e!=="fundType");
  } else if (action.type === Types.UPDATE_CRITERIA){
      if (action.critIdArray && action.critIdArray.length > 0 && action.critIdArray.filter(e => e === 'fidelityFundOnly').length === 0) {
        delete query['fidelityFundOnly'];
      }
  } else if (action.type===Types.UPDATE_FILTERS && (action.filterGroupId==="risk" || action.filterGroupId==="category")){
    if(action.filterGroupId==="risk" ){
      delete query["assetClass"];
      delete query["category"];
      order = order.filter(e=> e!=="assetClass" && e!=="category" && e!=="fundType");
    } else {
      delete query["risk"];
      order = order.filter(e=> e!=="risk"  && e!=="fundType");
    }
  } else if (action.type===Types.REMOVE_CRITERION){
    if ( action.critId==="fundType"){
      delete query["assetClass"];
      delete query["category"];
      delete query["risk"];
      order = order.filter(e=>e!=="risk" && e!=="assetClass" && e!=="category" && e!=="fundType");
    } else {
      if (removeUrlParamsTable[action.critId]){
        delete query[removeUrlParamsTable[action.critId]];
        order = order.filter(e=>e!==removeUrlParamsTable[action.critId]);
      }
    }
  } else if (action.type===Types.SHOW_FULL_RESULTS_VIEW){
    let id = 'tickers';
    if (removeUrlParamsTable[id]){
      delete query[removeUrlParamsTable[id]];
      order = order.filter(e=>e!==removeUrlParamsTable[id]);
    }
  } 
  else if (action.type===Types.SHOW_COMPARE_VIEW){
    order = _.cloneDeep(activeCriteria).filter(e=>e!=="tickers");
    order.push('tickers');
    query.order = order.join(",");
  } 
  if(action.type===Types.ADD_CRITERION || action.type===Types.UPDATE_CRITERIA || action.type===Types.UPDATE_FILTERS || 
    action.type===Types.REMOVE_CRITERION || action.type===Types.SHOW_FULL_RESULTS_VIEW){
    sortOrderWithActiveCriteria(order, activeCriteria,query);
  }
};

const markStrategyAsModifiedIfNeeded = (state, query) => {
  const { strategyId, isModified } = state.fundStrategies;
  if (!_.isNil(strategyId)) {
    query.strategy = isModified ? `${strategyId}_modified` : strategyId;
  }
};

const routerMethods = {};
//building a router methods object

routerMethods[Types.ADD_CRITERION] = (store, state, action) => {
  const query = getQueryFromFilterGroupSelections({...state.criteriaFilters, ...action.filterGroupSelections});
  markStrategyAsModifiedIfNeeded(state, query);
  dispatchResultsPath(store, state, { query, currentPageNumber: 1 },action);
};
//using 

routerMethods[Types.CLEAR_ALL] = (store, state, action) => {
  const href = { search: queryString.stringify(getRouterPersistentQueries(state)) };
  if (CLEAR_ALL_REDIRECTS_TO_START || action.goToStart) {
    href.pathname = getCurrentStartRoute(state);
    href.search = null;
  } else {
    // save "product=" parameter for Annuities.
    let params = href.search?href.search.split("&"):[];
    params = params.filter(e=> e.indexOf("product=")>=0);
    href.search=params.join("&");
  }
  const options = {
    persistQuery: false,
    source: 'routerMiddleware',
    shouldFetchData: true,
  };
  const newAction = push(href, options);
  store.dispatch(newAction);
};

routerMethods[Types.UPDATE_FILTERS] = routerMethods[Types.UPDATE_RANGE_INPUTS] = (store, state, action) => {
  const query = (() => {
    const added = { ...state.query, ...getQueryFromFilterGroupSelections(action.filterGroupSelections) };
    return _.omitBy(added, (filters, filterGroupId) => _.isNull(_.get(action, ['filterUpdates', filterGroupId])));
  })();
  markStrategyAsModifiedIfNeeded(state, query);
  dispatchResultsPath(store, state, {
    query,
    currentPageNumber: 1,
    persistQuery: false,
  },action);
};

routerMethods[Types.UPDATE_CRITERIA] = (store, state, action) => {
  const query = getQueryFromFilterGroupSelections(action.filterGroupSelections);
  const criteriaOrder = _.keys(query).join(',');

  markStrategyAsModifiedIfNeeded(state, query);
  dispatchResultsPath(store, state, {
    query,
    persistQuery: false,
    criteriaOrder,
    currentPageNumber: 1,
  },action);
};

routerMethods[Types.LOAD_STRATEGY] = (store, state, action) => {
  const query = getQueryFromFilterGroupSelections(action.filterGroupSelections);
  query.strategy = action.strategyId;
  dispatchResultsPath(store, state, {
    query,
    persistQuery: false,
    currentPageNumber: 1,
    viewTab: action.viewTabShortName,
    sortBy: _.get(action, ['sortParams', 'column']),
    sortOrder: _.get(action, ['sortParams', 'direction']),
  },action);
};

routerMethods[Types.REMOVE_CRITERION] = (store, state, action) => {
  if (state.activeCriteria.length <= RESULT_FEATURES.MIN_CRITERIA_COUNT) {
      store.dispatch({ type: Types.CLEAR_ALL, goToStart: true });
  } else {
    const filterGroups = ALL_CRITERIA[action.critId].filterGroups;
    const query = _.pickBy(state.query, (value, key) => !_.has(filterGroups, key));
    const currentOrder = state.query.order.split(',');
    const newCritOrder = _.without(currentOrder, ..._.keys(filterGroups)).join(',');
    markStrategyAsModifiedIfNeeded(state, query);
    dispatchResultsPath(store, state, {
      query,
      criteriaOrder: newCritOrder,
      persistQuery: false,
      currentPageNumber: 1,
    },action);
  }
};

routerMethods[Types.UPDATE_VIEW_TAB] = (store, state, action) => {
  dispatchResultsPath(store, state, {
    viewTab: action.viewTabShortName,
  },action);
};

routerMethods[Types.SORT_RESULTS] = (store, state, action) => {
  dispatchResultsPath(store, state, {
    sortBy: action.column,
    sortOrder: action.direction,
    currentPageNumber: 1,
  },action);
};

routerMethods[Types.UPDATE_RESULTS_PAGE_NUM] = (store, state, action) => {
  dispatchResultsPath(store, state, {
    currentPageNumber: action.pageNum,
  },action);
};

routerMethods[Types.UPDATE_DISPLAY_TYPE] = (store, state, action) => {
  dispatchResultsPath(store, state, {
    displayType: action.displayType,
    viewTab: action.viewTabShortName,
  },action);
};

routerMethods[Types.UPDATE_MOBILE_STATUS] = (store, state, action) => {
  if (state.route === ROUTE_CONSTANTS.RESULTS) {
    dispatchResultsPath(store, state, {
      viewTab: action.viewTabShortName,
      displayType: action.displayType,
    },action);
  }
};

routerMethods[Types.SHOW_COMPARE_VIEW] = (store, state, action) => {
  const selectedFundIds = getSelectedFundIds(state);
  dispatchResultsPath(store, state, {
    displayType: 'compare',
    viewTab: action.viewTabShortName,
    query: { ...state.query, [FUND_IDS_FIELD]: selectedFundIds.join(',') },
    criteriaOrder: [FUND_IDS_FIELD],
    persistQuery: false,
  },action);
};

routerMethods[Types.ADD_SYMBOL_SUCCESS] = (store, state, action) => {
  const selectedFundIds = getSelectedFundIds(state);
  dispatchResultsPath(store, state, {
    query: { [FUND_IDS_FIELD]: selectedFundIds.join(',') },
    criteriaOrder: [FUND_IDS_FIELD],
    persistQuery: false,
  },action);
};

routerMethods[Types.UPDATE_FUNDS_SELECTED] = (store, state, action) => {
  if (getDisplayType(state) === 'compare') {
    const selectedFundIds = _.map(action.fundsSelected, FUND_ID_FIELD);
    dispatchResultsPath(store, state, {
      query: { [FUND_IDS_FIELD]: selectedFundIds.join(',') },
      criteriaOrder: [FUND_IDS_FIELD],
      persistQuery: false,
    },action);
  }
};

routerMethods[Types.SHOW_FULL_RESULTS_VIEW] = (store, state, action) => {
  if (
    state.activeCriteria.length >= RESULT_FEATURES.MIN_CRITERIA_COUNT 
    //_.size(state.criteriaFilters) >= RESULT_FEATURES.MIN_CRITERIA_COUNT
  ) {
    const query = _.mapValues(state.criteriaFilters, filterVal => {
      return _.isArray(filterVal) ? filterVal.join(',') : filterVal;
    });

    markStrategyAsModifiedIfNeeded(state, query);
    dispatchResultsPath(store, state, {
      displayType: 'table',
      viewTab: action.viewTabShortName,
      query,
      persistQuery: false,
    },action);
  } else {
    const href = {
      pathname: getCurrentStartRoute(state),
      search: queryString.stringify(getRouterPersistentQueries(state)),
    };
    const options = {
      persistQuery: false,
      source: 'routerMiddleware',
    };
    const newAction = push(href, options);
    store.dispatch(newAction);
  }
};

routerMethods[Types.CHANGE_START_PAGE_VIEW_TAB] = (store, state, action) => {
  let pathname = '/';

  if (action.viewTab === 'fundStrategies') pathname = '/strategies';
  if (action.viewTab === 'fundPicks') pathname = '/picks';
  if (action.viewTab === 'annuityOptions') pathname = '/';
  if (action.viewTab === 'waysToInvest') pathname = '/ways-to-invest';

  const href = {
    pathname,
    search: queryString.stringify(getRouterPersistentQueries(state)),
  };
  const options = {
    persistQuery: false,
    source: 'routerMiddleware',
  };
  const newAction = push(href, options);
  store.dispatch(newAction);
};

routerMethods[Types.VIEW_NESTED_STRATEGY] = (store, state, action) => {
  const { strategyId } = action;
  const pathname = `/strategies/${strategyId}`;

  const href = {
    pathname,
    search: queryString.stringify(getRouterPersistentQueries(state)),
  };
  const options = {
    source: 'routerMiddleware',
    persistQuery: false,
  };
  const newAction = push(href, options);
  store.dispatch(newAction);
};

routerMethods[Types.UPDATE_RESULT_TOGGLE_GROUP] = (store, state, action) => {
  const dispatchResultsObj = (() => {
    if (action.selection.id === resultToggleGroupInitial[action.resultGroup].id) {
      const queryRemoveToggleGroup = _.pickBy(state.query, (qsVal, qsKey) => {
        return qsKey !== action.resultGroup;
      });
      return {
        query: queryRemoveToggleGroup,
        persistQuery: false,
      };
    } else {
      return {
        query: { [action.resultGroup]: action.selection.id },
      };
    }
  })();

  if (!_.isNil(action.newSortField)) {
    dispatchResultsObj['sortBy'] = action.newSortField;
    const sortDir = getSortSettings(state).sortDir;
    const newAction = Creators.sortResults(action.newSortField, sortDir);
    store.dispatch(newAction);
  }
  dispatchResultsPath(store, state, dispatchResultsObj,action);
};

routerMethods[Types.UPDATE_PRICING_MODEL] = (store, state, action) => {
  const query = state.query;
  query.product = action.productCode;

  const params = {
    query,
    persistQuery: false,
    currentPageNumber: 1,
  };
  dispatchResultsPath(store, state, params,action);
};

const routerMiddlewarex = store => next => action => {
  const state = store.getState();
  const routerMethod = _.get(routerMethods, action.type);
  //takes in the action.type  - compares it against the router methods and returns it,
  //when someone makes a seleciton in the form - causes one of these to hit.
  if (_.isFunction(routerMethod)) {
    routerMethod(store, state, action);
  }
  next(action);
};

export default routerMiddlewarex;
