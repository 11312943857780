import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { icons } from 'utilities';

const CriteriaMenuHeader = ({
  handleToggleCriteriaMenuDisplay,
  isCriteriaMenuOpen,
  activeCriteria,
  onClearAll,
  isMobile,
}) => {
  const hideButtonRef = useRef(null);
  const filterButtonRef = useRef(null);
  const handleClearAll = e => {
    e.preventDefault();
    onClearAll();
  };

  const clearAllDisplay = activeCriteria.length === 0 ? 'off' : 'clear-all';
  const clearAllButton = (
    <div className={clearAllDisplay}>
      <button className="as-link" type="button" onClick={handleClearAll} aria-label="criteria filter clear all">
        Clear All
      </button>
    </div>
  );

  let criteriaMenuHeaderDisplay, showHideButton, criteriaMenuHeaderExpanded;
  if (isCriteriaMenuOpen) {
    criteriaMenuHeaderDisplay = 'expanded';
    criteriaMenuHeaderExpanded = 'true';
    showHideButton = (
      <div className="toggle-button hide-button">
        <img src={icons.arrowLeft} alt="Arrow Left Icon" />
        <button className="as-link" ref={hideButtonRef} aria-label="criteria filter expanded, hide">
          Hide
        </button>
      </div>
    );
  } else {
    criteriaMenuHeaderDisplay = 'collapsed';
    criteriaMenuHeaderExpanded = 'false';
    showHideButton = (
      <div className="toggle-button show-button">
        <img src={icons.arrowRight} alt="Arrow Right Icon" />
        <button className="as-link" aria-label="criteria filter collapsed, show">
          Show
        </button>
      </div>
    );
  }

  const moveFocusWhenClickedFilterIcons = e => {
    setTimeout(() => {
      if (criteriaMenuHeaderExpanded === 'false' && hideButtonRef && hideButtonRef.current) {
        hideButtonRef.current.focus();
      }
      if (!isMobile && criteriaMenuHeaderExpanded === 'true' && filterButtonRef && filterButtonRef.current) {
        filterButtonRef.current.focus();
      }
    }, 500);
  };
  const handleToggleCriteriaMenuDisplayLocal = e => {
    moveFocusWhenClickedFilterIcons();
    handleToggleCriteriaMenuDisplay(e);
  };

  return (
    <div
      className={`criteria-menu-header ${criteriaMenuHeaderDisplay}`}
      onClick={e => handleToggleCriteriaMenuDisplayLocal(e)}
    >
      <div className="criteria-funnel-icon">
        <button tabIndex={0}
          aria-label="criteria filter"
          aria-expanded={`${criteriaMenuHeaderExpanded}`}
          ref={filterButtonRef}
        >
          <img src={icons.funnelIcon} alt="Funnel Icon" aria-hidden="true" />
        </button>
      </div>
      <div className="criteria-wrap">
        <div className="criteria-menu-title" aria-hidden="true">
          Criteria
        </div>
        {clearAllButton}
      </div>
      {showHideButton}
    </div>
  );
};

CriteriaMenuHeader.propTypes = {
  /** An array representing active fully formed criteria objects */
  activeCriteria: PropTypes.array.isRequired,

  /** Function that expands and collapses the criteria menu */
  handleToggleCriteriaMenuDisplay: PropTypes.func.isRequired,

  isCriteriaMenuOpen: PropTypes.bool,

  /** Function that clears all active criteria */
  onClearAll: PropTypes.func.isRequired,
};

export default CriteriaMenuHeader;
