import React, { Component } from 'react';
import QuickCriteriaCheckboxFilter from 'components/StartPage/QuickCriteriaCheckboxFilter';
import QuickCriteriaClassPopupDropdown from 'components/StartPage/QuickCriteriaClassPopupDropdown';
import PropTypes from 'prop-types';
import GlossaryDefinitionPopover from '../Shared/GlossaryDefinitionPopover';
import mobileUtils from '../../utilities/mobileUtils';

class QuickCriteriaClassPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedState: false,
      isAllAssetClassesCheckboxChecked: true,
      width: 600,
      left: 0,
      //container:scroll window, targetDiv: the div that includes the reference element of the popupover, targetDivHeight: height of the ref element.
      scrolled: { num: 0, container: 'asset-class-container', targetDiv: 'asset-class-wrap', targetDivHeight: 40 },
      collapseExpandStyle: {top: '70px'}
    };
    this.firstColumn = [];
    this.secondColumn = [];
    this.isChildExpanded = {};
    this.scrolledTimer = null;
  }

  updateDimensions() {
    const container = document.getElementsByClassName('quick-criteria-menu')[0];
    if (container) {
      const width = mobileUtils.isScreenMobile() ? 355 : 360;  // mobile : 355px,  desktop:360px
      let left1 = window.screen.width - (width + 50);
      left1 = left1 < 0 ? left1 : 0;
      left1 = left1 < -40 ? -40 : left1;
      if (this.state.width !== width || this.state.left !== left1) {
        this.setState({ width: width, left: left1 });
      }
    }
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedAssetClasses !== this.props.selectedAssetClasses) {
      this.setState({
        isAllAssetClassesCheckboxChecked: nextProps.selectedAssetClasses.length === 0,
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.isOpen && prevProps.isOpen) {
      this.collapseAll(false);
    }
  }

  handleSelectAllAssetClasses = assetClass => {
    this.props.handleChangeAllActiveAssetClasses({ label: assetClass });
  };

  //handle user typing in search criteria in "All Classes, All Categories" dropdown
  handleKeyUp = input => {
    const inputString = input.target.value;
    const inputStringLowerCase = inputString.toLowerCase();

    const relatedWords =
      'equity,stock,stk,fixed,commodities,commodity,income,bond,hybrid,allocation,freedom,target,date';
    const relatedAndKeyword = 'commodity';
    const checkboxFilters = document.getElementsByClassName('checkbox-label2');
    const dropdowns = Array.from(document.getElementsByClassName('asset-class-wrap'));
    const searchLegend = document.getElementsByClassName('search-legend')[0];
    searchLegend.style.display = 'none';

    const shownDropdowns = [];
    let self = this;

    Array.from(checkboxFilters).forEach(function (filter, filterindex) {
      let checkboxFilterText = filter.innerHTML;
      if (inputString.length >= 3) {
        const delimeters = [' ', '--', '-', '/'];
        const regEx = new RegExp(delimeters.join('|'));
        const stringSplit = checkboxFilterText.split(regEx);
        let checkboxFilterTextLowerCase = checkboxFilterText.toLowerCase();
        const stringSplitLowerCase = checkboxFilterTextLowerCase.split(regEx);
        const keywordIndex = stringSplitLowerCase.findIndex(x =>
          x.substring(0, x.length).includes(inputStringLowerCase),
        );
        const relatedSplit = relatedWords.split(',');
        let relatedIndex = relatedSplit.findIndex(x => x.includes(inputStringLowerCase));
        let relatedStr = self.getRelatedResult(relatedSplit[relatedIndex]);
        let relatedStrSplit = relatedStr !== '' ? relatedStr.split(',') : [];
        let relatedCount = 0;

        //show search-legend text
        searchLegend.style.display = '';

        //count how many related strings are in the current checkboxLabel
        stringSplitLowerCase.forEach(str => {
          if (relatedStrSplit.includes(str)) {
            relatedCount++;
          }
        });

        //keyword
        if (checkboxFilterTextLowerCase.includes(inputStringLowerCase)) {
          if (keywordIndex > -1 && !checkboxFilterText.includes('keyword')) {
            if (!checkboxFilterText.includes('related')) {
              checkboxFilterText = checkboxFilterText.replace(
                stringSplit[keywordIndex],
                '<span class="keyword" style="color:#6F9824;font-weight: bold;">' +
                  stringSplit[keywordIndex] +
                  '</span>',
              );
              filter.innerHTML = checkboxFilterText;
            } else {
              checkboxFilterText = checkboxFilterText.replace('font-weight: bold', 'color:#6F9824;font-weight: bold');
              checkboxFilterText = checkboxFilterText.replace('class="related"', 'class="keyword"');
              filter.innerHTML = checkboxFilterText;
            }
          }
        }

        //related
        relatedStrSplit.forEach(str => {
          if (checkboxFilterTextLowerCase.includes(str)) {
            const index = stringSplitLowerCase.findIndex(x => x.includes(str));
            const count = checkboxFilterText.match(/related/g);
            if (
              ((checkboxFilterText.includes('related') && checkboxFilterText.includes('keyword')) ||
                (checkboxFilterText.includes('related') && !checkboxFilterText.includes('keyword')) ||
                !(checkboxFilterText.includes('related') && checkboxFilterText.includes('keyword'))) &&
              !inputStringLowerCase.includes(relatedAndKeyword)
            ) {
              if (count === null || (count && count.length < relatedCount)) {
                checkboxFilterText = checkboxFilterText.replace(
                  stringSplit[index],
                  '<span class="related" style="font-weight: bold;">' + stringSplit[index] + '</span>',
                );
                filter.innerHTML = checkboxFilterText;
              }
            } else {
              checkboxFilterText = checkboxFilterText.replace('color:#6F9824;', '');
              checkboxFilterText = checkboxFilterText.replace('class="keyword"', 'class="related"');
              filter.innerHTML = checkboxFilterText;
            }
          }
        });

        let hasRelatedWords = false;
        let i = 0;
        while (!hasRelatedWords && i < relatedStrSplit.length) {
          const related = relatedStrSplit[i];
          if (checkboxFilterTextLowerCase.includes(related)) {
            hasRelatedWords = !hasRelatedWords;
            break;
          }
          ++i;
        }

        try {
          let parent = filter.parentNode;
          for(let t = 0; t<10; t++){
            if (parent.className === 'asset-class-wrap' || parent.className === 'all-asset-class-container'){
              break;
            }
            parent = parent.parentNode;
          }
          if (parent.className === 'asset-class-wrap' && parent.innerHTML.indexOf('All Asset Classes') < 0) {
            parent.style.display = 'none';
            if (hasRelatedWords || checkboxFilterTextLowerCase.includes(inputStringLowerCase)) {
              if (!shownDropdowns.includes(parent) && parent.className === 'asset-class-wrap') {
                shownDropdowns.unshift(parent);
              } 
            }
          }
        }
        catch (ex){
          // ignore.
        }
      }
      //remove styling when keyword/related term is no longer valid
      else {
        //hide search-legend text
        searchLegend.style.display = 'none';
        //contains keyword only
        if (checkboxFilterText.includes('keyword') && !checkboxFilterText.includes('related')) {
          checkboxFilterText = checkboxFilterText.replace(
            '<span class="keyword" style="color:#6F9824;font-weight: bold;">',
            '',
          );
          checkboxFilterText = checkboxFilterText.replace('</span>', '');
          filter.innerHTML = checkboxFilterText;
        } //contains keyword and related term
        else if (checkboxFilterText.includes('related') && checkboxFilterText.includes('keyword')) {
          checkboxFilterText = checkboxFilterText.replace('<span class="related" style="font-weight: bold;">', '');
          checkboxFilterText = checkboxFilterText.replace(
            '<span class="keyword" style="color:#6F9824;font-weight: bold;">',
            '',
          );
          checkboxFilterText = checkboxFilterText.replace('</span>', '');
          filter.innerHTML = checkboxFilterText;
        } else {
          Array.from(dropdowns).forEach(dropdown => {
            if (dropdown.className === 'all-asset-class-container') {
              dropdown.firstChild.style.display = '';
            }
            dropdown.style.display = '';
          });
        }
        //1 or more related terms
        while (checkboxFilterText.includes('related') && !checkboxFilterText.includes('keyword')) {
          checkboxFilterText = checkboxFilterText.replace('<span class="related" style="font-weight: bold;">', '');
          checkboxFilterText = checkboxFilterText.replace('</span>', '');
          filter.innerHTML = checkboxFilterText;
        }
      }
    });
    shownDropdowns.forEach(dropdown => {
      dropdown.style.display = '';
    });

    if(searchLegend.style.display === 'none'){
      this.setState({collapseExpandStyle: {top: '70px'}});
    } else {
      this.setState({collapseExpandStyle: {top: '105px'}});
    }
  };

  handleSubmit = e => {
    e.preventDefault();
  };

  getRelatedResult = input => {
    switch (input) {
      case 'equity':
        return 'stock,stk';
      case 'stock':
        return 'equity,stk';
      case 'stk':
        return 'equity,stock';
      case 'fixed':
        return 'income,bond';
      case 'income':
        return 'fixed,bond';
      case 'bond':
        return 'fixed,income';
      case 'hybrid':
        return 'allocation';
      case 'allocation':
        return 'hybrid';
      case 'freedom':
        return 'target,date';
      case 'target':
        return 'freedom,date';
      case 'date':
        return 'target,freedom';
      case 'commodities':
        return 'commodity';
      case 'commodity':
        return 'commodities';
      default:
        return '';
    }
  };

  getRelatedCategoryRiskDisclosure = assetClass => {
    const { categoryRiskDisclosures } = this.props;

    const assetClassCodeMap = {
      'All Asset Classes': '',
      Allocation: 'BAL',
      Alternative: 'ALT',
      Commodities: 'COM',
      'International Equity': 'IS',
      'Money Market': 'MM',
      'Municipal Bond': 'MB',
      'Sector Equity': 'SS',
      'Taxable Bond': 'BND',
      'U.S. Equity': 'DS',
    };

    const categoryCode = assetClassCodeMap[assetClass];
    return categoryRiskDisclosures[categoryCode] || '';
  };

  handleDropDown = asstClassExpand => {
    this.isChildExpanded[asstClassExpand.assetClass.label] = asstClassExpand.expanded;

    let anyExpanded = false;
    for (const property in this.isChildExpanded) {
      if (this.isChildExpanded[property]) {
        anyExpanded = true;
        break;
      }
    }
    this.setState({ expandedState: anyExpanded });
  };

  collapseAll = newState => {
    for (const property in this.isChildExpanded) {
      this.isChildExpanded[property] = newState;
    }
    this.setState({ expandedState: newState });
  };

  renderModal = () => {
    this.firstColumn = [];
    const {
      options,
      handleChangeAllActiveCategories,
      handleChangeAllActiveAssetClasses,
      selectedAssetClasses,
      selectedCategories,
    } = this.props;
    const allAssetClasses = (
      <div>
        <div className="asset-class-wrap" data-tc="All Asset Classes">
          <QuickCriteriaCheckboxFilter
            handleClick={this.handleSelectAllAssetClasses}
            isChecked={this.state.isAllAssetClassesCheckboxChecked}
            label={ 'All Asset Classes'}
            labelStyle={{'fontWeight':700}}
            glossaryDefinition={
              <GlossaryDefinitionPopover glossary="All Asset Classes" container="quick-criteria-fund-type" />
            }
          />
        </div>
        <div className='risk-distribution'>
          <div className='risk-distribution-container'>
            <span>RISK DISTRIBUTION</span>
            <GlossaryDefinitionPopover glossary="Category Risk Distribution Within Asset Class" container="quick-criteria-fund-type" />
          </div>
        </div>
      </div>
    );
    if (options) {
      options.forEach((option, i) => {
        if (typeof this.isChildExpanded[option.label] === 'undefined') {
          this.isChildExpanded[option.label] = false;
        }
        this.firstColumn.push(
          <QuickCriteriaClassPopupDropdown
            handleChangeAllActiveCategories={handleChangeAllActiveCategories}
            handleChangeAllActiveAssetClasses={handleChangeAllActiveAssetClasses}
            handleDropDown={this.handleDropDown}
            expandStatus={this.isChildExpanded[option.label]}
            selectedAssetClasses={selectedAssetClasses}
            selectedCategories={selectedCategories}
            assetClass={option}
            scrolled={this.state.scrolled}
          />,
        );
      });
      return (
        <div className="modal-wrap row">
          <div className="all-asset-class-container">{allAssetClasses}</div>
          <div className="asset-class-container">
            <div className="col-sm-12 col-md-12">{this.firstColumn}</div>
          </div>
        </div>
      );
    }
  };

  // onScroll event handler :
  hideIfpopupSourceIsOutsideView(e) {
    if (this.scrolledTimer) {
      clearTimeout(this.scrolledTimer);
      this.scrolledTimer = null;
    }
    this.scrolledTimer = setTimeout(() => {
      this.scrolledTimer = null;
      let scrolled = this.state.scrolled;
      scrolled.num = scrolled.num + 1;
      this.setState({ scrolled: scrolled });
    }, 500);
  }

  render() {
    this.updateDimensions();
    const displayStyle = this.props.isOpen ? '' : 'off';
    const collapseButtonCaption = () => {
      return this.state.expandedState ? 'Collapse All' : 'Expand All';
    };

    return (
      <div
        className={`quick-criteria-class-popup ${displayStyle}`}
        style={{ width: this.state.width, left: this.state.left }}
        onScroll={e => {
          this.hideIfpopupSourceIsOutsideView(e);
        }}
      >
        <form id="asset-class-search-form" onSubmit={this.handleSubmit}>
          <input type="text" placeholder="Search by Keyword" onKeyUp={this.handleKeyUp} />
          <div className="search-div">
            <p className="search-legend" style={{ display: 'none' }}>
              <i>Displaying</i>{' '}
              <b>
                <span className="fidelity-green">keyword</span>
              </b>{' '}
              <i>and</i> <b>related</b> <i>result</i>
            </p>
            <button
              id="collapse-expand"
              className="as-link collapse-all"
              onClick={e => {
                this.collapseAll(!this.state.expandedState);
              }}
              style={this.state.collapseExpandStyle}
            >
              {collapseButtonCaption()}
            </button>
          </div>
        </form>
        {this.renderModal()}
      </div>
    );
  }
}

QuickCriteriaClassPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default QuickCriteriaClassPopup;
