import { CIQ } from 'chartiq';
import React from 'react';

export default function (props, { config, pluginToggles }) {
  return (
    <React.Fragment>
      <cq-chart-instructions role="contentinfo"></cq-chart-instructions>
      <div className="ciq-header">
        <h1 className="header-text">Hypothetical Growth of $10,000</h1>
        <span className="as-of-date">AS OF {props.getAsOfDate()}</span>
        <cq-toggle class="tableview-ui" cq-member="tableView" role="switch" aria-pressed="false">
          <cq-text>Table View</cq-text>
          <div className="switch" />
        </cq-toggle>
      </div>
      <div className="ciq-chart-area" role="main">
        <div className="ciq-chart">
          <div className="chartContainer">
            <cq-chart-legend></cq-chart-legend>
            <cq-loader></cq-loader>
          </div>
        </div>
      </div>
      <div className="ciq-footer">
        <span className="range-menu">
          <label>Overall Performance:</label>
          <cq-show-range></cq-show-range>
        </span>
        <span className="frequency">
          <label>Frequency:</label>
          <span className="frequency-value">{props.getFrequency()}</span>
        </span>
      </div>
    </React.Fragment>
  );
}
