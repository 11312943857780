import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { icons } from 'utilities';
import CheckboxFilter from 'components/ActiveCriterion/CheckboxFilter';
import _ from 'lodash';

class ExpandableCheckboxFilter extends Component {
  handleSelectAllFilters = e => {
    e.preventDefault();

    if (this.props.isSelected) {
      this._uncheckAllFilters();
    } else {
      this._checkAllFilters();
    }
  };

  handleSelectIndividualFilter = (label, isChecked) => {
    const { parentFilterGroupId, filterGroupId, filtersSelected } = this.props;
    const { label: parentLabel } = this.props.filter;
    const filterUpdates = { [filterGroupId]: { [label]: isChecked } };
    if (!isChecked && filtersSelected.length === 1) {
      filterUpdates[parentFilterGroupId] = { [parentLabel]: false };
    } else if (isChecked && filtersSelected.length === 0) {
      filterUpdates[parentFilterGroupId] = { [parentLabel]: true };
    }
    this.props.onFiltersUpdated(filterUpdates, filterGroupId);
  };

  _uncheckAllFilters = () => {
    const filterUpdates = _.chain(this.props.filters)
      .filter(critFilter => {
        return this.props.filtersSelected.includes(critFilter.label);
      })
      .reduce((updatesObj, filter) => {
        updatesObj[filter.label] = false;
        return updatesObj;
      }, {})
      .value();
    const { filterGroupId, parentFilterGroupId, filter } = this.props;
    this.props.onFiltersUpdated(
      {
        [filterGroupId]: filterUpdates,
        [parentFilterGroupId]: { [filter.label]: false },
      },
      filterGroupId,
    );
  };

  _checkAllFilters = () => {
    const updatedState = {};
    this.props.filters.forEach(filter => {
      if (!this.props.filtersSelected.includes(filter.label)) {
        updatedState[filter.label] = true;
      }
    });

    const { filterGroupId, parentFilterGroupId, filter } = this.props;
    this.props.onFiltersUpdated(
      {
        [filterGroupId]: updatedState,
        [parentFilterGroupId]: { [filter.label]: true },
      },
      filterGroupId,
    );
  };

  _renderNestedFilters = () => {
    return _.chain(this.props.filters).sortBy('label').map(this._renderFilter).value();
  };

  _renderFilter = filter => {
    const isChecked = this.props.filtersSelected.includes(filter.label);
    return (
      <CheckboxFilter
        key={filter.label}
        isChecked={isChecked}
        isNested={true}
        filter={filter}
        handleClick={this.handleSelectIndividualFilter}
      />
    );
  };

  render() {
    const { filter, isExpanded, toggleExpansion, isSelected } = this.props;
    const { label, count } = filter;
    const matches = _.defaultTo(count, '--');
    const icon = isExpanded ? icons.collapseImg : icons.expandImg;
    const nestedDisplay = isExpanded ? '' : 'off';
    const nestedFilters = this._renderNestedFilters();
    const matchesDisplay = isSelected ? 'active' : '';
    const expandableCheckboxClassName = isSelected
      ? 'expandable-checkbox-filter checked'
      : 'expandable-checkbox-filter unchecked';

    return (
      <div className={`checkbox-input ${expandableCheckboxClassName}`}>
        <div className="expandable-checkbox-filter-top-level">
          <div className="expander" onClick={() => toggleExpansion(label)}>
            <img src={icon} alt="Expand/Collapse Icon" />
          </div>
          <div className="expandable-checkbox-top-level-input" onClick={this.handleSelectAllFilters}>
            <label for={label}>
              <input type="checkbox" id={label} name={label} value={label} />
              <svg
                class="checkbox"
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <path class="checkmark" d="M 5,11 L 8,14 L 14, 6" />
              </svg>
            </label>
            <div className="expandable-checkbox-label">{label}</div>
            <div className="expandable-checkbox-match-details">
              <div className={`expandable-checkbox-cumulative-matches ${matchesDisplay}`}>{matches}</div>
            </div>
          </div>
        </div>
        <ul className={`expandable-checkbox-nested-filters ${nestedDisplay}`}>{nestedFilters}</ul>
      </div>
    );
  }
}

ExpandableCheckboxFilter.propTypes = {
  /** List of all possible filters*/
  filters: PropTypes.array,

  /** Array of strings representing the different fund families selected by the user */
  filtersSelected: PropTypes.array.isRequired,

  /** Handles displaying expanded or collapsed icon  */
  isExpanded: PropTypes.bool.isRequired,

  filter: PropTypes.object.isRequired,

  /** Handles expanding and collapsing the filter */
  toggleExpansion: PropTypes.func.isRequired,

  /** Redux action that updates store with diff of changed filter */
  onFiltersUpdated: PropTypes.func.isRequired,
};

export default ExpandableCheckboxFilter;
