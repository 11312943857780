import React, { Component } from 'react';
import PropTypes from 'prop-types';

class HorizontalScrollbar extends Component {
  updateScroll = scrollPos => {
    if (this.props.horizontalScrollPos !== scrollPos) {
      this.props.updateHorizontalScroll(scrollPos);
    }
  };
  render() {
    const {
      staticTableWidth,
      visibleTableWidth,
      needsScrollBar,
      isScrollbarFloating,
      tableLeft,
	  scrollMaxValue
    } = this.props;
    if (!needsScrollBar) return null;
    const fauxScrollContainerStyle = {
      width: staticTableWidth + visibleTableWidth,
      left: isScrollbarFloating ? tableLeft : 0,
    };
    const fixedFauxScrollStyle = { width: staticTableWidth };
    const fauxScrollWrapperStyle = { width: visibleTableWidth };
    const fauxScrollWrapperContainerStyle = { width: visibleTableWidth };
    const fauxScrollStyle = { width: scrollMaxValue };
    return (
      <div
        className={`faux-scroll-container contain ${isScrollbarFloating ? 'affix' : 'affix-bottom'}`}
        style={fauxScrollContainerStyle}
      >
        <div id="fixed-faux-scroll" style={fixedFauxScrollStyle} />
        <div className="faux-scroll-wrapper" style={fauxScrollWrapperStyle}>
          <div
            id="faux-scroll-wrapper-container"
            className="faux-scroll-wrapper-container"
            style={fauxScrollWrapperContainerStyle}
            onScroll={e => {
              const scrollPos = e.currentTarget.scrollLeft;
              this.updateScroll(scrollPos);
            }}
          >
            <div id="faux-scroll" style={fauxScrollStyle} />
          </div>
          <div id="faux-scroll-bottom" />
        </div>
      </div>
    );
  }
}

HorizontalScrollbar.propTypes = {
  staticTableWidth: PropTypes.number,
  scrollableTableWidth: PropTypes.number,
  visibleTableWidth: PropTypes.number,
  tableLeft: PropTypes.number,
  scrollMaxValue: PropTypes.number,
  needsScrollBar: PropTypes.bool,
  horizontalScrollPos: PropTypes.number,
  updateHorizontalScroll: PropTypes.func,
  ScrollbarFloating: PropTypes.bool,
};

export default HorizontalScrollbar;
