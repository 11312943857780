import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import StarIconSvg from 'components/Shared/StarIconSvg';

const MAX_ICON_COUNT = 5;

class QuickCriteriaIconFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actualValue: null,
      hoverValue: null,
      isUserHovering: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.valuesSelected.length === 0 && !_.isNull(this.state.actualValue)) {
      this.setState({ actualValue: null });
    }
  };

  clearIconHighlight = () => {
    this.setState({
      hoverValue: null,
      actualValue: null,
    });
  };

  handleIconClick = id => {
    if (id !== null) {
      this.setState({
        actualValue: Number(id),
      });
    } else {
      this.setState({
        actualValue: null,
      });
    }
  };

  handleIconHover = id => {
    if (id !== null) {
      this.setState({
        isUserHovering: true,
        hoverValue: Number(id),
      });
    } else {
      this.setState({
        isUserHovering: false,
        hoverValue: null,
      });
    }
  };

  getCriteriaSelections = (id, criteriaName) => {
    let selections = [];
    if (id === this.state.actualValue) {
      selections = [];
      this.clearIconHighlight();
    } else {
      if (criteriaName === 'expenses') {
        for (let i = id; i > 0; i--) {
          selections.push(i.toString());
        }
      } else {
        for (let i = id; i < 6; i++) {
          selections.push(i.toString());
        }
      }
    }
    return selections;
  };

  _getRating = () => {
    if (!_.isNil(this.state.hoverValue)) {
      return this.state.hoverValue;
    } else if (!_.isNil(this.state.actualValue)) {
      return this.state.actualValue;
    }
    return null;
  };

  _getMStarRatingsDescriptiveText = () => {
    const rating = this._getRating();
    if (rating !== 5) {
      return !_.isNil(rating) ? `Rating of ${rating} or more stars` : 'Any Morningstar rating';
    }
    return `Rating of ${rating} stars`;
  };

  _getReturnRatingsDescriptiveText = () => {
    const returnRatings = {
      1: 'Low returns & higher',
      2: 'Below average returns & higher',
      3: 'Average returns & higher',
      4: 'Above average returns & higher',
      5: 'High returns only',
    };
    const rating = this._getRating();
    const returnsText = returnRatings[rating];
    return !_.isNil(rating) ? returnsText : 'Any Returns rating';
  };

  _getExpenseRatingsDescriptiveText = () => {
    const expenseRatings = {
      1: 'Low expenses only',
      2: 'Below average expenses & lower',
      3: 'Average expenses & lower',
      4: 'Above average expenses & lower',
      5: 'High expenses & lower',
    };
    const rating = this._getRating();
    const expensesText = expenseRatings[rating];
    return !_.isNil(rating) ? expensesText : 'Any Expense rating';
  };

  _renderDescriptiveText = criteriaName => {
    const text = (() => {
      switch (criteriaName) {
        case 'mStarRating':
          return this._getMStarRatingsDescriptiveText();
        case 'returns':
          return this._getReturnRatingsDescriptiveText();
        case 'expenses':
          return this._getExpenseRatingsDescriptiveText();
        default:
          return '';
      }
    })();
    return <div className="ratings-text">{text}</div>;
  };

  _renderMorningstarIcons = (icon, valuesSelected, criteriaName, handleClick) => {
    const icons = [];
    let activeIcons = null;
    if (this.state.isUserHovering) {
      activeIcons = this.state.hoverValue;
    } else {
      if (criteriaName !== 'expenses' && !_.isNil(valuesSelected[0])) {
        activeIcons = valuesSelected[0];
      } else if (criteriaName === 'expenses' && !_.isNil(_.last(valuesSelected))) {
        activeIcons = _.last(valuesSelected);
      } else {
        activeIcons = 0;
      }
    }
    for (let i = 0; i < MAX_ICON_COUNT; i++) {
      let className = 'icon-container';
      if (icon !== 'star') className = i < activeIcons ? `${icon}` : `${icon} greyed-out`;
      icons.push(
        <span
          className={className}
          key={i}
          data-id={i}
          onMouseOver={e => {
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
            let id = Number(e.target.getAttribute('data-id')) + 1;
            this.handleIconHover(id);
          }}
          onClick={e => {
            e.preventDefault();
            e.nativeEvent.stopImmediatePropagation();
            let id = Number(e.target.getAttribute('data-id')) + 1;
            this.handleIconClick(id);
            handleClick(criteriaName, this.getCriteriaSelections(id, criteriaName));
          }}
        >
          {this.props.icon === 'star' ? <StarIconSvg isGrayedOut={i >= activeIcons} /> : null}
        </span>,
      );
    }
    return (
      <div>
        <div className={`icons ${this.props.icon === 'star' ? 'star-icons' : ''}`}>{icons}</div>
      </div>
    );
  };
  render() {
    const { icon, criteriaName, handleClick, valuesSelected } = this.props;

    let displayIcon = null;

    if (icon === 'box' || icon === 'star') {
      displayIcon = this._renderMorningstarIcons(icon, valuesSelected, criteriaName, handleClick);
    } else {
      displayIcon = null;
    }

    let displayText = this._renderDescriptiveText(criteriaName);
    let rating = this._getRating();
    const keyDownList = ['ArrowRight', 'ArrowUp', 'ArrowLeft', 'ArrowDown', 'Home', 'End'];
    return (
      <div className="quick-criteria-icon-filter">
        <div className="icon-row">
          <div
            tabIndex={0}
            role="slider"
            aria-roledescription="rating slider"
            aria-valuemin={0}
            aria-valuemax={5}
            aria-valuetext={
              criteriaName === 'expenses'
                ? this._getExpenseRatingsDescriptiveText()
                : criteriaName === 'mStarRating'
                ? this._getMStarRatingsDescriptiveText()
                : this._getReturnRatingsDescriptiveText()
            }
            className="icon-container"
            onKeyDown={e => {
              if (keyDownList.includes(e.key)) {
                let id = 1;
                if (e.key === 'ArrowRight' || e.key === 'ArrowUp') {
                  e.preventDefault();
                  if (rating == 5) {
                    return;
                  }
                  if (rating && id < 5) {
                    id = rating + 1;
                  }
                } else if (e.key === 'ArrowLeft' || e.key === 'ArrowDown') {
                  e.preventDefault();
                  if (rating == 0 || rating === null) {
                    return;
                  }
                  if (rating && id > 0) {
                    id = rating - 1;
                  }
                } else if (e.key === 'Home') {
                  e.preventDefault();
                  if (rating == 0) {
                    return;
                  } else {
                    id = 0;
                  }
                } else if (e.key === 'End') {
                  e.preventDefault();
                  if (rating == 5) {
                    return;
                  } else {
                    id = 5;
                  }
                }
                if (id >= 0 && id <= 5) {
                  this.handleIconClick(id);
                  handleClick(criteriaName, this.getCriteriaSelections(id, criteriaName));
                }
              }
            }}
            onMouseLeave={e => {
              e.preventDefault();
              e.nativeEvent.stopImmediatePropagation();
              this.handleIconHover(null);
            }}
          >
            {displayIcon}
            <div className="quick-criteria-icon-filter-labels">
              <span>Low</span>
              <span>High</span>
            </div>
          </div>
        </div>
        {displayText}
      </div>
    );
  }
}

QuickCriteriaIconFilter.defaultProps = {
  classNames: '',
};

QuickCriteriaIconFilter.propTypes = {
  /** Additional classes for the top level component */
  classNames: PropTypes.string,

  /** Name of the criteria associated with this filter */
  criteriaName: PropTypes.string.isRequired,

  /** Handles selecting a filter */
  handleClick: PropTypes.func.isRequired,

  /** Can be either one of the Fidelity icons, or Morningstar stars/boxes */
  icon: PropTypes.string.isRequired,

  /** Array representing all Morningstar returns selected */
  valuesSelected: PropTypes.array.isRequired,
};

export default QuickCriteriaIconFilter;
