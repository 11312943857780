import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import Creators from 'actions/CriteriaActions';
import { ROUTE_CONSTANTS } from 'configs/AppFeatureConfig';
import QuickCriteriaMenuContainer from 'containers/QuickCriteriaMenuContainer';
import FundStrategiesContainer from 'components/StartPage/FundStrategiesContainer';
import FundPicksContainer from 'components/StartPage/FundPicksContainer';
import { getStartPageActiveViewTab } from 'reducers/StartPageViewTabReducer';
import { getIsMobile } from 'reducers/MobileStatusReducer';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getRouterRoute } from 'reducers/selectors/RouterSelectors';
import { START_PAGE_VIEW_TAB_SOURCE } from 'utilities/odaUtils';
import MadLibsContainer from './MadLibsContainer';

class StartPage extends Component {
  _renderContainer = () => {
    switch (this.props.match.path) {
      case ROUTE_CONSTANTS.INDEX:
        return (
          <React.Fragment>
            <QuickCriteriaMenuContainer match={this.props.match} />
            <MadLibsContainer />
          </React.Fragment>
        );
      case ROUTE_CONSTANTS.STRATEGIES:
      case ROUTE_CONSTANTS.NESTED_STRATEGIES:
        return <FundStrategiesContainer match={this.props.match} />;
      case ROUTE_CONSTANTS.PICKS:
        return <FundPicksContainer match={this.props.match} />;
      default:
        return null;
    }
  };
  render() {
    const { activeTab } = this.props;

    const quickCriteriaTabStyle = activeTab === 'quickCriteria' ? 'active' : 'inactive';
    const fundStrategiesTabStyle = activeTab === 'fundStrategies' ? 'active' : 'inactive';
    const fundPicksTabStyle = activeTab === 'fundPicks' ? 'active' : 'inactive';

    let activeTabSource = null;
    switch (activeTab) {
      case 'quickCriteria':
        activeTabSource = START_PAGE_VIEW_TAB_SOURCE.QUICK_CRITERIA;
        break;
      case 'fundStrategies':
        activeTabSource = START_PAGE_VIEW_TAB_SOURCE.STRATEGIES;
        break;
      case 'fundPicks':
        activeTabSource = START_PAGE_VIEW_TAB_SOURCE.PICKS;
        break;
      default:
        activeTabSource = null;
        break;
    }

    return (
      <div className="landing-page-container">
        <div className="start-page">
          <div className="view-tabs row">
            <div
              className={`col-xs-4 quick-criteria-tab ${quickCriteriaTabStyle}`} aria-current={activeTab === 'quickCriteria'}
              onClick={() => this.props.changeStartPageViewTab('quickCriteria', activeTabSource)}
            >
              <button className="as-link">Quick Criteria</button>
            </div>
            <div
              className={`col-xs-4 fund-strategies-tab ${fundStrategiesTabStyle}`} aria-current={activeTab === 'fundStrategies'}
              onClick={() => this.props.changeStartPageViewTab('fundStrategies', activeTabSource)}
            >
              <button className="as-link">Fund Strategies</button>
            </div>
            <div
              className={`col-xs-4 fund-picks-tab ${fundPicksTabStyle}`} aria-current={activeTab === 'fundPicks'}
              onClick={() => this.props.changeStartPageViewTab('fundPicks', activeTabSource)}
            >
              <button className="as-link">
                Fund Picks from Fidelity<sup>&reg;</sup>
              </button>
            </div>
          </div>
          {this._renderContainer()}
        </div>
      </div>
    );
  }
}

StartPage.propTypes = {
  /** The current active start page view tab */
  activeTab: PropTypes.string.isRequired,

  /** Redux action to change the view tab on the start page */
  changeStartPageViewTab: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    activeTab: getStartPageActiveViewTab(state),
    isMobile: getIsMobile(state),
    displayType: getDisplayType(state),
    route: getRouterRoute(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeStartPageViewTab: (viewTab, source) => {
      dispatch(Creators.changeStartPageViewTab(viewTab, source));
    }
  };
};

const StartPageContainer = connect(mapStateToProps, mapDispatchToProps)(StartPage);

export default StartPageContainer;
export { StartPage };
