import { createReducer } from 'reduxsauce';
import { Types as ReduxSauceTypes } from 'reduxsauce';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { ALL_CRITERIA } from 'configs/CriteriaConfig';
import { allMadLibsDataStructureFormatting } from 'utilities/apiUtils';
import mockData from 'madlibs-mock-data.js';
import Types from 'actions/Types';

const CRITERIA_PRIORITY_LIST = [
  'category',
  'risk',
  'keyCriteria',
  'indexFundOnly',
  'mStarRating',
  'returns',
  'expenses',
];

export const INITIAL_STATE = {
  isModalOpen: false,
  isMadLibsActive: false,
  madLibsSelections: [{ options: mockData, selected: null }],
  madLibsAdditionalCriteria: [
    { label: 'Sustainable Attributes', criteria: { sociallyResponsible: ['Y'] }, selected: false },
    { label: 'No Transaction Fee', criteria: { keyCriteria: ['ntf'] }, selected: false },
    { label: 'Low Expenses', criteria: { keyCriteria: ['minimumInvestment'] }, selected: false },
  ],
  userSelectedCriteria: null,
  activeFundType: 'category',
  selectedAssetClass: 'All Classes, All Categories',
  selections: {
    assetClass: [],
    category: [],
    risk: '5',
    keyCriteria: [],
    indexFundOnly: '',
    mStarRating: [],
    returns: [],
    expenses: [],
  },
  isAssetClassCategoryChanged: false,
};

const defaultHandler = (state = INITIAL_STATE) => {
  return state;
};

const updateActiveFundType = (state = INITIAL_STATE, action = {}) => {
  const selectionsCopy = _.cloneDeep(state.selections);
  if (action.fundType === 'risk') {
    return {
      ...state,
      isMadLibsActive: false,
      madLibsSelections: INITIAL_STATE.madLibsSelections,
      madLibsAdditionalCriteria: INITIAL_STATE.madLibsAdditionalCriteria,
      selectedAssetClass: INITIAL_STATE.selectedAssetClass,
      activeFundType: 'risk',
      selections: {
        ...selectionsCopy,
        assetClass: INITIAL_STATE.selections.assetClass,
        category: INITIAL_STATE.selections.category,
      },
      isAssetClassCategoryChanged: IsAssetClassCategoryChanged(state, action.fundType, action),
    };
  }

  return {
    ...state,
    isMadLibsActive: false,
    madLibsSelections: INITIAL_STATE.madLibsSelections,
    madLibsAdditionalCriteria: INITIAL_STATE.madLibsAdditionalCriteria,
    activeFundType: 'category',
    selections: {
      ...selectionsCopy,
      risk: INITIAL_STATE.selections.risk,
    },
    isAssetClassCategoryChanged: IsAssetClassCategoryChanged(state, action.fundType, action),
  };
};

function IsAssetClassCategoryChanged(state, critId, action) {
  if (state.isAssetClassCategoryChanged) return true;
  if (critId === 'assetClass' || critId === 'category' || critId === 'risk') return true;
  return false;
}

const updateSelectedAssetClass = (state = INITIAL_STATE, action = {}) => {
  return {
    ...state,
    isMadLibsActive: false,
    madLibsSelections: INITIAL_STATE.madLibsSelections,
    madLibsAdditionalCriteria: INITIAL_STATE.madLibsAdditionalCriteria,
    selectedAssetClass: action.assetClass,
    selections: {
      ...state.selections,
      category: action.categories,
    },
    isAssetClassCategoryChanged: IsAssetClassCategoryChanged(state, action.critId, action),
  };
};

const updateCriteriaSelections = (state = INITIAL_STATE, action = {}) => {
  return {
    ...state,
    isMadLibsActive: false,
    madLibsSelections: INITIAL_STATE.madLibsSelections,
    madLibsAdditionalCriteria: INITIAL_STATE.madLibsAdditionalCriteria,
    selections: {
      ..._.cloneDeep(state.selections),
      [action.critId]: action.selections,
    },
    isAssetClassCategoryChanged: IsAssetClassCategoryChanged(state, action.critId, action),
  };
};

const cancelModal = (state = INITIAL_STATE) => {
  return { ...state, isModalOpen: false, userSelectedCriteria: null };
};

const displayModal = (state = INITIAL_STATE, action = {}) => {
  return {
    ...state,
    userSelectedCriteria: action.userSelections,
    isModalOpen: true,
  };
};

const hideModal = (state = INITIAL_STATE) => {
  return {
    ...state,
    isModalOpen: false,
  };
};

const resetQuickCriteriaMenu = (state = INITIAL_STATE, action = {}) => {
  return { ...INITIAL_STATE };
};

const updateMadLibsSelections = (state = INITIAL_STATE, action = {}) => {
  return {
    ...state,
    isMadLibsActive: checkMadLibsActive(action, state),
    selections: INITIAL_STATE.selections,
    userSelectedCriteria: INITIAL_STATE.userSelectedCriteria,
    activeFundType: INITIAL_STATE.activeFundType,
    selectedAssetClass: 'All Asset Classes',
    madLibsSelections: [...action.selections],
  };
};

const updateMadLibsAdditionalCriteria = (state = INITIAL_STATE, action = {}) => {
  return {
    ...state,
    isMadLibsActive: checkMadLibsActive(action, state),
    selections: INITIAL_STATE.selections,
    userSelectedCriteria: INITIAL_STATE.userSelectedCriteria,
    activeFundType: INITIAL_STATE.activeFundType,
    selectedAssetClass: 'All Asset Classes',
    madLibsAdditionalCriteria: [...action.additionalCriteria],
  };
};

const checkMadLibsActive = (action, state) => {
  let madlibs = action.selections;
  let additional = action.additionalCriteria;
  let isMadlibSelected = false;
  let isAdditionalSelected = false;
  if (madlibs !== undefined) {
    isMadlibSelected = madlibs.filter(item => item.selected !== null).length > 0;
  } else if (state.madLibsSelections !== undefined) {
    madlibs = state.madLibsSelections;
    isMadlibSelected = madlibs.filter(item => item.selected !== null).length > 0;
  }
  if (additional !== undefined) {
    isAdditionalSelected = additional.filter(item => item.selected === true).length > 0;
  }
  return isMadlibSelected || isAdditionalSelected;
};

const ACTION_HANDLERS = {
  [Types.QUICK_CRITERIA_UPDATE_ACTIVE_FUND_TYPE]: updateActiveFundType,
  [Types.QUICK_CRITERIA_UPDATE_ASSET_CLASS]: updateSelectedAssetClass,
  [Types.QUICK_CRITERIA_UPDATE_SELECTIONS]: updateCriteriaSelections,
  [Types.QUICK_CRITERIA_CANCEL_MODAL]: cancelModal,
  [Types.QUICK_CRITERIA_DISPLAY_MODAL]: displayModal,
  [Types.QUICK_CRITERIA_HIDE_MODAL]: hideModal,
  [Types.ADD_CRITERION]: resetQuickCriteriaMenu,
  [Types.LOAD_STRATEGY]: resetQuickCriteriaMenu,
  [Types.CLEAR_ALL]: resetQuickCriteriaMenu,
  [Types.QUICK_CRITERIA_UPDATE_MAD_LIBS_SELECTIONS]: updateMadLibsSelections,
  [Types.QUICK_CRITERIA_UPDATE_MAD_LIBS_ADDITIONAL_CRITERIA]: updateMadLibsAdditionalCriteria,
  [ReduxSauceTypes.DEFAULT]: defaultHandler,
};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

// ---- Selectors ----

export const getQuickCriteriaSelections = state => state.quickCriteriaMenu.selections;
export const getQuickCriteriaActiveFundType = state => state.quickCriteriaMenu.activeFundType;
export const getQuickCriteriaSelectedAssetClass = state => state.quickCriteriaMenu.selectedAssetClass;
export const getQuickCriteriaModalState = state => state.quickCriteriaMenu.isModalOpen;
export const getQuickCriteriaUserSelectedCriteria = state => state.quickCriteriaMenu.userSelectedCriteria;
export const getIsMadLibsActive = state => state.quickCriteriaMenu.isMadLibsActive;
export const getMadLibsSelections = state => state.quickCriteriaMenu.madLibsSelections;
export const getMadLibsAdditionalCriteria = state => state.quickCriteriaMenu.madLibsAdditionalCriteria;
export const getIsAssetClassCategoryChanged = state => state.quickCriteriaMenu.isAssetClassCategoryChanged;

const getQuickCriteriaMenu = state => state.quickCriteriaMenu;

const _getAllAssetClassValues = () => {
  const fundTypeFilters = _.get(ALL_CRITERIA, 'fundType.filterGroups.category', []);

  let allValues = [];
  fundTypeFilters.forEach(category => allValues.push(category.value));

  return allValues;
};

const _addAssetClassCriteriaSelections = (filterAccumulator, activeFundType, value) => {
  if (activeFundType === 'category') {
    filterAccumulator['assetClass'] = [...value];
  }
};

const _addCategoryCriteriaSelections = (filterAccumulator, activeFundType, value, quickCriteriaMenu) => {
  if (activeFundType === 'category') {
    const { selections, selectedAssetClass } = quickCriteriaMenu;
    const areCategorySelectionsPopulated = selections.category.length > 0;

    if (selectedAssetClass === 'All Asset Classes' && !areCategorySelectionsPopulated) {
      filterAccumulator['category'] = _getAllAssetClassValues();
    } else {
      filterAccumulator['category'] = value;
    }
  }
};

const _addKeyCriteriaSelections = (filterAccumulator, value) => {
  value.forEach(val => {
    const critDefinition = ALL_CRITERIA[val];
    const critFilterList = critDefinition.filterGroups[val];

    let filterVal;

    if (val === 'minimumInvestment') {
      // default minimum investment range is $0 < $2.5k
      filterVal = ['0', '2499.99'];
    } else if (critDefinition.criteriaType === 'checkbox') {
      const boolVal = _.get(critFilterList, [0, 'value']);
      if (!_.isNil(boolVal)) {
        filterVal = [boolVal];
      }
    } else if (critDefinition.criteriaType === 'multiSelect') {
      const yesFilter = _.last(critFilterList);
      const { min, max } = yesFilter;
      filterVal = [min || null, max || null];
    }
    filterAccumulator[val] = filterVal;
  });
};

const _addIndexFundOnlyCriteriaSelections = (filterAccumulator, value) => {
  if (!_.isEmpty(value)) {
    filterAccumulator['indexFundOnly'] = [value];
  }
};

const _addRiskCriteriaSelections = (filterAccumulator, value, activeFundType) => {
  if (activeFundType === 'risk' && !_.isNil(value) && !_.isEmpty(value)) {
    filterAccumulator['risk'] = [value];
  }
};

export const buildCriteriaSelections = quickCriteriaMenu => {
  const { selections, activeFundType } = quickCriteriaMenu;
  return _.transform(
    selections,
    (filterAccumulator, value, key) => {
      switch (key) {
        case 'assetClass':
          _addAssetClassCriteriaSelections(filterAccumulator, activeFundType, value);
          break;
        case 'category':
          _addCategoryCriteriaSelections(filterAccumulator, activeFundType, value, quickCriteriaMenu);
          break;
        case 'keyCriteria':
          _addKeyCriteriaSelections(filterAccumulator, value);
          break;
        case 'indexFundOnly':
          _addIndexFundOnlyCriteriaSelections(filterAccumulator, value);
          break;
        case 'risk':
          _addRiskCriteriaSelections(filterAccumulator, value, activeFundType);
          break;
        default:
          if (!_.isEmpty(value)) {
            filterAccumulator[key] = [...value];
          }
          break;
      }
    },
    {},
  );
};

const _buildCritIdArray = (selections, activeFundType) => {
  const selectionKeys = Object.keys(selections).filter(
    key => key !== 'assetClass' && !CRITERIA_PRIORITY_LIST.includes(key),
  );
  const critIdSet = new Set();
  CRITERIA_PRIORITY_LIST.forEach(criterion => {
    const value = selections[criterion];

    if (value && criterion === 'keyCriteria') {
      value.forEach(val => critIdSet.add(val));
    } else if (value && (criterion === 'risk' || criterion === 'category')) {
      if (activeFundType && criterion === activeFundType) {
        critIdSet.add('fundType');
      }
    } else {
      if (!_.isEmpty(value)) critIdSet.add(criterion);
    }
  });
  return Array.from(critIdSet).concat(selectionKeys);
};

export const getMadLibsParsedSelections = createSelector([getQuickCriteriaMenu], quickCriteriaMenu => {
  const totalSelections = allMadLibsDataStructureFormatting(quickCriteriaMenu);
  const critIdArray = _buildCritIdArray(_.omit(totalSelections, 'activeFundType'), totalSelections.activeFundType);
  const filterGroupSelections = buildCriteriaSelections({
    selections: _.omit(totalSelections, 'activeFundType'),
    activeFundType: totalSelections.activeFundType,
  });

  return { critIdArray, filterGroupSelections };
});

export const getQuickCriteriaParsedSelections = createSelector([getQuickCriteriaMenu], quickCriteriaMenu => {
  const { selections, activeFundType } = quickCriteriaMenu;
  const critIdArray = _buildCritIdArray(selections, activeFundType);
  const filterGroupSelections = buildCriteriaSelections(quickCriteriaMenu);
  return { critIdArray, filterGroupSelections };
});
