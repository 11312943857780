import React from 'react';
import PropTypes from 'prop-types';
import MoreOnRiskLink from 'components/Shared/MoreOnRiskLink';

const FundTypeSubheader = ({ activeType, handleToggle }) => {
  const morningstarMessage =
    activeType === 'risk' ? (
      <div>
        <p>Select funds in Morningstar-defined categories grouped by risk level designated by Morningstar.</p>
        <MoreOnRiskLink />
      </div>
    ) : null;
  return (
    <div tabIndex={0} className="fund-type-subheader">
      <div tabIndex={0} className="fund-type-toggle">
        <input
          tabIndex={0}
          type="radio"
          id="by-category-input"
          value="category"
          onChange={handleToggle}
          checked={activeType === 'category'}
          readOnly
        />
        <label className="by-category-input" htmlFor="by-category-input">
          by Class/Category
        </label>

        <input
          tabIndex={0}
          type="radio"
          id="by-risk-input"
          value="risk"
          onChange={handleToggle}
          checked={activeType === 'risk'}
          readOnly
        />
        <label className="by-risk-input" htmlFor="by-risk-input">
          by Risk
        </label>
      </div>
      {morningstarMessage}
    </div>
  );
};

FundTypeSubheader.propTypes = {
  /** Active sub-criterion. May either be category or risk. */
  activeType: PropTypes.string.isRequired,

  onChangeFundTypeSection: PropTypes.func,
};

export default FundTypeSubheader;
