import React from 'react';
import PropTypes from 'prop-types';
import QuickCriteriaCheckboxFilter from 'components/StartPage/QuickCriteriaCheckboxFilter';
const QuickCriteriaTransactionFeeMessages = [
  [
    "Consider your investment amount and time horizon prior to purchasing. Other fees and expenses are described in the prospectus.",
    "Online transaction fees for purchasing transaction fee mutual funds are $49.95 or $75 depending on the fund. Other fees and expenses are described in the prospectus."
  ], [
    "No Transaction Fee funds are subject to short term trading fees. Other fees and expenses are described in the prospectus.",
    "Consider your investment amount and time horizon prior to purchasing. Other fees and expenses are described in the prospectus."
  ]
];

const QuickCriteriaTransactionFee = ({
  allCriteria,
  keyCriteriaSelected,
  handleSelectKeyCriteria
}) => {
  const { ntf, transactionFeeIndicator } = allCriteria;

  const getMessage = (f, s) => {
    let first = f === true ? 1 : 0;
    let second = s === true ? 1 : 0;
    return QuickCriteriaTransactionFeeMessages[first][second];
  }

  let noneChecked = false;
  if (!keyCriteriaSelected.includes(ntf.id) && !keyCriteriaSelected.includes(transactionFeeIndicator.id)) {
    noneChecked = true;
  }

  return (
    <div className="quick-criteria-transaction-fee">
      <div className="transaction-fee-checkboxes">
        <fieldset>
          <legend>
            <div className="large-header" style={{ marginRight: '0px' }}><span>Transaction Fees</span></div>
          </legend>
          <div className="first">
            <ul className="top-section">
              <QuickCriteriaCheckboxFilter
                classNames="item ntf"
                icon="ntfIcon"
                identifier={ntf.id}
                isChecked={keyCriteriaSelected.includes(ntf.id)}
                label={'$0'}
                handleClick={handleSelectKeyCriteria}
                criteriaName="keyCriteria"
                noneChecked={noneChecked}
              />
              <QuickCriteriaCheckboxFilter
                classNames="item ntf"
                icon="dollar"
                identifier={transactionFeeIndicator.id}
                isChecked={keyCriteriaSelected.includes(transactionFeeIndicator.id)}
                label={'Transaction Fee or Load'}
                handleClick={handleSelectKeyCriteria}
                criteriaName="keyCriteria"
                noneChecked={noneChecked}
              />
            </ul>
          </div>
        </fieldset>
      </div>
      <div className="message" id="supplemental-text">
        <span>{getMessage(keyCriteriaSelected.includes(ntf.id), keyCriteriaSelected.includes(transactionFeeIndicator.id))}</span>
      </div>
    </div>
  );
};

QuickCriteriaTransactionFee.propTypes = {
  /** Object representing all criteria definitions */
  allCriteria: PropTypes.object.isRequired,

  /** Handle selecting a key criteria */
  handleSelectKeyCriteria: PropTypes.func.isRequired,

  /** Array of strings representing selected key criteria */
  keyCriteriaSelected: PropTypes.array.isRequired,

};

export default QuickCriteriaTransactionFee;
