import _ from 'lodash';
import Bootstrapper from 'adobeAnalytics';
import {
  ROUTE_CONSTANTS,
  RESULT_FEATURES,
  COMPARE_FEATURES,
  APP_DISPLAY_TITLE,
  ADOBE_ANALYTICS_CHANNEL,
  ADOBE_ANALYTICS_SEC1_PREFIX,
  START_PAGE_TYPE
} from 'configs/AppFeatureConfig';

export const trackAnalytics = params => {
  if (!_.isNil(params) && !_.isNil(ADOBE_ANALYTICS_CHANNEL)) {
    // only do adobe analytics tracking for mfe for now
    try {
      if (typeof Bootstrapper._trackAnalytics === 'function') {
        // Add this if statement to avoid Trackanalytics error
        Bootstrapper._trackAnalytics(params);
        console.log('Bootstrapper _trackAnalytics', params);
      }
    } catch (err) {
      // Handle errors here
      console.log('Error In track analytics ' + err);
    }
  }
};

const DISPLAY_TYPE_TO_SEC1_SUFFIX = {
  table: 'result',
  compare: 'compare',
  card: 'card',
};

const baseTrackingData = {
  pt: 'ss',
  channel: ADOBE_ANALYTICS_CHANNEL,
  sec: 'research',
};

export const getPageViewTrackingData = (route, displayType, viewTab, product=null) => {
  let pname, sec1;

  switch (route) {
    case ROUTE_CONSTANTS.INDEX: {
      pname = START_PAGE_TYPE === 'fili' ? 'Annuity Options' : APP_DISPLAY_TITLE;
      sec1 = START_PAGE_TYPE === 'fili' ? 'annuity funds' : ADOBE_ANALYTICS_SEC1_PREFIX + ' evaluator';
      break;
    }
    case ROUTE_CONSTANTS.STRATEGIES: {
      pname = APP_DISPLAY_TITLE;
      sec1 = ADOBE_ANALYTICS_SEC1_PREFIX + ' strategies';
      break;
    }
    case ROUTE_CONSTANTS.PICKS: {
      pname = APP_DISPLAY_TITLE;
      sec1 = 'fidelity fund picks';
      break;
    }
    case ROUTE_CONSTANTS.WAYS_TO_INVEST: {
      pname = 'Ways to Invest';
      sec1 = 'annuity funds';
      break;
    }
    case ROUTE_CONSTANTS.RESULTS: {
      pname = viewTab;
      sec1 = ADOBE_ANALYTICS_SEC1_PREFIX + ' ' + DISPLAY_TYPE_TO_SEC1_SUFFIX[displayType];
      break;
    }
    default:
      return null;
  }
  if( product ){
    return {
      ...baseTrackingData,
      action: 'pageview',
      sec1,
      pname,
      pver: product,
    };
  } else {
    return {
      ...baseTrackingData,
      action: 'pageview',
      sec1,
      pname,
    };
  }
};

// Results Page Tracking Data

export const getResultsCompareFundsTrackingData = () => {
  return {
    ...baseTrackingData,
    action: 'interaction',
    sec1: ADOBE_ANALYTICS_SEC1_PREFIX + ' results',
    intname: RESULT_FEATURES.COMPARE_BUTTON_LABEL,
  };
};

// Compare Page Tracking Data

export const getCompareBackToResearchResultsTrackingData = () => {
  return {
    ...baseTrackingData,
    action: 'interaction',
    sec1: ADOBE_ANALYTICS_SEC1_PREFIX + ' compare',
    intname: COMPARE_FEATURES.BACK_BUTTON_LABEL,
  };
};

// Multiple Category Tracking Data

export const getBuyButtonTrackingData = (displayType, viewTab, symbol, cusip) => {
  return {
    ...baseTrackingData,
    action: 'interaction',
    sec1: ADOBE_ANALYTICS_SEC1_PREFIX + ' ' + DISPLAY_TYPE_TO_SEC1_SUFFIX[displayType],
    pname: viewTab,
    intname: START_PAGE_TYPE === 'fili' ? 'Exchange' : 'Buy',
    cusip: `${symbol}|${cusip}`,
  };
};

export const getAnnuityOptionTrackingData = name => {
  return {
    ...baseTrackingData,
    action: 'interaction',
    pname: 'Annuity Options',
    sec1: 'annuity funds',
    intname: name
  }
}

export const getIncludeETFTrackingData = data => {
  return {
    ...baseTrackingData,
    action: 'interaction',
    sec1: ADOBE_ANALYTICS_SEC1_PREFIX + ' results',
    ...data
  }
}

export const getViewMadLibsTrackingData = data => {
  return {
    pt: 'ss',
    action: 'interaction',
    channel: ADOBE_ANALYTICS_CHANNEL,
    sec: 'research',
    pname: 'Mutual Funds Research',
    intname: 'MadLibs View Funds',
    ad: data,
  }
}