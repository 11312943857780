import { connect } from 'react-redux';
import Creators from 'actions/CriteriaActions';
import ResultsView from 'components/ResultsView';
import { getIsMobile } from 'reducers/MobileStatusReducer';
import { getDisplayType } from 'reducers/DisplayTypeReducer';

const mapStateToProps = state => {
  return {
    isMobile: getIsMobile(state),
    displayType: getDisplayType(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateDisplayType: displayType => {
      dispatch(Creators.updateDisplayType(displayType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsView);
