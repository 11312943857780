import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RadioButtonFilter from 'components/ActiveCriterion/RadioButtonFilter';

class RadioButtonInterface extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilter: [],
    };
  }

  handleClickRadioButton = label => {
    let filterUpdates = {
      [label]: true,
    };

    if (this.props.filtersSelected.length) {
      filterUpdates[this.props.filtersSelected[0]] = false;
    }

    this.props.onFiltersUpdated(filterUpdates);
  };

  _renderAllFilters = filters => {
    return filters.map((filter, index) => (
      <RadioButtonFilter
        key={filter.label}
        index={index}
        isSelected={this.props.filtersSelected.includes(filter.label)}
        filter={filter}
        handleClick={this.handleClickRadioButton}
      />
    ));
  };

  render() {
    const { filters } = this.props;
    const allFilters = this._renderAllFilters(filters);

    return (
      <ul tabIndex={0} className="radio-button-interface">
        {allFilters}
      </ul>
    );
  }
}

RadioButtonInterface.propTypes = {
  filters: PropTypes.array,

  filtersSelected: PropTypes.array,

  onFiltersUpdated: PropTypes.func.isRequired,
};

export default RadioButtonInterface;
