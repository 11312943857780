import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _, { identity, result } from 'lodash';
import {
  RESULT_FEATURES,
  COMPARE_FEATURES,
  HAS_DOWNLOAD_RESULTS,
  HAS_HELP_ME_READ_THIS_TABLE_LINK,
} from 'configs/AppFeatureConfig';
import ResultsTableHeaderContainer from 'components/ResultsView/ResultsTableHeaderContainer';
import ResultsTableRowContainer from 'components/ResultsView/ResultsTableRowContainer';
import HorizontalScrollbar from 'components/ResultsView/HorizontalScrollbar';
import FundsSelectedPopoverContainer from 'components/ResultsView/FundsSelectedPopoverContainer';
import AddSymbolContainer from 'components/ResultsView/AddSymbolContainer';
import DownloadResults from './DownloadResults';
import { getDisplayType } from 'reducers/DisplayTypeReducer';
import { getResultsData } from 'reducers/FundSearchResultsReducer';
import { getSortSettings } from 'reducers/SortResultsReducer';
import { getFidelityMatches } from 'reducers/FidelityMatchesReducer';
import { getFundsSelected } from 'reducers/FundsSelectedReducer';
import { getSomeResultsLoading } from 'reducers/LoadingReducer';
import { getCriteriaDefinitions } from 'reducers/selectors/CriteriaSelectors';
import { getActiveCriteria } from 'reducers/ActiveCriteriaReducer';
import { getAllCriteriaFilters } from 'reducers/CriteriaFiltersReducer';
import { getTableDisclosureContent } from 'reducers/ApplicationDisclosuresReducer';
import { getFundSearchParsedError, getFidelityFundSearchParsedError } from 'reducers/ErrorReducer';
import { getColumnsForViewTab, getViewTab } from 'reducers/ViewTabReducer';
import { getCriteriaMenuExpanded } from 'reducers/CriteriaMenuExpandedReducer';
import { getSharedStaticColumns } from 'reducers/SharedStaticColumnsReducer';
import { getHelpMeReadThisLinkFullUrl } from 'reducers/selectors/LinkSelectors';
import Creators from 'actions/CriteriaActions';

class ResultsTable extends Component {
  constructor(props) {
    super(props);
    this.MAX_FUNDS_SELECTED = 5;
    this.pageScroll = this._pageScroll.bind(this);
    this.windowResize = this._windowResize.bind(this);
    this.debouncedPageScroll = _.debounce(this.pageScroll, 50);
    this.debouncedWindowResize = _.debounce(this.windowResize, 50);
    this.currentCheckboxRef = null;
    this.setCurrentCheckboxRef = element => {
      this.currentCheckboxRef = element;
    };
    this.fundToCheckAfterRemoval = {};
    this.state = {
      showFixedHeader: false,
      headerWidth: 0,
      headerHeight: 0,
      headerDimensions: [],
      staticHeaderDimensions: [],
      fidelityResultsRowHeights: [],
      staticTableWidth: 0,
      staticColumnsLeft: null,
      scrollableTableWidth: 0,
      visibleTableWidth: 0,
      needsScrollBar: false,
      horizontalScrollPos: 0,
      shouldScrollbarFloat: false,
      showFundsSelectedPopover: false,
    };
  }

  _closeFundsSelectedPopover = () => {
    this.setState({ showFundsSelectedPopover: false });
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.debouncedPageScroll); //ms
    window.addEventListener('resize', this.debouncedWindowResize); //ms
    this._alignTable();
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.debouncedPageScroll); //ms
    window.removeEventListener('resize', this.debouncedWindowResize); //ms
  };

  componentDidUpdate = (prevProps, prevState) => {
    const didResultsFinishLoading =
      !this.props.someResultsLoading && !_.isEqual(prevProps.someResultsLoading, this.props.someResultsLoading);
    const criteriaMenuToggled = prevProps.isCriteriaMenuExpanded !== this.props.isCriteriaMenuExpanded;
    if (didResultsFinishLoading || (criteriaMenuToggled && this.props.displayType !== 'card')) {
      this._alignTable();
    }

    if (
      this.refs.scrollableTableWrapper &&
      this.refs.scrollableTableWrapper.scrollLeft !== this.state.horizontalScrollPos
    ) {
      this.refs.scrollableTableWrapper.scrollLeft = this.state.horizontalScrollPos;
    }
  };

  _getStaticColumnsLeft() {
    const staticColumns = document.querySelectorAll(
      '#scrollable-results-table #results-header .grouped-column-headers .col-static',
    );
    if (_.isNil(staticColumns)) return null;
    let w = [0];
    let sum = 0;
    staticColumns.forEach(e => {
      sum = sum + e.offsetWidth;
      w.push(sum);
    });
    return w;
  }
  _getTableHeaderDimensions = () => {
    const resultsHeader = document.getElementById('results-header');
    if (_.isNil(resultsHeader)) return {};
    const header = resultsHeader.lastElementChild;
    const numHeaders = header.childNodes.length;
    let dimensions = [];
    for (let i = 0; i < numHeaders; i++) {
      const columnDimensions = {};
      const tableHeader = header.childNodes[i];
      columnDimensions.width = tableHeader.clientWidth;
      columnDimensions.height = tableHeader.clientHeight;
      dimensions.push(columnDimensions);
    }
    return { headerDimensions: dimensions };
  };

  _getStaticTableWidth = () => {
    const staticColumns = document.querySelectorAll(
      '#scrollable-results-table #results-header .grouped-column-headers .col-static',
    );
    if (_.isNil(staticColumns)) return 0;
    let w = 0;
    staticColumns.forEach(e => {
      w = w + e.offsetWidth;
    });
    return w;
  };

  _getScrollableTableWidth = () => {
    const t = document.getElementById('scrollable-results-table');
    if (_.isNil(t)) return 0;
    return t.offsetWidth - this._getStaticTableWidth();
  };

  _getTableBottomPosition = () => {
    const t = document.getElementById('scrollable-results-table-wrapper');
    if (_.isNil(t)) return 0;

    const rect = t.getBoundingClientRect();
    const resultsTableHeight = t.offsetHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return rect.top + scrollTop + resultsTableHeight;
  };

    _shouldScrollbarFloat = () => {
    const tableBottomPosition = this._getTableBottomPosition();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowBottom = scrollTop + window.innerHeight;
    return { shouldScrollbarFloat: windowBottom < tableBottomPosition };
  };

  getDimensionsOfScrollableResultsTableWrapper = () => {
    const t = document.getElementById('scrollable-results-table-wrapper');
    if (_.isNil(t)) return {visibleTableWidth:0, tableLeft:0, leftOffset:0, scrollLeftMax:0};
    const rect = t.getBoundingClientRect();
    const tableLeft = rect.x;
	const w = window.innerWidth;
    const visibleTableWidth = w - rect.x - this._getStaticTableWidth() - 10;
	const leftOffset = rect.left + window.pageXOffset;
	const scrollLeftMax = t.scrollLeftMax ? t.scrollLeftMax : t.scrollWidth - t.clientWidth;
	return {visibleTableWidth, tableLeft, leftOffset, scrollLeftMax};
  }

  _updateTableDimensionsForScrollbar = () => {
    const staticTableWidth = this._getStaticTableWidth(); // sum of static column's width
    const scrollableTableWidth = this._getScrollableTableWidth(); // sum of scrollable columns's width
	//visibleTableWidthL visible scrollable area's width, tableLeft: left of the table
    const { visibleTableWidth, tableLeft, scrollLeftMax } = this.getDimensionsOfScrollableResultsTableWrapper();
    const needsScrollBar = visibleTableWidth < scrollableTableWidth;
	const scrollMaxValue = visibleTableWidth + scrollLeftMax;
    return {
      tableLeft,
      staticTableWidth,
      scrollableTableWidth,
      visibleTableWidth,
	  scrollMaxValue,
      needsScrollBar,
    };
  };

  _getHeaderDimension = () => {
    const header = document.getElementById('results-header');
    if (_.isNil(header)) {
      return;
    }
    const headerRowHeights = _.map(header.childNodes, tr => {
      return tr.clientHeight;
    });
    const headerWidth = header.clientWidth;
    const headerHeight = header.clientHeight + 1;

    return {
      headerWidth,
      headerHeight,
      headerRowHeights,
    };
  };

  _getTableHeaderPos = () => {
    const tableHeader = document.getElementById('results-header');
    if (_.isNil(tableHeader)) return null;
    const headerRect = tableHeader.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return headerRect.top + scrollTop;
  };

  _showFixedHeader = () => {
    let supportPageOffset = window.pageXOffset !== undefined;
    let isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
    let scroll = {
      x: supportPageOffset
        ? window.pageXOffset
        : isCSS1Compat
        ? document.documentElement.scrollLeft
        : document.body.scrollLeft,
      y: supportPageOffset
        ? window.pageYOffset
        : isCSS1Compat
        ? document.documentElement.scrollTop
        : document.body.scrollTop,
    };
    const tableHeadPos = this._getTableHeaderPos();
    const tableBottomPosition = this._getTableBottomPosition();
    const headerHeight = this.state.headerHeight;

    const showFixedHeader = scroll.y > tableHeadPos && scroll.y + headerHeight < tableBottomPosition;
    return { showFixedHeader };
  };

  updateHorizontalScroll = horizontalScrollPos => {
    this.setState({ horizontalScrollPos });
  };

  _alignTable = () => {
    //check for table scrolling/ scrollbar position
    //Resize horizontal scrollbar
    const tableDimensionsForScrollbar = this._updateTableDimensionsForScrollbar();
    const shouldScrollbarFloat = this._shouldScrollbarFloat();

    //resize floating header
    // show/hide floating header
    //align floating header cells
    const headerDimension = this._getHeaderDimension();
    const tableHeaderDimensions = this._getTableHeaderDimensions();

    const showFixedHeader = this._showFixedHeader();
    // set horizontal scroll position (table, scrollbar, floating header)
    this.updateHorizontalScroll();

    this.setState({
      ...tableHeaderDimensions,
      ...headerDimension,
      ...tableDimensionsForScrollbar,
      ...showFixedHeader,
      ...shouldScrollbarFloat,
      staticColumnsLeft: this._getStaticColumnsLeft(),
    });
  };

  _windowResize = () => {
    //check for table scrolling/ scrollbar position
    //Resize horizontal scrollbar
    const tableDimensionsForScrollbar = this._updateTableDimensionsForScrollbar();
    const shouldScrollbarFloat = this._shouldScrollbarFloat();
    const tableHeaderDimensions = this._getTableHeaderDimensions();

    //resize floating headers
    const headerDimension = this._getHeaderDimension();

    this.setState({
      ...tableDimensionsForScrollbar,
      ...headerDimension,
      ...tableHeaderDimensions,
      ...shouldScrollbarFloat,
      staticColumnsLeft: this._getStaticColumnsLeft(),
    });
  };

  _pageScroll = () => {
    //check for table scrolling/ scrollbar position
    const tableDimensionsForScrollbar = this._updateTableDimensionsForScrollbar();
    const shouldScrollbarFloat = this._shouldScrollbarFloat();
    const showFixedHeader = this._showFixedHeader();

    // show/hide floating header
    const headerDimension = this._getHeaderDimension();
    this.setState({
      ...tableDimensionsForScrollbar,
      ...headerDimension,
      ...shouldScrollbarFloat,
      ...showFixedHeader,
      staticColumnsLeft: this._getStaticColumnsLeft(),
    });
  };

  _handleActionCheckboxClick = (isChecked, newSelection, ref) => {
    let newCheckedFundsList = [];
    if (isChecked) {
      newCheckedFundsList = [...this.props.fundsSelected, newSelection];
    } else {
      if (this.props.displayType === 'compare' && this.props.fundsSelected.length <= 1) {
        this.props.updateFundsSelectedError('notEnoughFunds');
        return;
      }
      newCheckedFundsList = _.filter(this.props.fundsSelected, fundObj => {
        return fundObj[COMPARE_FEATURES.FUND_ID_FIELD] !== newSelection[COMPARE_FEATURES.FUND_ID_FIELD];
      });
    }
    if (newCheckedFundsList.length > this.MAX_FUNDS_SELECTED && isChecked) {
      this.currentCheckboxRef = ref;
      this.fundToCheckAfterRemoval = newSelection;
      this.setState({ showFundsSelectedPopover: true });
    } else {
      this.setState({ showFundsSelectedPopover: false });
      this.props.updateFundsSelected(newCheckedFundsList);
    }
  };

  _classNameWithLoading = baseClassName => {
    const className = `${baseClassName} ${this.props.someResultsLoading ? 'loading' : ''}`;
    return className;
  };

  _getIsFidelityFundsOnlySelected = () => {
    const fidelityFundOnlyFilter = _.get(this.props.criteriaFilters, 'fidelityFundOnly', []);
    const isFidelityFundOnlySelected = fidelityFundOnlyFilter.includes('F');
    return isFidelityFundOnlySelected;
  };

  _getHasOnlyOneTable = () => {
    const { displayType } = this.props;
    const isFidelityFundOnlySelected = this._getIsFidelityFundsOnlySelected();
    return displayType === 'compare' || isFidelityFundOnlySelected || !RESULT_FEATURES.HAS_FIDELITY_FUNDS_TABLE;
  };

  _renderFidelityTableRows = (results, rowHeights) => {
    if (!_.isNil(this.props.fidelityResultsError)) {
      return this._renderErrorRow(this.props.fidelityResultsError, true);
    }
    const resultsTableRows = _.map(results, (resultObj, idx) => {
      const shouldBeGreen = RESULT_FEATURES.HAS_FIDELITY_FUNDS_TABLE && resultObj.additionalFundData.isFidelityFund;
      const className = shouldBeGreen ? 'fidelity-light-green' : '';
      return (
        <ResultsTableRowContainer
          key={idx}
          resultsData={resultObj}
          handleActionCheckboxClick={this._handleActionCheckboxClick}
          fundsSelected={this.props.cusipsSelected} //TODO: added cusipsSelected
          height={!_.isEmpty(rowHeights) ? rowHeights[idx] : null}
          className={className}
          setCurrentCheckboxRef={this.setCurrentCheckboxRef}
          staticColumnsLeft={this.state.staticColumnsLeft}
        />
      );
    });
    return resultsTableRows;
  };

  _renderErrorRow = (errorText, isFidelity) => {
    const className = isFidelity ? 'no-results fidelity-light-green' : 'no-results';
    const colSpan = this._getColSpan();
    return [
      <tr key="error-row" className={className}>
        <td colSpan={colSpan} style={{ 'textAlign': 'center' }}>
          {errorText}
        </td>
      </tr>,
    ];
  };

  _renderTableRows = (results, highlightFidelityRows) => {
    let resultsTableRows = [];

    if (!_.isNil(this.props.resultsError)) {
      resultsTableRows = this._renderErrorRow(this.props.resultsError, false);
    } else {
      resultsTableRows = _.map(results, (resultObj, idx) => {
        const shouldBeGreen =
          RESULT_FEATURES.HAS_FIDELITY_FUNDS_TABLE &&
          resultObj.additionalFundData.isFidelityFund &&
          highlightFidelityRows;
        const className = shouldBeGreen ? 'fidelity-light-green' : '';

        // Entry Padding
        // ----------
        const includeColumns = [
          'ytdDaily',
          'yr1',
          'yr3',
          'yr5',
          'yr10',
          'lifeOfFund',
          'lifeOfSubaccount',
          'netExpenseRatio',
        ];
        const includedIndexes = [];
        resultObj.metaData.forEach((i, index) => {
          if (includeColumns.includes(i.id)) {
            includedIndexes.push(index);
          }
        });
        let displayData = resultObj.displayData.filter((d, index) => includedIndexes.includes(index));
        // Scan displayData determine maximum present entries for this row
        let maxDataLines = 1;
        displayData.forEach((d, index) => {
          if (d.length > maxDataLines) {
            maxDataLines = d.length;
          }
        });
        // Insert '--' on columns with less than the maximum present entries
        displayData.forEach((d, index) => {
          if (d.length < maxDataLines) {
            displayData[index].push(<div>--</div>);
          }
        });
        // ----------

        /*********9/28/2023: Life of all funds override: Cleaned newProducts array out to allow regular data to flow through********/
        const columnIndices = [];
        //find column indices for overview and short term performance tabs
        if (this.props.viewTab === 'overview') {
          const overviewColumns = ['ytdDaily', 'yr1', 'yr3', 'yr5', 'yr10', 'lifeOfFund'];
          resultObj.metaData.forEach((i, index) => {
            if (overviewColumns.includes(i.id)) {
              columnIndices.push(index);
            }
          });
        } else if (this.props.viewTab === 'short-term-perf') {
          const shortTermColumns = [
            'ytdDaily',
            'perf1Month',
            'perf3Month',
            'perf6Month',
            'perf1Year',
            'perfMonthlyYtd',
          ];
          resultObj.metaData.forEach((i, index) => {
            if (shortTermColumns.includes(i.id)) {
              columnIndices.push(index);
            }
          });
        }
        const newProducts = [ ];

        // ----------
        //Hide life subaccount data for new products
        resultObj.displayData.forEach((row, rowIndex) => {
          //check if life of subaccount has a value, skip if it doesn't(i.e. '--')
          if (
            row.length <= 0 ||
            (row[0] !== undefined && row[0].props !== undefined && rowIndex === 9 && row[0].props.children !== '--')
          ) {
            return;
          } else {
            columnIndices.forEach(columnIndex => {
              if (newProducts.includes(resultObj.additionalFundData.tradingSymbol)) {
                resultObj.displayData[columnIndex] = new Array(React.createElement('div', { children: '--' }));
              }
            });
          }
        });
        // ----------
        /*********9/28/2023: Life of all funds override: Cleaned newProducts array out to allow regular data to flow through********/
        
        return (
          <ResultsTableRowContainer
            key={idx}
            resultsData={resultObj}
            handleActionCheckboxClick={this._handleActionCheckboxClick}
            fundsSelected={this.props.cusipsSelected} //TODO: added cusipsSelected
            height={null}
            className={className}
            setCurrentCheckboxRef={this.setCurrentCheckboxRef}
            staticColumnsLeft={this.state.staticColumnsLeft}
          />
        );
      });
    }
    if (this._getHasOnlyOneTable()) {
      return resultsTableRows;
    }
    const colSpan = this._getColSpan();
    const sharedStaticColumns = this.props.sharedStaticColumns;
    const sharedStaticColumnsCount = sharedStaticColumns ? sharedStaticColumns.length + 1 : 0;
    const resultsTBodyTitleRow = (
      <tr key="allFundsLabel" className="results-table-title">
        <td colSpan={sharedStaticColumnsCount} className={`col-static`}>
          All Matching {RESULT_FEATURES.RESULT_TYPE_CAPITALIZED}s
        </td>
        <td colSpan={colSpan - sharedStaticColumnsCount}></td>
      </tr>
    );
    return [resultsTBodyTitleRow, ...resultsTableRows];
  };

  _getColSpan = () => {
    const { columns } = this.props;
    let colSpan = columns.length + 1;
    if (RESULT_FEATURES.HAS_BUY_BUTTON) {
      colSpan += 1;
    }
    return colSpan;
  };

  _renderTableHeader = () => {
    return (
      <ResultsTableHeaderContainer
        headerHeight={this.state.headerHeight}
        id="results-header"
        isScrollableTable={true}
        headerRowHeights={this.state.headerRowHeights}
        staticColumnsLeft={this.state.staticColumnsLeft}
      />
    );
  };

  _renderFixedTableHeader = () => {
    return (
      <ResultsTableHeaderContainer
        showFixedHeader={this.state.showFixedHeader}
        headerHeight={this.state.headerHeight}
        headerWidth={this.state.staticTableWidth + this.state.visibleTableWidth}
        headerLeft={this.state.tableLeft}
        headerDimensions={this.state.headerDimensions}
        id="fixed-results-header"
        isScrollableTable={true}
        headerRowHeights={this.state.headerRowHeights}
        isFixedHeader={true}
        scrollPos={this.state.horizontalScrollPos}
        staticColumnsLeft={this.state.staticColumnsLeft}
      />
    );
  };

  _renderTable = () => {
    const className = !this.state.needsScollbar
      ? 'scrollable-results-table-wrapper no-scroll'
      : 'scrollable-results-table-wrapper';
    const fidelityResultsTableRows = this._renderFidelityTableRows(
      this.props.fidelityResults,
      this.state.fidelityResultsRowHeights,
    );
    const fidResultsTbody = this._getHasOnlyOneTable() ? null : (
      <tbody id="fidelity-results-tbody">{fidelityResultsTableRows}</tbody>
    );

    //DODGE suppress
    const dodgeAndCoxFunds = ['DODGX', 'DODBX', 'DODFX', 'DODLX', 'DODIX', 'DODEX', 'DODWX'];
    const filteredResults = this.props.results.filter(function (item) {
      return !dodgeAndCoxFunds.includes(item.additionalFundData.ticker);
    });

    const resultsTableRows = this._renderTableRows(filteredResults);
    const resultsTBody = <tbody id="results-tbody">{resultsTableRows}</tbody>;

    return (
      <div
        id="scrollable-results-table-wrapper"
        className={className}
        ref="scrollableTableWrapper"
        onScroll={e => {
          this.setState({ horizontalScrollPos: e.currentTarget.scrollLeft });
        }}
      >
        <table id="scrollable-results-table" className="scrollable-results-table">
          {this._renderTableHeader()}
          {this._renderFixedTableHeader()}
          {fidResultsTbody}
          {resultsTBody}
        </table>
      </div>
    );
  };


  _renderHorizontalScrollbar = () => {
    return (
      <HorizontalScrollbar
        staticTableWidth={this.state.staticTableWidth}
        scrollableTableWidth={this.state.scrollableTableWidth}
        visibleTableWidth={this.state.visibleTableWidth}
		scrollMaxValue = {this.state.scrollMaxValue}
        tableLeft={this.state.tableLeft}
        needsScrollBar={this.state.needsScrollBar}
        horizontalScrollPos={this.state.horizontalScrollPos}
        updateHorizontalScroll={this.updateHorizontalScroll}
        isScrollbarFloating={this.state.shouldScrollbarFloat}
      />
    );
  };

  _renderCompareTable = () => {
    let resultsTableRows;
    const highlightFidelityRows = true;
    const className = !this.state.needsScollbar
      ? 'scrollable-results-table-wrapper no-scroll'
      : 'scrollable-results-table-wrapper';
    resultsTableRows = this._renderTableRows(this.props.results, highlightFidelityRows);

    return (
      <div
        id="scrollable-results-table-wrapper"
        className={className}
        ref="scrollableTableWrapper"
        onScroll={e => {
          this.setState({ horizontalScrollPos: e.currentTarget.scrollLeft });
        }}
      >
        <table id="scrollable-results-table" className="scrollable-results-table">
          {this._renderTableHeader()}
          {this._renderFixedTableHeader()}
          <tbody id="results-tbody">{resultsTableRows}</tbody>
        </table>
      </div>
    );
  };

  _renderAddSymbol = () => {
    return COMPARE_FEATURES.HAS_ADD_SYMBOL_FIELD ? <AddSymbolContainer /> : null;
  };
  _renderCompareTableView = () => {
    return (
      <div className={this._classNameWithLoading('results-container')}>
		{ this._renderLoadingLoadedVoice()}
        <div className="table-container compare-table">
          <div className="table-wrapper">{this._renderCompareTable()}</div>
          {this._renderHorizontalScrollbar()}
          {this._renderAddSymbol()}
        </div>
        {this._renderTableFooterDisclosure()}
      </div>
    );
  };

  _renderViewMatchingFundsButton = () => {
    const isFidelityFundOnlySelected = this._getIsFidelityFundsOnlySelected();
    if (isFidelityFundOnlySelected) {
      return (
        <button className="view-matching-funds as-link" onClick={this._removeFidelityFundsOnlyCrit}>
          View All Matching {RESULT_FEATURES.RESULT_TYPE_CAPITALIZED}s
        </button>
      );
    } else if (this.props.numFidelityResults > 0) {
      return (
        <button className="view-matching-funds as-link" onClick={this._addFidelityFundsOnlyCrit}>
          View {this.props.numFidelityResults} Matching Fidelity Funds
        </button>
      );
    }
    return null;
  };

  _renderTableTitle = () => {
    const leftTitleItems = RESULT_FEATURES.HAS_FIDELITY_FUNDS_TABLE ? (
      <div className="left-title-items">
        <span className="strong">Matching Fidelity Funds: </span>
        {this._renderViewMatchingFundsButton()}
      </div>
    ) : (
      <div className="left-title-items">
        <span className="strong">Matching {RESULT_FEATURES.RESULT_TYPE_CAPITALIZED}s: </span>
      </div>
    );

    const downloadResultsButton = HAS_DOWNLOAD_RESULTS ? <DownloadResults viewTab={this.props.viewTab} /> : null;

    const helpMeLink = HAS_HELP_ME_READ_THIS_TABLE_LINK ? (
      <button onClick={this._showHelpMeReadThisTable} className="help-me-read-this-table as-link">
        <span>Help me read this table</span>
      </button>
    ) : null;

    return RESULT_FEATURES.HAS_MATCHING_FUNDS_LABEL ? (
      <div className="fidelity-results-table-title">
        {leftTitleItems}
        <div className="right-title-items">
          {helpMeLink}
          {downloadResultsButton}
        </div>
      </div>
    ) : null;
  };
  _renderLoadingLoadedVoice= () => {
	const tabName = this.props.activeTab?  this.props.activeTab.label : this.props.viewTab;
	if (this.props.someResultsLoading){
		return (
			<div class="screen-reader" aria-busy="true" aria-live="polite" aria-atomic="true"><span role="alert">Loading {tabName} data </span></div>
		)
	} else {
		return (
			<div class="screen-reader" aria-busy="false" aria-live="polite" aria-atomic="true"><span role="alert">Loaded {tabName} data </span></div>
		)
	}
  }
  _renderTableView = () => {
    return (
      <div className={this._classNameWithLoading('results-container')}>
		{ this._renderLoadingLoadedVoice()}
        <div className="table-container fidelity-results">
          {this._renderTableTitle()}
          <div className="table-wrapper">{this._renderTable()}</div>
          {this._renderHorizontalScrollbar()}
        </div>
        {this._renderFundsSelectedPopover()}
        {this._renderTableFooterDisclosure()}
      </div>
    );
  };

  _addFidelityFundsOnlyCrit = () => {
    const critArray = this.props.activeCriteria;
    const fidelityFundsOnlyCrit = this.props.criteriaDefinitions['fidelityFundOnly'];
    if (!critArray.includes(fidelityFundsOnlyCrit.id)) critArray.push(fidelityFundsOnlyCrit.id);
    const { defaultFilterGroupSelections } = fidelityFundsOnlyCrit;
    const filterGroupSelections = _.mapValues(defaultFilterGroupSelections, selections =>
      _.map(selections, selection => selection.value),
    );
    const allSelections = { ...this.props.criteriaFilters, ...filterGroupSelections };

    this.props.updateActiveCriteria(critArray, allSelections);
  };

  _removeFidelityFundsOnlyCrit = () => {
    const fidelityFundsOnlyCrit = this.props.criteriaDefinitions['fidelityFundOnly'];
    const critArray = _.filter(this.props.activeCriteria, critId => critId !== fidelityFundsOnlyCrit.id);
    const allSelections = { ...this.props.criteriaFilters };
    delete allSelections[fidelityFundsOnlyCrit.id];

    this.props.updateActiveCriteria(critArray, allSelections);
  };

  _showHelpMeReadThisTable = () => {
    window.open(this.props.helpMeReadThisLinkFullUrl, 'helpMeReadThisTableWindow');
  };

  _renderFundsSelectedPopover = () => {
    let errorMessageText = `You can only compare ${this.MAX_FUNDS_SELECTED} funds at a time. Please remove one of the funds you selected before adding another to your list.`;
    if (this.props.fundsSelected.length < this.MAX_FUNDS_SELECTED) {
      errorMessageText = null;
    }
    if (this.state.showFundsSelectedPopover) {
      return (
        <FundsSelectedPopoverContainer
          isOpen={this.state.showFundsSelectedPopover}
          container="results-container"
          closePopover={this._closeFundsSelectedPopover}
          referenceElement={this.currentCheckboxRef}
          placement="right"
          error={errorMessageText}
          fundToCheckAfterRemoval={this.fundToCheckAfterRemoval}
        />
      );
    }
    return null;
  };
  _renderTableFooterDisclosure = () => {
    return (
      <p className="table-disclosure-content" dangerouslySetInnerHTML={{ __html: this.props.tableDisclosureContent }} />
    );
  };

  render() {
    if (this.props.displayType === 'table') {
      return this._renderTableView();
    } else {
      return this._renderCompareTableView();
    }
  }
}

ResultsTable.propTypes = {
  /** Definitions of all criteria */
  criteriaDefinitions: PropTypes.object,

  /** All the currently selected criteria filters */
  criteriaFilters: PropTypes.object,

  // Array of objects where each object has 3 properties: additionalFundData, displayData, metaData
  results: PropTypes.array.isRequired,

  /** String that represents either table or card view */
  displayType: PropTypes.string.isRequired,

  /** This updates redux state with all selections passed in */
  updateActiveCriteria: PropTypes.func.isRequired,
  /** Dispatches an action updating the updateFundsSelectedError */
  updateFundsSelectedError: PropTypes.func.isRequired,
  /** True if result table is still loading */
  someResultsLoading: PropTypes.bool.isRequired,
  /** Shared columns that belong in the static table */
  sharedStaticColumns: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  const { results, fidelityResults } = getResultsData(state);
  const { sortDir, sortCol } = getSortSettings(state);
  const numFidelityResults = getFidelityMatches(state);
  const fundsSelected = getFundsSelected(state);
  const criteriaDefinitions = getCriteriaDefinitions(state);
  const criteriaFilters = getAllCriteriaFilters(state);
  const activeCriteria = getActiveCriteria(state);
  const resultsError = getFundSearchParsedError(state);
  const fidelityResultsError = getFidelityFundSearchParsedError(state);
  const columns = getColumnsForViewTab(state);
  const isCriteriaMenuExpanded = getCriteriaMenuExpanded(state);
  const sharedStaticColumns = getSharedStaticColumns(state);
  const helpMeReadThisLinkFullUrl = getHelpMeReadThisLinkFullUrl(state);
  const viewTab = getViewTab(state);

  return {
    results,
    resultsError,
    fidelityResults,
    fidelityResultsError,
    sortCol,
    sortDir,
    numFidelityResults,
    displayType: getDisplayType(state),
	fundsSelected,
	someResultsLoading: getSomeResultsLoading(state),
    criteriaDefinitions,
    criteriaFilters,
    activeCriteria,
    tableDisclosureContent: getTableDisclosureContent(state),
    columns,
    sharedStaticColumns,
    isCriteriaMenuExpanded,
    helpMeReadThisLinkFullUrl,
    viewTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateFundsSelected: fundsSelected => {
      dispatch(Creators.updateFundsSelected(fundsSelected));
    },
    updateActiveCriteria: (critArray, filterGroupSelections, source = 'resultsTable') => {
      dispatch(Creators.updateCriteria(critArray, filterGroupSelections, source));
    },
    updateFundsSelectedError: errorType => {
      dispatch(Creators.updateFundsSelectedError(errorType));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsTable);
