import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import format from 'utilities/format';

export default class ResultsPagination extends Component {
  _renderPaginationControlItem = (label, pageNum, isDisabled = false) => {
    const isActive = pageNum === this.props.currentResultsPageNum;
    const key = label + pageNum;
    if (!isDisabled) {
      return (
        <li
          key={key}
          className={`control-item ${isActive ? 'active' : ''}`}
          onClick={() => {
            if (isActive) {
              return 1;
            }
            this.props.updateResultsPageNum(pageNum);
          }}
        >
          {label}
        </li>
      );
    }
    return (
      <li key={key} className="control-item disabled">
        {label}
      </li>
    );
  };
  _renderPaginationEllipsisControlItem = (beforeEllipsisPageNum, afterEllipsisPageNum) => {
    //add ellipsis only if the number of pages in-between is 1 or greater
    if (afterEllipsisPageNum - beforeEllipsisPageNum > 1) {
      //find midpoint between beforeEllipsisPageNum and afterEllipsisPageNum (rounded down to nearest page)
      const midpointPageNum = Math.floor((beforeEllipsisPageNum + afterEllipsisPageNum) / 2);
      //we should show all page numbers when total pages <= 4 so we don't want ellipses in those cases.
      const showNumIfAppropriate = this.props.totalNumPages <= 4;
      //if showNumIfAppropriate is true and there is only one page in-between show page number instead of ellipsis
      const hasOnlyOnePageBetween = afterEllipsisPageNum - beforeEllipsisPageNum === 2;
      const labelText = showNumIfAppropriate && hasOnlyOnePageBetween ? midpointPageNum + '' : '...';
      return this._renderPaginationControlItem(labelText, midpointPageNum);
    }
    return null;
  };
  _renderNumericalPaginationControls = () => {
    const currentPageNum = this.props.currentResultsPageNum;
    const firstPageNum = 1;
    const lastPageNum = this.props.totalNumPages;

    let currentPageAndAdjacent = [];
    //always render page 1
    const firstPage = this._renderPaginationControlItem(firstPageNum + '', firstPageNum);
    const firstAdjacentPageNum = currentPageNum - 1;
    let lastAdjacentPageNum = currentPageNum + 1;

    //if on first page, render 1,2,3 (if the corresponding pages exist).  Otherwise render current page with pages on either side.
    if (currentPageNum === 1) {
      if (2 < lastPageNum) currentPageAndAdjacent.push(this._renderPaginationControlItem('2', 2));
      if (3 < lastPageNum) {
        currentPageAndAdjacent.push(this._renderPaginationControlItem('3', 3));
        lastAdjacentPageNum = 3;
      }
    } else {
      //render one before current page (if not already rendered)
      //render current page
      //render one after current page (if not already rendered)
      for (let i = currentPageNum - 1; i <= currentPageNum + 1; i++) {
        if (i === 1) continue;
        if (i === lastPageNum) break;
        currentPageAndAdjacent.push(this._renderPaginationControlItem(i + '', i));
      }
    }
    const lowerEllipsis = this._renderPaginationEllipsisControlItem(firstPageNum, firstAdjacentPageNum);
    const upperEllipsis = this._renderPaginationEllipsisControlItem(lastAdjacentPageNum, lastPageNum);

    //always render last page
    const lastPage = this._renderPaginationControlItem(lastPageNum + '', lastPageNum);

    const paginationControls = _.filter(
      [firstPage, lowerEllipsis, ...currentPageAndAdjacent, upperEllipsis, lastPage],
      controlItem => {
        return !_.isNil(controlItem);
      },
    );
    return paginationControls;
  };
  _renderPaginationControls = () => {
    //dont show pagination if we only have one page to show
    if (this.props.totalNumPages <= 1) {
      return null;
    }

    const isPrevDisabled = this.props.currentResultsPageNum === 1;
    const isNextDisabled = this.props.currentResultsPageNum === this.props.totalNumPages;
    const prevPage = this.props.currentResultsPageNum - 1;
    const nextPage = this.props.currentResultsPageNum + 1;
    return (
      <div className="pagination-controls-container">
        <ul className="pagination-controls">
          {this._renderPaginationControlItem('Previous', prevPage, isPrevDisabled)}
          {this._renderNumericalPaginationControls()}
          {this._renderPaginationControlItem('Next', nextPage, isNextDisabled)}
        </ul>
      </div>
    );
  };

  updateCurrentPageNum = newPageNum => {
    return this.props.updateResultsPageNum(newPageNum);
  };

  displayNumResults = ()=>{
    const { totalMatches, currentResultsPageNum } = this.props;
	return <div className="num-results" aria-live="polite">{format.formatNumResults(totalMatches, currentResultsPageNum)}</div>
  }

  render() {
    const { displayType, place} = this.props;
	const containerClass= place ==="top" ? "pagination-container-top" : "pagination-container-bottom";
    const paginationStyle = `${containerClass} ${displayType}`;
    return (
      <div className={paginationStyle}>
        { !place || place === "bottom" ? this._renderPaginationControls(): ""}
        { !place || place === "top" ? this.displayNumResults(): ""}
      </div>
    );
  }
}

ResultsPagination.propTypes = {
  currentResultsPageNum: PropTypes.number.isRequired,
  totalNumPages: PropTypes.number.isRequired,
  totalMatches: PropTypes.number,
  updateResultsPageNum: PropTypes.func.isRequired,
};
