import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ResultsPaginationContainer from 'containers/ResultsPaginationContainer';
import ResultsTableContainer from 'components/ResultsView/ResultsTableContainer';
import ViewTabsContainer from 'containers/ViewTabsContainer';
import ResultsCardViewContainer from 'components/ResultsView/ResultsCardViewContainer';
import ResultsTitleContainer from 'components/ResultsView/ResultsTitleContainer';
import ResultsSubTitleContainer from 'components/ResultsView/ResultsSubTitleContainer';
import TakeActionMenuContainer from 'components/ResultsView/TakeActionMenuContainer';
import CompareChartContainer from 'components/ResultsView/CompareChartContainer';
import CompareChartIQChartContainer from 'components/ResultsView/CompareChartIQChartContainer';
import StrategyInfoContainer from 'components/ResultsView/StrategyInfoContainer';
import MadLibsResultsTitleHeader from 'components/ResultsView/MadLibsResultsTitleHeader';
import { getIsMadLibsActive } from '../../reducers/QuickCriteriaMenuReducer';
import { getViewTab } from 'reducers/ViewTabReducer';
import { getViewTabDisplay } from 'reducers/selectors/ViewTabSelectors';
import { RUNNING_ENVIRONMENT, START_PAGE_TYPE } from '../../configs/AppFeatureConfig';

class ResultsViewContainer extends Component {
  getActiveTab = () => {
    const { activeViewTab, viewTabDisplay } = this.props;
    return viewTabDisplay && activeViewTab && viewTabDisplay[activeViewTab] ? viewTabDisplay[activeViewTab] : {};
  };
  _renderTableView = () => {
    const activeTab = this.getActiveTab();
    const shortName = activeTab && activeTab.shortName ? activeTab.shortName : '';
    const panelId = `view-tab-panel-${shortName}-id`;
    const tabId = `view-tab-${shortName}-id`;
    return (
      <React.Fragment>
        <ViewTabsContainer />
        <div id={panelId} class="view-tab-panel" role="tabpanel" aria-labelledby={tabId}>
          <TakeActionMenuContainer />
          <ResultsPaginationContainer place="top" />
          <ResultsTableContainer activeTab={activeTab} />
          <ResultsPaginationContainer place="bottom" />
        </div>
      </React.Fragment>
    );
  };
  _renderCompareView = () => {
    const activeTab = this.getActiveTab();
    const shortName = activeTab && activeTab.shortName ? activeTab.shortName : '';
    const panelId = `view-tab-panel-${shortName}-id`;
    const tabId = `view-tab-${shortName}-id`;
    const chart =  START_PAGE_TYPE === 'mfe' ? <CompareChartIQChartContainer /> : (RUNNING_ENVIRONMENT && RUNNING_ENVIRONMENT.Env === 'Development' ? null : <CompareChartContainer />);
    return (
      <React.Fragment>
        <ViewTabsContainer />
        <div id={panelId} class="view-tab-panel" role="tabpanel" aria-labelledby={tabId}>
          <TakeActionMenuContainer />
          <ResultsTableContainer activeTab={activeTab} />
          {chart}
        </div>
      </React.Fragment>
    );
  };

  _renderCardView = () => {
    return (
      <React.Fragment>
        <ResultsCardViewContainer />
        <ResultsPaginationContainer />
      </React.Fragment>
    );
  };
  _renderResultPageContents = () => {
    const { displayType } = this.props;
    if (displayType === 'compare') {
      return this._renderCompareView();
    } else if (displayType === 'card') {
      return this._renderCardView();
    }
    return this._renderTableView();
  };

  render() {
    return (
      <div className="results-view">
        <ResultsTitleContainer />
        {this.props.isMadLibsActive && this.props.displayType !== 'compare' ? <MadLibsResultsTitleHeader /> : null}
        <StrategyInfoContainer />
        <ResultsSubTitleContainer {...this.props} />
        {this._renderResultPageContents()}
      </div>
    );
  }
}

ResultsViewContainer.propTypes = {
  /** String that represents either table or card view */
  displayType: PropTypes.string.isRequired,

  isMobile: PropTypes.bool.isRequired,

  /** Function to switch between table and card views */
  updateDisplayType: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    isMadLibsActive: getIsMadLibsActive(state),
    activeViewTab: getViewTab(state),
    viewTabDisplay: getViewTabDisplay(state),
  };
}

const ResultsView = connect(mapStateToProps)(ResultsViewContainer);
export default ResultsView;
