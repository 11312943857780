import _ from 'lodash';
import Types from 'actions/Types';
import queryString from 'query-string';
import { push } from 'connected-react-router';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getRouterPersistentQueries, getRouterPathname } from 'reducers/selectors/RouterSelectors';
import { PLAN_NUMBER } from 'configs/AppFeatureConfig';
import { loginUtils } from 'utilities';
import { ENDPOINTS } from 'services/endpoints';
import {mockDataInjection} from "../tests/lib/mockDataSetInjection";


export default api => {
  // helpers

  function* callSagaWithCallback(saga, action) {
    
    yield* saga(action);
    if (action.callbackAction) {
      if (action.callbackAction.type === "@@router/LOCATION_CHANGE") {
        //To do change comments to reflect the new value
        // using the callback action directly is unreliable for ROUTER_LOCATION_CHANGED so create new action
        const pathname = yield select(getRouterPathname);
        const href = { pathname, search: queryString.stringify(action.callbackAction.payload.query) };
        const options = { persistQuery: true, source: 'classificationSaga' };
        const newAction = push(href, options);
        yield put(newAction);
      } else {
        yield put(action.callbackAction);
      }
    }
  }

  function* getApiParameters(action) {
    let policyCodes = action.policyCode;
    if (!policyCodes) {
      const persistentQueries = yield select(getRouterPersistentQueries);
      policyCodes = persistentQueries.product;
    }
    const parameters = { planNumber: PLAN_NUMBER, policyCodes };
    // filter out falsey params
    return _.pickBy(parameters, _.identity);
  }

  // api sagas

  function* fetchFundType(action) {
    const parameters = yield* getApiParameters(action);
    const response = yield call(api.getData, ENDPOINTS.CLASSIFICATION_ASSET_CLASS_AND_CATEGORIES, parameters);
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.CLASSIFICATION_ASSET_CLASS_AND_CATEGORIES, parameters);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}	
    if (response.ok) {
      yield put({ type: Types.FUND_TYPE_API_SUCCESS, payload: response });
    } else {
      yield put({ type: Types.FUND_TYPE_API_ERROR, payload: response });
    }
  }

  function* fetchFundFamily(action) {
    const parameters = yield* getApiParameters(action);
    const response = yield call(api.getData, ENDPOINTS.CLASSIFICATION_FUND_FAMILIES, parameters);
	if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.CLASSIFICATION_FUND_FAMILIES, parameters);
		if(d){
			response.ok = true;
			response.data = d;
		}
	}	
    if (response.ok) {
      yield put({ type: Types.FUNDFAMILY_API_SUCCESS, payload: response });
    } else {
      yield put({ type: Types.FUNDFAMILY_API_ERROR, payload: response });
    }
  }

  function* fetchPolicyInfo(action) {
    if (ENDPOINTS.CLASSIFICATION_POLICY_INFO) {
      const parameters = yield* getApiParameters(action);
      const response = yield call(api.getData, ENDPOINTS.CLASSIFICATION_POLICY_INFO, parameters);
	  if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.CLASSIFICATION_POLICY_INFO, parameters);
		if(d){
			response.ok = true;
			response.data = d;
		}
	  }	
      if (response.ok) {
        yield put({ type: Types.POLICY_INFO_API_SUCCESS, payload: response });
      } else {
        yield put({ type: Types.POLICY_INFO_API_ERROR, payload: response });
      }
    }
  }

  function* fetchEsgGroups(action) {
    if (ENDPOINTS.CLASSIFICATION_ESG_GROUPS) {
      const parameters = yield* getApiParameters(action);
      const response = yield call(api.getData, ENDPOINTS.CLASSIFICATION_ESG_GROUPS, parameters);
	  if (mockDataInjection.enableDataInjection(response)) {
		const d = mockDataInjection.getData(ENDPOINTS.CLASSIFICATION_ESG_GROUPS, parameters);
		if(d){
			response.ok = true;
			response.data = d;
		}
	  }	  
      if (response.ok) {
        yield put({ type: Types.ESGGROUPS_CLASSIFICATION_API_SUCCESS, payload: response });
      } else {
        yield put({ type: Types.ESGGROUPS_CLASSIFICATION_API_ERROR, payload: response });
      }
    }
  }

  function* fetchAllClassification(action) {
    yield* fetchFundType(action);
    yield* fetchFundFamily(action);
    yield* fetchPolicyInfo(action);
	yield* fetchEsgGroups(action);
  }

  function* watcher() {
    // handle login expiration
    loginUtils.redirectToLoginIfSessionExpired();
    loginUtils.resetLoginExpiration();

    // TODO: find a way to do away with callbacks all together
    yield takeEvery(Types.FUNDFAMILY_API, callSagaWithCallback, fetchFundFamily);
    yield takeEvery(Types.FUND_TYPE_API, callSagaWithCallback, fetchFundType);
    yield takeEvery(Types.POLICY_INFO_API, callSagaWithCallback, fetchPolicyInfo);
    yield takeEvery(Types.ESGGROUPS_CLASSIFICATION_API, callSagaWithCallback, fetchEsgGroups);
    yield takeEvery(Types.FUND_CLASSIFICATION_API, callSagaWithCallback, fetchAllClassification);
  }

  return {
    watcher,
    fetchFundFamily,
    fetchFundType,
    fetchPolicyInfo,
	fetchEsgGroups,
    fetchAllClassification,
  };
};
