import { createReducer } from 'reduxsauce';
import screenerConfig from 'screenerConfig';

export const INITIAL_STATE = [...screenerConfig.sharedStaticColumns];

const ACTION_HANDLERS = {};

export default createReducer(INITIAL_STATE, ACTION_HANDLERS);

// ---- Selectors ----

export const getSharedStaticColumns = state => state.sharedStaticColumns;
